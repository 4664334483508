import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
import RegistroPJBusiness from "@/business/indicadores/RegistroPJBusiness.js";
import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import IndicadorRealSimulacaoBusiness from "@/business/protocolo/simulacao/IndicadorRealSimulacaoBusiness.js";
import RegistroAuxiliarSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroAuxiliarSimulacaoBusiness.js";
import RegistroPJSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroPJSimulacaoBusiness.js";
import RegistroTituloSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroTituloSimulacaoBusiness.js";
import ConsultarIndicadorReal from "@/components/IndicadorReal/ConsultarIndicadorReal.vue";
import ConsultarRegistroAuxiliar from "@/components/RegistroAuxiliar/ConsultarRegistroAuxiliar.vue";
import ConsultarRegistroPJ from "@/components/RegistroPJ/ConsultarRegistroPJ.vue";
import DetalhesRegistroPJ from "@/components/RegistroPJ/Detalhes/DetalhesRegistroPJ.vue";
import FormRegistroPJ from "@/components/RegistroPJ/Form/FormRegistroPJ.vue";
import ConsultarRegistroTitulo from "@/components/RegistroTitulo/ConsultarRegistroTitulo.vue";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
import FormIndicadorReal from "@/components/IndicadorReal/Form/FormIndicadorReal.vue";
import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";
import FormRegistroAuxiliar from "@/components/RegistroAuxiliar/Form/FormRegistroAuxiliar.vue";
import DetalhesRegistroTitulo from "@/components/RegistroTitulo/Detalhes/DetalhesRegistroTD.vue";
import FormRegistroTitulo from "@/components/RegistroTitulo/Form/FormRegistroTD.vue";
import SimularIndicadorReal from "@/views/Protocolo/Atos/Simulacao/SimularIndicadorReal.vue";
import SimularRegistroAuxiliar from "@/views/Protocolo/Atos/Simulacao/SimularRegistroAuxiliar.vue";
import SimularRegistroPJ from "@/views/Protocolo/Atos/Simulacao/SimularRegistroPJ.vue";
import SimularRegistroTitulo from "@/views/Protocolo/Atos/Simulacao/SimularRegistroTitulo.vue";
import BemMovelBusiness from "@/business/crud/BemMovelBusiness";
import DetalhesBemMovel from "@/components/BemMovel/Detalhes/DetalhesBemMovel.vue";
import ConsultarBensMoveis from "@/components/BensMoveis/ConsultarBensMoveis.vue";
import VueBusiness from "@/business/VueBusiness";

export default {

  getDominioByLivro(livro) {
    switch (livro) {
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'REGISTRO_AUXILIAR':
        return 'PROTOCOLO_RI';
      case 'REGISTRO_TD':
        return 'PROTOCOLO_RTD';
      case 'REGISTRO_PJ':
        return 'PROTOCOLO_RCPJ';
      default:
        return null;
    }
  },

  getLivrosByDominio(dominio) {

    if(DominioBusiness.isDominioRI(dominio)){
      return [
        {id: 'MATRICULA', nome: 'Matrícula'},
        {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
        {id: 'TRANSCRICAO', nome: 'Transcrição'},
        // {id: 'TORRENS', nome: 'Torrens'},
      ];
    }

    if(DominioBusiness.isDominioRTD(dominio)){
      return [
        {id: 'REGISTRO_TD', nome: 'Registro TD'},
      ];
    }

    if(DominioBusiness.isDominioRCPJ(dominio)){
      return [
        {id: 'REGISTRO_PJ', nome: 'Registro PJ'},
      ];
    }

    return [];
  },

  getLivros() {
    return [
      {id: 'MATRICULA', nome: 'Matrícula', grupo : 'INDICADOR_REAL', sigla : 'M', atribuicao : []},
      {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar', grupo : 'REGISTRO_AUXILIAR', sigla : 'RA', atribuicao : []},
      {id: 'TRANSCRICAO', nome: 'Transcrição', grupo : 'INDICADOR_REAL', sigla : 'T', atribuicao : []},
      {id: 'REGISTRO_TD', nome: 'Registro TD', grupo : 'REGISTRO_TD', sigla : 'RTD', atribuicao : []},
      {id: 'REGISTRO_PJ', nome: 'Registro PJ', grupo : 'REGISTRO_PJ', sigla : 'RCPJ', atribuicao : []},
    ];
  },

  getLivro(livro) {
    return this.getLivros().find(e => e.id === livro);
  },

  getBusiness(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return RegistroAuxiliarBusiness;
      case 'REGISTRO_PJ':
      case 'A':
      case 'B':
        return RegistroPJBusiness;
      case 'REGISTRO_TD':
      case 'REGISTRO_CONSERVACAO':
      case 'REGISTRO_INTEGRAL':
      case 'REGISTRO_INSCRICAO':
        return RegistroTDBusiness;
      case 'BEM_MOVEL':
      case 'LIVRO_BENS':
        return BemMovelBusiness;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return IndicadorRealBusiness;
      default:
        return null;
    }
  },

  getModalConsulta(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return ConsultarRegistroAuxiliar;
      case 'REGISTRO_PJ':
      case 'A':
      case 'B':
        return ConsultarRegistroPJ;
      case 'REGISTRO_TD':
      case 'REGISTRO_CONSERVACAO':
      case 'REGISTRO_INTEGRAL':
      case 'REGISTRO_INSCRICAO':
        return ConsultarRegistroTitulo;
      case 'BEM_MOVEL':
      case 'LIVRO_BENS':
        return ConsultarBensMoveis;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return ConsultarIndicadorReal;
      default:
        return null;
    }
  },

  getDetalhesIndicador(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return DetalhesRegistroAuxiliar;
      case 'REGISTRO_PJ':
        return DetalhesRegistroPJ;
      case 'REGISTRO_TD':
        return DetalhesRegistroTitulo;
      case 'BEM_MOVEL':
        return DetalhesBemMovel;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return DetalhesIndicadorReal;
      default:
        return null;
    }
  },

  getFormIndicador(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return FormRegistroAuxiliar;
      case 'REGISTRO_PJ':
        return FormRegistroPJ;
      case 'REGISTRO_TD':
        return FormRegistroTitulo;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return FormIndicadorReal;
      default:
        return null;
    }
  },

  getSimulacaoIndicador(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return SimularRegistroAuxiliar;
      case 'REGISTRO_PJ':
        return SimularRegistroPJ;
      case 'REGISTRO_TD':
        return SimularRegistroTitulo;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return SimularIndicadorReal;
      default:
        return null;
    }
  },

  getSimulacaoBusinessIndicador(livro){
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return RegistroAuxiliarSimulacaoBusiness;
      case 'REGISTRO_PJ':
        return RegistroPJSimulacaoBusiness;
      case 'REGISTRO_TD':
        return RegistroTituloSimulacaoBusiness;
      case 'MATRICULA':
      case 'TRANSCRICAO':
      case 'INDICADOR_REAL':
        return IndicadorRealSimulacaoBusiness;
      default:
        return null;
    }
  },

  async digitalizacaoDesatualizada(indicador) {
    return !indicador;
  },

  /** *****************************************************************************/

  nomearFicha(ficha = {}, codigo = true, resumirFicha = false, livroLetra = true){
    let nome = ficha.status == 'PENDENTE' || ficha.prematricula? 'Pré-' : '';
    let livro = this.getLivro(ficha.livro).nome + ' ';
    if(resumirFicha){
      livro = (this.getLivro(ficha.livro).sigla)+'-';
    }
    nome += livro;
    let letra = `${livroLetra && ficha.livroLetra ? ficha.livroLetra : ''}`;
    if(letra != '' && ficha.livro != 'TRANSCRICAO' && /^[a-zA-Z0-9]+$/.test(letra)){
      letra = '-' + letra;
    }
    if(letra != '' && ficha.livro == 'TRANSCRICAO'){
      letra = '-' + `${ficha.livroNumero ? ficha.livroNumero : ''}` + `${ficha.livroLetra ? ficha.livroLetra : ''}`;
    }
    return `${nome}${codigo ? formatarCodigo(ficha.codigo) : ''}${letra}`.trim();
  },

  getTipoEntidade(ficha){
    switch (ficha.livro) {
      case 'TRANSCRICAO':
        return 'TRANSCRICAO';
      case 'MATRICULA':
        if(ficha.status === 'PENDENTE'){
          return 'PRE_MATRICULA';
        }else{
          return 'INDICADOR_REAL';
        }
      default:
        return ficha.livro;
    }
  },

}

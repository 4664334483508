<template>

  <modal :loading="loading" titulo="Importação Unidades" class="modal-importacao-unidades" id="importacao-unidades">

    <card titulo="Configurações Unidades">

      <template #acoes>
        <a href="" @click.prevent="gerarNovasColunas()" style="float: right;">
          <i class="fal fa-plus"></i> Adicionar mais opções de colunas na planilha
        </a>
      </template>
      <card :titulo="name(campo)" class="m-0" classTitle="border-h3" style="background-color: #232931;margin-top: 5px !important;" v-for="campo in camposPlanilhaForm" :key="campo">
        <template #acoes>
          <a href="" @click.prevent="remove(campo)" class="ml-1 icon float-right"><i class="fa fa-times"></i></a>
        </template>

        <div class="row no-gutters w-100" v-if="!camposEspeciais.includes(campo)" >
          <v-input type="select" :options="tipos(campo)" label="Tipo" v-model="dto[campo].tipo" class="col-auto mr-1" style="width: 150px;" @change="reloadInput"/>

          <SelectColunaPlanilha :label="dto[campo].tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: dto[campo].tipo.length > 1 ? '314.75px' : '633px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(dto[campo].tipo)" :model.sync="dto[campo].coluna1" :colunas="colunasPlanilha" :valor="dto[campo].coluna1" @change="salvarCache"/>

          <div v-if="(dto[campo].tipo || '').includes('F')" :style="{ width: dto[campo].tipo.length > 1 ? '314.75px' : '633px' }" class="col-auto no-gutters mr-1 float-left" >
            <label class="w-75">Fixo</label>
            <div v-if="campo === 'tipoImovel'" class="input-group float-left col-unica">
              <select v-model="dto[campo].fixo" class="form-control form-control-xs" @change="salvarCache">
                <option v-for="(tipo, index) in options.tipos" :value="tipo.id" :key="'tipo'+index">{{tipo.nome}}</option>
              </select>
            </div>

            <div v-else-if="campo === 'cidade'" class="input-group float-left col-unica">
              <select v-model="dto[campo].fixo" @change="salvarCache" class="form-control form-control-xs col-12 float-left">
                <option v-for="(cidade, index) in $root.cidades" :value="cidade.id" :key="'cidade'+index">
                  {{cidade.nome}}
                </option>
              </select>
            </div>

            <div v-else-if="campo === 'estado'" class="input-group float-left col-unica">
              <select v-model="dto[campo].fixo" @change="salvarCache" class="form-control form-control-xs col-12 float-left">
                <option v-for="(estado, index) in options.estados" :value="estado.id" :key="'estado'+index">
                  {{estado.id}}
                </option>
              </select>
            </div>

            <template v-else-if="campo === 'unidadeAutonoma'">
              <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                <input id="FLAG_UNIDADE_AUTONOMA_INCLU" style="position: relative; top: 3px; left: 5px;" type="checkbox" @change="salvarCache"
                       v-model="dto[campo].fixo" />
                <label for="FLAG_UNIDADE_AUTONOMA_INCLU" style="position: relative;top: 2px;left: 10px;" class="pointer">
                  Unidade Autônoma?
                </label>
              </div>
            </template>

            <template v-else-if="['imovelUniao', 'marinha', 'fronteira'].includes(campo)">
              <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                <v-input v-model="dto[campo].fixo" style="position: relative; top: 3px; left: 5px;" type="radio" class="" @change="salvarCache" />
              </div>
            </template>

            <template v-else-if="campo === 'numeroRipSpu'">
              <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                <v-input v-model="dto[campo].fixo" type="mask" :masks="['#### #######-##']" @change="salvarCache" />
              </div>
            </template>

            <div v-else-if="campo === 'tipoImovel'" class="input-group float-left col-unica">
              <select v-model="dto[campo].fixo" class="form-control form-control-xs col-11 float-left" @change="salvarCache">
                <option v-for="(tipoImovel, index) in options.tipos" :value="tipoImovel.id" :key="'tipoImovel'+index">
                  {{tipoImovel.id}}
                </option>
              </select>
            </div>

            <template v-else-if="campo === 'ultimoAto'">
              <div class="form-group mb-0 col-unica float-left">
                <v-input class="col" v-model="dto[campo].fixo" type="currency" :input-props="{precision : 0}" @change="salvarCache"/>
              </div>
            </template>

            <div v-else-if="campo === 'categoria'" class="input-group float-left col-unica">
              <select v-model="dto[campo].fixo" class="form-control form-control-xs col-12 float-left" @change="salvarCache">
                <option v-for="(categoria, index) in options.categorias" :value="categoria.id" :key="'tipo categoria'+index">
                  {{categoria.id}}
                </option>
              </select>
            </div>

            <v-input class="col-unica float-left" v-else-if="isArea(campo)" type="currency" :input-props="{precision : 5}" v-model="dto[campo].fixo" @change="validarFracao(campo, $event)"/>

            <v-input class="col-unica float-left" :small="true" v-else-if="campo === 'cep'" :type="'cep'" v-model="dto[campo].fixo" :placeholder="name(campo)" @change="salvarCache"/>


            <template v-else >
              <v-input :style="{  width: dto[campo].tipo.length > 1 ? '296.75px' : '597px' }" class="col-auto mr-1 float-left" v-if="showEditorReload" :small="true" :type="type(campo)" v-model="dto[campo].fixo" :placeholder="type(campo) === 'editor' ? '' : name(campo)" @change="salvarCache"/>
            </template>

          </div>
          <SelectColunaPlanilha :label="dto[campo].tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 314.75px" v-if="['FC', 'CC'].includes(dto[campo].tipo)" :model.sync="dto[campo].coluna2" :colunas="colunasPlanilha" :valor="dto[campo].coluna2" @change="salvarCache"/>
        </div>
        <template v-else-if="campo ==='camposArea'">
          <card-content class="input-group float-left col-12" style="padding: 0 !important;">

            <CampoArea classe="input-table" key="medidaArea" label="Medida Área" :value.sync="dto[campo].medidaArea" :colunas="colunasPlanilha" campo="medidaArea" style="padding-right: 0px;"/>
            <CampoArea classe="input-table" key="area" label="Área Total" :value.sync="dto[campo].area" :colunas="colunasPlanilha" campo="area" style="padding-left: 0px;padding-right: 0px;"/>
            <CampoArea classe="input-table" key="fracaoSolo" label="Fração Solo" :value.sync="dto[campo].fracaoSolo" :colunas="colunasPlanilha" campo="fracaoSolo" style="padding-right: 0px;"/>
            <CampoArea classe="input-table" key="fracaoSoloPercentual" label="Fração Solo (%)" :value.sync="dto[campo].fracaoSoloPercentual" campo="fracaoSoloPercentual" :colunas="colunasPlanilha" style="padding-left: 0px;padding-right: 0px;"/>
            <CampoArea classe="input-table" key="coeficienteProporcionalidade" label="Coeficiente de proporcionalidade" :value.sync="dto[campo].coeficienteProporcionalidade" campo="coeficienteProporcionalidade" :colunas="colunasPlanilha" style="padding-left: 0px;padding-right: 0px;"/>

          </card-content>

        </template>

        <template v-else-if="campo ==='areasEdificadas'">

          <card-content class="row gutters-xs content-aedificada tbl-visualizacao" style="padding: 0 !important;">
            <DetalharAreasPlanilha titulo="Áreas edificadas" key="areasEdificadas" tipo="areasEdificadas"  :visualizar="true" :areas="dto[campo]" :colunas="colunasPlanilha" @change="salvarCache"/>
          </card-content>

        </template>
        <template v-else-if="campo === 'areas'">

          <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
            <DetalharAreasPlanilha titulo="Outras Áreas" key="areas" tipo="areas" :visualizar="true" :areas="dto[campo]" :colunas="colunasPlanilha" @change="salvarCache"/>
          </card-content>

        </template>

        <template v-else-if="campo === 'unidadesAcessorias'">

          <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
            <DetalharAreasPlanilha titulo="Unidade acessória" key="unidadesAcessorias" tipo="unidadesAcessorias" :possui-tipo="false" :visualizar="true" :areas="dto[campo]" :colunas="colunasPlanilha" @change="salvarCache"/>
          </card-content>

        </template>

        <template v-else-if="campo === 'confrontacoes'">

          <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
            <DetalharAreasPlanilha titulo="Confrontações" key="confrontacoes" tipo="confrontacoes" :possui-tipo="false" :visualizar="true" :areas="dto[campo]" :colunas="colunasPlanilha" @change="salvarCache"/>
          </card-content>

        </template>
      </card>

      <hr style="border-color: #232931;"/>

      <!--          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <card titulo="Novo Item">
        <div class="mt-2 form">
          <v-input class="mb-2" v-model="newCampo" :small="true" type="select" :options="options.campos"/>
          <template v-if="newCampo && showInputReload">
            <div class="row no-gutters" style="width: calc(100% - 36px); float: left;" v-if="!camposEspeciais.includes(newCampo)" >

              <v-input type="select" :default="['imovelUniao', 'marinha', 'fronteira'].includes(newCampo) ? 'F' : 'C'" :options="tipos(newCampo)" label="Tipo" v-model="form.tipo" class="col-auto mr-1" style="width: 150px;" @change="reloadInput"/>

              <SelectColunaPlanilha :label="form.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: form.tipo.length > 1 ? '296.75px' : '597px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(form.tipo)" :model.sync="form.coluna1" :colunas="colunasPlanilha" :valor="form.coluna1"/>

              <div v-if="(form.tipo || '').includes('F')" :style="{ width: form.tipo.length > 1 ? '296.75px' : '597px' }" class="col-auto no-gutters mr-1 float-left" >
                <label class="w-75">Fixo</label>
                <div v-if="newCampo === 'tipoImovel'" class="input-group float-left col-unica">
                  <select v-model="form.fixo" class="form-control form-control-xs" @change="salvarCache">
                    <option v-for="(tipo, index) in options.tipos" :value="tipo.id" :key="'tipo'+index">{{tipo.nome}}</option>
                  </select>
                </div>

                <div v-else-if="newCampo === 'cidade'" class="input-group float-left col-unica">
                  <select v-model="form.fixo" @change="salvarCache" class="form-control form-control-xs col-12 float-left">
                    <option v-for="(cidade, index) in $root.cidades" :value="cidade.id" :key="'cidade'+index">
                      {{cidade.nome}}
                    </option>
                  </select>
                </div>

                <div v-else-if="newCampo === 'estado'" class="input-group float-left col-unica">
                  <select v-model="form.fixo" @change="salvarCache" class="form-control form-control-xs col-12 float-left">
                    <option v-for="(estado, index) in options.estados" :value="estado.id" :key="'estado'+index">
                      {{estado.id}}
                    </option>
                  </select>
                </div>

                <template v-else-if="newCampo === 'unidadeAutonoma'">
                  <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                    <input id="FLAG_UNIDADE_AUTONOMA_LIST" style="position: relative; top: 3px; left: 5px;" :value="false" type="checkbox" @change="salvarCache"
                           v-model="form.fixo" />
                    <label for="FLAG_UNIDADE_AUTONOMA_LIST" style="position: relative;top: 2px;left: 10px;" class="pointer">
                      Unidade Autônoma?
                    </label>
                  </div>
                </template>

                <template v-else-if="['imovelUniao', 'marinha', 'fronteira'].includes(newCampo)">
                  <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                    <v-input v-model="form.fixo" style="position: relative; top: 3px; left: 5px;" type="radio" class="" @change="salvarCache" />
                  </div>
                </template>

                <template v-else-if="newCampo === 'numeroRipSpu'">
                  <div class="form-group mb-0 col-unica float-left" style="margin-top: 4px;">
                    <v-input v-model="form.fixo" type="mask" :masks="['#### #######-##']" @change="salvarCache" />
                  </div>
                </template>

                <div v-else-if="newCampo === 'tipoImovel'" class="input-group float-left col-unica">
                  <select v-model="form.fixo" class="form-control form-control-xs col-11 float-left" @change="salvarCache">
                    <option v-for="(tipoImovel, index) in options.tipos" :value="tipoImovel.id" :key="'tipoImovel'+index">
                      {{tipoImovel.id}}
                    </option>
                  </select>
                </div>

                <template v-else-if="newCampo === 'ultimoAto'">
                  <v-input class="col-unica float-left" v-model="form.fixo" type="currency" :input-props="{precision : 0}" @change="salvarCache"/>
                </template>

                <div v-else-if="newCampo === 'categoria'" class="input-group float-left col-unica">
                  <select v-model="form.fixo" class="form-control form-control-xs col-12 float-left" @change="salvarCache">
                    <option v-for="(categoria, index) in options.categorias" :value="categoria.id" :key="'tipo categoria'+index">
                      {{categoria.id}}
                    </option>
                  </select>
                </div>

                <v-input class="col-unica float-left" v-else-if="isArea(newCampo)" type="currency" :input-props="{precision : 5}" v-model="form.fixo" @change="validarFracao(newCampo, $event)"/>

                <v-input class="col-unica float-left" :small="true" v-else-if="newCampo === 'cep'" :type="'cep'" v-model="form.fixo" :placeholder="name(newCampo)" @change="salvarCache"/>

                <template v-else >
                  <v-input :style="{ width: form.tipo.length > 1 ? '296.75px' : '597px' }" class="col-auto mr-1 float-left" v-if="showEditorReload" :small="true" :type="type(newCampo)" v-model="form.fixo" :placeholder="type(newCampo) === 'editor' ? '' : name(newCampo)" @change="salvarCache"/>
                </template>

              </div>
              <SelectColunaPlanilha :label="form.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 296.75px" v-if="['FC', 'CC'].includes(form.tipo)" :model.sync="form.coluna2" :colunas="colunasPlanilha" :valor="form.coluna2" @change="salvarCache"/>
            </div>
            <template v-else-if="newCampo ==='camposArea'">
              <card-content class="input-group float-left col-12" style="padding: 0 !important; width: 750px;">

                <CampoArea :novo="true" classe="input-table" key="medidaArea" label="Medida Área" :value.sync="form.medidaArea" :colunas="colunasPlanilha" campo="medidaArea" style="padding-right: 0px;"/>
                <CampoArea :novo="true" classe="input-table" key="area" label="Área Total" :value.sync="form.area" :colunas="colunasPlanilha" campo="area" style="padding-left: 0px;padding-right: 0px;"/>
                <CampoArea :novo="true" classe="input-table" key="fracaoSolo" label="Fração Solo" :value.sync="form.fracaoSolo" :colunas="colunasPlanilha" campo="fracaoSolo" style="padding-right: 0px;"/>
                <CampoArea :novo="true" classe="input-table" key="fracaoSoloPercentual" label="Fração Solo (%)" :value.sync="form.fracaoSoloPercentual" campo="fracaoSoloPercentual" :colunas="colunasPlanilha" style="padding-left: 0px;padding-right: 0px;"/>
                <CampoArea :novo="true" classe="input-table" key="coeficienteProporcionalidade" label="Coeficiente de proporcionalidade" :value.sync="form.coeficienteProporcionalidade" campo="coeficienteProporcionalidade" :colunas="colunasPlanilha" style="padding-left: 0px;padding-right: 0px;"/>

              </card-content>

            </template>

            <template v-else-if="newCampo ==='areasEdificadas'">

              <card-content class="row gutters-xs content-aedificada tbl-visualizacao" style="padding: 0 !important; width: 750px; float: left;">
                <DetalharAreasPlanilha :novo="true" titulo="Áreas edificadas" key="areasEdificadas" tipo="areasEdificadas"  :visualizar="true" :areas="form.areasEdificadas" :colunas="colunasPlanilha" @change="salvarCache"/>
              </card-content>

            </template>
            <template v-else-if="newCampo === 'areas'">

              <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important; width: 750px; float: left;">
                <DetalharAreasPlanilha :novo="true" titulo="Outras Áreas" key="areas" tipo="areas" :visualizar="true" :areas="form.areas" :colunas="colunasPlanilha" @change="salvarCache"/>
              </card-content>

            </template>

            <template v-else-if="newCampo === 'unidadesAcessorias'">

              <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important; width: 750px; float: left">
                <DetalharAreasPlanilha :novo="true" titulo="Unidade acessória" key="unidadesAcessorias" tipo="unidadesAcessorias" :possui-tipo="false" :visualizar="true" :areas="form.unidadesAcessorias" :colunas="colunasPlanilha" @change="salvarCache"/>
              </card-content>

            </template>

            <template v-else-if="newCampo === 'confrontacoes'">

              <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important; width: 750px; float: left">
                <DetalharAreasPlanilha :novo="true" titulo="Confrontações" key="confrontacoes" tipo="confrontacoes" :possui-tipo="false" :visualizar="true" :areas="form.confrontacoes" :colunas="colunasPlanilha" @change="salvarCache"/>
              </card-content>

            </template>

            <div style="margin-top: 23px;float: right;" v-if="form && newCampo">
              <a href="" @click.prevent="add($event)" class="btn btn-outline-secondary btn-xs">
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </template>
        </div>
      </card>
    </card>

    <card titulo="Configurações Básicas">
      <div class="row gutters-xs">
        <v-input type="number" label="Linha Inicial" :error="validation.linhaInicio" v-model.number="configuracoes.linhaInicio" @blur="salvarCache" class="col-3" />
        <v-input type="number" label="Linha Final" :error="validation.linhaFim" v-model.number="configuracoes.linhaFim" @blur="salvarCache" class="col-3" />
        <v-upload v-model="file" extensions="xls,xlsx" class="form-control col-6 icon" style="margin-top: 26px;" :class="{'planilha-error': validation.file}">
          <i class="fal fa-file-excel"></i> IMPORTAR PLANILHA
        </v-upload>
        <small class="col-6" style="margin-left: 50%; margin-top: -15px;">{{file[0] ? file[0].file.name : ''}}</small>

      </div>

    </card>

    <template #footer>
      <button @click.prevent="close()" class="btn btn-outline-secondary mr-2" type="button">Cancelar</button>
      <button class="btn btn-outline-secondary mr-2" value="exportar" type="button"
              @click.prevent="exportar()">Exportar
      </button>
      <label class="btn btn-outline-secondary mr-2" for="fileInput">
        Importar
      </label>
      <input id="fileInput" accept=".json" type="file" ref="fileInput" @change="importar" hidden>
      <v-button :run="limparConfiguracao" class="btn-outline-secondary mr-auto" v-if="true" :popover="true" >Limpar Configuração</v-button>
      <v-button :run="preview" class="btn-outline-primary mr-2" v-if="true" :popover="true">Preview de Unidades</v-button>
      <v-button :run="salvar" class="btn-outline-primary" v-if="true" :popover="true">Gerar Unidades</v-button>
    </template>

  </modal>

</template>

<script>
import Modal from "@/components/Modal";
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import EnderecoBusiness from "@/business/EnderecoBusiness";
import CardContent from "@/components/Layout/components/CardContent";
import CardTable from "@/components/Layout/components/CardTable";
import SelectColunaPlanilha from "@/components/IndicadorReal/Detalhes/ImportacaoUnidades/SelectColunaPlanilha";
import DetalharAreasPlanilha from "@/components/IndicadorReal/Detalhes/ImportacaoUnidades/DetalharAreasPlanilha";
import IndicadorRealUnidadeBusiness from "@/business/indicadores/IndicadorRealUnidadeBusiness";
import Utils from "@/commons/Utils";
import CampoArea from "@/components/IndicadorReal/Detalhes/ImportacaoUnidades/CampoArea";
import ListaUnidadesPreview from "@/components/IndicadorReal/Detalhes/ImportacaoUnidades/ListaUnidadesPreview";

export default {
  name: "ImportacaoUnidades",
  components: {
    CampoArea,
    DetalharAreasPlanilha,
    SelectColunaPlanilha,
    CardContent,
    Card, Modal},
  props: {
    ficha: Object,
  },

  modal:{
    width: 900
  },

  data(){
    return{
      loading : true,
      configuracoes:{},
      camposEspeciais: ['camposArea', 'areasEdificadas', 'areas', 'unidadesAcessorias', 'confrontacoes'],
      file: [],
      validation: {},

      dto: [],
      newCampo: null,

      form:{tipo: 'C', coluna1: null, fixo: null, coluna2: null},
      options: {
        medidas: IndicadorRealBusiness.getMedidas(),
        tipos: IndicadorRealBusiness.getTiposImovel(),
        categorias: IndicadorRealBusiness.getCategorias(),
        estados: EnderecoBusiness.getEstados(),
        campos: []
      },
      showInputReload: true,
      showEditorReload: true,
      alfabeto: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      prefixoInicial: 'A',
      colunas: [],

      camposInfoComplementar: [],
      camposCadastroIdentificao: [],
      camposEndereco: [],
      camposArea: [],
      camposEspecificacao: [],
      areasEdificadas: [],
      areas: [],
      unidadesAcessorias: [],
      confrontacoes: [],

    }
  },

  watch: {
    newCampo: function (newValue) {

      this.$nextTick(() => {
        this.$set(this, 'showInputReload', false)
        this.limparFormIndicadorReal();

        setTimeout(() => this.$set(this, 'showInputReload', true));
      })
    },
    'configuracoes': function () { this.salvarCache()},
    'dto': function () { this.salvarCache()},
  },

  mounted() {
    this.loading = false;
    this.colunas = [...this.alfabeto];
    this.camposInfoComplementar = IndicadorRealBusiness.getCamposInfoComplementar('', true);
    this.camposCadastroIdentificao = IndicadorRealBusiness.getCamposCadastroIdentificacao('', true);
    this.camposEndereco = IndicadorRealBusiness.getCamposEndereco();
    this.camposArea = IndicadorRealBusiness.getCamposArea(true);
    this.camposEspecificacao = IndicadorRealBusiness.getCamposEspecificao();

    this.options.campos = [
      {id: 'observacao', tipo: 'editor', nome: 'Observações Internas'},
      {id: 'certidao', tipo: 'editor', nome: 'Observações para Certidão'},
      {id: 'imovelUniao', nome: 'Imóvel da União'},
      {id: 'marinha', nome: 'Imóvel da Marinha'},
      {id: 'fronteira', nome: 'Imóvel de Fronteira'},
      ...this.camposInfoComplementar,
      ...this.camposCadastroIdentificao,
      ...this.camposEndereco,
      {id: 'camposArea', nome: 'Área'},
      {id: 'areasEdificadas', nome: 'Áreas Edificadas'},
      {id: 'areas', nome: 'Outras Áreas'},
      {id: 'unidadesAcessorias', nome: 'Unidades Acessórias'},
      {id: 'confrontacoes', nome: 'Confrontações'},
      ...this.camposEspecificacao,
      {id: 'categoria', nome: 'Categoria'},
      {id: 'unidadeAutonoma', nome: 'Unidade Autônoma'},
      {id: 'tipoImovel', nome: 'Tipo de imóvel'},
      {id: 'descricaoTipoImovelOutros', nome: 'Descrição', tipo: 'text'},
      {id: 'ultimoAto', nome: 'Último Ato', tipo: 'number'},
      {id: 'incra', nome: 'INCRA (CCIR)', tipo: 'text'},
      {id: 'itr', nome: 'ITR', tipo: 'text'},
      {id: 'car', nome: 'CAR', tipo: 'text'},
      {id: 'grupo', nome: 'Grupo (Tipo)', tipo: 'text'},
    ];
    let configuracao = localStorage.getItem('importacao-unidades');

    this.loadConfiguracoes(configuracao);

  },

  computed: {

    camposPlanilhaForm() {
      return Object.keys(this.dto) || [];
    },

    colunasPlanilha() {
      return this.colunas;
    },

  },

  methods: {
    tipos(campo) {
      let tipos = [
        {id: 'C', nome: 'Coluna'},
        {id: 'F', nome: 'Fixo'}
      ]
      let normal = ['cep', 'estado', 'tipoImovel', 'categoria', 'ultimoAto', 'unidadeAutonoma', 'imovelUniao', 'marinha', 'fronteira'];
      if (!normal.includes(campo)) {
        tipos = [...tipos, ... [{id: 'FC', nome: 'Fixo + Coluna'},
          {id: 'CF', nome: 'Coluna + Fixo'},
          {id: 'CC', nome: 'Coluna + Coluna'}]]
      }
      tipos = this.apenasTipoFixo(tipos, campo);
      return tipos
    },

    apenasTipoFixo(tipos, campo){
      if(['imovelUniao', 'marinha', 'fronteira'].includes(campo)){
        tipos = tipos.filter(tipo => tipo.id ==='F');
      }
      return tipos;
    },

    loadConfiguracoes(configuracao){
      if(!Utils.isEmptyNullOrUndefined(configuracao)) {
        configuracao = JSON.parse(configuracao);
        this.arquivoAntigo(configuracao)
        this.configuracoes = configuracao.configuracoes || {};
        this.dto = configuracao.dto || {};
        Object.values(this.camposPlanilhaForm).forEach(k => {
          if (!this.dto[k]) {
            this.$set(this.dto, k, {
              tipo: 'F', coluna1: null, fixo: null, coluna2: null
            })
          }
        })
        while(configuracao.prefixoInicial !== this.prefixoInicial){
          this.gerarNovasColunas();
        }
        this.options.campos.forEach(campo => {
          if(this.camposPlanilhaForm.includes(campo.id)){
            campo.disabled = true;
          }
        });
        if(Object.keys(configuracao?.dto || {}).length === 0){
          this.popularEnderecoFicha();
        }
      } else {
        this.popularEnderecoFicha();
      }
    },

    popularEnderecoFicha(){
      let endereco = ["cep", "estado", "cidade", "bairro", "logradouro", "numero", "complemento"];
      let dto = {};
      Object.assign(dto, this.dto);
      for (const campo of endereco) {
        if(!Utils.isEmptyNullOrUndefined(this.ficha[campo])){
          dto[campo] = {tipo: 'F', fixo: this.ficha[campo]};
          this.toggleOptionCampos(true, campo);
        }
      }
      this.$set(this, 'dto', dto);
    },

    remove(key) {
      const dto = Object.assign({}, this.dto );
      delete dto[key];
      this.dto = dto;
      this.toggleOptionCampos(false, key);
      this.limparFormIndicadorReal();
    },

    name(id) {
      return (this.options.campos.find(c => c.id === id) || {}).nome;
    },

    type(id) {
      return (this.options.campos.find(c => c.id === id) || {}).tipo;
    },

    isArea(campo) {
      return campo.startsWith('area') || campo.startsWith('fracao');
    },

    validarFracao(campo) {
      if (campo === 'fracaoSoloPercentual' && campo.fixo > 100) {
        this.$set(campo, 'fixo', 100);
        this.salvarCache();
      }
    },

    close() {
      FrontBusiness.closeModal();
    },

    limparFormIndicadorReal() {
      this.$set(this, 'form', {
        areasEdificadas: this.areasEdificadas,
        areas: this.areas,
        unidadesAcessorias: this.unidadesAcessorias,
        confrontacoes: this.confrontacoes,
      });
    },

    async add(e) {
      this.$nextTick(async () => {
        const dto = Object.assign({}, this.dto);
        if (this.newCampo === 'camposArea') {
          dto['camposArea'] = {};
          for (let i = 0; i < this.camposArea.length; i++) {
            if (this.form[this.camposArea[i].id]) {
              await this.$set(dto['camposArea'], this.camposArea[i].id, this.form[this.camposArea[i].id]);
            }
          }
        } else if (this.newCampo === 'areasEdificadas') {
          if (this.form['areasEdificadas'].length){
            await this.$set(dto, 'areasEdificadas', this.form['areasEdificadas']);
          }
        } else if (this.newCampo === 'areas') {
          if (this.form['areas'].length) {
            await this.$set(dto, 'areas', this.form['areas']);
          }
        } else if (this.newCampo === 'unidadesAcessorias') {
          if (this.form['unidadesAcessorias'].length) {
            await this.$set(dto, 'unidadesAcessorias', this.form['unidadesAcessorias']);
          }
        } else if (this.newCampo === 'confrontacoes') {
          if (this.form['confrontacoes'].length) {
            await this.$set(dto, 'confrontacoes', this.form['confrontacoes']);
          }
        } else if (this.newCampo && this.form.tipo) {
          if (this.newCampo === 'unidadeAutonoma' && this.form.tipo === 'F' && Utils.isEmptyNullOrUndefined(this.form.fixo)) this.form.fixo = false;
          if (!Utils.isEmptyNullOrUndefined(this.form.coluna1) || !Utils.isEmptyNullOrUndefined(this.form.fixo) || !Utils.isEmptyNullOrUndefined(this.form.coluna2)) await this.$set(dto, this.newCampo, this.form);
        }

        if ((Object.keys(dto) || []).includes(this.newCampo)) this.toggleOptionCampos(true, this.newCampo);
        this.dto = dto;
        this.$set(this, 'newCampo', null);
        this.limparFormIndicadorReal();
      });
    },

    gerarNovasColunas(){
      let prefixo = this.prefixoInicial;
      this.alfabeto.forEach((letra, index) => {
        if(letra === prefixo){
          this.prefixoInicial = this.alfabeto[index+1];
        }
        this.colunas.push(prefixo+letra);
      });
      this.salvarCache();
    },

    salvarCache(){
      localStorage.setItem('importacao-unidades', Utils.stringify(this.criarObjetoConfiguracoes()));
    },

    reloadInput(){
      this.$set(this, 'showEditorReload', false)
      setTimeout(() => this.$set(this, 'showEditorReload', true));
      this.salvarCache();
    },

    async preview(){
      if(this.formInvalido()){
        return;
      }
      Object.keys(this.dto).forEach(a => {
        delete this.dto[a].areas;
        delete this.dto[a].areasEdificadas;
        delete this.dto[a].unidadesAcessorias;
        delete this.dto[a].confrontacoes;
      })
      this.salvarCache();

      let planilhaFormSave = {};
      Object.assign(planilhaFormSave, Utils.clone(this.dto));
      Object.keys(planilhaFormSave).forEach(a => {
        if (a === 'numeroRipSpu' && planilhaFormSave[a].fixo) {
          planilhaFormSave[a].fixo = planilhaFormSave[a].fixo.replace(/[^\d]/g, '');
        }
      })

      const configuracao = this.criarObjetoConfiguracoes();
      configuracao.planilhaForm = planilhaFormSave;
      this.tratarCamposArea(planilhaFormSave);

      let response = await IndicadorRealUnidadeBusiness.preview(this.file[0].file, this.ficha.id, configuracao).catch(e => FrontBusiness.showError('', e.message));
      if (response) {
        await FrontBusiness.openModal(ListaUnidadesPreview, {unidades: response});
      }
    },

    async salvar(){
      if(this.formInvalido()){
        return;
      }
      this.salvarCache();

      let planilhaFormSave = {};
      Object.assign(planilhaFormSave, Utils.clone(this.dto));
      Object.keys(planilhaFormSave).forEach(a => {
        if (a === 'numeroRipSpu' && planilhaFormSave[a].fixo) {
          planilhaFormSave[a].fixo = planilhaFormSave[a].fixo.replace(/[^\d]/g, '');
        }
      })

      const configuracao = this.criarObjetoConfiguracoes();
      configuracao.planilhaForm = planilhaFormSave;
      this.tratarCamposArea(planilhaFormSave);

      let response = await IndicadorRealUnidadeBusiness.upload(this.file[0].file, this.ficha.id, configuracao).catch(e => FrontBusiness.showError('', e.message));
      if(response){
        FrontBusiness.closeModal(true);
      }
    },

    tratarCamposArea(planilhaFormSave){
      if(planilhaFormSave.camposArea){
        Object.keys(planilhaFormSave.camposArea).find(item => {
          planilhaFormSave[item] = planilhaFormSave.camposArea[item];
        });
        delete planilhaFormSave.camposArea;
      }
    },

    criarObjetoConfiguracoes(){
      return {
        configuracoes: {...this.configuracoes},
        prefixoInicial: this.prefixoInicial,
        dto: this.dto,
      };
    },

    limparConfiguracao(){
      this.dto = {};
      this.configuracoes = {};
      this.prefixoInicial = 'A';
      this.removeDisabledAllOptions();
      this.salvarCache();
    },

    formInvalido(){
      let hasError = false;
      if(!this.configuracoes.linhaInicio){
        this.$set(this.validation, 'linhaInicio', 'Informe a linha em que o sistema deve iniciar a leitura da planilha');
        hasError = true;
      } else {
        this.validation.linhaInicio = null;
      }
      if(!this.configuracoes.linhaFim){
        this.$set(this.validation, 'linhaFim', 'Informe a linha em que o sistema deve parar a leitura da planilha');
        hasError = true;
      } else {
        this.validation.linhaFim = null;
      }
      if(!this.file[0]){
        this.$set(this.validation, 'file', true);
        hasError = true;
      } else {
        this.$set(this.validation, 'file', false);
      }
      IndicadorRealUnidadeBusiness.getCamposObrigatoriosIndicador().forEach(e => {
        if(!this.camposPlanilhaForm.includes(e.id)){
          FrontBusiness.showError(e.nome);
          hasError = true;
        }
      });
      return hasError;
    },

    toggleOptionCampos(disabled, campo){
      const campos = Object.assign([], this.options.campos);
      campos.forEach((item, i) =>{
        if(item.id === campo){
          if(disabled){
            item.disabled = disabled;
          } else {
            delete campos[i].disabled;
          }
        }
      });
      this.$set(this.options, 'campos', campos);
    },

    removeDisabledAllOptions(){
      const campos = Object.assign([], this.options.campos);
      campos.forEach((item, i) => delete campos[i].disabled);
      this.$set(this.options, 'campos', campos);
    },

    exportar() {
      const texto = localStorage.getItem('importacao-unidades');
      FrontBusiness.downloadTxt(`importacao-unidades.json`, texto);
    },

    // TRATAMENTO PARA ARQUIVOS ANTIGOS
    arquivoAntigo(config) {
      config.dto = config?.dto || {};
      Object.keys(config?.planilhaForm || {}).forEach(a => {
        config.dto[a] = {};
        if (a === 'camposArea') {
          Object.keys(config?.planilhaForm?.[a] || {}).forEach(b => {
            config.dto[a][b] = {};
            if (config?.planilhaForm?.[a]?.[b]?.fixo) {
              config.dto[a][b].tipo = 'F';
              config.dto[a][b].fixo = config?.planilhaForm?.[a]?.[b]?.valor;
            } else {
              config.dto[a][b].tipo = 'C';
              config.dto[a][b].coluna1 = config?.planilhaForm?.[a]?.[b]?.valor;
            }
          })

        } else if (['areasEdificadas', 'areas', 'unidadesAcessorias'].includes(a)) {
          config.dto[a] = [];
          (config?.planilhaForm?.[a] || []).forEach(c => {
            let item = {};
            Object.keys(c || {}).forEach(b => {
              item[b] = {};
              if (c?.[b]?.fixo) {
                item[b].tipo = 'F';
                item[b].fixo = c?.[b]?.valor;
              } else {
                item[b].tipo = 'C';
                item[b].coluna1 = c?.[b]?.valor;
              }
            })
            config.dto[a].push(item)
          })
        } else {
          if ((config.camposFixos || []).includes(a)) {
            config.dto[a].tipo = 'F';
            config.dto[a].fixo = config?.planilhaForm?.[a];
          } else {
            config.dto[a].tipo = 'C';
            config.dto[a].coluna1 = config?.planilhaForm?.[a];
          }
        }
      })
    },

    popular(arquivo) {
      let config = JSON.parse(arquivo.target.result);
      //
      this.loadConfiguracoes(JSON.stringify(config) || "[]");
    },

    importar() {
      const input = this.$refs['fileInput'];

      if (input && input.files && input.files[0]) {
        const fr = new FileReader();
        fr.onload = this.popular;
        fr.readAsText(input.files[0]);
      }
    },

  }

}
</script>

<style lang="css">

.planilha-error {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.col-unica {
  width: 630px;
}

.form .col-unica {
  width: 597px;
}

.theme-dark #modals-container .border-h3 {
  border-color: #555555 !important;
}

</style>

<template>
  <modal titulo="Inserir Qualificação" :loading="!mostrar">

    <card titulo="Escolha o modelo para visualizar" v-if="mostrar">

      <card-table>

        <thead>
          <tr>

            <th>Pessoa</th>
            <th width="150px">Documento</th>
            <th width="30px">Pessoa</th>
            <th width="30px">Cônjuge</th>

          </tr>
        </thead>

        <tbody>

          <template v-for="(pessoa) in pessoas">
            <tr :key="pessoa.id">
              <td>
                {{pessoa.nome}} (Versão: {{pessoa.versao}})
                <div class="small text-muted text-orange w-100" v-if="pessoa.conjuge">
                  Cônjuge: {{pessoa.conjuge.nome}} (Versão: {{pessoa.conjuge.versao}})
                </div>
              </td>

              <td>{{pessoa.documento}}
                <div class="small text-muted text-orange w-100" v-if="pessoa.conjuge" >
                  {{pessoa.conjuge.documento}}
                </div>
              </td>

              <td class="text-center">
                <input class="form-check-input" style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="pessoa.selecionado" @change="montarTexto">
              </td>

              <td class="text-center">
                <input class="form-check-input" style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" @change="montarTexto"
                       v-if="pessoa.conjuge" v-model="pessoa.selecionadoConjuge">
              </td>

            </tr>
          </template>

        </tbody>
      </card-table>

    </card>

    <card titulo="Preview" v-if="textoSelecionado">
      <div v-html="textoSelecionado"></div>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="run" :popover="true" class="btn-outline-primary">Inserir</v-button>
    </template>

  </modal>
</template>

<script>
import ModeloTextualBusiness from "@/business/crud/ModeloTextualBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import qualificarEnvolvido from "@/business/livros/utils/qualificarEnvolvido.js";
import ProtocoloProcessoBusiness from "@/business/protocolo/ProtocoloProcessoBusiness.js";
import Utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";

export default {
  name: "ModalInserirQualificacao",
  components: {CardTable, Card, Modal},
  props: {
    envolvidos: Array
  },

  data() {
    return {
      mostrar: false,
      pessoas: [],
      configuracao: ['rg', 'nascimento', 'nacionalidade', 'profissao', 'estadoCivil', 'endereco'],
      textoSelecionado: ''
    }
  },

  async mounted() {
    let envolvidos = await Promise.all(this.envolvidos.map(e => IndicadorPessoalBusiness.getVersaoByIdComCache(e.id)));

    this.pessoas = [];
    for(let eI in envolvidos){
      let envolvido = envolvidos[eI];
      // console.debug(envolvido);

      if (envolvido.conjuge) {
        let conjuge = envolvidos.find(e => e?.indicadorPessoalId == envolvido?.conjuge?.indicadorPessoalId);

        if (conjuge) {
          envolvido.conjuge = Utils.clone(conjuge);
          envolvido.conjugeId = conjuge.indicadorPessoalId;
        }

      }

      this.pessoas.push(envolvido);

    }

    this.$set(this, 'mostrar', true);
  },

  methods: {

    close() {
      FrontBusiness.closeModal();
    },

    async run() {
      await this.montarTexto();
      FrontBusiness.closeModal(this.textoSelecionado);
    },

    async montarTexto(){
      this.$nextTick(async () => {
        let textos = await Promise.all(this.pessoas?.filter(e => e.selecionado)?.map(e => qualificarEnvolvido(Utils.clone(e), undefined, true, !!e.selecionadoConjuge)))
        this.$set(this, 'textoSelecionado', FrontBusiness.formatArray(textos, ', ', ' e '));
        console.debug(this.textoSelecionado)
      });
    }

  }
}
</script>

<style lang=scss>

</style>

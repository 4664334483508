import RestricaoBusiness from "@/business/RestricaoBusiness";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils";

const path = 'api/v2/indicador';

const tratarAto = (a) => {
  if(a.tipoServico) a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  if(a.validador) a.validador = JSON.parse(a.validador);
  if(a.protocolo) a.protocolo = JSON.parse(a.protocolo);
  return a;
};

export default {

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async editarTextoLegado(id, dto) {
    return Http.post(`${path}/${id}/texto-legado`, dto);
  },

  async getEnvolvidos(id){
    return Http.get(`${path}/${id}/envolvidos`);
  },

  async buscarAtosRegistrados(id){
    return Http.get(`${path}/${id}/atos-registrados`).then(l => l.map(tratarAto));
  },

  async verificarAtosImpressao(id){
    return Http.get(`${path}/${id}/atos-impressao`);
  },

  async ultimoAtoRegistrado(id){
    return Http.get(`${path}/${id}/ultimo-ato-registrado`).then(a => tratarAto(a));
  },

  async cancelarUltimoAto(id) {
    return await Http.post(`${path}/${id}/ato/cancelar-ultimo-ato`);
  },

  async setImpressao(id, imprimindo) {
    return Http.post(`${path}/${id}/ato-impressao/${imprimindo}`);
  },

  async buscarProtocolosAtivos(id){
    return Http.get(`${path}/${id}/protocolos-ativos`);
  },

  async getFichasPorCodigo(params = {}) {
      const {livro, status, dto} = params;
    return await Http.post(`${path}/${livro}/codigos/${status}`, dto);
  },

  async atosRegistrados(id){
    return Http.get(`${path}/${id}/atos`).then(l => l.map(tratarAto));
  },

  async getFichasPorId(ids) {
    return await Http.post(`${path}/ids`, ids);
  },

  async restricoesVigentes(id){
    return Http.get(`${path}/${id}/restricoes-vigentes`);
  },

  async restricoes(id, registroAto){
    if(Utils.isEmptyNullOrUndefined(registroAto)) registroAto = '';
    return Http.get(`${path}/${id}/restricoes/${registroAto}`).then(r => r.map(RestricaoBusiness.tratarRestricoes));
  },

  async digitalizacaoDesatualizada(id){
    return Http.get(`${path}/${id}/digitalizacao-desatualizada`);
  },

  async impressaoDesatualizada(id){
    return Http.get(`${path}/${id}/impressao-desatualizada`);
  },

  async indicadorValidado(id){
    return Http.get(`${path}/valido/${id}`);
  },

  async historico(id, page, size) {
    return Http.get(`${path}/${id}/historico?page=${page || 1}&size=${size || 10}`);
  },

  async getRegistrosPosteriores(indicadorId, sort, direction){
    return Http.get(`${path}/${indicadorId}/registros-posteriores?sort=${sort || 'codigo'},${direction || 'asc'}`);
  },

  async getAtos(indicadorId, atoId = '', registrado = false, selado = false){
    const tratarObjeto = (item) => {
      item.ato = JSON.parse(item.ato);
      return item;
    };
    return Http.get(`${path}/atos-indicador/${indicadorId}/${atoId}/${registrado}/${selado}`).then(response => response.map(tratarObjeto));
  },

  async getBaseCalculoAtoVinculado(id){
    return Http.get(`${path}/indicador-base-calculo/${id}`);
  },

  async getDocumentosVinculados(id){
    return Http.get(`${path}/documentos-indicador/${id}`)
  },

  async validarIndicadorDocumentos(ato, id) {
    return Http.get(`${path}/validar-documentos/${ato}/${id}`);
  },

  async saveDocumento(ato, id, dto) {
    return Http.post(`${path}/documento/${ato}/${id}`, dto);
  }
}

<template>

  <modal id="detalhes-oficio" class="modal-detalhes-oficio" :titulo="titulo" :loading="loading">

    <div id="aba-dados-principais" v-if="oficio">

      <card>
        <div class="row gutters-xs oficio">

          <v-input type="select" v-if="!id" :options="listas.tipos" label="Tipo" class="col-3" v-model="oficio.tipo" default="ENVIADO" />

          <v-input type="v-select" :options="listas.tiposServico" label="Tipo de Ofício" class="col" v-model="oficio.tipoServico.id" />

          <v-input type="date" class="col" label="Prazo para Resposta" v-model="oficio.vencimento" />

          <div class="col-12">

            <v-input class="w-100" label="Ofício de Origem" v-if="!oficio.anterior">
              <buscar-oficio @add="selecionar($event)" :novo="true" />
            </v-input>

            <div class="row gutters-xs" v-else>

              <v-input class="col form-group" label="Ofício de Origem">
                <input type="text" readonly v-model="anteriorCodigo" class="form-control form-control-xs" />
              </v-input>

              <div class="col-auto">
                <button class="btn btn-outline-secondary btn-xs" style="margin-top: 22px" @click="selecionar(null)" type="button" title="Remover">
                  <i class="fal fa-times"></i>
                </button>
              </div>

            </div>

          </div>

        </div>
      </card>

    </div>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>

import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness.js";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import OficioBusiness from "@/business/oficio/OficioBusiness.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import Utils from "@/commons/Utils.js";
import BuscarOficio from "@/components/BuscarOficio.vue";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal.vue";

export default {

    name: "DetalhesOficio",
    components: {
      BuscarOficio,
      Modal, Card,
    },

    props: {
      anterior: Object,
      id: String,
      protocolo: String,
      targetBlank: Boolean
    },

    modal:{
      width: 800,
      escapable: false
    },

    async mounted(){

      if(this.id){
        this.oficio = await OficioBusiness.getById(this.id);
        this.tipoServico = this.oficio.tipoServico;
      } else {
        this.oficio = {
          anterior: this.anterior?.id ? this.anterior : null,
          envolvidos: this.anterior?.id ? this.anterior?.envolvidos || [] : [],
          matriculas: this.anterior?.id ? this.anterior?.matriculas || [] : [],
          ano: null,
          numero: null,
          indices: this.anterior?.id ? this.anterior?.indices || {} : {},
          processo: this.anterior?.id ? this.anterior?.processo : null,
          oficio: this.anterior?.id ? this.anterior?.oficio : null,
          vara: this.anterior?.id ? this.anterior?.vara : null,
          remetente: this.anterior?.id ? this.anterior?.remetente : null,
          destinatario: this.anterior?.id ? this.anterior?.destinatario : null,
          observacoes: this.anterior?.id ? this.anterior?.observacoes : null,
          tipoServico: {},
          texto: '',
          layoutImpressao: {},
          protocolos: this.anterior?.id ? this.anterior?.protocolos || [] : [],
          gerarNumero: true
        };
      }

      this.oficio.indices = this.oficio.indices || {};
      this.oficio.tipoServico = this.oficio.tipoServico || {};

      this.listas.tiposServico = (await TipoServicoBusiness.getByDominio('OFICIO')).filter(t => t.ativo || t.id === this.oficio?.tipoServico?.id).map(o => {
        o.disabled = !o.ativo;
        return o;
      });

      this.loading = false;
    },

    computed:{

      configuracao(){
        return this.$root.config;
      },

      codigo(){
        return `${this.oficio?.numero}/${this.oficio?.ano}`;
      },

      titulo(){
        return this.id ? 'Editar Ofício' : 'Cadastrar Ofício';
      },

      componente(){
        return `aba-${this.aba}`;
      },

      mostrarGerarNumero(){
        return this.oficio.tipo == 'ENVIADO' && !this.oficio.id;
      },

      anteriorCodigo(){
        return `Ofício ${this.oficio.anterior.numero}/${this.oficio.anterior.ano}`;
      }

    },

    data() {
      return {
        loading: true,
        oficio : null,
        aba : 'dados',
        tiposServico : [],

        checklist : {
          indices : {},
          grupos:[]
        },
        listas: {
          layouts: [],
          listas: [],
          checklists : [],
          tipos: OficioBusiness.getTipos(),
          tiposServico : []
        }
      }
    },

    methods:{

      close : FrontBusiness.closeModal,

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#formulario-oficio');
      },

      async salvar() {

        if(!this.oficio.tipoServico?.id){
          return null;
        }

        let dto = Utils.clone(this.oficio);
        //dto.envolvidos = dto.envolvidos?.filter(e => e).map(e => e.id);
        dto.matriculas = dto.matriculas?.filter(e => e).map(e => e.id);
        dto.matriculasExternas = dto.matriculasExternas?.filter(e => e).map(e => e.id);
        dto.registrosAuxiliares = dto.registrosAuxiliares?.filter(e => e).map(e => e.id);
        dto.protocolos = dto.protocolos?.filter(e => e).map(e => e.id);
        dto.anterior = Utils.getObjectId(dto.anterior);

        if(this.protocolo) dto.protocolos.push(this.protocolo);
        let retorno = await OficioBusiness.save(dto, this.oficio.id).catch(this.showError);
        if(retorno){
          await OficioBusiness.regerarChecklist(retorno).catch(e => console.error(e));
          FrontBusiness.redirect(`oficio/${retorno}/geral`, this.targetBlank);
          this.close();
        }

      },


      nomearProtocolo : FrontBusiness.nomearProtocolo,

      selecionar(item){
        this.$set(this.oficio, 'anterior', item)
      },

      changeIndices(indices){
        this.$set(this.oficio, 'indices', indices);
      },

      remover(idx, lista) {
        this.$nextTick(() => {
          this.oficio[lista].splice(idx, 1);
        });
      },

      tipoProtocolo(label){
        return FrontBusiness.getLabel(label, ProtocoloBusiness.getTiposProtocolo());
      },

      dominio(label){
        return FrontBusiness.getLabel(label, DominioBusiness.getDominios());
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },


    }

  }
</script>

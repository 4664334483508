import FragmentoBusiness from "@/business/crud/FragmentoBusiness.js";
import error from "@/business/livros/copiladores/error.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import TextParser from "@/business/livros/TextParser.js";
const cacheFragmentos = {};

const fragmento = async function(node, callback, dicionario, debug){

  let indice = getVar(node.params.indice, dicionario, false, true);
  let fragmento = cacheFragmentos?.[indice];
  if(!fragmento?.minuta){
    fragmento = await FragmentoBusiness.getByIndice(indice);
    cacheFragmentos[indice] = fragmento;
    // console.debug('fragmento', indice, fragmento);
  }

  if(!fragmento?.minuta){
    fragmento = {minuta : error('fragmento não encontrado')};
  }

  if(debug) {
    debug.fragmento = TextParser.htmlText(fragmento.minuta);
  }

  let parametro = {};
  Object.keys(node.params).forEach(key => parametro[key] = getVar(node.params[key], dicionario, false, true));

  return await TextParser.parse(fragmento.minuta, callback, {...dicionario, parametro}, debug);

};

export default fragmento;

import Http from "@/commons/Http";

const path = 'api/financeiro/nota-fiscal';

export default {

  async getByProtocolo(protocoloId) {
    return Http.get(`${path}/protocolo/${protocoloId}`);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'n.rps'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async alterarStatus(notaFiscalId){
    return Http.post(`${path}/set-pendente/${notaFiscalId}`);
  },

  async gerarNotaFiscal(protocoloId) {
    return Http.get(`${path}/gerar-nota/${protocoloId}`);
  },

  async emitirNotasPendentes() {
    return Http.get(`${path}/processar-notas-pendentes`);
  },

  async gerarNotasPendentes() {
    return Http.get(`${path}/gerar-notas-pendentes`);
  },

  async consultarNotas() {
    return Http.get(`${path}/consultar-notas`);
  },

  async processarNotaFiscal(notaFiscalId) {
    return Http.get(`${path}/processar-nota/${notaFiscalId}`);
  },

  async consultarNotaFiscal(notaFiscalId) {
    return Http.get(`${path}/consultar-nota/${notaFiscalId}`);
  },

  async validacaoCancelamentoPorAPI() {
    return Http.get(`${path}/cancelamento-por-api`);
  },

  async cancelarNotaFiscal(notaFiscalId){
    return Http.post(`${path}/cancelar/${notaFiscalId}`);
  },

  async substituirNotaFiscal(id, dados) {
    return Http.post(`${path}/substituir-nota/${id}`, dados);
  },

  async atualizarNotaFiscal(id, dados) {
    return Http.post(`${path}/atualizar-nota/${id}`, dados);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  getNaturezasDeOperacao(){
    return [
      {id: "TRIBUTACAO_NO_MUNICIPIO", nome: "Tributação no município"},
      {id: "TRIBUTACAO_FORA_DO_MUNICIPIO", nome: "Tributação fora do município"},
      {id: "ISENCAO", nome: "Isenção"},
      {id: "IMUNE", nome: "Imune"},
      {id: "EXIGIBILIDADE_SUSPENSA_POR_DECISAO_JUDICIAL", nome: "Exigibilidade suspensa por decisão judicial"},
      {id: "EXIGIBILIDADE_SUSPENSA_POR_PROCEDIMENTO_ADMINISTRATIVO", nome: "Exigibilidade suspensa por procedimento administrativo"},
      {id: "EXIGIVEL", nome: "Exigível"},
      {id: "NAO_INCIDENCIA", nome: "Não incidência"},
      {id: "EXPORTACAO", nome: "Exportação"},
      {id: "ISS_FIXO", nome: "ISS fixo"},
      {id: "SUBSTITUICAO_TRIBUTARIA", nome: "Substituição tributária"},
      {id: "SIMPLES_NACIONAL", nome: "Simples Nacional"},
      {id: "ISENCAO_PARCIAL", nome: "Isenção parcial"},
      {id: "CANCELADO", nome: "Cancelado"},
      {id: "ISS_RETIDO_PELO_TOMADORINTERMEDIARIO", nome: "ISS retido pelo tomador/intermediário"},
      {id: "NAO_TRIBUTAVEL", nome: "Não tributável"},
      {id: "MICRO_EMPREENDEDOR_INDIVIDUAL_MEI", nome: "Micro Empreendedor Individual 'MEI'"},
      {id: "SERVICO_PARA_IMOVEL_CONSTRUCAO_CIVIL", nome: "Serviço para imóvel 'Construção Civil'"},
      {id: "SERVICO_EM_VIA_PUBLICA", nome: "Serviço em via pública"},
      {id: "TRIBUTADA_INTEGRALMENTE_COM_ISSRF", nome: "Tributada integralmente com ISSRF"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO", nome: "Tributada com redução da base de cálculo"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO_COM_ISSRF", nome: "Tributada com redução da base de cálculo com ISSRF"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO_COM_SUB_TRIBUTARIA", nome: "Tributada com redução da base de cálculo com Sub. Tributária"},
      {id: "NAO_TRIBUTADA_ISS_REGIME_ESTIMATIVA", nome: "Não tributada - ISS regime estimativa"},
      {id: "NAO_TRIBUTADA_ISS_CONSTRUCAO_CIVIL_RECOLHIDO_ANTECIPADAMENTE", nome: "Não Tributada - ISS construção civil recolhido antecipadamente"},
      {id: "NAO_TRIBUTADA_ATO_COOPERADO", nome: "Não Tributada - Ato Cooperado"},
      {id: "SIMPLES_NACIONAL_E_COM_O_ISS_RETIDO_NA_FONTE", nome: "Simples Nacional e com o ISS retido na fonte"},
      {id: "SUBSTITUICAO_TRIBUTARIA_PELO_AGENCIADOR_OU_INTERMEDIARIO_DA_PRESTACAO_DO_SERVICO", nome: "Substituição tributária pelo agenciador ou intermediário da prestação do serviço"},
      {id: "SIMPLES_NACIONAL_E_SUBSTITUICAO_TRIBUTARIA", nome: "Simples Nacional e substituição tributária"},
      {id: "ISSRETIDOANTERIORMENTESUBSTITUTOTRIBUTARIO", nome: "ISSRetidoAnteriormenteSubstitutoTributario"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO_OU_ALIQUOTA_E_COM_ISS_RETIDO_NA_FONTE", nome: "Tributada com redução da base de cálculo ou alíquota e com ISS retido na fonte"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO_OU_ALIQUOTA_E_SUJEITA_AO_REGIME_DA_SUBSTITUICAO_TRIBUTARIA", nome: "Tributada com reducao da base de cálculo ou alíquota e sujeita ao regime da substituicao tributária"},
      {id: "TRIBUTADA_COM_REDUCAO_DA_BASE_DE_CALCULO_OU_ALIQUOTA_E_COM_O_ISS_RETIDO_ANTERIORMENTE_PELO_SUBSTITUTO_TRIBUTARIO", nome: "Tributada com reducao da base de cálculo  ou aliquota e com o ISS retido anteriormente pelo substituto tributário"},
      {id: "NAO_TRIBUTADA_EM_RAZAO_DO_DESTINO_DOS_BENS_OU_OBJETOS_MERCADORIAS_PARA_A_INDUSTRIALIZACAO_OU_COMERCIALIZACAO", nome: "Nao tributada em razao do destino dos bens ou objetos - Mercadorias para a industrializacao ou comercializacao"},
      {id: "NAO_TRIBUTADA_EM_RAZAO_DO_DIFERIMENTO_DA_PRESTACAO_DO_SERVICO", nome: "Nao tributada em razao do diferimento da prestacao do servico"},
      {id: "TRIBUTADO_NO_MUNICIPIO_POREM_ISENTO", nome: "Tributado no município, porém Isento"},
      {id: "TRIBUTADO_FORA_DO_MUNICIPIO_POREM_ISENTO", nome: "Tributado Fora do município, porém Isento"},
      {id: "TRIBUTADO_NO_MUNICIPIO_POREM_IMUNE", nome: "Tributado no município, porém Imune"},
      {id: "TRIBUTADO_FORA_DO_MUNICIPIO_POREM_IMUNE", nome: "Tributado Fora do município, porém Imune"},
      {id: "TRIBUTADO_NO_MUNICIPIO_POREM_EXIGIBILIDADE_SUSPENSA", nome: "Tributado no município, porém Exigibilidade Suspensa"},
      {id: "TRIBUTADO_FORA_DO_MUNICIPIO_POREM_EXIGIBILIDADE_SUSPENSA", nome: "Tributado Fora do município, porém Exigibilidade Suspensa"},
      {id: "TRATAMENTO_FISCAL_DIFERENCIADO", nome: "Tratamento Fiscal Diferenciado"},
      {id: "ESTIMADO", nome: "Estimado"},
      {id: "IMPOSTO_DEVIDO_NO_MUNICIPIO_COM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_NO_MUNICIPIO", nome: "Imposto devido no município, com retenção na fonte 'serviço prestado no município'"},
      {id: "IMPOSTO_DEVIDO_NO_MUNICIPIO_SEM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_NO_MUNICIPIO", nome: "Imposto devido no município, sem retenção na fonte 'serviço prestado no município'"},
      {id: "NAO_TRIBUTAVEL_SERVICO_PRESTADO_NO_MUNICIPIO", nome: "Não tributável 'serviço prestado no município'"},
      {id: "SIMPLES_NACIONAL_SERVICO_PRESTADO_NO_MUNICIPIO", nome: "Simples Nacional 'serviço prestado no município'"},
      {id: "IMPOSTO_DEVIDO_NO_MUNICIPIO_COM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Imposto devido no município, com retenção na fonte 'serviço prestado fora do município'"},
      {id: "IMPOSTO_DEVIDO_NO_MUNICIPIO_SEM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Imposto devido no município, sem retenção na fonte 'serviço prestado fora do município'"},
      {id: "IMPOSTO_DEVIDO_FORA_DO_MUNICIPIO_COM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Imposto devido fora do município, com retenção na fonte 'serviço prestado fora do município'"},
      {id: "IMPOSTO_DEVIDO_FORA_DO_MUNICIPIO_SEM_RETENCAO_NA_FONTE_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Imposto devido fora do município, sem retenção na fonte 'serviço prestado fora do município'"},
      {id: "NAO_TRIBUTAVEL_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Não tributável 'serviço prestado fora do município'"},
      {id: "SIMPLES_NACIONAL_SERVICO_PRESTADO_FORA_DO_MUNICIPIO", nome: "Simples Nacional 'serviço prestado fora do Município'"},
      {id: "NAO_TRIBUTAVEL_SERVICO_PRESTADO_NO_EXTERIOR", nome: "Não tributável 'serviço prestado no exterior'"},
      {id: "SERVICO_NO_MUNICIPIO_ISS_MENSAL_SEM_RETENCAO_NA_FONTE", nome: "Serviço no município - ISS mensal sem retenção na fonte"},
      {id: "SERVICO_NO_MUNICIPIO_ISS_MENSAL_COM_RETENCAO_NA_FONTE", nome: "Serviço no município - ISS mensal com retenção na fonte"},
      {id: "CONSTRUCAO_CIVIL_NO_MUNICIPIO_ISS_MENSAL_SEM_RETENCAO_NA_FONTE", nome: "Construção civil - no município - ISS mensal sem retenção na fonte"},
      {id: "CONSTRUCAO_CIVIL_NO_MUNICIPIO_ISS_MENSAL_COM_RETENCAO_NA_FONTE", nome: "Construção civil - no município - ISS mensal com retenção na fonte"},
      {id: "SERVICO_EM_OUTRO_MUNICIPIO_ISS_MENSAL_SEM_RETENCAO_NA_FONTE", nome: "Serviço em outro município – ISS mensal sem retenção na fonte"},
      {id: "SERVICO_EM_OUTRO_MUNICIPIO_ISS_MENSAL_COM_RETENCAO_NA_FONTE", nome: "Serviço em outro município – ISS mensal com retenção na fonte"},
      {id: "CONSTRUCAO_CIVIL_OUTRO_MUNICIPIO_ISS_MENSAL_SEM_RETENCAO_NA_FONTE", nome: "Construção civil - outro município - ISS mensal sem retenção na fonte"},
      {id: "CONSTRUCAO_CIVIL_OUTRO_MUNICIPIO_ISS_MENSAL_COM_RETENCAO_NA_FONTE", nome: "Construção civil - outro município - ISS mensal com retenção na fonte"},
      {id: "SERVICO_NAO_ENQUADRADA_NAS_SITUACOES_ANTERIORES_SEM_RETENCAO", nome: "Serviço não enquadrada nas situações anteriores - sem retenção"},
      {id: "SERVICO_NAO_ENQUADRADA_NAS_SITUACOES_ANTERIORES_COM_RETENCAO", nome: "Serviço não enquadrada nas situações anteriores - com retenção"},
      {id: "ISS_DEVIDO_PARA_O_MUNICIPIO_SIMPLES_NACIONAL", nome: "ISS devido para o município 'Simples Nacional'"},
      {id: "ISS_DEVIDO_PARA_OUTRO_MUNICIPIO_SIMPLES_NACIONAL", nome: "ISS devido para outro município 'Simples Nacional'"},
      {id: "ESCRITORIO_CONTABIL_SIMPLES_NACIONAL", nome: "Escritório Contábil 'Simples Nacional'"},
      {id: "ISS_RETIDO_PELO_TOMADORINTERMEDIARIO_SIMPLES_NACIONAL", nome: "ISS retido pelo tomador/intermediário 'Simples Nacional'"},
      {id: "OPERACAO_IMUNE_ISENTA_OU_NAO_TRIBUTADA_SIMPLES_NACIONAL", nome: "Operação imune, isenta ou não tributada 'Simples Nacional'"}
    ]
  },

  getSituacaoNota(){
    return [
      {id: "PENDENTE", nome: "Pendente"},
      {id: "PROCESSANDO", nome: "Processando"},
      {id: "EMITIDA", nome: "Emitida"},
      {id: "CANCELADA", nome: "Cancelada"},
      {id: "VERIFICAR", nome: "Verificar"},
      {id: "REPROCESSAR", nome: "Reprocessar"},
      {id: "SUBSTITUIDA", nome: "Substituída"},
      {id: "EMISSAO_CANCELADA", nome: "Emissão Cancelada"},
    ]
  }

}

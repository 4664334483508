<template>

  <modal id="detalhes-indicador-real-externo" class="modal-detalhes-entidade" :titulo="titulo" :loading="!ficha">
    <template #actions>

      <li class="list-inline-item">
        <a @click.prevent.stop="editarIndicador(ficha)" title="Editar Indicador"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <template v-if="ficha.id">
      <component :is="componente" :ficha="ficha" :id="ficha.id" @load="load"/>
    </template>

    <template #footer>
        <v-button v-if="$root.getPermission({id: 'PERMITIR_EXCLUIR_INDICADOR_REAL_EXTERNO'})"
                  :run="excluir"
                  :popover="true"
                  position="dropupleft"
                  class="btn btn-outline-danger ml-auto">
          Excluir
        </v-button>
    </template>
  </modal>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness.js";
import Dados from "@/components/IndicadorRealExterno/Detalhes/Dados.vue";
import FormIndicadorRealExterno from "@/components/IndicadorRealExterno/Form/FormIndicadorRealExterno.vue";
import Modal from "@/components/Modal";
import RegistrosPosteriores from "@/components/IndicadorGeneric/Detalhes/RegistrosPosteriores.vue";

export default {

  name: "DetalhesIndicadorRealExterno",
  components: {
    Modal,
    "aba-dados" : Dados,
    "aba-unidades" : RegistrosPosteriores
  },

  props: {
    id : String,
    abaInicial: String
  },

  modal: {
    width: 1000
  },

  async mounted(){
    await this.load();
  },

  computed:{
    titulo(){
      return 'Indicador Real Externo: ' + IndicadorRealExternoBusiness.nomearFicha(this.ficha);
    },
    componente(){
      return `aba-${this.aba}`;
    },

    usuario() {
      return this.$root.usuarioLogado;
    },
  },

  data() {

    return {
      ficha : {},
      aba : 'dados',
      abas:[]
    }
  },

  methods:{
    showError(e) {
      FrontBusiness.showError('', e.message);
    },

    async load(){
      this.ficha = await IndicadorRealExternoBusiness.getById(this.id);
      this.abas = [
        {id: 'dados', nome: 'Dados', exibir: true, title: 'Dados Principais'},
        {id: 'unidades', nome: 'Registros Posteriores', exibir: true, title: 'Registros Posteriores'}
      ];
    },

    async editarIndicador(ficha) {
      let result = await FrontBusiness.openModal(FormIndicadorRealExterno, ficha);
      if(result){
        this.$set(this, 'ficha', {});
        await this.load();
      }
    },

    async excluir(){
      let retorno = await IndicadorRealExternoBusiness.delete(this.id).catch(this.showError);
      if (retorno) {
        FrontBusiness.closeModal();
      }
    }
  }
}
</script>

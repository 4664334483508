<template>

  <card titulo="Oficios">
    <card-table class="table-borderless mb-2" v-if="oficios.length > 0">

      <thead>
        <tr>
          <th>Ofício</th>
          <th>Tipo de Ofício</th>
          <th>Início</th>
          <th>Fim</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="oficio in oficios" :key="oficio.id">
          <td>{{titulo(oficio)}}</td>
          <td width="300px">{{oficio.tipoServico}}</td>
          <td width="100px">{{$formatters.date(oficio.cadastro)}}</td>
          <td width="100px">{{$formatters.date(oficio.encerramento)}}</td>
          <td width="40px">
            <router-link :to="{ name: 'oficio', params:{id : oficio.id} }" class="icon" target="_blank">
              <i class="fas fa-external-link-alt"></i>
            </router-link>
          </td>
        </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!oficios.length && !loading" class="alert alert-outline-info" >
      Não há ofícios para exibir
    </div>

  </card>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";
  import OficioBusiness from "@/business/oficio/OficioBusiness";

  export default {
    name: "Ofício",
    components: {SmallLoading, CardTable, Card},
    props: {
      pessoa : Object
    },

    mounted(){
      this.load();
    },

    data() {
      return {
        oficios : [],
        loading: true,
      }
    },

    methods:{
      async load(){
        this.oficios = await OficioBusiness.listarPorEnvolvido(this.pessoa.indicadorPessoalId);
        this.loading = false;
      },

      titulo(oficio){
        return 'Ofício ' + this.tipo(oficio?.tipo) + ' ' + (oficio?.id ? oficio.numero+'/'+oficio.ano :'');
      },

      tipo(tipo){
        return FrontBusiness.getLabel(tipo, OficioBusiness.getTipos());
      },

    }
  }
</script>

<style lang=scss>

</style>

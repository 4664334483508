import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/categoria';

export default {

  /*

   */

  tiposDocumentos: Object.freeze(Utils.orderBy([
    { id: 'DINHEIRO', nome: "Dinheiro"},
    { id: 'CHEQUE', nome: "Cheque"},
    { id: 'BOLETO', nome: "Boleto"},
    { id: 'DEPOSITO', nome: "Depósito"},
    { id: 'RECIBO', nome: "Recibo"},
    { id: 'NOTA_FISCAL', nome: "Nota Fiscal"},
    { id: 'COMPROVANTE', nome: "Comprovante"},
    { id: 'CONTRACHEQUE', nome: "Contracheque"},
    { id: 'CUPOM_FISCAL', nome: "Cupom Fiscal"},
    { id: 'DOCUMENTO_FISCAL', nome: "Documento Fiscal"},
    { id: 'FATURA', nome: "Fatura"},
    { id: 'GUIA', nome: "Guia de Recolhimento"},
    { id: 'CODIGO_IDENTIFICADOR', nome: "Código Identificador"},
  ], 'nome')),

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

}

<template>

    <a href class="mb-1 pointer" @click.prevent="detalhes()">
        {{pessoa.nome}}
        <div class="small text-muted" v-if="mostrarDocumento && pessoa.documento">
            {{pessoa.documento}}
        </div>
    </a>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";

  export default {
        name: "IndicadorPessoalNome",
        props:{
            pessoa: {type: Object},
            mostrarDocumento: {type: Boolean, default: true},
        },
        methods:{
            detalhes() {
                FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: this.pessoa.indicadorPessoalId, versao: this.pessoa.indicadorPessoalVersaoId});
            },
        }
    }
</script>

import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import IndicadorRealSimulacaoBusiness from "@/business/protocolo/simulacao/IndicadorRealSimulacaoBusiness.js";
import RegistroAuxiliarSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroAuxiliarSimulacaoBusiness.js";
import RestricaoBusiness from "@/business/RestricaoBusiness";
import business from "@/views/Administracao/modulos/tipo-servico/business";

export default {

    async simulacaoRestricao(ficha, ato){

        ficha.restricoes = ficha.restricoes || [];

        // cancela restricoes
        if (ato.dto.restricoesCanceladas && (ato.tipoServico.opcoes.cancelarRestricao || []).length > 0) {
            ficha.restricoes = ficha.restricoes.filter(r => !ato.dto.restricoesCanceladas.includes(r.id));
        }

        if (ato.dto.restricoesCanceladasSemRegistrar && ato.dto.restricoesCanceladasSemRegistrar.length > 0 && (ato.tipoServico.opcoes.cancelarRestricao || []).length > 0) {
          let listaRestricoes = [];
          ficha.restricoes.forEach(r => {
            ato.dto.restricoesCanceladasSemRegistrar.forEach(c => {
              let restricoes = r.restricao.split(', ').filter(r => r !== FrontBusiness.getLabel(c.restricao, business.restricoes()));
              if (r.indicadorPessoalVersaoId === c.indicadorPessoalVersaoId) {
                r.restricao = restricoes.join(', ');
              }
            });

            if (r.restricao !== '') {
              listaRestricoes.push(r);
            }

          });

          ficha.restricoes = listaRestricoes;
        }


        // ADICIONA RESTRICOES
        if (ato.tipoServico.opcoes.criarRestricao && ato.tipoServico.opcoes.criarRestricao.length > 0) {
            const labelVencimento = AtoBusiness.descricaoVencimentoRestricao(ato.dto?.configuracaoRestricao?.vencimento);
            (ato.envolvidos || []).forEach(envolvido => {
                if(RestricaoBusiness.papeisGeraRestricao().includes(envolvido.papel)){
                    let descricao = ato.codigo + ' - ' + ato.tipoServico.nome + labelVencimento;
                    const restricoes = ato.dto.configuracaoRestricao?.restricoesSelecionadas.filter(r => RestricaoBusiness.restricoesPorPapel(envolvido.papel).includes(r));
                    let tags = (restricoes || []).map(r => FrontBusiness.getLabel(r, business.restricoes()));
                    if(tags.length > 0) {
                        ficha.restricoes.push({
                            descricao: descricao,
                            indicadorPessoalId: envolvido.indicadorPessoalId,
                            indicadorPessoalVersaoId: envolvido.indicadorPessoalVersaoId,
                            nome: envolvido.indicadorPessoalVersao.nome,
                            papel: envolvido.papel,
                            documento: envolvido.indicadorPessoalVersao.documento,
                            restricao : tags.join(', ')
                        });
                    }
                }
            });
        }

    }


}

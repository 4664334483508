<template>
    <modal titulo="Auditorias" id="modal-auditorias">
      <card>
        <auditoria-pagination :referencia="referencia" :entidade="entidade" :formatters="formatters" />
      </card>
    </modal>
</template>

<script>

import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import TabelaCustaBusiness from "@/business/TabelaCustaBusiness.js";
import Modal from "@/components/Modal";
import Card from "@/components/Layout/components/Card.vue";
import AuditoriaPagination from "@/v2/components/Auditoria/AuditoriaPagination";
import AuditoriaBusiness from "@/business/AuditoriaBusiness";
import EtapaBusiness from "@/business/crud/EtapaBusiness";
import RepasseBusiness from "@/business/crud/RepasseBusiness";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import TipoDocumentoBusiness from "@/business/crud/TipoDocumentoBusiness";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import NaturezaBusiness from "@/business/crud/NaturezaBusiness";
import EnderecoBusiness from "@/business/EnderecoBusiness";
import SiscoafBusiness from "@/business/protocolo/SiscoafBusiness";
import RegraCustasBusiness from "@/business/RegraCustasBusiness";
import VueBusiness from "@/business/VueBusiness";

export default {
    name: "ModalAuditorias",
    components: {Card, Modal, AuditoriaPagination},
    props: {
        referencia : String,
        entidade : String
    },
    modal: {
      width: 1100
    },
    data() {
    return {
      teste: null
    }
  },

    methods: {
      async formatters(auditoria) {

        let formatters = [];

        if(['ATO', 'PROTOCOLO_CUSTA_ADICIONAL', 'PROTOCOLO_PRENOTACAO', 'CERTIDAO'].includes(auditoria.entidade)){
          formatters = [...formatters, ... await AuditoriaBusiness.formattersAtos(auditoria)]
        }

        if(auditoria.entidade == 'PROTOCOLO_REPASSE'){
          formatters = [
            {label: 'Protocolo', key: 'protocolo', formatter: (value) => AuditoriaBusiness.nomearProtocolo(value)},
            {label: 'Etapa', key: 'etapa', formatter: (value) => AuditoriaBusiness.getEntityById(value, EtapaBusiness)},
            {label: "Repasse", key: 'repasse', formatter: (value) => AuditoriaBusiness.getEntityById(value, RepasseBusiness)},
            {label: 'Descrição', key: 'descricao', type: 'string'},
            {label: 'Confirmado', key: 'confirmado', type: 'boolean'},
          ];
        }

        if(auditoria.entidade == 'PROTOCOLO_ANOTACAO'){
          formatters = [
            {label: 'Protocolo', key: 'protocolo', formatter: (value) => AuditoriaBusiness.nomearProtocolo(value)},
            {label: 'Etapa', key: 'etapa', formatter: (value) => AuditoriaBusiness.getEntityById(value, EtapaBusiness)},
            {label: 'Anotação', key: 'anotacao', type: 'string'},
          ];
        }

        if(auditoria.entidade == 'ENVIO_EMAIL'){
          formatters = [
            {label: 'Email', key: 'destino'},
            {label: 'Assunto', key: 'assunto'},
            {label: 'Mensagem', key: 'mensagem', type: 'string'},
          ];
        }

        if(auditoria.entidade == 'LIVRO_PROTOCOLO'){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'integer'},
            {label: 'Data', key: 'dia', type: 'date'},
            {label: "Tipo", key: 'tipo', type: 'enum', lista: ProtocoloBusiness.getTiposOcorrencia()},
            {label: 'Anotação', key: 'anotacao', type: 'string'}
          ];
        }

        if(auditoria.entidade == 'PROTOCOLO_DOCUMENTO'){
          formatters = [
            {label: 'Protocolo', key: 'protocolo', formatter: (value) => AuditoriaBusiness.nomearProtocolo(value)},
            {label: 'Etapa', key: 'etapa', formatter: (value) => AuditoriaBusiness.getEntityById(value, EtapaBusiness)},
            {label: 'Nome', key: 'nome', type: 'string'},
            {label: 'Tipo de Documento', key: 'tipoDocumento', formatter: (value) => AuditoriaBusiness.getEntityById(value, TipoDocumentoBusiness)},

            {label: 'Índices', key: 'indices', type: 'checklist', group: 'Checklist'},
            {label: 'Exigências', key: 'exigencias', type: 'listaHtml', group: 'Exigências'},
          ];
        }

        if(auditoria.entidade == 'PROTOCOLO'){

          let tipo = 'Serviço Principal';
          if(DominioBusiness.isPedidoCertidao(this.protocolo.dominio)){
            tipo = 'Certidão Principal';
          }

          formatters = [

            {label: 'Código', key: 'codigo', type: 'integer'},

            {label: 'Cadastro', key: 'cadastro', type: 'datetime'},
            {label: 'Registro', key: 'registro', type: 'datetime'},
            {label: 'Selado', key: 'selado', type: 'datetime'},
            {label: 'Vencimento', key: 'vencimento', type: 'date'},

            {label: "Tipo de Registro", key: 'dto.camposAdicionais.TIPO_REGISTRO', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro()},
            {label: "Tipo de Registro", key: 'tipoRegistro', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro()},
            {label: 'Tipo Registro', key: 'tipoRegistro', type: 'enum', lista: TabelaCustaBusiness.listTiposRegistro(), group: 'Emolumentos & Base de Cálculo'},
            {label: 'Isento', key: 'isento', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},
            {label: 'Custa Diferida', key: 'diferido', type: 'boolean', group: 'Emolumentos & Base de Cálculo'},
            {label: 'Motivo Diferimento', key: 'motivoDiferimento', type: 'enum', group: 'Emolumentos & Base de Cálculo', lista: RegraCustasBusiness.getMotivosIsencao(VueBusiness.getVueRoot().config.estado),},

            {label: tipo, key: 'tipoServico', formatter: (value) => AuditoriaBusiness.getEntityById(value, TipoServicoBusiness)},
            {label: "Natureza", key: 'natureza', formatter: (value) => AuditoriaBusiness.getEntityById(value, NaturezaBusiness)},

            {label: 'Prioridade', key: 'prioridade', type: 'enum', lista: ProtocoloBusiness.getPrioridades()},
            {label: 'Tipo de Suspensão', key: 'cancelamentoAutomatico', type: 'enum', lista: ProtocoloBusiness.getTipoCancelamentoAutomatico()},

            {label: "Solicitante", key: 'solicitante', formatter: (value) => AuditoriaBusiness.indicadorPessoal(value)},
            {label: "Interessado", key: 'interessado', formatter: (value) => AuditoriaBusiness.indicadorPessoal(value)},
            {label: "Tomador", key: 'tomador', formatter: (value) => AuditoriaBusiness.indicadorPessoal(value)},

            {label: 'Índices', key: 'indices', type: 'checklist', group: 'Checklist'},
            {label: 'Exigências', key: 'exigencias', type: 'listaHtml', group: 'Exigências'},
          ];
        }

        if(auditoria.entidade == 'SISCOAF'){
          formatters = [
            {label: 'Início do fato', key: 'inicio', type: 'date'},
            {label: 'Fim do fato', key: 'fim', type: 'date'},
            {label: 'Valor da operação', key: 'valor', type: 'currency'},
            {label: 'Código do recibo', key: 'codigo', type: 'integer'},
            {label: 'Cidade', key: 'cidade'},
            {label: 'Estado', key: 'estado', type: 'enum', lista: EnderecoBusiness.getEstados()},
            {label: 'Envolvidos', key: 'envolvidos', type: 'string', formatter: (value) => AuditoriaBusiness.envolvidosSiscoaf(value)},
            {label: 'Ocorrências', key: 'ocorrencias', type: 'listahtml'},
            {label: 'Informações Adicionais', key: 'descricao', type: 'string'},
            {label: 'Status', key: 'status', type: 'enum', lista: SiscoafBusiness.status()},
          ];
        }

        if(auditoria.entidade == 'ANEXO_PROTOCOLO' || auditoria.entidade == 'ANEXO_PROTOCOLO_DOCUMENTO'){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'string'},
            {label: 'Nome', key: 'nomeAnexo', type: 'string'},
            {label: 'Caminho', key: 'caminho', type: 'string'},
            {label: 'Tags', key: 'tags', type: 'listahtml'},
            {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
          ];
        }

        return formatters;

      }
    }
  }
</script>

<style lang=scss>

</style>

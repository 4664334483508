// import FrontBusiness from "@/business/FrontBusiness.js";
import {api} from "@/plugins/Axios";
import axios from 'axios'

const httpBusiness = api;
export default httpBusiness;

const source = axios.CancelToken;
export {source}

const getBase64Image = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  return reader.result;
};

export {getBase64Image}

<template>
  <div class="form-group">

    <v-input v-model="vincularOrigem" :options="options" @change="validarOrigem()"
             :disabled="(!!ato.registro && !ato.legado) || (ato.legado && !edicao) || !edicao"
             type="radio" class="mb-2" label="Vincular Restrição Anterior" v-if="(restricoes.length || restricoesNaoRegistrado.length)"/>
    <div class="w-100"></div>
    <card titulo="Restrições" v-if="restricoes.length && vincularOrigem">
      <table class="table card-table table-striped table-vcenter">
        <thead>
        <tr>
          <th width="20px"></th>
          <th class="pl-0">Ato</th>
          <th>Pessoa</th>
          <th>Restrições</th>
        </tr>
        </thead>
        <tbody>

        <template v-for="(restricao, index) in restricoes">

          <tr :key="'restricao'+index">

            <td class="text-left">
              <a class="icon" href="" @click.prevent="selecionar(restricao.atoId || restricao.id)">
                <i class="far"
                   :class="{ 'fa-check-circle' : refresh && selecaoCheckbox(restricao.atoId || restricao.id),
                                         'fa-circle' : refresh &&  !selecaoCheckbox(restricao.atoId || restricao.id),
                                          'not-allowed' : ato.registro && edicao && !ato.legado}">
                </i>
              </a>
            </td>

            <td class="pl-0" style="width: 50%;">
              {{descricaoAtoRestricao(restricao.descricaoRestricao)}}
            </td>

            <td>
              <a href class="mb-1">
                {{restricao.nome}}
                <div class="small text-muted" v-if="restricao.documento">
                  {{restricao.documento}}
                </div>
              </a>
            </td>

            <td>
              {{descricaoRestricao(restricao.restricao)}}
            </td>

          </tr>
        </template>
        </tbody>
      </table>
    </card>
    <card titulo="Restrições Não Registradas" v-if="restricoesNaoRegistrado.length && vincularOrigem">
      <table class="table card-table table-striped table-vcenter">
        <thead>
        <tr>
          <th width="20px"></th>
          <th class="pl-0">Ato</th>
          <th>Pessoa</th>
          <th>Restrições</th>
        </tr>
        </thead>
        <tbody>

        <template v-for="(restricao, index) in restricoesNaoRegistrado">

          <tr :key="'restricao'+index">

            <td class="text-left">
              <a class="icon" href="" @click.prevent="selecionar(restricao.atoId || restricao.id)">
                <i class="far"
                   :class="{ 'fa-check-circle' : refresh && selecaoCheckbox(restricao.atoId || restricao.id),
                                         'fa-circle' : refresh &&  !selecaoCheckbox(restricao.atoId || restricao.id),
                                          'not-allowed' : ato.registro && edicao && !ato.legado}">
                </i>
              </a>
            </td>

            <td class="pl-0" style="width: 50%;">
              {{descricaoAtoRestricao(restricao.descricaoRestricao)}}
            </td>

            <td>
              <a href class="mb-1">
                {{restricao.nome}}
                <div class="small text-muted" v-if="restricao.documento">
                  {{restricao.documento}}
                </div>
              </a>
            </td>

            <td>
              {{descricaoRestricao(restricao.restricao)}}
            </td>

          </tr>
        </template>
        </tbody>
      </table>
    </card>
  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import business from "@/views/Administracao/modulos/tipo-servico/business";

export default {
    name: "OrigemRestricao",
    components: {Card},
    props: {
      ato: {},
      edicao: {default: false},
      protocolo: {},
      ficha: {},
    },
    data() {
      return {
        restricoes: [],
        restricoesNaoRegistrado: [],
        refresh: true,
        vincularOrigem: false,
        options: [
          {id: true, nome: "Sim"},
          {id: false, nome: "Não"},
        ]
      }
    },
    watch: {
      async protocolo(newValue) {
        if (newValue) {
          await this.getRestricoes();
        }
      },
      async vincularOrigem(newValue) {
        if (newValue) {
          await this.getRestricoes();
        }
      }
    },
    async beforeMount() {
      await this.getRestricoes();
      if (this.ato.dto.configuracaoRestricao?.atoOrigem) this.vincularOrigem = true;
    },
    methods: {
      getRestricoes: async function () {
        if (!(this.protocolo?.id)) return;
        let atoRestricao = Utils.clone(this.ato?.dto?.restricoes || []);
        atoRestricao.forEach(a => {
          if (!a.atoId) a.atoId = JSON.parse(a.descricaoRestricao).id
        })
        let restricoesNaoRegistrado = [];
        let restricoes = [];
        if (this.ato?.registro) {
          restricoes = atoRestricao;
        } else {
          restricoesNaoRegistrado = this.ato.indicadorReal ? await AtoBusiness.restricoesSemRegistrar(this.protocolo?.id, this.ato?.id, this.ato?.indicadorReal?.id)
            : await AtoBusiness.restricoesSemRegistrarRegistroAuxiliar(this.protocolo?.id, this.ato?.id, this.ato?.registroAuxiliar?.id);
          if (atoRestricao.length) {
            atoRestricao.forEach(r => {
              let x = restricoesNaoRegistrado.find(a => r.restricao === a.restricao && r.indicadorPessoalVersaoId === a.indicadorPessoalVersaoId && r.atoId === a.atoId)
              if (!x) restricoes.push(r);
            })
          }
        }
        let tags = this.ato.tipoServico?.opcoes?.cancelarRestricao;
        this.$set(this, 'restricoesNaoRegistrado', restricoesNaoRegistrado.filter(r => tags.includes(r.restricao)));
        this.$set(this, 'restricoes', restricoes.filter(r => tags.includes(r.restricao)));
      },
      selecaoCheckbox(id) {
        return this.ato.dto.configuracaoRestricao.atoOrigem === id;
      },
      descricaoRestricao(restricao) {
        return FrontBusiness.getLabel(restricao, business.restricoes());
      },
      descricaoAtoRestricao(restricao) {
        return AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(restricao), this.ficha);
      },
      selecionar(atoId) {
        if (this.edicao) {
          if (this.ato.registro && !this.ato.legado) return;
          this.ato.dto.configuracaoRestricao.atoOrigem = atoId;
          this.toggleRefresh();
        }
      },
      toggleRefresh() {
        this.refresh = false;
        this.refresh = true;
      },
      validarOrigem() {
        if (!this.vincularOrigem) this.ato.dto.configuracaoRestricao.atoOrigem = null;
      }
    }
  }
</script>

<template>
  <v-input :class="cssClass" :error="validation" v-model="cepModel" @blur="getCep()" type="cep" :label="label">
    <template #extra-label-slot>
      <i class="fal fa-search pointer ml-1" @click.prevent="buscarCep()"></i>
    </template>
  </v-input>
</template>

<script>
import EnderecoBusiness from "@/business/EnderecoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import ConsultarCep from "@/components/Consultas/ConsultarCep";

export default {
  name: "V-InputCep",

  props:{
    validation: {},
    value: {},
    label: {type: String, default: 'CEP'},
    cssClass: {type: String, default: 'col-2 pr-1'},
  },

  data(){

    return{
      cepConsultado: '',
      loading: false,
      cepModel: null,
    }

  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val !== this.cepModel) {
          this.cepModel = val;
        }
      }
    },
    cepModel: {
      immediate: true,
      handler(val) {
        if (val !== this.value) {
          this.$emit('input', this.cepModel);
        }
      }
    },
  },

  mounted() {
    if(this.value) this.cepConsultado = this.value;
    this.$set(this, 'cepModel', this.value);
  },

  methods:{

    async getCep() {
      if ((this.cepConsultado && this.cepModel && this.cepConsultado?.replace(/[^\d]/g, '') !== this.cepModel?.replace(/[^\d]/g, '')) || !this.cepConsultado) {
        this.cepConsultado = this.cepModel;
        this.loading = true;
        const endereco = await EnderecoBusiness.getCep(this.cepModel);
        this.$nextTick(() => {
          endereco.cep = this.cepModel;
          this.emitirEndereco(endereco);
          this.loading = false;
        });
      }
    },

    async buscarCep() {
      let endereco = await FrontBusiness.openConsulta(ConsultarCep);
      if (endereco) {
        this.cepConsultado = endereco.cep;
        await this.emitirEndereco(endereco);
      }
    },

    async emitirEndereco(endereco){
      this.$emit('endereco', endereco);
    }

  }

}
</script>

<template>
  <modal titulo="Calcular Porcentagem">

    <div class="alert alert-outline-danger mb-2" v-if="!ficha.area">
      Área do indicador real não informada.
    </div>

    <p class="text-right mb-2" v-if="ficha.area">
      <span class="font-weight-500">Área total: </span><span>{{$formatters.currency(ficha.area,{min : 2, max : 10})}} {{unidadeArea}}</span>
    </p>

    <card :titulo="envolvidos.length ? 'Transmitentes' : 'Proprietários'" v-if="transmitentes.length">
      <template #acoes>
        <li class="list-inline-item">
          <a @click.prevent="selecionarTodos('transmitentes', false)" title="Selecionar Todos" class="icon">
            <i class="fal fa-check-circle"></i>
          </a>
        </li>
        <li class="list-inline-item">
          <a @click.prevent="selecionarTodos('transmitentes', true)" title="Limpar Seleção" class="icon">
            <i class="fal fa-circle"></i>
          </a>
        </li>
      </template>
      <card-table>
        <thead>
        <tr>
          <th width="26px"></th>
          <th>Pessoa</th>
          <th width="150px">Papel</th>
          <th width="100px" class="text-center">{{ unidadeArea }}</th>
          <th width="100px" class="text-center">%</th>
        </tr>
        </thead>

        <tbody>
        <template  v-for="(transmitente, i) in transmitentes" >
          <tr :key="`${i}-${transmitente.indicadorPessoalId}`">
            <td width="26px" class="text-center">
              <input class="form-check-input ml-1" @click="selecionar(transmitente)"
                     style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="transmitentes[i].selecionado">
            </td>
            <td>
              <div>
                {{transmitente.indicadorPessoalVersao.nome}} {{transmitente.indicadorPessoalVersao.versao ? `(Versão: ${transmitente.indicadorPessoalVersao.versao})` : ''}}
              </div>
              <div class="small text-muted">
                {{transmitente.indicadorPessoalVersao.documento}}
              </div>
            </td>
            <td>
              {{papel(transmitente.papel)}}
            </td>

            <td class="text-center">

              <v-input v-model="transmitente.areaCalculado" type="currency" placeholder="Área"
                       :input-props="{precision : {min : 2, max : 10}}"/>
            </td>

            <td class="text-center">

              <v-input v-model="transmitente.calculado" type="currency" placeholder="Fração"
                       :input-props="{precision : {min : 2, max : 10}}"/>
            </td>

          </tr>
        </template>

        <tr>
          <td class="text-right" colspan="3"> Total </td>

          <td class="text-center"> {{$formatters.currency(totalAreaTransmitentes,5, '-')}} </td>

          <td class="text-center"> {{$formatters.currency(totalFracoesTransmitentes, form.casasDecimais, '-')}} </td>

        </tr>

        </tbody>

      </card-table>

    </card>

    <card titulo="Adquirentes" v-if="adquirentes.length">
      <template #acoes>
        <li class="list-inline-item">
          <a @click.prevent="selecionarTodos('adquirentes', false)" title="Selecionar Todos" class="icon">
            <i class="fal fa-check-circle"></i>
          </a>
        </li>
        <li class="list-inline-item">
          <a @click.prevent="selecionarTodos('adquirentes', true)" title="Limpar Seleção" class="icon">
            <i class="fal fa-circle"></i>
          </a>
        </li>
      </template>

      <card-table>
        <thead>
        <tr>
          <th width="26px"></th>
          <th>Pessoa</th>
          <th width="150px">Papel</th>
          <th width="100px" class="text-center">{{ unidadeArea }}</th>
          <th width="100px" class="text-center">%</th>
        </tr>
        </thead>

        <tbody>
        <template  v-for="(adquirentes, i) in adquirentes" >
          <tr :key="`${i}-${adquirentes.indicadorPessoalId}`">
            <td width="26px" class="text-center">
              <input class="form-check-input ml-1" @click="selecionar(adquirentes)"
                     style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="adquirentes.selecionado">
            </td>
            <td>
              <div>
                {{adquirentes.indicadorPessoalVersao.nome}} {{adquirentes.indicadorPessoalVersao.versao ? `(Versão: ${adquirentes.indicadorPessoalVersao.versao})` : ''}}
              </div>
              <div class="small text-muted">
                {{adquirentes.indicadorPessoalVersao.documento}}
              </div>
            </td>
            <td>
              {{papel(adquirentes.papel)}}
            </td>

            <td class="text-center">

              <v-input v-model="adquirentes.areaCalculado" type="currency" placeholder="Área"
                       :input-props="{precision : {min : 2, max : 10}}"/>
            </td>

            <td class="text-center">

              <v-input v-model="adquirentes.calculado" type="currency" placeholder="Fração"
                       :input-props="{precision : {min : 2, max : 10}}"/>
            </td>

          </tr>
        </template>

        <tr>
          <td class="text-right" colspan="3"> Total </td>

          <td class="text-center"> {{$formatters.currency(totalAreaAdquirentes,5, '-')}} </td>

          <td class="text-center"> {{$formatters.currency(totalFracoesAdquirentes,form.casasDecimais, '-')}} </td>

        </tr>

        </tbody>

      </card-table>

    </card>



    <card titulo="Opções para Cálculo">
      <label class="w-75">Casas Decimais</label>
      <select class="form-control form-control-xs" v-model="form.casasDecimais">
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
    </card>



    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>

      <v-button :run="calcular" :popover="false" class="btn-outline-primary mr-2">Calcular</v-button>
      <v-button :run="utilizar" :popover="true" class="btn-outline-primary">Utilizar</v-button>
    </template>
  </modal>
</template>

<script>

import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils";

export default {

  name: "CalcularPorcentagem",
  components: {CardTable, Card, Modal},
  props: {
    envolvidos: {type: Array, default: () => []},
    proprietarios: {type: Array, default: () => []},
    ficha : Object,
  },

  async mounted(){
    this.original = Utils.clone(this.envolvidos.length ? this.envolvidos : this.proprietarios);
    this.envolvidos.forEach(e => {
      e.selecionado = true;
      e.calculado = e.fracao;
      e.areaCalculado = e.area;
    })
    this.proprietarios.forEach(e => {
      e.selecionado = true;
      e.calculado = e.fracao;
      e.areaCalculado = e.area;
    })
    this.$set(this, 'transmitentes', this.envolvidos.length ? this.envolvidos.filter(e => ['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(e.papel)) : this.proprietarios);
    this.$set(this, 'adquirentes', this.envolvidos.filter(e => ['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR'].includes(e.papel)) || []);
  },

  computed:{

    totalAreaTransmitentes() {
      return this.transmitentes.reduce((total, e) => total + (e.areaCalculado ? parseFloat(e.areaCalculado) : 0), 0);
    },

    totalFracoesTransmitentes() {
      return this.transmitentes.reduce((total, e) => total + (e.calculado ? parseFloat(e.calculado) : 0), 0);
    },

    totalAreaAdquirentes() {
      return this.adquirentes.reduce((total, e) => total + (e.areaCalculado ? parseFloat(e.areaCalculado) : 0), 0);
    },

    totalFracoesAdquirentes() {
      return this.adquirentes.reduce((total, e) => total + (e.calculado ? parseFloat(e.calculado) : 0), 0);
    },

    unidadeArea(){
      return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
    }

  },

  data() {
    return {
      form : {
        casasDecimais: 2
      },
      transmitentes: [],
      adquirentes: [],
      original: [],
    }
  },

  methods:{

    papel(papel){
      return FrontBusiness.getLabel(papel, IndicadorRealBusiness.getPapeisEnvolvidos());
    },

    selecionarTodos(lista, limpar) {
      let envolvidos = Utils.clone(this[lista]);
      this.$nextTick(async () => {
        envolvidos.forEach(e => {
          e.selecionado = !limpar;
        })
      })
      this.$set(this, lista, envolvidos)
    },

    selecionar(lista) {
      this.$set(lista, 'selecionado', !lista.selecionado)
    },

    calcular() {
      let transmitentes = Utils.clone(this.transmitentes.map(e => {
        e.calculado = parseFloat(((e.areaCalculado || 0) * 100 / this.ficha.area).toFixed(this.form.casasDecimais));
        return e
      }))
      let adquirentes = Utils.clone(this.adquirentes.map(e => {
        e.calculado = parseFloat(((e.areaCalculado || 0) * 100 / this.ficha.area).toFixed(this.form.casasDecimais));
        return e
      }))
      this.$set(this, 'transmitentes', transmitentes)
      this.$set(this, 'adquirentes', adquirentes)
    },

    utilizar(){

      let novos = [...this.transmitentes, ...this.adquirentes];

      this.original.forEach(e => {
        let envolvido = novos.find(env => env.ordem == e.ordem && env.indicadorPessoalId == e.indicadorPessoalId);
        // console.debug(e, envolvido);
        // delete e.area;
        if (envolvido.selecionado) {
          e.fracao = envolvido.calculado;
          e.area = envolvido.areaCalculado;
        }
      })
      FrontBusiness.closeModal(this.original);
    },

    close: FrontBusiness.closeModal
  }

}
</script>

<style lang=scss>

</style>

<template>
  <modal titulo="Consultas - Dicas de Utilização" id="ajuda-consulta">
    <card titulo="Buscas Textuais">
      <card-content>

        <p>Nos campos de busca textual, utilize o % para realizar buscas parciais. Veja alguns exemplos: </p>

        <p><b>Busca em nomes</b></p>

        <ul class="mt-2">
          <li>Pesquisar pelo primeiro nome: "José %". Essa busca retornará resultados que iniciem com o nome "José", como "José da Silva".</li>
          <li>No caso de desconhecer o nome do meio: "José%Silva". Essa busca irá retornar tanto "José Silva", como "José da Silva".</li>
          <li>Caso não tenha certeza se o nome foi escrito certo, pode-se trocar a parte com dúvida pelo %: "José %idt", que retornaria "José Schmidt",
            ou, ao pesquisar por "Jona%an ", é possível encontrar tanto "Jonathan" como "Jonatan"</li>
        </ul>

        <div class="alert alert-danger mt-2">
          <p><b>ATENÇÃO</b>: Ao não usar o %, o sistema irá realizar uma "busca exata" com o termo procurado.</p>
        </div>

      </card-content>
    </card>
  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";

export default {
    name: "AjudaConsultas",
    components: {CardContent, Card, Modal},

    props: {
      modulo: String
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

    }

  }
</script>

<style lang=scss>
  #ajuda-consulta {

    ol {
      padding-inline-start: 20px;
      li {
        margin-bottom: 10px;
      }

      &.exigencia-legada{
        &, *{
          color: white !important;
        }
      }

    }

  }
</style>

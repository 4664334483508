<template>
  <consulta ref="modal" :columns="columns" :process="process" id="consultar-indicador-real"
            :titulo="`Consultar ${title}`" :grabbable="true" :mlAuto="!marcados.length" :filtrosValidos="filtros.registroAnteriorValido && !pesquisando"
            :exportar="exportar"
            :custom-actions="actions">
    <template #cabecalho>

      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>

    </template>

    <template #filtros>

      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">
          <template v-if="item.id == 'serventia_registro_anterior'">
            <v-input type="select"
                     :options="options.opcaoServentiaRegistro"
                     class="col"
                     v-model="filtros.serventiaRegistroAnterior.tipo"/>
            <template v-if="filtros.serventiaRegistroAnterior.tipo === 'EXTERNO'">
                <v-input type="select"
                         :options="options.tiposTexto"
                         class="col-auto"
                         v-model="filtros.serventiaRegistroAnterior.type"/>

                <div class="col-8">
                    <v-input type="text" class="mb-0" v-model="filtros.serventiaRegistroAnterior.value" />
                    <div class="text-muted small">
                        <b>DICA:</b> Utilize o símbolo <b>%</b> para realizar buscas parciais.
                        <a href @click.prevent="dicasBusca('texto')">Clique aqui para alguns exemplos.</a>
                    </div>
                </div>
            </template>
          </template>
          <template v-if="item.id == 'registro_anterior'">
            <v-input v-model="filtros.registroAnterior.tipo"
                     type="select"
                     :options="options.tipoStatus"
                     :required="true"
                     placeholder="Tipo"
                     @change="tipoRaChange" class="col-3" />
            <v-input type="select"
                     v-if="isMatriculaExterna"
                     :options="livrosExternos"
                     class="col-auto"
                     v-model="filtros.registroAnterior.tipoExterno"/>
            <v-input v-if="isTexto"
                     type="select"
                     :options="options.tiposTexto"
                     v-model="filtros.registroAnterior.filtroTxt"/>

            <v-input :error="validation.registroAnterior"
                     type="text"
                     v-model="filtros.registroAnterior.registro"
                     class="col"
                     @blur="validarRegistroAnterior()"
                     :placeholder="placeholder" />
            <template v-if="isTranscricao">
              <v-input class="col-2" v-model="filtros.registroAnterior.livroNumero" type="text" placeholder="Livro (Número)" />
              <v-input class="col-2" v-model="filtros.registroAnterior.livroLetra" type="text" placeholder="Livro (Letra)" />
              <v-input class="col-2" v-model="filtros.registroAnterior.livroFolha" type="text" placeholder="Folha" />
            </template>
          </template>

          <template v-if="item.id === 'registro_auxiliar_id'">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="registro_auxiliar" label="Registros Auxiliares Relacionados" :livro="livroSelecionado" :status="statusSelecionado" :full="true"
                           ref="buscarFichas" @selecionar="selecionarRegistro('registro_auxiliar', ...arguments)" :multiple="false" class="pl-2 col"/>
          </template>
        </template>
      </filtrar-consulta>

      <template v-if="mode == 'default'">

        <v-input type="text" :label="title" v-model="filtros.codigo" class="col-3" />
        <template v-if="livro == 'TRANSCRICAO' || unificado">
          <v-input class="col" v-model="filtros.livroNumero" type="text" label="Livro (Número)" />
          <v-input class="col" v-model="filtros.livroLetra" type="text"  label="Livro (Letra)" />
          <v-input class="col" v-model="filtros.livroFolha" type="text"  label="Folha" />
        </template>
        <v-input v-model="filtros.tipoImovel" :options="options.tipos" type="select" class="col-auto" label="Tipo de Imóvel" />

        <div class="col-auto px-5">

          <v-input v-model="filtros.categoria" :options="options.categorias" type="radio" class="mb-2" />

          <div class="w-100"></div>

          <div class="form-group mb-0">
            <input style="position: relative; top: 3px; left: 0px;" type="checkbox" id="FLAG_UNIDADE_AUTONOMA"
                   v-model="filtros.unidadeAutonoma" />
            <label for="FLAG_UNIDADE_AUTONOMA" style="position: relative; top: 0px; left:4px;" class="pointer">
              Unidade Autônoma?
            </label>
          </div>
        </div>

        <template v-if="status !== 'PENDENTE'">
          <v-input v-model="filtros.digitalizacao" :options="options.tiposDigitalizacao" type="select" label="Digitalização" class="col-2" />
        </template>

        <template v-if="status !== 'PENDENTE'">
          <div class="w-100"></div>
          <v-input type="text" label="Minuta" v-model="filtros.minuta" class="col"/>
          <v-input type="date" label="Data Registro (Início) " v-model="filtros.registroInicio" class="col-2" />
          <v-input type="date" label="Data Registro (Fim)" v-model="filtros.registroFim" class="col-2" />
        </template>

        <div class="w-100"></div>
        <v-input type="text" label="Especificação" v-model="filtros.especificacao" class="col"/>


        <div class="w-100"></div>
        <v-input type="text" label="Serventia Registro Anterior" v-model="filtros.serventia" class="col"/>
        <div class="w-100"></div>
        <v-input label="Registro Anterior"
                 v-model="filtros.registroAnterior.tipo"
                 type="select"
                 :options="options.tipoStatus"
                 :required="true"
                 placeholder="Tipo"
                 @change="tipoRaChange" class="col-3" />
        <v-input type="select"
                 v-if="isMatriculaExterna"
                 label="Tipo Indicador"
                 :options="livrosExternos"
                 class="col-auto"
                 v-model="filtros.registroAnterior.tipoExterno"/>
        <v-input v-if="isTexto"
                 type="select"
                 label="Tipo de busca"
                 :options="options.tiposTexto"
                 v-model="filtros.registroAnterior.filtroTxt"/>

        <v-input :error="validation.registroAnterior"
                 type="text"
                 v-model="filtros.registroAnterior.registro"
                 label="Registro"
                 class="col"
                 @blur="validarRegistroAnterior()"
                 :placeholder="placeholder" />
        <template v-if="isTranscricao">
          <v-input class="col-2" v-model="filtros.registroAnterior.livroNumero" type="text" label="Livro (Número)" />
          <v-input class="col-2" v-model="filtros.registroAnterior.livroLetra" type="text" label="Livro (Letra)" />
          <v-input class="col-2" v-model="filtros.registroAnterior.livroFolha" type="text" label="Folha" />
        </template>

        <card class="card-full mt-2" titulo="Registros Auxiliares Relacionados">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="registro_auxiliar" label="Registros Auxiliares Relacionados" :livro="livroSelecionado" :status="statusSelecionado" :full="true"
                           ref="buscarFichas" @selecionar="selecionarRegistro('registro_auxiliar', ...arguments)" :multiple="false" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full" titulo="Proprietários atuais" key="proprietarios-atuais" chave="proprietarios-atuais">
          <card-content class="row gutters-xs">
            <div class="w-100"></div>
            <v-input type="select" label="Tipo de busca do CPF/CNPJ" :options="options.tiposBusca" v-model="filtros.tipoBuscaProprietarioDocumento" class="col-3" />
            <v-input type="text" label="CPF/CNPJ" v-model="filtros.proprietarioDocumento" class="col-3" />
            <v-input type="text" label="Nome" v-model="filtros.proprietarioNome" class="col" />
          </card-content>
        </card>

        <card class="card-full" titulo="Informações complementares" key="informacoes-complementares" chave="informacoes-complementares">

          <card-content class="row gutters-xs">

            <div class="w-100"></div>
            <template v-if="filtros.unidadeAutonoma">
              <v-input class="col" v-model="filtros.unidade" type="text" label="Número da unidade" />
              <v-input class="col" v-model="filtros.andar" type="text" label="Andar" />
              <v-input class="col" v-model="filtros.bloco" type="text" label="Bloco" />
              <v-input class="col" v-model="filtros.pavimento" type="text" label="Pavimento" />
            </template>
            <div class="w-100"></div>

            <v-input class="col" v-model="filtros.quadra" type="text" label="Quadra" v-if="filtros.categoria !== 'RURAL'"/>
            <v-input class="col" v-model="filtros.lote" type="text" label="Lote" />

            <div class="w-100"></div>

            <v-input class="col" label="Localização" v-model="filtros.localizacao" type="text"/>
            <v-input class="col" label="Empreendimento/Condomínio" v-model="filtros.empreendimento" type="text" />

          </card-content>
        </card>
        <card class="card-full" titulo="Área" key="area" chave="area">
          <card-content class="row gutters-xs">
            <v-input type="currency" v-model="filtros.area" class="col-4" />
          </card-content>
        </card>

        <card class="card-full" titulo="Identificação" key="identificacao" chave="identificacao">
          <card-content class="row gutters-xs">
            <div class="w-100"></div>
            <template v-if="filtros.categoria == 'RURAL'">
              <v-input class="col-3" v-model="filtros.incra" type="text" label="INCRA (CCIR)" />
              <v-input class="col-3" v-model="filtros.itr" type="text" label="ITR" />
              <v-input class="col-3" v-model="filtros.car" type="text" label="CAR" />
            </template>
            <v-input class="col-3" v-model="filtros.cadastroImobiliario" type="text" label="Cadastro Imobiliário" />
          </card-content>
        </card>

        <card class="card-full" titulo="Endereço" key="endereco" chave="endereco">
          <card-content class="row gutters-xs">
            <div class="w-100"></div>
            <v-input class="col-3" v-model="filtros.cep" type="cep" label="CEP" />
            <v-input class="col-5" v-model="filtros.logradouro" type="text" label="Logradouro (rua)" />
            <v-input class="col-4" v-model="filtros.numero" type="text" label="Número" />
            <div class="w-100"></div>
            <v-input class="col-4" v-model="filtros.bairro" type="text" label="Bairro (setor)" />
            <v-input class="col-4" v-model="filtros.cidade" type="text" label="Cidade" />
            <v-input class="col-4" v-model="filtros.estado" :options="options.estados" type="select" label="Estado" />
            <v-input class="col-12" v-model="filtros.complemento" type="text" label="Complemento" />
          </card-content>
        </card>

      </template>

    </template>

    <template #row="{ row }">
      <!--      <tr @click="select(row)" class="pointer">-->
      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectIndicador(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <a href class="icon" @click.prevent.stop="detalhes(row.id)">
            <i class="fas fa-home"></i>
          </a>
        </td>

        <td width="140px" class="nowrap">
          <a class="pointer icon mr-2" @click="select(row)">
            {{row.livroTranscricao ? `${row.livroTranscricao}-` : ''}}{{ row.codigo | inteiro }}{{row.livroLetra ? `-${row.livroLetra}` : ''}}
          </a>
          <alert-ocorrencia :imovel="row.id" />
          <alert-indisponibilidade :imovel="row.id" />
        </td>

        <template v-if="unificado">
          <td>{{row.tipo}}</td>
        </template>

        <template v-if="livro == 'TRANSCRICAO' || unificado">
          <td>{{ row.livroNumero }} {{ row.livroLetra }}</td>
          <td>{{ row.livroFolha }}</td>
        </template>

        <template v-if="mostrarColunaCadastroImobiliario">
          <td><div style="max-width: 300px" class="small">{{row.cadastroImobiliario}}</div></td>
        </template>

        <td>{{ row.unidade }}</td>
        <td>{{ row.bloco }}</td>
        <td>{{ row.localizacao }}</td>
        <td>{{ row.empreendimento }}</td>
        <td>{{ row.lote }}</td>
        <td>{{ row.quadra }}</td>
        <td>{{ row.logradouro }}</td>
        <td>{{ row.numero }}</td>
        <td>{{ row.complemento }}</td>
        <td>{{ row.bairro }}</td>
        <td>{{ row.cidade }}</td>
        <td>{{ $formatters.currency(row.area,{min : 2, max : 10})}} {{unidadeArea(row)}}</td>
        <template v-if="status !== 'PENDENTE'">
          <td>{{ row.abertura | datahora }}</td>
          <td>{{ row.encerramento | datahora }}</td>
        </template>

        <td width="120px" class="text-capitalize">{{ row.status.toLowerCase() }}</td>

      </tr>

    </template>

    <template #footer v-if="!usuario.auditor">

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="select" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness.js";
import EnderecoBusiness from "@/business/EnderecoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import Utils from "@/commons/Utils.js";
import AlertMessage from "@/components/alert/AlertConstMessage.js";
import AlertIcon from "@/components/alert/AlertIcon.vue";
import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";
import AlertOcorrencia from "@/components/alert/AlertOcorrencia.vue";
import Consulta from "@/components/Consultas/Consulta.vue";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
import FormIndicadorReal from "@/components/IndicadorReal/Form/FormIndicadorReal.vue";
import ModalEditarLote from "@/components/IndicadorReal/ModalEditarLote.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import DigitalizarFichaLote from "@/components/Livros/DigitalizarFichaLote.vue";
import ImprimirFichaLote from "@/components/Livros/ImprimirFichaLote.vue";
import ModalFichaAntiga from "@/components/ModalFichaAntiga.vue";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";
import ConsultarIndicadorReal from "@/components/IndicadorReal/ConsultarIndicadorReal.vue";
import AjudaConsultas from "@/components/Consultas/AjudaConsultas.vue";
import ModalGerarImagemAtoDigital from "@/views/Digitalizacao/modais/ModalGerarImagemAtoDigital.vue";

export default {
  name: "ConsultarIndicadorReal",
  components: {FiltrarConsulta, AlertIndisponibilidade, AlertOcorrencia, Consulta, Card, CardContent},
  props: {
    multiplos : {type : Boolean, default : false},
    seleciona : {type : Boolean, default : true},
    livro : String,
    status : String, // PENTENDE, ABERTA, NULL
    unificado: {type: Boolean, default: false}
  },
  modal: {
    width: 1750,
  },
  data() {
    return {
      buscar: 'REGISTRO_AUXILIAR',
      registro_auxiliar: null,
      mode: 'novo',
      opcoesConsulta: IndicadorRealBusiness.camposConsulta().filter(i => {
        return this.unificado ? i.unificado : ((!i.livro || i.livro == this.livro) && (!i.status || (i.status && !this.status) || (this.status && i.status && (i.status).includes(this.status))))}),
      validation: {},
      filtros : {
        filtros: [],
        livro: this.livro,
        status: this.status,
        localizacao: '',
        proprietario: null,
        registroAnterior: {
          tipo: 'MATRICULA',
          tipoExterno: 'MATRICULA',
          filtroTxt: 'contains',
          registro: null,
          pesquisa: [],
        },
        serventiaRegistroAnterior: {
          tipo: 'INTERNO',
          type: 'contains',
          value: null,
        },
        livroLetra: null,
        livroNumero: null,
        livroFolha: null,
        digitalizacao: '',
        registro: null,
        tipoBuscaProprietarioDocumento: 'contains'
      },
      options : {
        tiposPessoa: IndicadorPessoalBusiness.getTiposPessoa(),
        tipos: IndicadorRealBusiness.getTiposImovel(),
        categorias: IndicadorRealBusiness.getCategorias(),
        estados: EnderecoBusiness.getEstados(),
        tipoStatus: IndicadorRealBusiness.getTiposOrigens().filter(t => t.id !== 'TORRENS'),
        tiposAto: [],
        tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
        tiposTexto: FrontBusiness.tiposBuscaFiltro('text'),
        tiposDigitalizacao: AnexoBusiness.tiposDigitalizacao(),
        opcaoServentiaRegistro: [
          {id: 'INTERNO', nome: 'Esta Serventia'},
          {id: 'EXTERNO', nome: 'Outra Serventia'},
        ]
      },
      marcados: [],
      pesquisando: false,
      listas:{
        livros: [
          {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
          {id: 'PRE_REGISTRO_AUXILIAR', nome: 'Pré-Registro Auxiliar'}
        ]
      }
    }
  },

  watch: {
    'filtros.tipoRegistroAnterior'() {
      this.$set(this.filtros, 'registroAnterior', [])
      this.$set(this.filtros, 'registroAnteriorText', null)
      this.$set(this.filtros, 'registroAnteriorLivroNumero', null)
      this.$set(this.filtros, 'registroAnteriorLivroLetra', null)
      this.$set(this.filtros, 'registroAnteriorLivroFolha', null)
      this.$set(this.filtros, 'registroAnteriorValido', true)
      this.$refs.buscarFichas.limparCampos();
    }
  },

  computed:{

    livroSelecionado() {
      return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'REGISTRO_AUXILIAR' : this.buscar;
    },

    statusSelecionado() {
      return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'PENDENTE' : 'ABERTA';
    },

    estado(){
      return this.$root.config.estado;
    },
    title(){
      if(this.unificado){
        return 'Indicador Real'
      }

      return FrontBusiness.nomearFicha({livro : this.livro, status : this.status}, false);
    },

    hasFiltros() {
      return this.$refs?.modal?.hasFiltros;
    },

    initRegistroAnterior() {
      let registroAnterior = [];
      this.filtros.registroAnterior.forEach(r => {
        let letra = !this.isMatriculaExterna ? r?.livroLetra || '' : '';
        return  r?.codigo ? registroAnterior.push(r?.codigo + letra) : null
      })
      return registroAnterior.toString()
    },

    tipoRegistroAnterior() {
      return this.options?.tipoStatus?.find(e => e.id === this.filtros.tipoRegistroAnterior);
    },

    isMatriculaExterna(){
      let livro = this.filtros.registroAnterior.tipo || 'MATRICULA';
      return ['MATRICULA_EXTERNA'].includes(livro);
    },

    isMatricula() {
      let livro = this.filtros.registroAnterior.tipo || 'MATRICULA';
      return ['MATRICULA', 'PRE_MATRICULA', 'INSCRICAO'].includes(livro);
    },


    isTexto() {
      let livro = this.filtros.registroAnterior.tipo || 'MATRICULA';
      return ['TEXTO', 'LEGADO'].includes(livro);
    },

    isTranscricao() {
      let livro = this.filtros.registroAnterior.tipo || 'MATRICULA';
      return livro === 'TRANSCRICAO' || (this.isMatriculaExterna && this.filtros.registroAnterior.tipoExterno === 'TRANSCRICAO');
    },

    mostrarColunaCadastroImobiliario() {
      return this.mode == 'novo' && this.filtros?.filtros?.find(e => ["cadastro_imobiliario", 'cadastro_imobiliario_num'].includes(e.id));
    },

    columns(){

      let colunas = [
        {label: '', show: this.multiplos, field: 'selecionar', headerClass: 'column__botao', sortable: false, name: 'selecionar'},
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        {label:  this.title, field: 'i.codigo', headerClass: 'column__codigo_doc', name: 'codigo', minWidth:'130px'}
      ];

      if(this.unificado){
        colunas.push({label: 'Tipo', field: 'i.livro', headerClass: 'column__tipo_doc', name: 'tipoRegistro'});
      }
      if(this.livro == 'TRANSCRICAO' || this.unificado){
        colunas.push({label: 'Livro', field: 'i.livro_numero', headerClass: 'column__tipo_doc', name: 'livroNumero'});
        colunas.push({label: 'Folha', field: 'i.livro_folha', headerClass: 'column__tipo_doc', name: 'livroFolha'});
      }

      if(this.mostrarColunaCadastroImobiliario){
        colunas.push({label: 'Cadastro Imobiliário', field: 'i.cadastro_imobiliario', headerClass: 'column__ci', minWidth:'300px'});
      }

      colunas.push({label: 'Unidade', field: 'i.unidade', headerClass: 'column__cidade', minWidth:'110px'});
      colunas.push({label: 'Bloco', field: 'i.bloco', headerClass: 'column__cidade'});
      colunas.push({label: 'Localização', field: 'i.localizacao', headerClass: 'column__cidade', minWidth:'300px'});
      colunas.push({label: 'Empreendimento', field: 'i.empreendimento', headerClass: 'column__cidade', minWidth:'300px'});
      colunas.push({label: 'Lote', field: 'i.lote', headerClass: 'column__uf'});
      colunas.push({label: 'Quadra', field: 'i.quadra', headerClass: 'column__uf', minWidth:'150px'});
      colunas.push({label: 'Logradouro', field: 'i.logradouro', headerClass: 'column__logradouro', minWidth:'300px'});
      colunas.push({label: 'Número', field: 'i.numero', headerClass: 'column__cidade', minWidth:'110px'});
      colunas.push({label: 'Complemento', field: 'i.complemento', headerClass: 'column__complemento', minWidth:'350px'});
      colunas.push({label: 'Bairro', field: 'i.bairro', headerClass: 'column__bairro', minWidth:'250px'});
      colunas.push({label: 'Cidade', field: 'i.cidade', headerClass: 'column__cidade', minWidth:'250px'});
      colunas.push({label: 'Área', field: 'i.area', name: 'area', minWidth:'130px'});

      if(this.status !== 'PENDENTE'){
        colunas.push({label: 'Abertura', field: 'i.abertura', name: 'abertura', minWidth:'200px'});
        colunas.push({label: 'Encerramento', field: 'i.encerramento', name: 'encerramento', minWidth:'200px'});
      }

      colunas.push({label: 'Situação', field: 'i.status', headerClass: 'column__tipo_doc', name: 'status'});

      return colunas;
    },

    usuario() {
      return this.$root.usuarioLogado;
    },

    livrosExternos(){
      return [
        {id: 'MATRICULA', nome: 'Matrícula'},
        {id: 'TRANSCRICAO', nome: 'Transcrição'},
        {id: 'TORRENS', nome: 'Torrens'},
      ];
    },
    placeholder(){
      return this.isTranscricao ? 'Ex.: 123.123' : (this.isMatricula || this.isMatriculaExterna) ? 'Ex.: 300-320, 123.123, 156123, 156.128-156.136' : '';
    },

    auditor() {
      return this.$root.usuarioLogado.auditor;
    },

    exportar(){
      let forms = {
        direction:"asc",
        sort:"i.codigo, a.codigo, a.registro",
        preMatricula: this.status === 'PENDENTE',
        livro:this.livro,
        unificado:this.unificado,
        filtros:[{id: 'livro', value: this.livro}, ...this.filtros?.filtros].filter(e => e.id)
      };
      return this.mode === 'novo' && !this.auditor ? {id: "EXPORTAR_INDICADOR_REAL", forms, extensao: "XLSX"} : null;
    },

    actions() {
      let actions = [];
      if (this.auditor) return actions;
      if (this.livro === 'MATRICULA') {
        actions.push({
          ordem: 1,
          class: 'at-options',
          icon: 'far fa-file-export mr-1',
          label: 'Exportar - ONR',
          popover: true,
          action: this.exportarONR
        });
        actions.push({
          ordem: 2,
          class: 'at-options',
          icon: 'far fa-tasks mr-1',
          label: 'Confirmar envio - ONR',
          popover: true,
          action: this.confirmarONR
        });
        if (!this.unificado) {
          if (this.status !== 'PENDENTE') {
            actions.push({
              ordem: 4,
              class: 'at-options',
              icon: 'far fa-plus-square mr-1',
              label: 'Cadastrar Matrícula Manualmente',
              action: this.cadastrarAntiga
            });
            actions.push({
              ordem: 5,
              class: 'at-options',
              icon: 'far fa-print mr-1',
              label: 'Impressão em Lote',
              action: this.impressaoLote
            });

            actions.push({
              ordem: 7,
              class: 'at-options',
              icon: 'far fa-file-image mr-1',
              label: 'Digitalização em Lote',
              action: this.digitalizacaoLote
            });
            if (this.$root.getPermission({id: 'GERENCIAR_DIGITALIZACAO', atribuicao: 'RI'}) && this.$root.config.geral.permitirAtoDigital) {
              actions.push({
                ordem: 8,
                class: 'at-options',
                icon: 'far fa-file-invoice mr-1',
                label: 'Gerar imagens de atos digitais em Lote',
                action: this.gerarAtosDigitais
              });
            }
            actions.push({
              ordem: 9,
              class: 'at-options',
              icon: 'far fa-file-search mr-1',
              label: 'Consultar pré-matrícula',
              action: this.consultarPreMatricula
            });
          }
          actions.push({
            ordem: 6,
            class: 'at-options',
            icon: 'far fa-pen-square mr-1',
            label: 'Editar Indicadores em Lote',
            action: this.editarLote
          });
        }
      }

      if (this.livro === 'TRANSCRICAO') {
        if (!this.unificado) {
          actions.push({
            ordem: 3,
            class: 'at-options',
            icon: 'far fa-plus-square mr-1',
            label: 'Nova Transcrição',
            action: this.cadastrar
          });
        }
      }
      return  Utils.orderBy(actions, 'ordem');
    }
  },

  mounted() {
    this.load();
  },

  methods:{

    async load() {
      this.options.tiposAto = await TipoServicoBusiness.getByDominioLivroSimples('PROTOCOLO_RI', this.livro);
    },

    tipoRaChange(){
      this.$set(this.filtros, 'registroAnterior', {
        tipo: this.filtros.registroAnterior.tipo,
        tipoExterno: 'MATRICULA',
        filtroTxt: 'contains',
        registro: null,
        pesquisa: [],
      });
      this.$set(this.validation, 'registroAnterior', null);
    },

    stringCodigosToArray: Utils.stringCodigosLetraToArray,

    close(){
      FrontBusiness.closeModal();
    },

    select(indicador){
      if (this.seleciona) {
        FrontBusiness.closeModal(indicador);
      } else {
        this.detalhes(indicador?.id)
      }
    },

    async process(sort_by, sort_dir, page_length, page_number){

      if(this.mode == 'novo'){
        let filtros = [{id: 'livro', value: this.livro}, ...this.filtros?.filtros].filter(e => e.id);
        let preMatricula = this.status == 'PENDENTE';
        let registro_anterior = filtros.find(e => e.id === 'registro_anterior');
        if (registro_anterior) {
          registro_anterior.value = this.filtros.registroAnterior;
        }
        let serventia_registro_anterior = filtros.find(e => e.id === 'serventia_registro_anterior');

        if (serventia_registro_anterior) {
            if (this.filtros.serventiaRegistroAnterior.tipo === 'INTERNO') {
                serventia_registro_anterior.type = '';
                serventia_registro_anterior.value = true;
            } else {
                serventia_registro_anterior.type = this.filtros.serventiaRegistroAnterior.type;
                serventia_registro_anterior.value = this.filtros.serventiaRegistroAnterior.value;
            }
        }

        let registro_auxiliar_id = filtros.find(e => e.id === 'registro_auxiliar_id');
        if (registro_auxiliar_id) {
          registro_auxiliar_id.value = this.filtros.registro_auxiliar;
        }

        return IndicadorRealBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros, preMatricula, unificado: this.unificado})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
      }

      if(this.validation.registroAnterior) {
        return {quantidade:0, pagina:[]};
      } else {
        let filtros = Utils.clone(this.filtros);
        if (this.livro !== 'TRANSCRICAO' && filtros?.codigo) {
          filtros.codigo = Utils.stringCodigosToArray(filtros?.codigo)
        }
        return IndicadorRealBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros: filtros, unificado: this.unificado})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
      }

    },

    detalhes(id){
      FrontBusiness.openModal(DetalhesIndicadorReal, {id});
    },

    async cadastrar(){
      const indicador = await FrontBusiness.openModal(FormIndicadorReal, {retornarObject: true, livro: this.livro});
      if(indicador){
        FrontBusiness.openModal(DetalhesIndicadorReal, {id: indicador});
      }
    },

    async cadastrarAntiga(){
      const indicador = await FrontBusiness.openModal(ModalFichaAntiga, {livro: this.livro});
      if(indicador){
        FrontBusiness.openModal(DetalhesIndicadorReal, {id: indicador});
      }
    },

    validarRegistroAnterior() {
      if (!['TEXTO', 'LEGADO'].includes(this.filtros.registroAnterior.tipo)) {
        this.$set(this.filtros.registroAnterior, 'pesquisa', this.stringCodigosToArray(this.filtros.registroAnterior.registro));
      } else {
        this.$set(this.filtros.registroAnterior, 'pesquisaTexto', this.filtros.registroAnterior.registro);
      }
    },

    selecionar(item, nomeFiltro){
      this.$nextTick(() => {
        this.filtros[nomeFiltro] = item ? item.indicadorPessoalId : null;
      });
    },

    remover(idx, lista) {
      this.$nextTick(() => {
        this.filtros[lista].splice(idx, 1);
      });
    },

    async pesquisandoFicha(pesquisando, load = false) {
      await this.$set(this, 'pesquisando', pesquisando);
      if (load) await this.$refs?.modal?.load();

    },

    async selecionarRegistroAnterior(fichas, add, campoValidado, mensagemValidacao, livroSelecionado) {
      await this.$set(this.filtros, 'tipoIndicadorRealExterno', livroSelecionado);
      await this.$set(this.filtros, 'registroAnteriorLivroLetra', fichas[0]?.livroLetra || '');
      await this.$set(this.filtros, 'registroAnteriorLivroFolha', fichas[0]?.livroFolha || '');
      await this.$set(this.filtros, 'registroAnteriorLivroNumero', fichas[0]?.livroNumero || '');
      if(fichas.length > 0){
        await this.$set(this.filtros, 'registroAnterior', fichas);
        await this.$set(this.filtros, 'registroAnteriorValido', campoValidado);
      } else if(Utils.isEmptyNullOrUndefined(this.$refs.buscarFichas.str)) {
        await this.$set(this.filtros, 'registroAnterior', []);
        await this.$set(this.filtros, 'registroAnteriorValido', true);
      }
    },

    selectIndicador(ficha) {
      let index = this.marcados.findIndex(e => e.id === ficha.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(ficha)
      }
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    },

    impressaoLote(){
      FrontBusiness.openModal(ImprimirFichaLote, {livro : 'MATRICULA'});
    },

    editarLote(){
      FrontBusiness.openModal(ModalEditarLote);
    },

    gerarAtosDigitais() {
      FrontBusiness.openModal(ModalGerarImagemAtoDigital, {entidade: this.livro, isLote: true});
    },

    digitalizacaoLote(){
      FrontBusiness.openModal(DigitalizarFichaLote, {livro : 'MATRICULA'});
    },

    consultarPreMatricula() {
      FrontBusiness.closeModal();
      FrontBusiness.openModal(ConsultarIndicadorReal, { livro: 'MATRICULA', status : 'PENDENTE', seleciona: false}, 1300, true);
    },

    async exportarONR(){
      let link = `${window.server.API}/api/v2/integracoes/onr/indicador-real/exportar`;
      await FrontBusiness.downloadUrl(link, 'indicador-real.json');
    },

    async confirmarONR(){
      let resultado = await this.$api.get('api/v2/integracoes/onr/indicador-real/confirmar').catch(e => FrontBusiness.showError('', e.message));;
      if(resultado) {
        FrontBusiness.showSuccess('', 'Ação realizada com sucesso');
      }
    },

    unidadeArea(ficha){
      return ficha.medidaArea ? FrontBusiness.getLabel(ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
    },

    selecionarRegistro(nomeFiltro, item, add, x) {
      this.$nextTick(() => {
        nomeFiltro = nomeFiltro || x;
        this.filtros[nomeFiltro] = item[0] ? item[0].id : null;
        this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
        if (nomeFiltro === 'registro_auxiliar') {
          this[nomeFiltro] = item[0];
        } else {
          this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
        }

        if (add && item[0]) {
          this.executeLoad();
        }
      });
    },

    dicasBusca(modulo){
        FrontBusiness.openModal(AjudaConsultas, {modulo})
    },

  }
}
</script>

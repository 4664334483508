import Http from "@/commons/Http";

const path = 'api/legado';

export default {

  async atualizarTipo(tipo, id, tipoServico, principal = false){
    return Http.post(`${path}/atualizar-tipo/${tipo}/${id}/${tipoServico}/${principal}`);
  },

  async converterImagens(livro){
    return Http.get(`${path}/public/converter-imagens/${livro}`);
  },

  async verificarImagens(livro){
    return Http.get(`${path}/public/analisar-imagens/${livro}`);
  },

  async converterTiffs(){
    return Http.get(`${path}/public/converter-tiffs`);
  },

  async verificarTiffs(){
    return Http.get(`${path}/public/analisar-tiffs`);
  },

  async sugestoesImplantacao(){
    return Http.get(`${path}/sugestoes`);
  },

}

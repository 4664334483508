<template>
  <card titulo="Restrições e Direitos Ativos" v-if="ficha.restricoes && ficha.restricoes.length > 0">

    <card-table v-if="simulacao" class="table table-striped">
      <thead>
      <tr>
        <th class="pl-1" width="100px">Ato</th>
        <th class="pl-1" width="100px">Pessoa</th>
        <th class="pl-1" width="100px">Papel</th>
        <th class="pl-1" width="100px">Restrições</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(restricao, index) in ficha.restricoes" :key="'ficha_restricao'+index">
        <td>{{descricaoAtoRestricao(restricao)}}</td>
        <td>
          <indicador-pessoal-nome :pessoa="getPessoa(restricao)"/>
        </td>
        <td>{{nomePapel(restricao.papel)}}</td>
        <td class="pr-2">{{labelRestricao(restricao.restricao)}}</td>
      </tr>
      </tbody>
    </card-table>

    <consulta v-else :columns="columns" ref="tabela" :process="process" minWidth="100%" id="restricoes-direitos-ativos"
              label-lista-vazia="Não há restrições ativas para exibir">

      <template #row="{ row }">

        <tr>
          <td>{{descricaoAtoRestricao(row)}}</td>
          <td>
            <indicador-pessoal-nome :pessoa="getPessoa(row)"/>
          </td>
          <td>{{nomePapel(row.papel)}}</td>
          <td class="pr-2">{{labelRestricao(row.restricao)}}</td>
        </tr>

      </template>
    </consulta>
  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card";
import business from "@/views/Administracao/modulos/tipo-servico/business";
import Consulta from "@/components/Consultas/Consulta";
import RestricaoBusiness from "@/business/RestricaoBusiness";
import CardTable from "@/components/Layout/components/CardTable";
import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business.js";

export default {
  name: "TabelaRestricoesAtivas",
  components: {Consulta, CardTable, Card},
  props:{
    ficha: {type: Object},
    indicadorReal: {type: Boolean, default: false},
    simulacao: {type: Boolean, default: false},
  },

  data() {
    return {
      filtros : {
        id: this.ficha.id,
        entidade: this.indicadorReal ? 'indicadorReal' : 'registroAuxiliar',
      },
    }
  },

  computed: {
    columns() {
      return [
        {label: 'Ato', field: 'a.codigo', minWidth: '100px'},
        {label: 'Pessoa', field: 'ipv.nome', minWidth: '100px'},
        {label: 'Papel', field: 'r.papel', minWidth: '100px'},
        {label: 'Restrições', field: 'r.restricao', width: '130px'},
      ];
    },
  },

  async mounted(){
    await this.$refs?.tabela?.load();
  },

  methods:{

    nomePapel(papel){ return businessTipoServico.nomePapel(papel) },

    descricaoAtoRestricao(restricao) {
      if(restricao.descricaoRestricao){
        return AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(restricao.descricaoRestricao));
      }
      return AtoBusiness.descricaoAtoRestricao(restricao);
    },

    labelRestricao(restricao){
      let label = FrontBusiness.getLabel(restricao, business.restricoes());
      return Utils.isEmptyNullOrUndefined(label) ? restricao : label;
    },

    getPessoa(restricao){
      const pessoa = {
        indicadorPessoalId: restricao.indicadorPessoalId,
        indicadorPessoalVersaoId: restricao.indicadorPessoalVersaoId,
        documento: restricao.documento,
        nome: restricao.nome,
      };
      return pessoa;
    },

    async process(sort_by, sort_dir, page_length, page_number){
      let result = await RestricaoBusiness.buscarRestricao(sort_by, sort_dir, page_number, page_length, {filtros: this.filtros});
      return {rows: result.pagina, total: result.quantidade};
    }
  }
}
</script>

<style lang="scss">
#restricoes-direitos-ativos {
  .modal-body {
    padding: 0;
  }
}
</style>

import Http from "@/commons/Http";

const path = 'api/public/site';

const tratarDocumento = (documento) => {
  return documento?.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '').replaceAll(' ', '');
};

export default {

  setAuth(auth){
    try{
      if(auth){
        return localStorage.setItem('site.auth', auth);
      }else{
        return localStorage.removeItem('site.auth');
      }
    }catch (e){
      console.error(e);
    }
  },

  getAuth(){
    try{
      return localStorage.getItem('site.auth');
    }catch (e){
      console.error(e);
      return null;
    }
  },

  async getConfiguracaoAcompanhamentoOnline() {
    return Http.get(`${path}/configuracao/acompanhamento-online`);
  },

  async getConfiguracaoPedidoOnline() {
    return Http.get(`${path}/configuracao/pedido-online`);
  },

  async getConfiguracaoPainelAtendimento() {
    return Http.get(`api/v2/painel-atendimento/public/configuracoes`);
  },

  async acompanhamento(entidade, codigo, senha) {
    return Http.get(`${path}/acompanhamento/${entidade}/${codigo}/${senha}`);
  },

  baixarExigencia(protocolo, atividade, assinada = true){
    return `${path}/exigencia/${protocolo}/${atividade}/${assinada}`;
  },

  async cadastrar(dto) {
    return Http.post(`${path}/cadastrar`, dto);
  },

  async recuperarSenha(documento) {
    documento = tratarDocumento(documento);
    return Http.get(`${path}/recuperar-senha/${documento}`);
  },

  async getUser(autenticao) {
    return Http.get(`${path}/${autenticao}`);
  },

  async alterarSenha(documento, senha) {
    documento = tratarDocumento(documento);
    return Http.get(`${path}/resetar-senha/${documento}/${senha}`);
  },

  async pesquisarPedidosDocumento(documento) {
    documento = tratarDocumento(documento);
    return Http.get(`${path}/pedidos-documento/${documento}`);
  },

  async autenticar(documento, senha) {
    documento = tratarDocumento(documento);
    return Http.get(`${path}/autenticar/${documento}/${senha}`);
  },

  async listarPedidos(autenticao) {
    return Http.get(`${path}/${autenticao}/pedido-certidao`);
  },

  async novoPedido(autenticao, dto) {
    return Http.post(`${path}/${autenticao}/pedido-certidao`, dto);
  },

  async novoPedidoOnline(dto) {
    return Http.post(`${path}/novo-pedido-online`, dto);
  },

  async editarPerfil(autenticao, dto) {
    return Http.post(`${path}/${autenticao}/editar-perfil`, dto);
  },

  async visualizarPedido(autenticao, id) {
    return Http.get(`${path}/${autenticao ? `${autenticao}/` : '' }pedido-certidao/${id}`);
  },

  async anexar(autenticao, id, file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/${autenticao}/upload-file/${id}`, formData, config);
  },

  async listarComentarios(id) {
    return Http.get(`${path}/pedido-certidao/${id}/comentarios`);
  },

  async comentar(autenticao, pedido, id, dto) {
    return Http.post(`${path}/${autenticao ? `${autenticao}/` : '' }pedido-certidao/${pedido}/comentario${id ? `/${id}` : '' }`, dto);
  },

  async excluir(id){
    return Http.delete(`${path}/pedido-certidao/${id}/excluir`);
  },

  async recusar(autenticao, id) {
    return Http.post(`${path}/${autenticao}/pedido-certidao/${id}/recusar`);
  },

  async aprovar(autenticao, id) {
    return Http.post(`${path}/${autenticao}/pedido-certidao/${id}/aprovar`);
  },

  tiposCertidao(){
    return [
      {i: 0, id : 'BUSCA_NOME', nome : 'Busca por nome', url : '', descricao : `` },
      {i: 1, id : 'BUSCA_IMOVEL', nome : 'Busca por imóvel', url : '', descricao : `` },
      {i: 2, id : 'INTEIRO_TEOR_MATRICULA', nome : 'Certidão de inteiro teor ou de matrícula', url : 'INTEIRO_TEOR', descricao : `É uma cópia reprográfica da matrícula do imóvel constante no Cartório, também conhecida como “certidão da matrícula”. ` },
      {i: 3, id : 'ONUS_ACAO', nome : 'Certidão de ônus e ações', url : 'ONUS', descricao : `É o documento que atesta a existência ou não de ônus ou de ações que recaiam sobre o imóvel e fornece o número da matrícula, endereço do imóvel e o nome do proprietário.` },
      // {id : 'COMPLETA', nome : 'Certidão de inteiro teor ou de matrícula + Certidão de ônus e ações', url : 'COMPLETA', descricao : `Nesta opção, é possível solicitar, em um só pedido, a cópia da matrícula do imóvel com todas informações registradas no Cartório e a Certidão de Ônus ou de Ações.` },
      {i: 4, id : 'INTEIRO_TEOR_TRANSCRICAO', nome : 'Certidão de transcrição ou inscrição', url : 'TRANSCRICAO', descricao : `É o documento emitido para imóveis que não têm cadastro no Cartório com número de matrícula, apenas com o número de transcrição, por se tratar de propriedade constituída antes de 1976.` },
      {i: 5, id : 'PACTO', nome : 'Certidão de pacto antenupcial', url : 'PACTO', descricao : `Consiste no contrato solene, realizado antes do casamento, para regulamentação das relações patrimoniais do futuro casal.` },
      {i: 6, id : 'REGISTRO_AUXILIAR', nome : 'Certidão de registro auxiliar', url : 'REGISTRO_AUXILIAR', descricao : `Documento que contempla os atos que não estão relacionados diretamente ao imóvel como cédulas de crédito, convenções de condomínio, convenções antenupciais e etc.` },
      {i: 7, id : 'COPIA', nome : 'Cópia de documento arquivado', url : '', descricao : `Os documentos apresentados no Cartório são devidamente arquivados em seu acervo. Nesta opção, os usuários podem solicitar suas cópias de plantas, loteamento, contratos, memoriais de incorporação, etc. ` },
      {i: 8, id : 'CERTIDAO_PERIODO', nome : 'Certidão quinzenária, vintenária, cinquentenária ou outro período', url : '', descricao : `Atesta o histórico do registro imobiliário dos últimos 15, 20, 50 anos ou outro período, compreendendo todo e qualquer registro feito em relação a esse imóvel, seja sob número de matrícula ou transcrição antigo.` },
      {i: 9, id : 'NEGATIVA_PROPRIEDADE', nome : 'Certidão negativa de propriedade', url : 'NEGATIVA_PROPRIEDADE', descricao : `É o documento que comprova a inexistência de imóvel em nome de uma pessoa física ou jurídica.` },
      {i: 10, id : 'NEGATIVA_REGISTRO', nome : 'Certidão negativa de registro', url : 'NEGATIVA_REGISTRO', descricao : `Este documento certifica a inexistência de registro de uma determinada unidade imobiliária para situações nas quais há parcelamento irregular ou clandestino do solo.` },
      {i: 11, id : 'OUTRAS', nome : 'Outras certidões', url : '', descricao : `` },
    ]
  },

  tiposVinculo(){
    return  [
      {id: 'INDICADOR_PESSOAL', nome: 'Indicador Pessoal'},
      {id: 'INDICADOR_REAL', nome: 'Indicador Real'},
      {id: 'PACTO', nome: 'Pacto Antenupcial'},
      {id: 'TIPO_CERTIDAO', nome: 'De acordo com o tipo de certidão'},
      {id: 'NENHUM', nome: 'Nenhum'}
    ]
  },

  async downloadCertidao(hash){
    return Http.get(`${path}/certidao-assinada/${hash}`,{
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      return new Blob([response], {type: "application/pdf"});
    })
  },

  async validarAssinatura(hash) {
    return Http.get(`${path}/verificar-assinatura/${hash}`);
  },

}

<template>

  <consulta-indisponibilidade :aba="aba" :pessoa="pessoa" @trocar="trocarAba($event)">
    <template #menu>
      <a href v-if="aba !== 'abertas'" class="icon float-right pointer mr-2" @click.prevent="trocarAba('abertas')">Indisponibilidades abertas</a>
      <a href v-if="aba !== 'ordens'" class="icon float-right pointer mr-2" @click.prevent="trocarAba('ordens')">Histórico de ordens</a>
    </template>
  </consulta-indisponibilidade>
</template>

<script>
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
  import ConsultaIndisponibilidade from "@/components/IndicadorPessoal/Detalhes/Indisponibilidades/Consulta.vue";

  export default {

    name: "Indisponibilidades",
    components: {ConsultaIndisponibilidade},
    props: {
      pessoa: Object
    },

    async mounted() {
      this.indisponibilidades = await IndicadorPessoalBusiness.getIndisponibilidades(this.pessoa.indicadorPessoalId);
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        indisponibilidades: [],
        aba: 'abertas'
      }
    },

    methods: {
      trocarAba(aba) {
        this.$set(this, 'aba', aba);
      }
    },

  }
</script>

<template>

  <modal titulo="Importar dados">
    <div class="row mt-2 p-2 gutters-xs">
      <v-input v-model="form.tipo" type="select" :options="tipo_status"
               :required="true" placeholder="Tipo" class="col-3"/>
      <div class="col">
        <buscar-fichas livro="MATRICULA" :status="status" ref="buscarFichas" @selecionar="selecionarFicha"
                       :multiple="false"/>
      </div>

      <card :titulo="titulo" v-if="detalhesIndicador.id" class="w-100">
        <div class="div-select-all">
          <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
            <i class="ml-2 fal fa-check-circle"></i>
          </a>
          <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
            <i class="ml-2 fal fa-circle"></i>
          </a>
        </div>
        <card>
          <card-content class="row gutters-xs">
            <v-input v-if="detalhesIndicador.categoriaDescricao.length" v-model="detalhesIndicador.categoriaDescricao"
                     type="text" class="col-auto" label="Categoria" :disabled="true" style="width: 100px;">
              <template #input>
                <input v-model="selecionados.categoria" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.unidadeAutonomaDescricao.length" v-model="detalhesIndicador.unidadeAutonomaDescricao"
                     type="text" class="col-auto" label="Unidade Autônoma?" :disabled="true" style="width: 135px;">
              <template #input>
                <input v-model="selecionados.unidadeAutonoma" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.tipoImovel.length"  v-model="detalhesIndicador.tipoImovel"
                     type="text" class="col" label="Tipo de imóvel" :disabled="true">
              <template #input>
                <input v-model="selecionados.tipoImovel" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.imovelUniaoDescricao.length"  v-model="detalhesIndicador.imovelUniaoDescricao"
                     type="text" class="col" label="Imóvel da União" :disabled="true">
              <template #input>
                <input v-model="selecionados.imovelUniao" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.marinhaDescricao.length"  v-model="detalhesIndicador.marinhaDescricao"
                     type="text" class="col" label="Imóvel da Marinha" :disabled="true">
              <template #input>
                <input v-model="selecionados.marinha" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.fronteiraDescricao.length"  v-model="detalhesIndicador.fronteiraDescricao"
                     type="text" class="col" label="Imóvel de Fronteira" :disabled="true">
              <template #input>
                <input v-model="selecionados.fronteira" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
              </template>
            </v-input>

            <div class="w-100"></div>
            <v-input v-if="detalhesIndicador.descricaoTipoImovelOutros.length" class="col" v-model="detalhesIndicador.descricaoTipoImovelOutros"
                     type="text" label="Descrição" :disabled="true">
              <template #input>
                <input v-model="selecionados.descricaoTipoImovelOutros" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <div class="w-100"></div>
            <template v-if="detalhesIndicador.origens.length" >
              <label class="form-label mb-2 w-100">
                <input v-model="selecionados.origens" style="position: relative; top: 2px; left: 0;" type="checkbox"/>
                Registro anterior
              </label>
              <RegistroAnteriorLista :visualizar="true" :registros="detalhesIndicador.origens"
                                     @update="detalhesIndicador.origens = $event"/>
            </template>
          </card-content>

        </card>

        <card titulo="Identificação">
          <card-content class="row gutters-xs">

            <template v-if="detalhesIndicador.unidadeAutonoma">

              <v-input v-if="detalhesIndicador.unidade.length" class="col" v-model="detalhesIndicador.unidade" type="text" label="Número da unidade"
                       :disabled="true">
                <template #input>
                  <input v-model="selecionados.unidade" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.bloco.length" class="col" v-model="detalhesIndicador.bloco" type="text" label="Bloco" :disabled="true">
                <template #input>
                  <input v-model="selecionados.bloco" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>

              <div class="w-100"></div>
            </template>

            <v-input v-if="detalhesIndicador.quadra.length" :class="'col-3'" v-model="detalhesIndicador.quadra" type="text" label="Quadra" :disabled="true">
              <template #input>
                <input v-model="selecionados.quadra" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.lote.length" :class="'col-3'" v-model="detalhesIndicador.lote" type="text" label="Lote" :disabled="true">
              <template #input>
                <input v-model="selecionados.lote" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.localizacao.length" class="col" label="Localização" v-model="detalhesIndicador.localizacao" type="text"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.localizacao" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>

            <div class="w-100"></div>
            <v-input v-if="detalhesIndicador.empreendimento.length" class="col" v-model="detalhesIndicador.empreendimento" type="text" label="Empreendimento/Condomínio"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.empreendimento" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>

          </card-content>
        </card>

        <card titulo="Informações Complementares"
              v-if="detalhesIndicador.andar.length ||
              detalhesIndicador.pavimento.length ||
              detalhesIndicador.grupo.length ||
              detalhesIndicador.denominacao.length ||
              detalhesIndicador.incra.length ||
              detalhesIndicador.certificacaoIncra.length ||
              detalhesIndicador.itr.length ||
              detalhesIndicador.car.length ||
              detalhesIndicador.cadastroImobiliario.length">
          <card-content class="row gutters-xs">

            <template v-if="detalhesIndicador.unidadeAutonoma">
              <v-input v-if="detalhesIndicador.andar.length" class="col" v-model="detalhesIndicador.andar" type="text" label="Andar" :disabled="true">
                <template #input>
                  <input v-model="selecionados.andar" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.pavimento.length" class="col" v-model="detalhesIndicador.pavimento" type="text" label="Pavimento"
                       :disabled="true">
                <template #input>
                  <input v-model="selecionados.pavimento" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.grupo.length" class="col" v-model="detalhesIndicador.grupo" type="text" label="Grupo (Tipo)" :disabled="true">
                <template #input>
                  <input v-model="selecionados.grupo" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <div class="w-100"></div>
            </template>

            <template v-if="detalhesIndicador.categoria == 'RURAL'">
              <v-input v-if="detalhesIndicador.denominacao.length" class="col" v-model="detalhesIndicador.denominacao" type="text" label="Denominação da Área Rural" :disabled="true">
                <template #input>
                  <input v-model="selecionados.denominacao" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.incra.length" class="col" v-model="detalhesIndicador.incra" type="text" label="INCRA (CCIR)" :disabled="true">
                <template #input>
                  <input v-model="selecionados.incra" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.itr.length" class="col" v-model="detalhesIndicador.itr" type="text" label="ITR" :disabled="true">
                <template #input>
                  <input v-model="selecionados.itr" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.car.length" class="col" v-model="detalhesIndicador.car" type="text" label="CAR" :disabled="true">
                <template #input>
                  <input v-model="selecionados.car" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.certificacaoIncra.length" class="col" v-model="detalhesIndicador.certificacaoIncra" type="text" label="Certificação no SIGEF" :disabled="true">
                <template #input>
                  <input v-model="selecionados.certificacaoIncra" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
            </template>

            <v-input v-if="detalhesIndicador.cadastroImobiliario.length" :class="'col mb-3'" v-model="detalhesIndicador.cadastroImobiliario" type="text"
                     label="Cadastro imobiliário" :disabled="true">
              <template #input>
                <input v-model="selecionados.cadastroImobiliario" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>

            <v-input v-if="detalhesIndicador.imovelUniao && detalhesIndicador.numeroRipSpu" :class="'col mb-3'" v-model="detalhesIndicador.numeroRipSpu" type="mask"
                     label="Número RIP SPU" :disabled="true" :masks="['#### #######-##']">
              <template #input>
                <input v-model="selecionados.numeroRipSpu" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>

          </card-content>
        </card>

        <card titulo="Endereço">
          <card-content class="row gutters-xs">
            <v-input v-if="detalhesIndicador.cep.length" :class="'col-2 pr-1'" v-model="detalhesIndicador.cep" type="cep" label="CEP" :disabled="true">
              <template #input>
                <input v-model="selecionados.cep" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.tipoLogradouro.length" class="col" v-model="detalhesIndicador.tipoLogradouro" type="text" label="Tipo Logradouro"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.tipoLogradouro" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.logradouro.length" class="col" v-model="detalhesIndicador.logradouro" type="text" label="Logradouro (rua)"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.logradouro" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.numero.length" :class="'col-2'" v-model="detalhesIndicador.numero" type="text" label="Número" :disabled="true">
              <template #input>
                <input v-model="selecionados.numero" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <div class="w-100"></div>
            <v-input v-if="detalhesIndicador.bairro.length" class="col" v-model="detalhesIndicador.bairro" type="text" label="Bairro (setor)"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.bairro" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.cidade.length" class="col" v-model="detalhesIndicador.cidade" type="text" label="Cidade" :disabled="true">
              <template #input>
                <input v-model="selecionados.cidade" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.estado.length" :class="'col-auto'" v-model="detalhesIndicador.estado" type="text" label="Estado"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.estado" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <div class="w-100"></div>
            <v-input v-if="detalhesIndicador.complemento.length" :class="'col mb-3'" v-model="detalhesIndicador.complemento" type="text" label="Complemento"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.complemento" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
          </card-content>
        </card>

        <card titulo="Área">
          <card-content class="row gutters-xs">
            <v-input v-if="detalhesIndicador.medidaArea.length" class="col" v-model="detalhesIndicador.medidaArea" :options="options.medidas" type="select"
                     label="Medida área"
                     :disabled="true">
              <template #input>
                <input v-model="selecionados.medidaArea" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <v-input v-if="detalhesIndicador.area" :disabled="true" class="col" label="Área total" v-model="detalhesIndicador.area" type="currency"
                     :input-props="{precision : decimais}">
              <template #input>
                <input v-model="selecionados.area" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
            <template v-if="detalhesIndicador.unidadeAutonoma">
              <div class="w-100"></div>
              <v-input v-if="detalhesIndicador.fracaoSolo" :disabled="true" class="col" :label="unidadeArea" v-model="detalhesIndicador.fracaoSolo"
                       type="currency" :input-props="{precision : decimais}">
                <template #input>
                  <input v-model="selecionados.fracaoSolo" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <v-input v-if="detalhesIndicador.fracaoSoloPercentual" :disabled="true" class="col" label="Fração solo (%)"
                       v-model="detalhesIndicador.fracaoSoloPercentual" type="currency"
                       :input-props="{precision : decimais}">
                <template #input>
                  <input v-model="selecionados.fracaoSoloPercentual" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
              <div class="w-100"></div>
              <v-input v-if="detalhesIndicador.coeficienteProporcionalidade" :disabled="true" class="col" label="Coeficiente de proporcionalidade"
                       v-model="detalhesIndicador.coeficienteProporcionalidade" type="currency"
                       :input-props="{currencyDisplay: 'hidden', precision : {min : 0, max : 10}}">
                <template #input>
                  <input v-model="selecionados.coeficienteProporcionalidade" style="margin-top: 10px;margin-right: 3px;"
                         type="checkbox"/>
                </template>
              </v-input>
            </template>
            <template v-if="detalhesIndicador.unidadeAutonoma">
              <div class="w-100"></div>
              <div class="col mb-3" v-if="detalhesIndicador.areas.length">
                <input v-model="selecionados.areas" style="margin-top: 10px;margin-right: 3px;" type="checkbox"/>
                <detalhar-areas-indicador-real :visualizar="true" :edicao="false" class="col" tipo="areas"
                                               titulo="Detalhamento das áreas" :areas="detalhesIndicador.areas"/>
              </div>

            </template>

          </card-content>
        </card>

        <card titulo="Áreas edificadas" v-if="detalhesIndicador.areasEdificadas.length">
          <template #acoes>
            <input v-model="selecionados.areasEdificadas" style="float: left;margin-right: 3px;"
                   type="checkbox"/>
          </template>
          <card-content class="row gutters-xs">
            <detalhar-areas-indicador-real :visualizar="true" :edicao="false" tipo="areasEdificadas"
                                           titulo="Áreas edificadas" :possui-tipo="false"
                                           :areas="detalhesIndicador.areasEdificadas"/>
          </card-content>
        </card>

        <card v-if="detalhesIndicador.unidadesAcessorias.length" titulo="Unidades acessórias">
          <template #acoes>
            <input v-model="selecionados.unidadesAcessorias" style="float: left;margin-right: 3px;"
                   type="checkbox"/>
          </template>
          <card-content class="row gutters-xs">
            <detalhar-areas-indicador-real :visualizar="true" :edicao="false" tipo="unidadesAcessorias"
                                           titulo="Unidade acessória" :possui-tipo="false"
                                           :areas="detalhesIndicador.unidadesAcessorias"/>
          </card-content>
        </card>

        <card titulo="Confrontações" v-if="detalhesIndicador.confrontacoes.length">
          <template #acoes>
            <input v-model="selecionados.confrontacoes" style="float: left;margin-right: 3px;"
                   type="checkbox"/>
          </template>
          <card-content class="row gutters-xs">
            <confrontacao-indicador-real :visualizar="true" :confrontacoes="detalhesIndicador.confrontacoes" :edicao="false"  />
          </card-content>
        </card>

        <card v-if="detalhesIndicador.especificacao.length">
          <card-content>
            <v-input :disabled="true" type="editor" label="Especificações do imóvel "
                     v-model="detalhesIndicador.especificacao">
              <template #input>
                <input v-model="selecionados.especificacao" style="margin-top: 10px;margin-right: 3px;"
                       type="checkbox"/>
              </template>
            </v-input>
          </card-content>
        </card>


      </card>
    </div>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="importar" :popover="true" class="btn-outline-primary" style="float: right;">Importar</v-button>
    </template>
  </modal>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import DetalharAreasIndicadorReal from "@/components/IndicadorReal/Form/DetalharAreasIndicadorReal";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import EnderecoBusiness from "@/business/EnderecoBusiness";
  import ConfrontacaoIndicadorReal from "@/components/IndicadorReal/Form/ConfrontacaoIndicadorReal";
  import Utils from "@/commons/Utils";

  export default {

    name: "ImportarIndicadorReal",
    components: {
      Card,
      CardContent,
      DetalharAreasIndicadorReal,
      Modal,
      ConfrontacaoIndicadorReal,
      RegistroAnteriorLista: () => import('@/components/IndicadorReal/RegistroAnteriorLista.vue')
    },

    modal: {
      width: 860
    },

    async mounted() {

    },

    computed: {
      tipo_status() {
        return [{id: "MATRICULA", nome: "Matrícula", livro: "MATRICULA", status: 'ABERTA'}, {
          id: "PRE_MATRICULA",
          nome: "Pré-Matrícula",
          livro: "MATRICULA",
          status: 'PENDENTE'
        }];
      },

      status() {
        return this.tipo_status.find(e => e.id === this.form.tipo)?.status || 'ABERTA'
      },
      unidadeArea() {
        return this.detalhesIndicador?.medidaArea ? 'Fração solo (' + FrontBusiness.getLabel(this.detalhesIndicador.medidaArea, IndicadorRealBusiness.getMedidas()) + ')' : 'Fração solo';
      },
      titulo() {
        return `Indicador real: ${this.nomearLivro(this.detalhesIndicador)}`
      }
    },

    data() {
      return {
        form: {
          tipo: 'MATRICULA'
        },
        selecionados: {},
        detalhesIndicador: {},
        options: {
          medidas: IndicadorRealBusiness.getMedidas(),
          tipos: IndicadorRealBusiness.getTiposImovel(),
          categorias: IndicadorRealBusiness.getCategorias(),
          estados: EnderecoBusiness.getEstados()
        },
        decimais: {min : 2, max : 10},
        camposComuns: ['categoria', 'unidadeAutonoma', 'tipoImovel', 'imovelUniao', 'marinha', 'fronteira', 'descricaoTipoImovelOutros', 'origens', 'quadra', 'lote', 'localizacao', 'empreendimento', 'cadastroImobiliario', 'cep', 'tipoLogradouro', 'logradouro', 'numero', 'bairro', 'cidade', 'estado', 'complemento', 'medidaArea', 'area', 'areasEdificadas', 'unidadesAcessorias', 'confrontacoes', 'especificacao',],
        camposUnidadeAutonoma: ['unidade', 'bloco', 'andar', 'pavimento', 'grupo', 'fracaoSolo', 'fracaoSoloPercentual', 'coeficienteProporcionalidade', 'areas',],
        camposRural: ['incra', 'itr', 'car', 'denominacao', 'certificacaoIncra',]
      }
    },

    methods: {

      close(e) {
        FrontBusiness.closeModal(e);
      },

      nomearLivro: FrontBusiness.nomearFicha,

      selecionarTodos(limpar = false) {
        this.$set(this, 'selecionados', {})
        this.camposComuns.forEach(c => {
          if (![null, undefined, ''].includes(this.detalhesIndicador[c])) this.$set(this.selecionados, c, !limpar)
        })
        if (this.detalhesIndicador.unidadeAutonoma) this.camposUnidadeAutonoma.forEach(c => {
          if (![null, undefined, ''].includes(this.detalhesIndicador[c])) this.$set(this.selecionados, c, !limpar)
        })
        if (this.detalhesIndicador.categoria === 'RURAL') this.camposRural.forEach(c => {
          if (![null, undefined, ''].includes(this.detalhesIndicador[c])) this.$set(this.selecionados, c, !limpar)
        })
        if (this.detalhesIndicador.imovelUniao)
          if (![null, undefined, ''].includes(this.detalhesIndicador['numeroRipSpu'])) this.$set(this.selecionados, 'numeroRipSpu', !limpar)

      },

      async selecionarFicha(fichas) {
        this.form.ficha = fichas[0];
        this.selecionarTodos(true);
        if (this.form.ficha?.id) {
          this.detalhesIndicador = await IndicadorRealBusiness.getById(this.form.ficha?.id)
          for (const campo in this.detalhesIndicador) {
            if (!this.detalhesIndicador[campo] && !['imovelUniao', 'marinha', 'fronteira'].includes(campo)) {
              this.detalhesIndicador[campo] = '';
            }
          }
          this.detalhesIndicador.categoriaDescricao = this.options.categorias.find(f => f.id === this.detalhesIndicador.categoria)?.nome;
          this.detalhesIndicador.unidadeAutonoma = this.detalhesIndicador.unidadeAutonoma ? this.detalhesIndicador.unidadeAutonoma : false;
          this.detalhesIndicador.unidadeAutonomaDescricao = this.detalhesIndicador.unidadeAutonoma ? 'Sim' : 'Não';
          this.detalhesIndicador.imovelUniaoDescricao = typeof this.detalhesIndicador.imovelUniao === "boolean" ? this.detalhesIndicador.imovelUniao ? 'Sim' : 'Não' : '';
          this.detalhesIndicador.marinhaDescricao = typeof this.detalhesIndicador.marinha === "boolean" ? this.detalhesIndicador.marinha ? 'Sim' : 'Não' : '';
          this.detalhesIndicador.fronteiraDescricao = typeof this.detalhesIndicador.fronteira === "boolean" ? this.detalhesIndicador.fronteira ? 'Sim' : 'Não' : '';
          this.detalhesIndicador.tipoImovel = this.options.tipos.find(f => f.id === this.detalhesIndicador.tipoImovel)?.nome;
          this.detalhesIndicador.estado = this.options.estados.find(f => f.id === this.detalhesIndicador.estado)?.nome || '';
          this.detalhesIndicador.especificacao = this.detalhesIndicador.especificacao || '';
          this.detalhesIndicador.areas = [...(this.detalhesIndicador.areas || [])];
          this.detalhesIndicador.areasEdificadas = [...(this.detalhesIndicador.areasEdificadas || [])];
          this.detalhesIndicador.unidadesAcessorias = [...(this.detalhesIndicador.unidadesAcessorias || [])];
          this.detalhesIndicador.confrontacoes = [...(this.detalhesIndicador.confrontacoes || [])];
        } else {
          this.detalhesIndicador = {};
        }
      },

      importar() {
        if (!this.form.ficha?.id) return FrontBusiness.showError('', 'Nenhuma matrícula selecionada', 'app');
        let selecionados = []
        for (const campo in this.selecionados) if (this.selecionados[campo]) selecionados.push(campo);
        if (!selecionados.length) return FrontBusiness.showError('', 'Nenhuma campo selecionado', 'app');
        this.close({id: this.form.ficha.id, campos: selecionados})
      }
    }

  }
</script>

<style lang=scss>

</style>

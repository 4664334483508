<template>
  <card titulo="Registros Auxiliares">
    <card-table class="table-borderless mb-2" v-if="registrosAuxiliares.length > 0">

      <thead>
        <tr>
          <th>Registro Auxiliar</th>
          <th>Situação</th>
          <th>Tipo Registro</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="registroAuxiliar in registrosAuxiliares" :key="registroAuxiliar.id">
          <td><a href @click.prevent="detalhes(registroAuxiliar.id)" >{{formatarCodigo(registroAuxiliar.codigo) + (registroAuxiliar.livroLetra || '')}}</a> </td>
          <td class="text-capitalize">{{registroAuxiliar.status.toLowerCase()}}</td>
          <td>{{tipoRegistro(registroAuxiliar.tipoRegistro) }}</td>
        </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!registrosAuxiliares.length && !loading" class="alert alert-outline-info">
      Não há registros auxiliares para exibir
    </div>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar";
  import SmallLoading from "@/components/SmallLoading";

  export default {

    name: "RegistrosAuxiliares",
    components: {SmallLoading, CardTable, Card},
    props: {
      pessoa : Object
    },

    data() {
      return {
        registrosAuxiliares : [],
        loading: true,
      }
    },

    async mounted(){
      this.registrosAuxiliares = await IndicadorPessoalBusiness.getRegistrosAuxiliares(this.pessoa.indicadorPessoalId);
      this.loading = false;
    },

    methods:{

      formatarCodigo : FrontBusiness.formatarCodigo,

      tipoRegistro(valor){
        return FrontBusiness.getLabel(valor, RegistroAuxiliarBusiness.getTipos());
      },

      detalhes(id){
        FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id});
      },

    }
  }
</script>

<template>

  <div class="buscar-fichas-wrapper row gutters-xs">

    <template v-if="selecionarLivros">
      <v-input type="select" :options="livros" :tipoRegistro="tipoRegistro" class="col-auto" v-model="livroSelecionado" @change="adicionar(true, true)" :disabled="livros.length == 1"/>
    </template>

    <div class="item-action col-auto pt-2" v-if="search">
      <a ref="ficha" href="" @click.prevent="pesquisar()" class="icon">
        <i class="fa fa-search"></i>
      </a>
    </div>

    <div class="item-action col-auto pt-2" v-if="sugestoes.length">
      <a title="Sugestões" ref="sugestoes" href="" @click.prevent="sugestao()" class="icon">
        <i class="fas fa-lightbulb-on"></i>
      </a>
    </div>

    <v-input type="text" v-if="multiple"

             :title="mensagemValidacao"
             :error="mensagemValidacao"
             :valid="campoValidado"
             v-model.trim="str"
             :small="true"

             @enter="adicionar(true)"
             @blur="adicionar()"


             :placeholder="placeholder"
             :loading="pesquisando"

             :disabled="desabilitado"
             :data-testid="dataTestid"

             class="col" />

    <template v-else>
      <v-input
        class="col"
        :title="mensagemValidacao"
        :error="mensagemValidacao"
        :valid="campoValidado">

        <v-input type="text"
                 v-model.trim="individual.codigo"
                 :small="true"
                 @enter="adicionar(true)"
                 @blur="adicionar()"
                 placeholder="Código"
                 :disabled="desabilitado"
                 :loading="pesquisando"
                 :valid="campoValidado"
                 class="col m-0 pl-1 pr-1" />

        <v-input type="text" v-if="livroSelecionado ==='TRANSCRICAO'"
                 v-model.trim="individual.livroNumero"
                 :small="true"
                 @enter="adicionar(true)"
                 placeholder="Número"
                 :disabled="desabilitado"
                 :loading="pesquisando"
                 :valid="campoValidado"
                 class="col m-0 pl-1 pr-1" />

        <v-input type="text" v-if="!['BEM_MOVEL', 'REGISTRO_PJ', 'REGISTRO_TD'].includes(livroSelecionado)"
                 v-model.trim="individual.livroLetra"
                 :small="true"
                 @enter="adicionar(true)"
                 placeholder="Letra"
                 :disabled="desabilitado"
                 :loading="pesquisando"
                 :valid="campoValidado"
                 class="col m-0 pl-1 pr-1" />

        <v-input type="text" v-if="livroSelecionado ==='TRANSCRICAO'"
                 v-model.trim="individual.livroFolha"
                 :small="true"
                 @enter="adicionar(true)"
                 placeholder="Folha"
                 :disabled="desabilitado"
                 :loading="pesquisando"
                 :valid="campoValidado"
                 class="col m-0 pl-1 pr-1" />

        <a href="" @click.prevent="adicionar(true)" v-if="allowAdd && livroSelecionado !=='BEM_MOVEL'" class="btn btn-outline-secondary btn-xs">
          <i class="fa fa-plus"></i>
        </a>

      </v-input>
    </template>
    <div class="item-action col-auto" :class="{'pt-2' : !selecionarLivros}" v-if="desabilitado || selecionarLivros">
      <a ref="ficha" href="" @click.prevent="trocarFichas()" class="icon" :class="{'pt-2 mr-2' : selecionarLivros}" v-if="desabilitado">
        <i class="fa fa-times"></i>
      </a>

      <a href="" @click.prevent="adicionar(true)" v-if="selecionarLivros" class="btn btn-outline-secondary btn-xs">
        <i class="fa fa-plus"></i>
      </a>
    </div>

  </div>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
  import Utils from "@/commons/Utils";
  import utils from "@/commons/Utils.js";
  import FichaSugestao from "@/components/FichaSugestao.vue";

  export default {
    name: "BuscarFichas",

    props: {
      livro    : String,
      dominio  : String,
      status   : {type : String, default : 'ABERTA'},
      multiple : {type : Boolean, default : true},
      allowAdd : {type : Boolean, default : true},
      search : {type : Boolean, default : true},
      full : {type : Boolean, default : false},
      multiplos : {type : Boolean, default : false},
      desabilitar : {type : Boolean, default : false},
      selecionarLivros : {type : Boolean, default : false},
      valorInicial: {type: String, default: ''},
      valorInicialIndividual: {type: Object, default: () => {}},
      sugestoes: {type: Array, default: () => []},
      noChangeSelect: {type: Boolean, default: false},
      listaLivros: {type: Array, default: () => []},
      limparCampo: {type: Boolean, default: false},
      completo: {type: Boolean, default: false},
      buscaExata: {type: Boolean, default: false},
      dataTestid: String,
      tipoRegistro: String
    },

    computed:{

      business(){
        return IndicadorBusiness.getBusiness(this.livroSelecionado);
      },

      modal(){
        return IndicadorBusiness.getModalConsulta(this.livroSelecionado);
      },

      placeholder(){
        return this.multiple ? 'Ex.: 300-320, 123.123, 156123, 156.128-156.136' : 'Ex.: 123.123';
      },

      livros(){

        if(this.selecionarLivros && this.listaLivros?.length) {
          return IndicadorBusiness.getLivros().filter(l => (this.listaLivros.includes(l.id)));
        }

        if(this.livro){
          return IndicadorBusiness.getLivros().filter(l => this.livro == l.id);
        }

        return IndicadorBusiness.getLivrosByDominio(this.dominio);

      },

    },

    watch: {
      async str() {
        this.setPesquisando(true);
        this.desvalidarCodigos();
        await this.validarCodigosDebounce();
      },
      "individual.codigo"() {
        this.desvalidarCodigos();
      },

      livro(newValue, oldValue) {
        console.debug(newValue, oldValue);
        if(newValue != oldValue) this.livroSelecionado = newValue;
      },

      desabilitar(newValue, oldValue) {
        console.debug(newValue, oldValue);
        if(newValue != oldValue) this.desabilitado = newValue;
      },
    },

    data() {
      return {
        str : this.valorInicial,
        individual : {
          codigo: this.valorInicialIndividual?.codigo,
          livroLetra: this.valorInicialIndividual?.livroLetra,
          livroNumero: this.valorInicialIndividual?.livroNumero,
          livroFolha: this.valorInicialIndividual?.livroFolha,
        },
        pesquisando : false,
        mensagemValidacao : null,
        campoValidado : false,
        livroSelecionado: this.livro,
        desabilitado: this.desabilitar,
        adicionar: Utils.debounce(this.adicionarFnc, 500),

        validarCodigosDebounce: Utils.debounce(async function () {
          await this.validarCodigos();
          if (this.full) await this.adicionar();
        }, 500),
        idsPesquisa: [],

      }
    },

    methods: {

      async setPesquisando(pesquisando, load) {
        await this.$set(this, 'pesquisando', pesquisando)
        await this.$emit('pesquisando', pesquisando, load);
      },

      trocarFichas(codigos){
        this.$nextTick(() => {
          this.setPesquisando(false);
          this.desvalidarCodigos();

          this.$set(this, 'str', codigos);

          this.validarCodigos();
          // this.selecionarFichas([]);
        });
      },

      selecionarFichas(fichas, add){
        if (this.idsPesquisa && this.idsPesquisa.length) {
          fichas = fichas.filter(f => this.idsPesquisa.includes(f.id))
          if (add) this.idsPesquisa = [];
        }
        this.$emit('selecionar', fichas, add, this.campoValidado, this.mensagemValidacao);
        if(this.limparCampo){
          this.limparCampos()
        }
      },

        limparCampos(){
            this.individual = {};
            this.str = '';
        },

      async pesquisar(){
        let result = await FrontBusiness.openConsulta(this.modal,{ livro: this.livroSelecionado, registro: this.tipoRegistro, status : this.status, multiplos: this.multiplos });
        if(result){
          if (!this.multiple) {
            this.individual = Array.isArray(result) ? result[0] : result;
            this.idsPesquisa = [this.individual.id];
            this.adicionar();
            return
          }
          if (Array.isArray(result)) {
            result.forEach(r => {
              this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(r.codigo)}${r.livroLetra || ''}`;
            })
            this.idsPesquisa = result.map(r => r.id);
          } else {
            this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(result.codigo)}${result.livroLetra || ''}`;
            this.idsPesquisa = [result.id];
          }
          this.adicionar();
        }
      },

      async sugestao(){
        let result = await FrontBusiness.openConsulta(FichaSugestao,{ sugestoes: this.sugestoes });
        if(result){
          if (Array.isArray(result)) {
            result.forEach(r => {
              this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(r.codigo)}${r.livroLetra || ''}`;
            })
          } else {
            this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(result.codigo)}${result.livroLetra || ''}`;
          }
          await this.adicionar();
        }
      },

      async adicionarFnc(add, alteracao = false){
        if(alteracao && this.noChangeSelect) this.str = '';
        let encontradas = await this.validarCodigos(add) || [];
        if(!this.noChangeSelect || (this.noChangeSelect && add)) this.selecionarFichas(encontradas, alteracao && this.noChangeSelect ? false : add);
        if(alteracao && this.noChangeSelect) {
          this.str = '';
        }
      },

      async validarCodigos(add) {

        let encontradas = null;

        if (!Utils.isEmptyNullOrUndefined(this.str) || !Utils.isEmptyNullOrUndefined(this.individual.codigo)) {

          await this.setPesquisando(true);
          await this.desvalidarCodigos();

          try{
            if (this.multiple) {
              let fichas = Utils.stringCodigosLetraToArray(this.str);

              if (!fichas.length) {
                throw 'Código digitado errado';
              }

              encontradas = await this.validarFichas(fichas);
            } else {
              encontradas = await this.validarFicha();
            }

            if(encontradas && encontradas.length){

              if(!this.multiple && encontradas.length > 1){
                let error = `Foram encontrados ${encontradas.length} registros com os dados informados. Utilize a lupa para definir o registro a ser selecionado.`;
                encontradas = [];
                throw error;
              } else {
                this.$set(this, 'campoValidado', true);
              }

            }

          }catch (e) {
            console.error(e);
            this.$set(this, 'mensagemValidacao', e);
          }

        }
        await this.setPesquisando(false, add);

        return encontradas;

      },

      desvalidarCodigos(){
        this.$set(this, 'mensagemValidacao', null);
        this.$set(this, 'campoValidado', false);
      },

      async validarFichas(codigos) {
        codigos = codigos.map(c => c.toString());
        let tipoRegistro = this.tipoRegistro;
        let fichas = await this.business.getFichasPorCodigo({status: this.status, dto: {codigos, tipoRegistro}, livro: this.livroSelecionado, completo: this.completo});
        if (this.buscaExata || !this.multiple) {
          fichas = (fichas || []).filter(f => codigos.includes(f.codigo + (f.livroLetra || '')) )
        }
        let matriculasEncontradas = Utils.uniq((fichas || []).map(f => f.codigo + ''));
        let matriculasEncontradasLetra = Utils.uniq((fichas || []).map(f => f.codigo + (f.livroLetra || '')));
        let matriculasNaoEncontradas = codigos.filter(f => !matriculasEncontradas.includes(f) && !matriculasEncontradasLetra.includes(f));
        if (matriculasNaoEncontradas.length) {
          throw 'Código(s) não encontrado(s) ' + matriculasNaoEncontradas.map(n => n).join(', ');
        }
        return fichas;
      },

      async validarFicha() {
        let dto = utils.clone(this.individual);
        dto.codigo = (Utils.isEmptyNullOrUndefined(dto.codigo) ? this.str : dto.codigo + '').replace(/[. ]+/g, "");
        dto.tipoRegistro = this.tipoRegistro;
        let fichas = await this.business.getFichasPorCodigo({
          status: this.status,
          dto,
          livro: this.livroSelecionado,
          completo: this.completo
        });
        if (dto.id) {
          fichas = (fichas || []).filter(f => [dto.id].includes(f.id))
        }
        if (!fichas.length) {
          throw 'Código não encontrado';
        }
        if (fichas.length === 1) {
          this.individual = fichas[0];
        }
        return fichas;
      },

    }

  }
</script>

<style lang=scss>

</style>

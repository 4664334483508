<template>

  <modal id="registro-anterior" titulo="Referência" :loading="loading">

    <card>

      <div class="row gutters-xs">

        <div class="col">
          <label>Vinculo</label>
          <buscar-fichas livro="MATRICULA" status="ABERTA"
                         :valorInicialIndividual="form.referenciaDetalhes || matriculaMae" ref="buscarFichas" @selecionar="selecionarFichas"
                         :multiplos="false" :multiple="false" />
        </div>

        <div class="col-12 text-danger small" v-if="validation.referenciaId">{{validation.referenciaId}}</div>

        <div class="w-100"></div>

        <v-input :error="validation.tipo" class="col" v-model="form.tipo" :options="options.tipos"
                 type="select" label="Tipo" />

        <v-input :error="validation.ato" type="number" label="Ato" v-model.number="form.ato" class="col-4" />

        <div class="w-100"></div>

        <v-input class="col" :error="validation.descricao" v-model="form.descricao" type="text" label="Descrição" />

      </div>

    </card>


    <div class="modal-footer">
      <button type="button" @click.prevent="close()" class="btn btn-secondary">Cancelar</button>
      <button type="button" @click.prevent="save()" class="btn btn-primary">Salvar</button>
    </div>

  </modal>

</template>
<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import Card from "@/components/Layout/components/Card.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils";

  export default {
    name: 'ReferenciaImovelForm',

    components: {
      Card,
      Modal
    },

    props: {
      registro: Object,
      ficha: Object,
      matriculaMae: Object
    },

    modal: {
      width: 700
    },

    data() {
      return {
        titulo: 'Registro Anterior',
        loading: false,
        validation: {},
        fichas: [],
        form: this.registro || {},
        options: {
          tipos: IndicadorRealBusiness.getTiposReferencia()
        }
      }
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      save() {
        let form = Utils.clone(this.form);
        const validation = IndicadorRealBusiness.getErrosFormReferenciaImovel(this.form) || {};
        if(Object.keys(validation).length){
          this.showErros(validation);
        }else{
          FrontBusiness.closeModal(form);
        }
      },

      showErros(validation) {
        this.$set(this, 'validation', validation);
      },

      /****************/
      selecionarFichas(ficha, add, campoValidado, msg) {
        this.form.referenciaDetalhes = ficha[0];
        this.form.referenciaId = ficha[0]?.id;
      },

    }
  }

</script>
<style lang="scss">

  #registro-anterior {

    .modal-dialog {
      $size: 1000px;
      width: 99%;
      max-width: $size;

      .modal-body {
        background: var(--alt-bg-color);
        max-height: calc(100vh - 180px);
        overflow: auto;
        border-top: 5px solid var(--alt-bg-color);
      }

      .busca-ficha {
        margin-top: 24px;
      }

    }

    /*select.form-control:not([size]):not([multiple]){*/
    /*height: 36px;*/
    /*}*/

  }


</style>

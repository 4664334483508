import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness";
import TextParser from "@/business/livros/TextParser";
import SimulacaoAtoBusiness from "@/business/protocolo/simulacao/SimulacaoAtoBusiness";
import VueBusiness from "@/business/VueBusiness.js";
import Utils from "@/commons/Utils";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness";
import TipoServicoBusiness from "@/views/Administracao/modulos/tipo-servico/business";
import moment from 'moment'
import atoBusiness from "@/business/protocolo/AtoBusiness";

export default {

  async gerarMinutaLote(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'gerar-minuta', usuarioLogado);
    }

    return {atos, ficha};

  },

  async gerarMinuta(protocolo, ficha, atos, documentos = [], ctrl = {}, ato, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      if(ato?.id == atos[i]?.id){
        atos[i].templateMinuta = ato.texto;
      }
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, ato?.id == atos[i]?.id ? 'gerar-minuta' : 'processar', usuarioLogado);
      if(ato == atos[i]?.id){
        return {atos, ficha};
      }
    }

    return {atos, ficha};

  },

  async simular(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'completo', usuarioLogado);
    }

    return {atos, ficha};

  },

  async simularAto(ato, posicao, protocolo, ficha, documentos, modo = 'completo', usuarioLogado){
    ato.envolvidos = ato?.envolvidos || [];
    let simulacaoCompleta = modo == 'completo' || modo == 'gerar-minuta';
    ato.erros = [];

    if(ficha.status == 'PENDENTE'){
      ficha.prematricula = true;
    }

    const criarErro = (msg, area = '', tipo = 'erro') => {
      ato.erros.push({area, msg, tipo});
    };

    //atualiza ato (para minuta e demais acoes)
    ato.ficha = Utils.clone(ficha);
    ato.codigo = (ficha.status == 'PENDENTE' || (ato.tipo || ato.tipoServico?.opcoes?.tipoAto) === 'ABERTURA') ? 0 : (parseInt(ficha.ultimoAto || 0,10) + 1);

    let tipo = ato.tipoServico.opcoes.tipoAto == 'REGISTRO' ? 'R.' : 'Av.';
    ato.codigoStr = ato.tipoServico.opcoes.tipoAto == 'ABERTURA' ? 'M.0' : tipo + ato.codigo;
    ficha.ultimoAto = ato.codigo;

    const motivoIsencao= VueBusiness.getVueRoot().isEstadoCE ? ato.ressarcimento?.motivo : ato.ressarcimento?.motivoIsencao;

    if(ato.isento && (Utils.isEmptyNullOrUndefined(motivoIsencao))){
      if(VueBusiness.getVueRoot().isEstadoSC){
        if(Utils.isEmptyNullOrUndefined(ato.ressarcimento?.tipoProcesso) || (ato.ressarcimento?.tipoProcesso != 'NAO_RESSARCIVEL' && Utils.isEmptyNullOrUndefined(ato.ressarcimento?.numeroProcesso))){
          criarErro(`Necessário preencher os dados de ressarcimento.`, null,  'erro');
        }
      }

      if(VueBusiness.getVueRoot().isEstadoMG || VueBusiness.getVueRoot().isEstadoCE){
        criarErro(`Necessário preencher os dados de ressarcimento.`, null,  VueBusiness.getVueRoot().isEstadoMG ? 'erro' : 'alerta');
      }
    }

    if (VueBusiness.getVueRoot().isEstadoPE) {
      if (Utils.isEmptyNullOrUndefined(ato.guia)){
        criarErro(`Necessário informar o número da Guia SICASE`, null,  'erro');
      }
    }

    await atoBusiness.validarAto(protocolo.id,ato.livro, ato.id, simulacaoCompleta, modo).then(alertas=>{
      alertas.map(a=>{
        criarErro(a.message,a.area, a.tipo);
      });
    });

    for(let envolvido of (ato.envolvidos || [])){
      envolvido.indicadorPessoalVersao = await IndicadorPessoalBusiness.getVersaoByIdComCache(envolvido.indicadorPessoalVersaoId);

      // if(envolvido.indicadorPessoalVersao.tipoPessoa == 'FISICA'){
      //   let pep = await PessoaExpostaPoliticamenteBusiness.getByDocumento(envolvido.documento);
      //   if (pep || envolvido.indicadorPessoalVersao.expostaPoliticamente) {
      //     criarErro("A parte envolvida " + envolvido.nome + " é uma pessoa exposta politicamente", null,  'warning');
      //   }
      // }

      if(VueBusiness.getVueRoot().isEstadoSC){
        let campos = IndicadorPessoalBusiness.validarEndereco(envolvido.indicadorPessoalVersao);
        if(campos?.length){
          criarErro(`O endereço do envolvido ${envolvido.nome} está incompleto: ${FrontBusiness.formatArray(campos)}`, '', 'warning');
        }
      }

    }

    if(ato.tipoServico?.opcoes?.envolvidosSemHashCNIB == false && ato.envolvidos.find(e => Utils.isEmptyNullOrUndefined(e.consultaCnib))){
      criarErro("Necessário informar hash da consulta CNIB das partes envolvidas", 'envolvidos', 'erro');
    }

    (ato.tipoServico.opcoes.papeisObrigatorios || []).forEach(papel => {
      if(!ato.envolvidos.find(e => e.papel == papel)){
        criarErro(`Necessário informar uma parte envolvida como ${TipoServicoBusiness.nomePapel(papel)}`, 'envolvidos', 'erro');
      }
    });

    if(ato.tipoServico.opcoes.restricaoOpcional){
      const c1 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas.find(a => ['ONUS', 'ACAO', 'INDISPONIBILIDADE'].includes(a));
      const restricao_acao = ato.dto?.configuracaoRestricao?.restricoesSelecionadas.find(r => r === 'ACAO');
      const papeis_obrigatorios = [...['DEVEDOR', 'EMITENTE', 'CREDOR', 'GARANTIDOR', 'FIEL_DEPOSITARIO'], ...(restricao_acao ? ['AUTOR', 'REU'] : [])];
      const pObrigatorios1 = ato.tipoServico?.opcoes?.papeisObrigatorios.find(p => papeis_obrigatorios.includes(p));
      if(c1 && !pObrigatorios1 && !ato.envolvidos.find(e => papeis_obrigatorios.includes(e.papel))){
        criarErro(`Necessário informar uma parte envolvida como Devedor, Emitente, Garantidor, Fiel Depostário${restricao_acao ? ', Credor, Autor ou Réu' : ' ou Credor'} para a criação da restrição`, 'envolvidos', 'erro');
      }

      const c2 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas.find(a => ['COMPROMISSO'].includes(a));
      const pObrigatorios2 = ato.tipoServico?.opcoes?.papeisObrigatorios.find(p => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(p));
      if(c2 && !pObrigatorios2 && !ato.envolvidos.find(e => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(e.papel))){
        criarErro('Necessário informar uma parte envolvida como Promitente Transmitente ou Promitente Adquirente para a criação da restrição', 'envolvidos', 'erro');
      }

      const c3 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas.find(a => ['DOMINIO'].includes(a));
      const pObrigatorios3 = ato.tipoServico.opcoes.papeisObrigatorios.find(p => ['DEVEDOR'].includes(p));
      if(c3 && !pObrigatorios3 && !ato.envolvidos.find(e => ['DEVEDOR'].includes(e.papel))){
        criarErro('Necessário informar uma parte envolvida como Devedor para a criação da restrição', 'envolvidos', 'erro');
      }
    }

    //verifica exigencia
    if (ato?.exigencias?.length) {
      criarErro("O ato possui exigências", 'exigencia', 'erro');
    }


    if (simulacaoCompleta && !ato?.indices?.respondidos || ato?.indices?.respondidos < ato?.indices?.quantidade) {

      if(!ato.legado){
        // INÍCIO: Montar Contador por Grupo de Checklist
        const item = await AtoBusiness.getById(protocolo.id, ato.id);

        const vinculos = {documentos};
        if (item.envolvidos) {
          vinculos.envolvidos = item.envolvidos;
        }
        if (item.indicadorReal) {
          vinculos.matricula = item.indicadorReal;
        }

        let resultados = (item.checklist || []).map(checklist => {
          return ChecklistProcessamentoBusiness.tratarGrupoChecklist({requisitos : checklist.checklist, id: checklist.id, titulo: checklist.titulo}, {...item.indices}, vinculos)
        });

        resultados = await Promise.all(resultados);

        for(let checklist of (resultados || [])){
          if(checklist.contador.quantidade > 0 && checklist.contador.respondidos < checklist.contador.quantidade){
            let msgErro = 'Checklist está incompleto: ';
            let titulo = checklist.titulo || checklist.nome;
            let area = `checklist-${checklist.id}.checklist-group .nao-respondido`;
            if(VueBusiness.getVueRoot().getPermission({id: 'PERMITIR_REGISTRAR_CHECKLIST_INCOMPLETO_PROTOCOLO', dominio: protocolo?.dominio || 'PROTOCOLO_RI'})){
              criarErro(msgErro + titulo, area, 'alerta');
            }else{
              criarErro(msgErro + titulo, area, 'erro');
            }
          }
        }

      }

    }

    //ENCERRAMENTO DE MATRICULA
    if(['CANCELAMENTO', 'ENCERRAMENTO'].includes(ato.tipoServico.opcoes.operacaoRegistroAuxiliar)){
      let msg = `Este ato ${ato.tipoServico?.opcoes?.operacaoRegistroAuxiliar == 'CANCELAMENTO' ? 'cancelará' : 'encerrará'} o ${ficha.status == 'PENDENTE' || ficha.prematricula? 'pré-' : ''}${FrontBusiness.nomearLivro(ficha.livro).toLowerCase()}.`
      criarErro(msg, null,  'warning');
      ficha.status = ato.tipoServico.opcoes.operacaoRegistroAuxiliar == 'CANCELAMENTO' ? 'CANCELADA' : 'ENCERRADA';
    }

    //gerar minuta
    ato.debug = {};
    if(simulacaoCompleta){

      if(modo == 'gerar-minuta' || !ato?.tipoServico?.opcoes?.editarMinuta){
        ato.minuta = await LivroMatriculaBusiness.gerarMinutaAto(Utils.clone(ato), Utils.clone(ficha), Utils.clone(protocolo), Utils.clone(documentos), usuarioLogado);
      }else{
        ato.minuta = ato.texto;
      }

      const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
      if(minutaInvalida){
        criarErro(minutaInvalida, 'minuta', 'erro');
      }

    }

    const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
    if(minutaInvalida){
      criarErro(minutaInvalida, 'minuta', 'erro');
    }

    //ABERTURA DE MATRICULA - DEVE SEMPRE SER APÓS A GERAÇÃO DA MINUTA
    if(ficha.status == 'PENDENTE'){
      if(ato.tipoServico.opcoes.tipoAto != 'ABERTURA'){
        criarErro('O primeiro ato deve ser um ato de abertura', '', 'erro');
      }else{
        ficha.status = 'ABERTA';
      }
    }

    //verifica erros no calculo de custa
    if(ato.custas?.erro){
      criarErro(ato.custas.erro?.descricao || ato.custas.erro, '', 'erro');
    }

    if (ato.tipoServico.opcoes.editarRegistroAuxiliar){
      let data = ato.dto?.pacto;
      if (data && (moment(data).isAfter(moment()) || !moment(data).isValid() || data.split('-')[0] < '1850')) {
        criarErro(`Necessário informar uma data válida para o Pacto Antenupcial`, 'editar-registro-auxiliar', 'erro');
      }

      ['tipoCedula','vencimento','pacto', 'condominio', 'texto', 'observacoes'].forEach(k => {
        if(ato.dto[k] != undefined && ato.dto[k]){
          ficha[k] = ato.dto[k];
        }
      });

      if (ato.tipoServico.opcoes.tipoAto === 'ABERTURA') {

        switch (ato.tipoServico.opcoes.tipoRegistro) {
          case 'CEDULA':
            if (ficha.tipoCedula == null) {
              criarErro("Necessário informar o tipo da cédula", 'indicador');
            }

            break;
          case 'PACTO':

            if (ficha.pacto == null) {
              criarErro("Necessário informar a data do pacto", 'indicador');
            }

            if (ato.envolvidos.filter(e => e.papel == 'CONTRATANTE').length != 2) {
              criarErro("Necessário informar 2 partes envolvidas como Contratante", 'envolvidos');
            }

            break;
          case 'CONVENCAO_CONDOMINIO':

            if ((ficha.condominio || '').trim().length === 0) {
              criarErro("Necessário informar o nome do condomínio", 'condominio');
            }

            break;
        }

      }

      ficha.valor = ato.dto.valoresBaseCalculo?.valorRegistro;

    }

    if(simulacaoCompleta) {
      await SimulacaoAtoBusiness.simulacaoRestricao(ficha, ato);
    }

  },

}

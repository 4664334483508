<template>
  <div id="aba-resumo">

    <card titulo="Resumo">

      <small-loading v-if="loading" :loading="true"></small-loading>

      <card-content v-if="resumo">
        <div v-html="resumo"></div>
      </card-content>

      <div v-if="!resumo.length && !loading" class="alert alert-outline-info">
        Não há resumo para exibir
      </div>
    </card>

  </div>
</template>

<script>
  import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness.js";
  import FragmentoBusiness from "@/business/crud/FragmentoBusiness.js";
  import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness.js";
  import TextParser from "@/business/livros/TextParser.js";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import SmallLoading from "@/components/SmallLoading.vue";

  export default {

    name: "Resumo",
    components: {SmallLoading, CardContent, Card},
    props: {
      ficha: Object,
    },

    data() {
      return {
        resumo: '',
        loading : true
      }
    },

    async mounted(){

      let configuracao = this.$root.config;
      if(configuracao.resumoIndicadorReal){
        let fragmento = await FragmentoBusiness.getById(configuracao.resumoIndicadorReal.id);
        this.resumo = await LivroMatriculaBusiness.gerarMinutaAto({tipoServico : {minuta : fragmento.minuta}}, this.ficha);
      }

      this.loading = false;

    },

  }
</script>

<style scoped>

  .td-toggle {
    text-align:right;
    vertical-align:top;
  }
  tbody {
    border-top-width: 0px !important;
  }

</style>

import Http from "@/commons/Http";
import VueBusiness from "@/business/VueBusiness";
import AtribuicaoBusiness from "@/business/crud/AtribuicaoBusiness";
import DominioBusiness from "@/business/protocolo/DominioBusiness";
import Utils from "@/commons/Utils.js";

const path = 'api/grupo-permissao';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList() {
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids) {
    return Http.post(`${path}/simple`, ids);
  },

  async getAllSimpleList() {
    return Http.get(`${path}/all-simple`);
  },

  async getById(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async save(dto, id = '') {
    return Http.post(`${path}/${id}`, dto);
  },

  async getPermission(completo = true) {
    return Http.get(`${path}/permissoes/${completo}`);
  },

  async checkPermission(permissao = '') {
    return Http.get(`${path}/check-permission/${permissao}`);
  },

  getPermissionOrAssignment(params={}) {
    const root = VueBusiness.getVueRoot();
    let {id, protocolo} = params;
    let usuario = root.usuarioLogado;
    if (!usuario || usuario.auditor) return false;
    let dono = protocolo.usuario?.id != undefined && usuario.id === protocolo.usuario?.id;
    let antigo = false;
    if (root.atribuicao[protocolo.id] || dono) {
      let comAtribuicao = root.getPermission({id: `${id}_COM_ATRIBUICAO`, protocolo })
      if (['PERMITIR_EDICAO_COMPLETA', 'PERMITIR_CANCELAR_PROTOCOLO'].includes(id)) antigo = !(root.usuarioLogado?.permissao || []).length;
      return comAtribuicao || antigo;
    } else {
      return root.getPermission({id: `${id}_SEM_ATRIBUICAO`, protocolo, olharAtribuicao: false })
    }
    return false;
  }

}

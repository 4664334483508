<template>
  <div class="dropdown dropleft with-arrow" :class="{'show' : show}">

    <button class="btn btn-icon" type="button" @click.prevent="open()">
      <i class="fas fa-filter"></i>
    </button>

    <div class="dropdown-menu">
      <div class="dropdown-content auto-scroll p-20" :class="filterSize" v-click-outside="close" v-if="show">
        <form @submit.prevent="filtrar()">
          <p class="p-0 m-0 font-weight-bold text-muted text-uppercase">Filtrar Resultados</p>
          <div class="dropdown-divider mb-15 mt-5"></div>

          <slot name="filters"></slot>

          <div class="dropdown-divider mt-15 mb-15"></div>
          <input class="btn btn-block" type="submit" @click.prevent="filtrar()" value="Buscar Resultados">
        </form>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "VueTableFilters",

  props:{
    //w-250 = width: 25rem (250px), w-sm-350 = width: 35rem (350px) only on devices where width > 576px
    filterSize: {type : String, default : 'w-500 h-400'}
  },

  data() {
    return {
      show: false
    }
  },

  methods: {
    open() {
      this.show = true;
    },
    close() {
      if(this.show){
        this.show = false;
      }
    },
    filtrar(){
      this.$emit('filtrar');
      this.close();
    }
  }

}
</script>

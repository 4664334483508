<template>
  <modal titulo="Editar Indicadores em Lote" id="editar-indicadores-lote" :fechar="true" @close="close" :loading="loading">

    <template>
      <div class="row m-0">
        <v-input class="col-auto p-0" v-model="buscar" :options="options.livros" type="select" />
        <buscar-fichas :completo="true" :limparCampo="true" multiplos :livro="livroSelecionado" :status="statusSelecionado" ref="buscarFichas" @selecionar="selecionar($event)"  class="pl-2 pr-0 col"/>
      </div>
      <div class="row mx-0">

        <div class="col-5 mr-2 p-0" style="max-width: 315px">
          <card titulo=" " class="col mr-3 p-0">
            <div class="div-select-all">
              <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
                <i class="ml-2 fal fa-check-circle"></i>
              </a>
              <a @click.prevent="selecionarTodos( true)" title="Limpar Seleção" class="icon">
                <i class="ml-2 fal fa-circle"></i>
              </a>
            </div>

              <div class="form-group ml-3 mt-2 mb-0" v-for="(campo, i) in campos" :key="i">
                <input style="position: relative; top: 2px; left: 0px;" type="checkbox" :id="'editar-Lote'+campo.id"
                       v-model="dto[campo.id]"/>
                <label :for="'editar-Lote'+campo.id" :title="campo.nome" style="position: relative; top: 0px; left:4px;"
                       class="pointer">
                  {{campo.nome}}
                </label>
              </div>

          </card>
        </div>

        <card titulo="Indicadores Relacionados" class="p-0 col">

          <div v-dragscroll="true" class="card-table table-borderless" style="overflow: auto;">
            <div class="alert alert-outline-secondary alert-sm mb-0" v-if="!indicadores.length">
              Nenhum Indicador relacionado
            </div>
            <card-table style="min-width: 100%; width: max-content;" v-else>
              <thead>
              <tr>
                <th width="30px"></th>
                <th></th>
                <th v-for="(c) in columns" :key="c.id" style="min-width: 350px;">
                  <div class="d-flex justify-content-between align-items-center" style="gap: 10px">
                    {{c.nome}}
                    <div>
                      <v-button v-if="c.id === 'especificacao'" :run="utilizarModeloTodos" :params="[c]" :button="false" :popover="true"
                                label="Essa ação irá aplicar o modelo para todos os registros. Você tem certeza?"
                                position="dropleft" class="icon mr-3">
                        <i class="fal fa-file-alt pointer" title="Utilizar Modelo"></i>
                      </v-button>
                      <v-button :run="replicar" :params="[c]" :button="false" :popover="true"
                                label="Essa ação irá replicar o valor do primeiro registro
                              dessa coluna para os demais. Você tem certeza?"
                                position="dropleft" class="icon">
                        <i class="fal fa-clone pointer" title="Replicar"></i>
                      </v-button>
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(ficha, i) in indicadores" >
                <tr :key="'ficha_'+(ficha.id || i)">
                  <td>
                    <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
                  </td>
                  <td  style="font-weight: bold;">
                    {{nomearFicha(ficha)}}
                  </td>
                  <td v-for="(c) in columns" :key="c.id"  width="160px" style="cursor: pointer">
                    <div v-if="['especificacao', 'certidao', 'observacao'].includes(c.id)" :ref="c.id"
                         style="min-height: 200px">
                      <async-input>
                        <template #input>
                          <template v-if="existModeloEspecificaoImovel && c.id === 'especificacao'" >
                            <v-button :button="false" :run="utilizarModelo" :params="[ficha, i]" class="icon float-right pointer mr-3" :popover="true">
                              UTILIZAR MODELO
                            </v-button>
                          </template>
                          <v-input class="mb-0 lote-editor" style="width: 500px" :small="true" :type="c.tipo"
                                   v-model="ficha[c.id]" :placeholder="c.nome" @change="() => setAlteracao(ficha)"/>
                        </template>
                      </async-input>
                    </div>

                    <template v-else-if="c.id === 'unidadeAutonoma'">
                      <input id="FLAG_UNIDADE_AUTONOMA_LIST" style="margin: auto; display: block;" :value="false" type="checkbox" @change="() => setAlteracao(ficha)"
                             v-model="ficha[c.id]" />
                    </template>

                    <template v-else-if="c.id === 'descricaoTipoImovelOutros'">
                      <v-input v-if="ficha.tipoImovel === 'OUTROS'" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['ultimoAto', 'status'].includes(c.id) && ficha.status !== 'PENDENTE'">
                      <v-input :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :input-props="{precision : 0}" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="c.id === 'encerramento'">
                      <v-input v-if="ficha.status !== 'ABERTA'" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="ficha.status === 'CANCELADA' ? 'Cancelamento' : 'Encerramento'" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['numeroRipSpu'].includes(c.id)">
                      <v-input v-if="ficha.imovelUniao" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :masks="['#### #######-##']" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['regime', 'medidaAreaUniao', 'areaUniao'].includes(c.id)">
                      <v-input v-if="ficha.imovelUniao" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :input-props="{precision : 10}" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['unidade', 'bloco', 'andar', 'pavimento', 'grupo', 'fracaoSolo', 'fracaoSoloPercentual'].includes(c.id)">
                      <v-input v-if="ficha.unidadeAutonoma" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :input-props="{precision : 10}" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['coeficienteProporcionalidade'].includes(c.id)">
                      <v-input v-if="ficha.unidadeAutonoma" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :input-props="{currencyDisplay: 'hidden', precision : {min : 0, max : 10}}" @change="() => setAlteracao(ficha)" />
                    </template>

                    <template v-else-if="['origens'].includes(c.id)">
                      <registro-anterior-lista @update="setFichaOrigens($event, i)" :registros="ficha.origens" :is-importar-registro-anterior="false" :visualizacao-lote="true"></registro-anterior-lista>
                    </template>

                    <template v-else-if="['denominacao', 'incra', 'itr', 'car', 'certificacaoIncra'].includes(c.id)">
                      <v-input v-if="ficha.categoria === 'RURAL'" :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.options"
                               :placeholder="c.nome" :input-props="{precision : 10}" @change="() => setAlteracao(ficha)" />
                    </template>

                    <v-input v-else :small="true" :type="c.tipo" v-model="ficha[c.id]" :options="c.id === 'cidade' ? montarOptions(ficha[c.id]) : c.options"
                             :placeholder="c.nome" :input-props="{precision : 10}" @change="() => setAlteracao(ficha)" />
                  </td>

                </tr>
              </template>

              </tbody>

            </card-table>
          </div>
        </card>
      </div>

    </template>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" :popover="true" class="btn-outline-primary">Alterar</v-button>
    </template>
  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import TextParser from "@/business/livros/TextParser.js";
import Utils from "@/commons/Utils.js";
import AsyncInput from "@/components/Form/AsyncInput.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable";
import Modal from "@/components/Modal";

export default {
    name: "ModalEditarLote",
    components: {
      Card,
      CardTable,
      Modal,
      AsyncInput,
      RegistroAnteriorLista: () => import('@/components/IndicadorReal/RegistroAnteriorLista.vue')
    },
    props: { },

    async mounted() {
      this.loading = false;
      let camposInfoComplementar = IndicadorRealBusiness.getCamposInfoComplementar('', true);
      let camposCadastroIdentificao = IndicadorRealBusiness.getCamposCadastroIdentificacao('', false);
      let camposEndereco = IndicadorRealBusiness.getCamposEndereco();
      let camposEspecificacao = IndicadorRealBusiness.getCamposEspecificao();

      this.campos = [
        {id: 'origens', nome: 'Registro Anterior'},
        {id: 'categoria', nome: 'Categoria', tipo: 'radio', options: IndicadorRealBusiness.getCategorias()},
        {id: 'unidadeAutonoma', nome: 'Unidade Autônoma'},
        {id: 'tipoImovel', nome: 'Tipo de imóvel', tipo: 'select', options: IndicadorRealBusiness.getTiposImovel()},
        {id: 'descricaoTipoImovelOutros', nome: 'Descrição do Tipo de Imóvel', tipo: 'text'},
        {id: 'ultimoAto', nome: 'Último Ato', tipo: 'currency'},
        {id: 'abertura', nome: 'Abertura', tipo: 'datetime-local'},
        {id: 'encerramento', nome: 'Cancelamento/Encerramento', tipo: 'datetime-local'},
        {id: 'imovelUniao', nome: 'Imóvel da União', tipo: 'radio'},
        {id: 'marinha', nome: 'Imóvel da Marinha', tipo: 'radio'},
        {id: 'fronteira', nome: 'Imóvel de Fronteira', tipo: 'radio'},
        {id: 'numeroRipSpu', nome: 'Número RIP SPU', tipo: 'mask'},
        {id: 'regime', nome: 'Regime', tipo: 'select', options: IndicadorRealBusiness.getRegimesImovelUniao()},
        {id: 'medidaAreaUniao', nome: 'Terreno da União: Medida área', tipo: 'select', options: IndicadorRealBusiness.getMedidasUniao()},
        {id: 'areaUniao', nome: 'Terreno da União: Área', tipo: 'currency'},
        ...camposInfoComplementar,
        ...camposCadastroIdentificao,
        ...camposEndereco,
        {id: 'medidaArea', nome: 'Medida área', tipo: 'select', options: IndicadorRealBusiness.getMedidas()},
        {id: 'area', nome: 'Área total', tipo: 'currency'},
        {id: 'fracaoSolo', nome: 'Fração solo', tipo: 'currency'},
        {id: 'fracaoSoloPercentual', nome: 'Fração solo (%)', tipo: 'currency'},
        {id: 'coeficienteProporcionalidade', nome: 'Coeficiente de proporcionalidade', tipo: 'currency'},
        ...camposEspecificacao,
        {id: 'certidao', tipo: 'editor', nome: 'Observações para Certidão'},
        {id: 'observacao', tipo: 'editor', nome: 'Observações Internas'},
      ];
      await this.initValues();
    },

    watch: {},

    modal: {
      width: 2000
    },

    data() {
      return {
        loading: true,
        indicadores: [],
        campos: [],
        buscar : 'MATRICULA',
        options: {
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        },
        alteracao: false,
        dto: {},
      }
    },

    computed: {
      columns() {
        let columns = [];
        Object.keys(this.dto).forEach(key => {
          if (this.dto[key]) {
            columns.push(this.campos.find(f => f.id === key))
          }
        });
        return columns;
      },

      livroSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
      },

      statusSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
      },

      existModeloEspecificaoImovel(){
        return this.$root.config.outrosTemplates?.especificaoImovel;
      }
    },

    methods: {
      montarOptions(atual) {
        let cidades = Utils.clone(this.$root.cidades);
        if(atual && !cidades.find(m => m.id === atual)){
          cidades.push({
            id: atual,
            nome: atual
          });
        }
        return cidades;
      },

      async selecionar(itens) {
        this.$nextTick(() => {
          this.loading = true;
          itens?.forEach((item) => {
            if (item && !this.indicadores.find(e => e.id == item.id)) {
              this.indicadores.push(item);
            }
          });
          this.loading = false;
        });
      },

      remover(idx) {
        this.$nextTick(() => this.indicadores.splice(idx, 1));
      },

      async initValues() {
        this.validation = [];
        this.campos.forEach((col) => {
          this.$set(this.dto, col.id, false);
        });
      },

      replicar(coluna) {
        let valor = this.indicadores[0][coluna.id];
        for (let a in this.indicadores) {
          if(!coluna.id == 'origens') {
            this.indicadores[a][coluna.id] = valor;
          } else {
            this.indicadores[a][coluna.id] = Utils.clone(this.indicadores[0][coluna.id]);
          }
        }
        this.$set(this, 'indicadores', this.indicadores);
        this.$set(this, 'alteracao', true);
      },

      setAlteracao() {
        this.$set(this, 'alteracao', true);
      },

      setItem(campo) {
        this.$set(this.dto, campo.id, !!!this.dto[campo.id]);
        this.$forceUpdate();
      },

      async selecionarTodos(limpar = false) {
        let total = Object.keys(this.dto).length;
        this.loading = true;
        for (let i in this.dto) {
          setTimeout(async () => {
            this.$set(this.dto, i, !limpar)
            total--;
            if (total === 0) {
              this.loading = false;
            }
          });
        }
      },

      async utilizarModelo(ficha, i){
        if(this.existModeloEspecificaoImovel){
          this.$set(this.indicadores[i], 'especificacao', '');
          let modelo = this.$root.config.outrosTemplates?.especificaoImovel;
          modelo = await TextParser.gerar(modelo, {imovel: Utils.clone(ficha)});
          this.$set(this.indicadores[i], 'especificacao', modelo);
          this.$set(this, 'alteracao', true);
        }
      },

      async utilizarModeloTodos() {
        this.loading = true;
        for (let i in this.indicadores) {
          await this.utilizarModelo(this.indicadores[i], i)
        }
        this.$set(this, 'alteracao', true);
        this.loading = false;
      },

      async close(retorno) {
        FrontBusiness.closeModal(retorno);
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#editar-indicadores-lote');
      },

      setFichaOrigens(registros, index) {
        this.$set(this, 'alteracao', true);
        this.$set(this.indicadores[index], 'origens', registros);
      },

      async salvar() {
        if (!this.indicadores.length) return FrontBusiness.showWarn('', 'Necessário informar ao menos um Indicador Real');
        if (!this.columns.length) return FrontBusiness.showWarn('', 'Necessário informar ao menos um campo para sofrer alteração');
        if (!this.alteracao) return FrontBusiness.showWarn('', 'Nenhuma alteração realizada');
        let dto = Utils.clone(this.indicadores);
        dto.forEach(i => {
          i.registroAuxiliar = (i?.registroAuxiliar || []).map((f) => ({id: f.id}));
          if (i.numeroRipSpu) {
            i.numeroRipSpu = i.numeroRipSpu.replace(/[^\d]/g, '');
          }
          delete i.textoLegado;
        });
        let indicador = await IndicadorRealBusiness.lote(dto).catch(this.showError);
        if (indicador) {
          FrontBusiness.showSuccess('', 'Alterações realizadas com sucesso');
          await this.close(true)
        }
      },
      nomearFicha: FrontBusiness.nomearFicha,
    }
  }
</script>

<style lang=scss>
  #editar-indicadores-lote {


    .lote-editor {
      .tox-tinymce {
        height: 200px !important;
        .document-editor__editable-container {
          height: 200px;
        }
      }
    }

    .card-title {
      /*white-space: nowrap;*/
      /*overflow: hidden;*/
      /*text-overflow: ellipsis;*/
      line-height: 1.2rem;
    }

    .div-select-all {
      position: absolute;
      top: 8px;
      right: 17px;
    }
  }
</style>

<template>
  <card :titulo="titulo" :chave="titulo">

    <card-table class="table-borderless mb-2" v-if="propriedades.length > 0">
      <thead>
      <tr>
        <th>MATRÍCULA</th>
<!--        <th v-if="!propriedadesAtuais">DATA DE TRANSMISSÃO</th>-->
      </tr>
      </thead>
      <tbody>
        <tr v-for="propriedade in propriedades" :key="propriedade.id">
          <td>
            <a href class="mb-1 pointer" @click.prevent="detalhesPropriedade(propriedade.id)">
              {{nomearFicha(propriedade)}}
            </a>
            <div class="w-100 mt-1 small text-muted">
              <resumo-indicador-real :imovel="propriedade" />
            </div>
          </td>
<!--          <td v-if="!propriedadesAtuais" class="text-truncate">-->
<!--            <a href class="mb-1 pointer">-->
<!--              {{$formatters.date(propriedade.registro)}}-->
<!--            </a>-->
<!--          </td>-->
        </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!propriedades.length && !loading" class="alert alert-outline-info">
      {{alertaSemPropriedades}}
    </div>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
  import ResumoIndicadorReal from "@/components/IndicadorReal/ResumoIndicadorReal.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";

  export default {

    name: "Propriedades",
    components: {ResumoIndicadorReal, SmallLoading, CardTable, Card},
    props: {
      pessoa : Object,
      propriedadesAtuais: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        propriedades : [],
        loading: true,
        tiposImovel: IndicadorRealBusiness.getTiposImovel(),
      }
    },

    computed: {

      titulo(){
        return (this.propriedadesAtuais ? 'Propriedades Atuais' : 'Ex-propriedades') + ` (${this.propriedades.length} indicadores)`;
      },

      alertaSemPropriedades(){
        return this.propriedadesAtuais ? 'Não há propriedades para exibir' : 'Não há ex-propriedades para exibir';
      }

    },

    async mounted(){
      if(this.propriedadesAtuais){
        this.propriedades = await IndicadorPessoalBusiness.buscarPropriedadesAtuais(this.pessoa.indicadorPessoalId);
      } else {
        this.propriedades = await IndicadorPessoalBusiness.buscarExPropriedades(this.pessoa.indicadorPessoalId);
      }
      this.loading = false;
    },

    methods:{

      detalhesPropriedade(propriedadeId) {
        FrontBusiness.openModal(DetalhesIndicadorReal, {id: propriedadeId});
      },

      nomearFicha: FrontBusiness.nomearFicha,

      labelTipoImovel(propriedade) {
        return this.tiposImovel.find(e => e.id == propriedade.tipoImovel)?.nome;
      },

    }
  }
</script>

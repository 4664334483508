<template>

  <modal id="detalhes-registro-auxiliar" class="modal-detalhes-entidade" :titulo="titulo" :loading="loading" :check="registroValido">
    <template #title-extra>
      <div v-if="ficha.status !== 'ABERTA'" class="badge text-center" :class="classStatus">
        {{ficha.status}}
      </div>
    </template>
    <template #actions>

      <template v-if="!usuario.auditor && ficha.status != 'PENDENTE'">

        <li class="list-inline-item" :class="{active : aba == 'anexos'}" >
          <a @click.prevent="aba = 'anexos'" title="Documentação & Anexos" href>
            <i class="fal fa-paperclip"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'historico'}">
          <a @click.prevent="aba = 'historico'" title="Histórico" href>
            <i class="fas fa-history"></i>
          </a>
        </li>

      </template>

        <li class="list-inline-item">
          <router-link :to="{ name: 'digitalizacao', params: { id: id, entidade : 'REGISTRO_AUXILIAR', tipoEntidade : 'REGISTRO_AUXILIAR' }}" target="_blank" title="Digitalização">
            <i class="fal fa-file-image"></i>
          </router-link>
        </li>

      <template v-if="!usuario.auditor && ficha.status != 'PENDENTE'">

        <li class="list-inline-item">
          <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
            <i class="fal fa-print"></i>
          </a>
        </li>

      </template>

      <li class="list-inline-item" v-if="!usuario.auditor">
        <a @click.prevent.stop="editarRegistroAuxiliar(ficha)" title="Editar Registro Auxiliar"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <template v-if="ficha.id">
      <template v-if="!usuario.auditor">

        <template v-for="(alerta, index) in alertas" >
          <AlertBanner v-if="alerta.mostrar" :key="index" :message="alerta.msg"/>
        </template>

      </template>

      <template v-if="this.componente === 'aba-atos' && ficha.textoLegado " >
        <a @click.prevent="vizualizarTextoLegado(ficha)" title="Vizualizar Texto" href>
          <AlertBanner message="Registro auxiliar possui um texto importado!"/>
        </a>
      </template>

    </template>
    <component v-if="!loading" :is="componente" :ficha="ficha" :id="ficha.id" @load="load"/>


    <template #footer>
      <v-button v-if="$root.getPermission({id: 'PERMITIR_EXCLUIR_REGISTRO_AUXILIAR'})"
                :run="excluir"
                :popover="true"
                position="dropupleft"
                class="btn btn-outline-danger ml-auto">
        Excluir
      </v-button>
    </template>
  </modal>

</template>

<script>

  import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
  import AlertBanner from "@/components/alert/AlertBanner";
  import AlertMessage from "@/components/alert/AlertConstMessage";
  import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
  import Modal from "@/components/Modal";
  import Anexos from "@/components/RegistroAuxiliar/Detalhes/Anexos";
  import Atos from "@/components/RegistroAuxiliar/Detalhes/Atos.vue";
  import Historico from "@/components/RegistroAuxiliar/Detalhes/Historico.vue";
  import Dados from "@/components/RegistroAuxiliar/Detalhes/Dados";
  import Protocolos from "@/components/RegistroAuxiliar/Detalhes/Protocolos.vue";
  import FormRegistroAuxiliar from "@/components/RegistroAuxiliar/Form/FormRegistroAuxiliar.vue";
  import TextoLegado from "@/views/Protocolo/Atos/TextoLegado";
  import EventBus from "@/commons/EventBus";

  export default {

    name: "DetalhesRegistroAuxiliar",
    components: {
      Modal,
      AlertBanner,
      "aba-dados" : Dados,
      "aba-atos" : Atos,
      "aba-historico" : Historico,
      "aba-anexos" : Anexos,
      "aba-protocolos" : Protocolos,
    },

    props: {
      id : String,
      abaInicial: String
    },

    modal: {
      width: 900
    },

    async mounted(){
        await this.load();

      AuditoriaBusiness.auditarTela({id: this.id, entidade: 'REGISTRO_AUXILIAR', descricao: `Tela: Detalhes do Indicador` })
        .catch(e => null);

      EventBus.$on('ATOS-LOAD', this.load);
    },

    computed:{
      titulo(){
        return this.ficha?.livro ? FrontBusiness.nomearFicha(this.ficha) : '';
      },
      componente(){
        return `aba-${this.aba}`;
      },
      alertas() {
          return [
              {mostrar: this.impressaoDesatualizada, msg: AlertMessage.impressaoDesatualizada()},
              {mostrar: this.digitalizacaoDesatualizada, msg: AlertMessage.digitalizacaoDesatualizada()},
              {mostrar: this.protocolosAtivos, msg: AlertMessage.registroAuxiliarPossuiProtocolos()}
          ];
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      classStatus() {
        if (this.ficha.status == 'ENCERRADA') {
          return 'badge-primary-soft';
        } else if (this.ficha.status == 'CANCELADA') {
          return 'badge-danger-soft';
        }
        return 'badge-success-soft'
      }
    },

    data() {
      return {
        ficha : {},
        aba : 'dados',
        protocolosAtivos: false,
        atos : [],
        impressaoDesatualizada : false,
        digitalizacaoDesatualizada : false,
        registroValido: false,
        abas:[],
        loading: true
      }
    },

    methods:{
      showError(e) {
        FrontBusiness.showError('', e.message);
      },

      async load(loading = true){
        this.loading = loading;
        this.ficha = await RegistroAuxiliarBusiness.getById(this.id);

        let protocolos = [];
        const auditor = this.usuario.auditor;

        await Promise.all([
          RegistroAuxiliarBusiness.buscarAtosRegistrados(this.ficha.id).then(r => this.atos = r),
          !auditor ? RegistroAuxiliarBusiness.buscarProtocolosAtivos(this.ficha.id).then(r => protocolos = r) : null,
          !auditor ? this.checarImpressao() : null,
          this.registroAuxiliarValidado()
        ]);

        this.protocolosAtivos = protocolos.length > 0;

        let exibir = !auditor && !this.preview;
        let isPendente = this.ficha.status == 'PENDENTE';

        this.abas = [
          {id: 'dados', nome: 'Dados', exibir: true, title: 'Dados Principais'},
          {id: 'atos', nome: 'Atos Registrados', exibir: !this.preview && !isPendente, title: 'Atos Registrados'},
          {id: 'protocolos', nome: 'Protocolos', exibir: exibir},
          // {id: 'anexos', nome: 'Anexos', exibir: exibir, title: 'Documentação & Anexos'}
        ];

        if (this.abaInicial) {
          this.$set(this, 'aba', this.abaInicial);
        }

        this.$set(this, 'loading', false);
      },

      async checarImpressao(){
        this.digitalizacaoDesatualizada = await RegistroAuxiliarBusiness.digitalizacaoDesatualizada(this.id);
        this.impressaoDesatualizada = await RegistroAuxiliarBusiness.impressaoDesatualizada(this.id);
      },

      async editarRegistroAuxiliar(ficha) {
          let result = await FrontBusiness.openModal(FormRegistroAuxiliar, {id: ficha.id, livro: ficha.livro});
          if(result){
              this.$set(this, 'ficha', result);
              await this.load();
          }
      },

      imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: 'REGISTRO_AUXILIAR', codigo: ficha.codigo});
      },

      async vizualizarTextoLegado(ficha){
        let retorno = await FrontBusiness.openModal(TextoLegado, {ficha: ficha, tipo: "REGISTRO_AUXILIAR"});
        if (retorno) {
          this.ficha = retorno;
        }
      },

      async registroAuxiliarValidado(){
        this.registroValido = await RegistroAuxiliarBusiness.registroAuxiliarValidado(this.ficha.id);
      },

      async excluir(){
        let retorno = await RegistroAuxiliarBusiness.delete(this.id).catch(this.showError);
        if (retorno) {
          FrontBusiness.closeModal();
        }
      }

    }

  }
</script>

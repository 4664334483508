import error from "@/business/livros/copiladores/error.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import Utils from "@/commons/Utils.js";

const usarModelo = async function(node, callback, dicionario, debug){

  let nome = getVar(node.params.nome, dicionario, false, true);

  const template = dicionario?.templates?.[nome];
  if(!template){
    return error('modelo não encontrado');
  }

  let parametro = {};
  Object.keys(node.params).forEach(key => parametro[key] = getVar(node.params[key], dicionario, false, true));

  return await callback(Utils.clone(template), Utils.clone({...dicionario, parametro}), debug);

};

export default usarModelo;

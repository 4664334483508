<template>

  <modal id="detalhes-indicador-pessoal" class="modal-detalhes-entidade" :titulo="titulo" :loading="loading">

    <template v-if="!usuario.auditor && aba != 'duplicados' && versoes > 0">

      <a @click.prevent="aba = 'duplicados'" title="Indicador com documentos duplicados" href>
        <AlertBanner message="Há versões deste Indicador Pessoal em indicadores diferentes"/>
      </a>

    </template>

    <template #actions>

      <template v-if="!usuario.auditor">

        <li class="list-inline-item" :class="{active : aba == 'historico'}" >
          <a @click.prevent="aba = 'historico'" title="Histórico" href>
            <i class="fas fa-history"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'versoes'}">
          <a @click.prevent="aba = 'versoes'" title="Versões" href>
            <i class="fal fa-user-tag"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'duplicados'}">
          <a @click.prevent="aba = 'duplicados'" title="Unificar Versões" href>
            <i class="fas fa-equals"></i>
          </a>
        </li>

        <li class="list-inline-item">
          <a @click.prevent.stop="editarIndicador(pessoa)" title="Editar Indicador"  href>
            <i class="fal fa-edit"></i>
          </a>
        </li>

      </template>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <template v-if="pessoa">

      <template v-if="!usuario.auditor">
        <alert-indisponibilidade :pessoa="pessoa.indicadorPessoalId" tipo="alert-banner" />
        <alert-ocorrencia :pessoa="pessoa.indicadorPessoalId" tipo="alert-banner" :banner="ocorrenciaBloqueante ? 'alert-danger' : 'alert-warning'" />
      </template>

      <component :key="aba" :is="componente" :pessoa="pessoa" @versaoAberta="versaoAberta" ref="comp" />
      <component :key="aba" :is="componente" :pessoa="pessoa" :propriedadesAtuais="false" v-if="aba === 'propriedades'" />

    </template>

    <template #footer>
      <v-button style="margin-left: auto;" v-if="aba == 'duplicados'" class="btn-outline-primary" :run="merge">Unificar versões</v-button>
    </template>

  </modal>

</template>

<script>

  import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import AlertBanner from "@/components/alert/AlertBanner";
  import AlertMessage from "@/components/alert/AlertConstMessage";
  import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";
  import AlertOcorrencia from "@/components/alert/AlertOcorrencia.vue";
  import Atos from "@/components/IndicadorPessoal/Detalhes/Atos.vue";
  import Dados from "@/components/IndicadorPessoal/Detalhes/Dados";
  import Documentacao from "@/components/IndicadorPessoal/Detalhes/Documentacao";
  import Indisponibilidades from "@/components/IndicadorPessoal/Detalhes/Indisponibilidades";
  import Ocorrencias from "@/components/IndicadorPessoal/Detalhes/Ocorrencias.vue";
  import Oficios from "@/components/IndicadorPessoal/Detalhes/Oficios.vue";
  import Propriedades from "@/components/IndicadorPessoal/Detalhes/Propriedades";
  import Protocolos from "@/components/IndicadorPessoal/Detalhes/Protocolos.vue";
  import RegistrosAuxiliares from "@/components/IndicadorPessoal/Detalhes/RegistrosAuxiliares";
  import RestricoesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/RestricoesIndicadorPessoal.vue";
  import Socios from "@/components/IndicadorPessoal/Detalhes/Socios";
  import Versoes from "@/components/IndicadorPessoal/Detalhes/Versoes";
  import Modal from "@/components/Modal";
  import Duplicados from "@/components/IndicadorPessoal/Detalhes/Duplicados";
  import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
  import Historico from "@/components/IndicadorPessoal/Detalhes/Historico";
  import EventBus from "@/commons/EventBus";

  export default {

    name: "DetalhesIndicadorPessoal",
    components: {
      AlertOcorrencia,
      AlertIndisponibilidade,
      AlertBanner,
      Modal,
      "aba-dados" : Dados,
      "aba-versoes" : Versoes,
      "aba-documentacao" : Documentacao,
      "aba-socios" : Socios,
      "aba-atos" : Atos,
      "aba-protocolos" : Protocolos,
      "aba-ocorrencias" : Ocorrencias,
      "aba-oficios" : Oficios,
      "aba-propriedades" : Propriedades,
      "aba-ex-propriedades" : Propriedades,
      "aba-indisponibilidades" : Indisponibilidades,
      "aba-restricoes" : RestricoesIndicadorPessoal,
      "aba-registros-auxiliares" : RegistrosAuxiliares,
      "aba-duplicados" : Duplicados,
      "aba-historico" : Historico,
    },

    modal:{
      width: 1000
    },

    props: {
      id : String,
      versao : String,
      abaInicial : String,
    },

    async mounted(){
      await this.load(this.versao);
      await  this.possuiOcorrenciasBloqueantes();
      AuditoriaBusiness.auditarTela({id: this.id, entidade: 'INDICADOR_PESSOAL', descricao: `Tela: Detalhes do Indicador` })
        .catch(e => null);

      EventBus.$on('ATOS-LOAD', this.load);
    },

    computed:{
      titulo(){
        return this.pessoa?.nome ? `${this.pessoa.nome}${this.pessoa.documento ? ` - ${this.pessoa.documento}` : ''}${this.pessoa.versao ? ` (Versão: ${this.pessoa.versao})` : ''}` : 'Detalhes Indicador Pessoal';
      },
      componente(){
        return `aba-${this.aba}`;
      },

      usuario() {
        return this.$root.usuarioLogado;
      }
    },

    data() {
      return {
        loading: true,
        versoes: 0,
        pessoa : null,
        aba : 'dados',
        ocorrenciaBloqueante: false,
        indicadorPossuiIndisponibilidade : AlertMessage.indicadorPossuiIndisponibilidade(),
        indicadorPossuiOcorrencias : AlertMessage.indicadorPossuiOcorrencias(),
        abas:[
          {id: 'dados', nome: 'Resumo', exibir: true},
          {id: 'propriedades', nome: 'Propriedades', exibir: true},
          {id: 'restricoes', nome: 'Restrições e Direitos Ativos', exibir: true},
          {id: 'indisponibilidades', nome: 'CNIB', exibir: !this.$root.usuarioLogado.auditor},
          {id: 'documentacao', nome: 'Anexos', exibir: !this.$root.usuarioLogado.auditor, title: 'Documentação & Anexos'},
          {id: 'socios', nome: 'Vínculo Societário', exibir: !this.$root.usuarioLogado.auditor},
          {id: 'protocolos', nome: 'Protocolos', exibir: !this.$root.usuarioLogado.auditor},
          {id: 'ocorrencias', nome: 'Ocorrências', exibir: !this.$root.usuarioLogado.auditor},
          {id: 'oficios', nome: 'Ofícios', exibir: !this.$root.usuarioLogado.auditor},
          {id: 'registros-auxiliares', nome: 'Registros Auxiliares', exibir: true},
          {id: 'atos', nome: 'Atos', exibir: !this.$root.usuarioLogado.auditor, title: 'Atos Registrados'},
        ]
      }
    },

    methods:{

        async load(versao) {
          if(versao){
            this.pessoa = await IndicadorPessoalBusiness.getVersaoById(versao);
          }else{
            this.pessoa = await IndicadorPessoalBusiness.getById(this.id);
          }
          this.pessoa.documentoInvalido = await IndicadorPessoalBusiness.validarDocumentos(this.pessoa.tipoPessoa, this.pessoa.documento, false, true);
          if (this.abaInicial) {
            this.$set(this, 'aba', this.abaInicial);
          }

          if (!this.usuario?.auditor && this.pessoa?.indicadorPessoalId){
            await Promise.all([
              OcorrenciaBusiness.indicadorPessoalPossuiOcorrenciasAtivas(this.pessoa.indicadorPessoalId).then(r => this.$set(this.pessoa, 'ocorrenciasAtivas',r)),
              IndicadorPessoalBusiness.getQtdDocumentosDuplicados(this.pessoa.indicadorPessoalId).then(r => this.versoes = r)
            ]);
          }

          this.$set(this, 'loading', false);
        },

      async possuiOcorrenciasBloqueantes(){
            let ocorrenciaEnvolvido = await OcorrenciaBusiness.listarPorEnvolvido(this.pessoa.indicadorPessoalId);
        for (let i = 0; i < ocorrenciaEnvolvido.length; i++) {
          if (ocorrenciaEnvolvido[i].ocorrencia_bloqueante === "true") {
            this.ocorrenciaBloqueante = true;
          }
        }
      },

        async versaoAberta(id) {
          await this.load(id);
        },

        async merge(){
            await this.$refs?.comp?.merge();
        },

        async editarIndicador(pessoa) {
          let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: pessoa.id});
          if(retorno){
            retorno.documentoInvalido = await IndicadorPessoalBusiness.validarDocumentos(retorno.tipoPessoa, retorno.documento, false, true);
            this.$set(this, 'pessoa', retorno);
            EventBus.$emit("PESSOA_ALTERADA_DETALHES_INDICADOR_PESSOAL", retorno);
          }
        },
    }
  }
</script>

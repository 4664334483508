import Http from "@/commons/Http";

const path = 'api/socio';

export default {

  async listarSocios(indicador = ''){
    return Http.get(`${path}/socios/${indicador}`);
  },

  async listarEmpresas(indicador = ''){
    return Http.get(`${path}/empresas/${indicador}`);
  },

  async getById(indicador){
    return Http.get(`${path}/${indicador}`);
  },

  async deleteById(id){
    return Http.delete(`${path}/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

}

<script>

import Utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";

export default {
    name: 'ErroValidacao',
    components: {Card},
    props: {
      ato: {type: Object, default: () => ({})},
      servico: {type: String, default: ''},
    },

    computed:{
      titulo(){
        return `${this.ato.codigoStr} - ${this.ato.tipoServico.nome}`;
      },

      erros(){
        return Utils.uniqBy(this.ato.erros, (e) => e.msg);
      },

    },

    data() {
      return {
        loading: false,

      }
    },

    methods: {
      abrirErro(ato, campo, servico, command) {
        if(command){
          command();
        } else {
          this.$emit('abrirErro', [ato, campo, servico]);
        }
      },
      classe(tipo){
        return tipo == 'erro' ? 'danger' : 'warning'
      }
    }
  }

</script>

<template>
  <card :titulo="titulo" class="erroValidacao"  v-if="ato && ato.erros && ato.erros.length">

    <div v-for="(erro, i) in erros" :key="erro+ato.id+i">

      <p :class="'alert alert-' + classe(erro.tipo) +  ' pointer mb-2'" :key="ato.id+'--'+i"
         @click="abrirErro(ato, erro.area, servico, erro.command)" title="Editar Ato" v-html="erro.msg">
      </p>

    </div>

  </card>
</template>


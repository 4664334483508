<template>

  <modal id="registro-anterior" :titulo="titulo" :loading="loading">

    <card>

      <div class="row gutters-xs">

        <v-input :error="validation.tipo" class="col-4" v-model="form.tipo" :options="options.tipos"
                 type="select" label="Tipo" @change="onChangeTipo()" />
        <v-input :error="validation.registro" class="col-8" v-model="form.registro" type="text" label="Registro" v-if="isTexto"/>

        <div class="col" v-else-if="isMatricula">
          <label style="margin-left: 22px">Registro</label>
          <buscar-fichas :valorInicialIndividual="{codigo: form.registro, livroNumero: form.livroNumero, livroFolha: form.livroFolha, livroLetra: form.livroLetra}" :livro="tipo.livro" :status="tipo.status" ref="buscarFichas"
                         @selecionar="selecionarFicha" :full="true" :multiple="form.tipo === 'TRANSCRICAO' ? false : !this.registroEditar"/>
        </div>

        <div class="col" v-else-if="isMatriculaExterna">
          <buscar-fichas-externas  :valorInicialIndividual="{codigo: form.registro, livroNumero: form.livroNumero, livroFolha: form.livroFolha, livroLetra: form.livroLetra}" :valorInicial="form.registro + (form.livroLetra || '')"
                                  :livro="form.tipoIndicadorRealExterno" ref="buscarFichasExternas" @selecionar="selecionarFicha" :full="true" :multiple="form.tipoIndicadorRealExterno === 'TRANSCRICAO' ? false : !this.registroEditar"/>
        </div>

        <template v-else>
          <div class="col">
            <label>Registro</label>
            <div class="buscar-fichas-wrapper row gutters-xs">
              <v-input type="text" :error="validation.registro" v-model="form.registro" :small="true" class="col" @keypress="validateRegistro"/>
            </div>
          </div>
        </template>

        <template v-if="(!isMatricula && !isMatriculaExterna && !isTexto)">
          <div class="w-100"/>
          <v-input class="col" :error="validation.livroNumero" v-model="form.livroNumero" type="text" label="Livro (Número)" />
          <v-input class="col" :error="validation.livroLetra" v-model="form.livroLetra" type="text" label="Livro (Letra)" />
          <v-input class="col" :error="validation.livroFolha" v-model="form.livroFolha" type="text" label="Folha" />
        </template>

        <div class="w-100"></div>
        <v-input v-if="!isTexto" class="col" :error="validation.complemento" v-model="form.complemento" type="text" label="Complemento" />

      </div>

    </card>


    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary">Cancelar</button>
      <v-button type="button" :run="save" class="btn-outline-primary ml-auto">Salvar</v-button>
    </template>

  </modal>

</template>
<script>

  import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness";
  import ListaBusiness from "@/business/crud/ListaBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import ConsultarIndicadorReal from '@/components/IndicadorReal/ConsultarIndicadorReal.vue';
  import Card from "@/components/Layout/components/Card.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils";

  export default {
    name: 'RegistroAnterior',

    components: {
      Card,
      Modal
    },

    props: ['registroEditar'],

    modal: {
      width: 800
    },

    data() {
      return {
        titulo: 'Registro Anterior',
        loading: false,
        validation: {},
        fichas: [],
        form: this.registroEditar ? Utils.clone(this.registroEditar) : {tipo: 'MATRICULA', serventia: '', registro: '', livro: '', folha: '', livroNumero: '', livroLetra: '', livroFolha: '', livroSelecionado: '', codigo: '', tipoIndicadorRealExterno: 'MATRICULA', interno: true},
        options: {
          tipos: IndicadorRealBusiness.getTiposOrigens(),
          serventias: []
        }
      }
    },

    async mounted() {
      let config = this.$root.config;
      let serventia = config?.listaRegistroAnterior?.id ? await ListaBusiness.getById( config?.listaRegistroAnterior?.id) : false;
      this.$set(this.options, 'serventias', (serventia || {lista: []}).lista.map(e => ({
        id: e,
        nome: e
      })));
      this.verificarRegistro();
    },

    watch: {
      'form.tipo'() {
          this.$set(this.form, 'registro', '')
          this.$set(this.form, 'origemId', '')
          this.$set(this.form, 'origemExternaId', '')
          this.$set(this.form, 'livro', '')
          this.$set(this.form, 'folha', '')
          this.$set(this.form, 'livroNumero', '')
          this.$set(this.form, 'livroLetra', '')
          this.$set(this.form, 'livroFolha', '')
      },

      'form.registro'(){
        this.verificarRegistro();
      }
    },

    computed: {

      isMatricula() {
        let livro = this.form.tipo || 'MATRICULA';
        return ['TRANSCRICAO', 'MATRICULA', 'PRE_MATRICULA'].includes(livro);
      },

      isMatriculaExterna(){
        let livro = this.form.tipo || 'MATRICULA_EXTERNA';
        return livro === 'MATRICULA_EXTERNA';
      },

      isTexto() {
        return this.form.tipo === 'TEXTO' || this.form.tipo === 'LEGADO';
      },

      tipo() {
        return this.options.tipos.find(t => t.id === this.form.tipo);
      }
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      sugerirServentia(query) {
        if (query.trim().length === 0) {
          return null
        }
        return new Promise(resolve => {
          IndicadorRealBusiness.sugerirServentia(query.trim()).then(resolve);
        });
      },

      save() {
        let form = !this.registroEditar ? [this.form] : this.form;
        if ((this.isMatricula || this.isMatriculaExterna) && this.fichas.length > 1) {
          form = [];
          this.fichas.forEach(ficha => {
            const formx = Object.assign({}, this.form);
            formx.registro = ficha?.codigo || '';
            formx.origemId = !this.isMatriculaExterna ? ficha?.id : '';
            formx.origemExternaId = this.isMatriculaExterna ? ficha?.id : '';
            formx.tipoIndicadorRealExterno = ficha?.tipo || null;
            formx.livroLetra = ficha?.livroLetra || '';
            formx.livroFolha = ficha?.livroFolha || '';
            formx.livroNumero = ficha?.livroNumero || '';
            formx.interno = formx.tipo !== 'MATRICULA_EXTERNA';
            formx.serventia = formx.interno ? null : ficha?.serventia || '';
            if (formx.interno) {
              formx.tipoIndicadorRealExterno = null;
            }
            form.push(formx)
          })
        } else {
            if (form.interno) {
                form.tipoIndicadorRealExterno = null;
            }
        }

        const validation = IndicadorRealBusiness.getErrosFormRegistroAnterior(this.form) || {};
        if(!this.isMatricula && this.validation?.registro) validation.registro = this.validation.registro;
        Object.keys(validation).length ? this.showErros(validation) : FrontBusiness.closeModal(form);
      },

      showErros(validation) {
        this.$set(this, 'validation', validation);
      },

      selecionarFicha(fichas, add, campoValidado, mensagemValidacao, livroSelecionado) {
        this.$set(this, 'fichas', fichas || []);
        const form = Object.assign({}, this.form);
        const ficha = fichas[0];
        form.registro = ficha.codigo.toString() || '';
        form.origemId = !this.isMatriculaExterna ? ficha?.id : null;
        form.origemExternaId = this.isMatriculaExterna ? ficha?.id : null;
        form.livroLetra = ficha?.livroLetra || '';
        form.livroFolha = ficha?.livroFolha || '';
        form.livroNumero = ficha?.livroNumero || '';
        form.interno = form.tipo !== 'MATRICULA_EXTERNA';
        form.serventia = form.interno ? null : ficha?.serventia || '';
        form.tipoIndicadorRealExterno = livroSelecionado;
        if (form.interno) {
          form.tipoIndicadorRealExterno = null;
        }
        this.$set(this, 'form', form);
      },

      onChangeTipo() {
        if (this.isMatricula) {
          delete this.form.livroNumero;
          delete this.form.livroFolha;
          delete this.form.livroLetra;
        }
      },

      verificarRegistro(){
        if(!this.isMatricula && !this.isTexto) {
          let regex = /^\d+$/;
          this.$set(this.validation, 'registro', this.form.registro && !regex.test(this.form.registro) ? 'Código digitado errado' : null);
        }
      },

      validateRegistro(evt){
        if(this.form.tipo == 'TRANSCRICAO' || this.form.tipo == 'TORRENS'){
          let theEvent = evt || window.event;
          let key;
          if (theEvent.type === 'paste') {
            key = event.clipboardData.getData('text/plain');
          } else {
            key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
          }
          let regex = /^\d+$/;
          if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
          }
        }
      }

    }
  }

</script>
<style lang="scss">

  #registro-anterior {

    .modal-dialog {
      $size: 1000px;
      width: 99%;
      max-width: $size;

      .modal-body {
        background: var(--alt-bg-color);
        max-height: calc(100vh - 180px);
        overflow: auto;
        border-top: 5px solid var(--alt-bg-color);
      }

      .busca-ficha {
        margin-top: 24px;
      }

    }

    /*select.form-control:not([size]):not([multiple]){*/
    /*height: 36px;*/
    /*}*/

  }


</style>

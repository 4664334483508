import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness";
import TextParser from "@/business/livros/TextParser";
import SimulacaoAtoBusiness from "@/business/protocolo/simulacao/SimulacaoAtoBusiness";
import VueBusiness from "@/business/VueBusiness.js";
import utils from "@/commons/Utils";
import Utils from "@/commons/Utils.js";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
import moment from "moment";
import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
import TipoServicoBusiness from "@/views/Administracao/modulos/tipo-servico/business";
import AtoLegadoBusiness from "@/business/indicadores/AtoLegadoBusiness";
import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
import FracaoTransferenciaImovelBusiness from "@/business/protocolo/FracaoTransferenciaImovelBusiness";
import IndicadorRealBusiness from "../../indicadores/IndicadorRealBusiness";
import envolvido from "@/views/Protocolo/Certidoes/Certidao/Envolvido";
import atoBusiness from "@/business/protocolo/AtoBusiness";

export default {

  async gerarMinutaLote(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'gerar-minuta', null, usuarioLogado);
    }

    return {atos, ficha};

  },

  async gerarMinuta(protocolo, ficha, atos, documentos = [], ctrl = {}, ato, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      if(ato?.id == atos[i]?.id){
        atos[i].templateMinuta = ato.texto;
      }
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, ato?.id == atos[i]?.id ? 'gerar-minuta' : 'processar', null, usuarioLogado);
      if(ato == atos[i]?.id){
        return {atos, ficha};
      }
    }

    return {atos, ficha};

  },

  async simular(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado= {}){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }
    const root = VueBusiness.getVueRoot();
    let registroIndisponibilidade = atos.filter(a => !a?.tipoServico?.opcoes?.registroIndisponibilidade);
    let registroOcorrencia = atos.filter(a => !a?.tipoServico?.opcoes?.registroOcorrencia);
    let gestorIndisponibilidade = !(root.usuarioLogado?.permissao || []).length ?
      (root.usuarioLogado.gestor && root.config?.indisponibilidade?.registroIndisponibilidadeGestor === false)
      : root.getPermission({id: 'PERMITIR_REGISTRAR_PROTOCOLO_INDISPONIBILIDADE_SEM_PERMISSAO', dominio: protocolo?.dominio || 'PROTOCOLO_RI'});
    if (root.config.geral.usarGrupoPermissaoCompleto) {
      gestorIndisponibilidade = root.getPermission({id: 'PERMITIR_REGISTRAR_PROTOCOLO_INDISPONIBILIDADE_SEM_PERMISSAO', dominio: protocolo?.dominio || 'PROTOCOLO_RI'});
    }
    let extras = {
      registroIndisponibilidade: gestorIndisponibilidade || registroIndisponibilidade.length === 0,
      registroOcorrencia: registroOcorrencia.length === 0,
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'completo', extras, usuarioLogado);
    }

    return {atos, ficha};

  },

  async simularAto(ato, posicao, protocolo, ficha, documentos = [], modo = 'completo', extras = {}, usuarioLogado){
    ato.envolvidos = ato.envolvidos || [];
    // console.debug('simularAto', Utils.clone(ato.envolvidos))
    let simulacaoCompleta = modo == 'completo';
    const trataFracao = (valor) => parseFloat(parseFloat(valor).toFixed(10));

    let promises = [], envolvidosPromise = [];

    ato.erros = [];
    ficha.alertas = [];
    let unidadeArea = ficha.medidaArea ? FrontBusiness.getLabel(ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : ''

    if(ficha.status == 'PENDENTE'){
      ficha.prematricula = true;
    }

    const criarErro = (msg, area = '', tipo = 'erro', command, tipoErro) => {
      if(simulacaoCompleta){
        ato.erros.push({area, msg, tipo, command, tipoErro});
      }
    };

    if(!ato.tipoServico?.opcoes){
      criarErro("Necessário configurar o tipo de ato", null, 'erro');
      console.error("Necessário configurar o tipo de ato");
      return;
    }

    if(posicao == 0 && simulacaoCompleta){

      let ocorrenciaBloqueante = false;
      let ocorrenciasIndicadorReal = await OcorrenciaBusiness.listarPorIndicadorReal(ficha.id);

      for (let i = 0; i < ocorrenciasIndicadorReal.length; i++) {
        if (ocorrenciasIndicadorReal[i].ocorrencia_bloqueante === "true") {
          ocorrenciaBloqueante = true;
        }
      }
      if(ato.envolvidos.length > 0){
        for (let i = 0; i < ato.envolvidos.length; i++) {
          let ocorrenciaEnvolvido = await OcorrenciaBusiness.listarPorEnvolvido(ato.envolvidos[i].indicadorPessoalId);
          if (ocorrenciaEnvolvido.ocorrencia_bloqueante === "true") {
            ocorrenciaBloqueante = true;
          }
        }
      }

      const ocorrenciasAtivasPromise = OcorrenciaBusiness.indicadorRealPossuiOcorrenciasAtivas(ficha.id).then(ocorrenciasAtivas => {
        if(ocorrenciasAtivas){
          let permiteRegistro = !ocorrenciaBloqueante || extras.registroOcorrencia;
          const erroOuWarning = protocolo.dataConsentimentoRegistro || permiteRegistro ? 'warning' : 'erro';
          criarErro(FrontBusiness.nomearFicha(ficha)+" possui ocorrências",  '', erroOuWarning, () => {
            FrontBusiness.openModal(DetalhesIndicadorReal, {id: ficha.id, abaInicial: 'ocorrencias'});
          }, 'OCORRENCIA');
        }
      });
      promises.push(ocorrenciasAtivasPromise);

      if (ficha.indisponibilidades && ficha.indisponibilidades.length > 0) {
        const erroOuWarning = protocolo.dataConsentimentoRegistro || extras.registroIndisponibilidade ? 'warning' : 'erro';
        criarErro("Matrícula possui indisponibilidade", '', erroOuWarning, () => {
          FrontBusiness.openModal(DetalhesIndicadorReal, {id: ficha.id, abaInicial: 'indisponibilidades'});
        }, 'INDISPONIBILIDADE');
      }

    }
    const motivoIsencao= VueBusiness.getVueRoot().isEstadoCE ? ato.ressarcimento?.motivo : ato.ressarcimento?.motivoIsencao;

    if(ato.isento && (Utils.isEmptyNullOrUndefined(motivoIsencao))){
      if(VueBusiness.getVueRoot().isEstadoSC){
        if(Utils.isEmptyNullOrUndefined(ato.ressarcimento?.tipoProcesso) || (ato.ressarcimento?.tipoProcesso != 'NAO_RESSARCIVEL' && Utils.isEmptyNullOrUndefined(ato.ressarcimento?.numeroProcesso))){
          criarErro(`Necessário preencher os dados de ressarcimento.`, null,  'erro');
        }
      }

      if(VueBusiness.getVueRoot().isEstadoMG || VueBusiness.getVueRoot().isEstadoCE){
        criarErro(`Necessário preencher os dados de ressarcimento.`, null,  VueBusiness.getVueRoot().isEstadoMG ? 'erro' : 'alerta');
      }
    }

    if (VueBusiness.getVueRoot().isEstadoPE) {
      if (Utils.isEmptyNullOrUndefined(ato.guia)){
        criarErro(`Necessário informar o número da Guia SICASE`, null,  'erro');
      }
    }

    const documentosEnvolvidos = [];

    ficha.proprietarios.forEach(proprietario => {
      ato.envolvidos?.forEach((envolvido) => {
        const versaoDoProprietario = proprietario.indicadorPessoalVersao;
        if(versaoDoProprietario && versaoDoProprietario.documento === envolvido.documento && versaoDoProprietario.indicadorPessoalId !== envolvido.indicadorPessoalId && !Utils.isEmptyNullOrUndefined(envolvido.documento)){
          criarErro(`O proprietário (${versaoDoProprietario.nome}) possui o mesmo ${envolvido.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} que o envolvido (${envolvido.nome}) porém são indicadores diferentes`, null,  'warning',);
        }
      });
    });

   await atoBusiness.validarAto(protocolo.id,ato.livro, ato.id, simulacaoCompleta, modo).then(alertas=>{
      alertas.map(a=>{
        criarErro(a.message,a.area, a.tipo);
      });
    });

    for(let envolvido of ato.envolvidos){

      if(!ato?.tipoServico?.opcoes?.registroIndisponibilidade){// ato não pode conter indisponibilidade
        if(simulacaoCompleta && ['TRANSMITENTE', 'CEDENTE', 'PROMITENTE_VENDEDOR', 'ADQUIRENTE', 'CESSIONARIO',  'PROMITENTE_COMPRADOR'].includes(envolvido.papel)){
          let promise = IndicadorPessoalBusiness.possuiIndisponibilidades(envolvido.indicadorPessoalId);
          promises.push(promise);
          promise.then(possuiIndisponibilidade => {
            if(possuiIndisponibilidade){
              const erroOuWarning = protocolo.dataConsentimentoRegistro || extras.registroIndisponibilidade ? 'warning' : 'erro';
              criarErro(`O envolvido ${envolvido.nome} [${envolvido.documento}] possui indisponibilidades abertas`, 'envolvidos', erroOuWarning, () => {
                FrontBusiness.openModal(DetalhesIndicadorPessoal, {versao: envolvido.indicadorPessoalVersaoId, abaInicial: 'indisponibilidades'});
              }, 'INDISPONIBILIDADE');
            }
          });
        }
      }

      if(simulacaoCompleta || modo == 'gerar-minuta'){
        let p = IndicadorPessoalBusiness.getVersaoByIdComCache(envolvido.indicadorPessoalVersaoId);
        envolvidosPromise.push(p);

        p.then( async pessoa => {

          envolvido.indicadorPessoalVersao = pessoa;

          if(documentosEnvolvidos.includes(envolvido.documento) && !Utils.isEmptyNullOrUndefined(envolvido.documento)){
            criarErro(`Há envolvidos com o mesmo ${envolvido.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} (${envolvido.documento})`, null,  'warning');
          }

          documentosEnvolvidos.push(envolvido.documento);

          if(VueBusiness.getVueRoot().isEstadoSC){
            let campos = IndicadorPessoalBusiness.validarEndereco(pessoa);
            if(campos?.length){
              criarErro(`O endereço do envolvido ${envolvido.nome} está incompleto: ${FrontBusiness.formatArray(campos)}`, '', 'warning');
            }
          }

        });
      }

    }

    if(envolvidosPromise.length){
      await Promise.all(envolvidosPromise);
    }

    //atualiza ato (para minuta e demais acoes)
    ato.ficha = utils.clone(ficha);
    ato.codigo = (ficha.status == 'PENDENTE' || (ato.tipo || ato.tipoServico?.opcoes?.tipoAto) === 'ABERTURA') ? 0 : (parseInt(ficha.ultimoAto || 0,10) + 1);

    let tipo = ato.tipoServico?.opcoes?.tipoAto == 'REGISTRO' ? 'R.' : 'Av.';
    ato.codigoStr = ato.tipoServico?.opcoes?.tipoAto == 'ABERTURA' ? 'M.0' : tipo + ato.codigo;
    ficha.ultimoAto = ato.codigo;

    //verifica exigencia
    let isExigencias = false;
    if (simulacaoCompleta && ato?.exigencias?.length) {
      isExigencias = true;
    }

    if (simulacaoCompleta && ato?.envolvidos?.length) {
      (ato?.envolvidos || []).forEach(envolvido => {
        if (envolvido?.exigencias?.length) {
          isExigencias = true;
        }
      })
    }

    if (isExigencias) {
      criarErro("O ato possui exigências", 'exigencia', 'erro');
    }

    if (simulacaoCompleta && !ato?.indices?.respondidos || ato?.indices?.respondidos < ato?.indices?.quantidade) {

      if(!ato.legado){
        // INÍCIO: Montar Contador por Grupo de Checklist
        const item = await AtoBusiness.getById(protocolo.id, ato.id);

        const vinculos = {documentos};
        if (item.envolvidos) {
          vinculos.envolvidos = item.envolvidos;
        }
        if (item.indicadorReal) {
          vinculos.matricula = item.indicadorReal;
        }

        let resultados = (item.checklist || []).map(checklist => {
          return ChecklistProcessamentoBusiness.tratarGrupoChecklist({requisitos : checklist.checklist, id: checklist.id, titulo: checklist.titulo}, {...item.indices}, vinculos)
        });

        resultados = await Promise.all(resultados);

          // Exibição ato relatório de indicador real
        if((JSON.stringify(item.dto.indicador) == '{}' && JSON.stringify(item.dto.incluirAtoRelatorioIR) == 'true' && item.tipoServico?.opcoes?.incluirRelatorioIR) || (!item.tipoServico?.opcoes?.opcionalIncluirRelatorioIR &&  item.tipoServico?.opcoes?.incluirRelatorioIR && JSON.stringify(item.dto.indicador) == '{}')){
          criarErro('Este ato deve ser incluído no relatório de Indicador Real, mas não houve alteração do indicador real.', '', 'alerta')
        }

        for(let checklist of (resultados || [])){
          if(checklist.contador.quantidade > 0 && checklist.contador.respondidos < checklist.contador.quantidade){
            let msgErro = 'Checklist está incompleto: ';
            let titulo = checklist.titulo || checklist.nome;
            let area = `checklist-${checklist.id}.checklist-group .nao-respondido`;
            if(VueBusiness.getVueRoot().getPermission({id: 'PERMITIR_REGISTRAR_CHECKLIST_INCOMPLETO_PROTOCOLO', dominio: protocolo?.dominio || 'PROTOCOLO_RI'})){
              criarErro(msgErro + titulo, area, 'alerta');
            }else{
              criarErro(msgErro + titulo, area, 'erro');
            }
          }
        }

      }

    }

    //ENCERRAMENTO DE MATRICULA
    if(['CANCELAMENTO', 'ENCERRAMENTO'].includes(ato.tipoServico?.opcoes?.operacaoIndicadorReal)){
      let msg = `Este ato ${ato.tipoServico?.opcoes?.operacaoIndicadorReal == 'CANCELAMENTO' ? 'cancelará' : 'encerrará'} a ${ficha.status == 'PENDENTE' || ficha.prematricula? 'pré-' : ''}${FrontBusiness.nomearLivro(ficha.livro).toLowerCase()}.`
      criarErro(msg, null,  'warning');
      ficha.status = ato.tipoServico?.opcoes?.operacaoIndicadorReal == 'CANCELAMENTO' ? 'CANCELADA' : 'ENCERRADA';
    }

    //gerar minuta
    if(simulacaoCompleta || modo == 'gerar-minuta'){

      if((modo == 'gerar-minuta' || !ato?.tipoServico?.opcoes?.editarMinuta)){

        let atoPromise = LivroMatriculaBusiness.gerarMinutaAto(ato, ficha, protocolo, documentos, usuarioLogado);
        atoPromise.then(minuta => {
          ato.minuta = minuta;

          const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
          if(minutaInvalida){
            criarErro(minutaInvalida, 'minuta', 'erro');
          }

        });

        promises.push(atoPromise);

      }else {
        ato.minuta = ato.texto;
        const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
        if(minutaInvalida){
          criarErro(minutaInvalida, 'minuta', 'erro');
        }
      }

    }

    //necessario encerrar as promessas antes de seguir com as transformações
    await Promise.all(promises);

    //ABERTURA DE MATRICULA - DEVE SEMPRE SER APÓS A GERAÇÃO DA MINUTA
    if(ficha.status == 'PENDENTE'){
      if(ato.tipoServico.opcoes.tipoAto != 'ABERTURA' && !VueBusiness.getVueRoot()?.config?.indicadorReal?.permitirRegistroPreMatricula){
        criarErro('O primeiro ato deve ser um ato de abertura', '', 'erro');
      }else{
        ficha.status = 'ABERTA';
      }
    }

    // REATIVAR
    if (!['CANCELADA', 'ENCERRADA'].includes(ficha.status) && ato.tipoServico.opcoes.operacao == 'REATIVAR') {
      criarErro('Só é possível reativar matrículas canceladas ou encerradas', '', 'erro');
    }else if (ato.tipoServico.opcoes.operacao == 'REATIVAR') {
      ficha.status = 'ABERTA';
    }

    if(simulacaoCompleta){
      //verifica erros no calculo de custa
      if(ato.custas?.erro){
        criarErro(ato.custas.erro?.descricao || ato.custas.erro, '', 'erro');
      }

      //erro com envolvidos
      let envolvidos = ato.envolvidos.map(e => `${e.papel}-${e.indicadorPessoalVersao.indicadorPessoalId}`);

      let duplicados = [];

      if(envolvidos.length != Array.from(new Set(envolvidos)).length){
        ato.envolvidos.forEach(envolvidoOuter => {
          ato.envolvidos.forEach(envolvidoInner => {
            if(ato.envolvidos.indexOf(envolvidoOuter) != ato.envolvidos.indexOf(envolvidoInner) && envolvidoOuter.nome == envolvidoInner.nome) {
              if(!duplicados.includes(envolvidoOuter.nome) && envolvidoInner.papel == envolvidoOuter.papel) {
                criarErro(envolvidoOuter.nome + " está informado(a) duas vezes com o mesmo papel.", 'envolvidos', 'erro');
                duplicados.push(envolvidoOuter.nome);
              }
            }
          })
        })
      }

      if(ato.tipoServico?.opcoes?.envolvidosSemHashCNIB == false && ato.envolvidos.find(e => Utils.isEmptyNullOrUndefined(e.consultaCnib))){
        criarErro("Necessário informar hash da consulta CNIB das partes envolvidas", 'envolvidos', 'erro');
      }

      const quantidadePartesSemDocumento = ato.envolvidos.filter(e => !e.indicadorPessoalVersao.documento || !e.indicadorPessoalVersao.nome).length;
      if(quantidadePartesSemDocumento > 0){
        criarErro(`Existe ${quantidadePartesSemDocumento > 1 ? 'mais de uma' : 'uma'} parte envolvida sem CPF/CNPJ`, 'envolvidos', 'warning');
      }

      (ato.tipoServico.opcoes.papeisObrigatorios || []).forEach(papel => {
        if(!ato.envolvidos.find(e => e.papel == papel)){
          criarErro(`Necessário informar uma parte envolvida como ${TipoServicoBusiness.nomePapel(papel)}`, 'envolvidos', 'erro');
        }
      });

      if(ato.tipoServico.opcoes.restricaoOpcional){
        const c1 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['ONUS', 'ACAO', 'INDISPONIBILIDADE'].includes(a));
        const restricao_acao = ato.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(r => r === 'ACAO');
        const papeis_obrigatorios = [...['DEVEDOR', 'EMITENTE', 'CREDOR', 'GARANTIDOR', 'FIEL_DEPOSITARIO'], ...(restricao_acao ? ['AUTOR', 'REU'] : [])];
        const pObrigatorios1 = ato.tipoServico.opcoes.papeisObrigatorios.find(p => papeis_obrigatorios.includes(p));
        if(c1 && !pObrigatorios1 && !ato.envolvidos.find(e => papeis_obrigatorios.includes(e.papel))){
          criarErro(`Necessário informar uma parte envolvida como Devedor, Emitente, Garantidor, Fiel Depositário${restricao_acao ? ', Credor, Autor ou Réu' : ' ou Credor'} para a criação da restrição`, 'envolvidos', 'erro');
        }

        const c2 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['COMPROMISSO'].includes(a));
        const pObrigatorios2 = ato.tipoServico.opcoes.papeisObrigatorios?.find(p => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(p));
        if(c2 && !pObrigatorios2 && !ato.envolvidos.find(e => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(e.papel))){
          criarErro('Necessário informar uma parte envolvida como Promitente Transmitente ou Promitente Adquirente para a criação da restrição', 'envolvidos', 'erro');
        }

        const c3 = ato.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['DOMINIO'].includes(a));
        const pObrigatorios3 = ato.tipoServico.opcoes.papeisObrigatorios?.find(p => ['DEVEDOR'].includes(p));
        if(c3 && !pObrigatorios3 && !ato.envolvidos.find(e => ['DEVEDOR'].includes(e.papel))){
          criarErro('Necessário informar uma parte envolvida como Devedor para a criação da restrição', 'envolvidos', 'erro');
        }
      }
    }

    if(!['NENHUM', undefined, null].includes(ato.tipoServico.opcoes.tipoTransacaoDoi) && ato.doi.gerarDoi !== false){
      const camposDoi = [
        {id: 'situacaoConstrucao', nome: 'Situação da construção'},
        {id: 'formaAlienacao', nome: 'Forma da alienação'},
        {id: 'emitida', nome: 'DOI já foi emitida no título que está sendo registrado?'},
        {id: 'descricaoImovel', nome: 'Descrição do tipo de imóvel (Outros)'},
        {id: 'valorAlienacao', nome: 'Valor do negócio/alienação'},
        {id: 'valorAvaliacao', nome: 'Valor de avaliação fiscal'},
        {id: 'area', nome: 'Área total'},
        {id: 'areaConstruida', nome: 'Área Construída (m²)'},
        {id: 'objetoAlienacaoFiduciaria', nome: 'O imóvel foi objeto de alienação fiduciária?'},
        {id: 'ultimaParcela', nome: 'Última parcela'},
        {id: 'valorPago', nome: 'Valor pago até data do ato'},
        {id: 'valorPagoMoedaCorrente', nome: 'Valor pago em moeda corrente'}
      ];

      ato.doi = ato.doi || {};

      if(simulacaoCompleta){
        if(!protocolo.natureza.naturezaDoi) {
          criarErro(`DOI - Necessário preencher o campo “Natureza Título DOI” da natureza do protocolo`, 'doi', 'erro');
        }
        let campos = ['situacaoConstrucao', 'formaAlienacao', 'emitida'];
        if (ato.doi?.editarInformacao) {
          // campos = [...campos, ...['descricaoImovel']]
          if(ato.doi?.alienacaoConsta !== 'VALOR_NAO_CONSTA_NOS_DOCUMENTOS') {
            campos = [...campos, ...['valorAlienacao']];
          }
          if(ato.doi?.itbiConsta !== 'VALOR_NAO_CONSTA_NOS_DOCUMENTOS') {
            campos = [...campos, ...['valorAvaliacao']];
          }
          if(ato.doi?.areaConsta !== 'AREA_NAO_CONSTA_NOS_DOCUMENTOS') {
            campos = [...campos, ...['area']];
          }
        }

        if (ato.doi?.formaAlienacao === 'A_PRAZO') {
          campos.push('objetoAlienacaoFiduciaria');
          campos.push('ultimaParcela');
          if([null, undefined, 0].includes(ato.doi?.valorPago)){
            criarErro(`DOI - Necessário preencher o campo "Valor pago até data do ato"`, 'doi', 'warning');
          }
        }

        if ([null, undefined].includes(ato.dto.alienacaoPagoEspecie)) {
          criarErro("DOI - Necessário responder a pergunta “Valor pago em espécie ou título ao portador?”", 'doi', 'erro');
        } else {
          if (ato.dto.alienacaoPagoEspecie) campos.push('valorPagoMoedaCorrente');
        }

        let camposVazios = campos.filter(key => Utils.isEmptyNullOrUndefined(ato.doi[key]));
        if ([null, undefined].includes(ato.doi.areaConstruida) && ato.doi.areaConstruidaConsta !== 'AREA_NAO_CONSTA_NOS_DOCUMENTOS') {
          camposVazios.push('areaConstruida');
        }

        camposVazios.forEach(campo => {
          criarErro(`DOI - Necessário preencher o campo “${FrontBusiness.getLabel(campo, camposDoi)}”`, 'doi', 'erro');
        });

        if (!ato.doi.dataAlienacao || (moment(ato.doi.dataAlienacao).isAfter(moment()) || !moment(ato.doi.dataAlienacao).isValid() || (ato.doi.dataAlienacao && ato.doi.dataAlienacao.split('-')[0] < '1850'))) {
          criarErro("DOI - Necessário preencher o campo “Data da alienação” com uma data válida", 'doi', 'erro');
        }

        let alienacao = campos.includes('valorAlienacao') ? ato.doi?.valorAlienacao : ato.dto?.valoresBaseCalculo?.alienacao;
        if (ato.doi?.formaAlienacao === 'A_PRAZO') {
          if(alienacao == '0' || !alienacao) criarErro("DOI - Necessário preencher o campo “Valor do Negócio/Alienação”", 'doi', 'erro');
        }

        // VALIDAR ENVOLVIDOS DOI
        const papeisTransmitentesDoi = ato.envolvidos.filter(ae => AtoLegadoBusiness.getPapeisTransmitente().includes(ae.papel));
        if(papeisTransmitentesDoi.length === 0) criarErro("Um ato que gera DOI deve possuir ao menos um envolvido como transmitente (Transmitente, Cedente ou Promitente Transmitente)", 'envolvidos', 'erro');

        const papeisAdquirenteDoi = ato.envolvidos.filter(ae => AtoLegadoBusiness.getPapeisAdquirente().includes(ae.papel));
        if(papeisAdquirenteDoi.length === 0) criarErro("Um ato que gera DOI deve possuir ao menos um envolvido como adquirente (Adquirente, Cessionário ou Promitente Adquirente)", 'envolvidos', 'erro');
        let regimes = IndicadorPessoalBusiness.getRegimes();
        ato.envolvidos.map(ae => {
          let pessoa = ae.indicadorPessoalVersao;
          if (pessoa.tipoPessoa === 'FISICA' && (pessoa.possuiUniaoEstavel || pessoa.estadoCivil === 'CASADO')) {
            if (AtoLegadoBusiness.getPapeisTransmitente().includes(ae.papel) || AtoLegadoBusiness.getPapeisAdquirente().includes(ae.papel)) {
              if (['CASAMENTO_EXTERIOR', 'DESCONHECIDO'].includes(pessoa.regimeCasamento)) {
                criarErro(`O Envolvido ${ae.nome} está cadastrado com o Regime de Bens ${FrontBusiness.getLabel(pessoa.regimeCasamento, regimes)}, que será encaminhado para a DOI como Comunhão Parcial de Bens. `, 'envolvidos', 'alerta');
              }
            }
          }

          if (AtoLegadoBusiness.getPapeisTransmitente().includes(ae.papel) || AtoLegadoBusiness.getPapeisAdquirente().includes(ae.papel)) {
            if((pessoa.estadoCivil === 'CASADO' || pessoa.possuiUniaoEstavel === true) && !pessoa.regimeCasamento){
              criarErro(`DOI - Necessário informar o regime de bens para o envolvido ${pessoa.nome}`, 'doi', 'erro');
            }
          }
        })

        if(!this.getVerificarParticipacaoDoi(ato.envolvidos)){
          criarErro(`DOI - Necessário que a soma da participação dos adquirentes e dos transmitentes resulte em 100%`, 'doi', 'erro');
        }

        let indicadorReal = ato.indicadorReal;
        let camposIndicadorReal = [
          {id: 'cep',nome:'CEP',valorDefault:'00000-000',area:'doi',tipo:'warning'},
          {id: 'tipoLogradouro',nome:'Tipo logradouro',valorDefault:'“n/c” (não consta)',area:'doi',tipo:'warning'},
          {id: 'logradouro',nome:'Logradouro',valorDefault: '“n/c” (não consta)',area:'doi',tipo:'warning'},
          {id: 'numero',nome:'Número',valorDefault: '“n/c” (não consta)',area:'doi',tipo:'warning'},
          {id: 'bairro',nome:'Bairro',valorDefault: '“n/c” (não consta)',area:'doi',tipo:'warning'},
          {id: 'cadastroImobiliarioBrasileiro',nome:'Cadastro Imobiliário Brasileiro (CIB)',valorDefault: '',area:'doi',tipo:'warning'},
        ];

        if(indicadorReal.categoria==='RURAL'){
          camposIndicadorReal.push({id: 'incra',nome:'INCRA (CCIR)',valorDefault: '',area:'doi',tipo:'erro'})
          camposIndicadorReal.push({id: 'denominacaoAreaRural',nome:'Denominação da Área Rural',valorDefault: '',area:'doi',tipo:'erro'})
          camposIndicadorReal.push({id: 'localizacao',nome:'Localização',valorDefault: '',area:'doi',tipo:'erro'})
        }

        if(indicadorReal.livro ==='TRANSCRICAO'){
          camposIndicadorReal.push({id: 'livroFolha',nome:'Folha',valorDefault: '',area:'doi',tipo:'erro'})
        }

        camposIndicadorReal.forEach(campo=>{
          if(!indicadorReal[campo.id]){
            switch (campo.tipo) {
              case 'warning':
                criarErro(`DOI - O campo "${campo.nome}" deste indicador real não foi preenchido ${campo.valorDefault ? ` e será enviado como ${campo.valorDefault}` : ''}`, `${campo.area}`, `${campo.tipo}`);
                break;
              case 'erro':
                criarErro(`DOI - Necessário preencher o campo “${campo.nome}“ no indicador real`, `${campo.area}`, `${campo.tipo}`);
                break;
              default:
                break;
            }
          }
        });

        if(indicadorReal.categoria==='URBANO' && ato.doi.areaConstruidaConsta === 'AREA_NAO_CONSTA_NOS_DOCUMENTOS'){
          criarErro(`DOI - A informação de “Área Construída” será enviada como não consta nos documentos`, 'doi', 'warning');
        }
        if((!indicadorReal.areaTotal && !indicadorReal.areaTotalDoi && !ato.doi.editarInformacao) ||
          (ato.doi.editarInformacao && ato.doi.areaConsta === 'AREA_NAO_CONSTA_NOS_DOCUMENTOS') ){
          criarErro(`DOI - A informação de “Área Total” (Área Imóvel) será enviada como não consta nos documentos`, 'doi', 'warning');
        }
        if(ato.tipoServico.regraCusta === 'COMPRA_VENDA'){
          if((!ato.doi.editarInformacao && !ato.dto?.valoresBaseCalculo?.alienacao && !ato.dto?.valoresBaseCalculo?.recursosProprios) || (ato.doi.editarInformacao && ato.doi.alienacaoConsta ==='VALOR_NAO_CONSTA_NOS_DOCUMENTOS')){
            criarErro(`DOI - A informação “Valor do Negócio/Alienação” (Valor Operação Imobiliária) será enviada como não consta nos documentos`, 'doi', 'warning');
          }
        }else{
          if((!ato.doi.editarInformacao && !ato.dto?.valoresBaseCalculo?.alienacao) || (ato.doi.editarInformacao && ato.doi.alienacaoConsta ==='VALOR_NAO_CONSTA_NOS_DOCUMENTOS')){
            criarErro(`DOI - A informação “Valor do Negócio/Alienação” (Valor Operação Imobiliária) será enviada como não consta nos documentos`, 'doi', 'warning');
          }
        }

        if((!ato.dto?.valoresBaseCalculo?.valorAvaliacao && !ato.doi.editarInformacao) || (ato.doi.editarInformacao && ato.doi.itbiConsta ==='VALOR_NAO_CONSTA_NOS_DOCUMENTOS')){
          criarErro(`DOI - A informação “Valor de Avaliação Fiscal” (Valor Base de Cálculo ITBI/ITCMD) será enviada como não consta nos documentos`, 'doi', 'warning');
        }
      }

      if (ficha.tipoImovel === 'OUTROS') {
        if(Utils.isEmptyNullOrUndefined(ficha.descricaoTipoImovelOutros)) {
          criarErro(`DOI - Necessário informar a descrição do tipo de imóvel “Outros”`, null,  'erro');
        }
      }
      if(!ato?.tipoServico?.opcoes?.tipoTransacaoDoi) {
        criarErro(`DOI - Necessário preencher o campo “Operação DOI” do tipo de serviço`, null,  'alerta');
      }

      if(ato?.tipoServico?.opcoes?.tipoTransacaoDoi == 'OUTROS' && Utils.isEmptyNullOrUndefined(ato?.tipoServico?.opcoes?.doiDescricao)) {
        criarErro(`DOI - Necessário preencher o campo “Descrição DOI” do tipo de serviço`, null,  'erro');
      }
    }

    //Necessário verificar o percentual de aquisição da propriedade.
    if (ato.tipoServico.opcoes.alterarProprietarios) {
      let adquirenteZerado = 0;
      (ato.envolvidos || []).forEach((p, i) => {
        let fracao = parseFloat(p.fracao || 0);
        if (AtoLegadoBusiness.getPapeisAdquirente().includes(p.papel) && fracao <= 0) {
          adquirenteZerado += 1;
        }
      })
      if (adquirenteZerado > 0) {
        criarErro(`Necessário verificar o percentual de aquisição da propriedade.`, 'envolvidos', 'erro');
      }
    }

    //altera proprietarios e normaliza proprietarios na ficha
    if (ato.tipoServico.opcoes.alterarProprietarios) {

      let proprietarios = {}, proprietariosArea = {};
      let pessoas = {};
      (ficha.proprietarios || []).forEach(p => {
        proprietarios[p.indicadorPessoalVersao.indicadorPessoalId] = p.fracao;
        proprietariosArea[p.indicadorPessoalVersao.indicadorPessoalId] = p.area;
        pessoas[p.indicadorPessoalVersao.indicadorPessoalId] = {
          nome: p.indicadorPessoalVersao.nome,
          documento: p.indicadorPessoalVersao.documento
        };
      });

      (ato.envolvidos || []).forEach((p, i) => {

        let idIndicadorPessoalOriginal = p.indicadorPessoalId;
        pessoas[idIndicadorPessoalOriginal] = { nome: p.nome, documento: p.documento, indicadorPessoalId : p.indicadorPessoalId, id : p.indicadorPessoalVersaoId };

        let fracaoAtual = parseFloat(proprietarios[idIndicadorPessoalOriginal] || 0);
        let fracaoInformada = parseFloat(p.fracao || 0);

        let areaAtual = parseFloat(proprietariosArea[idIndicadorPessoalOriginal] || 0);
        let areaInformada = parseFloat(p.area || 0);

        if (p.papel == 'ADQUIRENTE') {
          proprietarios[idIndicadorPessoalOriginal] = trataFracao(fracaoAtual + fracaoInformada);
          proprietariosArea[idIndicadorPessoalOriginal] = trataFracao(areaAtual + areaInformada);
        }

        if (p.papel == 'TRANSMITENTE') {

          if (fracaoInformada > fracaoAtual) {
            criarErro(`${p.nome} não possui fração suficiente para transmitir`, 'envolvidos', 'erro');
          } else {
            proprietarios[idIndicadorPessoalOriginal] = trataFracao(fracaoAtual - fracaoInformada);
            if (proprietarios[idIndicadorPessoalOriginal] <= 0) {
              delete proprietarios[idIndicadorPessoalOriginal];
            }
          }

          if (areaInformada > areaAtual) {
            //criarErro(`${p.nome} não possui fração (${unidadeArea}) suficiente para transmitir`, 'envolvidos', 'erro');
          } else {
            proprietariosArea[idIndicadorPessoalOriginal] = trataFracao(areaAtual - areaInformada);
            if (proprietariosArea[idIndicadorPessoalOriginal] <= 0) {
              delete proprietariosArea[idIndicadorPessoalOriginal];
            }
          }

        }

      });

      let somaFracao = Object.values(proprietarios).reduce((accum, curr) => (parseFloat(accum) + parseFloat(curr)).toFixed(10), 0);
      let somaArea = Object.values(proprietariosArea).reduce((accum, curr) => (parseFloat(accum) + parseFloat(curr)).toFixed(10), 0);

      if(simulacaoCompleta){
        const erro = await FracaoTransferenciaImovelBusiness.validarMargemTransferenciaRegistro(somaFracao);
        if(erro){
          criarErro(erro.mensagem, 'envolvidos', erro.tipo);
        }

        if (ficha.area && somaArea && (somaArea < (ficha.area - 0.5) || somaArea > (ficha.area + 0.5))) {
          //criarErro(`A soma das frações em ${unidadeArea} dos adquirentes está diferente da soma das frações em ${unidadeArea} dos proprietários`, 'envolvidos', 'erro');
        }
      }

      ficha.proprietarios = Object.keys(proprietarios).map(id => {
        return {indicadorPessoalVersao : {...pessoas[id], indicadorPessoalId : id}, fracao: parseFloat(proprietarios[id]).toFixed(10), area: parseFloat(proprietariosArea[id]).toFixed(10)};
      });

    }

    if (ato.tipoServico.opcoes.tipoTransacaoDoi !== 'NENHUM') {
      let adquirente = 0;
      let transmitente = 0;
      (ato.envolvidos || []).forEach((p, i) => {
        let fracao = parseFloat(p.fracao || 0);
        if (['PROMITENTE_COMPRADOR', 'CESSIONARIO'].includes(p.papel)) {
          adquirente += trataFracao(fracao);
        }
        if (['PROMITENTE_VENDEDOR', 'CEDENTE'].includes(p.papel)) {
          transmitente += trataFracao(fracao);
        }
      });
      let diferente = FrontBusiness.formatNumber(adquirente, 2, 10) !== FrontBusiness.formatNumber(transmitente, 2, 10);
      if (transmitente + adquirente > 0 && diferente) {
        criarErro(`A somatória das frações dos envolvidos com os papeis Promitente Transmitente e Cedente não está de acordo com a somatória dos envolvidos com os papeis Promitente Adquirente e Cessionário.`, 'envolvidos', 'erro');
      }
      if (transmitente > 100.50) {
        criarErro(`A somatória das frações dos envolvidos com os papeis Promitente Transmitente e Cedente está ultrapassando o total permitido: 100,50%`, 'envolvidos', 'erro');
      }
    }

    //altera indicador real
    if (ato.tipoServico.opcoes.editarIndicador || ato.tipoServico.opcoes.editarIndicadorReal) {
      Object.keys(ato.dto.indicador || {}).forEach(k => ficha[k] = ato.dto.indicador[k]);
    }

    if(simulacaoCompleta){

      // if (Utils.isEmptyNullOrUndefined(ficha.imovelUniao)) {
      //   criarErro(`IMÓVEL DA UNIÃO [DOITU]: O campo "Imóvel da União" não foi informado no indicador real`, '', 'warning', () => {
      //     FrontBusiness.openModal(DetalhesIndicadorReal, {id: ficha.id});
      //   }, 'warning');
      // } else

      if (ficha.imovelUniao) {
        if (ato.tipoServico.opcoes.tipoTransacaoDoi !== 'NENHUM' && (ato.doi?.gerarDoi || !ato.tipoServico?.opcoes?.doiOpcional)
          && Utils.isEmptyNullOrUndefined(ato.doi?.tipoTransacaoDoitu)) {
          criarErro("IMÓVEL DA UNIÃO [DOITU] - Necessário preencher o campo “Tipo de Transação”", null, 'erro');
        }
        let msgErroWarning = ato.doi?.gerarDoi || !ato.tipoServico?.opcoes?.doiOpcional ? 'erro' : 'warning';
        if (Utils.isEmptyNullOrUndefined(ficha.numeroRipSpu)) {
          criarErro(`IMÓVEL DA UNIÃO [DOITU] - Necessário preencher o campo “Número RIP SPU" no indicador real`, '', msgErroWarning, () => {
            FrontBusiness.openModal(DetalhesIndicadorReal, {id: ficha.id});
          }, 'erro');
        }
        if (Utils.isEmptyNullOrUndefined(ato.numeroCat)) {
          criarErro(`IMÓVEL DA UNIÃO [DOITU] - Necessário preencher o campo "Número da CAT`, '', msgErroWarning, null, 'erro');
        }
      }

      await SimulacaoAtoBusiness.simulacaoRestricao(ficha, ato);
    }

  },

  getVerificarParticipacaoDoi(envolvidos){
    let participacaoTransmitentes=0;
    let participacaoAdquirentes=0;
    envolvidos.forEach(en=>{
          if(AtoLegadoBusiness.getPapeisAdquirente().includes(en.papel)){
            participacaoAdquirentes = Utils.toNumber(participacaoAdquirentes) + Utils.toNumber(en.participacaoDoi || 0);
          }
          if(AtoLegadoBusiness.getPapeisTransmitente().includes(en.papel)){
            participacaoTransmitentes = Utils.toNumber(participacaoTransmitentes) + Utils.toNumber(en.participacaoDoi || 0);
          }
        })
    return participacaoAdquirentes === 100 && participacaoTransmitentes === 100;

  }

}

<template>
  <modal titulo="Referências" id="referencias-imovel" :fechar="true" @close="close" :loading="loading">
    <referencia-imovel-lista v-if="!loading" :ficha="referencia" :matricula-mae="matriculaMae" :visualizar="false"/>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" :popover="true" class="btn-outline-primary">Salvar</v-button>
    </template>
  </modal>
</template>

<script>

import Modal from "@/components/Modal.vue";
import FrontBusiness from "@/business/FrontBusiness";
import ReferenciaImovelLista from "@/components/IndicadorReal/ReferenciaImovelLista.vue";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils";

export default {
  name: 'ModalReferenciaImovel',
  components: {ReferenciaImovelLista, Modal},
  props:{
    ficha: Object,
    matriculaMae: Object
  },

  async mounted() {
  let indicador = {};
  let id = this.ficha.id;

  if(id) indicador = await IndicadorRealBusiness.getById(id);

  this.referencia = indicador;
  this.loading = false;
},

  data(){
    return {
      loading: true,
      referencia: null
    }
  },

  methods:{
    async close(retorno) {
      FrontBusiness.closeModal(retorno);
    },

    async salvar(){
      let dto = Utils.clone(this.referencia);

      dto.referencias = (dto.referencias || []).map((f) => {
        delete f.referenciaDetalhes;
        return f;
      });

      let indicador = await IndicadorRealBusiness.save(this.ficha.id, dto, dto.origens[0].id).catch(this.showError);
      if (indicador) {
        FrontBusiness.closeModal(indicador);
      }

    },

    showError(e) {
      FrontBusiness.showError('', e.message);
    },
  }
}
</script>

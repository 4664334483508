<template>
  <async-alert title="Verificando ocorrências" :id="id" :service="service" :msg="msg" :tipo="tipo" @exist="$emit('exist')" :banner="banner" />
</template>

<script>

import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness.js";
import AlertMessage from "@/components/alert/AlertConstMessage.js";
import AsyncAlert from "@/components/alert/AsyncAlert.vue";

export default {
  name: "AlertOcorrencia",
  components: {
    AsyncAlert
  },

  props: {
    pessoa: String,
    imovel: String,
    tipo: {type:String, default:'alert-icon'},
    banner: {type:String, default:'alert-danger'},
  },

  computed:{

    msg(){
      return AlertMessage.indicadorPossuiOcorrencias();
    },

    id(){
      return this.imovel ? this.imovel : this.pessoa;
    },

    service(){
      return this.imovel ? OcorrenciaBusiness.indicadorRealPossuiOcorrenciasAtivas : OcorrenciaBusiness.indicadorPessoalPossuiOcorrenciasAtivas;
    },

  }

}
</script>
<style lang="scss" scoped>
.alert.alert-banner{
  margin: -10px 0 1rem !important;
  border-radius: 0.25rem !important;
  padding: 0.75rem 1.25rem !important;
}
</style>

<template>

    <card titulo="Ex-proprietários">

      <table class="table table-striped" v-if="pessoas.length > 0">

        <thead>
          <tr>
            <th class="pl-2">PESSOA</th>
            <th>DOCUMENTO</th>
<!--            <th>DATA DE TRANSMISSÃO</th>-->
            <th></th>
          </tr>
        </thead>

        <tbody v-for="pessoa in pessoas" :key="pessoa.id">
          <tr>
            <td class="pl-2">
              <a href class="mb-1 pointer" @click.prevent="detalhes({versao: pessoa.versaoId})">
                {{pessoa.nome}} (Versão: {{pessoa.versao}})</a>
            </td>
            <td class="nowrap">{{pessoa.documento}}</td>
<!--            <td class="nowrap">{{$formatters.date(pessoa.registro)}}</td>-->
            <td class="td-toggle">
              <toggle :mostrar="chave(pessoa) == mostrarVersoesPessoa" :loading="loadingVersao" :run="change" :params="[pessoa]" @change="change(pessoa)" :chave="chave(pessoa)" class="ml-2 icon" />
            </td>
          </tr>
          <tr v-if="chave(pessoa) == mostrarVersoesPessoa">
            <td colspan="3">
              <card  titulo="Atos ex-proprietário">
                <div class="alert alert-outline-secondary row" style="margin-bottom: 5px;font-size: 13px;padding: 10px 0;" v-for="versaoAto in pessoa.atosPorVersao" :key="versaoAto.codigo">
                  <a href class="mb-1 pointer col-8" @click.prevent="detalhes({versao: versaoAto.versaoId})">
                    {{versaoAto.nome}} - {{versaoAto.documento}} (Versão: {{versaoAto.versao}})</a>
                  <span style="float: right;text-align: end" class="col-4">{{getCodigo(versaoAto.ato)}} - {{versaoAto.ato.nome}}</span>
                </div>
                <div v-if="pessoa.atosPorVersao.length == 0" class="alert alert-outline-info">
                  Não há ato registrado para ex-proprietário
                </div>
              </card>
            </td>
          </tr>
        </tbody>

      </table>

      <small-loading v-if="loading" ></small-loading>

      <div v-if="!pessoas.length && !loading" class="alert alert-outline-info">
        Não há ex-proprietários para exibir
      </div>


    </card>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import AtoBusiness from "@/business/protocolo/AtoBusiness";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import Card from "@/components/Layout/components/Card.vue";
  import Toggle from "@/components/Layout/components/Toggle.vue";
  import SmallLoading from "@/components/SmallLoading";

  export default {

    name: "ExProprietarios",
    components: {SmallLoading, Card, Toggle},
    props: {
      ficha : Object
    },

    data() {
      return {
        pessoas : [],
        mostrarVersoesPessoa: null,
        loading: true,
        loadingVersao: false,
      }
    },

    async mounted(){
      this.pessoas = await IndicadorRealBusiness.buscarExProprietarios(this.ficha.id);
      this.loading = false;
    },

    methods:{

      async change(pessoa){
        let chavePessoa = this.chave(pessoa);
        this.loadingVersao = true;
        if(chavePessoa == this.mostrarVersoesPessoa){
          this.mostrarVersoesPessoa = null;
          this.loadingVersao = false;
        } else {
          await this.buscarAtosPorVersaoIndicadorPessoal(this.ficha.id, pessoa);
          this.mostrarVersoesPessoa = chavePessoa;
          this.loadingVersao = false;
        }
      },
      chave(pessoa) {
        return `pessoa-id-${pessoa.id}`
      },
      detalhes(pessoa){
        FrontBusiness.openModal(DetalhesIndicadorPessoal, pessoa);
      },
      async buscarAtosPorVersaoIndicadorPessoal(fichaId, pessoa){
        if(!pessoa.atosPorVersao){
          pessoa.atosPorVersao = await IndicadorRealBusiness.buscarAtosPorVersaoIndicadorPessoal(fichaId, pessoa.id);
          pessoa.atosPorVersao.forEach(e => e.ato = JSON.parse(e.ato));
        }
      },
      getCodigo(ato) {
        return AtoBusiness.getCodigo(ato, this.ficha);
      },
    }
  }
</script>
<style scoped>

  .td-toggle {
    text-align:right;
    vertical-align:top;
  }

  tbody {
    border-top-width: 0px !important;
  }

</style>

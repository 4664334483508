<template>
  <async-alert title="Verificando indisponibilidades" :id="idEntidade" :service="service" :msg="msg" :tipo="tipo" @exist="$emit('exist')" />
</template>

<script>

import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import AlertMessage from "@/components/alert/AlertConstMessage.js";
import AsyncAlert from "@/components/alert/AsyncAlert.vue";

export default {
  name: "AlertIndisponibilidade",
  components: {
    AsyncAlert
  },

  props: {
    pessoa: String,
    imovel: String,
    tipo: {type:String, default:'alert-icon'}
  },

  mounted() {
    //console.debug('teste', this.msg, this.id)
  },

  computed:{

    msg(){
      return AlertMessage.indicadorPossuiIndisponibilidade();
    },

    idEntidade(){
      return this.imovel ? this.imovel : this.pessoa;
    },

    service(){
      return this.imovel ? IndicadorRealBusiness.possuiIndisponibilidades : IndicadorPessoalBusiness.possuiIndisponibilidades;
    },

  }

}
</script>

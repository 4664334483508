<template>

  <div class="row flex-nowrap justify-content-between align-items-center p-1">

    <div class="col-3 align-self-center text-muted small"> {{formatarCodigo(total)}} {{total == 1 ? 'item encontrado' : 'itens encontrados'}}</div>

    <div class="col align-self-center">

      <div class="float-right" v-if="qtd > 0">
        <div class="float-left mr-3 pr-3" style="border-right: 1px solid #232931;">
          <label class="form-label text-muted small float-left mr-1" style="margin-top: 7px;">Exibir</label>
          <input type="number" class="pagina-aberta form-control form-control-xs small text-center float-left mr-1" style="width: 40px;" v-model="qtd_registro" min="1" max="50" @keyup.enter="changeQtdPerPage()" @blur="changeQtdPerPage()">
          <label class="form-label text-muted small float-left" style="margin-top: 7px;width: 55px;">{{qtd_registro > 1 ? 'registros' : 'registro'}}</label>
        </div>

        <ul class="pagination pagination-sm mb-0" style="margin-top: 2px;">

          <li class="page-item" v-if="page > 1">
            <a class="page-link" href="#" @click.prevent="change(page - 1)">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          <li class="page-item" v-for="i in pages" :key="i" :class="{active : page == i}">
            <a class="page-link" href="#" @click.prevent="change(i)">
              {{i}}
            </a>
          </li>

          <li class="page-item" v-if="page < qtd">
            <a class="page-link" href="#" @click.prevent="change(page + 1)">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>

        </ul>
      </div>

    </div>
  </div>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import TableEventBus from './index';

export default {
    name: "AsyncPager",

    props: {
      name : {type : String, required : true},
    },

    data() {
      return {
        page : 0,
        total : 0,
        qtd : 0,
        pages : [],
        qtd_registro: 10
      }
    },

    mounted(){
      TableEventBus.$on(`vap-mount-${this.name}`, this.mount);
    },

    beforeDestroy(){
      TableEventBus.$off(`vap-mount-${this.name}`, this.mount);
    },

    methods:{

      formatarCodigo: FrontBusiness.formatarCodigo,

      change(page, qtd, setQtd = false){
        TableEventBus.$emit(`vap-set-page-${this.name}`, page, qtd, setQtd);
      },

      mount(page, total, perPage){
        let qtd = Math.ceil(total / perPage);

        let pages = [page - 4, page - 3, page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4 ].filter(i => i > 0 && i <= qtd);

        this.$set(this, 'page', page);
        this.$set(this, 'total', total);
        this.$set(this, 'qtd', qtd);
        this.$set(this, 'pages', pages);
      },

      changeQtdPerPage(){
        if(this.qtd_registro < 1) this.qtd_registro = 1;
        if(this.qtd_registro > 500) this.qtd_registro = 500;
        this.change(this.page, this.qtd_registro, true);
      }

    }
  }
</script>

<style lang=scss>
input.pagina-aberta::-webkit-outer-spin-button,
input.pagina-aberta::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.pagina-aberta[type=number] {
  -moz-appearance: textfield;
}
</style>

// Vue.config.errorHandler = (err, vm, info) => {
//   // Log detalhado do erro
//   console.error(`[ERRO NÃO TRATADO] ${err.toString()}\nInformação: ${info}`);
//   // Você pode adicionar mais informações sobre o contexto se necessário
//   if (vm) {
//     console.error(`Erro no componente: ${vm.$options.name || 'componente anônimo'}`);
//     console.error(`Caminho completo: ${vm.$route.fullPath}`);
//   }
// };

// window.addEventListener('error', (event) => {
//   console.error('[ERRO NÃO TRATADO] ', event.message, 'no', event.filename, 'linha', event.lineno, 'coluna', event.colno);
// });

window.onerror = function (message, source, lineno, colno, error) {
  console.error(`[ERRO NÃO TRATADO] ${source} na linha: ${lineno}, coluna: ${colno}`);
  console.error('Mensagem de erro:', message);
  if (error && error.stack) {
    console.error('Stack trace:', error.stack);
  }
};

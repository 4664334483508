<template>
  <modal titulo="Vincular Indisponibilidade">

    <card titulo="Protocolos Relacionados">
      <card-table>

        <thead>
          <tr>
            <th width="30px"></th>
            <th>Protocolo</th>
            <th>Ato Principal</th>
            <th>Status</th>
            <th width="30px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(protocolo, i) in protocolos" :key="protocolo.id">
            <td width="30px" class="text-center">
              <router-link :to="{ name: 'protocolo', params:{id : protocolo.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
                <i class="fas fa-external-link-alt"></i>
              </router-link>
            </td>
            <td>{{nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo)}}
              <a v-if="protocolo.indisponibilidadeOrdem" class="icon" title="Protocolo já possui vínculo com uma indisponibilidade">
                <i class="fas fa-exclamation-triangle text-danger"></i>
              </a>
            </td>
            <td>{{protocolo.tipoServico}}</td>
            <td>{{protocolo.status}}</td>
            <td><a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <buscar-protocolos :selecionarDominio="false" ref="buscarProtocolos" @selecionar="selecionar($event, 'protocolos')" />
            </td>
          </tr>
        </tbody>

      </card-table>
    </card>


    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <v-button :run="run" class="btn-outline-primary" :popover="true">Salvar</v-button>
    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";

  export default {
    name: "ModalVincularIndisponibilidade",
    components: {Card, Modal,
      BuscarProtocolos: () => import("@/components/BuscarProtocolos.vue"), CardTable},

    props: {
      id: String
    },

    data() {
      return {
        protocolos: [],
      }
    },

    methods: {
      nomearProtocolo : FrontBusiness.nomearProtocolo,

      remover(idx) {
        this.$nextTick(() => {
          this.protocolos.splice(idx, 1);
        });
      },

      selecionar(itens) {
        this.$nextTick(() => {
          itens?.forEach((item) => {
            if (item && !this.protocolos.find(e => e.id == item.id)) {
              this.protocolos.push(item);
            }
          });
        });
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '');
      },

      close(r) {
        FrontBusiness.closeModal(r);
      },

      async run() {
        this.$set(this, 'validation', {});
        if (!this.protocolos.length) return FrontBusiness.showError('', 'Necessário informar os protocolos a serem vinculados.');
        const protocolosValidos = this.protocolos.filter(p => !p.indisponibilidadeOrdem).map(m => m.id);
        if (!protocolosValidos.length) return FrontBusiness.showError('', 'Necessário informar ao menos um protocolo válido.');
        let retorno = await IndisponibilidadeBusiness
          .vincularProtocoloExistente(this.id, protocolosValidos)
          .catch(this.showError);

        if(retorno){
          let plural = protocolosValidos.length > 1 ? 's' : '';
          FrontBusiness.showSuccess('', `Protocolo${plural} vinculado${plural}`);
          this.close(true);
        }
      },

    }

  }
</script>

<template>
  <card titulo="Histórico">

    <auditoria-pagination :referencia="ficha.id" entidade="REGISTRO_AUXILIAR" :formatters="formatters" />

  </card>
</template>

<script>
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";

  export default {

    name: "Histórico",
    components: {
      Card,
      AuditoriaPagination: () => import('@/v2/components/Auditoria/AuditoriaPagination')
    },
    props: {
      ficha : Object
    },

    data() {
      return {
        historico : [],
        loading: true,
        disable: false,
        page: 1,
        pageSize: 10
      }
    },

    methods:{

      async formatters(auditoria) {
        let formatters = [];
        if (auditoria.entidade === 'ATO') {
          formatters = [...formatters, ... await AuditoriaBusiness.formattersAtos(auditoria)]
        } else if(auditoria.entidade === 'ANEXO_REGISTRO_AUXILIAR'){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'string'},
            {label: 'Nome', key: 'nomeAnexo', type: 'string'},
            {label: 'Caminho', key: 'caminho', type: 'string'},
            {label: 'Tags', key: 'tags', type: 'listahtml'},
            {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
          ];
        } else {
          let tipoRegistro = auditoria?.antes?.tipoRegistro || auditoria?.depois?.tipoRegistro;
          formatters = [
            {label: 'CNM', key: 'cnm'},
            {label: 'Tipo de Registro Auxiliar', key: 'tipoRegistro', type: 'enum', lista: RegistroAuxiliarBusiness.getTipos()},
            {label: 'Tipo de Cédula', key: 'tipoCedula', type: 'enum', lista: RegistroAuxiliarBusiness.getTiposCedulas()},
            {label: 'Nome do Condomínio', key: 'condominio'},
            {label: 'Data do Pacto', key: 'pacto', type: 'date'},
            {label: 'Último Ato', key: 'ultimoAto'},
            {label: 'Situação', key: 'status', type: 'enum', lista: RegistroAuxiliarBusiness.getStatusImovel(false)},
            {label: 'Abertura', key: 'abertura', type: 'datetime'},
            {label: 'Cancelamento/Encerramento', key: 'encerramento', type: 'datetime'},
            {label: 'Vencimento', key: 'vencimentoCedula', type: 'date'},
            {label: 'Valor', key: 'valorCedula', type: 'currency'},
            {label: tipoRegistro === 'CEDULA' ? "Garantia" : "Teor", key: 'texto'},
            {label: 'Texto Importado', key: 'textoLegado'},
            {label: 'Observações', key: 'observacoes'},
            {label: 'Livro (Letra)', key: 'livroLetra'},
          ];
        }

        return formatters;
      },

    }
  }
</script>

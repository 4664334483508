<template>

  <modal id="formulario-socio" titulo="Gestão de Vínculo Societário" :loading="!form">
    <card>
        <div class="row gutters-xs form">
          <div class="col-12" >
            <v-input class="w-100" v-if="!form.indicadorPessoalSocio || !form.indicadorPessoalSocio.id" label="Nome" :error="validation.indicadorPessoalSocio">
              <buscar-pessoa @add="selecionar($event)" :novo="true"/>
            </v-input>

            <div class="row gutters-xs" v-else>
              <v-input class="col form-group">
                <input type="text" readonly v-model="form.indicadorPessoalSocio.nome" class="form-control form-control-xs"/>
              </v-input>
              <div class="col-auto">
                <button class="btn btn-outline-secondary btn-xs" @click="selecionar(null)"
                        type="button" title="Remover">
                  <i class="fal fa-times"></i>
                </button>
              </div>

              <div class="col-auto">
                <button class="btn btn-outline-secondary btn-xs" @click="openIndicador()"
                        type="button" title="Editar">
                  <i class="fal fa-edit"></i>
                </button>
              </div>
            </div>
          </div>

          <v-input v-model="form.tipoVinculo" :default="$root.enums?.EnumTipoVinculo?.[0]?.id"
                   label="Tipo de Vínculo" type="v-select" :options="$root.enums?.EnumTipoVinculo" class="col-5"/>
          <v-input v-if="form.tipoVinculo === 'OUTRO'"
                   class="col-7" :error="validation.descricao"
                   v-model="form.descricao"
                   type="text" label="Descrição" />

          <div class="w-100" />
          <v-input v-model="form.periodoDesconhecido" label="Período desconhecido?" type="radio" class="col"/>
          <template v-if="!form.periodoDesconhecido">
            <v-input type="date" class="col-4" label="Inicio"
                     v-model="form.inicio" :error="validation.inicio"/>

            <v-input type="date" class="col-4" label="Fim"
                     v-model="form.fim"/>
          </template>

        </div>

    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import SocioBusiness from "@/business/indicadores/SocioBusiness";
  import utils from "@/commons/Utils";
  import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
  import Card from "@/components/Layout/components/Card.vue";
  import Modal from "@/components/Modal";

  export default {
    name: "FormularioSocio",
    components: {Card, Modal},
    props: {
      id: String,
      indicadorPessoal: Object
    },

    modal:{
      width: 600
    },

    async mounted() {
      this.load()
    },

    data() {
      return {
        form: {},
        validation: {},
      }
    },
    methods: {

      close: FrontBusiness.closeModal,

      async load(){
        if (this.id) {
          this.form = await SocioBusiness.getById(this.id);
        }else{
          this.form = {
            periodoDesconhecido: false
          };
        }
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#formulario-socio');
      },

      async salvar() {
        let validated = true;
        let dto = utils.clone(this.form);
        if (!dto?.indicadorPessoalSocio?.id) {
          this.$set(this.validation, 'indicadorPessoalSocio', 'Campo obrigatório');
          validated = false;
        }
        if (!dto.periodoDesconhecido) {
          ['inicio'].forEach(e => {
            if (!dto[e]) {
              this.$set(this.validation, e, 'Campo obrigatório');
              validated = false;
            }
          });
        } else {
          dto.inicio = null;
          dto.fim = null;
        }
        if (dto.tipoVinculo === 'OUTRO') {
          if (!dto.descricao) {
            this.$set(this.validation, 'descricao', 'Campo obrigatório');
            validated = false;
          }
        }
        if (validated) {
          dto.indicadorPessoal = this.indicadorPessoal;
          let retorno = await SocioBusiness.save(dto, this.form.id).catch(this.showError);
          if(retorno){
            this.$set(this.form, 'id', retorno);
            this.close();
          }
        }
      },

      async selecionar(envolvido) {
        let indicadorPessoalSocio = envolvido?.indicadorPessoalId ? await IndicadorPessoalBusiness.getById(envolvido?.indicadorPessoalId) : null;
        this.$set(this.form, 'indicadorPessoalSocio', indicadorPessoalSocio?.indicadorPessoal);
      },

      async openIndicador() {
        let indicadorPessoalSocioVersao = await IndicadorPessoalBusiness.getById(this.form.indicadorPessoalSocio.id);
        let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: indicadorPessoalSocioVersao.id});
        if (retorno) {
          this.selecionar(retorno);
        }
      },

    }
  }
</script>

<style lang=scss>

</style>

<template>

  <card titulo="Tributos e Recolhimentos">

    <card-table class="table-borderless">
      <thead>
        <tr>
          <th class="pr-0" style="min-width: 100px;">Tipo</th>
          <th class="pr-0">Nº do Documento</th>
          <th class="pr-0">Data de Emissão</th>
          <th class="pr-0">Data do Pagamento</th>
          <th class="pr-0" style="width: 130px;">Valor Pago</th>
          <th width="20px">
            <a href="" @click.prevent="add()" class="btn btn-outline-secondary btn-xs" v-if="edicao">
              <i class="fa fa-plus"></i>
            </a>
          </th>
        </tr>
      </thead>

      <tbody>

        <tr v-for="(campo, idx) in ato.recolhimentos" :key="idx">
          <td>
            <v-input :disabled="!edicao" v-model="campo.tipo" type="select" ref="papel" :options="recolhimentos" />
          </td>
          <td>
            <v-input type="text" v-model="campo.numero" :disabled="!edicao"/>
          </td>
          <td>
            <v-input type="date" v-model="campo.emissao" :disabled="!edicao"/>
          </td>
          <td>
            <v-input type="date" v-model="campo.pagamento" :disabled="!edicao"/>
          </td>
          <td>
            <v-input v-model="campo.valorPago" type="currency" :disabled="!edicao"
                     :input-props="{precision : {min : 2, max : 10}}" />
          </td>
          <td class="text-center">
            <div class="ato-action" v-if="edicao">
              <a href="" @click.prevent="remove(idx)" class="icon"><i class="fa fa-times"></i></a>
            </div>
          </td>
        </tr>

        <tr v-if="!ato.recolhimentos || !ato.recolhimentos.length">
          <td colspan="100%" class="p-0">
            <div class="alert alert-outline-secondary alert-sm mb-0">
              Nenhum valor cadastrado
            </div>
          </td>
        </tr>

      </tbody>

    </card-table>

  </card>

</template>

<script>
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import business from "@/views/Protocolo/Atos/business";

export default {

    name: "Recolhimentos",
    components: {CardTable, Card},
    props: ['ato', 'edicao'],

    async created() {
      this.ato.recolhimentos = this.ato.recolhimentos || [];
      this.recolhimentos = await AtoBusiness.recolhimentos();
    },

    beforeMount() {
      this.ato.recolhimentos.sort((a, b) => a.ordem - b.ordem);
    },

    data() {
      return {
        recolhimentos: []
      }
    },

    methods: {
      add() {
        this.ato.recolhimentos.push({tipo: this.recolhimentos?.[0]?.id,
          ordem: this.ato.recolhimentos.length + 1,
        });
        this.$forceUpdate();
      },
      remove(idx){
        this.ato.recolhimentos.splice(idx, 1);
        this.$forceUpdate();
      }
    },

  }
</script>


<template>
  <td :class="column.class" v-if="column.component">
    <component :is="column.component" :column="column" :row="row" />
  </td>
  <td :class="column.class" v-else v-html="value"></td>
</template>

<script>
import Utils from "@/commons/Utils.js";

export default {
  name: "VueTableCell",

  props: {
    column: Object,
    row : Object,
  },

  computed:{

    value(){
      let value = Utils.get(this.row, this.column.field);
      if(this.column?.formatter != undefined){
        return this.column.formatter(value, this.row);
      }
      return value;
    }
  },

}
</script>

<template>
  <div class="form-group">

    <label class="form-label">{{titulo}}</label>
    <div class="w-100"></div>
    <div class="tags">
        <span class="badge badge-secondary mr-1 mb-1 pointer" style="font-weight: 300"
              v-for="item in tags" :key="item.id"
              :class="{'badge-success' :  refresh && list1.includes(item.id), 'badge-danger' :  list2 ? list2.includes(item.id) : false, 'not-allowed' : disabled}"
              @click.prevent="toggle(item.id, list1, list2)">
          {{item.nome}}

          <a href="#" class="tag-addon"
             v-if="list1.includes(item.id)">
            <i class="far fa-check-circle text-white" :class="{'not-allowed' : disabled}"></i>
          </a>

          <a href="#" class="tag-addon"
             v-if="list2 && list2.includes(item.id)">
            <i class="fa fa-times-circle text-white"></i>
          </a>

        </span>
    </div>
  </div>
</template>


<script>
  export default {
    name: "TagList",
    props: {
      titulo : String,
      yes : String,
      no : String,
      tags :Array,
      list1 : Array,
      list2 : Array,
      disabled: {type: Boolean, default: false}
    },
    data(){
      return {
        refresh: true,
      }
    },

    methods:{

      toggle(val, list1, list2){
        if(this.disabled) return;
        let list1Idx = list1.findIndex(e => e == val);
        let list2Idx = list2 ? list2.findIndex(e => e == val): -1;

        if(list1Idx > -1){
          list1.splice(list1Idx, 1);
          if(!list2) list2 = [];
          list2.push(val);
        }else if(list2Idx > -1){
          list2.splice(list2Idx, 1);
        }else{
          list1.push(val);
        }
        this.toggleRefresh();
      },

      toggleRefresh(){
        this.refresh = false;
        this.refresh = true;
      }

    }
  }
</script>

<style lang=scss>
  .not-allowed {
    cursor: not-allowed !important;
  }
</style>

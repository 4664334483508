<template>
  <div>

    <card titulo="Protocolos Ativos">
      <template #acoes>
        <a class="icon float-right" href title="Não Ativos" @click.prevent="protocolosNaoAtivos()">
          NÃO ATIVOS
        </a>
      </template>
      <card-table class="table-borderless mb-2" v-if="protocolos.length > 0">

        <thead>
          <tr>
            <th>Protocolo</th>
            <th>Natureza</th>
            <th>Serviço</th>
            <th>Etapa</th>
            <th>Cadastro</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="protocolo in protocolos" :key="protocolo.id">
            <td class="text-truncate">
              <router-link :to="{ name: 'protocolo', params:{id : protocolo.id} }" class="mb-1 pointer" target="_blank" @click.native="$event.stopImmediatePropagation()">
                {{nomear(protocolo)}}
              </router-link>
            </td>
            <td>{{protocolo.natureza}}</td>
            <td>{{protocolo.tipoServico}}</td>
            <td>{{protocolo.etapa}}</td>
            <td>{{protocolo.cadastro | data}}</td>
          </tr>
        </tbody>

      </card-table>

      <small-loading v-if="loading" ></small-loading>

      <div v-if="!protocolos.length && !loading" class="alert alert-outline-info">
        Não há protocolos ativos para exibir
      </div>

    </card>

  </div>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";
  import ConsultarProtocoloNaoAtivos from "@/components/Protocolo/ConsultarProtocoloNaoAtivos.vue";

  export default {

    name: "Protocolos",
    components: {SmallLoading, CardTable, Card},
    props: {
      pessoa : Object
    },

    data() {
      return {
        protocolos : [],
        loading: true,
      }
    },

    async mounted(){
      this.protocolos = await IndicadorPessoalBusiness.buscarProtocolosAtivos(this.pessoa.indicadorPessoalId);
      this.loading = false;
    },

    methods:{

      nomear(protocolo){
        return FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo)
      },

      protocolosNaoAtivos() {
        FrontBusiness.openConsulta(ConsultarProtocoloNaoAtivos,{ indicadorPessoalId: this.pessoa.indicadorPessoalId });
      },

    }
  }
</script>

<template>
  <modal titulo="Imprimir" id="modal-imprimir" :loading="loading">
    <AlertBanner v-if="alerta && tipo !== 'LIVRO_ESTRANGEIRO'" message="Existem atos que não estão prontos para serem impressos: necessário verificar se os atos não impressos estão selados ou se possuem variáveis de texto não convertidas." type="alert-warning" />
    <card>
      <card-content v-if="!loading">

        <div class="row">

          <v-input type="select" :options="layouts" label="Layout" v-model="form.layout" :class="'col'" />

          <template v-if="!['LIVRO_ESTRANGEIRO', 'REGISTRO_TD', 'REGISTRO_PJ'].includes(tipo)">
            <v-input label="Livro" v-model="form.livro" type="text" class="col-4" />

            <div class="w-100"></div>

            <v-input v-model="form.impressaoCompleta" label="Impressão completa?" type="radio" class="col-6" />

            <template v-if="!form.impressaoCompleta">

              <v-input v-if="atosParaImpressao.length" v-model="form.impressaoParcial"
                       @change="iniciarTexto()"
                       label="Impressão Parcial do ato?" type="radio" class="col-auto" />

              <div class="w-100"></div>
              <div class="form-group input-editor col-12" v-if="form.impressaoParcial && atosParaImpressao.length">
                <label>
                  Próximo ato
                </label>

                <v-input type="editor" @change="validarTexto($event)"
                         v-model="minutaEdicao.texto" ref="editor" @onkeyup="setKeyPressed"
                         :tiny-options="{ toolbar: false, min_height: 100 }"
                />

              </div>

              <v-input label="Primeira Folha" v-model="form.primeiraFolha"
                       type="currency" :input-props="{precision : 0}" class="col" />

              <v-input v-model="form.isFrente" :default="true"
                       label="Lado Inicial" type="radio" class="col-auto" :options="ladosBoolean" />

              <v-input label="Margem Inicial do Texto (mm)" v-model="form.marginInicial"
                       type="currency" :input-props="{precision : 0}" class="col" />

              <div class="w-100"></div>

              <v-input :defaut="false" label="Adicionar Linha Antes do Primeiro Ato?"
                       v-model="form.incluirLinhaAnterior" type="radio" class="col" />

              <v-input :defaut="true" label="Incluir última linha?"
                       v-model="form.incluirLinhaFinal" type="radio" class="col" />

              <div class="w-100"></div>

            </template>

            <template v-else>

              <v-input label="Imprimir até o ato:" v-model="form.ultimoAto"  v-if="form.impressaoCompleta"
                       placeholder="Deixe em branco para imprimir todos os atos"
                       type="currency" :input-props="{precision : 0}" class="col" />

              <div class="w-100"></div>

              <v-input :defaut="true" label="Incluir última linha?"
                       v-model="form.incluirLinhaFinal" type="radio" class="col" />

              <v-input :defaut="false" label="Incluir texto externo? (Matrículas importadas com texto externo)"
                       v-model="form.incluirTextoLegado" type="radio" class="col" />

            </template>

            <div class="w-100"></div>

            <v-input label="Incluir linhas de Encerramento?" v-model="form.incluirLinhaEncerramento"
                     type="radio" class="col" />

            <template v-if="form.incluirLinhaEncerramento">
              <v-input type="number" label="Quantidade de Linhas" class="col" v-model="form.quantidadeLinhas" />
            </template>

            <v-input label="Incluir texto de encerramento" v-model="form.incluirTextoEncerramento" :default="false"
                     type="radio" class="col" />

            <div class="form-group input-editor col-12" v-if="form.incluirTextoEncerramento">
              <label>
                Texto de Encerramento
              </label>

              <v-input type="editor" v-model="form.textoEncerramento" ref="editorEncerramento" :tiny-options="{ min_height: 100 }"/>
            </div>

            <div class="w-100"></div>

            <v-input label="Seguir a sequência da última folha" v-model="form.seguirSequenciaUltimaFolha" type="radio" class="col-5" />
          </template>
        </div>

      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Cancelar</button>
      <button v-if="!simulacao & tipo != 'LIVRO_ESTRANGEIRO'" type="button" @click.prevent="marcarAtosImpressos()" class="btn btn-outline-primary mr-auto">Marcar atos como impressos</button>

      <v-button v-if="!simulacao" :run="run"
                :params="['arquivar']" class="btn-outline-warning ml-2" :disabled="minutaEdicao.editando">Arquivar PDF Assinado</v-button>

      <v-button v-if="!simulacao" :run="run" :params="['baixar']" class="btn-outline-primary ml-2" :disabled="minutaEdicao.editando">Baixar PDF</v-button>
      <v-button :run="run" :class="simulacao ? 'ml-auto' : ''" class="btn-outline-primary ml-2" :disabled="minutaEdicao.editando">Visualizar</v-button>
    </template>

  </modal>
</template>

<script>
import LayoutImpressaoBusiness from "@/business/crud/LayoutImpressaoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";
import moment from "moment";
import AlertBanner from "@/components/alert/AlertBanner";
import LacunaBusiness from "@/business/LacunaBusiness";

export default {
    name: "ImprimirFichaV2",
    components: {CardContent, Card, AlertBanner, Modal},

    props: ['tipo', 'id', 'codigo', 'simulacao', 'atosSimulacao','idAto'],

    async mounted() {

      let promise, promises, alerta;
      if (this.tipo == 'REGISTRO_AUXILIAR') {
        if (!this.simulacao) {
          promise = RegistroAuxiliarBusiness.buscarAtosRegistrados(this.id);
        }
        alerta = RegistroAuxiliarBusiness.verificarAtosImpressao(this.id);
        promises = [LayoutImpressaoBusiness.listByTipo('REGISTRO_AUXILIAR_V2'), LayoutImpressaoBusiness.listByTipo('REGISTRO_AUXILIAR_V3')];
      } else if (this.tipo == 'TRANSCRICAO') {
        promise = IndicadorRealBusiness.buscarAtosRegistrados(this.id);
        promises = [LayoutImpressaoBusiness.listByTipo('TRANSCRICAO')];
      } else if (['REGISTRO_PJ', 'REGISTRO_TD', 'TRANSCRICAO'].includes(this.tipo)) {
        promises = [LayoutImpressaoBusiness.listByTipo(this.tipo)];
      }else if(this.tipo == 'LIVRO_ESTRANGEIRO'){
        if (!this.simulacao) {
          promise = IndicadorRealBusiness.buscarAtosRegistrados(this.id);
        }
        alerta = IndicadorRealBusiness.verificarAtosImpressao(this.id);
        promises = [LayoutImpressaoBusiness.listByTipo('LIVRO_ESTRANGEIRO')];
      } else {
        if (!this.simulacao) {
          promise = IndicadorRealBusiness.buscarAtosRegistrados(this.id);
        }
        alerta = IndicadorRealBusiness.verificarAtosImpressao(this.id);
        promises = [LayoutImpressaoBusiness.listByTipo('MATRICULA_V2'), LayoutImpressaoBusiness.listByTipo('MATRICULA_V3')];
      }

      let r = await Promise.all(promises);
      this.layouts = [...r[0], ...(r?.[1] || [])];
      this.$set(this.form, 'layout', this.layouts?.[0]?.id);

      this.alerta = !(await alerta);
      this.atos = (await promise) || [];

      this.$set(this.form, 'completo', false);
      await this.carregarMemoria();

      this.$set(this, 'loading', false);

    },

    modal: {
      // width: 400,
      escapable: true
    },

    data() {
      return {
        loading: true,

        atos: [],
        alerta: false,
        layouts: [],

        lados: [
          {id: 'frente', nome: 'Frente'},
          {id: 'verso', nome: 'Verso'},
        ],

        ladosBoolean: [
          {id: true, nome: 'Frente'},
          {id: false, nome: 'Verso'},
        ],

        template: '',
        form: {

          layout: null,

          ultimoAto: null,
          impressaoCompleta: true,
          impressaoParcial: false,
          incluirLinhaAnterior : false,
          incluirLinhaEncerramento: false,
          seguirSequenciaUltimaFolha: true,
          incluirLinhaFinal: true,
          incluirTextoLegado: false,
          quantidadeLinhas: 1,
          primeiraFolha: 1,
          isFrente: true,
          marginInicial: 0,

          padrao: {}

        },

        minutaEdicao: {
          atualizado: null,
          editando: false,
          validando: false,
          init: null,
          texto: ''
        },

        keyPressed: null,

        validarTexto: Utils.debounce(this.validarTextoFnc, 300)

      }
    },

    computed: {

      atosParaImpressao(){
        let atosParaImpressao = (Utils.clone(this.atos)).filter(a => this.form.impressaoCompleta  || (!a.impressao && this.form.impressaoParcial !== null)) || [];
        return atosParaImpressao;
      },

      dataImpressao(){
        return moment(this.atosParaImpressao?.[0]?.registro);
      },

      matricula() {
        return this.codigo || '123.123';
      },

      version() {
        console.debug(this.layouts.find(e => e.id == this.form.layout))
        if (['REGISTRO_PJ', 'REGISTRO_TD', 'TRANSCRICAO'].includes(this.tipo)) {
          return 3
        }
        return this.layouts.find(e => e.id == this.form.layout)?.tipo?.includes('_V3') ? 3 : 2;
      },

    },

    methods: {

      esc(e) {
        if (e.keyCode === 27) this.close()
      },

      iniciarTexto(e){
        this.$set(this.minutaEdicao, 'texto', this.atosParaImpressao?.[0]?.texto);
      },

      validarTextoFnc(e){

        try{

          const limparString = (str) => {
            return Utils.clone((Utils.removeHTML(str)).replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g,'').replaceAll(" ", "").trim());
          }

          const original = limparString(this.atosParaImpressao?.[0]?.texto);
          const modificado = limparString(this.minutaEdicao.texto);

          // console.debug('validarTexto', {original, modificado, result: original.endsWith(modificado), keyPressed: this.keyPressed});

          if(!original.endsWith(modificado) || !modificado || (this.keyPressed && this.keyPressed != 46 && this.keyPressed != 8)){

            let msg = !this.minutaEdicao.texto?.length ? 'Não é permitido apagar todo o conteúdo do ato' : 'Permitido editar apenas o início do ato';

            this.iniciarTexto();

            setTimeout(() => {
              let modal = document.getElementById('modal-imprimir');
              if(modal) {
                FrontBusiness.showError('', msg);
              }
            }, 100);
            this.$forceUpdate();
          }

        }catch (e){
          console.error(e);
        }

      },

      async clickEditor(e, focus = false) {
        console.debug(e, focus);
        if (focus && !this.minutaEdicao.editando) {
          this.minutaEdicao.editando = true;
          this.minutaEdicao.init = Utils.clone(this.$refs.editor.$el.innerText);
        } else if (!focus) {
          this.minutaEdicao.validando = true;
          let newValue = this.$refs.editor.$el.innerText;
          if (newValue.charCodeAt(0) === 160) newValue = newValue.split(newValue.charAt(0))[1];
          if ((this.minutaEdicao.init || '').endsWith(newValue)) {
            await this.$set(this.atosParaImpressao[0], 'texto', Utils.clone(this.minutaEdicao).texto);
          } else {
            let msg = !Utils.clone(this.minutaEdicao).texto.length ? 'Não é permitido apagar todo o conteúdo do ato' : 'Permitido editar apenas o início do ato'
            setTimeout(() => {
              let modal = document.getElementById('modal-imprimir');
              if(modal) FrontBusiness.showError('', msg)
            })
            await this.$set(this.minutaEdicao, 'texto', Utils.clone(this.atosParaImpressao[0]).texto);
          }
          await this.$forceUpdate();
          this.minutaEdicao.init = null;
          this.minutaEdicao.validando = false;
          this.minutaEdicao.editando = false;
        }
      },

      CaptureKeys( editor ) {
        editor.editing.view.document.on( 'keydown', ( evt, data ) => {
          if (data.domEvent.keyCode !== 46 && !([37, 38, 39, 40].includes(data.domEvent.keyCode))) data.domEvent.returnValue = false;
        })
      },

      close(response) {
        FrontBusiness.closeModal(response);
      },

      async run(modo = 'visualizar') {

        if(!this.form.layout){
          return;
        }

        this.$set(this.minutaEdicao, 'atualizado', this.$moment());
        if(!this.form.impressaoCompleta && this.form.impressaoParcial) {
          this.validarTextoFnc();
        }

        try{

          let dto = {
            impressaoCompleta : this.form.impressaoCompleta,
            marginInicial : this.form.marginInicial,

            incluirLinhaAnterior : this.form.incluirLinhaAnterior,
            incluirLinhaFinal: this.form.incluirLinhaFinal,

            incluirLinhaEncerramento : this.form.incluirLinhaEncerramento,
            quantidadeLinhas : this.form.quantidadeLinhas,

            incluirTextoLegado : this.form.incluirTextoLegado,
            primeiroAto : this?.minutaEdicao?.texto,

            incluirTextoEncerramento : this.form.incluirTextoEncerramento,
            textoEncerramento : this.form.textoEncerramento,
            ultimoAto: !this.form.impressaoCompleta ? 999999 : this.form.ultimoAto,

            livro : this.form.livro,
          }

          if(!this.form.impressaoCompleta) {
            dto.impressaoParcial = this.form.impressaoParcial;
            dto.primeiraFolha = this.form.primeiraFolha;
            dto.isFrente = this.form.isFrente;
          }

          if ((this.atosSimulacao || []).length) {
            dto.atos = (this.atosSimulacao || []).map((a) => {
              return {
                id: a.id,
                registro: this.$moment().format('YYYY-MM-DD'),
                texto: a.texto || a.minuta
              }
            })
          }

          console.debug('config', dto);

          let pdf = LayoutImpressaoBusiness.imprimirFichaUrl(this.tipo, this.form.layout, this.id, this.version);
          if (this.tipo === 'LIVRO_ESTRANGEIRO') {
            pdf = `api/livro-estrangeiro/${this.form.layout}/imprimir/${this.idAto}`;
          }

          switch (modo){
            case 'arquivar':{

              dto.indicador = this.id;
              if (this.tipo === 'LIVRO_ESTRANGEIRO') {
                dto.idAto = this.idAto;
              }
              await LacunaBusiness.sign('arquivar-impressao', this.form.layout, '', 'impressao-eletronica.pdf', false, dto, false).then(() => {
                FrontBusiness.showSuccess('', 'Impressão arquivada com sucesso');
              }).catch(e => {
                FrontBusiness.showError('Erro ao tentar assinar o livro', e?.message || e);
                return false;
              });

              // await this.$api.post(`${pdf}/true`, dto).then(e => {
              //   FrontBusiness.showSuccess('', 'Impressão arquivada com sucesso');
              // }).catch(e => {
              //   FrontBusiness.showError('Erro ao arquivar impressão', e.message);
              // });

              // await RelatorioBusiness.visualizarPdf(pdf, 'impressao', 'POST', dto);
              break;
            }
            case 'visualizar':{
              await RelatorioBusiness.visualizarPdf(pdf, 'impressao', 'POST', dto);
              break;
            }
            default:{
              await RelatorioBusiness.downloadPdf(pdf, 'impressao', 'POST', dto);
              break;
            }
          }

        }catch (e){
          console.error(e);
        }
      },

      async marcarAtosImpressos() {
        await AtoBusiness.marcarAtosImpressos(this.tipo, this.id, true);
        FrontBusiness.showSuccess('', 'Atos marcados como impressos com sucesso!');
      },

      setKeyPressed(event){
        this.$set(this, 'keyPressed',  event.which || event.keyCode);
      },

      setNonNull(key, value){
        if(value != undefined){
          this.$set(this.form, key, value);
        }
      },

      async carregarMemoria(){
        // if(false){
          this.$set(this, 'loading', true);
          let cfg = await RelatorioBusiness.ultimaImpressaoFicha(this.tipo, this.id);
          if(cfg?.layout){
            this.setNonNull('impressaoCompleta', cfg?.impressaoCompleta);
            this.setNonNull('primeiraFolha', cfg?.primeiraFolha);
            this.setNonNull('isFrente', cfg?.isFrente);
            this.setNonNull('marginInicial', cfg?.marginInicial);
            this.setNonNull('incluirLinhaAnterior', cfg?.incluirLinhaAnterior);
            this.setNonNull('incluirLinhaFinal', cfg?.incluirLinhaFinal);
            this.setNonNull('incluirLinhaEncerramento', cfg?.incluirLinhaEncerramento);
            this.setNonNull('quantidadeLinhas', cfg?.quantidadeLinhas);
            this.setNonNull('impressaoParcial', cfg?.impressaoParcial);
            this.setNonNull('primeiroAto', cfg?.primeiroAto);
            this.setNonNull('incluirTextoEncerramento', cfg?.incluirTextoEncerramento);
            this.setNonNull('textoEncerramento', cfg?.textoEncerramento);
            this.setNonNull('livro', cfg?.livro);
            this.setNonNull('layout', cfg?.layout);
          }
          this.$set(this, 'loading', false);
        // }
      },

    }
  }
</script>

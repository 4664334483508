<template>
  <modal id="form-indicador-real" :titulo="titulo" :loading="!form">
    <form @submit.prevent="" v-if="form" autocomplete="off">

      <card>

        <card-content class="row gutters-xs">
          <v-input :error="validation.versao" v-model="form.versao" type="text" label="Versão" class="col-1"/>

          <div class="col-auto pr-4">
            <v-input :error="validation.categoria" v-model="form.categoria" :options="options.categorias"
                     type="radio" class="mb-2" />

            <div class="w-100"></div>

            <div class="form-group mb-0">
              <input style="position: relative; top: 3px; left: 0px;" type="checkbox" id="FLAG_UNIDADE_AUTONOMA"
                     v-model="form.unidadeAutonoma" />
              <label for="FLAG_UNIDADE_AUTONOMA" style="position: relative; top: 0px; left:4px;" class="pointer">
                Unidade Autônoma?
              </label>
            </div>

          </div>

          <v-input :error="validation.tipoImovel" v-model="form.tipoImovel" :options="options.tipos"
                   type="select" class="col" label="Tipo de imóvel" />

          <v-input class="col" v-model="form.descricaoTipoImovelOutros" v-if="form.tipoImovel == 'OUTROS'"
                   type="text" label="Descrição do Tipo de Imóvel" />

          <div class="w-100"></div>

          <v-input :default="false" :error="validation.imovelUniao" v-model="form.imovelUniao" label="Imóvel da União" type="radio" class="col-4" :required="true" />
          <v-input :default="false" :error="validation.marinha"     v-model="form.marinha" label="Imóvel da Marinha" type="radio" class="col-4" :required="true" />
          <v-input :default="false" :error="validation.fronteira"   v-model="form.fronteira" label="Imóvel de Fronteira" type="radio" class="col-4" :required="true" />
          <div class="w-100"></div>

        </card-content>

      </card>

      <card titulo="Identificação">
        <card-content class="row gutters-xs">

          <template v-if="form.unidadeAutonoma">

            <v-input class="col" v-model="form.unidade" type="text" label="Número da unidade" />
            <v-input class="col" v-model="form.bloco" type="text" label="Bloco" />

            <div class="w-100"></div>
          </template>

          <v-input class="col-3" v-model="form.quadra" type="text" label="Quadra" />
          <v-input class="col-3" v-model="form.lote" type="text" label="Lote" />

          <v-input class="col" label="Localização" v-model="form.localizacao" type="text" />

          <div class="w-100"></div>
          <v-input class="col" v-model="form.empreendimento" type="text" label="Empreendimento/Condomínio" />

        </card-content>
      </card>

      <card titulo="Informações Complementares">
        <card-content class="row gutters-xs">

          <template v-if="form.unidadeAutonoma">
            <v-input class="col" v-model="form.andar" type="text" label="Andar" />
            <v-input class="col" v-model="form.pavimento" type="text" label="Pavimento" />
            <v-input class="col" v-model="form.grupo" type="text" label="Grupo (Tipo)" />
            <div class="w-100"></div>
          </template>

          <template v-if="form.categoria == 'RURAL'">
            <v-input class="col" v-model="form.denominacao" type="text" label="Denominação da Área Rural" />
            <div class="w-100"></div>
            <v-input class="col" v-model="form.incra" type="text" label="INCRA (CCIR)" />
            <v-input class="col" v-model="form.itr" type="text" label="ITR" />
            <v-input class="col" v-model="form.car" type="text" label="CAR" />
            <v-input class="col" v-model="form.certificacaoIncra" type="text" label="Certificação no SIGEF" />
          </template>
          <div class="w-100"></div>
          <v-input class="col mb-3" v-model="form.cadastroImobiliarioBrasileiro" type="text" label="Cadastro Imobiliário Brasileiro" :inputProps="{ 'maxlength': 8}" />
          <v-input class="col mb-3" v-model="form.cadastroImobiliario" type="text" label="Cadastro imobiliário" />
          <v-input class="col mb-3" v-model="form.cadastroFiscal" type="text" label="Cadastro fiscal" />
        </card-content>
      </card>

      <card titulo="Endereço">
        <card-content class="row gutters-xs">

          <v-input-cep :validation="validation.cep" v-model="form.cep" @endereco="atualizarEndereco" />

          <v-input class="col-3" v-model="form.tipoLogradouro" type="text" label="Tipo logradouro" />
          <v-input class="col" v-model="form.logradouro" type="text" label="Logradouro (rua)" />
          <v-input class="col-2" v-model="form.numero" type="text" label="Número" />

          <div class="w-100"></div>

          <v-input class="col" v-model="form.bairro" type="text" label="Bairro (setor)" />
          <v-input class="col" v-model="form.cidade" type="text" label="Cidade" />
          <v-input class="col-auto" v-model="form.estado" :options="options.estados" type="select" label="Estado" />

          <div class="w-100"></div>

          <v-input class="col mb-3" v-model="form.complemento" type="text" label="Complemento" />

        </card-content>
      </card>

    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>
<script>

  import EnderecoBusiness from "@/business/EnderecoBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import utils from "@/commons/Utils";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils";
  import IndicadorRealHistoricoBusiness from "@/business/indicadores/IndicadorRealHistoricoBusiness";

  export default {
    name: "FormIndicadorRealHistorico",
    components: {
      Card,
      CardContent,
      Modal,
    },

    modal: {
      escapable: false
    },

    props: {
      id: String,
      indicador: Object
    },

    async mounted() {

      let form = Utils.clone(this.indicador);

      if (this.id) {
        form = await IndicadorRealHistoricoBusiness.getById(this.id);
        this.titulo = `Editar dado anterior`;
      } else {
        this.titulo = 'Adicionar dado anterior';
      }
      form.unidadeAutonoma = !!form.unidadeAutonoma;
      this.$set(this, 'form', form);

    },

    data() {
      return {
        form: null,
        validation: {},
        titulo: '',
        decimais: {min : 2, max : 10},
        options: {
          tipos: IndicadorRealBusiness.getTiposImovel(),
          categorias: IndicadorRealBusiness.getCategorias(),
          estados: EnderecoBusiness.getEstados(),
        },
      }
    },

    computed: {
    },

    methods: {
      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        FrontBusiness.showError('', e.message);
      },

      async salvar() {

        this.$set(this, 'validation', {});
        let validated = true;
        let dto = utils.clone(this.form);
        ['versao'].forEach(e => {
          if (!dto[e]) {
            this.$set(this.validation, e, 'Campo obrigatório');
            validated = false;
          }
        });

        if (validated) {
          let indicador = await IndicadorRealHistoricoBusiness.save(dto, this.id).catch(this.showError);
          if (indicador) {
            FrontBusiness.closeModal(indicador);
          }
        }
      },

      atualizarEndereco(endereco){
        if (endereco) {
          this.$set(this.form, 'cep', endereco.cep);
          this.$set(this.form, 'logradouro', endereco.logradouro);
          this.$set(this.form, 'bairro', endereco.bairro);
          this.$set(this.form, 'cidade', endereco.cidade);
          this.$set(this.form, 'estado', endereco.estado);
          this.getFocusElements('Logradouro (rua)');
        }
      },

    }

  }
</script>

<style lang=scss>
  #form-indicador-real {
    .card {
      hr {
        margin-top: .25rem;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>

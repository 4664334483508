<template>
  <card titulo="SICASE" v-if="$root.config.estado === 'PE'">

    <card-content class="mb-2" :background="true">
      <div class="row gutters-xs">
        <v-input type="text" class="col" :error="validation.guia" :disabled="!edicao"
                 label="Número da Guia SICASE" v-model="item.guia" :inputProps="{ 'maxlength': 8}" @change="validarGuia()"/>
        <div class="w-100" />
        <v-input type="v-select" :options="tiposTribunal" class="col-12" v-if="tiposTribunal.length" :disabled="!edicao"
                 label="Natureza SICASE" v-model="item.codigoTj" />
      </div>
    </card-content>

  </card>
</template>

<script>
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
export default {
  name: "VinculoSicase",
  components: {Card, CardContent},
  props:{
    item:Object,
    edicao:Boolean,
    dominio:{type: String, default: ""},
    tipo:String,
    validation:{type: Object, default: () => ({})},
  },
  data(){
    return {
      tiposTribunal: [],
    }
  },

  async created() {
    let tiposTribunal = await TipoServicoBusiness.getTiposAtoTribunal(/*this.dominio*/);
    if (!this.item?.codigoTj) {
      this.item.codigoTj = (((tiposTribunal || []).find(e => e.id === this.item?.tipoServico?.tipoTribunal))?.codigo) || '';
      this.item.codigoTj = this.item.codigoTj + '';
    }
    this.tiposTribunal = tiposTribunal.map(t => {
      return {id: t.codigo + '', nome: t.nome}
    });
  },

  methods:{
    validarGuia(){
      let guia = this.item.guia;
      this.$set(this.validation, 'guia', null);
      if(guia.length < 8){
        this.$set(this.validation, 'guia', 'Número da Guia SICASE com quantidade de caracteres inválidos');
      }
    }
  }

}
</script>

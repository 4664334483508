<template>
  <vue-table :service="paginar" :columns="columnsComputed" :paginar="true" :header="false" :filters="filtros" :responsive="true">

    <template #filters>

      <div class="row">
        <v-input type="text" class="col-12" v-model="filtros.usuario" label="Usuário" placeholder="Nome do usuário" />
        <v-input type="text" class="col-12" v-model="filtros.descricao" label="Descrição" placeholder="Descrição" />
      </div>

    </template>

    <template #row="{row}">

      <tr>

        <td class="text-right" data-testid="detalhes-auditoria">
          <v-button :run="detalhes" :params="[row]" :button="false" class="icon text-muted" v-if="(row.detalhes == undefined || row.detalhes) && !(row.descricao || '').includes('Assinou') && !(row.descricao || '').includes('Unificou')">
            <template #default="{ loading }">
              <template v-if="!loading">
                <i class="fal fa-info-circle"></i>
              </template>
            </template>
          </v-button>
        </td>

        <td class="text-left">{{$formatters.datetime(row.cadastro)}}</td>
        <td>{{exibirUsuario(row) ? row.usuario : ''}}</td>
<!--        <td>{{tipos[row.operacao]}}</td>-->

        <slot name="colunas" :row="row"></slot>

        <td>{{row.descricao || `${tipos[row.operacao]} [${row.entidade}]` }}</td>

      </tr>

    </template>

  </vue-table>
</template>

<script>
import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import AuditoriaDetails from "@/v2/components/Auditoria/AuditoriaDetails.vue";
export default {

  name: "AuditoriaPagination",

  props:{
    entidade: String,
    referencia: String,
    protocolo: String,
    operacao: {type : String, default: ''},
    dominio: {type : String, default: ''},

    formatters: Function,
    columns: Array,
    mostrarOperacao: {type : Boolean, default: () => false}
  },

  data(){
    return {
      filtros: {},
      filtrosBase: {
        entidade: this.entidade ? this.entidade.split(', ') : this.entidade,
        operacao: this.operacao ? this.operacao.split(', ') : this.operacao,
        referencia: this.referencia,
        dominio: this.dominio,
        protocolo: this.protocolo
      },
      tipos: AuditoriaBusiness.operacoes
    }
  },

  computed:{

    columnsComputed(){
      return [
        { width : '30px' },
        {label: 'Data', sortable : 'cadastro', width : '100px'},
        {label: 'Usuário', sortable : 'usuario', width : '150px'},
        // {label: 'Operação', sortable : 'a.operacao', minWidth : '150px'},
        ...(this.columns || []),
        {label: 'Descrição', sortable : 'descricao'},
      ];
    },

  },

  methods:{

    exibirUsuario(row) {
      if(!this.$root.usuarioLogado.administrador && !this.$root.usuarioLogado.gestor && row.entidade === 'INCONFORMIDADE'){
        return false
      }
      return true
    },

    async detalhes(row){
      let formatters = await this.formatters(row);
      await FrontBusiness.openModal(AuditoriaDetails, {id : row.id, formatters});
    },

    async paginar(sort, direction, page, perPage){
      let filtros = {...this.filtros, ...this.filtrosBase};
      return AuditoriaBusiness.pagination(sort, direction, page, perPage, {filtros}).catch(() => []);
    }

  }

}
</script>

import Utils from "@/commons/Utils.js";

const criarModelo = async function(node, callback, dicionario, debug){

  console.debug(node);

  let nome = node.params.nome;

  if(!dicionario?.templates){
    dicionario.templates = {};
  }

  dicionario.templates[nome] = Utils.clone(node.children);

  return "";

};

export default criarModelo;

import FrontBusiness from "@/business/FrontBusiness.js";
import TextParser from "@/business/livros/TextParser.js";
import moment from "moment";

const proxy = function(value, key){

  try {

    if (value === true || value == "true" || value == 'Sim') {
      return 'sim';
    }

    if (value === false || value == "false" || value == 'Não') {
      return 'não';
    }

    // if (typeof (value) == typeof (0.0)) {
    //   FrontBusiness.showWarn(`Atenção: Váriavel do tipo "numeral" [${key}] usada fora do método correto. Favor avisar a coordenação.` );
    //
    //   // return parseFloat(value).toLocaleString("pt-BR", {
    //   //   minimumFractionDigits: 2,
    //   //   maximumFractionDigits: 5,
    //   //   style: 'currency',
    //   //   currency: 'BRL'
    //   // }).replace("R$ ", "");
    // }
    //
    // if (moment(value, 'YYYY-MM-DD', true).isValid()) {
    //   FrontBusiness.showWarn(`Atenção: Váriavel do tipo "data" [${key}] usada fora do método correto. Favor avisar a coordenação.` );
    //   // return moment(value).format('DD/MM/YYYY');
    // }

  } catch (e) {
    console.error(e);
  }

  return value;

};

export default proxy;

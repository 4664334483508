import Http from "@/commons/Http";

const path = 'api/indicador-real-historico';

export default {

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async listarDadosAnteriores(id){
    return Http.get(`${path}/${id}/dados-anteriores`);
  },

  async delete(id){
    return Http.delete(`${path}/${id}`);
  },

}

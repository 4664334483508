
<template>
  <modal titulo="Gerar imagens de atos digitais"  id="modal-gerar-atos-digitais"  :loading="loading">
    <card>
      <card-content v-if="!loading">
        <div class="row">
        <template>
          <v-input :defaut="true" label="Impressão completa?"  v-model="form.impressaoCompleta" type="radio" class="col-4" />
          <v-input :defaut="true" label="Incluir última linha?"  v-model="form.incluirLinhaFinal" type="radio" class="col-4" />
          <v-input v-if="!idProtocolo && !isLote" label="Livro" v-model="form.livro" type="text" class="col-4" />
        </template>

          <div class="w-100"></div>

        <template>
          <v-input label="Incluir linhas de Encerramento?" v-model="form.incluirLinhaEncerramento" type="radio" class="col-5" />

          <template v-if="form.incluirLinhaEncerramento">
            <v-input type="number" label="Quantidade de Linhas" v-model="form.quantidadeLinhas" class="col-7"  />
          </template>

        </template>

          <template>
            <v-input label="Incluir texto de encerramento" v-model="form.incluirTextoEncerramento"  :default="false" type="radio" class="col" />

                    <div class="form-group input-editor col-12" v-if="form.incluirTextoEncerramento">
                      <label>
                        Texto de Encerramento
                      </label>

                      <v-input type="editor" v-model="form.textoEncerramento" ref="editorEncerramento" :tiny-options="{ min_height: 100 }"/>
                    </div>

            <div class="w-100"></div>
          </template>

          <v-input label="Seguir a sequência da última folha" v-model="form.seguirSequenciaUltimaFolha" type="radio" class="col-5" />
        </div>


        <card-table class="mt-1 mb-3" v-if="isLote">
          <tbody>
          <tr v-for="(ficha, i) in fichas" :key="`${ficha.livro}-${ficha.id}`">
            <td>
              {{nomearFicha(ficha)}}
            </td>
            <td width="30px">
              <a @click.prevent="removerFichas(i)" class="icon">
                <i class="fa fa-times"></i>
              </a>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <buscar-fichas :limparCampo="true" :livro="entidade"
                             status="ABERTA" ref="buscarFichas" @selecionar="selecionarFichas" />
            </td>
          </tr>
          </tbody>

        </card-table>
      </card-content>

    </card>
    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Cancelar</button>

      <v-button :run="gerarAtosDigitais" :popover="true"
                 :button="true" class="btn btn-outline-primary mr-2 ">
        Continuar
      </v-button>
    </template>
  </modal>

</template>

<script>

import Modal from "@/components/Modal.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import FrontBusiness from "@/business/FrontBusiness";
import AnexoBusiness from "@/business/AnexoBusiness";
import CardTable from "@/components/Layout/components/CardTable.vue";

export default {
  name: "ModalGerarImagemAtoDigital",
  components: {CardTable, CardContent, Card, Modal},

  props: {
    id:String,
    entidade:String,
    isLote:{type:Boolean,default:false},
    idProtocolo:{type:String,default: null}
  },

  modal: {
    // width: 400,
    escapable: true
  },

  data() {
    return {
      loading: false,
      fichas: [],
      form: {
        id:null,
        entidade:'',
        incluirLinhaEncerramento: false,
        impressaoCompleta: false,
        incluirLinhaFinal: true,
        incluirTextoEncerramento: false,
        seguirSequenciaUltimaFolha: true,
        quantidadeLinhas: 1,
        textoEncerramento:'',
        idProtocolo:null

      },
    }
  },
  methods:{
    nomearFicha: FrontBusiness.nomearFicha,

    close(response) {
      FrontBusiness.closeModal(response);
    },
    async gerarAtosDigitais() {
      this.$set(this, 'loading', true);
      if (this.idProtocolo) {
        await this.gerarAtosDigitaisProtocolo();
      } else {
        if (this.entidade) {
          if (this.isLote) {
            this.form.ids = this.fichas.map(f => f.id);
          } else {
            this.form.ids = [this.id];
          }
          this.form.entidade= ['MATRICULA'].includes(this.entidade) ? 'INDICADOR_REAL' : this.entidade;
          await AnexoBusiness.gerarDigitalizacaoAtoDigital(this.form)
            .then(() => {
              FrontBusiness.showSuccess('', 'Ação realizadas com sucesso');
              this.close(true)
            })
            .catch(error => {
              FrontBusiness.showError('', error.exception?.replace("ResponseException: ", "") || error.message);
            })
        }else{
          FrontBusiness.showError('','Identificação do registro ou entidade invalida!')
        }
      }
      this.$set(this, 'loading', false);
    },

    async gerarAtosDigitaisProtocolo() {
      this.form.idProtocolo = this.idProtocolo;
      await AnexoBusiness.gerarDigitalizacaoAtoDigitalProtocolo(this.form)
        .then(() => {
          FrontBusiness.showSuccess('', 'Ação realizadas com sucesso');
          this.close(true)
        })
        .catch(error => {
          FrontBusiness.showError('',error.exception?.replace("ResponseException: ", "") || error.message);
        })
    },

    removerFichas(idx) {
      this.$nextTick(() => {
        this.fichas.splice(idx, 1);
      });
    },

    selecionarFichas(fichas) {
      this.$nextTick(() => {
        fichas?.forEach((ficha) => {
          if (ficha && !this.fichas.find(e => e.id === ficha.id && e.livro === ficha.livro)) {
            this.fichas.push(ficha);
          }
        });
      });
    },
  }

}

</script>



<style lang="scss">
#modal-gerar-atos-digitais {
  .modal-footer {
    .d-flex {
      width: 100%;
    }
  }
}
</style>

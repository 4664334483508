<template>

  <modal :titulo="titulo" :loading="loading" :focus="false">

    <card>

      <div class="row gutters-xs">

        <v-input class="col-12" label="Serventia" v-model="form.serventia" :options="options.serventias" type="v-select" :error="validation.serventia"/>
        <v-input :error="validation.tipo" class="col-4" v-model="form.tipo" :options="options.tipos"
                 type="select" label="Tipo" />
        <v-input :error="validation.codigo" class="col-8" v-model="form.codigo" type="currency" :input-props="{precision : 0}" label="Código"/>
        <template v-if="form.tipo === 'TRANSCRICAO'">
          <div class="w-100"></div>
          <v-input class="col" :error="validation.livroNumero" v-model="form.livroNumero" type="text" label="Livro (Número)" />
          <v-input class="col" :error="validation.livroLetra" v-model="form.livroLetra" type="text" label="Livro (Letra)" />
          <v-input class="col" :error="validation.livroFolha" v-model="form.livroFolha" type="text" label="Folha" />
        </template>
      </div>

    </card>


    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <button type="button" @click.prevent="save()" class="btn btn-outline-primary">Salvar</button>
    </template>

  </modal>

</template>
<script>

import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness";
import ListaBusiness from "@/business/crud/ListaBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal";

export default {
  name: 'FormIndicadorRealExterno',

  components: { Card, Modal },

  modal: {
    width: 700
  },

  props: {
    id: String,
  },

  data() {
    return {
      titulo: 'Indicador Real Externo',
      loading: false,
      validation: {},
      form: {},
      options: {
        tipos: [
          {id: 'MATRICULA', nome: 'Matrícula', livro: "MATRICULA", status: 'ABERTA'},
          {id: 'TRANSCRICAO', nome: 'Transcrição', livro: "TRANSCRICAO"},
          {id: 'TORRENS', nome: 'Torrens', livro: "TORRENS"},
        ],
        serventias: []
      }
    }
  },

  async mounted() {
    let config = this.$root.config;
    let serventia = await ListaBusiness.getById( config?.listaRegistroAnterior?.id);
    this.$set(this.options, 'serventias', (serventia || {lista: []}).lista.map(e => ({
      id: e,
      nome: e
    })));

    let indicador = {};
    if (this.id) {
      indicador = await IndicadorRealExternoBusiness.getById(this.id);
    }

    let form = {tipo: 'MATRICULA', serventia: '',
      registro: null, livroNumero: '', livroLetra: '', livroFolha: '', ...indicador};

    this.$set(this, 'form', form);

  },

  computed: { },

  methods: {
    close(retorno) {
      FrontBusiness.closeModal(retorno);
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '#formulario-cadastro-protocolo');
    },

    async save() {
      let form = this.form;
      const validation = IndicadorRealExternoBusiness.getErrosForm(this.form) || {};
      if (Object.keys(validation).length) {
        this.$set(this, 'validation', validation);
      } else {
        let retorno = await IndicadorRealExternoBusiness.save(form, form?.id).catch(this.showError);;
        if (retorno) {
          this.close(retorno);
        }
      }
    },
  }
}

</script>

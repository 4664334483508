<template>
  <modal titulo="Consultar Modelos Textuais" :loading="!mostrar">

    <card titulo="Escolha o modelo para visualizar">

      <div class="row">
        <v-input type="v-select" :options="modelos" v-model="form.modelo" class="col" />
      </div>

    </card>

    <card titulo="Preview" v-if="textoSelecionado">
      <div v-html="textoSelecionado"></div>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Cancelar</button>
      <v-button :run="converter" class="btn-outline-primary" v-if="copilador">Converter Texto</v-button>
      <v-button :run="run" :popover="true" class="btn-outline-primary ml-auto">Utilizar Modelo</v-button>
    </template>

  </modal>
</template>

<script>
import ModeloTextualBusiness from "@/business/crud/ModeloTextualBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal";
import DominioBusiness from "@/business/protocolo/DominioBusiness";

export default {
  name: "ModalSelecionarModeloTextual",
  components: {Card, Modal},
  props: {
    tipo: String,
    copilador:{type: Function},
    dominio: String
  },

  data() {
    return {
      mostrar: false,
      modelos: [],
      copilados: {},
      form: {
        modelo: null,
      }
    }
  },

  computed:{
    textoCopilado(){
      return this.form?.modelo && !!this.copilados?.[this.form?.modelo];
    },
    textoSelecionado(){
      if(this.textoCopilado){
        return this.copilados?.[this.form?.modelo];
      }
      return this.modelos?.find(e => e.id == this.form?.modelo)?.minuta;
    }
  },

  async mounted() {
    this.modelos = await ModeloTextualBusiness.listByTipo(this.tipo, DominioBusiness.getDominio(this.dominio)?.atribuicao || null);
    this.$set(this, 'mostrar', true);
  },

  methods: {

    close() {
      FrontBusiness.closeModal();
    },

    async run() {
      let modelo = this.textoSelecionado;
      if(!this.textoCopilado && this.copilador){
        modelo = await this.copilador(this.textoSelecionado, this.form?.modelo);
      }
      FrontBusiness.closeModal({texto: modelo});
    },

    async converter(){
      let modelo = await this.copilador(this.textoSelecionado, this.form?.modelo);
      this.$set(this.copilados, this.form?.modelo, modelo);
    }

  }
}
</script>

import FrontBusiness from "@/business/FrontBusiness";
import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness";
import Utils from "@/commons/Utils";

export default {

  async validarMargemTransferenciaRegistro(somaFracao = 0){
    let erro = null;

    if(!window.server.config){
      throw 'Erro de configuração';
    }

    const configuracao = window.server.config?.configuracaoFracaoTransferenciaImovel;

    if(!configuracao.habilitarMargem && (parseFloat(somaFracao).toFixed(10) !== parseFloat("100").toFixed(10))){
      erro = {
        mensagem: `Percentual total de propriedade ficará incompleto após o registro: ${FrontBusiness.formatNumber(somaFracao, 2, 10)}%`,
        tipo: 'erro',
      };
    } else {
      if (somaFracao < (100 - configuracao.margemTransferencia)) {
        erro = {
          mensagem: `Percentual total de propriedade ficará inferior ao permitido (${FrontBusiness.formatNumber(100 - configuracao.margemTransferencia, 2, 10)}%) após o registro: ${FrontBusiness.formatNumber(somaFracao, 2, 10)}%`,
          tipo: 'erro',
        };
      }else if(somaFracao < 100){
        erro = {
          mensagem: `Percentual total de propriedade ficará menor que 100% após o registro: ${FrontBusiness.formatNumber(somaFracao, 2, 10)}%`,
          tipo: 'warning',
        };
      }if(somaFracao > 100 && somaFracao <= (100 + configuracao.margemTransferencia)){
        erro = {
          mensagem: `Percentual total de propriedade ficará maior que 100% após o registro: ${FrontBusiness.formatNumber(somaFracao, 2, 10)}%`,
          tipo: 'warning',
        };
      }else if (somaFracao > (100 + configuracao.margemTransferencia)) {
        erro = {
          mensagem: `Percentual total de propriedade ficará superior ao permitido (${FrontBusiness.formatNumber(100 + configuracao.margemTransferencia, 2, 10)}%) após o registro: ${FrontBusiness.formatNumber(somaFracao, 2, 10)}%`,
          tipo: 'erro',
        };
      }
    }

    return erro;
  },

  validarAreaEnvolvidos(percentualTransmitente = 0, percentualAdquirente = 0, unidadeArea) {
    let erro = null;
    let percentualTransmitenteDisplay = FrontBusiness.formatNumber(percentualTransmitente, 2, 10);
    let percentualAdquirenteDisplay = FrontBusiness.formatNumber(percentualAdquirente, 2, 10);
    const mensagemFracaoTransmitente = `(Transmitente, Cedente ou Promitente Transmitente) ${percentualTransmitenteDisplay}${unidadeArea}`;
    const mensagemFracaoAdquirente = `(Adquirente, Cessionário ou Promitente Adquirente) ${percentualAdquirenteDisplay}${unidadeArea}`;
    let mensagem = `A somatória das frações (${unidadeArea}) está incorreta: `;

    if (percentualTransmitente != percentualAdquirente) {
      mensagem += `${mensagemFracaoTransmitente} e ${mensagemFracaoAdquirente}`;
      erro = {mensagem: mensagem, cssClass: 'danger'};
    }
    return erro;
  },

  async validarMargemTransferenciaAto(percentualTransmitente = 0, percentualAdquirente = 0, isAtoAbertura = false){
    let erro = null;

    if(!window.server.config){
      throw 'Erro de configuração';
    }

    const configuracao = window.server.config?.configuracaoFracaoTransferenciaImovel;

    let percentualTransmitenteDisplay = FrontBusiness.formatNumber(percentualTransmitente, 2, 10);
    let percentualAdquirenteDisplay = FrontBusiness.formatNumber(percentualAdquirente, 2, 10);

    const mensagemFracaoTransmitente = `(Transmitente, Cedente ou Promitente Transmitente) ${percentualTransmitenteDisplay}%`;
    const mensagemFracaoAdquirente = `(Adquirente, Cessionário ou Promitente Adquirente) ${percentualAdquirenteDisplay}%`;
    let mensagem = `A somatória das frações está incorreta: `;

    if(isAtoAbertura){
      percentualTransmitente = 100;
      percentualTransmitenteDisplay = FrontBusiness.formatNumber(percentualTransmitente, 2, 10);

      mensagem += mensagemFracaoAdquirente;
    } else {
      mensagem += `${mensagemFracaoTransmitente} e ${mensagemFracaoAdquirente}`;
    }

    if(!configuracao.habilitarMargem && (percentualTransmitenteDisplay !== percentualAdquirenteDisplay)){
      erro = {mensagem: mensagem, cssClass: 'danger'};
    } else {
      mensagem = `${mensagem}. Diferença máxima permitida é de (${FrontBusiness.formatNumber(configuracao.margemTransferencia, 2, 10)}%)`
      percentualTransmitente = new Utils.bigDecimal.BigDecimal(percentualTransmitente);
      percentualAdquirente = new Utils.bigDecimal.BigDecimal(percentualAdquirente);

      let diferencaTransferencia = percentualTransmitente.subtract(percentualAdquirente.setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP)).setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP).doubleValue();
      if(diferencaTransferencia < 0){
        diferencaTransferencia = diferencaTransferencia * -1;
      }

      if (diferencaTransferencia > 0 && diferencaTransferencia <= configuracao.margemTransferencia) {
        erro = {mensagem: mensagem, cssClass: 'warning'};
      }else if(diferencaTransferencia > 0 && diferencaTransferencia > configuracao.margemTransferencia){
        erro = {mensagem: mensagem, cssClass: 'danger'};
      }
    }

    return erro;
  },

}

import Http from "@/commons/Http";
import utils from "@/commons/Utils";
import Utils from "@/commons/Utils.js";

const path = 'api/protocolo';

const tratarAto = (a) => {

  a.tipoServico = JSON.parse(a.tipoServico);
  if(a.tipoServico?.opcoes && typeof a.tipoServico?.opcoes === 'string'){
    a.tipoServico.opcoes = JSON.parse(a.tipoServico.opcoes || false)
  }
  a.indicadorPessoalVersao = a.indicadorPessoalVersao ? JSON.parse(a.indicadorPessoalVersao) : {};
  a.custas = JSON.parse(a.custas);
  a.checklist = JSON.parse(a.checklist);
  a.indices = JSON.parse(a.indices);
  a.exigencias = JSON.parse(a?.exigencias || false);
  a.dto = JSON.parse(a.dto) || {};

  a.indicador = JSON.parse(a.indicador);
  (a.indicador?.id) ? '' : a.indicador = undefined;

  a.registroAuxiliar = JSON.parse(a.registroAuxiliar);
  (a.registroAuxiliar?.id) ? '' : a.registroAuxiliar = undefined;

  a.indicadorReal = JSON.parse(a.indicadorReal);
  (a.indicadorReal?.id) ? '' : a.indicadorReal = undefined;

  a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;

  return a;

};

export default {

  baixarCertidao(protocolo, certidao, pdfa = false){
    return `${path}/${protocolo}/certidao/${certidao}/pdf${pdfa ? 'a' : ''}`;
  },

  baixarCertidoesProtocolo(protocolo){
    return `${path}/${protocolo}/certidao/pdf`;
  },

  async editarDataSelagem(dto, protocolo){
    return Http.post(`${path}/${protocolo}/certidao/atualizar-data-selagem`, dto);
  },

  tiposCertidao(dominio){
    let retorno = [
      {id: 'INTEIRO_TEOR', nome: 'Inteiro Teor'},
      {id: 'INTEIRO_TEOR_SEM_BUSCA', nome: 'Inteiro Teor - Sem Busca (4hrs)'},
    ];

    if(dominio === 'CERTIDAO_RCPJ'){
      retorno.push({id: 'INTEIRO_TEOR_PESSOA_JURIDICA',nome:'Inteiro Teor de Pessoa Jurídica'})
    }

    retorno.push(
      {id: 'RESUMIDA', nome: 'Resumida'},
      {id: 'POR_QUESITO', nome: 'Por Quesito'},
      {id: 'NEGATIVA', nome: 'Negativa'},
      {id: 'ONUS_ACOES', nome: 'Ônus & Ações'},
      {id: 'INFORMACAO_VERBAL', nome: 'Informação Verbal'},
      {id: 'COPIA_POR_PAGINA', nome: 'Cópia Reprográfica por Página'},
      {id: 'RELATORIO', nome: 'Certidão em Relatório'},
      {id: 'QUESITOS', nome: 'Certidão em Relatório com Quesitos'},
      {id: 'UNIDADES_VIRTUAIS', nome: 'Certidão de Atos Virtuais'},
      {id: 'SITUACAO_JURIDICA', nome: 'Certidão de Situação Jurídica'},
      {id: 'DOCUMENTO_ARQUIVADO', nome: 'Documento Arquivado na Serventia'}
    )

    return retorno;
  },

  nomearEntidade(certidao, code = true){
    return 'Certidão';
  },

  async listar(protocolo){
    return Http.get(`${path}/${protocolo}/certidao`).then(l => l.map(tratarAto));
  },

  async getById(protocolo, id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${protocolo}/certidao/${id}`).then(a => {

      (a.registroAuxiliar?.id) ? '' : a.registroAuxiliar = undefined;
      (a.indicadorReal?.id) ? '' : a.indicadorReal = undefined;
      (a.indicador?.id) ? '' : a.indicador = undefined;

      a.ficha = a.indicadorReal || a.registroAuxiliar || a.indicador;
      return a;

    });
  },

  async gerarMinuta(protocolo, id, modelo){
    if(modelo){
      return Http.get(`${path}/${protocolo}/certidao/${id}/${modelo}/minuta`);
    }else{
      return Http.get(`${path}/${protocolo}/certidao/${id}/minuta`);
    }
  },

  async getCusto(protocolo){
    return Http.get(`${path}/${protocolo}/certidao/custo`);
  },

  async calcularCustoProtocolo(protocolo){
    return Http.post(`${path}/${protocolo}/certidao/calcular-custo-protocolo`);
  },

  async verificarCertidoesSeladas(protocolo, dto){
    return Http.post(`${path}/${protocolo}/certidao/verificar-certidoes-seladas`, dto);
  },

  async verificarCertidoesAssinadas(protocolo, dto){
    return Http.post(`${path}/${protocolo}/certidao/verificar-certidoes-assinadas`, dto);
  },

  async delete(protocolo, id){
    return Http.delete(`${path}/${protocolo}/certidao/${id}`);
  },

  async save(dto, protocolo, id = ''){
    return Http.post(`${path}/${protocolo}/certidao/${id}`, dto);
  },

  async inserirCertidaoVinculo(dtoEnvolvidos, protocolo){
    return Http.post(`${path}/${protocolo}/certidao/inserir-certidao-vinculo`, dtoEnvolvidos);
  },

  async getCertidaoVinculo(idCertidao, protocolo) {
    let params = new URLSearchParams();
    params.set("idCertidao",idCertidao.toString());
    return Http.get(`${path}/${protocolo}/certidao/get-certidao-vinculo`,{params});
  },

  async inserirAnexoCertidao(idProtocolo, idCertidao, dto){
    return Http.post(`${path}/${idProtocolo}/certidao/inserir-anexo-certidao/${idCertidao}`, dto);
  },

  async getAnexoCertidao(idProtocolo, idCertidao){
    let params = new URLSearchParams();
    params.set("idCertidao",idCertidao.toString());
    return Http.get(`${path}/${idProtocolo}/certidao/get-anexo-certidao`, {params});
  },

  async inserirLote(protocolo, dto) {
    return Http.post(`${path}/${protocolo}/certidao/lote`, dto);
  },

  async inserirQuantidade(protocolo, dto, quantidade) {
    return Http.post(`${path}/${protocolo}/certidao/lote/${quantidade}`, dto);
  },

  async regerarChecklist(protocolo){
    return Http.post(`${path}/${protocolo}/certidao/checklist`);
  },

  async ordenarCertidoes(protocolo, certidoes) {
    return Http.post(`${path}/${protocolo}/certidao/ordenar`, certidoes);
  },

  async importarVisualizacoes(protocolo, tipo, file) {
    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/${protocolo}/certidao/importar-visualizacoes/${tipo}`, formData, config);
  },

  async importarPesquisaQualificadaOnr(protocolo,file,idTipoCertidao){

    const formData = new FormData();
    formData.append("file", file);
    formData.append("idTipoCertidao", idTipoCertidao);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/${protocolo}/certidao/importar-pesquisa-qualificada-onr`, formData, config);
  },

  isCertidaoFicha(livro) {
    if (utils.isEmptyNullOrUndefined(livro)) {
      return false;
    }
    if (typeof livro === "string") {
      return livro !== 'NAO_VINCULAR';
    }
    if (typeof livro === "object") {
      return livro.length > 0 && !livro.includes('NAO_VINCULAR');
    }
    return false;
  }

}

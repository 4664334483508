<template>
  <div id="registros-posteriores-indicador">

    <div v-if="isMatricula">
      <div class="mb-2" v-if="registros.length > 0">
        <template v-for="(item, key) in resumo">
          <span class="badge badge-outline-secondary" v-if="item.qtd > 0" :key="item+'_'+key">
            {{item.descricao}}: {{item.qtd}}
          </span>
        </template>
      </div>
    </div>

    <card v-if="isMatricula" titulo="Registros Posteriores">

      <template #acoes>
        <a class="icon mx-1 float-right" title="Baixar Modelo" href="/app/v1/xls/modelo_unidades_virtuais_asgard_vhl.xlsx" target="_blank" download>
          <i class="fal fa-file-excel"></i>
        </a>

        <v-upload v-model="file" :upload="importarUnidades" extensions="xls,xlsx" class="icon mx-1 float-right">
          IMPORTAR
        </v-upload>

        <a class="icon mx-1 float-right" href title="Importação customizada" @click.prevent="abrirImportacao(ficha)">
          IMPORTAÇÃO CUSTOMIZADA
        </a>

        <a class="icon mx-1 float-right" href title="Adicionar Unidade" @click.prevent="adicionar(ficha)">
          ADICIONAR
        </a>
      </template>


      <div v-if="!registros.length && !loading" class="alert alert-outline-info">
        Não há registros para exibir
      </div>
    </card>

    <div v-if="isRegistroTDPJ">
      <card v-if="registrosAbertos.length > 0" titulo="Abertos" >
        <card-table>
          <thead>
          <header-cell v-for="(column, i) in columns" :column="column" :key="i" :loading="loading"
                       :direction="getDirection(column)" @change="toggleSort" />
          </thead>

          <tbody>

          <tr v-for="registro in registrosAbertos" :key="registro.id">
            <td>
              {{registro.averbacao}}
            </td>
            <td class="icon pointer" @click.prevent="detalhes(registro)">
              {{nomearFicha({ livro : registro.livro, codigo : registro.codigo, status : registro.status }, true, true)}}
            </td>
            <td>{{tipoRegistro(registro)}}</td>
            <td>{{registro.livroLetra ? registro.livroLetra : '-'}}</td>
          </tr>

          </tbody>
        </card-table>
      </card>

      <card v-if="registrosPendentes.length > 0" titulo="Pendentes" >
        <card-table>
          <thead>
          <header-cell v-for="(column, i) in columns" :column="column" :key="i" :loading="loading"
                       :direction="getDirection(column)" @change="toggleSort" />
          </thead>

          <tbody>

          <tr v-for="registro in registrosPendentes" :key="registro.id">
            <td>
              {{registro.averbacao}}
            </td>
            <td class="icon pointer" @click.prevent="detalhes(registro)">
              {{nomearFicha({ livro : registro.livro, codigo : registro.codigo, status : registro.status }, true, true)}}
            </td>
            <td>{{tipoRegistro(registro)}}</td>
            <td>{{registro.livroLetra ? registro.livroLetra : '-'}}</td>
          </tr>

          </tbody>
        </card-table>
      </card>


      <div v-if="!registros.length && !loading" class="alert alert-outline-info">
        Não há registros para exibir
      </div>
    </div>

    <div v-else>
      <card titulo="Abertos" v-if="registrosAbertos.length > 0">
        <template #acoes>
          <a class="icon mx-1 float-right" href title="Exportar XLS" @click.prevent="exportarXls()">
            EXPORTAR XLS
          </a>
        </template>

        <card-table class="table-borderless mb-2">
          <thead>
          <header-cell v-for="(column, i) in columns" :column="column" :key="i" :loading="loading"
                       :direction="getDirection(column)" @change="toggleSort" />
          </thead>
          <draggable v-model="registrosAbertos" @end="ordenar" ghost-class="ghost"
                     handle=".move" tag="tbody" class="table-unidades">
            <tr v-for="unidade in registrosAbertos" :key="unidade.id">

              <td>
              <span class="icon move hidden-column" style="width: 30px">
                <i class="fas fa-arrows-alt"></i>
              </span>
                {{nomearFicha({ livro : unidade.livro, codigo : unidade.codigo, status : unidade.status, livroLetra : unidade.livroLetra }, true, true)}}</td>

              <td>
                {{tipoImovel(unidade.tipoImovel)}}{{unidade.tipoImovel == 'OUTROS' && unidade.descricaoTipoImovelOutros ? ' - ' + unidade.descricaoTipoImovelOutros : ''}}
              </td>
              <td>{{unidade.unidade}}</td>

              <td class="text-center">{{unidade.bloco}}</td>
              <td class="text-center">{{unidade.lote}}</td>
              <td class="text-center">{{unidade.quadra}}</td>
              <td class="small-2" v-html="unidade.referenciaTxt"></td>

              <td class="text-left">

                <a title="Visualizar Referências" class="icon ml-2" @click.prevent="gerenciarReferencias(unidade)">
                  <i class="fal fa-link"></i>
                </a>

                <a title="Clonar" class="icon ml-2" @click.prevent="adicionar(unidade)">
                  <i class="fal fa-copy"></i>
                </a>

                <a title="Detalhes da Unidade" class="icon ml-2" @click.prevent="detalhes(unidade)">
                  <i class="fal fa-home"></i>
                </a>

                <template v-if="unidade.status === 'PENDENTE'">

                  <a title="Editar" class="icon ml-2" @click.prevent="editarIndicador(unidade)">
                    <i class="fal fa-edit"></i>
                  </a>

                  <v-button v-if="!unidade.possuiAtos" :run="remover" :params="[unidade]" :button="false" :popover="true" class="icon ml-2" position="dropleft">
                    <i class="fal fa-times" title="Excluir"></i>
                  </v-button>

                </template>

              </td>

            </tr>
          </draggable>
        </card-table>
      </card>
      <card titulo="Pendentes" v-if="registrosPendentes.length > 0">
        <template #acoes>
          <v-button v-if="registrosPendentes.length > 1" :run="apagarLote" :button="false" class="icon ml-2" position="dropleft" style="float: right;">
            EXCLUIR EM LOTE
          </v-button>
        </template>
        <template v-if="unidade.possuiVinculos && unidade.possuiVinculos.length">
          <p class="alert alert-danger mb-3 pointer">
            A {{nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo, status : unidade.status })}} ainda possui vínculos com:
            <span v-for="(documento, d) in unidade.possuiVinculos" :key="documento.id"> {{nomearProtocolo(documento.dominio, documento.tipoProtocolo, documento.codigo)}}{{d + 1 === unidade.possuiVinculos.length ? '' : ', '}}</span>
          </p>
        </template>
        <card-table class="table-borderless mb-2" >
          <thead>
          <header-cell v-for="(column, i) in columns" :column="column" :key="i" :loading="loading"
                       :direction="getDirection(column)" @change="toggleSort" />
          </thead>
          <draggable v-model="registrosPendentes" @end="ordenar" ghost-class="ghost"
                     handle=".move" tag="tbody" class="table-unidades">
            <tr v-for="unidade in registrosPendentes" :key="unidade.id">

              <td>
              <span class="icon move hidden-column" style="width: 30px">
                <i class="fas fa-arrows-alt"></i>
              </span>
                {{nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo, status : unidade.status }, true, true)}}
              </td>

              <td>
                {{tipoImovel(unidade.tipoImovel)}} {{unidade.tipoImovel === 'OUTROS' && unidade.descricaoTipoImovelOutros ? ' - ' + unidade.descricaoTipoImovelOutros : ''}}
              </td>
              <td>{{unidade.unidade}}</td>

              <td class="text-center">{{unidade.bloco}}</td>
              <td class="text-center">{{unidade.lote}}</td>
              <td class="text-center">{{unidade.quadra}}</td>
              <td class="small-2" v-html="unidade.referenciaTxt"></td>

              <td class="text-left">

                <a title="Visualizar Referências" class="icon ml-2" @click.prevent="gerenciarReferencias(unidade)">
                  <i class="fal fa-link"></i>
                </a>

                <a title="Clonar" class="icon ml-2" @click.prevent="adicionar(unidade)">
                  <i class="fal fa-copy"></i>
                </a>

                <a title="Detalhes da Unidade" class="icon ml-2" @click.prevent="detalhes(unidade)">
                  <i class="fal fa-home"></i>
                </a>

                <template v-if="unidade.status === 'PENDENTE'">

                  <a title="Editar" class="icon ml-2" @click.prevent="editarIndicador(unidade)">
                    <i class="fal fa-edit"></i>
                  </a>

                  <v-button v-if="!unidade.possuiAtos" :run="remover" :params="[unidade]" :button="false" :popover="true" class="icon ml-2" position="dropleft">
                    <i class="fal fa-times" title="Excluir"></i>
                  </v-button>

                </template>

              </td>

            </tr>
          </draggable>
        </card-table>
      </card>
    </div>

    <small-loading v-if="loading"></small-loading>

  </div>
</template>

<script>

import ListaBusiness from "@/business/crud/ListaBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness";
import RegistroPJBusiness from "@/business/indicadores/RegistroPJBusiness";
import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness";
import RelatorioBusiness from "@/business/RelatorioBusiness";
import Utils from "@/commons/Utils";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
import ImportacaoUnidades from "@/components/IndicadorReal/Detalhes/ImportacaoUnidades/ImportacaoUnidadesV2.vue";
import ModalApagarUnidades from "@/components/IndicadorReal/Detalhes/ModalApagarUnidades.vue";
import FormIndicadorReal from "@/components/IndicadorReal/Form/FormIndicadorReal.vue";
import ModalReferenciaImovel from "@/components/IndicadorReal/ModalReferenciaImovel.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import DetalhesRegistroPJ from "@/components/RegistroPJ/Detalhes/DetalhesRegistroPJ.vue";
import DetalhesRegistroTD from "@/components/RegistroTitulo/Detalhes/DetalhesRegistroTD.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import HeaderCell from "@/components/Table/HeaderCell.vue";
import draggable from "vuedraggable";

export default {
  name: 'RegistrosPosteriores',
  components: {SmallLoading, HeaderCell, CardTable, Card, draggable},
  props:{
    ficha: Object
  },

  data(){
    return {
      loading: true,
      registros: [],
      livro: null,
      resumo: [],
      file: [],
      unidade: {
        possuiVinculos: []
      },
      tipos: IndicadorRealBusiness.getTiposImovel(),
      status: IndicadorRealBusiness.getStatusImovel(),
      serventiaUnidade: '',
      referencia: {}
    }
  },

  async mounted() {
    await this.process();

    if(this.isMatricula){
      await this.afterProcess()
    }
    this.loading = false
  },

  methods: {
    nomearFicha : FrontBusiness.nomearFicha,

    async process(){

      if(this.isMatricula){
        if(this.ficha.tipo){
          this.registros = await IndicadorRealExternoBusiness.listarRegistros(this.ficha.id);
        } else {
          this.registros = await IndicadorRealBusiness.listarUnidades(this.ficha.id) || [];
        }

        this.resumo = [];
        this.tipos.forEach((t) => this.resumo.push({id: t.id, descricao: t.nome, qtd: 0}));
        this.status.forEach((s) => this.resumo.push({id: s.id, descricao: s.nome, qtd: 0}));
        let config = this.$root.config;
        let serventia = config?.listaRegistroAnterior?.id ? await ListaBusiness.getById(config?.listaRegistroAnterior?.id) : {};
        this.serventiaUnidade = serventia?.lista?.[0];

        this.registros.forEach((u) => {
          this.resumo.forEach((a, i) => {
            if (this.resumo[i].id === u.tipoImovel || this.resumo[i].id === u.status) {
              this.resumo[i].qtd++
            }
          });
        });

      } else {
        this.$set(this, 'registros', await IndicadorAbstractService.getRegistrosPosteriores(this.ficha.id, this.sortBy, this.sortDir));
      }

      this.$set(this, 'loading', false);
    },

    async afterProcess(){
      this.process = await Utils.debounce(async () => {
        this.$set(this, 'loading', true);
        this.$set(this, 'registros', []);
        if(this.ficha.tipo){
          this.$set(this, 'registros', await IndicadorRealExternoBusiness.listarRegistros(this.ficha.id, this.sortBy, this.sortDir));
        } else {
          this.$set(this, 'registros', await IndicadorRealBusiness.listarUnidades(this.ficha.id, this.sortBy, this.sortDir));
        }
        this.ordenar();
        this.$set(this, 'loading', false);
      }, 150);
    },

    getDirection(column) {
      return column.field === this.sortBy ? this.sortDir : null;
    },

    async toggleSort(column) {
      if (!this.loading) {
        if (column.field === this.sortBy) {
          this.sortDir = !this.sortDir || this.sortDir === 'desc' ? 'asc' : 'desc';
        } else {
          this.sortBy = column.field;
          this.sortDir = 'asc';
        }
        await this.process();
      }
    },

    detalhes(registro){
      switch (registro.livro) {
        case 'REGISTRO_TD':
          FrontBusiness.openModal(DetalhesRegistroTD, {id: registro.id});
          break
        case 'REGISTRO_PJ':
          FrontBusiness.openModal(DetalhesRegistroPJ, {id: registro.id});
          break
        default:
          FrontBusiness.openModal(DetalhesIndicadorReal, {id: registro.id});
      }
    },

    async gerenciarReferencias(unidade){
      let r = await FrontBusiness.openModal(ModalReferenciaImovel, {ficha: unidade, matriculaMae: this.ficha});
      if(r){
        await this.process();
        await this.afterProcess();
      }
    },

    async importarUnidades() {
      if (this.file[0]) {
        this.loading = true;
        let indicador = await IndicadorRealBusiness.saveImportacao(this.ficha.id, this.file[0]?.file).catch(this.showError);
        if (indicador) {
          this.file = [];
          this.process();
          this.loading = false;
        }
      }
    },
    async adicionar(registro) {
      this.$set(this, 'loading', true);
      let indicador;
      let fichaId;
      let fichaExternaId;
      let origem = {
        registro: this.ficha.codigo,
        serventia: this.serventiaUnidade || ''
      };

      if(registro.tipo){
        fichaExternaId = registro.id;
        origem.origemExternaId = registro.id;
        origem.tipo = 'MATRICULA_EXTERNA';
        origem.tipoIndicadorRealExterno = registro.tipo;
        indicador = await IndicadorRealExternoBusiness.getById(registro.id);
      } else {
        fichaId = registro.id;
        origem.origemId = registro.id;
        origem.tipo = (this.ficha.status === 'PENDENTE' ? 'PRE_' : '')+'MATRICULA';
        indicador = await IndicadorRealBusiness.getById(registro.id);
      }
      origem.interno = origem.tipo !== 'MATRICULA_EXTERNA';

      await FrontBusiness.openModal(FormIndicadorReal, {
        livro: 'MATRICULA', origem: fichaId, origemExterna: fichaExternaId, clone: {
          ...indicador,
          id: null,
          proprietarios: [],
          status: 'PENDENTE',
          ultimoAto: 0,
          origens: [origem]
        }
      });

      this.$set(this, 'loading', false);

      this.process();
    },

    async editarIndicador(ficha) {
      await FrontBusiness.openModal(FormIndicadorReal, {id: ficha.id, livro: ficha.livro});
      this.process();
    },

    async remover(unidade) {
      let retorno = await IndicadorRealBusiness.removerUnidade(unidade.id).catch(async e => {
        this.$set(this, 'unidade', {...unidade, ...{possuiVinculos: await IndicadorRealBusiness.buscarProtocolos(unidade?.id)}});
        FrontBusiness.showError('', e.message)
      });
      if (retorno) {
        this.$set(this, 'unidade', {possuiVinculos: []});
        FrontBusiness.showSuccess('', `Unidade excluída com sucesso`);
      }
      await this.process();
    },

    async apagarLote() {
      let retorno = await FrontBusiness.openModal(ModalApagarUnidades, {
        unidadesPendentes: this.registrosPendentes
      });
      await this.process();
      await this.afterLoad();
    },

    ordenar() {
      this.$nextTick(() => {
        const ordens = {};
        for (let idx in this.registros) {
          if (this.registros?.[idx]) {
            const ordem = 1000 + parseInt(idx);
            this.registros[idx].ordem = ordem;
            let id = this.registros[idx].id;
            ordens[id] = ordem;
          }
        }

        if (Object.keys(ordens).length) {
          if(this.ficha.tipo){
            IndicadorRealExternoBusiness.ordenarRegistros(this.ficha.id, ordens).catch(this.showError);
          } else {
            IndicadorRealBusiness.ordenarUnidades(this.ficha.id, ordens).catch(this.showError);
          }
        }
      });
    },

    async abrirImportacao(ficha) {
      let response = await FrontBusiness.openModal(ImportacaoUnidades, {ficha: ficha});
      if(response){
        await this.process();
        await this.afterProcess();
      }
    },

    async exportarXls() {
      let business = this.ficha.tipo ? IndicadorRealExternoBusiness : IndicadorRealBusiness;
      await RelatorioBusiness.downloadExcel(business.exportarRegistrosPosteriores(this.ficha.id, this.sortBy, this.sortDir), 'relatorio.xls');
    },

    tipoRegistro(registro){
      if(registro.livro === 'REGISTRO_PJ'){
        return RegistroPJBusiness.getTipos().find(t => t.id === registro.tipoRegistro)?.nome;
      } else {
        return RegistroTDBusiness.getTipos().find(t => t.id === registro.tipoRegistro)?.nome;
      }
    },

    tipoImovel(tipo) {
      return FrontBusiness.getLabel(tipo, IndicadorRealBusiness.getTiposImovel());
    },

    showError(e) {
      FrontBusiness.showError('', e?.message || 'Ocorreu um erro ao tentar adicionar a(s) unidade(s)');
    },
  },

  computed: {

    columns() {
      let columns = [];

      if(this.isMatricula){
        columns.push({label: 'Ficha', field: 'codigo', headerClass: 'column__codigo', name: 'codigo', minWidth: '140px'});
        columns.push({label: 'Tipo', field: 'tipo_imovel', headerClass: 'column__tipo', name: 'tipo_imovel', minWidth: '140px'});
        columns.push({label: 'Unidade', field: 'unidade', headerClass: 'column__unidade', name: 'unidade', minWidth: '85px'});
        columns.push({label: 'Bloco', field: 'bloco', headerClass: 'column__bloco', name: 'bloco', minWidth: '85px'});
        columns.push({label: 'Lote', field: 'lote', headerClass: 'column__lote', name: 'lote', minWidth: '85px'});
        columns.push({label: 'Quadra', field: 'quadra', headerClass: 'column__quadra', name: 'quadra', minWidth: '85px'});
        columns.push({label: 'Referências', field: 'referencias', headerClass: 'column__referencias', name: 'referencias', minWidth: '85px', sortable: false});
        columns.push({label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id', minWidth: '110px'});
      } else {
        columns.push({label: 'Averbacao', field: 'averbacao', headerClass: 'column__averbacao', name: 'averbacao', minWidth: '40px'});
        columns.push({label: 'Registro', field: 'codigo', headerClass: 'column__id', name: 'id', minWidth: '140px'});
        columns.push({label: 'Tipo de Registro', field: "tipo_registro", headerClass: 'column__tipo_registro', name: 'tipoRegsitro', minWidth: '85px'});
        columns.push({label: 'Livro', field: 'livro_letra', headerClass: 'column__livro_letra', name: 'livroLetra', minWidth: '85px'});
      }
      return columns;
    },
    registrosAbertos() {
      return (this.registros || []).filter(r => r.status !== 'PENDENTE').map(e => {
        e.referencias = JSON.parse(e.referencias || '[]');
        e.referenciaTxt = e.referencias.map(r => `Ato ${r.ato} - ${FrontBusiness.getLabel(r.tipo, IndicadorRealBusiness.getTiposReferencia())}`).join('<br />');
        this.referencia = e;
        return e;
      });
    },
    registrosPendentes() {
      return (this.registros || []).filter(r => r.status === 'PENDENTE').map(e => {
        e.referencias = JSON.parse(e.referencias || '[]');
        e.referenciaTxt = e.referencias.map(r => `Ato ${r.ato} - ${FrontBusiness.getLabel(r.tipo, IndicadorRealBusiness.getTiposReferencia())}`).join('<br />');
        this.referencia = e;
        return e;
      });
    },
    isMatricula(){
      return !['REGISTRO_TD', 'REGISTRO_PJ'].includes(this.ficha.livro)
    },
    isRegistroTDPJ(){
      return ['REGISTRO_TD', 'REGISTRO_PJ'].includes(this.ficha.livro)
    }
  }
}
</script>

<style scoped lang="scss">

.td-toggle {
  text-align: right;
  vertical-align: top;
}

.table-registros {
  .hidden-column {
    display: none;
  }

  &:hover {
    .hidden-column {
      display: initial;
    }
  }
}

#div-registro-posteriores-indicador table thead th {
  line-height: 11px !important;
}
</style>


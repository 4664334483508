import getVar from "@/business/livros/copiladores/getVar.js";
import TextParser from "@/business/livros/TextParser.js";
import Utils from "@/commons/Utils.js";
import moment from "moment";

const testarCondicao = function(params, dicionario, debug, v2 = false ){

  const testar = (params, dicionario, debug) => {

    try {

      const dicionarioTranslate = function(valor, proxy = false){
        return getVar(valor, dicionario, proxy, true);
      };

      let parsed = {};

      Object.keys(params).forEach(k => {
        if(params[k]){
          parsed[k] = getVar(params[k], dicionario, true, true);
        }
      });

      let condicao  = dicionarioTranslate(params.se);

      // verificar o tipo da condicao
      const isArray = Array.isArray(condicao);
      const isString = typeof condicao == "string";
      const isNumber = !isNaN(parseFloat(condicao));

      // if(params.se == 'parametro.classificacao'){
      //   let val = dicionarioTranslate(params.diferente, true);
      //   let se = dicionarioTranslate(params.se, true);
      //   console.debug(isNumber, parsed, params, val, se)
      // }

      //'valor não encontrado: ' + condicao;
      if(parsed.se == params.se){
        if(v2){
          return params.diferente != undefined;
        }else{
          let val = dicionarioTranslate(params.diferente, true);
          return params.diferente != undefined && val == 'sim';
        }
      }

      if (params.comeca != undefined) {
        let stringLimpa = TextParser.stripHtml((parsed.se + '')).toLowerCase().trim();
        return parsed.comeca?.toLowerCase().split(/\s*\|\s*/).findIndex(e => stringLimpa.startsWith((e + '').trim())) > -1;
      }

      /** TESTA DATAS **************************************************/
      if(params.data != undefined){

        try{

          let valor = moment(condicao);

          if (params.maior != undefined) {
            let teste = moment(dicionarioTranslate(params.maior));
            return valor.isAfter(teste);
          }

          if (params.menor != undefined) {
            let teste = moment(dicionarioTranslate(params.menor));
            return valor.isBefore(teste);
          }

        }catch (e) {
          console.error('Erro na conversão de data:', e);
        }

      }

      /** TESTA VETOR **************************************************/
      if (params.contem != undefined && isArray) {
        return condicao.includes(dicionarioTranslate(params.contem));
      }

      /** TESTA VALORES NUMERICOS **************************************************/
      if(isArray || isNumber){

        let valor = parseFloat(Array.isArray(condicao) ? condicao.length : condicao );

        if (params.maior != undefined) {
          return valor > parseFloat(dicionarioTranslate(params.maior) || 0);
        }

        if (params.menor != undefined) {
          return valor < parseFloat(dicionarioTranslate(params.menor) || 0);
        }

        if (params.igual != undefined) {
          return parseFloat(dicionarioTranslate(params.igual) || 0) == valor;
        }

        if (params.diferente != undefined) {
          return parseFloat(dicionarioTranslate(params.diferente) || 0) != valor;
        }

      }

      /** TESTA STRING **************************************************/
      if (params.igual != undefined) {
        return parsed.igual?.toLowerCase().split(/\s*\|\s*/).includes(parsed.se.toLowerCase());
      }

      if (params.diferente != undefined) {
        return !parsed.diferente?.toLowerCase().split(/\s*\|\s*/).includes(parsed.se.toLowerCase());
      }

      if (params.contem != undefined && isString) {
        let valores = parsed.contem?.toLowerCase().split(/\s*\|\s*/);
        return valores.findIndex(e => condicao.toLowerCase().includes(e.toLowerCase())) > -1;
      }

      /** TESTA BOOLEANO **************************************************/

      let c = (parsed.se + '').toLowerCase();

      if(debug) {
        debug.test = c;
      }

      return c == 'sim' || (c != 'não' && c != 'nao' && !!parsed.se);

    } catch (e) {
      console.error(e);
      return false;
    }

  };

  let resultado = testar(params, dicionario, debug);

  if(debug) {
    debug.resultado = params.negativa != undefined || params.negativo != undefined ? !resultado : resultado;
  }

  return params.negativa != undefined ? !resultado : resultado;

};

export default testarCondicao;

<template>
    <card-table  id="confrontacao-indicador-real" class="table-borderless">

      <thead>
      <tr>
        <th class="text-muted tamanho" v-if="ordenar"></th>
        <th class="text-muted">Confrontação </th>
        <th class="text-muted">Metragem </th>
        <th class="text-muted">Confrontante </th>
        <th class="text-muted" width="30px" style="vertical-align: middle; text-align: center" v-if="!visualizar">
          <a class="icon" href="" @click.prevent="adicionarConfrontacao()">
            <i class="fa fa-plus-square"></i>
          </a>
        </th>
      </tr>
      </thead>
      <draggable v-model="confrontacoes" @end="$emit('ordenar', 'confrontacoes', confrontacoes)" ghost-class="ghost"
                 handle=".move" tag="tbody" class="table-areas">
        <tr v-for="(confrontacao, i) in confrontacoes" :key="'item' + i">
          <td class="text-center icon move" v-if="ordenar">
            <span class="icon move">
              <i class="fas fa-arrows-alt"></i>
            </span>
          </td>
          <td class="form-group">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="confrontacao.confrontacao" type="text" />
          </td>

          <td class="form-group">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="confrontacao.metragem" type="text" />
          </td>

          <td class="form-group">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="confrontacao.confrontante" type="text" />
          </td>

          <td class="text-center" v-if="!visualizar">
            <a class="icon" href="" @click.prevent="removerConfrotacoes(i)">
              <i class="fa fa-times"></i>
            </a>
          </td>
        </tr>
      </draggable>
      <tr v-if="confrontacoes.length === 0">
        <td colspan="100%">
          <div class="alert alert-warning alert-sm mb-0">
            Nenhuma confrontação cadastrada
          </div>
        </td>
      </tr>

      <tfoot></tfoot>

    </card-table>
</template>

<script>
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import draggable from "vuedraggable";

  export default {
      components: {CardTable, draggable},
      props : {
        confrontacoes : {type : Array, required : true},
        visualizar: {type : Boolean, default : false},
        edicao: {type : Boolean, default : true},
        ordenar: {type : Boolean, default : false}
      },

      name: "ConfrontacaoIndicadorReal",
      data(){
        return {
        }
      },

      methods:{

        removerConfrotacoes(index) {
          this.$nextTick(() => {
            if (this.confrontacoes && this.confrontacoes.length) {
              this.confrontacoes.splice(index, 1);
            }
          });
        },

        adicionarConfrontacao(){
          this.$nextTick(() => {
            this.confrontacoes.push({confrontacao: '', metragem: '', confrontante: ''});
          });
        }

      }

    }
</script>

<style scoped lang=scss>
  .tamanho {
    width: 5%;
    max-width: 36px;
  }
</style>

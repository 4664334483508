<template>

  <card titulo="Cancelar Restrições" v-if="temRestricoes && restricoes.length">

    <table class="table card-table table-striped table-vcenter">
      <thead>
        <tr>
          <th width="20px"></th>
          <th class="pl-0">Ato</th>
          <th>Pessoa</th>
          <th>Restrições</th>
        </tr>
      </thead>
      <tbody>

        <template v-for="(restricao, index) in restricoes">
          <tr :key="'restricao'+index">

            <td class="text-left">
              <a class="icon" href="" @click.prevent="selecionar(restricao.id)">
                <i class="far"
                   :class="{ 'fa-check-circle' : selecaoCheckbox(restricao.id), 'fa-circle' : !selecaoCheckbox(restricao.id) }">
                </i>
              </a>
            </td>

            <td class="pl-0" style="width: 50%;">
              {{descricaoAtoRestricao(restricao.descricaoRestricao)}}
            </td>

            <td>
              <indicador-pessoal-nome :pessoa="getPessoa(restricao)"/>
            </td>

            <td>
              {{descricaoRestricao(restricao.restricao)}}
            </td>

          </tr>
        </template>
      </tbody>
    </table>
  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Card from "@/components/Layout/components/Card.vue";
import business from "@/views/Administracao/modulos/tipo-servico/business";

export default {

    name: "CancelarRestricoes",
    components: {Card},
    props: ['ato', 'protocolo', 'ficha', 'edicao'],

    beforeMount() {
      this.ato.dto.restricoesCanceladas = this.ato.dto.restricoesCanceladas || [];

    },

    mounted() {

      this.ato.dto.restricoesCanceladas = this.ato.dto.restricoesCanceladas.filter(r => {
        return this.ato?.dto?.restricoes?.find(restricao => restricao.id == r);
      });

    },

    computed: {
      temRestricoes() {
        return this.ato?.dto?.restricoes?.length;
      },

      restricoes() {
        return this.ato?.dto?.restricoes.filter(r => this.ato.tipoServico?.opcoes?.cancelarRestricao.includes(r.restricao));
      }
    },

    methods: {
      selecionar(id) {
        if(this.edicao){
          let restricoes = this.ato.dto.restricoes;
          this.$set(this.ato.dto, 'restricoes', []);

          if (!this.ato.dto.restricoesCanceladas.includes(id)) {
            this.ato.dto.restricoesCanceladas.push(id);
          } else {
            this.ato.dto.restricoesCanceladas = this.ato.dto.restricoesCanceladas.filter(p => p != id);
          }

          this.$set(this.ato.dto, 'restricoes', restricoes);
          this.$forceUpdate();
        }
      },
      selecaoCheckbox(id) {
        return this.ato.dto.restricoesCanceladas.includes(id);
      },

      descricaoRestricao(restricao) {
        return FrontBusiness.getLabel(restricao, business.restricoes());
      },

      descricaoAtoRestricao(restricao) {
        return AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(restricao));
      },

      getPessoa(restricao){
        const pessoa = {
          indicadorPessoalId: restricao.indicadorPessoalId,
          indicadorPessoalVersaoId: restricao.indicadorPessoalVersaoId,
          documento: restricao.documento,
          nome: restricao.nome,
        };
        return pessoa;
      }

    },


  }
</script>

<template>
  <div id="aba-dados-principais">
    <card v-if="loading">
      <small-loading :loading="true"></small-loading>
    </card>

    <template v-else>
      <card titulo="Registro de Título">
        <card-content>

          <p>
            <span class="font-weight-500">Situação: </span><span>{{status.find(s => s.id === ficha.status).nome}}</span>
          </p>

          <p>
            <span class="font-weight-500">Tipo de Registro: </span><span>{{tipoRegistro}}</span>
          </p>

          <p v-if="ficha.origem != null">
            <span class="font-weight-500">Registro Anterior: </span><span class="icon" @click.prevent="detalhesRegistro(ficha.origem.id)">{{nomearFicha(ficha.origem)}} - {{tipoRegistroAnterior}}</span>
          </p>

          <p v-if="baseCalculo.valorAvaliacao">
            <span class="font-weight-500">Valor da Avaliação Fiscal: </span><span>{{baseCalculo.valorAvaliacao | moeda}}</span>
          </p>

          <p v-if="ficha.cadastro">
            <span class="font-weight-500">Cadastro: </span><span>{{ficha.cadastro | datahora}}</span>
          </p>

          <p v-if="ficha.abertura">
            <span class="font-weight-500">Abertura: </span><span>{{ficha.abertura | datahora}}</span>
          </p>

          <p v-if="ficha.encerramento && (ficha.status === 'ENCERRADA' || ficha.status === 'CANCELADA')">
            <span v-if="ficha.status === 'ENCERRADA'" class="font-weight-500">Encerramento: </span><span v-if="ficha.status === 'CANCELADA'" class="font-weight-500">Cancelamento: </span><span>{{ficha.encerramento | datahora}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Identificação" v-if="ficha.livroLetra">
        <card-content>
          <p v-if="ficha.livroLetra">
            <span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Observações" v-if="ficha.observacoes">
        <card-content>
          <div v-html="ficha.observacoes"></div>
        </card-content>
      </card>

      <card titulo="Documentos" v-if="documentos && documentos.length">
        <table class="table table-striped card-table table-vcenter">

          <thead>
          <tr>
            <th class="pl-2">Tipo</th>
            <th>Data</th>
            <th class="text-right pr-2">Número</th>
            <th class="text-right pr-2">Observação</th>
            <th class="text-right pr-2">Quantidade de folhas</th>
            <th class="text-right pr-2">Livro</th>
            <th class="text-right pr-2">Início</th>
            <th class="text-right pr-2">Fim</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(documento, i) in documentos" :key="i">
            <td class="pl-2">{{documento.nome}}</td>
            <td class="nowrap">{{documento.dataDocumento | data }}</td>
            <td class="nowrap text-right">{{documento.numeroDocumento || '-'}}</td>
            <td class="text-right">{{documento.observacao || '-'}}</td>
            <td class="text-right">{{documento.quantidadeFolhas || '-'}}</td>
            <td class="text-right">{{documento.livro || '-'}}</td>
            <td class="text-right">{{documento.inicio || '-'}}</td>
            <td class="text-right">{{documento.final || '-'}}</td>
          </tr>
          </tbody>

        </table>
      </card>

      <card titulo="Envolvidos" v-if="envolvidos && envolvidos.length">

        <table class="table table-striped card-table table-vcenter">

          <thead>
          <tr>
            <th class="pl-2">PESSOA</th>
            <th>DOCUMENTO</th>
            <th class="text-right pr-2">PAPEL</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(envolvido, index) in envolvidos" :key="index+envolvido.indicadorPessoalVersaoId">
            <td class="pl-2">
              <a href class="mb-1 pointer" @click.prevent="detalhes(envolvido.indicadorPessoalVersaoId)">{{envolvido.nome}}</a>
            </td>
            <td class="nowrap">{{envolvido.documento}}</td>
            <td class="nowrap text-right pr-2">{{nomePapel(envolvido.papel)}}</td>
          </tr>
          </tbody>

        </table>

      </card>

    </template>

  </div>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroPJBusiness from "@/business/indicadores/RegistroPJBusiness.js";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import SmallLoading from "@/components/SmallLoading";
  import DetalhesRegistroPJ from "@/components/RegistroPJ/Detalhes/DetalhesRegistroPJ.vue";
  import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business.js";
  import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";

  export default {

    name: "Dados",
    components: {CardContent, Card, SmallLoading},
    props: {
      id: String,
    },

    data() {
      return {
        envolvidos : [],
        ficha : [],
        documentos: [],
        baseCalculo: [],
        loading : true,
        status: RegistroPJBusiness.getStatusImovel(),
        tipoRegistroAnterior: ''
      }
    },

    async mounted() {
      this.loading = true;

      await Promise.all([
        RegistroPJBusiness.getById(this.id).then(r => this.ficha = r),
        RegistroPJBusiness.getEnvolvidos(this.id).then(r => this.envolvidos = r),
        IndicadorAbstractService.getDocumentosVinculados(this.id).then(r => this.documentos = r),
        IndicadorAbstractService.getBaseCalculoAtoVinculado(this.id).then(r => this.baseCalculo = r)
      ]);

      if(this.ficha.origem != null){
        let origem = await RegistroPJBusiness.getById(this.ficha?.origem?.id);
        let tipo =  await RegistroPJBusiness.getTipos().find(t => t.id === origem.tipoRegistro)?.nome;
        this.tipoRegistroAnterior = tipo;
      }
      this.loading = false;
    },

    computed:{
      tipoRegistro(){
        return RegistroPJBusiness.getTipos().find(t => t.id === this.ficha.tipoRegistro)?.nome;
      },
    },

    methods:{
      nomePapel(papel){ return businessTipoServico.nomePapel(papel) },

      detalhes(versao){
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {versao});
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      async detalhesRegistro(id){
        await FrontBusiness.openModal(DetalhesRegistroPJ, {id});
      },

    }
  }
</script>

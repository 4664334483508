import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http.js";

const path = 'api/v2/regras-estaduais';

export default {

  async getCustaAbatimentoRO(protocolo){
    return CachedApi.getCached('ro-custa-abatimento-'+protocolo, async () => {
      return Http.get(`${path}/RO/abatimento/${protocolo}`);
    });
  },

  async getSaldoProvisionadoSP(protocolo){
    return CachedApi.getCached('sp-saldo-provisionado-'+protocolo, async () => {
      return Http.get(`${path}/RO/abatimento/${protocolo}`);
    });
  }

}

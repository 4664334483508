<template>
  <modal id="form-registro-auxiliar" :titulo="titulo" :loading="!form">

    <form @submit.prevent="" v-if="form" autocomplete="off">

      <card>

        <card-content class="row gutters-xs">

          <v-input :error="validation.tipoRegistro" v-model="form.tipoRegistro" :options="options.tiposRegistros"
                   type="select" class="col-4" label="Tipo de Registro Auxiliar" />

          <v-input :error="validation.descricaoTipoRegistroOutros" v-model="form.descricaoTipoRegistroOutros"
                   type="text" class="col-4" label="Descrição" v-if="form.tipoRegistro == 'OUTROS'"/>

          <v-input :error="validation.tipoCedula" v-model="form.tipoCedula" :options="options.tiposCedulas"
                   type="select" class="col-4" label="Tipo de Cédula" v-if="form.tipoRegistro == 'CEDULA'"/>

          <v-input :error="validation.condominio" v-model="form.condominio"
                   type="text" class="col" label="Nome do Condomínio" v-if="form.tipoRegistro == 'CONVENCAO_CONDOMINIO'"/>

          <v-input :error="validation.pacto" v-model="form.pacto" @change="validarData"
                   type="date" class="col-3" label="Data do Pacto" v-if="form.tipoRegistro == 'PACTO'"/>

          <v-input class="col-2" label="Último Ato" v-model="form.ultimoAto" v-if="form.status == 'ABERTA'"
                   type="currency" :error="validation.ultimoAto" :input-props="{precision : 0}" />

          <v-input v-if="form.id && form.status !== 'PENDENTE'" :error="validation.status" v-model="form.status" :options="options.statusEdicao"
                   type="select" class="col-2" label="Situação" />

          <template v-if="form.status !== 'PENDENTE'">
            <div class="w-100"></div>
            <v-input type="datetime-local" class="col-6" label="Abertura"
                     v-model="form.abertura" :error="validation.abertura"/>

            <v-input v-if="form.status !== 'ABERTA'" type="datetime-local" class="col-6" :label="form.status === 'CANCELADA' ? 'Cancelamento' : 'Encerramento'"
                     v-model="form.encerramento" :error="validation.encerramento"/>

          </template>

        </card-content>

      </card>

      <card titulo="Identificação" v-if="enableLivroLetra || form.tipoRegistro === 'CEDULA'">
        <card-content class="row gutters-xs" >

          <v-input class="col-4" v-if="enableLivroLetra" v-model="form.livroLetra" type="text" label="Livro (Letra)" />

          <template v-if="form.tipoRegistro === 'CEDULA'">
            <v-input v-model="form.vencimentoCedula" type="date" class="col-3" label="Vencimento"/>

            <v-input class="col-3" label="Valor" v-model="form.valorCedula" type="currency" :input-props="{precision : decimais}" />
          </template>

        </card-content>
      </card>

      <card :titulo="labelTexto">
        <card-content class="row gutters-xs">
          <v-input type="editor" class="col" v-model="form.texto" />
        </card-content>
      </card>

      <card titulo="Observações">
        <card-content class="row gutters-xs">
          <v-input type="editor" class="col" v-model="form.observacoes" />
        </card-content>
      </card>

      <card titulo="Matrículas Relacionadas">
        <card-table>

          <thead>
            <tr>
              <th>Matrícula</th>
              <th width="30px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ficha, i) in form.indicadorReal" :key="ficha.id">
              <td class="pointer icon" @click="detalhesFicha(ficha.id)">
                <a>{{nomearFicha(ficha)}}</a>
              </td>
              <td>
                <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
              </td>
            </tr>
            <tr>
              <td colspan="100%">
                <div class="row">
                  <v-input class="col-auto pr-0" v-model="buscar" :options="options.livros" type="select" />
                  <buscar-fichas :livro="livroSelecionado" :status="statusSelecionado" ref="buscarFichas" @selecionar="selecionar($event)" class="pl-2 col"/>
                </div>
              </td>
            </tr>
          </tbody>

        </card-table>
      </card>
    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>
<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroAuxiliarBusiness from '@/business/indicadores/RegistroAuxiliarBusiness';
  import utils from "@/commons/Utils";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils";

  export default {
    name: "FormRegistroAuxiliar",
    components: {
      Card,
      CardTable,
      CardContent,
      Modal
    },

    modal: {
      escapable: false
    },

    props: {
      id: String,
      livro: String,
      clone: {type: Object, default: () => ({})}
    },

    async mounted() {

      let registroAuxiliar = {};

      if (this.id) {
        registroAuxiliar = await RegistroAuxiliarBusiness.getById(this.id);
        this.titulo = `Editar ${this.nomearLivro(registroAuxiliar)}`;
      } else {
        this.titulo = 'Adicionar ' + this.nomeLivro;
        registroAuxiliar = {...this.clone};
      }

      let form = {
        ...registroAuxiliar
      };

      form.abertura = registroAuxiliar.abertura ? this.$moment(registroAuxiliar.abertura).format('YYYY-MM-DDTHH:mm:ss') : null;
      form.encerramento = registroAuxiliar.encerramento ? this.$moment(registroAuxiliar.encerramento).format('YYYY-MM-DDTHH:mm:ss') : null;

      this.$set(this, 'form', form);
      this.validarData()
    },

    data() {
      return {
        form: null,
        validation: {},
        titulo: '',
        decimais: 2,
        buscar : 'MATRICULA',
        options: {
          tiposRegistros: RegistroAuxiliarBusiness.getTipos(),
          tiposCedulas: RegistroAuxiliarBusiness.getTiposCedulas(),
          statusEdicao: RegistroAuxiliarBusiness.getStatusImovel(false),
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        }
      }
    },

    computed: {
      nomeLivro() {
        return FrontBusiness.nomearFicha({livro: this.form?.livro || this.livro, status: ''}, false);
      },
      labelTexto(){
        return this.form.tipoRegistro === 'CEDULA' ? "Garantia" : "Teor";
      },

      livroSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
      },

      statusSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
      },

      enableLivroLetra(){
          return this.form.livroLetra !== null || this.$root.config.indicadorReal.habilitarLivroLetraRegistroAuxiliar && this.livro === 'REGISTRO_AUXILIAR';
      }
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#form-registro-auxiliar');
      },

      nomearLivro: FrontBusiness.nomearFicha,

      async salvar() {

          this.form.livro = this.form.livro || 'REGISTRO_AUXILIAR';

          let dto = utils.clone(this.form);
          dto.indicadorReal = (dto.indicadorReal || []).map((f) => ({id: f.id}));

          let obrigatorios = [], validated = true;

          if(this.form.status === 'ABERTA') obrigatorios.push('ultimoAto');

          obrigatorios.forEach(e => {
            if (Utils.isEmptyNullOrUndefined(this.form[e], true)) {
              this.$set(this.validation, e, 'Campo obrigatório');
              validated = false;
            }
          });

          if(validated) {
            if (dto.tipoRegistro === 'PACTO') {
              let data = dto.pacto;
              if (Utils.isEmptyNullOrUndefined(data) || ((this.$moment(data).isAfter(this.$moment()) || !this.$moment(data).isValid()) || data.split('-')[0] < '1850')) {
                return FrontBusiness.showError('', 'Necessário informar uma data válida para o Pacto Antenupcial')
              }
            }

            let registroAuxiliar = await RegistroAuxiliarBusiness.save(this.id, dto).catch(this.showError);
            if (registroAuxiliar) {
              FrontBusiness.closeModal(registroAuxiliar);
            }
          }
      },

      validarData(){
        let data = this.form.pacto;
        this.$set(this.validation, 'pacto', null);
        if (data && (this.$moment(data).isAfter(this.$moment()) || !this.$moment(data).isValid() || data.split('-')[0] < '1850')) {
          this.$set(this.validation, 'pacto', 'Data inválida');
        }
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      selecionar(itens) {
        this.$nextTick(() => {
          itens?.forEach((item) => {
            if (item && !this.form.indicadorReal.find(e => e.id == item.id)) {
              this.form.indicadorReal.push(item);
            }
          });
        });
      },

      remover(idx) {
        this.$nextTick(() => {
          this.form.indicadorReal.splice(idx, 1);
        });
      },

      detalhesFicha(id){
        FrontBusiness.openModal(DetalhesIndicadorReal, {id});
      },
    }
  }
</script>

<style lang=scss>
  #form-registro-auxiliar {
    .card {
      hr {
        margin-top: .25rem;
      }

      .busca-cep {
        margin-top: 21px;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>

<template>

  <div class="row no-gutters w-100">

    <div class="col-auto pr-2 align-self-center">
      <a href="" class="text-secondary" @click.prevent="pesquisar()" id="link-buscar-pessoa" title="Pesquisa Avançada">
        <i class="far fa-search"></i>
      </a>
    </div>
    <suggestions v-if="!masks" class="col form-group mb-0"
                 v-model="query"
                 :id="idInput"
                 :options="options"
                 :onItemSelected="selected"
                 :onInputChange="onInputChange">

      <template #item="{item}">

        <div class="single-item" v-if="item == 'empty'">
          Nenhum registro encontrado
        </div>

        <div class="single-item" v-else>
          {{item.nome}} - {{item.documento}} {{(filtros.versao || versao) ? '(Versão: ' + item.versao + ')' : ''}}
        </div>
      </template>

    </suggestions>

    <suggestions v-else class="col form-group mb-0"
                 v-model="query"
                 :id="idInput"
                 :options="options"
                 :onItemSelected="selected"
                 :onInputChange="onInputChange" v-mask="masks">

      <template #item="{item}">

        <div class="single-item" v-if="item == 'empty'">
          Nenhum registro encontrado
        </div>

        <div class="single-item" v-else>
          {{item.nome}} - {{item.documento}}
        </div>

      </template>

    </suggestions>

    <template v-if="loading">
      <span class="ml-1 spinner-border spinner-border-sm" style="position: relative; left: -22.5px; top: 10px;"></span>
    </template>

    <template v-if="novo">

      <div class="col-auto">
        <button class="btn btn-outline-secondary btn-xs ml-2" @click="criar()" title="Adicionar Pessoa" type="button" id="btn-criar-indicador-pessoal">
          <i class="fa fa-plus"></i>
        </button>
      </div>

    </template>

    <slot name="acoes"></slot>

  </div>

</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import ConsultarIndicadorPessoal from "@/components/IndicadorPessoal/ConsultarIndicadorPessoal.vue";
import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal.vue";
import {useDebounceFn, useThrottleFn} from "@vueuse/core";
import Suggestions from 'v-suggestions'
import {mask} from "vue-the-mask";

export default {
  name: "BuscarPessoa",
  directives: {mask},

  props: {
    defaultQuery: {type: String, default: ''},
    exemplo: String,
    novo: {type: Boolean, default: false},
    placeholder: {type: String, default: 'Digite o nome ou documento'},
    masks: String,
    idInput: String,
    fornecedor: {type: Boolean, default: false},
    tipoPessoa:{type:String,default:null},
    filtros: {type: Object, default: () => ({})},
  },

  components:{Suggestions},

  data () {
    let _this = this;
    return {
      query: this.defaultQuery,
      selectedSearchItem: null,
      loading: false,
      options: {
        debounce: 1000,
        inputClass: 'form-control form-control-xs',
        placeholder: this.placeholder
      },
    }
  },

  computed: {
    versao() {
      return this.$root.config.geral.tipoSelecaoIndicadorPessoal === "VERSAO_SELECIONADA";
    }
  },

  methods: {

    async sugerirPessoaFnc(query){
      this.loading = true;
      this.sugestoes = ['empty'];
      let retorno = await IndicadorPessoalBusiness
        .sugerir({...{'queryString': query, fornecedor: this.fornecedor,'tipoPessoa':this.tipoPessoa}, ...this.filtros})
        .then((results) => {
          if (results.length > 0) {
            results.sort((a, b) => a.nome.localeCompare(b.nome));
            return results;
          } else {
            return ['empty'];
          }
        }).catch(() => ['empty']);
      this.loading = false;
      this.sugestoes = retorno;
      console.debug('sugestoes fnc', this.sugestoes, retorno)
      return retorno;
    },

    async onInputChange (query) {
      if (query?.trim()?.length < 5) {
        this.loading = false;
        return ['empty'];
      }
      let res = await this.sugerirPessoaFnc(query).catch(() => null);
      console.debug('sugestoes', res)
      return res;
    },

    selected (item) {
      if(item != 'empty' && item?.id){
        this.$emit('add', item);
      }else{
        this.$emit('add', null);
      }
      this.query = '';
    },

    async pesquisar(){
      let pessoa = await FrontBusiness.openConsulta(ConsultarIndicadorPessoal, {...{select : true, fornecedor: this.fornecedor}, ...this.filtros});
      if(pessoa) {
        this.selected(pessoa);
      }
      FrontBusiness.setFocus(this.$el, 'link-buscar-pessoa');
    },

    async criar(){
      let pessoa = await FrontBusiness.openModal(FormIndicadorPessoal,{fornecedor: this.fornecedor});
      if(pessoa){
        this.selected(pessoa);
      }
      FrontBusiness.setFocus(this.$el, 'btn-criar-indicador-pessoal');
    },
  }
}
</script>

import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/fragmento';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getAllSimpleList() {
    return Http.get(`${path}/all-simple`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByIndice(indice){
    return CachedApi.getCached('fragmento-'+indice, async () => {
      return Http.get(`${path}/indice/${indice}`);
    }, false);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

}

<template>

  <div class="buscar-protocolos-wrapper row gutters-xs w-100">
    <template>
      <v-input type="select"  placeholder="Tipo" default="ENVIADO" :options="listas.tipos" class="col-auto" v-model="filtros.tipo"/>
      <v-input type="number" placeholder="Ano" v-model.number="filtros.ano" class="col-auto" style="max-width: 80px;" />

    </template>
    <div class="item-action col-auto pt-2">
      <a href="" class="icon" @click.prevent="pesquisar()" title="Pesquisa Avançada">
        <i class="far fa-search"></i>
      </a>
    </div>
    <suggestions class="col form-group mb-0"
                 v-model="query"
                 :options="options"
                 :onItemSelected="selected"
                 :onInputChange="onInputChange">

      <template #item="{item}">

        <div class="single-item" v-if="item == 'empty'">
          Nenhum registro encontrado
        </div>

        <div class="single-item" v-else>
          {{item.numero}}/{{item.ano}}
        </div>

      </template>

    </suggestions>

    <slot name="acoes"></slot>

  </div>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import OficioBusiness from "@/business/oficio/OficioBusiness.js";
  import ConsultarOficio from "@/components/Consultas/ConsultarOficio";
  import Suggestions from 'v-suggestions'

  export default {
    name: "BuscarOficio",
    props: {
      exemplo: String,
      novo: {type: Boolean, default: false},
      placeholder: {type: String, default: 'Digite o número'},
    },

    components:{Suggestions},

    data () {
      return {
        filtros : {
          ano : null,
        },
        listas:{
          tipos : OficioBusiness.getTipos()
        },
        query: '',
        selectedSearchItem: null,
        options: {debounce: 300, inputClass: 'form-control form-control-xs', placeholder: this.placeholder}
      }
    },

    methods: {

      onInputChange (query) {
        if (query.trim().length === 0) {
          return null
        }
        return OficioBusiness.sugerir(query, this.filtros).then(l => l.length ? l : ['empty']);
      },

      selected (item) {
        if(item != 'empty' && item?.id){
          this.$emit('add', item);
        }else{
          this.$emit('add', null);
        }
        this.query = '';
      },

      async pesquisar(){
        let oficio = await FrontBusiness.openConsulta(ConsultarOficio, {novoOficio: false, select : true});
        if(oficio) {
          this.selected(oficio);
        }
      },

    }
  }
</script>

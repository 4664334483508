<template>
    <router-view />
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style lang="css" src="@fortawesome/fontawesome-pro/css/all.css"></style>

<style>
  html.translated-ltr #app:before, html.translated-rtl #app:before{
    content: "Desative os plugins de tradução";
    font-weight: bold;
    width: 100%;
    line-height: 40px;
    height: 40px;
    background: #ff0000;
    position: absolute;
    top: 0;
    left: 0;
    color: #FFF;
    text-align: center;
    z-index: 9999999;
  }
</style>

<template>
  <modal titulo="Retificar Selo" id="retificar-selo">

    <card>
      <div v-if="selo.descricao">Tipo: {{selo.descricao}} {{selo.codigoAto ? '('+selo.codigoAto+')': ''}}</div>
      <div class="mt-2" v-if="selo.codigo">Código: {{ selo.codigo }}<template v-if="selo.codigoValidador">-{{selo.codigoValidador}}</template></div>
      <div class="mt-2" v-if="selo.utilizacao">Data de Utilização: {{selo.utilizacao | datahora}}</div>
      <div class="mt-2" v-if="selo.envio">Data de Envio: {{selo.envio | datahora}}</div>
    </card>

    <card class="mt-4">
      <small-loading :loading="true" v-if="loading" class="py-4"></small-loading>
      <informacoes-ressarcimento v-else-if="item" :item="item" :edicao="true" :retificarSelo="true"
                                 :tipo="tipo" :validation="validation" />
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <v-button :run="retificarSelo" v-if="item" class="btn-outline-primary" :popover="true">Retificar Selo</v-button>
    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import SeloBusiness from "@/business/SeloBusiness.js";
  import {source} from "@/commons/Http.js";
  import InformacoesRessarcimento from "@/components/InformacoesRessarcimento.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils.js";
  import AtoBusiness from "@/business/protocolo/AtoBusiness";
  import RegraCustasBusiness from "@/business/RegraCustasBusiness";
  import SmallLoading from "@/components/SmallLoading";
  import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness";
  import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
  import ProtocoloCustaAdicionalBusiness from "@/business/protocolo/ProtocoloCustaAdicionalBusiness";

  export default {
    name: "ModalRetificarSelo",
    components: {InformacoesRessarcimento, Card, Modal, SmallLoading},

    props: {
      selo: Object
    },

    data() {
      return {
        tipo: '',
        item:null,
        dto: null,
        motivosIsencao: RegraCustasBusiness.getMotivosIsencao(this.$root.config.estado),
        tiposProcessoRessarcimento: RegraCustasBusiness.getTiposProcessoRessarcimento(this.$root.config.estado),
        validation: {},
        isIsento: false,
        loading: true
      }
    },

    async mounted() {
      this.tipo = this.selo.atoId != null ? 'ato' : 'certidao';

      if(this.selo.atoId != null){
        this.dto = await AtoBusiness.getById(this.selo.protocoloId, this.selo.atoId);
      }

      if(this.selo.certidaoId != null){
        this.dto = await CertidaoBusiness.getById(this.selo.protocoloId, this.selo.certidaoId);
      }

      if(this.selo.protocoloCustaAdicional != null){
        this.dto = await ProtocoloCustaAdicionalBusiness.getById(this.selo.protocoloId, this.selo.protocoloCustaAdicional);
      }

      if(this.dto){
        this.$set(this, 'item', {
          ressarcimento: this.dto.ressarcimento || {},
          dto: {
            protocolo: this.dto.protocolo || this.selo.protocoloId
          },
        });
      }

      this.$set(this, 'loading', false);
    },

    methods: {

      close(response) {
        FrontBusiness.closeModal(response);
      },

      async retificarSelo() {
        let validated = true;

        if(!this.item){
          return;
        }

        if(!['SP', 'PR'].includes(this.$root.config.estado)) {
          let obrigatorios = ['motivoIsencao', 'tipoProcesso'];
          if (this.item.ressarcimento?.tipoProcesso != 'NAO_RESSARCIVEL') {
            obrigatorios.push('numeroProcesso');
          }

          this.$set(this, 'validation', {});

          if (this.item.isento) {
            obrigatorios.forEach(e => {
              if (Utils.isEmptyNullOrUndefined(this.item.ressarcimento[e])) {
                this.$set(this.validation, e, 'Campo obrigatório');
                validated = false;
              }
            });
          } else {
            obrigatorios.forEach(e => {
              this.$set(this.item.ressarcimento, e, null);
            });
          }
        }

        if(validated){
          let ato = Utils.clone(this.dto), dto;
          ato.isento = this.item.isento;
          ato.ressarcimento = this.item.ressarcimento;
          dto = {
            protocolo: {
              id: this.item.dto.protocolo?.id,
              interessado: {id: this.item.dto.protocolo?.interessado?.id}
            }
          };
          if (this.selo.atoId != null) {
            dto.ato = ato;
          } else if(this.selo.atoId == null && this.selo.certidaoId == null){
            dto.custaAdicional = ato;
          } else {
            dto.certidao = ato;
          }

          let result;

          if(this.selo.atoId == null && this.selo.certidaoId == null){
            result = await SeloBusiness.retificarSeloCustaAdicional(this.selo.id, dto).catch(e => {
              FrontBusiness.showError('', e.message);
              return false;
            });
          } else {
            result = await SeloBusiness.retificarSelo(this.selo.id, dto, this.selo.atoId != null ? false : true).catch(e => {
              FrontBusiness.showError('', e.message);
              return false;
            });
          }

          if(result){
            this.close(true);
          }
        }else{
          FrontBusiness.showError('', 'Favor verificar os campos obrigatórios');
        }
      }

    }

  }
</script>


import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/lista';
const cache = {};

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByIdCached(id){

    if(!cache[id]?.id){
      let r = await Http.get(`${path}/${id}`);
      cache[id] = r;
    }

    return cache[id];
  },

  async getListaBancos(){
    return Http.get(`${path}/public/bancos`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async importarXls(file) {
    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return Http.post(`${path}/importar-xls`, formData, config);
  },

}

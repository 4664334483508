<template>

  <div class="buscar-protocolos-wrapper row gutters-xs">
    <template v-if="selecionarDominio">

      <v-input type="select" v-if="!tipoProtocoloSimples && !hideDominio" :hideDominiosDiferentes="hideDominiosDiferentes"
               :options="options.dominios" class="col-auto" v-model="varDominio" @change="adicionar(true, true)"/>

      <template v-if="selecionarTipo && isProtocolo || (isPedidoCertidao && showTiposCertidao)">
        <v-input type="select" :options="tiposProtocoloDominio" class="col-auto"
                 :disabled="desabilitarTipo" v-model="varTipoProtocolo" @change="adicionar(true)"/>
      </template>

    </template>

    <template  v-if="varDominio == 'OFICIO'">
      <v-input type="select" :options="options.tiposOficio" class="col-auto" v-model="varTipoOficio" @change="adicionar(true)"/>
      <v-input type="number" v-model.number="varAnoOficio" class="col-auto" style="max-width: 80px;" @change="adicionar(true)" @blur="checkYear"/>
    </template>

    <div class="item-action col-auto pt-2">
      <a ref="protocolo" href="" @click.prevent="pesquisar()" data-testid="pesquisar" class="icon">
        <i class="fa fa-search"></i>
      </a>
    </div>

    <v-input type="text"

             :title="mensagemValidacao"
             :error="mensagemValidacao"
             :valid="campoValidado"
             v-model.trim="str"
             :small="true"

             @enter="adicionar(true)"
             @blur="adicionar()"

             :placeholder="placeholder"
             :loading="pesquisando"
             :data-testid="dataTestid"

             class="col" />
  </div>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness.js";
  import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
  import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
  import Utils from "@/commons/Utils";
  import utils from "@/commons/Utils.js";
  import ConsultarProtocolo from "@/components/Protocolo/ConsultarProtocolo.vue";
  import ConsultarOficio from "@/components/Consultas/ConsultarOficio";
  import ConsultarNotificacao from "@/components/Consultas/ConsultarNotificacao";
  import ConsultarOcorrencia from "@/components/Consultas/ConsultarOcorrencia";
  import OficioBusiness from "@/business/oficio/OficioBusiness.js";
  import NotificacaoBusiness from "../business/notificacao/NotificacaoBusiness";
  import OcorrenciaBusiness from "../business/ocorrencia/OcorrenciaBusiness";

  export default {
    name: "BuscarProtocolos",

    props: {
      dominioDefault: String,
      tipoDefault: String,
      allDominios: {type : Boolean, default : false},
      dominio : {type: String, default: DominioBusiness.getDominios()[0].id },
      tipoProtocolo : {type: String, default: ProtocoloBusiness.getTiposProtocolo()[0].id },
      multiple : {type : Boolean, default : true},
      multiplos : {type : Boolean, default : false},
      customDominios : {type : Array, default : () => []},
      selecionarDominio: {type: Boolean, default: true},
      selecionarTipo: {type: Boolean, default: true},
      limparCampo: {type: Boolean, default: true},
      valorInicial: {type: String, default: ''},
      tipoProtocoloSimples: {type: Boolean, default: false},
      desabilitarTipo: {type: Boolean, default: false},
      showTiposCertidao: {type: Boolean, default: false},
      hideDominio: {type: Boolean, default: false},
      hideDominiosDiferentes: {type: Boolean, default: false},
      noChangeSelect: {type: Boolean, default: false},
      tiposProtocolo: {type: Array, default: () => []},
      tiposCertidao: {type: Array, default: () => []},
      dataTestid: String,
      filtrosIniciais: {type: Array, default: () => []},
    },

    mounted() {
      if (this.tiposProtocolo.length) {
        this.options.tiposProtocolo = this.tiposProtocolo;
        this.varTipoProtocolo = this.options.tiposProtocolo[0].id;
      }
      if (this.tiposCertidao.length) {
        this.options.tiposCertidao = this.tiposCertidao;
        this.varTipoProtocolo = this.options.tiposCertidao[0].id;
      }
    },

    computed:{

      placeholder(){
        return this.multiple ? 'Ex.: 300-320, 123.123, 156123, 156.128-156.136' : 'Ex.: 123.123';
      },

      business(){
        let dominio = this.dominioDefault || this.varDominio;
        switch (dominio) {
          case 'OFICIO':
            return OficioBusiness;
          case 'NOTIFICACAO':
            return NotificacaoBusiness
          case 'OCORRENCIA':
            return OcorrenciaBusiness;
          default:
            return ProtocoloBusiness;
        }
      },

      dominioAtivo(){
        return DominioBusiness.getDominio(this.varDominio);
      },

      isPedidoCertidao(){
        return this.dominioAtivo?.tipo == 'PEDIDO_CERTIDAO';
      },

      isProtocolo(){
        return this.dominioAtivo?.tipo == 'PROTOCOLO';
      },

      tiposProtocoloDominio(){

        if(this.isProtocolo && this.tiposProtocolo?.length > 0){
          if(['RTD', 'RCPJ'].includes(this.dominioAtivo.atribuicao)){
            return [{id: 'ORCAMENTO', nome: 'Orçamento'}];
          }

          return this.tiposProtocolo;
        }

        if(this.isPedidoCertidao && this.tiposCertidao?.length > 0){
          return this.tiposCertidao;
        }

        return DominioBusiness.getTiposProtocoloPorDominio(this.dominioAtivo?.id)
      }
    },

    watch: {
      async str() {
        this.pesquisando = true;
        this.desvalidarCodigos();
        await this.validarCodigosDebounce();
      },

      varDominio () {
        this.$set(this, 'varTipoProtocolo', this.options?.tiposProtocolo[0]?.id);
      },

    },

    data() {
      return {
        str : this.valorInicial,
        pesquisando : false,
        mensagemValidacao : null,
        campoValidado : false,
        varDominio: this.dominio,
        varTipoProtocolo: this.tipoProtocolo,
        varTipoOficio: OficioBusiness.getTipos()[0].id,
        varAnoOficio: this.$moment().year(),
        options: {
          dominios: !this.allDominios ? (this.customDominios.length > 0 ? this.customDominios : DominioBusiness.dominiosAtivos()) : FrontBusiness.getDominios(),
          tiposProtocolo: ProtocoloBusiness.getTiposProtocolo(this.tipoProtocoloSimples),
          tiposCertidao: ProtocoloBusiness.getTiposCertidao(),
          tiposOficio: OficioBusiness.getTipos()
        },
        idsPesquisa: [],
      }
    },

    methods:{

      validarCodigosDebounce: Utils.debounce(async function () {
        await this.validarCodigos();
      }, 500),

      selecionarProtocolos(protocolos, add){
        if(this.hideDominiosDiferentes) {
          let primeiroProtocolo = protocolos[0];
          if(DominioBusiness.dominiosAuxiliares().includes(primeiroProtocolo.dominio)){
            this.options.dominios = this.options.dominios.filter(d => DominioBusiness.dominiosAuxiliares().includes(d.id));
          }else{
            this.options.dominios = this.options.dominios.filter(d => !DominioBusiness.dominiosAuxiliares().includes(d.id));
          }
        }
        if (this.idsPesquisa && this.idsPesquisa.length) {
          protocolos = protocolos.filter(f => this.idsPesquisa.includes(f.id))
          if (add) this.idsPesquisa = [];
        }
        this.$emit('selecionar', protocolos, add);
        if(this.limparCampo){
          this.str = '';
        }
      },

      async pesquisar(){
        let dominio = this.dominioDefault || this.varDominio;
        let consulta = ConsultarProtocolo;
        switch (dominio) {
          case 'OFICIO':
            consulta = ConsultarOficio;
            break;
          case 'NOTIFICACAO':
            consulta = ConsultarNotificacao;
            break;
          case 'OCORRENCIA':
            consulta = ConsultarOcorrencia;
            break;
        }
        let result = await FrontBusiness.openConsulta(consulta,{
          dominio: dominio, disabledDominio: this.hideDominio,
          tipoProtocolo : this.tipoDefault || this.varTipoProtocolo,
          multiplos: this.multiplos,
          tipoDefault: this.varTipoOficio,
          anoDefault: this.varAnoOficio,
          filtrosIniciais: this.filtrosIniciais || []
        });
        if(result){
          if (Array.isArray(result)) {
            result.forEach(r => {
              this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(dominio == 'OFICIO' ? r.numero : r.codigo)}${r.letra || ''}`;
            })
            this.idsPesquisa = result.map(r => r.id);
          } else {
            this.str = `${this.str || ''}${(this.str ? ', ' : '')}${FrontBusiness.formatarCodigo(dominio == 'OFICIO' ? result.numero : result.codigo)}${result.letra || ''}`;
            this.idsPesquisa = [result.id];
          }
          this.adicionar();
        }
      },

      async adicionar(add, alteracao){
        if(alteracao && this.noChangeSelect) this.str = '';
        let encontrados = await this.validarCodigos() || [];

        if(encontrados.length > 0){
          this.selecionarProtocolos(encontrados, alteracao && this.noChangeSelect ? false : add);
        }
      },

      async validarCodigos() {
        let encontrados = null;

        if (!Utils.isEmptyNullOrUndefined(this.str)) {

          this.pesquisando = true;
          await this.desvalidarCodigos();

          try{

            let protocolos = Utils.stringCodigosLetraToArray(this.str);

            if (!protocolos.length) {
              throw 'Código digitado errado';
            }

            encontrados = await this.validarFichas(protocolos);

            if(encontrados && encontrados.length){

              if(!this.multiple && encontrados.length > 1){
                encontrados = [];
                throw 'Digite apenas um código';
              }

              if (!this.idsPesquisa.length) {
                if (encontrados.length !== protocolos.length) {
                  let error = `Foram encontrados ${encontrados.length} registros com os dados informados. Utilize a lupa para definir o registro a ser selecionado.`;
                  encontrados = [];
                  throw error;
                }
              }

              this.$set(this, 'campoValidado', true);

            }

          }catch (e) {
            this.$set(this, 'mensagemValidacao', e);
          }

        }

        this.pesquisando = false;

        return encontrados;

      },

      desvalidarCodigos(){
        this.$set(this, 'mensagemValidacao', null);
        this.$set(this, 'campoValidado', false);
      },

      async validarFichas(codigos) {
        let dominio = this.dominioDefault || this.varDominio;
        let secondParam = dominio == 'OFICIO' ? this.varAnoOficio : dominio;
        let tipo = dominio == 'OFICIO' ? this.varTipoOficio : (this.tipoDefault || this.varTipoProtocolo);

        let protocolos = (await this.business.buscarPorCodigos(codigos, secondParam, tipo)) || [];
        protocolos.sort((a, b) => a.codigo - b.codigo);

        let protocolosEncontrados =  protocolos.map(f => f.codigo.toString().replace(/[^\d]/g, '').concat(f.letra || ''));
        let protocolosNaoEncontrados = codigos.filter(f => !protocolosEncontrados.includes(this.formatCodigo(f, dominio, secondParam, tipo ).toString().concat(f.letra || '')));
        if(protocolosNaoEncontrados.length){
          throw 'Código(s) não encontrado(s) ' + protocolosNaoEncontrados.join(', ');
        }
        return protocolos;
      },

      formatCodigo(codigo, dominio, secondParam, tipo) {
        if (dominio === 'OFICIO') {
          return `${FrontBusiness.getLabel(tipo, OficioBusiness.getTipos())} ${codigo}/${secondParam}`
        } else {
          return codigo;
        }

      },

      checkYear(){
        if(!this.varAnoOficio || this.varAnoOficio <= 0){
          this.varAnoOficio = this.$moment().year();
        }
      }

    }

  }
</script>

<style lang=scss>

</style>

<template>
  <modal titulo="CNIB - Consulta de Documentos" :closeable="!rodando">
    <AlertBanner v-if="consulta" message="A consulta abaixo serve apenas para simples conferência, os indicadores ficam indisponíveis apenas com a importação do XML da CNIB para dentro do sistema"/>

    <card :title="processo">
      <card-content>

        <p class="text-center" v-html="processo"></p>

        <template v-if="erros && erros.length">
          <p class="text-danger mb-2"><b>Erros retornados [{{erros.length}}]:</b></p>
          <ul class="pl-4">
            <li v-for="erro in erros" v-html="erro" :key="erro"></li>
          </ul>
        </template>

        <template v-if="resultados && resultados.length">

          <div v-for="resultado in resultados" :key="resultado.hash" class="alert my-2"
               :class="{'alert-outline-success' : resultado.valido, 'alert-outline-danger' : !resultado.valido}">

            <p><b>Documento:</b> {{resultado.documento}}</p>
            <p><b>Hash:</b> {{resultado.hash}}</p>
            <p class="pb-0">
              {{resultado.mensagem}}
              <template v-if="resultado.protocolos && resultado.protocolos.length">{{resultado.protocolos.join(', ')}}</template>
            </p>

          </div>

        </template>

      </card-content>
    </card>

    <template #footer>

      <button type="button" v-if="rodando" :disabled="true" class="btn btn-primary ml-auto mr-auto">
        <small-loading :loading="true" style="display: inline-block; margin-top: 5px; margin-bottom: 0 !important;" color="text-white" class="mb-1">
        </small-loading>
      </button>

      <template v-if="!rodando">

        <button type="button" class="btn btn-outline-primary mr-auto" @click="close">
            Fechar Consulta
        </button>

        <v-button v-if="!consulta" class="btn btn-outline-warning" :run="atualizar" :popover="true">
          Atualizar Envolvidos
        </v-button>

      </template>

    </template>

  </modal>
</template>

<script>
import ArispBusiness from "@/business/ArispBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness.js";
import LacunaBusiness from "@/business/LacunaBusiness.js";
import AlertBanner from "@/components/alert/AlertBanner.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import SmallLoading from "@/components/SmallLoading.vue";

export default {
    name: "CnibPesquisaDocumento",
    components: {SmallLoading, CardContent, Card, Modal, AlertBanner},
    props: {
      protocolo: String,
      documentos: Array,
      consulta:  {type : Boolean, default: () => false},
    },

    modal:{
      width: 900,
      escapable: false
    },

    data() {
      return {
        contador:{
          total: 0,
          atual: 0
        },
        processo: null,
        pedidos : null,
        erros: [],
        rodando: false,
        resultados: []
      }
    },

    mounted() {
      this.iniciar();
    },

    methods: {

      close(){
        FrontBusiness.closeModal();
      },

      async atualizar(){

        const valores = {};
        this.resultados?.forEach(e => {
          valores[e.documento] = e.hash;
        });

        let r = await this.$api.post(`api/v2/cnib/atualizar-protocolo/${this.protocolo}`, valores)
          .catch(e => this.mostrarErro(null, e));

        if(r){
          FrontBusiness.showSuccess('', 'Envolvidos atualizados com sucesso')
          FrontBusiness.closeModal(true);
        }

      },

      async mostrarErro(pedido, e){
        this.erros.push(`<b>Erro: </b> ${e?.message || e}`);
        return null;
      },

      async iniciar(){

        this.$set(this, 'rodando', true);
        this.contador.atual = 0;

        this.processo = "Testando certificado";
        let lacuna = await ArispBusiness.testarCertificado().catch(e => this.mostrarErro(null, e));

        if(!lacuna){
          this.$set(this, 'rodando', false);
          return;
        }

        this.processo = "Iniciando Comunicação";
        let challenge = await IndisponibilidadeBusiness.getChallenge().catch(e => this.mostrarErro(null, e));

        if(!challenge){
          this.$set(this, 'rodando', false);
          return;
        }

        let token = await LacunaBusiness.signData({
          thumbprint: lacuna.thumbprint,
          data: challenge.secret,
          digestAlgorithm: lacuna.digestAlgorithm
        });

        this.processo = "Iniciando consulta";
        let response;
        if (this.documentos && this.documentos.length) {
          response = await ArispBusiness.consultarHashsCnibDocumentos(lacuna, challenge.secret, token, this.documentos).catch(e => this.mostrarErro(null, e));
        } else {
          response = await ArispBusiness.consultarHashsCnibProtocolo(lacuna, challenge.secret, token, this.protocolo).catch(e => this.mostrarErro(null, e));
        }
        console.debug('response', response);

        this.processo = "Consulta finalizada";

        this.$set(this, 'resultados', response);
        this.$set(this, 'rodando', false);

      }
    }

  }
</script>

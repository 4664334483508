<template>
  <card :titulo="nome" class="anexos-wrapper anexos-wrapper-upload relative" :classTitle="classTitle" v-if="showEmpty || anexos.length">

    <template #acoes>

      <slot name="menu"></slot>

    </template>

    <small-loading :loading="true" v-if="loading" class="py-4"></small-loading>

    <!--    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">-->
    <!--      <h3>Solte os arquivos aqui</h3>-->
    <!--    </div>-->

    <template v-if="files.length">

      <div class="my-2">

        <ul>
          <li v-for="file in files" :key="file.id">

            <span>{{ file.name }}</span> -
            <span>{{ file.size | formatSize }}</span>
            <span v-if="file.error" class="text-red"> - {{ file.error }}</span>
            <span v-else-if="file.success" class="text-success"> - OK</span>
            <span v-else-if="file.active"> - enviando...</span>

            <div class="progress" v-if="file.active || file.progress !== '0.00'">
              <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">
                {{ file.progress }}% [{{ file.speed | formatSize }}/s velocidade]
              </div>
            </div>

          </li>
        </ul>

        <!--        <button type="button" class="btn btn-outline-success mx-1 pointer" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">-->
        <!--          <i class="fa fa-arrow-up" aria-hidden="true"></i>-->
        <!--          Começar o Envio-->
        <!--        </button>-->

        <button type="button" class="btn btn-outline-danger mx-1 pointer" v-if="!(!$refs.upload || !$refs.upload.active)" @click.prevent="$refs.upload.active = false">
          <i class="fa fa-stop" aria-hidden="true"></i>
          Parar o Envio
        </button>

      </div>
    </template>
    <div class="anexos-wrapper-botoes">

      <a v-if="!loading" target="_blank" class="btn btn-sm btn-outline-primary pointer mr-2" title="Baixar Anexos em Lote" @click.prevent="baixarAnexosLote">
        <i class="fal fa-download"></i>
      </a>

      <router-link :to="{ name: 'digitalizacao', params: { id: id, entidade : entidade, tipoEntidade: tipoEntidade }}" v-if="digitalizacao"
                   target="_blank" title="Digitalização" class="btn btn-sm btn-outline-primary pointer mr-2">
        <i class="fal fa-file-image"></i>
      </router-link>

      <template v-if="permitirEdicao && insert">

        <scanner-link :entidade="entidade" :id="id" :usuario="usuario.id" :etapa="etapa" @load="load()" class="mr-2 btn-sm btn-outline-primary" />

        <!--              :custom-action="upload"-->
        <!-- :headers="{'Content-Type': 'multipart/form-data'}"-->
        <file-upload v-if="postUrl"
                     class="btn btn-sm btn-outline-primary pointer"
                     :post-action="postUrl"
                     :multiple="true"
                     :drop="true"
                     :drop-directory="true"
                     v-model="files"
                     :input-id="uuid"
                     :thread="1"
                     :accept="accept"
                     :extensions="extensions"
                     @input-file="start"
                     @input-filter="inputFilter"
                     :size="maxUploadSize"
                     ref="upload"
                     name="file">
          <i class="fa fa-upload pointer"></i>
        </file-upload>

        <file-upload v-else
                     class="btn btn-sm btn-outline-primary pointer"
                     :custom-action="upload"
                     :multiple="true"
                     :drop="true"
                     :drop-directory="true"
                     v-model="files"
                     :input-id="uuid"
                     :thread="1"
                     :accept="accept"
                     :extensions="extensions"
                     @input-file="start"
                     @input-filter="inputFilter"
                     :size="maxUploadSize"
                     ref="upload"
                     name="file">
          <i class="fa fa-upload pointer"></i>
        </file-upload>

      </template>
    </div>

    <card-table v-show="!loading" class="mb-0">

      <thead>
      <tr>
        <th width="40px"></th>
        <th>Arquivo</th>

        <th colspan="2" class="text-right">

        </th>

      </tr>
      </thead>

    </card-table>

    <template v-if="!loading && anexos.length">

      <div class="d-flex justify-content-end mt-3">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="ml-2 fal fa-check-circle"></i>
        </a>
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="ml-2 fal fa-circle"></i>
        </a>
      </div>

      <card-table v-for="(arquivos, etapa) in etapas" :key="`anexos-${etapa}`">
        <tr style="background: transparent" v-if="etapa != '0'">
          <th colspan="100%">Etapa: {{etapa}}</th>
        </tr>
        <draggable v-model="arquivos.itens" ghost-class="ghost" handle=".move"
                   @end="ordenar" v-if="arquivos.itens.length > 0" tag="tbody">
          <tr v-for="(arquivo, index) in arquivos.itens" :key="arquivo.id">

            <td style="max-width: 15px; padding-right: 0;">
              <input type="checkbox" title="Selecionar" :id="arquivo.id" v-model="selecionados['arquivo_' + arquivo.id]"
                     :value="selecionados['arquivo_' + arquivo.id]" :checked="true" @change="arquivosSelecionados"  />
            </td>
            <td style="max-width: 15px;" v-if="!$root.usuarioLogado.auditor">
              <span class="icon move mr-2">
                <i class="fas fa-arrows-alt"></i>
              </span>
            </td>
            <td>
              <div style="max-width: 400px;" :title="arquivo.nomeAnexo" v-if="!arquivo.editando">
                <div class="text-truncate d-flex" style="max-width: 400px;" data-testid="arquivo">
                  <div class="text-truncate" style="max-width: 90%">
                    {{ arquivo.nomeAnexo }}
                  </div>
                  <span class="pointer" @click.prevent="editarAnexo(arquivo)" v-if="!$root.usuarioLogado.auditor">
                    <i class="icon ml-1 far fa-edit"></i>
                  </span>
                </div>
                <div class="small text-muted">{{ $formatters.datetime(arquivo.cadastro) }}
                  <label class="small text-muted" v-if="arquivo.tamanho > 0">({{ arquivo.tamanho | formatSize }}) </label>
                  <span>&nbsp;</span>
                  <label class="small text-muted" v-if="arquivo.tamanho > 0 && arquivo.extensao =='pdf'">| {{arquivo.total_pagina}} - pág.</label>
                </div>

                <template v-if="entidade == 'SISTEMA'">

                  <div class="small text-muted">
                    ID: {{arquivo.id}}
                  </div>

                  <div class="small text-muted">
                    URL: {{apiUrl}}/api/anexo/public/{{arquivo.id}}/download
                  </div>

                </template>

              </div>
              <div v-else class="row gutters-xs">
                <v-input v-model="arquivo.nomeAnexo" type="text" class="col" data-testid="nome-arquivo"/>
                <div class="col-auto">
                  <v-button class="btn btn-outline-success pointer mr-2" :run="salvarAnexo" :params="[arquivo]" type="button" title="Salvar" data-testid="editar-anexo">
                    <i class="far fa-save"></i>
                  </v-button>
                </div>
              </div>
            </td>
            <td style="max-width: 120px;" v-if="!$root.usuarioLogado.auditor">
              <TagShowSelect :id="arquivo.id" @retorno="updateTagAnexo(arquivo, $event)"/>
            </td>
            <td class="text-right" style="width: 150px;">

              <a class="pointer icon" target="_blank" v-if="permiteAssinar(arquivo) && !$root.usuarioLogado.auditor" @click="assinarDocumento(arquivo, index)" title="Assinar Documento">
                <i class="fal fa-pen-alt" v-if="indexItemAssindando === -1"></i>
                <span class="ml-1 spinner-border spinner-border-sm" v-else-if="indexItemAssindando === index"></span>
              </a>

<!--ASGARD-2365 - Opção ocultada até o problema com a validação dos arquivos assinados digitalmente ser resolvido-->
              <v-button :run="validarAssinatura" :params="[arquivo]" :button="false" class="icon ml-2">
                <i class="fal fa-shield-check" title="Validar Assinatura"></i>
              </v-button>

              <v-button :run="view" :params="[arquivo]" :button="false" class="icon ml-2">
                <i class="fal fa-external-link-alt" title="Visualizar Arquivo"></i>
              </v-button>

              <anexo-wrapper-download :anexo="arquivo" :assinado="true" class="icon ml-2" v-if="arquivo.assinatura" title="Download Arquivo Assinado" target="_blank">
                <template #conteudo>
                  <i class="fal fa-download download-assinado"></i>
                </template>
              </anexo-wrapper-download>

              <anexo-wrapper-download :anexo="arquivo" class="icon ml-2" v-if="entidade != 'sistema'" title="Download" target="_blank">
                <template #conteudo>
                  <i class="fal fa-download" data-testid="donwload-anexo"></i>
                </template>
              </anexo-wrapper-download>

              <anexo-wrapper-download :anexo="arquivo" class="icon ml-2" target="_blank" v-else title="Download">
                <template #conteudo>
                  <i class="fal fa-download" data-testid="donwload-anexo"></i>
                </template>
              </anexo-wrapper-download>

              <v-button :run="remover" :params="[arquivo]" :button="false" :popover="true" position="dropleft" class="icon ml-2" v-if="!$root.usuarioLogado.auditor">
                <i class="fal fa-times" title="Excluir" v-if="permitirExcluir(arquivo)"></i>
              </v-button>

            </td>
          </tr>
        </draggable>
      </card-table>

    </template>

    <card-table>
      <tbody v-if="!anexos.length && !loading">

      <tr>
        <td colspan="100%">
          <div class="alert alert-outline-info" data-testid="nenhum-arquivo">
            Nenhum arquivo anexado.
          </div>
        </td>
      </tr>

      </tbody>
    </card-table>

    <div class="d-flex" v-if="excluirButton && !$root.usuarioLogado.auditor">
      <v-button :run="baixarAnexosSelecionados" class="btn-primary">Download</v-button>
      <v-button :run="selecionarTagsLote" :params="['ADICIONAR']" class="btn-primary ml-2">Vincular tags</v-button>
      <v-button :run="selecionarTagsLote" :params="['REMOVER']" class="btn-primary ml-2">Remover tags</v-button>
      <v-button :run="removerLote" :popover="true" class="btn-primary ml-auto">Excluir</v-button>
    </div>

  </card>
</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness.js";
import AsgardStorageBusiness from "@/business/cloudStorage/AsgardStorageBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import LacunaBusiness from "@/business/LacunaBusiness.js";
import Utils from "@/commons/Utils.js";
import ModalValidarAssinatura from "@/components/Anexos/ModalValidarAssinatura.vue";
import ScannerLink from "@/components/Anexos/ScannerLink.vue";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import FileUpload from "vue-upload-component";
import AnexoWrapperDownload from "@/components/Anexos/AnexoWrapperDownload";
import draggable from 'vuedraggable'
import TagSelectModal from "@/components/Tag/TagSelectModal.vue";
import TagBusiness from "@/business/TagBusiness";

export default {
  name: "AnexosWrapper",

  props: {
    nome: String,
    entidade: String,
    id: String,
    accept: String,
    extensions: String,
    etapa: String,
    status: String,
    permitirEdicao: {type: Boolean, default: true},
    insert: {type: Boolean, default: true},
    showEmpty: {type: Boolean, default: true},
    digitalizacao: {type: Boolean, default: false},
    exclusao: {type: Boolean, default: true},
    labelDrop: String,
    classTitle: String,
    permiteAssinarDocumentos: {type: Boolean, default: false},
    tipoEntidade: String,
    dominio: String
  },

  components: {
    ScannerLink,
    AnexoWrapperDownload,
    TagShowSelect: () => import("@/components/Tag/TagShowSelect.vue"),
    SmallLoading,
    CardTable,
    Card,
    FileUpload,
    draggable
  },

  data() {
    return {
      files: [],
      anexos: [],
      load: null,
      loading: false,
      uuid: "upload-" + Utils.uuid(),
      indexItemAssindando: -1,
      scanner: {
        closeFnc: () => {
        }
      },
      postUrl: null,
      maxUploadSize: FrontBusiness.maxUploadSize,
      selecionados: {},
      excluirButton: false
    }
  },

  computed: {
    usuario() {
      return this.$root.usuarioLogado;
    },
    etapas(){
      let etapas = Utils.groupBy(this.anexos, (a) => a.etapa || '0');
      for (let e in etapas) {
        const x = etapas[e];
        etapas[e] = {};
        etapas[e]['itens'] = x;
      }
      return etapas;
    },
    apiUrl(){
      return window.server.API;
    }
  },

  beforeMount() {
    this.emitLoad();
    this.load = Utils.debounce(this.emitLoad, 1000);
  },

  beforeDestroy() {
    this?.scanner?.closeFnc();
  },

  mounted() {
    this.getPostUrl();
    window.onunload = () => {
      this?.scanner?.closeFnc();
    };
  },

  methods: {
    async initValues(){
      this.anexos.forEach((a) => {
        this.$set(this.selecionados, 'arquivo_' + a.id, false)
      })
    },

    arquivosSelecionados(){
      let selecionado = Object.keys(this.selecionados).find(key => this.selecionados[key] === true);

      if(!Utils.isEmptyNullOrUndefined(selecionado)){
        this.$set(this, 'excluirButton', true);
      } else {
        this.$set(this, 'excluirButton', false);
      }
    },

    selecionarTodos(limpar) {
      for (let a in this.selecionados) {
        this.$set(this.selecionados, a, !limpar);
        this.$set(this, 'excluirButton', !limpar);
      }
    },

    async setLoading(loading) {
      await this.$set(this, 'loading', loading);
      await this.$emit('loading', loading);
    },

    // async upload(file) {
    //   return await AnexoBusiness.inserir(file.file, this.entidade, this.id, null, false, false, this.etapa).then(r => {
    //     console.debug('upload', file.file, file.file.progress)
    //     this.load();
    //     return r;
    //   }).catch(e => {
    //     this.setLoading(false);
    //     FrontBusiness.showError('', e.message)
    //   });
    // },

    async emitLoad() {
      // setTimeout(() => this.$set(this, 'files', []), 200);
      await this.setLoading(true);
      this.$emit('load');
      await AnexoBusiness.listar(this.entidade, this.id).then(l => this.anexos = l);
      await this.initValues();
      await this.setLoading(false);
    },

    async removerLote(){
      let selecionado = Object.keys(this.selecionados).filter(key => this.selecionados[key] === true);
      let contadorErro = 0;
      if (selecionado.length){
        for (const i of selecionado) {
          if(this.permitirExcluir(i)){
            let id = i.split('_')[1];
            await AnexoBusiness.deleteById(id)
              .catch(e => FrontBusiness.showError('', e.message));
          }else{
            contadorErro++;
          }
        }
        if(contadorErro > 0 ){
          FrontBusiness.showWarn("","Ação não concluída com sucesso!");
        }
        this.selecionados = [];
        this.$set(this, 'excluirButton', false);
        await this.emitLoad();
      }
    },

    async remover(anexo) {
      if (this.permitirExcluir(anexo)) {
        await AnexoBusiness.deleteById(anexo.id)
          .catch(e => FrontBusiness.showError('', e.message));
        await this.emitLoad();
      }
    },

    start(newFile, oldFile) {
      setTimeout(() => this.$refs.upload.active = true, 500);

      if (newFile && oldFile) {
        if (newFile.success !== oldFile.success) {
          this.load();
        }
        if (newFile.error !== oldFile.error) {
          if (newFile.error === 'size') {
            newFile.error = 'O arquivo excede o tamanho máximo de ' + Utils.humanFileSize(this.maxUploadSize, false, 0)+ ' permitido';
            FrontBusiness.showError('', 'O arquivo excede o tamanho máximo de ' + Utils.humanFileSize(this.maxUploadSize, false, 0)+ ' permitido');
          }

        }
      }
      // $refs.upload.active = true
    },

    inputFilter(newFile, oldFile, prevent){
      if (newFile && newFile.xhr && !newFile.xhr.withCredentials) {
        newFile.xhr.withCredentials = true
      }
    },

    permitirExcluir(anexo) {

      if (this.entidade == 'PROTOCOLO_DOCUMENTO') {
        return (this.permitirEdicao && (!anexo.etapa?.id || (this.etapa && this.etapa == anexo.etapa?.id)))
      }

      if(this.entidade == 'PROTOCOLO' && this.status == 'FINALIZADO') {
        return this.$root.getPermission({id: 'PERMITIR_EXCLUIR_ANEXO_PROTOCOLO_FINALIZADO', dominio: this.dominio})
      } else {
        return true;
      }

      return false;
    },

    async updateTagAnexo(anexo, novasTags, action) {
      let anexoId = (typeof anexo === 'string') ? anexo : anexo.id;

      let dto = {
        id: anexoId,
        tags: []
      };

      if (action) {
        dto.tags = await TagBusiness.getByVinculo(anexo, 'ANEXO');
      }

      if (action === 'REMOVER') {
        novasTags.forEach((t) => {
          dto.tags = dto.tags.filter((tag) => tag.tag.id !== t.id);
        });
      } else {
        novasTags.forEach((t, i) => {
          dto.tags.push({ ordem: i + 1, tag: { id: t.id } });
        });
      }

      const retorno = await AnexoBusiness.salvarTags(anexoId, dto).catch(e => FrontBusiness.showError('', e.message));
      if (retorno) {
        FrontBusiness.showSuccess('', 'Tags do anexo salvas com sucesso');
      }
    },

    async assinarDocumento(arquivo, index) {
      this.indexItemAssindando = index;

      const response = await LacunaBusiness.signAnexo(arquivo.id).catch(e => {
        FrontBusiness.showError('Erro ao tentar assinar anexo', e.message );
        return false;
      });

      if (response) {
        this.$set(this.anexos[index], 'assinatura', response.assinatura);
        this.$set(this.anexos[index], 'caminhoAssinado', response.caminhoAssinado);
        FrontBusiness.showSuccess('', 'Arquivo assinado com sucesso');
      }
      this.indexItemAssindando = -1;
    },

    permiteAssinar(arquivo) {
      return this.permiteAssinarDocumentos && arquivo.extensao === 'pdf'
    },

    async getPostUrl(){
      this.postUrl = await AnexoBusiness.getUploadUrl(this.entidade, this.id, this.grupo, this.etapa);
    },

    async upload(file) {
      return await AnexoBusiness.inserir(file.file, this.entidade, this.id, null, false, this.grupo, this.etapa).then(r => {
        this.load();
        return r;
      });
    },

    async ordenar(o) {
      await this.$nextTick(async () => {
        const etapas = this.etapas;
        const ordens = {};
        const anexos = [];

        for (let e in etapas) {
          for (let i in etapas[e].itens) {
            anexos.push(etapas[e].itens[i]);
          }
        }

        this.$set(this, 'anexos', anexos);

        anexos.forEach((anexo, idx) => {
          if (anexo.ordem !== idx + 1) {
            ordens[anexo.id] = idx + 1;
            anexo.ordem = idx + 1;
          }
        })

        if (Object.keys(ordens).length) {
          await AnexoBusiness.ordenar(ordens);
        }
      });

    },

    validarAssinatura(arquivo) {
      FrontBusiness.openModal(ModalValidarAssinatura, {id: arquivo.id});
    },

    async editarAnexo(anexo) {
      this.$set(anexo, 'editando', true);
    },

    async salvarAnexo(anexo) {
      let form = {
        nomeAnexo: anexo.nomeAnexo
      }
      anexo = await AnexoBusiness.save(anexo.id, form).catch(this.showError);
      if (anexo) {
        this.$set(anexo, 'editando', false);
        await this.emitLoad();
      }
    },

    async view(arquivo) {
      let link = AsgardStorageBusiness.getViewUrl(arquivo);
      await FrontBusiness.abrirJanela(null, 'Visualizar Anexo',link);
    },

    async baixarAnexosLote(){
      let link = document.createElement('a');

      for(let anexo of this.anexos){
        let url = AsgardStorageBusiness.getDownloadUrl(anexo, anexo.assinatura);
        link.href = url;
        link.download = anexo.nomeAnexo;
        link.click();
       await FrontBusiness.sleep(1000);
      }
    },

    async baixarAnexosSelecionados(){
      let selecionado = Object.keys(this.selecionados).filter(key => this.selecionados[key] === true);
      if (selecionado.length) {
        let link = document.createElement('a');
        for (const i of selecionado) {
          try {
            const anexo = await AnexoBusiness.getDetalhesById(i.slice(8));
            link.href = AsgardStorageBusiness.getDownloadUrl(anexo, anexo.assinatura);
            link.download = anexo.nomeAnexo;
            link.click();
            await FrontBusiness.sleep(1000);
          } catch (error) {
            FrontBusiness.showError("", "Não foi possível fazer download dos anexos")
          }
        }
      }
    },

    async selecionarTagsLote(action) {
      let selecionados = Object.keys(this.selecionados).filter(key => this.selecionados[key] === true);

      let tags = await FrontBusiness.openModal(TagSelectModal, {
        tagsDisponiveis: await TagBusiness.getByTipo('ANEXO'),
        tags: []
      });

      if (tags) {
        await this.$set(this, 'tags', tags);
        for (const i of selecionados) {
          if (action === 'ADICIONAR') {
            await this.updateTagAnexo(i.slice(8), tags, 'ADICIONAR');
          } else {
            await this.updateTagAnexo(i.slice(8), tags, 'REMOVER');
          }
        }
        await this.emitLoad();
      }
    },
  }
}
</script>

<style lang="scss">

.anexos-wrapper {

  position: relative;

  //.drop-active {
  //
  //  top: 0;
  //  bottom: 0;
  //  right: 0;
  //  left: 0;
  //  position: fixed;
  //  z-index: 9999;
  //  opacity: .6;
  //  text-align: center;
  //  background: #000;
  //
  //  h3 {
  //    margin: -.5em 0 0;
  //    position: absolute;
  //    top: 50%;
  //    left: 0;
  //    right: 0;
  //    -webkit-transform: translateY(-50%);
  //    -ms-transform: translateY(-50%);
  //    transform: translateY(-50%);
  //    font-size: 40px;
  //    color: #fff;
  //    padding: 0;
  //  }
  //
  //}

  .drop-active {

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;

    h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }

  }

  .download-assinado {
    color: #00bc8c !important;
  }

  .anexos-wrapper-botoes {
    position: absolute;
    right: 17px;
  }

}

</style>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import LacunaBusiness from "@/business/LacunaBusiness.js";
import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness.js";
import Utils from "@/commons/Utils.js";

export default {

  name: "ArquivoRelatorioRow",

  props:{
    original: Object,
    completo: Boolean,
  },

  data(){
    return {
      loading: false,
      arquivo: null,
      excluido: false
    }
  },

  mounted() {
    this.update(this.original);
  },

  beforeMount() {
    if(this.original.status != 'CONCLUIDO' && this.original.status != 'ERRO' ){
      this.intervalAtualizacao = setInterval(this.emProgresso, 10000);
    }
  },


  computed:{

    mostrarOpcaoRegerar(){
      return this.$root.isVhlUser || this.arquivo.status == 'ERRO' || this.arquivo.status == 'FILA';
    },

    mostrarAssinar(){
      return this.arquivo.assinar && this.arquivo.extensao == 'PDF';
    },

    exibirVisualizar(){
      return ['PDF'].includes(this.arquivo.extensao);
    },

    tituloBotaoAssinatura(){
      if(this.arquivo.assinar && this.arquivo.assinado){
        return "Baixar Assinado";
      }else if(this.arquivo.assinar){
        return "Assinar e Baixar";
      }
    },

    chaveUnica(){
      return `${this.arquivo?.id}-${this.arquivo?.status}-${this.arquivo?.assinado}-${this.arquivo?.finalizacao}`;
    }

  },

  methods: {

    emitUpdate(){
      this.$emit('update', this.arquivo);
    },

    update(arquivo){
      this.$set(this, 'arquivo', Utils.clone(arquivo));
    },

    async reload(){
      this.setLoading(true);
      const arquivo = await ArquivoRelatorioBusiness.get(this.arquivo.id);
      this.update(arquivo);
      this.emitUpdate();
      this.setLoading(false);
    },

    async emProgresso(){
      let arquivo;
      if(this.original.id){
        arquivo = await ArquivoRelatorioBusiness.get(this.original.id).catch(e => null);
        if(arquivo && arquivo.status !== this.arquivo.status){
          this.setLoading(true);
          this.update(arquivo);
          this.emitUpdate();
          this.setLoading(false);
        }
      }

      if(!arquivo || arquivo.status == 'CONCLUIDO' || arquivo.status == 'ERRO'){
        clearInterval(this.intervalAtualizacao);
      }
    },

    setLoading(loading){
      this.$set(this, 'loading', loading);
    },

    botaoAssinar(){
      if(this.arquivo.assinar){
        if(!this.arquivo.assinado){
          this.assinarRelatorio();
        }else{
          this.downloadAssinatura();
        }
      }
    },

    async visualizarPdf() {
      await ArquivoRelatorioBusiness.visualizarPdf(this.arquivo.id).catch(e => {
        FrontBusiness.showError('Erro ao tentar fazer o download do relatório.', e.message);
      });
    },

    async regerar(){
      this.setLoading(true);
      await ArquivoRelatorioBusiness.regerar(this.arquivo.id).catch(e => {
        FrontBusiness.showError('Erro ao tentar fazer o download do relatório.', e.message);
      });
      await this.reload();
    },

    async download() {
      await ArquivoRelatorioBusiness.arquivoDownload(this.arquivo,false).catch(e => {
        FrontBusiness.showError('Erro ao tentar fazer o download do relatório.', e.message);
      });
    },

    async assinarRelatorio() {
      this.setLoading(true);

      await LacunaBusiness.preauthorizeSignatures(await LacunaBusiness.getThumb()).catch(e => {
        FrontBusiness.showError('', 'Erro ao tentar utilizar o certificado digital. Realize seu login novamente.');
        return false;
      });

      let form = {
        idArquivo:this.arquivo.id,
      }

      await LacunaBusiness.sign("relatorio", null, '', this.trataNomeArquivo(this.arquivo,"pdf"), false, form, true)
        .catch(e => {
          FrontBusiness.showError('Erro ao tentar assinar o relatório', e?.message || e);
          return false;
        });

      await this.reload();

      this.setLoading(false);

    },

    async downloadAssinatura() {
      this.setLoading(true);
      await ArquivoRelatorioBusiness.arquivoDownload(this.arquivo,true).catch(e => {
        FrontBusiness.showError('', 'Erro ao tentar fazer o download do relatório.');
      });
      this.setLoading(false);
    },

    async excluir() {
      this.setLoading(true);
      await ArquivoRelatorioBusiness.excluirRelatorio(this.arquivo.id);
      this.$set(this, 'excluido', true);
      this.emitUpdate();
      this.setLoading(false);
    },

    trataNomeArquivo(row, extensao){
      let nome;
      nome = row.id+"-"+ "assinado"+"."+extensao;
      nome = nome.toLocaleLowerCase();
      nome = nome.replaceAll("_","-");
      return nome;
    },

  }

}
</script>

<template>

  <tr v-if="!excluido && arquivo" :key="chaveUnica">

    <td class="small">{{ arquivo.nome }}</td>
    <td class="small">{{ arquivo.cadastro | datahora  }}</td>
    <td class="small">{{ arquivo.finalizacao | datahora }}</td>

    <td class="small" v-if="completo"><div style="max-width: 300px;">{{ arquivo.descricao }}</div></td>
    <td class="small" v-if="completo">{{ arquivo.extensao }}</td>

    <td class="small" :title="arquivo.descricao">{{ arquivo.status }}</td>

    <td class="small">

      <template v-if="loading || arquivo.status ==='PROGRESSO'">
        <span class="ml-1 spinner-border spinner-border-sm mr-2"></span>
      </template>

      <template v-if="!loading">

        <v-button v-if="mostrarOpcaoRegerar" :run="regerar" position="dropleft"
                  :button="false" class="icon mr-2" title="Regerar" :popover="true">
          <i class="fa fa-repeat mr-1"></i>
        </v-button>

        <template v-if="arquivo.status ==='CONCLUIDO'">

          <v-button v-if="mostrarAssinar" :run="botaoAssinar" position="dropleft" :button="false"
                    class="icon mr-2" :title="tituloBotaoAssinatura" :popover="true">
            <i :class="['fa', 'fa-file-signature']"></i>
          </v-button>

          <v-button :run="download" :button="false" class="icon mr-2" title="Baixar">
            <i :class="['fa', 'fa-file-download']"></i>
          </v-button>

          <v-button v-if="exibirVisualizar" :run="visualizarPdf"
                    :button="false" class="icon mr-2" title="Visualizar">
            <i :class="['fa', 'fa-file-search']"></i>
          </v-button>

        </template>

        <v-button :run="excluir" position="dropleft"
                  :button="false" class="icon" title="Excluir" :popover="true">
          <i class="fa fa-times"></i>
        </v-button>

      </template>
    </td>
  </tr>

</template>

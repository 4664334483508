import FrontBusiness from "@/business/FrontBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";

const path = 'api/configuracao';

export default {

  async get(){
    return Http.get(`${path}`).catch(e => {
      FrontBusiness.showError('', e.message);
      return {};
    });
  },

  async getResumo(){
    return CachedApi.getCached('resumo-config', async () => {
      return Http.get(`${path}/public?c=${window.server.SLUG}`);
    });
  },

  async update(dto){
    return Http.post(`${path}`, dto);
  },

  async alterarExpiracaoAcessoVHL(data) {
    return Http.post(`${path}/alterar-expiracao-vhl/${data}`);
  },

  async salvarFrasesLivro(dto){
    return Http.post(`${path}/salvar-frases-livro`, dto);
  },

  async atualizarFrasesLivro(id, dto){
    return Http.post(`${path}/atualizar-frases-livro/${id}`, dto);
  },

  async deletarFrasesLivro(id){
    return Http.delete(`${path}/deletar-frases-livro/${id}`);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/frases/pagination?sort=${sort || 'fl.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getConfiguracoesAcompanhamentoOnline(){
    return Http.get(`${path}/configuracoes-acompanhamento-online`);
  },

  async getLstBancoEmissor(){
    return Http.get(`${path}/banco-emissor`);
  },

  async getBancoEmissor(id){
    return Http.get(`${path}/banco-emissor/${id}`);
  },

  async getFrasesLivroProtocolo(id){
    return Http.get(`${path}/frases-livro-protocolo/${id}`);
  },

  async moduloNotaFiscalAtivo(){
    return VueBusiness.getVueRoot().config?.notaFiscal?.habilitada;
    // return Http.get(`${path}/modulo-nota-fiscal-ativo`)
  },

  async getLstTipoSelecaoIndicadorPessoal(){
    return Http.get(`${path}/tipo-selecao-indicador`);
  },

  async getLstApresentantes(){
    return Http.get(`${path}/apresentantes`);
  },

  async testarEnvioEmail(email){
    return Http.post(`${path}/testar-email`, email, {
      headers: { 'Content-Type': 'text/plain' }
    });
  },

  async getUltimaData(modulo) {
    return Http.get(`${path}/ultimaAtualizacao/${modulo}`);
  },

  async testarEnvioWhats(email){
    return Http.post(`${path}/whatsapp/test`, email, {
      headers: { 'Content-Type': 'text/plain' }
    });
  },

  async testarAssinadorOnr(){
    return Http.post(`${path}/testar-assinatura-onr`);
  },

  async saveLogoLivroProtocolo(logoLivroProtocolo) {
    return Http.post(`${path}/logo`, logoLivroProtocolo);
  },

  async deleteLogoLivroProtocolo() {
    return Http.delete(`${path}/logo`);
  },

  async getEnums() {
    return Http.get(`${path}/enums`);
  },

  async getLogoLivroProtocolo(cache = true) {
    let url = `${path}/public/logo${!cache ? `?clear=${Date.now()}` : ''}`;
    let logoLivroProtocolo = await Http.get(url, {cache : cache})
      .catch(() => null);
    return logoLivroProtocolo?.length > 1 ? `${window.server.API}/${url}`: null;
  },

  getContratoArisp(){
    return {
      tipoDocumento: [
        { id: 1, nome: "Escritura Pública"},
        { id: 2, nome: "Instrumento Particular"},
        { id: 4, nome: "Instrumento Particular com força de Escritura Pública"},
        { id: 6, nome: "Instrumento Particular de Cancelamento de Garantias"},
        { id: 5, nome: "Ordens Judiciais e Administrativas"},
        { id: 7, nome: "Requerimento Averbação"},
        { id: 8, nome: "Título de Crédito"},
        { id: 9, nome: "Parcelamento do Solo/Loteamento"},
        { id: 10, nome: "Incorporação/Especificação"},
        { id: 11, nome: "Retificação Administrativa"},
        { id: 12, nome: "Usucapião Extrajudicial e Judicial"},
        { id: 13, nome: "Reurb"}
      ],
      certidoes: [
        { id: 3, nome: "Matrícula do Imóvel"},
        { id: 4, nome: "Transcrição"},
        { id: 6, nome: "Ônus e Alienações"},
        { id: 2, nome: "Vintenária"},
        { id: 1, nome: "Propriedade/Negativa de Propriedade"},
        { id: 7, nome: "Documento Arquivado"},
        { id: 5, nome: "Pacto Antenupcial"},
        { id: 8, nome: "Convenção de Condomínio"},
        { id: 9, nome: "Livro 3 - Garantias"},
        { id: 10, nome: "Outros Registros Livro 3 - Auxiliar"},
        { id: 12, nome: "Inteiro Teor, Ônus e Ações"},
        { id: 13, nome: "Por Quesito"},
        { id: 14, nome: "Negativa de Penhor"},
        { id: 15, nome: "Ônus Reais e Ações Reipersecutórias"},
        { id: 16, nome: "Usucapião"},
        { id: 17, nome: "Propriedade, Ônus E Alienações"},
        { id: 18, nome: "Cadeia Dominial (Filiação Até Origem)"},
        { id: 19, nome: "Ações Reipersecutórias"},
        { id: 20, nome: "Ônus Reais"},
        { id: 21, nome: "Certidão da Situação Jurídica Atualizada do Imóvel"},
      ],
      tipoPesquisaCertidao:[
        { id: 4, nome: "Nº de Matrícula"},
        { id: 5, nome: "Nº de Transcrição"},
        { id: 6, nome: "Nome da Pessoa Física ou Jurídica"},
        { id: 7, nome: "Nº de Registro"},
        { id: 8, nome: "Nome dos Pactuantes"},
        { id: 9, nome: "Endereço"},
        { id: 10, nome: "Nº do Protocolo"},
        { id: 11, nome: "Nº do Registro do Livro 3"},
        { id: 12, nome: "Nome do Condomínio"},
        { id: 13, nome: "Nº de Matrícula com Complemento"},
        { id: 14, nome: "Nº de Transcrição com Complemento"},
      ]
    }
  },

  getPeerBancos(){
    return {
      papeis: [
        { id: 1,	nome : 'Adquirente' },
        { id: 2,	nome : 'Avalista' },
        { id: 3,	nome : 'Credor' },
        { id: 4,	nome : 'Depositário' },
        { id: 5,	nome : 'Devedor' },
        { id: 6,	nome : 'Incorporado' },
        { id: 7,	nome : 'Incorporador' },
        { id: 8,	nome : 'Interessado' },
        { id: 9,	nome : 'Interveniente' },
        { id: 10,	nome : 'Proprietário' },
        { id: 11,	nome : 'Requerente' },
        { id: 12,	nome : 'Transmitente' },
        { id: 13,	nome : 'Anuente' },
        { id: 14,	nome : 'Representante Credor' }
      ],

      atos : [
        {id : 1, nome :	'Venda e Compra'},
        {id : 2, nome :	'Hipoteca'},
        {id : 3, nome :	'Alienação Fiduciária'},
        {id : 4, nome :	'Portabilidade'},
        {id : 5, nome :	'Compra e Venda Alienação'},
        {id : 6, nome :	'Compra e Venda Alienação CCI'},
        {id : 7, nome :	'Compra e Venda Alienação CCB'},
        {id : 8, nome :	'Convenio de Limite de Crédito com Alienação Fiduciária'},
        {id : 9, nome :	'Compromisso de Venda e Compra'},
        {id : 10, nome : 'Doação'},
        {id : 11, nome : 'Usufruto'},
        {id : 12, nome : 'Inventário'},
        {id : 13, nome : 'Part. Separação/Divórcio'},
        {id : 14, nome : 'Dação em pagamento'},
        {id : 15, nome : 'Permuta'},
        {id : 16, nome : 'Conferência de bens'},
        {id : 17, nome : 'Bem de família'},
        {id : 18, nome : 'Aditamento de Cédula'}
      ],

      naturezas : [
        {id : 101, nome: 'Instrumento Particular'},
        {id : 102, nome: 'Cédula de Crédito Imobiliário'},
        {id : 103, nome: 'Cédula de Crédito Bancário'},
        {id : 104, nome: 'Escritura pública'},
        {id : 1, nome: 'SFH taxa tabelada'},
        {id : 2, nome: 'SFH taxa de mercado'},
        {id : 3, nome: 'PMCMV'},
        {id : 4, nome: 'SFI'},
        {id : 5, nome: 'Outro Enquandramento de Financiamento'},
      ],

      documentos : [
        {id : 1, nome: 'Instrumento Particular'},
        {id : 2, nome: 'Cédula de Crédito Imobiliário'},
        {id : 3, nome: 'Cédula de Crédito Bancário'},
        {id : 4, nome: 'Escritura pública'},
      ],

      indices : [
        {id : 'DATAINSTRUMENTO', nome : 'DATAINSTRUMENTO'},
        {id : 'NUMCONTRATO', nome : 'NUMCONTRATO'},

        {id : 'CEDULA.TIPOCEDULA', nome : 'CEDULA.TIPOCEDULA'},
        {id : 'CEDULA.TIPO', nome : 'CEDULA.TIPO'},
        {id : 'CEDULA.NUMERO', nome : 'CEDULA.NUMERO'},
        {id : 'CEDULA.FRACAO', nome : 'CEDULA.FRACAO'},
        {id : 'CEDULA.SERIE', nome : 'CEDULA.SERIE'},
        {id : 'CEDULA.ESPECIE', nome : 'CEDULA.ESPECIE'},
        {id : 'CEDULA.CUSTODIANTE_EMISSOR', nome : 'CEDULA.CUSTODIANTE_EMISSOR'},
        {id : 'CEDULA.DATA', nome : 'CEDULA.DATA'},

        {id : 'IMPOSTOTRANSMISSAO.ISENCAO', nome : 'IMPOSTOTRANSMISSAO.ISENCAO'},
        {id : 'IMPOSTOTRANSMISSAO.INSCRICAO', nome : 'IMPOSTOTRANSMISSAO.INSCRICAO'},
        {id : 'IMPOSTOTRANSMISSAO.GUIA', nome : 'IMPOSTOTRANSMISSAO.GUIA'},
        {id : 'IMPOSTOTRANSMISSAO.VALOR', nome : 'IMPOSTOTRANSMISSAO.VALOR'},

        {id : 'NEGOCIO.VALORLEILAO', nome : 'NEGOCIO.VALORLEILAO'},
        {id : 'NEGOCIO.RECURSOSPROPRIOS', nome : 'NEGOCIO.RECURSOSPROPRIOS'},
        {id : 'NEGOCIO.OUTROSRECURSOS', nome : 'NEGOCIO.OUTROSRECURSOS'},
        {id : 'NEGOCIO.PRIMEIRAAQUISICAO', nome : 'NEGOCIO.PRIMEIRAAQUISICAO'},
        {id : 'NEGOCIO.OBSERVACOESGERAIS', nome : 'NEGOCIO.OBSERVACOESGERAIS'},

        {id : 'FINANCIAMENTO.VALORFINANCIAMENTO', nome : 'FINANCIAMENTO.VALORFINANCIAMENTO'},
        {id : 'FINANCIAMENTO.VALORAVALIACAO', nome : 'FINANCIAMENTO.VALORAVALIACAO'},
        {id : 'FINANCIAMENTO.VALORLEILAO', nome : 'FINANCIAMENTO.VALORLEILAO'},
        {id : 'FINANCIAMENTO.PRAZOCARENCIA', nome : 'FINANCIAMENTO.PRAZOCARENCIA'},
        {id : 'FINANCIAMENTO.ENQUADRAMENTOFINANCIAMENTO', nome : 'FINANCIAMENTO.ENQUADRAMENTOFINANCIAMENTO'},
        {id : 'FINANCIAMENTO.SISTEMAAMORTIZACAO', nome : 'FINANCIAMENTO.SISTEMAAMORTIZACAO'},
        {id : 'FINANCIAMENTO.PRAZOAMORTIZACAO', nome : 'FINANCIAMENTO.PRAZOAMORTIZACAO'},
        {id : 'FINANCIAMENTO.ORIGEMRECURSOS', nome : 'FINANCIAMENTO.ORIGEMRECURSOS'},
        {id : 'FINANCIAMENTO.JUROSANUALNOMINAL', nome : 'FINANCIAMENTO.JUROSANUALNOMINAL'},
        {id : 'FINANCIAMENTO.JUROSANUALEFETIVO', nome : 'FINANCIAMENTO.JUROSANUALEFETIVO'},
        {id : 'FINANCIAMENTO.JUROSMENSALNOMINAL', nome : 'FINANCIAMENTO.JUROSMENSALNOMINAL'},
        {id : 'FINANCIAMENTO.JUROSMENSALEFETIVO', nome : 'FINANCIAMENTO.JUROSMENSALEFETIVO'},
        {id : 'FINANCIAMENTO.DATAPRIMEIRAPARCELA', nome : 'FINANCIAMENTO.DATAPRIMEIRAPARCELA'},
        {id : 'FINANCIAMENTO.VALORPRIMEIRAPARCELA', nome : 'FINANCIAMENTO.VALORPRIMEIRAPARCELA'},
        {id : 'FINANCIAMENTO.DESTFINANCIAMENTO', nome : 'FINANCIAMENTO.DESTFINANCIAMENTO'},
        {id : 'FINANCIAMENTO.FORMADEPAGAMENTO', nome : 'FINANCIAMENTO.FORMADEPAGAMENTO'},
        {id : 'FINANCIAMENTO.VALORTOTALCREDITO', nome : 'FINANCIAMENTO.VALORTOTALCREDITO'},
        {id : 'FINANCIAMENTO.TAXAMAXIMAJUROS', nome : 'FINANCIAMENTO.TAXAMAXIMAJUROS'},
        {id : 'FINANCIAMENTO.TAXAMINIMAJUROS', nome : 'FINANCIAMENTO.TAXAMINIMAJUROS'},
        {id : 'FINANCIAMENTO.PRAZODEVIGENCIA', nome : 'FINANCIAMENTO.PRAZODEVIGENCIA'},
        {id : 'FINANCIAMENTO.VENCIMENTOANTECIPADO', nome : 'FINANCIAMENTO.VENCIMENTOANTECIPADO'},
        {id : 'FINANCIAMENTO.INFORMACAOGERAIS', nome : 'FINANCIAMENTO.INFORMACAOGERAIS'}
      ]
    }
  },
  getAcompanhamentoOnline(){
    return [
      {id: 'TODAS_EXIGENCIAS', nome: 'Todas as exigências do protocolo atual'},
      {id: 'ULTIMA_EXIGENCIA', nome: 'Apenas a última exigência do protocolo atual'},
      {id: 'TODAS_EXIGENCIAS_REPROTOCOLO', nome: 'Todas as exigências do reprotocolo'}
    ]
  }
}

<template>
  <consulta :columns="columns" ref="modal" :process="process" id="consultar-relatorio" :titulo="titulo"
            :grabbable="true">

    <template #filtros>
      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">

          <v-input type="v-multi-select" :options="tags"
                   v-model="item.value" v-if="item.id == 'tag'" class="col-12" />

        </template>
      </filtrar-consulta>

    </template>

    <template #row="{ row }">
      <arquivo-relatorio-row :original="row" :completo="true"
                             :key="row.id" @update="reload()"></arquivo-relatorio-row>
    </template>

  </consulta>
</template>

<script>
import Utils from "@/commons/Utils.js";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import ArquivoRelatorioRow from "@/components/Livros/ArquivoRelatorioRow.vue";
import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness";
import loading from "@/components/Loading.vue";
import RelatorioBusiness from "@/business/RelatorioBusiness";

export default {
  name: "ConsultarRelatorio",
  components: {ArquivoRelatorioRow, FiltrarConsulta,
    Consulta: () => import("@/components/Consultas/Consulta.vue"),
  },

  props: {
    multiplos : {type : Boolean, default : false},
    filtrosIniciais: {type: Array, default: () => []},
    identificacao : String,
  },

  async mounted() {
    if (this.identificacao) {
      let tipos = await RelatorioBusiness.getRelatoriosAll();
      this.tipo = (tipos || []).find(t => t.id === this.identificacao)?.nome
      setTimeout(() => {
        this.reload();
      }, 100);
    }
  },

  modal: {
    width: 2000,
    escapable: false
  },

  data() {
    return {
      idRowClicada:null,
      clickBotao:false,
      mode: 'novo',
      tipo: '',
      opcoesConsulta: ArquivoRelatorioBusiness.camposConsulta(),
      filtros: {
      },
    }
  },

  computed: {
    titulo() {
      let titulo = 'Consultar Relatório';
      if (this.tipo) {
        titulo += ` - ${this.tipo}`;
      }
      return titulo;
    },
    loading() {
      return loading
    },
    columns() {
      let cols = [];
      cols.push(
        {label: 'Nome', field: 'ar.nome', headerClass: 'column__nome', name: 'nome', minWidth:'250px'},
        {label: 'Cadastro', field: 'ar.cadastro', headerClass: 'column__cadastro', name: 'cadastro', minWidth:'170px'},
        {label: 'Finalizado', field: 'ar.finalizacao', headerClass: 'column__finalizacao', name: 'finalizacao', minWidth:'170px'},
        {label: 'Descrição', field: 'ar.descricao', headerClass: 'column__descricao', name: 'descricao', width:'250px'},
        {label: 'Extensão', field: 'ar.extensao', headerClass: 'column__extensao', name: 'extensao', minWidth:'100px'},
        {label: 'Status', field: 'ar.status', headerClass: 'column__status', name: 'status'},
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id', minWidth:'120px'},
      );
      return cols;
    },
  },


  methods: {

    reload(){
      this.$refs?.modal?.load();
    },

    async process(sort_by, sort_dir, page_length, page_number) {
      let filtros = Utils.clone([...this.filtros?.filtros].filter(e => e.id));
      if (this.identificacao) filtros.push({id: 'identificacao', value: this.identificacao});
      return ArquivoRelatorioBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
    },

  }
}
</script>

<style lang=scss>
#consultar-indicador-pessoal {
  th.column__botao {
    width: 50px;
  }
}
.buttons_opcoes{
  margin-right: 10px;
}
.disabled {
  opacity: 0.5;
}
</style>

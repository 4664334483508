<template>
  <div>
    <template v-for="(alerta, i) in alertas">
      <p class="alert alert-warning mb-2 pointer" :class="classe" :key="'ato-ficha-alerta--'+i" @click="abrirAlerta(alerta)">
        {{alerta.msg}}
      </p>
    </template>
  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";

export default {
        name: "AlertBannerRadius",
        props:{
          alertas: {type: Array, default: () => []},
          classe: String
        },
        methods: {
          abrirAlerta(alerta) {
            if(alerta.command){
              alerta.command();
            } else {
              FrontBusiness.closeModal({id: alerta.id, tipo: alerta.tipo, area: alerta.area});
            }
          },
        }
    }
</script>

<template>
  <div id="aba-anexo-indicador-real">
    <anexos-wrapper nome="Anexos" entidade="INDICADOR_REAL" :id="ficha.id" :permite-assinar-documentos="true"/>
  </div>
</template>

<script>
  import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";
  import FrontBusiness from "@/business/FrontBusiness";

  export default {

    name: "Anexos",
    components: {AnexosWrapper},
    props: {
      ficha: Object,
    },

    data() {
      return {
      }
    },

    computed: {
      tipoEntidade() {
        return `${this.ficha.status == 'PENDENTE' || this.ficha.prematricula? 'pre-' : ''}${this.ficha.livro.toLowerCase()}`;
      }
    },

    methods: {
    }
  }
</script>

<template>
  <modal :titulo="titulo">

    <div class="alert alert-warning" v-if="!sugestoes.length">
      Nenhum indicador sugerido
    </div>

    <card titulo=" ">
      <div class="div-select-all">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="ml-2 fal fa-check-circle"></i>
        </a>
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="ml-2 fal fa-circle"></i>
        </a>
      </div>
      <card-table>
        <tbody>

        <tr v-for="sugestao in sugestoes" :key="sugestao.id">
          <td class="text-right">
            <v-button :run="selecionar" :params="[sugestao]" :button="false" class="icon" :popover="false">
              <i
                :class="{'fa-check-square text-primary' : fichas.includes(sugestao), 'fa-square text-muted' : !fichas.includes(sugestao)}"
                class="fal pointer"></i>
            </v-button>
          </td>
          <td class="w-100">
            <v-button :run="selecionar" :params="[sugestao]" :button="false" class="icon" :popover="false">
              {{nomearFicha(sugestao)}}
            </v-button>
          </td>
        </tr>

        </tbody>

      </card-table>

    </card>


    <template #footer>

      <v-button class="btn btn-outline-primary mr-2 ml-auto" :run="select" :params="[fichas]">
        Selecionar
      </v-button>

    </template>

  </modal>
</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";

  export default {

    name: "FichaSugestao",
    components: {CardTable, Card, Modal},
    props: {
      sugestoes: Array,
      titulo: {type: String, default: 'Sugestões'}
    },

    modal: {
      width: 500,
      escapable: false
    },

    data() {
      return {
        fichas: []
      }
    },

    methods: {

      selecionar(ficha) {
        // let i = this.fichas.indexOf(ficha.id);
        let i = this.fichas.findIndex((item, i) => {
          return item.id === ficha.id
        });
        if (i === -1) {
          this.fichas.push(ficha);
        } else {
          this.fichas.splice(i, 1)
        }
      },

      selecionarTodos(limpar = false) {

        this.fichas = [];
        if (!limpar) this.sugestoes?.forEach(item => this.selecionar(item));
      },

      nomearFicha(ficha) {
        return FrontBusiness.nomearFicha(ficha);
      },

      select(sugestao) {
        FrontBusiness.closeModal(sugestao);
      }

    }

  }
</script>

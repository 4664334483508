import Http from "@/commons/Http";
import Utils from "@/commons/Utils";
import DominioBusiness from "@/business/protocolo/DominioBusiness";

const path = 'api/protocolo';

export default {

  async salvar(dto, protocolo){
    return Http.post(`${path}/${protocolo}/siscoaf`, dto);
  },

  async comunicar(protocolo, siscoaf, statusCoaf){
    return Http.post(`${path}/${protocolo}/siscoaf/${siscoaf}/${statusCoaf}`);
  },

  async getEnvolvidos(protocolo, siscoaf){
    return Http.get(`${path}/${protocolo}/siscoaf/${siscoaf}/envolvidos`);
  },

  async listarOcorrencias(protocolo){
    return Http.get(`${path}/${protocolo}/siscoaf/ocorrencias`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`api/siscoaf/pagination?sort=${sort || 's.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async pendente(protocolo){
    return Http.get(`${path}/${protocolo}/siscoaf/pendente`);
  },

  async analisarSiscoaf(protocolo, filtros){
    return Http.post(`${path}/${protocolo}/siscoaf/analisar`, filtros);
  },

  tiposOcorrenciaProvimento88(){
    return [
      { id: 951, nome : `Art. 20-I - a operação que aparente não resultar de atividades ou negócios usuais do cliente ou do seu ramo de negócio. CNJ - Provimento 88/2019.` },
      { id: 952, nome : `Art. 20-II - a operação cuja origem ou fundamentação econômica ou legal não sejam claramente aferíveis. CNJ - Provimento 88/2019.` },
      { id: 953, nome : `Art. 20-III - a operação incompatível com o patrimônio ou com a capacidade econômico-financeira do cliente. CNJ - Provimento 88/2019.` },
      { id: 954, nome : `Art. 20-IV - a operação cujo beneficiário final não seja possível identificar. CNJ - Provimento 88/2019.` },
      { id: 955, nome : `Art. 20-V - as operações envolvendo pessoas jurídicas domiciliadas em jurisdições consideradas pelo Grupo de Ação contra a Lavagem de Dinheiro e o Financiamento do Terrorismo (Gafi) de alto risco ou com deficiências estratégicas de prevenção e combate à lavagem de dinheiro e ao financiamento do terrorismo. CNJ - Provimento 88/2019.` },
      { id: 956, nome : `Art. 20-VI - operações envolvendo países ou dependências considerados pela RFB de tributação favorecida e/ou regime fiscal privilegiado, conforme lista pública. CNJ - Provimento 88/2019.` },
      { id: 957, nome : `Art. 20-VII - a operação envolvendo pessoa jurídica cujo beneficiário final, sócios, acionistas, procuradores ou representantes legais mantenham domicílio em jurisdições consideradas pelo Gafi de alto risco ou com deficiências estratégicas de prevenção e combate à lavagem de dinheiro e ao financiamento do terrorismo. CNJ - Provimento 88/2019.` },
      { id: 958, nome : `Art. 20-VIII - a resistência, por parte do cliente e/ou dos demais envolvidos, no fornecimento de informações solicitadas para o registro da operação, bem como para o preenchimento dos cadastros. CNJ - Provimento 88/2019.` },
      { id: 959, nome : `Art. 20-IX - a prestação, por parte do cliente e/ou dos demais envolvidos, de informação falsa ou de difícil ou onerosa verificação para o registro da operação, bem como para o preenchimento dos cadastros. CNJ - Provimento 88/2019.` },
      { id: 960, nome : `Art. 20-X - a operação injustificadamente complexa ou com custos mais elevados, que visem dificultar o rastreamento dos recursos ou a identificação do seu real objetivo. CNJ - Provimento 88/2019.` },
      { id: 961, nome : `Art. 20-XI - a operação fictícia ou com indícios de valores incompatíveis com os de mercado. CNJ - Provimento 88/2019.` },
      { id: 962, nome : `Art. 20-XII - a operação com cláusulas que estabeleçam condições incompatíveis com as praticadas no mercado. CNJ - Provimento 88/2019.` },
      { id: 963, nome : `Art. 20-XIII - qualquer tentativa de burlar os controles e registros exigidos pela legislação de prevenção à lavagem de dinheiro e ao financiamento do terrorismo, através de fracionamento, pagamento em espécie ou por meio de título emitido ao portador. CNJ - Provimento 88/2019.` },
      { id: 964, nome : `Art. 20-XIV - o registro de documentos de procedência estrangeira, nos termos do art. 129, 6º, c/c o art. 48 da Lei n. 6.015, de 31 de dezembro de 1973. CNJ - Provimento 88/2019.` },
      { id: 965, nome : `Art. 20-XV - a operação que indique substancial ganho de capital em um curto período de tempo. CNJ - Provimento 88/2019.` },
      { id: 966, nome : `Art. 20-XVI - a operação que envolva a expedição ou utilização de instrumento de procuração que outorgue poderes de administração, de gerência dos negócios, ou de movimentação de conta corrente vinculada de empresário individual, sociedade empresária ou cooperativa. CNJ - Provimento 88/2019.` },
      { id: 967, nome : `Art. 20-XVII - as operações de aumento de capital social quando pelas partes envolvidas no ato, ou as características do empreendimento, verificar-se indícios de que o referido aumento não possui correspondência com o valor ou o patrimônio da empresa. CNJ - Provimento 88/2019.` },
      { id: 968, nome : `Art. 20-XVIII - quaisquer outras operações que, considerando as partes e demais envolvidos, os valores, modo de realização e meio e forma de pagamento, ou a falta de fundamento econômico ou legal, possam configurar sérios indícios da ocorrência dos crimes de lavagem de dinheiro ou de financiamento do terrorismo, ou com eles relacionar-se. CNJ - Provimento 88/2019.` },
      { id: 969, nome : `Art. 20-XIX - outras situações designadas em instruções complementares a este provimento. CNJ - Provimento 88/2019.` },
      { id: 970, nome : `Art. 23-I - qualquer operação que envolva o pagamento ou recebimento de valor em espécie, igual ou superior a R$ 30.000,00 (trinta mil reais) ou equivalente em outra moeda, desde que perante o tabelião. CNJ - Provimento 88/2019.` },
      // { id: 971, nome : `Art. 23-II - qualquer operação que envolva o pagamento ou recebimento de valor, por meio de título de crédito emitido ao portador, igual ou superior a R$ 30.000,00 (trinta mil reais), desde que perante o tabelião. CNJ - Provimento 88/2019.` },
      // { id: 972, nome : `Art. 24 - pagamentos ou cancelamentos de títulos protestados em valor igual ou superior a R$ 1.000.000,00 (um milhão de reais), não relacionados ao mercado financeiro, mercado de capitais ou entes públicos. CNJ - Provimento 88/2019.` },
      { id: 973, nome : `Art. 25-I - registro de transmissões sucessivas do mesmo bem, em período não superior a 6 (seis) meses, se a diferença entre os valores declarados for superior a 50%. CNJ - Provimento 88/2019.` },
      { id: 974, nome : `Art. 25-II - registro de título no qual constem diferenças entre o valor da avaliação fiscal do bem e o valor declarado, ou entre o valor patrimonial e o valor declarado (superior ou inferior), superiores a 100%. CNJ - Provimento 88/2019.` },
      { id: 975, nome : `Art. 25-III - registro de documento ou título em que conste declaração das partes de que foi realizado pagamento em espécie ou título de crédito ao portador de valores igual ou superior a R$ 30.000,00 (trinta mil reais). CNJ - Provimento 88/2019.` },
      { id: 976, nome : `Art. 26-I - doações de bens imóveis ou direitos reais sobre bens imóveis para terceiros sem vínculo familiar aparente com o doador, referente a bem imóvel que tenha valor venal atribuído pelo município igual ou superior a R$100.000,00 (cem mil reais). CNJ - Provimento 88/2019.` },
      { id: 977, nome : `Art. 26-II - concessão de empréstimos hipotecários ou com alienação fiduciária entre particulares. CNJ - Provimento 88/2019.` },
      { id: 978, nome : `Art. 26-III - registro de negócios celebrados por sociedades que tenham sido dissolvidas e tenham regressado à atividade. CNJ - Provimento 88/2019.` },
      { id: 979, nome : `Art. 26-IV - registro de aquisição de imóveis por fundações e associações, quando as características do negócio não se coadunem com as finalidades prosseguidas por aquelas pessoas jurídicas. CNJ - Provimento 88/2019.` },
      { id: 980, nome : `Art. 27 - operações que envolvam o pagamento ou recebimento de valor igual ou superior a R$ 50.000,00 (cinquenta mil reais) ou equivalente em outra moeda, inclusive quando se relacionar à compra ou venda de bens móveis e imóveis. CNJ - Provimento 88/2019.` },
      { id: 981, nome : `Art. 28-I - registro de quaisquer documentos que se refiram a transferências de bens imóveis de qualquer valor, de transferências de cotas ou participações societárias, de transferências de bens móveis de valor superior a R$ 30.000,00. CNJ - Provimento 88/2019.` },
      { id: 982, nome : `Art. 28-II - registro de quaisquer documentos que se refiram a mútuos concedidos ou contraídos ou doações concedidas ou recebidas, de valor superior ao equivalente a R$ 30.000,00. CNJ - Provimento 88/2019.` },
      { id: 983, nome : `Art. 28-III - registro de quaisquer documentos que se refiram, ainda que indiretamente, a participações, investimentos ou representações de pessoas naturais ou jurídicas brasileiras em entidades estrangeiras, especialmente “trusts” ou fundações. CNJ - Provimento 88/2019.` },
      { id: 984, nome : `Art. 28-IV - registro de instrumentos que prevejam a cessão de direito de títulos de créditos ou de títulos públicos de valor igual ou superior a R$ 500.000,00 (quinhentos mil reais). CNJ - Provimento 88/2019.` },
      // { id: 985, nome : `Art. 35 - lavratura de procuração que outorgue plenos poderes de gestão empresarial, conferida em caráter irrevogável ou irretratável ou quando isenta de prestação de contas, independentemente de ser em causa própria, ou ainda, de ser ou não por prazo indeterminado. CNJ - Provimento 88/2019.` },
      // { id: 986, nome : `Art. 36-I - qualquer operação que envolva o pagamento ou recebimento de valor em espécie igual ou superior a R$ 30.000,00 (trinta mil reais) ou equivalente em outra moeda, em espécie, inclusive a compra ou venda de bens móveis ou imóveis. CNJ - Provimento 88/2019.` },
      // { id: 987, nome : `Art. 36-II - qualquer operação que envolva o pagamento ou recebimento de valor igual ou superior a R$ 30.000,00 (trinta mil reais), por meio de título de crédito emitido ao portador, inclusive a compra ou venda de bens móveis ou imóveis. CNJ - Provimento 88/2019.` },
      // { id: 988, nome : `Art. 36-III - qualquer das hipóteses previstas em resolução da Unidade de Inteligência Financeira - UIF que disponha sobre procedimentos a serem observados pelas pessoas físicas e jurídicas por ela reguladas relativamente a operações ou propostas de operações ligadas ao terrorismo ou seu financiamento. CNJ - Provimento 88/2019.` },
      // { id: 989, nome : `Art. 36-IV - qualquer operação ou conjunto de operações relativas a bens móveis de luxo ou alto valor, assim considerados os de valor igual ou superior a R$ 300.000,00 (trezentos mil reais), ou equivalente em outra moeda. CNJ - Provimento 88/2019.` },
      // { id: 990, nome : `Art. 36-V - escritura pública - registro de transmissões sucessivas do mesmo bem, em período não superior a 6 (seis) meses, se a diferença entre os valores declarados for superior a 50%. CNJ - Provimento 88/2019 - art. 25-I.` },
      // { id: 991, nome : `Art. 36-V - escritura pública - registro de título no qual constem diferenças entre o valor da avaliação fiscal do bem e o valor declarado, ou entre o valor patrimonial e o valor declarado (superior ou inferior), superiores a 100%;. CNJ - Provimento 88/2019 - art. 25-II.` },
      // { id: 992, nome : `Art. 36-V - escritura pública - registro de documento ou título em que conste declaração das partes de que foi realizado pagamento em espécie ou título de crédito ao portador de valores igual ou superior a R$ 30.000,00 (trinta mil reais).. CNJ - Provimento 88/2019 - art. 25-II.` },
    ];
  },

  tiposOcorrenciaProvimento161(){
    return [
      /* [RI, RTD e RCPJ] */
      { id: 1356, nome: `Art. 155-A, I - aparentem não decorrer de atividades ou negócios usuais do cliente, de outros envolvidos ou do seu ramo de atuação; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1357, nome: `Art. 155-A, II - tenham origem ou fundamentação econômica ou legal não claramente aferíveis; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1358, nome: `Art. 155-A, III - se mostrem incompatíveis com o patrimônio ou com a capacidade econômico-financeira do cliente ou de outros envolvidos; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1359, nome: `Art. 155-A, IV - envolvam difícil ou inviável identificação de beneficiário(s) final(is); CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1360, nome: `Art. 155-A, V - se relacionem a pessoa jurídica domiciliada em jurisdição listada pelo Grupo de Ação Financeira (Gafi) como de alto risco ou com deficiências estratégicas em matéria de PLD/FTP; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1361, nome: `Art. 155-A, VI - envolvam países ou dependências listados pela RFB como de tributação favorecida e/ou regime fiscal privilegiado; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1362, nome: `Art. 155-A, VII - se relacionem a pessoa jurídica cujos sócios, administradores, beneficiários finais, procuradores ou representantes legais mantenham domicílio em jurisdições consideradas pelo Gafi de alto risco ou com deficiências estratégicas em matéria de PLD/FTP; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1363, nome: `Art. 155-A, VIII - apresentem, por parte de cliente ou demais envolvidos, resistência ao fornecimento de informação ou documentação solicitada para fins relacionados ao disposto neste Capítulo; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1364, nome: `Art. 155-A, IX - envolvam a prestação, por parte de cliente ou demais envolvidos, de informação ou documentação falsa ou de difícil ou onerosa verificação; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024) ` },
      { id: 1365, nome: `Art. 155-A, X - se mostrem injustificadamente mais complexas ou onerosas que de ordinário, mormente se isso puder dificultar o rastreamento de recursos ou a identificação de real propósito; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n.161, de 11.3.2024)` },
      { id: 1366, nome: `Art. 155-A, XI - apresentem sinais de caráter fictício ou de relação com valores incompatíveis com os de mercado; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1367, nome: `Art. 155-A, XII - envolvam cláusulas que estabeleçam condições incompatíveis com as praticadas no mercado; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1368, nome: `Art. 155-A, XIII - aparentem tentativa de burlar controles e registros exigidos pela legislação de PLD/FTP, inclusive mediante fracionamento ou pagamento em espécie, com título emitido ao portador ou por outros meios que dificultem a rastreabilidade; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1369, nome: `Art. 155-A, XIV - envolvam o registro de documento de procedência estrangeira, nos termos do art. 129, 6º, combinado com o art. 148 da Lei n. 6.015, de 31 de dezembro de 1973, que ofereçam dificuldade significativa para a compreensão do seu sentido jurídico no contexto da atividade notarial ou registral de que se trate; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1370, nome: `Art. 155-A, XV - revelem substancial ganho de capital em curto período; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1371, nome: `Art. 155-A, XVI - envolvam lavratura ou utilização de instrumento de procuração que outorgue amplos poderes de administração de pessoa jurídica ou de gestão empresarial, de gerência de negócios ou de movimentação de conta bancária, de pagamento ou de natureza semelhante, especialmente quando conferidos em caráter irrevogável ou irretratável ou isento de prestação de contas, independentemente de se tratar, ou não, de procuração em causa própria ou por prazo indeterminado; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1372, nome: `Art. 155-A, XVII - revelem operações de aumento de capital social que pareçam destoar dos efetivos atributos de valor, patrimônio ou outros aspectos relacionados às condições econômico financeiras da sociedade, diante de circunstâncias como, por exemplo, partes envolvidas no ato ou características do empreendimento; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1373, nome: `Art. 155-A, XVIII - quaisquer outras operações, propostas de operação ou situações que, considerando suas características, especialmente partes, demais envolvidos, valores, modo de realização, meios e formas de pagamento, falta de fundamento econômico ou legal ou, ainda, incompatibilidade com práticas de mercado, possam configurar sérios indícios de práticas de LD/FTP ou de infrações que com elas se relacionem. CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1374, nome: `Art. 155-A [...] Parágrafo único, I: Parágrafo único - Na hipótese do caput deste artigo, o notário e o registrador também atentarão para operações, propostas de operação ou situações que: I - revelem emprego não usual de meio ou forma de pagamento que possa viabilizar anonimato ou dificultar a rastreabilidade de movimentação de valores ou a identificação de quem a tenha realizado, como o uso de valores anormalmente elevados em espécie ou na forma de título emitido ao portador ou, ainda, de ativo virtual não vinculado nominalmente a quem o movimente; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n.161, de 11.3.2024)` },
      { id: 1375, nome: `Art. 155-A [...] Parágrafo único, II: Parágrafo único - Na hipótese do caput deste artigo, o notário e o registrador também atentarão para operações, propostas de operação ou situações que: II - apresentem algum sinal de possível relação, direta ou indireta, com práticas de terrorismo ou proliferação de armas de destruição em massa ou com seus financiamentos, inclusive em hipóteses correlatas eventualmente contempladas em atos normativos da UIF. CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },

      /* [RI] */
      { id: 1379, nome: `Art. 161. O oficial de registro de imóveis, ou seu oficial de cumprimento, comunicará à UIF, na forma do art. 151, II, registro de documento ou título em que conste declaração das partes de que foi realizado pagamento em espécie, ou por título ao portador, de valor igual ou superior a R$ 100.000,00 (cem mil reais) ou ao equivalente em outra moeda. CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1380, nome: `Art. 162, I - doações de bens imóveis ou direitos reais sobre bens imóveis para terceiros sem vínculo familiar aparente com o doador, referente a bem imóvel que tenha valor venal atribuído pelo município igual ou superior a R$ 100.000,00 (cem mil reais); CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1381, nome: `Art. 162, II - concessão de empréstimos hipotecários ou com alienação fiduciária entre particulares; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1382, nome: `Art. 162, III - registro de negócios celebrados por sociedades que tenham sido dissolvidas e tenham regressado à atividade; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1383, nome: `Art. 162, IV - registro de aquisição de imóveis por fundações e associações, quando as características do negócio não se coadunem com suas finalidades; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1384, nome: `Art. 162, V - registro de transmissões sucessivas do mesmo bem em período e com diferença de valor anormais; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1385, nome: `Art. 162, VI - registro de título no qual conste valor declarado de bem com diferença anormal em relação a outros valores a ele associados, como o de sua avaliação fiscal ou o valor patrimonial pelo qual tenha sido considerado para fins sucessórios ou de integralização de capital de sociedade, por exemplo. CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },

      /* [RTD & RCPJ] */
      { id: 1386, nome: `Art. 163. O oficial de registro de títulos e documentos e de registro civil das pessoas jurídicas, ou seu oficial de cumprimento, comunicará à UIF, na forma do art. 151, II, qualquer operação que envolva pagamento ou recebimento em espécie, ou por título ao portador, de valor igual ou superior a R$ 100.000,00 (cem mil reais) ou ao equivalente em outra moeda, inclusive quando se relacionar à compra ou venda de bens móveis ou imóveis. CNJ -Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1387, nome: `Art. 164, I - transferências de bens imóveis de qualquer valor, de cotas ou participações societárias ou de bens móveis de valor superior a R$ 100.000,00 (cem mil reais); CNJ - Provimento CN n.149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1388, nome: `Art. 164, II - mútuos concedidos ou contraídos ou doações concedidas ou recebidas de valor superior ao equivalente a R$ 100.000,00 (cem mil reais); CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1389, nome: `Art. 164, III - participações, investimentos ou representações de pessoas naturais ou jurídicas brasileiras em entidades estrangeiras, especialmente trusts, arranjos semelhantes ou fundações; CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` },
      { id: 1390, nome: `Art. 164, IV - cessão de direito de títulos de créditos ou de títulos públicos de valor igual ou superior a R$ 500.000,00 (quinhentos mil reais). CNJ - Provimento CN n. 149/2023 (incluído pelo Provimento CN n. 161, de 11.3.2024)` }
    ];
  },

  getTiposOcorrencia(dominio, dto) {
    if (['AGUARDANDO_ANALISE', 'AGUARDANDO_COMUNICACAO', 'NAO_COMUNICAR'].includes(dto.status) || !dto.id) {
      if (DominioBusiness.isDominioRI(dominio)) {
        return this.tiposOcorrenciaProvimento161().filter(t => ![1386, 1387, 1388, 1389, 1390].includes(t.id));
      }
      if (DominioBusiness.isDominioRCPJ(dominio) || DominioBusiness.isDominioRTD(dominio)) {
        return this.tiposOcorrenciaProvimento161().filter(t => ![1379, 1380, 1381, 1382, 1383, 1384, 1385].includes(t.id));
      }
    } else {
      if (DominioBusiness.isDominioRI(dominio)) {
        return this.tiposOcorrenciaProvimento88().filter(t => ![980, 981, 982, 983, 984].includes(t.id))
      }
      if (DominioBusiness.isDominioRCPJ(dominio) || DominioBusiness.isDominioRTD(dominio)) {
        return this.tiposOcorrenciaProvimento88().filter(t => ![970, 973, 974, 975, 976, 977, 978, 979].includes(t.id))
      }
    }
    return []
  },

  getAllTiposOcorrencia() {
    return this.tiposOcorrenciaProvimento88().concat(this.tiposOcorrenciaProvimento161());
  },

  status(){
    return [
      {id: 'AGUARDANDO_ANALISE', nome: 'Aguardando Análise'},
      {id: 'AGUARDANDO_COMUNICACAO', nome: 'Aguardando Comunicação'},
      {id: 'AGUARDANDO_RECIBO', nome: 'Aguardando Recibo'},
      {id: 'COMUNICADO', nome: 'Comunicado'},
      {id: 'NAO_COMUNICAR', nome: 'Não Comunicar'},
    ]
  },

  getNomeStatus(id){
    return Utils.clone(this.status).filter(status => status.id === id)[0]?.nome;
  },

  papeis(){
    return [
      { id : 'BENEFICIARIO_FINAL', nome : 'Beneficiário Final'},
      { id : 'COMPRADOR', nome : 'Comprador'},
      { id : 'OUTORGADO', nome : 'Outorgado'},
      { id : 'OUTORGANTE', nome : 'Outorgante'},
      { id : 'OUTROS', nome : 'Outros'},
      { id : 'PROCURADOR', nome : 'Procurador'},
      { id : 'REPRESENTANTE', nome : 'Representante'},
      { id : 'TITULAR', nome : 'Titular'},
      { id : 'VENDEDOR', nome : 'Vendedor'}
    ]
  },

  requiredFields() {
    return ['inicio', 'fim', 'valor', 'cidade', 'estado', 'descricao', 'envolvidos', 'ocorrencias'];
  }

}

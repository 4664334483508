<template>

  <modal :titulo="titulo" :loading="loading">
    <card titulo="Inserir Indicador em Lote?">
      <v-input v-model="form.lote" type="radio" class="col" />
    </card>
    <card titulo="Insira os valores">

      <div class="row gutters-xs" v-if="form.lote">
        <v-input class="col" :error="validation.codigoInicio" label="Código Início" v-model="form.codigoInicio" type="currency" :input-props="{precision : 0}" />
        <v-input class="col" :error="validation.codigoFim" label="Código Fim" v-model="form.codigoFim" type="currency" :input-props="{precision : 0}" />
      </div>

      <div class="row gutters-xs" v-else>
        <v-input class="col" :error="validation.codigo" label="Código" v-model="form.codigo" type="currency" :input-props="{precision : 0}" />
        <v-input class="col" v-if="!form.lote && enableLivroLetra" v-model="form.letra" type="text" label="Letra" />
      </div>

      <div class="row gutters-xs" v-if="['REGISTRO_TD', 'REGISTRO_PJ'].includes(livro)">
        <v-input :error="validation.tipoRegistro" v-model="form.tipoRegistro" :options="options.tiposRegistros"
                 type="select" class="col" label="Tipo de Registro" />
      </div>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" :popover="true" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import Utils from "@/commons/Utils.js";
import utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal.vue";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness";
import RegistroPJBusiness from "@/business/indicadores/RegistroPJBusiness";
import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness";

export default {
  name: "ModalFichaAntiga",
  modal:{
    width: 450
  },
  components: {Card, Modal},
  props:{
    livro:String
  },

  data(){
    return {
      loading: false,
      validation:{},
      form:{
        lote: false,
        codigo: null,
        codigoInicio: null,
        codigoFim: null,
        letra: null
      },
      options: {
        tiposRegistros: [],
      }
    }
  },

  created() {
    if (this.isRegistroTDPJ) {
      this.options.tiposRegistros = ['REGISTRO_TD'].includes(this.livro) ? RegistroTDBusiness.getTipos() : RegistroPJBusiness.getTipos();
    }
  },

  computed:{
    titulo() {
      return `Cadastrar ${IndicadorBusiness.getLivro(this.livro).nome} Manualmente`;
    },

    enableLivroLetra(){
      if(this.livro === 'REGISTRO_AUXILIAR'){
        return this.$root.config.indicadorReal.habilitarLivroLetraRegistroAuxiliar
      } else {
        return this.$root.config.indicadorReal.habilitarLivroLetraMatricula
      }
    },

    isRegistroTDPJ(){
      return ['REGISTRO_TD', 'REGISTRO_PJ'].includes(this.livro);
    }
  },

  methods:{

    async salvar(){
      this.validation = {}
      let validation = true;
      let dto = utils.clone(this.form);
      console.debug(dto)

      if (dto.lote) {
        if (!dto?.codigoInicio || !Number.isInteger(dto.codigoInicio) || dto.codigoInicio > 999999) {
          this.validation.codigoInicio = 'Informe um valor inicial númerico';
          validation = false;
        }
        if (!dto?.codigoFim || !Number.isInteger(dto.codigoFim) || dto.codigoFim > 999999) {
          this.validation.codigoFim = 'Informe um valor final númerico';
          validation = false;
        }

        if (dto?.codigoInicio > dto?.codigoFim) {
          this.validation.codigoInicio = 'Valor inicial não pode ser maior que o final';
          validation = false;
        }

        dto.codigo = dto.codigoInicio + ' - ' + dto.codigoFim;
      }else if(!dto?.codigo || !Number.isInteger(dto.codigo) || dto.codigo > 999999) {
        this.validation.codigo = 'Informe um valor númerico';
        validation = false;
      }


      if(dto?.codigo && validation){
        let codigos = utils.stringCodigosToArray(dto?.codigo)
        dto = codigos.map((c) => {
          return {
            codigo: c,
            letra: !dto.lote ? dto.letra : null,
            tipoRegistro: dto?.tipoRegistro,
          }
        })

        let r;
        let mensagemPlural = "Registros salvos com sucesso.";
        let mensagemSingular = "Registro salvo com sucesso.";
        switch (this.livro) {
          case 'MATRICULA':
            r = await this.$api.post(`api/indicador-real/retroativa`, dto).catch(this.showError);
            mensagemPlural = "Matrículas salvas com sucesso.";
            mensagemSingular = "Matrícula salva com sucesso.";
            break;
          case 'REGISTRO_AUXILIAR':
            r = await this.$api.post(`api/registro-auxiliar/retroativa`, dto).catch(this.showError);
            break;
          case 'REGISTRO_TD': {
            r = await this.$api.post(`api/v2/registro-td/retroativa`, dto).catch(this.showError);
            break;
          }
          case 'REGISTRO_PJ': {
            r = await this.$api.post(`api/v2/registro-pj/retroativa`, dto).catch(this.showError);
            break;
          }
        }

        if(r){
          if (codigos.length > 1) {
            FrontBusiness.showSuccess('', mensagemPlural);
            this.close(null);
          } else if (typeof r === 'string') {
            FrontBusiness.showSuccess('', mensagemSingular);
            this.close(r);
          }
        }
      }
    },

    close(response) {
      FrontBusiness.closeModal(response);
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '');
    },

  }

}
</script>

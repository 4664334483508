<template>
  <modal titulo="Anexar Documentos">
    <anexos-wrapper nome="Anexos" :entidade="entidade" :id="id" :permitir-edicao="permitirEdicao" />
  </modal>
</template>

<script>

import {defineComponent} from "vue";
import Modal from "@/components/Modal.vue";
import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";

export default defineComponent({
  components: {AnexosWrapper, Modal},
  props: {
    id: String,
    entidade: String,
    permitirEdicao: Boolean
  }
})
</script>

<style>

</style>

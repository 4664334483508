<script>

import SeloBusiness from "@/business/SeloBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";

export default {
    name: "ModalSelosPendentes",
    components: {CardTable, Card, Modal},

    props: {
      tipo: String,
      id: String
    },

    data() {
      return {
        selosPendentes: [],
        loading: true
      }
    },

    computed:{
      titulo(){
        return `Selos Pendentes [${this.selosPendentes?.length || 0}]`
      }
    },

    async beforeMount() {

      switch (this.tipo.toString().toLowerCase()) {
        case 'certidao':
          this.selosPendentes = await SeloBusiness.getSelosPendentesCertidao(this.id);
          break;
        case 'ato':
          this.selosPendentes = await SeloBusiness.getSelosPendentesAto(this.id);
          break;
        case 'prenotacao':
          this.selosPendentes = await SeloBusiness.getSelosPendentesPrenotacao(this.id);
          break;
        case 'protocolo':
          this.selosPendentes = await SeloBusiness.getSelosPendentesProtocolo(this.id);
          break;
        case 'busca':
          this.selosPendentes = await SeloBusiness.getSelosPendentesBuscas(this.id);
          break;
        case 'custa-adicional':
          this.selosPendentes = await SeloBusiness.getSelosPendentesCustasAdicionais(this.id);
          break;
      }

      this.loading = false;

    },

  }
</script>

<template>
  <modal :titulo="titulo" :loading="loading">

    <card>
      <card-table class="table-borderless">
        <thead>
          <tr>
            <th width="140px">Origem</th>
            <th>Descrição</th>
            <th width="150px">Verificação</th>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="linha in selosPendentes" :key="linha.id" :class="{'menos-destaque' : linha.convertido}">

            <td>
              <b>{{linha.tipo == 'CERTIDAO' ? `Certidão ${linha.certidao}` : `Protocolo ${linha.protocolo}`}}</b>
              <div>{{linha.geracao | data}}</div>
            </td>

            <td :class="{'text-success' : linha.principal}" :title="`${linha.principal ? 'Selo Principal (Pai)' : ''}`">
              <b>{{linha.codigoAto}}</b> - {{linha.descricao }}
            </td>

            <td v-if="linha.convertido" colspan="2">
              Selo convertido: {{linha.convertido | datahora }}
            </td>

            <template v-else>
              <td>{{linha.tentativa | datahora }}</td>
              <td class="small">{{linha.log }}</td>
            </template>

          </tr>
        </tbody>
      </card-table>
    </card>

  </modal>

</template>


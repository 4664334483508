<template>

  <div>

    <card titulo="Documentação">

      <template #acoes>
        <a class="text-secondary ml-1" href title="Adicionar Documentação" @click.prevent="formulario()">
          <i class="fas fa-plus"></i>
        </a>

        <a class="icon float-right" @click.prevent="filtros = !filtros" href title="Filtrar Consulta">
          <i class="fal fa-filter"></i>
        </a>
      </template>

      <card chave="principal" class="filtros-consulta" key="principal" titulo="Filtrar Consulta" v-if="filtros">
        <card-content>
          <form @submit.prevent="load(true)" @keyup.enter="load(true)">
            <div class="w-100 row gutters-xs">
              <v-input :options="filtroTipo" class="form-group col-3 input-select"
                       label="Tipo" type="select" v-model="form.tipo"/>
              <v-input :options="filtroSituacao" class="form-group col-3 input-select"
                       label="Situação" type="select" v-model="form.situacao"/>
              <template v-if="form.tipo === 'PROCURACAO'">
                <v-input type="text" class="col-4" label="Livro"
                         v-model="form.livro"/>
                <v-input type="text" class="col-4" label="Folha"
                         v-model="form.folha"/>
                <v-input type="date" class="col-4" label="Lavratura"
                         v-model="form.lavratura"/>
                <v-input type="date" class="col-4" label="Vencimento"
                         v-model="form.vencimento"/>
              </template>
              <template v-if="form.tipo === 'CONTRATO_SOCIAL'">
                <v-input type="text" class="col-3" label="Nº Registro"
                         v-model="form.numeroRegistro"/>
                <v-input type="date" class="col-4" label="Data Registro"
                         v-model="form.registro"/>
              </template>
              <div class="w-100"></div>
              <v-input type="text" label="CPF/CNPJ" v-model="form.documento" class="col-3" />
              <v-input type="text" label="Nome" v-model="form.nome" class="col-5" />
              <v-input label="Papel" v-model="form.papel" class="col-4" type="select" ref="papel" :options="papeis" />
              <div class="col-auto">
                <button class="btn btn-secondary mt-4" type="submit">Filtrar</button>
              </div>
            </div>
          </form>

        </card-content>
      </card>

      <card-table class="table-borderless mb-2" v-if="documentacoes.length > 0">

        <thead>
          <tr>
            <th>Código</th>
            <th>Tipo</th>
            <th v-if="tipo === 'PROCURACAO'">Livro</th>
            <th v-if="tipo === 'PROCURACAO'">Folha</th>
            <th v-if="tipo === 'PROCURACAO'">Lavratura</th>
            <th v-if="tipo === 'CONTRATO_SOCIAL'">Nº Registro</th>
            <th v-if="tipo === 'CONTRATO_SOCIAL'">Registro</th>
            <th>Vencimento</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>

          <tr v-for="documentacao in documentacoes" :key="documentacao.id">
            <td>{{documentacao.codigo}}</td>
            <td>{{labelTipo(documentacao.tipo)}}  <span class="badge badge-red-soft mb-0" v-if="!documentacao.ativo" :title="Desativado">Desativado</span></td>
            <td v-if="tipo === 'PROCURACAO'" width="80px">{{documentacao.livro}}</td>
            <td v-if="tipo === 'PROCURACAO'" width="80px">{{documentacao.folha}}</td>
            <td v-if="tipo === 'PROCURACAO'" width="100px">{{$formatters.date(documentacao.lavratura)}}</td>
            <td v-if="tipo === 'CONTRATO_SOCIAL'" width="100px">{{documentacao.numero_registro}}</td>
            <td v-if="tipo === 'CONTRATO_SOCIAL'" width="100px">{{$formatters.date(documentacao.registro)}}</td>
            <td width="100px">{{$formatters.date(documentacao.vencimento)}}</td>
            <td width="40px">
              <!--            <router-link :to="{ name: 'digitalizacao', params: { id: documentacao.id, entidade : 'documentacao' }}" target="_blank" title="Digitalização" class="ml-2 icon small">-->
              <!--              <i class="fal fa-file-image"></i>-->
              <!--            </router-link>-->
            </td>
            <td width="40px">
              <a title="Editar" class="pointer" @click.prevent="formulario(documentacao)"><i class="fal fa-edit"></i></a>
            </td>
          </tr>

        </tbody>

      </card-table>

      <small-loading v-if="loading" ></small-loading>

      <div v-if="!documentacoes.length && !loading" class="alert alert-outline-info">
        Não há documentação para exibir
      </div>

    </card>

    <anexos-wrapper nome="Outros Anexos" entidade="INDICADOR_PESSOAL" :id="pessoa.indicadorPessoalId" />

  </div>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import DocumentacaoBusiness from "@/business/indicadores/DocumentacaoBusiness";
  import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";
  import DocumentacaoFormulario from "@/components/IndicadorPessoal/Detalhes/DocumentacaoFormulario";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";

  export default {
    name: "Documentacao",
    components: {AnexosWrapper, SmallLoading, CardTable, CardContent, Card},
    props: {
      pessoa : Object
    },

    mounted(){
      this.load();
    },

    data() {
      return {
        documentacoes : [],
        loading: true,
        filtros: false,
        tipo: '',
        form: {
          tipo: "",
          situacao: "ATIVOS",
        },
        filtroTipo: [{id: '', nome: 'Todos'}, ...DocumentacaoBusiness.tipos()],
        filtroSituacao: [{id: 'TODOS', nome: 'Todos'}, {id: 'ATIVOS', nome: 'Ativos'}, {id: 'DESATIVADOS', nome: 'Desativados'}],
        papeis: DocumentacaoBusiness.getPapeisEnvolvidos()
      }
    },

    watch: {
      'form.tipo': function (newValue) {
        this.$set(this, 'form', {tipo: newValue, situacao: this.form?.situacao});
      }
    },
    methods:{

      async formulario(documentacao = {}){
        await FrontBusiness.openModal(DocumentacaoFormulario, { id : documentacao.id, envolvido: this.pessoa });
        await this.load();
      },

      async load(filtro = false){
        if (filtro) this.filtros = !this.filtros;
        this.loading = true;
        this.$set(this, 'tipo', this.form.tipo)
        this.documentacoes = await DocumentacaoBusiness.listar(this.pessoa.indicadorPessoalId, this.form);
        this.loading = false;
      },

      labelTipo(tipo) {
        return FrontBusiness.getLabel(tipo, DocumentacaoBusiness.tipos());
      },


    }
  }
</script>

<style lang=scss>

</style>

import Http from "@/commons/Http";
const path = 'api/restricao';

export default {

  async buscarRestricao(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/restricoes-ficha?sort=${sort || 'a.codigo'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async buscarRestricaoPessoa(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/restricoes-pessoa?sort=${sort || 'r.codigo'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  restricoesPorPapel(papel){
    switch (papel) {
      case 'EMITENTE' :
      case 'CREDOR' :
      case 'GARANTIDOR' :
      case 'FIEL_DEPOSITARIO':
          return ['ONUS', 'ACAO', 'INDISPONIBILIDADE'];
      case 'PROMITENTE_VENDEDOR' :
      case 'PROMITENTE_COMPRADOR' :
          return ['COMPROMISSO'];
      case 'AUTOR' :
      case 'REU' :
        return ['ACAO'];
      case 'DEVEDOR' :
        return ['ONUS', 'ACAO', 'INDISPONIBILIDADE', 'DOMINIO'];
      }
    },

  papeisGeraRestricao(){
    return ['EMITENTE', 'CREDOR', 'DEVEDOR', 'GARANTIDOR', 'AUTOR', 'REU', 'PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR', 'FIEL_DEPOSITARIO']
  },

  tratarRestricoes(r){
    if(r.restricoes) r.restricoes = JSON.parse(r.restricoes);
    if(r.devedores) {
      r.devedores = JSON.parse(r.devedores);
      let documentos = [];
      documentos.push(...new Set(r.devedores.map(devedor => devedor.documento)));
      r.devedores = r.devedores.filter(devedor => {
        if(documentos.includes(devedor.documento)) {
          documentos = documentos.filter((r => r !== devedor.documento));
          return true;
        }
        return false;
      })
    }
    return r;
  },

}

<template>
  <a :href="link" :title="title" :target="target">
    <slot name="conteudo"></slot>
  </a>
</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness";
import AsgardStorageBusiness from "@/business/cloudStorage/AsgardStorageBusiness.js";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";

export default {
  name: "AnexoWrapperDownload",

  props:{
    anexo: {type: Object, default: () => {}},
    title: {type: String, default: ''},
    target: {type: String, default: ''},
    assinado: {type: Boolean, default: false},
    forcarDownload: {type: Boolean, default: true}
  },

  data(){
    return{
      link: '',
    }
  },

  async mounted() {
    this.link = AsgardStorageBusiness.getDownloadUrl(this.anexo, this.assinado);
  },

}
</script>

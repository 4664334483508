import Http from "@/commons/Http";
import AsgardStorageBusiness from "@/business/cloudStorage/AsgardStorageBusiness";

const path = 'api/anexo';

const cloudDB = {
  dados: null,
  frontCloudCommunication: null
}

export default {

  async getStorageBusiness(){
    // if(!cloudDB.dados){
    //   // cloudDB.dados = await this._getDadosCloud();
    //   // dadosCloud.frontCloudCommunication = await this._frontCloudCommunication(dadosCloud);
    // }
    // if(cloudDB?.dados?.frontCloudCommunication){
    //   switch (cloudDB?.dados?.cloudType){
    //     case 'AMAZON' : return AmazonStorageBusiness;
    //     case 'AZURE' : return AzureStorageBusiness;
    //     case 'MINIO' : return MinioStorageBusiness;
    //     default : return AsgardStorageBusiness;
    //   }
    // }
    return AsgardStorageBusiness;
  },

}

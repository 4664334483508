<template>

  <card titulo="Referências">

    <card-table class="table-borderless mb-1">

      <thead>
        <tr>
          <th width="180px">Origem</th>
          <th class="text-center" width="90px">Ato</th>
          <th class="text-center">Tipo</th>
          <th>Descrição</th>
          <th  width="15px;" v-if="!visualizar"></th>
          <th  width="15px;" v-if="!visualizar">
            <i class="fa fa-plus-square pointer float-right mr-2" @click="form()"></i>
          </th>
        </tr>
      </thead>

      <tbody>

        <tr v-for="(registro, i) in ficha.referencias" :key="i">
          <td class="pr-2 icon">
            <a @click.prevent="detalhesFichaIndicadorReal(registro.referenciaId)">{{nomearFicha(registro.referenciaDetalhes)}} </a>
          </td>
          <td class="text-center pr-2">{{registro.ato}}</td>
          <td class="text-center pr-2">{{formatarTipoReferencia(registro.tipo)}}</td>
          <td class="pr-2">{{registro.descricao}}</td>

          <td style="padding-left: 0px;" class="text-right pr-2" v-if="!visualizar">
            <a class="icon" title="Editar" @click.prevent="form(registro, i)">
              <i class="fa fa-edit"></i>
            </a>
          </td>

          <td style="padding-left: 0px;" class="text-right pr-2" v-if="!visualizar">
            <a class="icon" title="Excluir" @click.prevent="remove(i)">
              <i class="far fa-trash-alt"></i>
            </a>
          </td>
        </tr>
      </tbody>

    </card-table>
      <div v-if="!ficha.referencias || !ficha.referencias.length">
        <div class="p-3 text-truncate alert alert-warning">
          Nenhuma referência informada
        </div>
      </div>
  </card>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import Utils from "@/commons/Utils.js";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
  import ReferenciaImovelForm from "@/components/IndicadorReal/ReferenciaImovelForm.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";

  export default {

    name: "ReferenciaImovelLista",
    components: {Card, CardTable},
    props: {
      ficha: Object,
      visualizar: {type: Boolean, default: false},
      matriculaMae: Object
    },

    data() {
      return {
      }
    },

    mounted() {
      if(!this.ficha.referencias){
        this.ficha.referencias = [];
      }
    },

    methods: {

      async form(registro, index) {
        let response = await FrontBusiness.openModal(ReferenciaImovelForm, {registro: Utils.clone(registro), matriculaMae: this.matriculaMae});
        if (response) {
          const registros = this.ficha.referencias || [];
          if (index === null || index === undefined) {
            registros.push(response);
          } else {
            registros[index] = response;
          }
          this.$set(this.ficha, 'referencias', registros);
          this.$forceUpdate();
        }
        console.debug('response', this.ficha.referencias, response)
      },

      remove(index) {
        const registros = this.ficha.referencias || [];
        registros.splice(index, 1);
        this.$set(this.ficha, 'referencias', registros);
      },

      async detalhesFichaIndicadorReal(id){
        await FrontBusiness.openModal(DetalhesIndicadorReal, {id});
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      formatarTipoReferencia(tipo){
        return FrontBusiness.getLabel(tipo, IndicadorRealBusiness.getTiposReferencia());
      },

    }

  }
</script>

<template>

  <v-input v-model="ato.dto.configuracaoRestricao.restritiva" :disabled="((!!ato.registro && !edicao) && !ato.legado) || (ato.legado && !edicao) || !edicao"
           type="radio" class="col-3" :default="false"
           label="Restrição Restritiva?" v-if="mostrar"/>

</template>

<script>

    export default {
        name: "RestritivaRestricao",
        props: {
            ato: {},
            edicao: {default: false}
        },
        computed:{
          mostrar(){
            return this.ato.tipoServico?.opcoes?.restricaoRestritiva
          }
        }
    }
</script>

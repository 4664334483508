<template>
  <modal id="atos-versao" titulo="Atos da Versão">

    <card>
      <card-content class="mb-2" v-for="(ato, index) in atos" :key="ato.id">

        <p class="mb-2 small">
          <strong>
            {{ getCodigo(ato, index) }}
            <template v-if="ato.tipoServico && ato.tipoServico.nome">
              - {{ ato.tipoServico.nome }}
            </template>
          </strong>
          <span class="text-muted" v-if="ato.registro"> - {{ $formatters.datetime(ato.registro) }}</span>
        </p>

        <div v-html="ato.texto" class="texto-minuta mb-2" v-if="ato.texto"></div>
        <div class="texto-minuta mb-5 text-red" v-else>
          Ato sem minuta.
        </div>

      </card-content>

      <div v-if="!atos.length && !loading" class="alert alert-outline-info">
        Não há atos para exibir
      </div>

      <small-loading v-if="loading"></small-loading>

    </card>
  </modal>
</template>

<script>
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";
import SmallLoading from "@/components/SmallLoading.vue";

export default {
  name: "AtosVersao",
  components: {SmallLoading, CardContent, Card, Modal},
  props: {
    id: String,
    versao: Object
  },
  data() {
    return {
      atos: [],
      loading: true
    }
  },
  async mounted() {
    this.atos = await IndicadorPessoalBusiness.buscarAtosVersao(this.id);
    this.loading = false;
  },
  methods: {
    getCodigo: AtoBusiness.getCodigo
  }
}
</script>

<style lang=scss>

</style>

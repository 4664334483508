<script>

import FrontBusiness from "@/business/FrontBusiness";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils";
import AlertMessage from "@/components/alert/AlertConstMessage";
import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";
import AlertOcorrencia from "@/components/alert/AlertOcorrencia.vue";
import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
import Card from "@/components/Layout/components/Card.vue";

import EnvolvidosAto from "@/views/Protocolo/Atos/Ato/EnvolvidosAto";
import draggable from 'vuedraggable'
import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness";
import NotaExigencia from "@/components/Checklist/NotaExigencia";
import Toggle from "@/components/Layout/components/Toggle";
import FracaoTransferenciaImovelBusiness from "@/business/protocolo/FracaoTransferenciaImovelBusiness";
import EventBus from "@/commons/EventBus";
import CalcularPorcentagem from "@/views/Protocolo/Atos/Ato/CalcularPorcentagem";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import CardTable from "@/components/Layout/components/CardTable.vue";

export default {

  name: "Envolvidos",

  components: {
    CardTable,
    AlertOcorrencia,
    AlertIndisponibilidade,
    Card, Toggle, draggable,
    ResponderChecklist: () => import("@/components/Protocolo/ResponderChecklist.vue"),
  },

  props: {
    'ato': {type: Object},
    'edicao': {type: Boolean},
    'protocolo': {type: Object},
    'ficha': {type: Object},
    'documentos': {type: Array},
    checklist: {type: Array, default: () => []}
  },

  beforeMount() {
    this.proprietarios = this.ficha?.proprietarios || [];
    Utils.orderFields(this.ato?.envolvidos, ['ordem', 'nome']);
    this.ordenar(true);
  },

  created() {
    this.getInventariante();
    this.getCnib();
    this.getRepresentante();
    EventBus.$on('ADD-SUGESTAO-ENVOLVIDOS', this.addSugestaoEnvolvidos);
  },

  data() {
    return {
      label: '',
      form: {},
      isDocumentoValido: false,
      papeisComFracao: ['ADQUIRENTE', 'TRANSMITENTE', 'DEVEDOR','GARANTIDOR', 'CREDOR', 'CEDENTE', 'CESSIONARIO', 'PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR', 'FIEL_DEPOSITARIO'],
      papeisParticipacaoDoi: ['ADQUIRENTE', 'TRANSMITENTE', 'CEDENTE', 'CESSIONARIO', 'PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'],
      proprietarios: [],
      indicadorPossuiIndisponibilidade : AlertMessage.indicadorPossuiIndisponibilidade(),
      indicadorPossuiOcorrencias : AlertMessage.indicadorPossuiOcorrencias(),
      mostrarChecklistEnvolvido: null,
      validacaoFracaoEnvolvido: [],
      papeisTransmitentes:['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'],
      papeisAdquirentes:['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR'],
      docRepresentante:null
    }
  },

  computed: {

    papelComFracao() {
      return this.ato.envolvidos
        .find(e => this.papeisComFracao.includes(e.papel));
    },

    transmitentes() {
      return this.ato.envolvidos
        .reduce((total, e) => total + (e.papel == 'TRANSMITENTE' || e.papel == 'CEDENTE' || e.papel == 'PROMITENTE_VENDEDOR' ? (e.fracao ? parseFloat(e.fracao) : 0) : 0), 0);
    },

    adquirentes() {
      return this.ato.envolvidos
        .reduce((total, e) => total + (['ADQUIRENTE', 'CESSIONARIO', 'PROMITENTE_COMPRADOR'].includes(e.papel) ? (e.fracao ? parseFloat(e.fracao) : 0) : 0), 0);
    },

    dominio(){
      return this.ato?.tipoServico?.dominio || this.protocolo?.dominio;
    },

    adquirenteValidaPorcentagemDOI() {
      return this.ato.envolvidos
        .find(e => e.ajusteDoi === true && ['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR'].includes(e.papel)) ? true : false;
    },

    transmitenteValidaPorcentagemDOI() {
      return this.ato.envolvidos
        .find(e => e.ajusteDoi === true && ['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(e.papel)) ? true : false;
    },

    papeis() {
      const proibidos = this.ato.tipoServico?.opcoes?.papeisProibidos || [];
      return IndicadorRealBusiness.getPapeisEnvolvidos({dominio: this.dominio}).filter(p => !proibidos.includes(p.id))
    },

    exibirFracao() {
      return ['MATRICULA', 'TRANSCRICAO', 'TORRENS'].includes(this.ato.livro);
    },

    exibirParticipacaoDoi(){
      return this.ato.tipoServico.opcoes.tipoTransacaoDoi !== 'NENHUM' && this.ato.doi.gerarDoi !== false;
    },

    unidadeArea(){
      return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
    }

  },

  watch: {
    'ato.envolvidos': function () {
      this.setMedidaAreaEnvolvidos();
      this.getInventariante();
      this.getRepresentante();
      this.calculoInicialParticipacaoDoi();
    }
  },

  methods: {
    async getInventariante() {
      await this.ato.envolvidos.map(async envolvido => {
        this.$set(envolvido, 'exibicao', this.inventarianteExibicao(envolvido));
        this.$set(envolvido, 'inventarianteVersao', envolvido.inventarianteVersao || {});
        this.$set(envolvido, 'editandoInventariante', await this.validarDocumentos(envolvido, true));
      });
    },

    async getRepresentante(){
      await this.ato.envolvidos.map(async envolvido => {
        this.$set(envolvido, 'editandoRepresentante', false);
        this.representantePrincipalExibicao(envolvido);
      })
    },

    async getCnib() {
      await this.ato.envolvidos.map(async envolvido => {
        this.$set(envolvido, 'consultaCnib', envolvido.consultaCnib);
        this.$set(envolvido, 'cnib', envolvido.consultaCnib);
      });
    },

    validacaoEnvolvido() {
      this.proprietarios.forEach(proprietario => {
        this.ato.envolvidos?.forEach((envolvido) => {
          let versaoDoProprietario = proprietario.indicadorPessoalVersao;
          let versaoDoEnvolvido = envolvido.indicadorPessoalVersao;
          if(versaoDoProprietario && versaoDoProprietario.documento === versaoDoEnvolvido.documento && versaoDoProprietario.indicadorPessoalId !== versaoDoEnvolvido.indicadorPessoalId && !Utils.isEmptyNullOrUndefined(versaoDoEnvolvido.documento)){
            envolvido.ALERTA_DOCUMENTO = `O proprietário (${versaoDoProprietario.nome}) possui o mesmo ${envolvido.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} que o envolvido (${envolvido.nome}) porém são indicadores diferentes`;
          }
        });
      });
    },

    ordenar(skipEmit = false) {

      this.$set(this.ato, 'envolvidos', this.ato.envolvidos.map((e, i) => {
        e.ordem = i + 1;
        return e;
      }));

      this.validacaoEnvolvido();

      if (!skipEmit) {
        this.$emit('checklist');
      }

    },

    async emitChange(i) {
      this.$emit('change-editor', {}, null, [true, false]);
      await this.$refs?.checklist?.[i]?.process();
    },

    remover(idx, atualizar = true) {
      this.ato.envolvidos.splice(idx, 1);
      this.ordenar();
      this.recalcularParticipacaoDoi();
      if (atualizar) this.emitChange();
    },

    duplicar(envolvido) {
      this.selecionarEnvolvido(envolvido?.indicadorPessoalVersao)
    },

    atualizar(i, envolvido) {
      this.remover(i, false);
      this.ato.envolvidos.splice(i, 0, envolvido);
      this.ordenar();
    },

    async editar(envolvido, i) {

      if (!this.edicao || !envolvido?.indicadorPessoalId) {
        return;
      }

      let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: envolvido.indicadorPessoalVersao.id});
      if (retorno) {
        this.atualizar(i, {
          exigencias: this.ato.envolvidos[i].exigencias,
          indices: this.ato.envolvidos[i].indices,
          papel: this.ato.envolvidos[i].papel || this.papeis[0].id,
          fracao: this.ato.envolvidos[i].fracao || 0,
          participacaoDoi: this.ato.envolvidos[i].participacaoDoi || 0,
          area: this.ato.envolvidos[i].area || 0,
          ajusteDoi: this.ato.envolvidos[i].ajusteDoi || false,
          exibicao: this.ato.envolvidos[i].exibicao,
          exibicaoRepresentantePrincipal:this.ato.envolvidos[i].exibicaoRepresentantePrincipal,
          consultaCnib: this.ato.envolvidos[i].consultaCnib,
          indicadorPessoalVersao: retorno,
          tipoPessoa: retorno.tipoPessoa,
          nome: retorno.nome,
          indicadorPessoalId: retorno.indicadorPessoalId,
          inventarianteVersao: this.ato.envolvidos[i].inventarianteVersao,
          inventariante: this.ato.envolvidos[i].inventariante,
          representantes:this.ato.envolvidos[i].representantes,
        });
      }

    },

    verificarFracao(envolvido, i) {
      if (envolvido.fracao > 100) {
        this.ato.envolvidos[i].fracao = 100;
      }
      this.ordenar();
    },

    verificarArea(envolvido, i) {
      let total = this.ficha.area;
      if (envolvido.area > total) {
        this.ato.envolvidos[i].area = total;
      }
      this.ordenar();
    },

    verificarConjuge(conjuge) {
      let notifica = true;
      this.ato.envolvidos.findIndex(envolvido => {
        if (envolvido.indicadorPessoalVersao.id === conjuge.conjuge_id) notifica = false;
      });
      if (notifica) {
        this.confirmarConjuge(undefined, conjuge.conjuge_id);
      }
    },

    async confirmarConjuge(close, conjuge) {
      let result = FrontBusiness.showConfirmacao("Envolvido possui cônjuge", "Deseja adicioná-lo ao ato?").then(async r => {
        if (r.value) {
          let indicador = await IndicadorPessoalBusiness.getVersaoById(conjuge);
          let envolvidos = {};
          let campos = ["possuiParticipacaoEstrangeira", "telefone", "tipoPessoa", "conjuge_id", "lote", "indicadorPessoalId", "quadra", "documento", "nome", "comercial", "celular", "id", "email", "versao"]
          for (let i = 0; i < campos.length; i++) {
            let campo = campos[i];
            envolvidos[campo] = indicador[campo];
          }
          this.selecionarEnvolvido(envolvidos);
          return (close ? true : false);
        } else if (r.dismiss === 'cancel') {
          return false;
        } else {
          return true;
        }
      }).catch(this.showError).finally(() => {
        FrontBusiness.setFocus(this.$el, 'buscar-pessoa input');
      });

      return result
    },

    async selecionarEnvolvido(envolvido, fracao = 0, area = 0, papel = null, verificarConjuge = true) {
      if (!envolvido?.indicadorPessoalId) {
        return;
      }
      if (envolvido.conjuge_id && verificarConjuge) this.verificarConjuge(envolvido);
      if (envolvido.inventarianteId) this.$set(envolvido, 'inventarianteVersao', await IndicadorPessoalBusiness.getVersaoById(envolvido.inventarianteId));
      this.$set(envolvido, 'exibicao', this.inventarianteExibicao(envolvido));
      this.representantePrincipalExibicao(envolvido);
      await this.validarDocumentos(envolvido, true);

      this.ato.envolvidos.push({
        indicadorPessoalVersao: envolvido,
        papel: papel || this.papeis[0]?.id,
        fracao: fracao,
        area,
        medidaArea: this.ficha.medidaArea,
        ajusteDoi: false,
        versao: null,
        exibicao: envolvido.exibicao,
        exibicaoRepresentantePrincipal:envolvido.exibicaoRepresentantePrincipal,
        ordem: 1000,
        tipoPessoa: envolvido.tipoPessoa,
        nome: envolvido.nome,
        indicadorPessoalId: envolvido.indicadorPessoalId,
        representantes: envolvido?.representantes ? JSON.parse(envolvido?.representantes) :[],
        inventariante:envolvido.inventariante,
        inventarianteVersao: envolvido.inventarianteVersao || {},
        inventarianteDocumentoInvalido: envolvido.inventarianteDocumentoInvalido,
      });

      this.ordenar();
      this.$emit('change-editor', {}, `ENVOLVIDO_PAPEL_${envolvido.indicadorPessoalId}`, [true, false]);
    },

    async sugerirEnvolvidos() {
      await FrontBusiness.openModal(EnvolvidosAto, {
        protocolo: this.protocolo?.id,
        ficha: this.ficha,
        proprietarios: this.proprietarios,
        atuais: this.ato?.envolvidos
      });
    },

    async addSugestaoEnvolvidos(selecionados) {
      if (selecionados) {
        let papel = this.papeis[0]?.id;
        let transmitente = this.papeis.find(e => e.id == 'TRANSMITENTE') ? 'TRANSMITENTE' : papel;
        selecionados.forEach(selecionado => {
          if (selecionados.length === 1) {
            selecionado.indicadorPessoalVersao.conjuge_id = selecionado.indicadorPessoalVersao.conjuge?.id || selecionado.indicadorPessoalVersao.conjuge;
          }
          delete selecionado.indicadorPessoalVersao.conjuge
          this.selecionarEnvolvido(selecionado.indicadorPessoalVersao, selecionado.fracao || 0, selecionado.area || 0, selecionado.fracao ? transmitente : papel, false);
        });
      }
    },

    // Inventariante
    inventarianteExibicao(envolvido) {
      return `${envolvido?.inventarianteVersao?.nome ? `${envolvido?.inventarianteVersao?.nome} - ` : ''}${envolvido.inventariante}`;
    },

    selecionarRepresentantePrincipal(envolvido,selectedRepresentante) {
        envolvido.representantes.forEach(representante => {
          if (representante !== selectedRepresentante) {
            representante.principal = false;
          }
        });
        this.representantePrincipalExibicao(envolvido);
    },

    representantePrincipalExibicao(envolvido) {
      let listaRepresentantes = envolvido.representantes || [];
      let exibicao = '-';
      if(listaRepresentantes.length >0){
        let repPrincipal = listaRepresentantes.find(re=> re.principal) || listaRepresentantes[0];
        exibicao = `${repPrincipal?.nome ? `${repPrincipal?.nome} - ` : ''} ${repPrincipal.documento ? repPrincipal.documento : '' }`;
      }
      this.$set(envolvido, 'exibicaoRepresentantePrincipal',  exibicao);
    },

    editInventariante(envolvido) {
      if (!this.edicao)  return;
      if(!envolvido.editandoInventariante){
        this.ato.envolvidos.forEach(e=>{
          this.$set(e, 'editandoInventariante', false);
        })
      }
      this.$set(envolvido, 'editandoInventariante', !envolvido.editandoInventariante);
    },

    getExibirInventariante(envolvido){
      if(!envolvido.indicadorPessoalVersao.falecido){
        envolvido.inventariante=null;
        envolvido.inventarianteVersao=null;
      }
      return envolvido.indicadorPessoalVersao.falecido;
    },


    // Representante
    editRepresentante(envolvido) {
      if (!this.edicao)  return;
      if(!envolvido.editandoRepresentante){
        this.ato.envolvidos.forEach(e=>{
          this.$set(e, 'editandoRepresentante', false);
        })
      }
      this.$set(envolvido, 'editandoRepresentante', !envolvido.editandoRepresentante);
    },

    editHashCNIB(envolvido) {
      if (!this.edicao)  return;
      if(!envolvido.editandoHashCNIB) {
        this.ato.envolvidos.forEach(e => {
          this.$set(e, 'editandoHashCNIB', false);
        })
      }
      this.$set(envolvido, 'editandoHashCNIB', !envolvido.editandoHashCNIB);
    },

    async copyHashCNIB(envolvido) {
      let r = await FrontBusiness.showConfirmacao("", "Deseja confirmar a cópia desse hash para este envolvido em todos os atos?").catch(this.showError);
      if (r.value) {
        let result = await AtoBusiness.copiarCNIB(this.protocolo.id, envolvido)
          .catch(e => FrontBusiness.showError('', e?.message || 'Ocorreu um erro ao tentar copiar hash CNIB'));
        if (result) {
          FrontBusiness.showSuccess('', 'Hash CNIB copiado com sucesso');
        }
      } else if (r.dismiss === 'cancel') {}
    },

    cnibSubStr(envolvido){
      if (envolvido.consultaCnib.length >= 100) {
        return envolvido.consultaCnib.substr(0, 99) + '...';
      } else {
        return envolvido.consultaCnib;
      }
    },

    async selecionarRepresentante(envolvido, indicador) {
      let representante = {
        documento:indicador.documento,
        nome:indicador.nome,
        indicadorPessoalVersaoId:indicador.id,
        envolvidoId:envolvido.id,
        principal:envolvido.representantes && envolvido.representantes.length === 0
      };
      envolvido.representantes.push(representante);
      this.representantePrincipalExibicao(envolvido);
    },

    async validarSelecionarDocumentosRepresentante(envolvido) {
      let documento =this.$refs.buscar[0].query;
      if(!documento){
        return FrontBusiness.showError('','Documento não informado');
      }
      let msgInvalid = await IndicadorPessoalBusiness.validarDocumentos('FISICA', this.$refs.buscar[0].query);
      if(msgInvalid){
        FrontBusiness.showError('',msgInvalid);
      }

      let representante = {
        documento:documento,
        envolvidoId:envolvido.id,
        principal:envolvido.representantes && envolvido.representantes.length === 0
      };
      envolvido.representantes.push(representante);
      this.representantePrincipalExibicao(envolvido);
      this.$refs.buscar[0].query='';
    },

    removerRepresentante(envolvido,representante){
      let index =  envolvido.representantes.indexOf(representante);
      if(index !== -1){
        envolvido.representantes.splice(index, 1);
        this.representantePrincipalExibicao(envolvido);
      }
    },

    async selecionarInventariante(envolvido, indicador) {
      this.$set(envolvido, 'inventariante', indicador?.documento);
      this.$set(envolvido, 'inventarianteVersao', {id: indicador?.id, nome: indicador?.nome});
      this.$set(envolvido, 'exibicao', this.inventarianteExibicao(envolvido));
      await this.validarDocumentos(envolvido)
      if(!indicador) {
        this.$set(envolvido, 'inventarianteDocumentoInvalido', null);
      }
    },

    async removerCnib(envolvido, indicador) {
      if(!indicador) {
        this.$set(envolvido, 'cnib', null);
        this.$set(envolvido, 'consultaCnib', null);
      };
    },

    async validarDocumentos(envolvido, selecao = true) {
      let documentoInventariante = envolvido.inventariante;
      if (!selecao) documentoInventariante = this.$refs.buscar[0].query;
      let msgDocInvalido = await IndicadorPessoalBusiness.validarDocumentos('FISICA', documentoInventariante);
      if (!selecao) {
        this.$set(envolvido, 'inventariante', documentoInventariante);
        this.$set(envolvido, 'inventarianteVersao', {});
        this.$set(envolvido, 'exibicao', this.inventarianteExibicao(envolvido));
      }

      if (msgDocInvalido == null || (msgDocInvalido && (documentoInventariante == null || documentoInventariante === ""))) {
        this.$set(envolvido, 'inventirianteDocumentoInvalido', null);
      } else {
        this.$set(envolvido, 'inventarianteDocumentoInvalido', msgDocInvalido);
      }
    },

    async inserirCnib(envolvido, selecao = true) {
      if (!selecao) {
        this.$set(envolvido, 'cnib', envolvido.consultaCnib);
        this.$set(envolvido, 'consultaCnib', envolvido.consultaCnib);
      }
    },


    detalhes(pessoa, versao) {
      FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao});
    },

    async exibirExigencia(dto) {

      let indices = dto.indices;
      let exigencias = dto.exigencias;

      exigencias = await ChecklistProcessamentoBusiness.copilarExigencias(this.protocolo, {...dto, indices}, exigencias);
      await FrontBusiness.openModal(NotaExigencia, {exigencias: exigencias, protocolo: this.protocolo});

    },

    async change(e){
      let chave = this.chave(e);
      if(chave === this.mostrarChecklistEnvolvido){
        this.mostrarChecklistEnvolvido = null;
      } else {
        // await this.buscarAtosPorVersaoIndicadorPessoal(this.ficha.id, pessoa);
        this.mostrarChecklistEnvolvido = chave;
      }
    },

    chave(e) {
      return `envolvido-id-${e}`
    },

    async erroFracaoEnvolvido(){
      this.validacaoFracaoEnvolvido = [];
      let show = !(IndicadorBusiness.getLivro(this.ato?.ficha?.livro).grupo != 'INDICADOR_REAL' && this.ato?.tipoServico?.opcoes?.tipoAto === 'ABERTURA');
      if (show && this.ato?.tipoServico) {
        const validacao = await FracaoTransferenciaImovelBusiness.validarMargemTransferenciaAto(this.transmitentes, this.adquirentes, this.ato.tipoServico?.opcoes?.tipoAto === 'ABERTURA');
        if (validacao) {
          this.validacaoFracaoEnvolvido.push(validacao);
        }
      }
    },

    distribuirFracao() {
      let qtdAdquirentes = this.ato.envolvidos.reduce((total, e) => total + (['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR'].includes(e.papel) ? 1 : 0), 0);
      let fracaoDistribuir = (this.transmitentes/(qtdAdquirentes)).toFixed(2);
      this.ato.envolvidos.forEach(e => {
        if (['ADQUIRENTE','CESSIONARIO','PROMITENTE_COMPRADOR'].includes(e.papel)) {
          e.fracao = fracaoDistribuir;
        }
      })
      this.erroFracaoEnvolvido()
    },

    copyDocumento(text) {
      let textArea = document.createElement("textarea");
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        let successful = document.execCommand('copy');
      } catch (err) {
        window.prompt("Copie para área de transferência: Ctrl+C e tecle Enter", text);
      }

      document.body.removeChild(textArea);
    },

    async calcularPorcentagem() {
      let envolvidos = await FrontBusiness.openModal(CalcularPorcentagem, {
        ficha: this.ficha,
        envolvidos: Utils.clone(this.ato?.envolvidos)
      });

      if (envolvidos) {
        this.$set(this.ato.dto, 'informarAreaEnvolvidos', true);
        this.$set(this.ato, 'envolvidos', envolvidos);
      }

    },

    setMedidaAreaEnvolvidos() {
      this.ato.envolvidos.forEach(e => {
        e.medidaArea = this.ato.dto.informarAreaEnvolvidos ? this.ficha.medidaArea : null;
        e.area = this.ato.dto.informarAreaEnvolvidos ? e.area : null;
      });
      this.erroFracaoEnvolvido();
    },

    calcularFracao(e) {
      e.fracao = ((e.area || 0) * 100 / this.ficha.area).toFixed(2);
    },

    participacao(e) {
      let doi = this.ato.tipoServico.opcoes.tipoTransacaoDoi !== 'NENHUM' && this.ato.doi.gerarDoi !== false;
      if (!doi || e.fracao <= 0) return;
      let porcentagem = 0;
      if (this.papeisAdquirentes.includes(e.papel)) {
        porcentagem = this.adquirentes;
      } else if (this.papeisTransmitentes.includes(e.papel)) {
        porcentagem = this.transmitentes;
      } else {
        return ''
      }
      let participacao = Utils.truncateNumber(e.fracao || 0, 10) * 100 / Utils.truncateNumber(porcentagem, 10);
      e.participacaoDoi = Utils.truncateNumber(participacao, 10);
    },

    recalcularParticipacaoDoi(){
      if(this.exibirParticipacaoDoi){
        this.ato.envolvidos.forEach(envolvido =>{
          this.participacao(envolvido);
        })
      }
    },
    calculoInicialParticipacaoDoi(){
      let participacaoZerada = this.ato.envolvidos.every(objeto => objeto.participacaoDoi === 0);
      if(participacaoZerada){
        this.recalcularParticipacaoDoi();
      }
    },
    somatoriaParticipacao(isAdquirente){
      let listaPapeis = isAdquirente ? this.papeisAdquirentes : this.papeisTransmitentes;
      let soma = 0;
      if(listaPapeis.length > 0){
        this.ato.envolvidos.forEach(e =>{
          if(listaPapeis.includes(e.papel)){
            soma = Utils.toNumber(soma) + Utils.toNumber(e.participacaoDoi || 0);
          }
        })
      }
      return Utils.toNumber(soma, 2);
    }
  },

  beforeDestroy(){
    EventBus.$off('ADD-SUGESTAO-ENVOLVIDOS');
  }

}
</script>

<template>

  <card titulo="Envolvidos">

    <template #acoes>

      <a title="Sugestões" href v-if="edicao"
         @click.prevent="sugerirEnvolvidos()" class="icon ml-1">
        <i class="fa fa-users"></i>
      </a>

      <div class="d-flex float-right" v-if="papelComFracao" :style="'align-items: center;' + (edicao ? 'margin-top: -10px;' : '')">

        <span class="font-weight-500 small mr-2" v-if="ficha.area && ato.dto.informarAreaEnvolvidos">Área total: {{$formatters.currency(ficha.area,{min : 2, max : 10})}} {{unidadeArea}}</span>

        <div class="form-check form-check-inline form-group small float-right mb-0 mr-3" v-if="ficha.livro == 'MATRICULA' || ficha.livro == 'TRANSCRICAO'">
          <input class="form-check-input" id="informar-area" type="checkbox" style="margin-top: -1px;" :disabled="!edicao"
                 v-model="ato.dto.informarAreaEnvolvidos" @change="setMedidaAreaEnvolvidos">
          <label for="informar-area" class="form-check-label">Informar Área</label>
        </div>

        <v-button v-if="edicao" :run="calcularPorcentagem" :button="false" class="btn-outline-primary btn-sm btn small float-right mr-3">
          <i class="fas fa-calculator" title="Calcular Porcentagem"></i> Calcular Porcentagem
        </v-button>

        <v-button class="icon small float-right mr-1" :run="distribuirFracao" :button="false" :popover="true" position="dropleft" v-if="transmitentes && edicao">
          <i class="fas fa-divide" title="Distribuir fração transmitida"></i>
        </v-button>

        <div class="small float-right" style="cursor: help" title="Transmitentes & Adquirentes">
          <span class="text-right text-red"> {{$formatters.currency(transmitentes,{min : 2, max : 10})}}%</span>
          /
          <span class="text-right text-green">{{$formatters.currency(adquirentes,{min : 2, max : 10})}}%</span>
        </div>


        <div v-if="exibirParticipacaoDoi" style="margin-left: 10px" class="form-check form-check-inline form-group small float-right mb-0 mr-1">
          <label class="form-check-label">Participação: </label>
        </div>
        <div v-if="exibirParticipacaoDoi" class="small float-right" style="cursor: help;" title="Participação DOI">
          <span class="text-right text-red"> {{somatoriaParticipacao(false)}}%</span>
          /
          <span class="text-right text-green">{{somatoriaParticipacao(true)}}%</span>
        </div>



      </div>

    </template>

    <div>
      <p v-for="(validacao, i) in validacaoFracaoEnvolvido" :key="i" :class="'alert alert-' +validacao.cssClass+  ' mb-2'" title="Editar Ato" v-html="validacao.mensagem">
      </p>
      <div style="margin-top: -10px;" v-if="ato.envolvidos.length">
        <div class="text-muted cell-top" style="min-width: 34px;" v-if="edicao"></div>
        <div class="text-muted cell-top" :class="{'pl-0' : edicao}" style="width: 100%">Pessoa</div>
        <div class="text-muted cell-top" style="min-width: 180px" v-if="papeis.length > 0">Papel</div>
        <div class="text-muted cell-top" style="min-width: 150px" v-if="ato.dto.informarAreaEnvolvidos">{{ unidadeArea }}</div>
        <div class="text-muted cell-top" style="min-width: 150px" v-if="exibirFracao">Fração</div>
        <div class="text-muted cell-top" style="min-width: 150px" v-if="exibirParticipacaoDoi">Participação DOI</div>
        <div class="text-muted cell-top" style="min-width: 34px"></div>
        <div class="text-muted cell-top" style="min-width: 34px"></div>
        <div class="text-muted cell-top" style="min-width: 34px" v-if="checklist.length"></div>
      </div>
      <div v-if="!ato.envolvidos.length" class="alert alert-outline-secondary alert-sm mb-1">
        Nenhuma parte envolvida cadastrada
      </div>
      <draggable class="table-envolvidos" v-if="ato.envolvidos.length" v-model="ato.envolvidos" handle=".move" ghost-class="ghost" @end="ordenar" :disabled="!edicao">
        <div v-for="(envolvido, i) in ato.envolvidos" class="row-e" :key="`${i}-${envolvido.indicadorPessoalId}`" :title="envolvido.ALERTA_DOCUMENTO" :class="{'ALERTA_DOCUMENTO': envolvido.ALERTA_DOCUMENTO}">
          <div class="cell move text-left" style="min-width: 20px;" v-if="edicao">
            <a @click.prevent="editar(envolvido, i)" class="icon" style="position: relative; top: calc(50% - 12px);"><i class="fal fa-edit"></i></a>
          </div>

          <div class="cell move" style="width: 100%" :class="{'pl-0' : edicao}">
            <div>
              <a href class="mb-1 pointer icon mr-2" @click.prevent="detalhes(envolvido.indicadorPessoalVersao, envolvido.indicadorPessoalVersao.id)">
                {{envolvido.indicadorPessoalVersao.nome}} {{envolvido.indicadorPessoalVersao.versao ? `(Versão: ${envolvido.indicadorPessoalVersao.versao})` : ''}}</a>
              <alert-indisponibilidade :pessoa="envolvido.indicadorPessoalVersao.indicadorPessoalId" />
              <alert-ocorrencia :pessoa="envolvido.indicadorPessoalVersao.indicadorPessoalId" />
            </div>

            <div class="small text-muted">

              {{envolvido.indicadorPessoalVersao.documento}}
              <span class="pointer" @click.prevent="copyDocumento(envolvido.indicadorPessoalVersao.documento)">
                <i class="icon ml-0 mr-1 far fa-copy"></i>
              </span>
            </div>

            <div class="mt- pt-1"></div>
            <div class="small text-muted">
             <span class="text-cyan" :class="{'pointer': edicao,'disabledClick': !edicao}" @click.prevent="editRepresentante(envolvido)">
                ({{envolvido.representantes.length > 0 ?`Representante: ${envolvido.exibicaoRepresentantePrincipal}` : 'Informar representante'}})
                <i class="icon ml-1 far" :class="{'fa-angle-up' : envolvido.editandoRepresentante, 'fa-angle-down' : !envolvido.editandoRepresentante}"></i>
              </span>
            </div>

            <template v-if="envolvido.editandoRepresentante">
              <div class="w-100 mt-2 border-top border-gray pt-2"></div>
                <buscar-pessoa @add="selecionarRepresentante(envolvido, $event)" :novo="true" ref="buscar" :placeholder="``" :masks="'###.###.###-##'">
                  <template #acoes>
                    <div class="col-auto ml-2" style="margin-top: -1px;">
                      <button class="btn btn-primary btn-xs "
                              @click.prevent="validarSelecionarDocumentosRepresentante(envolvido)"
                              type="button">OK
                      </button>
                    </div>
                  </template>
                </buscar-pessoa>
              <div class="w-100 mt-2 border-top border-gray pt-2"></div>
              <card-table v-if="envolvido.representantes.length >0">
                <tr>
                  <th style="width: 70%">Doc./Nome</th>
                  <th style="width: 20%;text-align: center">Principal</th>
                  <th style="width: 10%"></th>
                </tr>
                <tr v-for="representante in envolvido.representantes" :key="representante.id">
                  <td >
                    {{representante.nome ? representante.nome:representante.documento}}
                  </td>
                  <td style="text-align: center">
                    <input type="checkbox" :default="true"  v-model="representante.principal"
                           @change="selecionarRepresentantePrincipal(envolvido,representante)" />
                  </td>
                  <td >
                    <div class="ato-action d-flex justify-content-between">
                      <a href="" @click.prevent="removerRepresentante(envolvido,representante)" class="icon">
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </card-table>
            </template>
            <div v-if="getExibirInventariante(envolvido)">
              <div class="mt- pt-1"></div>
              <div class="small text-muted">
                   <span style="color: cornflowerblue" :class="{'pointer': edicao,'disabledClick': !edicao}" @click.prevent="editInventariante(envolvido, i)">
                     ({{envolvido.inventariante ? `Inventariante: ${envolvido.exibicao}` : 'Informar inventariante'}})
                     <i class="icon ml-1 far" :class="{'fa-angle-up' : envolvido.editandoInventariante, 'fa-angle-down' : !envolvido.editandoInventariante}"></i>
                   </span>
              </div>

              <template v-if="envolvido.editandoInventariante">
                <div class="w-100 mt-2 border-top border-gray pt-2"></div>
                <v-input v-if="!envolvido.inventariante" :disabled="!edicao" v-model="envolvido.inventariante" :small="true"
                         title="Editar inventariante">
                  <buscar-pessoa @add="selecionarInventariante(envolvido, $event)" :novo="true" ref="buscar" :placeholder="``" :masks="'###.###.###-##'">
                    <template #acoes>
                      <div class="col-auto ml-2" style="margin-top: -1px;">
                        <button class="btn btn-primary btn-xs "
                                @click.prevent="validarDocumentos(envolvido, false)"
                                type="button">OK
                        </button>
                      </div>
                    </template>
                  </buscar-pessoa>
                </v-input>
                <div v-else class="row gutters-xs">
                  <v-input class="col form-group" :class="{'invalido': envolvido.inventarianteDocumentoInvalido && envolvido.inventariante, 'valido': !envolvido.inventarianteDocumentoInvalido && envolvido.inventariante}">
                    <input type="text" readonly :title="envolvido.exibicao" v-model="envolvido.exibicao" class="form-control form-control-xs"/>
                  </v-input>
                  <div class="col-auto">
                    <button class="btn btn-outline-secondary btn-xs" @click="selecionarInventariante(envolvido)" type="button" title="Remover">
                      <i class="fal fa-times"></i>
                    </button>
                  </div>
                </div>
                <label class="small text-danger-soft" style="color: #dc3545 !important" v-if="envolvido.inventarianteDocumentoInvalido && envolvido.inventariante">{{envolvido.inventarianteDocumentoInvalido}}</label>
              </template>
            </div>


            <div class="mt- pt-1"></div>
            <div class="small text-muted">
              <span class="text-teal" :class="{'pointer': edicao,'disabledClick': !edicao}" @click.prevent="editHashCNIB(envolvido, i)">
                ({{envolvido.cnib ? `CNIB: ${cnibSubStr(envolvido)}` : 'Informar hash da consulta CNIB'}})
                <span v-if="envolvido.cnib" class="pointer" title="Copiar hash CNIB" @click.prevent.stop="copyHashCNIB(envolvido)">
                  <i class="icon ml-0 mr-1 far fa-copy"></i>
                </span>
                <i class="icon ml-1 far" :class="{'fa-angle-up' : envolvido.editandoHashCNIB, 'fa-angle-down' : !envolvido.editandoHashCNIB}"></i>
              </span>
            </div>

            <template v-if="envolvido.editandoHashCNIB">
              <div class="w-100 mt-2 border-top border-gray pt-2"></div>
              <div v-if="!envolvido.cnib" :disabled="!edicao" class="input-group" :small="true" title="Editar CNIB">
                <input type="text" :title="envolvido.consultaCnib" v-model="envolvido.consultaCnib" class="form-control form-control-xs"/>
                <div class="ml-2" style="margin-top: -1px;">
                  <button class="btn btn-primary btn-xs "
                          @click.prevent="inserirCnib(envolvido, false)"
                          type="button">OK
                  </button>
                </div>
              </div>

              <div v-else class="row gutters-xs">
                <v-input class="col form-group">
                  <input type="text" readonly :title="envolvido.consultaCnib" v-model="envolvido.consultaCnib" class="form-control form-control-xs"/>
                </v-input>
                <div class="col-auto">
                  <button class="btn btn-outline-secondary btn-xs mr-1" @click="copyHashCNIB(envolvido)" type="button" title="Copiar hash CNIB">
                    <i class="far fa-copy"></i>
                  </button>
                  <button class="btn btn-outline-secondary btn-xs" @click="removerCnib(envolvido)" type="button" title="Remover">
                    <i class="fal fa-times"></i>
                  </button>
                </div>
              </div>
            </template>

            <!--            <template v-if="(adquirentes != transmitentes && ((adquirentes > 0 && adquirentes != 100 && ['ADQUIRENTE','PROMITENTE_COMPRADOR'].includes(envolvido.papel)) || (transmitentes > 0 && transmitentes != 100 && ['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(envolvido.papel))))-->
            <!--             || ((adquirentes > 100 && ['ADQUIRENTE','PROMITENTE_COMPRADOR'].includes(envolvido.papel)) || (transmitentes > 100 && ['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(envolvido.papel)))">-->
            <!--              <div class="w-100 mt-2 border-top border-gray pt-2"></div>-->
            <!--              <div class="small">-->
            <!--                <input style="position: relative; top: 3px; left: 0px;" type="checkbox" :id="`${i}-${envolvido.indicadorPessoalId}-validarPorcentagemDOI`"-->
            <!--                       v-model="envolvido.ajusteDoi" :disabled="(protocolo && protocolo.registro) || (!envolvido.ajusteDoi && ((['ADQUIRENTE','PROMITENTE_COMPRADOR'].includes(envolvido.papel) && adquirenteValidaPorcentagemDOI) || (['TRANSMITENTE','CEDENTE','PROMITENTE_VENDEDOR'].includes(envolvido.papel) && transmitenteValidaPorcentagemDOI)))" />-->
            <!--                <label :for="`${i}-${envolvido.indicadorPessoalId}-validarPorcentagemDOI`" style="position: relative; top: 1px; left:4px;" class="pointer">-->
            <!--                  Selecionar o envolvido para validar a porcentagem da DOI-->
            <!--                </label>-->
            <!--              </div>-->
            <!--            </template>-->



          </div>
          <div class="cell move" style="min-width: 180px" v-if="papeis.length > 0">
            <v-input @change="emitChange(i)" :idindice="`ENVOLVIDO_PAPEL_${envolvido.indicadorPessoalId}`" :disabled="!edicao" v-model="envolvido.papel" type="select" ref="papel" :options="papeis" />

          </div>

          <div class="cell move row pr-2" style="min-width: 150px" v-if="ato.dto.informarAreaEnvolvidos">
            <template v-if="papeisComFracao.includes(envolvido.papel)">
              <v-input v-model="envolvido.area" type="currency" placeholder="Área"
                       @blur="verificarArea(envolvido, i)" class="col-10 float-left pl-0 pr-2"
                       :input-props="{precision : {min : 2, max : 10}}" :disabled="!edicao"/>

              <div class="ato-action float-left mt-1" v-if="edicao">
                <a href title="Calcular Fração" class="icon small" @click.prevent.stop="calcularFracao(envolvido)">
                  <i class="fal fa-calculator"></i>
                </a>
              </div>
            </template>
          </div>

          <div class="cell move" style="min-width: 150px" v-if="exibirFracao">
            <v-input :disabled="!edicao" v-model="envolvido.fracao" type="currency"
                     @blur="verificarFracao(envolvido, i)" placeholder="Fração" :input-props="{precision : {min : 2, max : 10}}"
                     v-if="papeisComFracao.includes(envolvido.papel)"
                     @input="recalcularParticipacaoDoi">
            </v-input>
          </div>

          <div class="cell move" style="min-width: 150px" v-if="exibirParticipacaoDoi">
            <v-input :disabled="!edicao" v-model="envolvido.participacaoDoi" type="currency" v-if="papeisParticipacaoDoi.includes(envolvido.papel)"
                     placeholder="Participação Doi" :input-props="{precision : {min : 2, max : 2}}">
            </v-input>
          </div>

          <div class="cell text-right" style="min-width: 34px">
            <div class="ato-action" v-if="edicao">
              <a href title="Duplicar" class="icon" @click.prevent.stop="duplicar(envolvido)">
                <i class="fal fa-clone"></i>
              </a>
            </div>
          </div>
          <div class="cell text-right" style="min-width: 34px">
            <div class="ato-action" v-if="edicao">
              <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
            </div>
          </div>
          <div class="cell td-toggle" style="min-width: 34px" v-if="checklist.length">
            <toggle :mostrar="chave(i) === mostrarChecklistEnvolvido" :run="change" :params="[i]" :chave="chave(i)" class="ml-2 icon" />
          </div>
          <div style="background-color: rgb(28 30 36); width: 100%; padding-top: 5px;" class="float-left" v-if="chave(i) === mostrarChecklistEnvolvido">
            <responder-checklist :documentos="documentos"
                                 :checklists="checklist"
                                 :permitir-edicao="edicao"
                                 :envolvidos="[envolvido]"
                                 :dto="envolvido"
                                 ref="checklist" @exigencia="exibirExigencia(envolvido)"
            />
          </div>
        </div>
      </draggable>
      <div v-if="form && edicao" style="padding: 0.5rem 0.75rem;">
        <buscar-pessoa @add="selecionarEnvolvido($event)" :novo="true" id-input="buscar-pessoa"/>
      </div>
    </div>
  </card>

</template>

<style lang=scss>
.invalido input{
  border-color: #dc3545 !important;
}

.valido input{
  border-color: #0e4e0e !important;
}

.disabledClick {
  pointer-events: none;
}

.ALERTA_DOCUMENTO {
  background-color: #a83d3426 !important;
}

.table-envolvidos {
  /*display: table;*/
  /*width: 100%;*/
  .row-e {
    /*display: table-row;*/
    vertical-align: inherit;
    border-color: inherit;
    background: rgb(35, 41, 49);

    .cell {
      display: table-cell;
      vertical-align: middle;
      border-bottom: 5px solid #1c1e24;
      padding: 0.75rem;
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}

.cell-top {
  display: table-cell;
  vertical-align: middle;
  padding: 0.75rem;
  font-weight: bold;
}


.theme-light{
  .table-envolvidos {
    .row-e {
      background-color: #fff;
      .cell {
        border-bottom-color: #F1F1F2;
      }
    }
  }
}

</style>


import {CodeMirror} from 'vue-codemirror'

CodeMirror.defineMode("minuta", function(config, parserConfig) {
  const mustacheOverlay = {
    token: function(stream, state) {
      // Procurar por $('...')
      if (stream.match(/\$\([^\)]*\)/)) {
        return "variavel";
      }
      // Procurar por {{...}}
      else if (stream.match(/{{[^}}]*}}/)) {
        return "variavel";
      }
      while (stream.next() != null && !stream.match(/\$\([^\)]*\)/, false) && !stream.match(/{{[^}}]*}}/, false)) {
        // Continuar enquanto não encontrar os padrões
      }
      return null;
    }
  };
  return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "wordpresspost"), mustacheOverlay);
});

import FrontBusiness from "@/business/FrontBusiness.js";
import Http from "@/commons/Http";

const path = 'api/v2/integracoes/onr/';

export default {

  async solicitarAssinatura(entity, ids){
    let link = await Http.post(`${path}assinar/${entity}`, ids);
    const win = await FrontBusiness.abrirJanela(null, 'Assinador ONR', link.url);

    return new Promise((resolve) => {

      let timer = setInterval(() => {
        if(win.closed) {
          clearInterval(timer);
          resolve(true);
          // alert('closed');
        }
      }, 100);

    });
  }

}

<template>

    <card titulo="Restrições" v-if="ato && (ato.indicadorReal || ato.registroAuxiliar)">
        <card-content class="mb-12">
            <OpcoesRestricoes style="padding-left: 0px;" :ato="ato" :edicao="edicao" :key="1+chave"/>
            <div class="mx-0 row">
              <RestritivaRestricao style="padding-left: 0px;" :ato="ato" :edicao="edicao" :key="2+chave"/>
              <VencimentoRestricao style="padding-left: 0px;" :ato="ato" :edicao="edicao" :key="3+chave"/>
            </div>
            <OrigemRestricao v-if="origemRestricao" :ato="ato" :edicao="edicao" :protocolo="protocolo" :ficha="ficha" :key="4+chave"/>
        </card-content>
    </card>

</template>

<script>
import Card from "@/components/Layout/components/Card";
import CardContent from "@/components/Layout/components/CardContent";
import OpcoesRestricoes from "@/views/Protocolo/Atos/Ato/Restricoes/OpcoesRestricoes";
import OrigemRestricao from "@/views/Protocolo/Atos/Ato/Restricoes/OrigemRestricao";
import RestritivaRestricao from "@/views/Protocolo/Atos/Ato/Restricoes/RestritivaRestricao";
import VencimentoRestricao from "@/views/Protocolo/Atos/Ato/Restricoes/VencimentoRestricao";

export default {
        name: "RestricoesCard",
        components: {RestritivaRestricao, VencimentoRestricao, OrigemRestricao, OpcoesRestricoes, Card, CardContent},
        props:{
            ato: {},
            edicao: {default: false},
            chave: {default: ''},
            protocolo: {},
            ficha: {},
        },

        created() {
            if(!this.ato.dto.configuracaoRestricao) this.ato.dto.configuracaoRestricao = {};
        },

        computed: {
          origemRestricao(){
            return this.ato.tipoServico?.opcoes?.cancelarRestricao && this.ato.tipoServico?.opcoes?.cancelarRestricao?.length > 0;
          }
        }
    }
</script>

<template>
  <modal titulo="Permissões">


    <div class="row gutters-xs mb-3" style="align-items: center;">
      <v-input v-if="nome" class="col-12" v-model="nome" readonly placeholder="Nome da Permisssão"
               type="text"  />
      <v-input class="col-12 mb-0" v-model="query" placeholder="Nome da Permisssão"
               type="text" >
        <template #input>
          <i class="fa fa-search icon" style="position: absolute; right: 8px; top: calc(50% - 8px); font-size: 16px;"></i>
        </template>
      </v-input>
    </div>
    <div class="w-100"></div>
    <card v-for="(subgrupos, grupo) in montagem" :key="grupo" :titulo="grupo" class="w-100">
      <card v-for="(permissoes, subgrupo) in subgrupos" :key="subgrupo" :titulo="subgrupo" class="w-100" :chave="grupo + '_' + subgrupo">
        <div v-for="(permissao, index) in permissoes" :key="index" class="mb-3">
          {{permissao.descricao}}
        </div>
      </card>
    </card>
    <div v-if="!filtrado.length" class="alert alert-outline-secondary alert-sm mb-0">
      Nenhuma permissão encontrada
    </div>
  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Utils from "@/commons/Utils.js";
import Modal from "@/components/Modal";
import usuarioBusiness from "@/business/crud/UsuarioBusiness";
import AuthBusiness from "@/business/AuthBusiness";
import Card from "@/components/Layout/components/Card.vue";

export default {
  name: "ModalGrupoPermissao",
  components: {
    Card,
    Modal,
  },
  props:{
    permissoes: {type: Array, default: null},
    nome: {type: String, default: null},
  },

  computed:{
    filtrado() {
      let permissoes = this.listaPermissoes;

      let q = this.query.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

      if (q?.length) {
        permissoes = (this.listaPermissoes || [])?.filter((opt) => {
          return opt.descricao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(q)
        });
      }
      return permissoes;
    },

    montagem() {
      let permissoes = this.filtrado;

      let rows = Utils.groupBy(permissoes, 'grupo');
      for (const key in rows) {
        rows[key] = Utils.groupBy(rows[key], 'subgrupo')
      }
      return rows;
    }
  },

  async mounted() {
    this.listaPermissoes = (this.$root.permissoes || []).filter(e => this.permissoes.includes(e.id));
  },
  data() {
    return {
      query: '',
      listaPermissoes: []
    }
  },

  methods: {

  }
}
</script>

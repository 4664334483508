import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/etapa';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByDominio(id){
    return Http.get(`${path}/dominio/${id}`);
  },

  async getByDominioSimples(id){
    return Http.get(`${path}/dominio-simples/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  tiposEtapa(dominio = '') {
    let tiposEtapa = [
      {id : 'QUALIFICACAO', nome : 'Qualificação'},
      {id : 'REGISTRO', nome : 'Registro'},
      {id : 'POS_REGISTRO', nome : 'Pós-Registro'},
      {id : 'INTERNA', nome : 'Interna'},
      {id : 'EXTERNA', nome : 'Externa'},
      {id : 'EXIGENCIA', nome : 'Exigência'},
      {id : 'AGUARDANDO_PAGAMENTO', nome : 'Aguardando Pagamento'},
    ];

    if (dominio === 'CERTIDAO_RI') {
      tiposEtapa.push({id : 'ORCAMENTO', nome : 'Orçamento'});
    }

    return tiposEtapa;
  }

}

<template>
  <modal :titulo="titulo" :closeable="!rodando">

    <card :title="processo">
      <card-content>

        <p class="text-center" v-html="processo"></p>

        <template v-if="erros.length">
          <p class="text-danger mb-2"><b>Erros retornados [{{erros.length}}]:</b></p>
          <ul class="pl-4">
            <template v-for="erro in erros">
              <li v-if="!erro.id" v-html="erro" :key="erro.msg"></li>
              <li v-if="erro.id" :key="erro.msg">
                <b>
                  <router-link :to="{ name: 'protocolo', params:{id : erro.id} }" class="icon" target="_blank">
                    <i class="fas fa-external-link-alt"></i> {{erro.codigo}}
                  </router-link>
                </b> {{erro.msg}}
              </li>
            </template>
          </ul>
        </template>

      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" :disabled="rodando"
              class="btn btn-primary ml-auto mr-auto">

        <template v-if="rodando">
          <span>Processando: {{contador.atual}} / {{contador.total}}</span>
          <small-loading :loading="true" style="display: inline-block; margin-top: 5px; margin-bottom: 0 !important;" color="text-white" class="ml-2 mb-1">
          </small-loading>
        </template>

        <template v-else>
          Fechar
        </template>

      </button>
    </template>

  </modal>
</template>

<script>
import ArispBusiness from "@/business/ArispBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import AuditoriaBusiness from "@/business/AuditoriaBusiness";

export default {
    name: "ArispAtualizarProtocolos",
    components: {SmallLoading, CardContent, Card, Modal},
    props: {
      dominio: String,
      protocolo: Object
    },

    modal:{
      width: 800,
      escapable: false
    },

    data() {
      return {
        contador:{
          total: 0,
          atual: 0
        },
        processo: null,
        pedidos : null,
        erros: [],
        rodando: false
      }
    },

    mounted() {
      this.iniciar();
    },

    computed:{
      titulo(){
        if(this.dominio == 'CERTIDAO_RI'){
          return 'ONR - Atualizar E-certidão';
        }else{
          return 'ONR - Atualizar E-protocolo';
        }
      }
    },

    methods: {

      close(){
        FrontBusiness.closeModal();
      },

      async mostrarErro(pedido, e){
        if(pedido){
          this.erros.push({id: pedido.id, codigo: pedido.codigo, msg: `${e?.message || e}`});
        }else{
          this.erros.push(`<b>Erro: </b> ${e?.message || e}`);
        }
        return null;
      },

      async atualizarIndividual(){
        this.processo = `Atualizando protocolo <b>${this.protocolo.codigo}</b>`;
        await ArispBusiness.atualizarProtocolo(this.protocolo.id).catch(e => this.mostrarErro(this.protocolo, e));
        AuditoriaBusiness.auditarAcao({id: this.protocolo?.id, entidade: AuditoriaBusiness.enumEntidadeExecucao({entidade: 'EXECUCAO_ARISP', dominio: this.dominio}), descricao: `Atualizou o Pedido` })
          .catch(e => null);
        this.$set(this, 'rodando', false);
      },

      async iniciar(){

        this.$set(this, 'rodando', true);
        this.contador.atual = 0;

        if(this.$root.usuarioUtilizaCertificadoTerceiros){

          this.processo = "Testando certificado";
          let testeCertificado = await ArispBusiness.testarCertificado().catch(e => this.mostrarErro(null, e));

          if(!testeCertificado){
            this.$set(this, 'rodando', false);
            return;
          }

          let r = await ArispBusiness.iniciarTokens().catch(e => this.mostrarErro(null, e));

          if(!r){
            this.$set(this, 'rodando', false);
            return;
          }

        }

        if(this.protocolo?.id){
          await this.atualizarIndividual();
          return;
        }

        this.processo = "Buscando protocolos para atualização";
        let retorno = await ArispBusiness.getPedidosAguardandoAtualizacao(this.dominio).catch(e => {
          this.mostrarErro(null, e);
          return false;
        });

        if(!retorno?.length){
          this.processo = "Nenhum protocolo encontrado";
          this.$set(this, 'rodando', false);
          return;
        }

        this.contador.total = retorno.length;

        let promisses = [];

        for(let pedido of retorno){
          if(this.rodando){
            this.processo = `Atualizando protocolo <b>${pedido.codigo}</b>`;
            ++this.contador.atual;
            promisses.push(ArispBusiness.atualizarProtocolo(pedido.id).catch(e => this.mostrarErro(pedido, e)));
            await FrontBusiness.sleep(1000);
          }
        }

        await Promise.all(promisses);

        AuditoriaBusiness.auditarAcao({ entidade: AuditoriaBusiness.enumEntidadeExecucao({entidade: 'EXECUCAO_ARISP', dominio: this.dominio}), descricao: `Atualizou Pedidos` })
          .catch(e => null);

        this.processo = "Não há mais pedidos para atualizar";
        this.$set(this, 'rodando', false);

      }
    }

  }
</script>

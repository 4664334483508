<template></template>

<script>
import FrontBusiness from "@/business/FrontBusiness";

import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import ImprimirFichaV2 from "@/components/Livros/ImprimirFichaV2.vue";
import Modal from "@/components/Modal";
import AlertBanner from "@/components/alert/AlertBanner";

//FIXME: Remover este componente
export default {
    name: "ImprimirFicha",
    components: {CardContent, Card, AlertBanner, Modal},

    props: ['tipo', 'id', 'codigo', 'simulacao', 'atosSimulacao'],

    async mounted() {
      this.abrirNovoModelo();
    },

    modal: {
      escapable: true
    },

    methods: {
      abrirNovoModelo(){
        FrontBusiness.closeModal();
        FrontBusiness.openModal(ImprimirFichaV2, {id: this.id, tipo: this.tipo, codigo: this.codigo, simulacao: this.simulacao, atosSimulacao: this.atosSimulacao});
      },
    }
  }
</script>

<template>

  <file-upload :ref="`upload-${uuid}`" :input-id="`upload-${uuid}`" class="pointer"
               :multiple="multiple" :accept="accept" :extensions="extensions"  :disabled="loading"
               v-model="model" :drop="true" @input-file="inputFile">

    <slot :loading="loading"></slot>

    <span class="ml-1 spinner-border spinner-border-sm"
          v-if="loading" role="status" aria-hidden="true"></span>

  </file-upload>

</template>

<script>

  import VueUploadComponent from 'vue-upload-component';
  import utils from "@/commons/Utils";

  export default {
    name: "V-Upload",

    components: {
      FileUpload: VueUploadComponent
    },

    props: {
      value: {},
      upload : Function,
      params : {type: Array, default: () => []},
      multiple : {type : Boolean, default: () => false},
      drop : {type : Boolean, default: () => false},
      dropDirectory : {type : Boolean, default: () => false},
      accept : {type : String, default: undefined},
      extensions : {type : String, default: undefined}
    },

    computed:{
      model: {
        get: function () {
          return this.value
        },
        set: function (newValue) {
          this.$emit('input', newValue);
        }
      }
    },

    data() {
      return {
        uuid : utils.uuid(),
        loading : false,
        opened : false
      }
    },

    methods:{

      async inputFile(){
        await this.action(this.upload);
      },

      async action(fnc = () => {}){
        if(this.loading){
          return;
        }
        try{
          this.$set(this, 'loading', true);
          this.files = await fnc(...this.params);
        }finally {
          this.$set(this, 'loading', false);
        }
      }

    }
  }
</script>

<style lang=scss>

</style>

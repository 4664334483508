<template>
  <span v-if="!visible" style="color: transparent;" class="placeholder-image-anexo">.</span>
  <loading-spinner v-else-if="visible && loading" class="text-center"/>
  <img :id="uuid" loading="lazy" :src="link" :height="height" :width="width" alt="" v-else>
</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness";
import FrontBusiness from "@/business/FrontBusiness.js";
import LoadingSpinner from "@/v2/components/LoadingSpinner";
import utils from "@/commons/Utils";

export default {
  name: "ImageFromAnexo",
  components: {LoadingSpinner},
  props:{
    height: {type: String, default: ''},
    width: {type: String, default: ''},
    anexoOrIdAnexo: null,
    thumb: {default: false},
    cache: {default: null},
    forceShow: {default: false},
  },

  data(){
    return{
      link: {type: String, default: ''},
      observer: null,
      visible:false,
      loading: true,
      uuid: utils.uuid(),
    }
  },

  async mounted() {

    this.observer = FrontBusiness.delayedView(this.$el, () => {
      if(this.anexoOrIdAnexo){
        this.load();
        return true;
      }else{
        return false;
      }
    }, null, this.forceShow);

    // this.observer = new IntersectionObserver((entries ) => {
    //   // setTimeout(() => {
    //     const el = entries?.[0];
    //     if (el?.isIntersecting && this.anexoOrIdAnexo) {
    //       if(el?.isVisible || typeof el?.isVisible === undefined){
    //         // console.debug(el, this.anexoOrIdAnexo)
    //         this.load();
    //         this.observer?.unobserve(this.$el);
    //         this.observer?.disconnect();
    //       }
    //     }
    //   // }, 300);
    // },{
    //   threshold: 0.5,
    //   // 🆕 Track the actual visibility of the element
    //   trackVisibility: true,
    //   // 🆕 Set a minimum delay between notifications
    //   delay: 500
    // });
    //
    // this.observer.observe(this.$el);
  },

  destroyed() {
    this.observer.disconnect();
  },

  computed:{
    apiUrl(){
      return window.server.API;
    }
  },

  methods:{
    async load(){
      console.debug('load', this.cache);
      this.$set(this, 'visible', true);
      this.loading = true;
      //await this.$api.get(this.link).catch(e => null);
      this.link = await AnexoBusiness.ver(this.anexoOrIdAnexo, this.thumb, this.cache || '');
      setTimeout(() => {
        let img = document.getElementById(this.uuid);
        if (img) {
          img.draggable = false;
        }

      }, 1000)
      this.$set(this, 'loading', false);
    }
  },

}
</script>

<template>
  <modal :titulo="titulo" :closeable="!rodando">

    <card :title="processo">
      <card-content>

        <p class="text-center" v-html="processo"></p>

        <template v-if="erros.length">
          <p class="text-danger mb-2"><b>Erros retornados [{{erros.length}}]:</b></p>
          <ul class="pl-4">
            <li v-for="erro in erros" v-html="erro" :key="erro"></li>
          </ul>
        </template>

      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" :disabled="rodando"
              class="btn btn-primary ml-auto mr-auto">

        <template v-if="rodando">
          <span>Processando: {{contador.atual}} / {{contador.total}}</span>
          <small-loading :loading="true" style="display: inline-block; margin-top: 5px; margin-bottom: 0 !important;" color="text-white" class="ml-2 mb-1">
          </small-loading>
        </template>

        <template v-else>
          Fechar
        </template>

      </button>
    </template>

  </modal>
</template>

<script>
import ArispBusiness from "@/business/ArispBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import AuditoriaBusiness from "@/business/AuditoriaBusiness";

export default {
    name: "ArispImportarPedidosProtocolo",
    components: {SmallLoading, CardContent, Card, Modal},
    props: {
      dominio: String
    },

    modal:{
      width: 800,
      escapable: false
    },

    data() {
      return {
        contador:{
          total: 0,
          atual: 0
        },
        processo: null,
        pedidos : null,
        erros: [],
        rodando: false
      }
    },

    mounted() {
      this.iniciar();
    },

  computed:{
    titulo(){
      if(this.dominio == 'CERTIDAO_RI'){
        return 'ONR - Importar Pedidos do E-certidão';
      }else{
        return 'ONR - Importar Pedidos do E-protocolo';
      }
    }
  },

    methods: {

      close(){
        FrontBusiness.closeModal();
      },

      async mostrarErro(pedido, e){
        console.error(e);
        if(pedido){
          this.erros.push(`<b>${pedido.protocolo}:</b> ${e?.message || e}`);
        }else{
          this.erros.push(`<b>Erro: </b> ${e?.message || e}`);
        }
        return null;
      },

      async forcarProtocolo(dominio, protocolo){

        this.$set(this, 'rodando', true);
        this.contador.atual = 0;

        // if(!this.$root.config.arisp.usarCertificadoLocal){
        //
        //   this.processo = "Testando certificado";
        //   let testeCertificado = await ArispBusiness.testarCertificado().catch(e => this.mostrarErro(null, e));
        //
        //   if(!testeCertificado){
        //     this.$set(this, 'rodando', false);
        //     return;
        //   }
        //
        //   let r = await ArispBusiness.iniciarTokens().catch(e => this.mostrarErro(null, e));
        //
        //   if(!r){
        //     this.$set(this, 'rodando', false);
        //     return;
        //   }
        //
        // }

        this.processo = `Importando protocolo ONR manualmente <i>${protocolo}</i>`;
        await ArispBusiness.importarPedido(dominio, protocolo).catch(e => this.mostrarErro({protocolo}, e));

        this.processo = "Não há mais pedidos para importar";
        this.$set(this, 'rodando', false);

      },

      async iniciar(){

        this.$set(this, 'rodando', true);
        this.contador.atual = 0;

        if(this.$root.usuarioUtilizaCertificadoTerceiros){

          this.processo = "Testando certificado";
          let testeCertificado = await ArispBusiness.testarCertificado().catch(e => this.mostrarErro(null, e));

          if(!testeCertificado){
            this.$set(this, 'rodando', false);
            return;
          }

          let r = await ArispBusiness.iniciarTokens().catch(e => this.mostrarErro(null, e));

          if(!r){
            this.$set(this, 'rodando', false);
            return;
          }

        }

        this.processo = "Conectando à ONR";
        let retorno = await ArispBusiness.pedidosPendentes(this.dominio).catch(e => this.mostrarErro(null, e));

        if(!retorno){
          this.$set(this, 'rodando', false);
          return;
        }

        this.contador.total = retorno.quantidade;

        if(retorno.quantidade > 0) {
          for (let pedido of retorno.pedidos) {
            if (this.rodando) {
              this.processo = `Importando protocolo Arisp <b>${pedido.protocolo}</b> <br /> <i>${pedido.instituicao}</i>`;
              ++this.contador.atual;
              await ArispBusiness.importarPedido(this.dominio, pedido.idcontrato).catch(e => this.mostrarErro(pedido, e));
              await FrontBusiness.sleep(500);
            }
            // this.rodando = false;
          }
        }
        AuditoriaBusiness.auditarAcao({entidade: AuditoriaBusiness.enumEntidadeExecucao({entidade: 'EXECUCAO_ARISP', dominio: this.dominio}), descricao: `Importou Pedidos`})
          .catch(e => null);

        this.processo = "Não há mais pedidos para importar";
        this.$set(this, 'rodando', false);

      }
    }

  }
</script>

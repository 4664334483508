
export default {
  validateLongArray: (params = {values, max, min}) => {
    const {values = [], max = 9223372036854775807, min = -9223372036854775808} = params;

    return values.every((value) => {
      return !Number.isNaN(value) && typeof value === "number"
        && Number.isFinite(value) && Number.isSafeInteger(value)
        && value >= min && value <= max;
    });
  }
}

<template>
  <div>

    <div class="alert alert-outline-secondary mb-2" v-if="!dto.filtros || !dto.filtros.length && showEmptyFilter">
      Nenhum filtro está sendo utilizado ainda.
    </div>

    <div class="row gutters-xs mb-2 pb-2 border-bottom border-sidebar" v-for="(f, i) in dto.filtros" :key="`${i}-${f.id}`">

      <div class="col-auto" style="min-width: 300px">
        <v-input type="v-select" class="mb-0" data-testid="selecionar-filtro" :options="ops(f.id)" v-model="f.id" @change="clean(f)" :disabled="f.hiddenActions"/>
      </div>

      <div class="col">
        <filtrar-consulta-item :item="f" :options="ops(f.id)" v-if="f.id && !loading" :key="`${i}-${f.id}-item`">
          <template #custom="{item}">
            <slot name="custom" :item="item" />
          </template>
        </filtrar-consulta-item>
      </div>

      <div class="col-auto" v-if="!f.hiddenActions">

        <a href="" @click.prevent="add(f)" class="btn btn-outline-secondary btn-xs mr-2">
          <i class="fa fa-copy"></i>
        </a>

        <a href="" @click.prevent="remove(i)" class="btn btn-outline-danger btn-xs">
          <i class="fa fa-minus"></i>
        </a>

      </div>

    </div>

    <a href @click.prevent="add()" data-testid="adicionar-filtro">+ ADICIONAR FILTRO</a>

  </div>
</template>

<script>

import FiltrarConsultaItem from "@/components/Consultas/components/FiltrarConsultaItem.vue";

export default {
  name: "FiltrarConsulta",
  components: {FiltrarConsultaItem},
  props:{
    dto: Object,
    options: Array,
    showEmptyFilter: {type: Boolean, default: true}
  },

  mounted() {
    this.$set(this.dto, 'filtros', this.dto.filtros || []);
  },

  computed: {
    usados() {
      return this.dto.filtros.filter(o => !o.type || o.type === 'equal').map(o => o.id) || [];
    },
  },

  data(){
    return {
      loading:false
    }
  },

  methods:{
    ops(id) {
      return this.options.filter(e => e.id === id || !this.usados.includes(e.id))
    },

    clean(f){
      this.loading = true;
      this.$set(f, 'value', null);
      this.$set(f, 'type', null);
      this.$emit('change', new Date());
      this.loading = false;
    },

    add(model = {}){
      this.dto.filtros.push({...model});
      this.$forceUpdate();
    },

    remove(i){
      this.dto.filtros?.splice(i, 1);
      this.$forceUpdate();
    }

  }

}
</script>

<template>
  <modal titulo="Selecionar solicitante" id="modal-selecionar-solicitante">
    <card-table :striped="true" :dragscroll="true" class="table table-borderless">
      <thead>
      <tr>
        <th>Nome</th>
        <th>Documento</th>
        <th>Versão</th>
        <th>CEP</th>
        <th>Logradouro</th>
        <th>Bairro</th>
        <th>E-mail</th>
        <th>Cidade</th>
        <th>UF</th>
        <th>Estado Civil</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="pessoa in pessoas" :key="pessoa.id">
        <td style="min-width: 300px;" class="nowrap">
          <a @click="select(pessoa)" class="pointer icon mr-2">{{ pessoa.nome }}</a>
        </td>
        <td class="table-cell">{{ pessoa.documento }}</td>
        <td class="table-cell">{{ pessoa.versao }}</td>
        <td class="table-cell">{{ pessoa.cep }}</td>
        <td class="table-cell">{{ pessoa.logradouro }}</td>
        <td class="table-cell">{{ pessoa.bairro }}</td>
        <td class="table-cell">{{ pessoa.email }}</td>
        <td class="table-cell">{{ pessoa.cidade }}</td>
        <td class="table-cell">{{ pessoa.estado }}</td>
        <td class="table-cell">{{ pessoa.estadoCivil }}</td>
      </tr>
      </tbody>
    </card-table>

    <template #footer>
      <button type="button" @click.prevent="close" class="btn btn-outline-secondary mr-auto">Cancelar</button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";

export default {
  name: "ModalVinculoSolicitante",

  components: {CardTable, Modal},
  props: {
    pessoas: Array,
  },

  data() {
    return {
      solicitante: {}
    }
  },

  methods: {
    select(pessoa) {
      FrontBusiness.closeModal({id: pessoa.id, nome: pessoa.nome});
    },

    close() {
      FrontBusiness.closeModal();
    }
  }
}
</script>

<style scoped lang="scss">
.table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
}
</style>

import Vue from 'vue';

const EventBus = new Vue({
  methods:{
    setTitle(title){
      document.title = `${title.replace(' - Sistema Asgard', '')} - Sistema Asgard`
    }
  }
});

export default EventBus;

import Http from "@/commons/Http";
import FrontBusiness from "@/business/FrontBusiness";
import Utils from "@/commons/Utils";
import FracaoTransferenciaImovelBusiness from "@/business/protocolo/FracaoTransferenciaImovelBusiness";

const path = 'api/ato-legado';

const PAPEIS_TRANSMITENTE = ['TRANSMITENTE', 'CEDENTE', 'PROMITENTE_VENDEDOR'];
const PAPEIS_ADQUIRENTE = ['ADQUIRENTE', 'CESSIONARIO', 'PROMITENTE_COMPRADOR'];

export default {

  getPapeisTransmitente(){
    return PAPEIS_TRANSMITENTE;
  },

  getPapeisAdquirente(){
    return PAPEIS_ADQUIRENTE;
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async getById(id){
    return Http.get(`api/protocolo/legado/ato/${id}`);
  },

  async validarEnvolvidos(envolvidos, ato){
    let percentualTransmitente = new Utils.bigDecimal.BigDecimal.ZERO;
    percentualTransmitente.setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP);
    let percentualAdquirente = new Utils.bigDecimal.BigDecimal.ZERO;
    percentualAdquirente.setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP);

    if(Utils.isEmptyNullOrUndefined(envolvidos)) envolvidos = [];
    envolvidos = envolvidos.filter(e => (PAPEIS_TRANSMITENTE.includes(e.papel) || PAPEIS_ADQUIRENTE.includes(e.papel)));

    if(envolvidos.length === 0 && ato.tipoServico?.opcoes?.tipoTransacaoDoi !== 'NENHUM') {
      throw 'Um ato que gera DOI deve possuir ao menos um envolvido com os um dos papeis (Transmitente, Cedente ou Promitente Transmitente) e um envolvido com os papeis (Adquirente, Cessionário ou Promitente Adquirente)';
    }

    // const transmitenteValidaPorcentagemDoi = envolvidos.find(e => e.ajusteDoi === true && PAPEIS_TRANSMITENTE.includes(e.papel));
    // const adquirenteValidaPorcentagemDoi = envolvidos.find(e => e.ajusteDoi === true && PAPEIS_ADQUIRENTE.includes(e.papel));
    // const transmitentes = envolvidos.reduce((total, e) => total + (PAPEIS_TRANSMITENTE.includes(e.papel) ? e.fracao : 0), 0);
    // const adquirentes = envolvidos.reduce((total, e) => total + (PAPEIS_ADQUIRENTE.includes(e.papel) ? e.fracao : 0), 0);

    // if((adquirentes != transmitentes && ((transmitentes > 0 && transmitentes != 100 && !transmitenteValidaPorcentagemDoi) || (adquirentes > 0 && adquirentes != 100 && !adquirenteValidaPorcentagemDoi)))
    //   || ((transmitentes > 100 && !transmitenteValidaPorcentagemDoi) || (adquirentes > 100 && !adquirenteValidaPorcentagemDoi))){
    //   throw `Necessário selecionar um envolvido para validar a porcentagem da DOI`;
    // }

    envolvidos.forEach(envolvido => {
      envolvido.fracao = envolvido?.fracao || 0;
      if(PAPEIS_TRANSMITENTE.includes(envolvido.papel)){
        percentualTransmitente = percentualTransmitente.add(new Utils.bigDecimal.BigDecimal(envolvido.fracao).setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP));
      }
      if(PAPEIS_ADQUIRENTE.includes(envolvido.papel)){
        percentualAdquirente = percentualAdquirente.add(new Utils.bigDecimal.BigDecimal(envolvido.fracao).setScale(10, new Utils.bigDecimal.RoundingMode.HALF_UP));
      }

      // if(app.__vue__.config.estado === 'SC'){
      //   let campos = ['cep', 'logradouro', 'numero', 'bairro', 'cidade', 'estado' ].filter(c => {
      //     return Utils.isEmptyNullOrUndefined(envolvido.indicadorPessoalVersao[c])
      //   });
      //   if(campos?.length){
      //     throw `O endereço do envolvido ${envolvido.nome} está incompleto: ${FrontBusiness.formatArray(campos)}`;
      //   }
      // }

    });

    let validarFracao = !(ato?.ficha?.livro === 'REGISTRO_AUXILIAR' && ato?.tipoServico?.opcoes?.tipoAto === 'ABERTURA');
    if (validarFracao) {
      const validacaoFracao = await FracaoTransferenciaImovelBusiness.validarMargemTransferenciaAto(percentualTransmitente.doubleValue(), percentualAdquirente.doubleValue(), ato.tipoServico.opcoes.tipoAto === 'ABERTURA');
      if(validacaoFracao !== null && validacaoFracao.cssClass === 'danger'){
        throw validacaoFracao.mensagem;
      }
    }
  }

}

import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";

export default {

  async testServer(){
    return Http.get('/api/public/version').catch(() => false);
  },

  async testarConexao(key, i){
    return Http.get(`/api/public/version/${key}/${i}`).catch((e) => console.error(key, i, e));
  },

  async getLoggedUser(){
    return Http.get('/api/autenticacao').catch((e) => {
      console.error(e);
      return null;
    });
  },

  async authenticateGoogleToken(token) {
    return Http.post('/api/autenticacao/google/?token=' + token);
  },

  async heartbeat(id){
    return CachedApi.getCached('heartbeat-'+id, async () => {
      return Http.get(`/hb?i=${id}`).catch(() => {});
    });
  },

  async logout(){
    await Http.post('logout');
  },

  async login(username, password){
    await this.logout();
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return Http.post('/login', params);

  },

  senhaValida(value = ''){
    let validacoes = {};
    let letrasMaiusculas = /[A-Z]/, letrasMinusculas = /[a-z]/, numeros = /[0-9]/, caracteresEspeciais = /[.|,|!|@|#|$|%|^|&|*|(|)|-|_]/;
    let auxMaiusculo = 0, auxMinusculo = 0, auxNumero = 0, auxEspecial = 0;
    for(let i=0; i < value.length; i++){
      if(letrasMaiusculas.test(value[i])) {
        auxMaiusculo++;
      } else if(letrasMinusculas.test(value[i])) {
        auxMinusculo++;
      } else if(numeros.test(value[i])) {
        auxNumero++;
      } else if(caracteresEspeciais.test(value[i])) {
        auxEspecial++;
      }
    }
    validacoes.quantidade = value.length >= 12;
    validacoes.maiusculo = auxMaiusculo > 0;
    validacoes.minusculo = auxMinusculo > 0;
    validacoes.numero = auxNumero > 0;
    validacoes.caracterEspecial = auxEspecial > 0;
    validacoes.valida = validacoes.quantidade && validacoes.maiusculo && validacoes.minusculo && validacoes.numero && validacoes.caracterEspecial;
    return validacoes;
  },

  async validarAcesso() {
    return Http.get(`api/usuario/public/validar-acesso`);
  }

}

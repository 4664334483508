<template>
  <div id="aba-dados-principais">

    <card titulo="Dados Básicos" v-if="exibirDadosBasicos">
      <card-content>

        <p v-if="pessoa.nome">
          <span class="font-weight-500">Nome: </span><span>{{pessoa.nome}}</span>
        </p>

        <template v-if="pessoa.tipoPessoa == 'FISICA'">

          <p v-if="pessoa.documento">
            <span class="font-weight-500">CPF: </span><span>{{pessoa.documento}}</span>
            <alert-icon v-if="pessoa.documentoInvalido" :message="pessoa.documentoInvalido" icon="fas fa-exclamation-triangle ml-1" />
          </p>
          <p v-if="pessoa.sexo">
            <span class="font-weight-500">Sexo: </span><span>{{labelSexo}}</span>
          </p>
          <p v-if="pessoa.nascimento">
            <span class="font-weight-500">Data de nascimento: </span><span>{{$formatters.date(pessoa.nascimento)}}</span>
          </p>
          <p v-if="pessoa.nacionalidade">
            <span class="font-weight-500">Nacionalidade: </span><span>{{labelNacionalidade}}</span>
          </p>
          <p v-if="pessoa.estadoCivil">
            <span class="font-weight-500">Estado civil: </span><span>{{labelEstadosCivil}}</span>
          </p>
          <p v-if="pessoa.profissao">
            <span class="font-weight-500">Profissão: </span><span>{{pessoa.profissao}}</span>
          </p>
          <p v-if="pessoa.nomePai">
            <span class="font-weight-500">Nome do pai: </span><span>{{pessoa.nomePai}}</span>
          </p>
          <p v-if="pessoa.nomeMae">
            <span class="font-weight-500">Nome da mãe: </span><span>{{pessoa.nomeMae}}</span>
          </p>
          <p v-if="pessoa.estadoCivil !== 'CASADO'">
            <span class="font-weight-500">União Estável: </span><span>{{labelUniaoEstavel}}</span>
          </p>

          <p v-if="pessoa.expostaPoliticamente">
            <span class="font-weight-500">Pessoa exposta politicamente: Sim</span>
          </p>

          <p v-if="pessoa.falecido">
            <span class="font-weight-500">Pessoa falecida: Sim</span>
          </p>

        </template>

        <template v-if="pessoa.tipoPessoa == 'JURIDICA'">

          <p v-if="pessoa.documento">
            <span class="font-weight-500">CNPJ: </span><span>{{pessoa.documento}}</span>
            <alert-icon v-if="pessoa.documentoInvalido" :message="pessoa.documentoInvalido" icon="fas fa-exclamation-triangle ml-1" />
          </p>

          <p v-if="pessoa.inscricaoEstadual">
            <span class="font-weight-500">Inscrição estadual: </span><span>{{pessoa.inscricaoEstadual}}</span>
          </p>

          <p v-if="pessoa.inscricaoMunicipal">
            <span class="font-weight-500">Inscrição municipal: </span><span>{{pessoa.inscricaoMunicipal}}</span>
          </p>

          <p>
            <span class="font-weight-500">Participação Estrangeira: </span><span>{{pessoa.possuiParticipacaoEstrangeira ? 'Sim' : 'Não'}}</span>
          </p>

          <p v-if="pessoa.naturezaJuridica">
            <span class="font-weight-500">Natureza Jurídica: </span><span>{{labelNaturezaJuridica}}</span>
          </p>

          <p v-if="pessoa.naturezaJuridicaOutras">
            <span class="font-weight-500">Descrição: </span><span>{{pessoa.naturezaJuridicaOutras}}</span>
          </p>
          <p v-if="pessoa.substituicaoTributaria">
            <span class="font-weight-500">Substituto Tributário: </span><span>{{pessoa.substituicaoTributaria ? 'Sim' : 'Não'}}</span>
          </p>

        </template>

      </card-content>
    </card>

    <card :titulo="`Dados  ${consorcio}`" v-if="exibirDadosCasamento">
      <card-content>

        <p v-if="pessoa.conjuge">
          <span class="font-weight-500">{{consorte}}: </span><span><a href="" @click.prevent="detalhes(pessoa.conjuge)">{{pessoa.conjuge.nome}} (Versão: {{pessoa.conjuge.versao}})</a></span>
        </p>

        <p v-if="!Object.is(pessoa.casamentoAposLei6515, null)">
          <span class="font-weight-500">Após Lei nª 6.515: </span><span>{{pessoa.casamentoAposLei6515 ? 'Sim' : 'Não'}}</span>
        </p>

        <p v-if="pessoa.dataCasamento">
          <span class="font-weight-500">Data {{consorcio}}: </span><span>{{$formatters.date(pessoa.dataCasamento)}}</span>
        </p>

        <p v-if="pessoa.regimeCasamento">
          <span class="font-weight-500">Regime: </span><span>{{labelRegimeCasamento}}</span>
        </p>

        <p v-if="pessoa.detalhesRegime">
          <span class="font-weight-500">Detalhes do regime: </span><span v-html="pessoa.detalhesRegime"></span>
        </p>

        <p v-if="pessoa.numeroRegistroPactoAntenupcial">
          <span class="font-weight-500">Número do registro: </span><span v-html="pessoa.numeroRegistroPactoAntenupcial"></span>
        </p>

        <p v-if="pessoa.registroImoveisPactoAntenupcial">
          <span class="font-weight-500">Registro de imóveis: </span><span v-html="pessoa.registroImoveisPactoAntenupcial"></span>        </p>

      </card-content>
    </card>

    <card titulo="Identificação" v-if="pessoa.rg || pessoa.cnh || pessoa.cip || pessoa.outrosDocumentos">
      <card-content>

        <p v-if="pessoa.rg">
          <span class="font-weight-500">RG: </span><span>{{pessoa.rg}}</span>
        </p>
        <p v-if="pessoa.cnh">
          <span class="font-weight-500">CNH: </span><span>{{pessoa.cnh}}</span>
        </p>
        <p v-if="pessoa.cip">
          <span class="font-weight-500">Identidade Profissional: </span><span>{{pessoa.cip}}</span>
        </p>
        <p v-if="pessoa.passaporte">
          <span class="font-weight-500">Passaporte: </span><span>{{pessoa.passaporte}}</span>
        </p>
        <p v-if="pessoa.outrosDocumentos">
          <span class="font-weight-500">Outros: </span><span>{{pessoa.outrosDocumentos}}</span>
        </p>

      </card-content>
    </card>

    <card titulo="Contato" v-if="pessoa.email || pessoa.telefone || pessoa.celular || pessoa.comercial">
      <card-content>

        <p v-if="pessoa.email">
          <span class="font-weight-500">E-mail: </span><span>{{pessoa.email}}</span>
        </p>
        <p v-if="pessoa.telefone">
          <span class="font-weight-500">Telefone: </span><span>{{pessoa.telefone}}</span>
        </p>
        <p v-if="pessoa.celular">
          <span class="font-weight-500">Celular: </span><span>{{pessoa.celular}}</span>
        </p>
        <p v-if="pessoa.comercial">
          <span class="font-weight-500">Comercial: </span><span>{{pessoa.comercial}}</span>
        </p>

      </card-content>
    </card>

    <card titulo="Endereço" v-if="exibirEndereco">
      <card-content>

        <p v-if="pessoa.cep">
          <span class="font-weight-500">CEP: </span><span>{{pessoa.cep}}</span>
        </p>
        <p v-if="pessoa.logradouro">
          <span class="font-weight-500">Logradouro: </span><span>{{pessoa.logradouro}}</span>
        </p>
        <p v-if="pessoa.numero">
          <span class="font-weight-500">Número: </span><span>{{pessoa.numero}}</span>
        </p>
        <p v-if="pessoa.lote">
          <span class="font-weight-500">Lote: </span><span>{{pessoa.lote}}</span>
        </p>
        <p v-if="pessoa.quadra">
          <span class="font-weight-500">Quadra: </span><span>{{pessoa.quadra}}</span>
        </p>
        <p v-if="pessoa.bairro">
          <span class="font-weight-500">Bairro: </span><span>{{pessoa.bairro}}</span>
        </p>
        <p v-if="pessoa.cidade">
          <span class="font-weight-500">Cidade: </span><span>{{pessoa.cidade}}</span>
        </p>
        <p v-if="pessoa.estado">
          <span class="font-weight-500">Estado: </span><span>{{pessoa.estado}}</span>
        </p>
        <p v-if="pessoa.complemento">
          <span class="font-weight-500">Complemento: </span><span>{{pessoa.complemento}}</span>
        </p>

        <p v-if="pessoa.pais">
          <span class="font-weight-500">País: </span><span>{{labelPais(pessoa.pais)}}</span>
        </p>

      </card-content>
    </card>

    <card titulo="Endereço comercial" v-if="exibirEnderecoComercial">
      <card-content>

        <p v-if="pessoa.cepComercial">
          <span class="font-weight-500">CEP: </span><span>{{pessoa.cepComercial}}</span>
        </p>
        <p v-if="pessoa.logradouroComercial">
          <span class="font-weight-500">Logradouro: </span><span>{{pessoa.logradouroComercial}}</span>
        </p>
        <p v-if="pessoa.numeroComercial">
          <span class="font-weight-500">Número: </span><span>{{pessoa.numeroComercial}}</span>
        </p>
        <p v-if="pessoa.loteComercial">
          <span class="font-weight-500">Lote: </span><span>{{pessoa.loteComercial}}</span>
        </p>
        <p v-if="pessoa.quadraComercial">
          <span class="font-weight-500">Quadra: </span><span>{{pessoa.quadraComercial}}</span>
        </p>
        <p v-if="pessoa.bairroComercial">
          <span class="font-weight-500">Bairro: </span><span>{{pessoa.bairroComercial}}</span>
        </p>
        <p v-if="pessoa.cidadeComercial">
          <span class="font-weight-500">Cidade: </span><span>{{pessoa.cidadeComercial}}</span>
        </p>
        <p v-if="pessoa.estadoComercial">
          <span class="font-weight-500">Estado: </span><span>{{pessoa.estadoComercial}}</span>
        </p>
        <p v-if="pessoa.complementoComercial">
          <span class="font-weight-500">Complemento: </span><span>{{pessoa.complementoComercial}}</span>
        </p>
        <p v-if="pessoa.paisComercial">
          <span class="font-weight-500">País: </span><span>{{labelPais(pessoa.paisComercial)}}</span>
        </p>

      </card-content>
    </card>

    <card titulo="Observações" v-if="pessoa.observacao">
      <card-content>
        <div v-html="pessoa.observacao"></div>
      </card-content>
    </card>

    <card titulo="Identificação no sistema">
      <card-content>
        <p>
          <span class="font-weight-500">Identificação da Pessoa: </span><span>{{pessoa.indicadorPessoalId}}</span>
        </p>
        <p>
          <span class="font-weight-500">Identificador da Versão: </span><span>{{pessoa.id}}</span>
        </p>
        <p v-if="pessoa.cadastro">
          <span class="font-weight-500">Cadastro da Versão: </span><span>{{pessoa.cadastro | datahora}}</span>
        </p>
        <p v-if="pessoa.atualizacao">
          <span class="font-weight-500">Atualização da Versão: </span><span>{{pessoa.atualizacao | datahora}}</span>
        </p>
      </card-content>
    </card>

  </div>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import RestricoesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/RestricoesIndicadorPessoal.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import AlertIcon from "@/components/alert/AlertIcon.vue";

  export default {

    name: "Dados",
    components: {CardContent, Card, AlertIcon},
    props: {
      pessoa : Object
    },

    data() {
      return {
        naturezaJuridica: []
      }
    },

    async mounted() {
      this.naturezaJuridica = IndicadorPessoalBusiness.getNaturezaJuridica();
    },

    computed:{

      exibirEndereco(){
        return ['cep','logradouro','numero','bairro','cidade','estado','complemento','quadra','lote'].some(e => this.pessoa[e]);
      },

      exibirEnderecoComercial(){
        return ['cepComercial','logradouroComercial','numeroComercial','bairroComercial','cidadeComercial','estadoComercial','complementoComercial','quadraComercial','loteComercial'].some(e => this.pessoa[e]);
      },

      exibirDadosBasicos(){
        return ['sexo','nascimento','nacionalidade','estadoCivil','profissao','nomePai','nomeMae'].some(e => this.pessoa[e]) || this.pessoa.tipoPessoa == 'JURIDICA';
      },

      exibirDadosCasamento(){
        return ['conjuge','casamento','regimeCasamento','detalhesRegime'].some(e => this.pessoa[e]);
      },

      consorcio(){
        return this.pessoa.possuiUniaoEstavel && this.pessoa.estadoCivil !== 'CASADO' ? 'União Estável': 'Casamento';
      },
      consorte(){
        return this.pessoa.possuiUniaoEstavel && this.pessoa.estadoCivil !== 'CASADO' ? 'Convivente': 'Cônjuge';
      },

      labelSexo() {
        return FrontBusiness.getLabel(this.pessoa.sexo, IndicadorPessoalBusiness.getSexos());
      },

      labelEstadosCivil() {
        return FrontBusiness.getLabel(this.pessoa.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil());
      },

      labelUniaoEstavel() {
        return this.pessoa.possuiUniaoEstavel ? 'Sim' : (this.pessoa.possuiUniaoEstavel === null ? 'Não Informado' : 'Não');
      },

      labelRegimeCasamento() {
        return FrontBusiness.getLabel(this.pessoa.regimeCasamento, IndicadorPessoalBusiness.getRegimes());
      },

      labelNacionalidade() {
        return FrontBusiness.getLabel(this.pessoa.nacionalidade, IndicadorPessoalBusiness.getNacionalidades());
      },

      labelNaturezaJuridica() {
        return FrontBusiness.getLabel(this.pessoa.naturezaJuridica, this.naturezaJuridica);
      },

    },

    methods:{

        detalhes(pessoa) {
            FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao : pessoa.id});
        },

        labelPais(idNacionalidade){
          return IndicadorPessoalBusiness.getLabelPais(idNacionalidade);
        }

    }
  }
</script>

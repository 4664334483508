<template>

  <card titulo="Múltiplas Cobranças">

    <v-input :disabled="!edicao" v-model="ato.dto.multiplasCobrancas" label="Incluir Mútiplas Cobranças?" type="radio" class="pb-2" />

    <template v-if="ato.dto.multiplasCobrancas">

      <card-table class="table-borderless">
        <thead>
          <tr>
            <th width="200px">Quantidade</th>
            <th class="pr-0">Base Cálculo</th>
            <th width="20px">
              <a href="" @click.prevent="add()" class="btn btn-outline-secondary btn-xs" v-if="edicao">
                <i class="fa fa-plus"></i>
              </a>
            </th>
          </tr>
        </thead>

        <tbody>

          <tr v-for="(campo, idx) in ato.dto.valoresMultiplasCobrancas" :key="idx">
            <td>
              <v-input type="number" class="col" v-model.number="campo.quantidade" :disabled="!edicao" placeholder="Quantidade" :min="1"/>
            </td>
            <td>
              <v-input class="col" v-model="campo.valorBase" type="currency" :disabled="!edicao"
                       :input-props="{precision : {min : 2, max : 10}}" />
            </td>
            <td class="text-right">
              <div class="ato-action" v-if="edicao">
                <a href="" @click.prevent="remove(idx)" class="icon"><i class="fa fa-times"></i></a>
              </div>
            </td>
          </tr>

          <tr v-if="!ato.dto.valoresMultiplasCobrancas || !ato.dto.valoresMultiplasCobrancas.length">
            <td colspan="100%" class="p-0">
              <div class="alert alert-outline-secondary alert-sm mb-0">
                Nenhuma cobrança cadastrada
              </div>
            </td>
          </tr>

        </tbody>

      </card-table>

    </template>



  </card>

</template>

<script>
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";

export default {

    name: "MultiplasCobrancas",
    components: {CardTable, Card},
    props: ['ato', 'edicao', 'dominio', 'tipo'],

    created() {
      this.ato.dto.valoresMultiplasCobrancas = this.ato.dto.valoresMultiplasCobrancas || [];
      if(!this.ato.dto.multiplasCobrancas) this.ato.dto.multiplasCobrancas = false;
    },

    computed:{

    },

    methods : {
      add() {
        this.ato.dto.valoresMultiplasCobrancas.push({quantidade: 1, valorBase:0.00});
        this.$forceUpdate();
      },
      remove(idx){
        this.ato.dto.valoresMultiplasCobrancas.splice(idx, 1);
        this.$forceUpdate();
      }
    },

  }
</script>


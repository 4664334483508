<template>
  <div class="modal-content" :class="{'full-height' : !maxHeight}">

    <div class="modal-header" v-if="!hideTitle && titulo">
      <div class="d-flex">

        <div class="col">
          <div class="d-flex align-items-center">
            <h5 class="modal-title mr-1">
              {{titulo}}
              <i v-if="check" class="far fa-check-circle text-success" title="Validado"></i>
            </h5>
            <slot name="title-extra"></slot>
          </div>
        </div>

        <div class="col-auto text-right">
          <ul class="list-inline ml-2 mb-0">

            <slot name="actions"></slot>

            <li class="list-inline-item">
              <a class="pointer mover-modal" href="javascript:void(0)" title="Mover">
                <i class="fas fa-arrows-alt"></i>
              </a>
            </li>

            <li class="list-inline-item mr-1" v-if="closeable">
              <a class="pointer" href title="Fechar" @click.prevent="close()" data-testid="fechar-modal">
                <i class="far fa-times"></i>
              </a>
            </li>

          </ul>
        </div>

      </div>

      <slot name="header"></slot>

    </div>

    <div id="overlay" v-if="loading">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="loadingTxt" class="mt-1">{{ loadingTxt }}</span>
      </div>
    </div>

    <div class="modal-body" ref="modal_body" :class="{'has-footer' : hasFooter}">
      <slot></slot>
    </div>

    <div class="modal-footer" v-if="hasFooter">
      <div class="d-flex" :class="{'align-end' : justifyEnd}">
        <slot name="footer"></slot>
      </div>
    </div>

  </div>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness";
import EventBus from "@/commons/EventBus";

export default {
    name: "Modal",
    props: {
      titulo: String,
      loading: Boolean,
      loadingTxt: String,
      fechar: Boolean,
      hideTitle: {type: Boolean, default: false},
      check: {type: Boolean, default: false},
      focus: {type: Boolean, default: true},
      closeable: {type: Boolean, default: true},
      closeEsc: {type: Boolean, default: true},
      maxHeight: {type: Boolean, default: true},
      justifyEnd: {type : Boolean, default : false},
    },

    data() {
      return {}
    },

    computed: {
      hasFooter() {
        return !!this.$slots.footer;
      }
    },

    mounted() {
      this.$nextTick(function () {
        let elements = (this.$refs.modal_body).querySelectorAll('input, input.form-check-input, select, textarea, .document-editor__editable, .add-editor')
        if (Object.keys(elements).length > 0 && this.focus) elements[0].focus()
      })
      document.addEventListener("keydown", this.escClose);
    },

    beforeDestroy() {
      document.removeEventListener('keydown', this.escClose)
    },

    methods: {
      close() {
        if (!this.fechar) {
          FrontBusiness.closeModal();
        } else {
          this.$emit('close');
        }
      },
      escClose(e) {
        e.stopPropagation();
        if (e.keyCode === 27 && this.closeEsc && this.closeable) {
          EventBus.$emit('close-esc');
          FrontBusiness.closeModal();
        }
      }
    }
  }
</script>

<style>
  .modal-content.full-height .modal-body{
    max-height: unset !important;
  }
  .modal-header .nav.nav-tabs{
    border-bottom: none;
    margin: 5px -14px -10px;
    padding: 0 10px 0;
    border-top: 1px solid var(--asgard-input);
  }
  .modal-header .nav.nav-tabs a{
    color: var(--asgard-muted);
  }
  .align-end{
    justify-content: space-between;
  }
</style>

<template>
  <consulta ref="modal" :columns="columns" :process="process" id="consultar-indicador-real"
            :titulo="titulo" :grabbable="true" :mlAuto="!marcados.length" :filtrosValidos="!pesquisando"
            :custom-actions="actions">
    <template #cabecalho>

      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>

    </template>

    <template #filtros>

      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
      </filtrar-consulta>

      <template v-if="mode == 'default'">
        <v-input class="col-2" v-model="filtros.codigo" type="text" label="Código" />

        <v-input type="v-select" :options="options.serventias" label="Serventia" class="col"
                 v-model="filtros.serventia"  />

        <v-input v-model="filtros.tipo" :options="options.tipos" :disabled="!!tipo" type="select" class="col-2" label="Tipo" />
        <div class="w-100"/>
        <v-input class="col-2" v-model="filtros.livro_numero" type="text" label="Livro (Número)" />
        <v-input class="col-2" v-model="filtros.livro_letra" type="text"  label="Livro (Letra)" />
        <v-input class="col-2" v-model="filtros.livro_folha" type="text"  label="Folha" />

      </template>

    </template>

    <template #row="{ row }">
      <tr>
        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectIndicador(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <a href class="icon" @click.prevent.stop="detalhes(row.id)">
            <i class="fas fa-home"></i>
          </a>
        </td>

        <td width="140px" class="nowrap">
          <a class="pointer icon mr-2" @click="select(row)">
            {{ nomearFicha(row) }}
          </a>
          <alert-protocolo-ativos ref="aletaProtocoloAtivos" :imovel="row" :externo="true"/>
        </td>

        <td>{{ row.serventia }}</td>
        <td>{{ row.livroNumero }}</td>
        <td>{{ row.livroLetra }}</td>
        <td>{{ row.livroFolha }}</td>

      </tr>

    </template>

    <template #footer>

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="select" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness.js";
import ListaBusiness from "@/business/crud/ListaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness.js";
import Utils from "@/commons/Utils.js";
import Consulta from "@/components/Consultas/Consulta.vue";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import DetalhesIndicadorRealExterno from "@/components/IndicadorRealExterno/Detalhes/DetalhesIndicadorRealExterno.vue";
import FormIndicadorRealExterno from "@/components/IndicadorRealExterno/Form/FormIndicadorRealExterno.vue";
import AlertProtocoloAtivos from "@/components/alert/AlertProtocolosAtivos.vue";

export default {
  name: "ConsultarIndicadorRealExterno",
  components: {AlertProtocoloAtivos, FiltrarConsulta, Consulta},
  props: {
    tipo : String,
    multiplos : {type : Boolean, default : false},
    seleciona : {type : Boolean, default : true},
  },
  data() {
    let filtros = []
    if(this.tipo){
      filtros.push({id: "tipo",type: null,value: this.tipo, hiddenActions: true, disabled: true});
    }
    return {
      mode: 'novo',
      opcoesConsulta: [],
      validation: {},
      filtros : {
        tipo: this.tipo,
        livro_letra: null,
        livro_numero: null,
        livro_folha: null,
        filtros
      },
      options : {
        serventias: [],
        tipos: [
          {id: 'MATRICULA', nome: 'Matrícula', livro: "MATRICULA"},
          {id: 'TRANSCRICAO', nome: 'Transcrição', livro: "TRANSCRICAO"},
          {id: 'TORRENS', nome: 'Torrens', livro: "TORRENS"},
        ]
      },
      marcados: [],
      pesquisando: false,
    }
  },

  async mounted() {
    let config = this.$root.config;
    let serventia = await ListaBusiness.getById( config?.listaRegistroAnterior?.id);
    this.$set(this.options, 'serventias', (serventia || {lista: []}).lista.map(e => ({
      id: e,
      nome: e
    })));

    let opcoes = [];
    opcoes.push({id: 'codigo', nome: 'Código', type: 'text'});
    opcoes.push({id: 'serventia', nome: 'Serventia', type: 'list', options: this.options.serventias});
    opcoes.push({id: 'tipo', nome: 'Tipo', type: 'list', options: this.options.tipos});
    opcoes.push({id: 'livro_numero', nome: 'Livro (Número)', type: 'text'});
    opcoes.push({id: 'livro_letra', nome: 'Livro (Letra)', type: 'text'});
    opcoes.push({id: 'livro_folha', nome: 'Folha', type: 'text'});
    this.opcoesConsulta = Utils.orderBy(opcoes, 'nome');
  },

  computed:{

    columns(){

      let colunas = [];
      colunas.push({label: '', show: this.multiplos, field: 'selecionar', headerClass: 'column__botao', sortable: false, name: 'selecionar'});
      colunas.push({label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'});
      colunas.push({label: 'Código', field: 'ire.codigo', headerClass: 'column__codigo', name: 'codigo'});
      colunas.push({label: 'Serventia', field: 'ire.serventia', headerClass: 'column__serventia'});
      colunas.push({label: 'Livro (Número)', field: 'ire.livro_numero', headerClass: 'column__livro_numero', minWidth:'150px'});
      colunas.push({label: 'Livro (Letra)', field: 'ire.livro_letra', headerClass: 'column__livro_letra', minWidth:'150px'});
      colunas.push({label: 'Folha', field: 'ire.livro_folha', headerClass: 'column__livro_folha', minWidth:'150px'});

      return colunas;
    },

    titulo() {
      let titulo = "Consultar Indicador Real Externo";
      if (this.tipo) {
        titulo += ` - ${FrontBusiness.getLabel(this.tipo, this.options.tipos)}`
      }
      return titulo;
    },

    actions() {
      let actions = [];
      if (this.auditor) return actions;
        actions.push({
          ordem: 1,
          class: 'at-options',
          icon: 'far fa-plus-square mr-1',
          label: 'Cadastrar',
          action: this.cadastrar
        });
      return  Utils.orderBy(actions, 'ordem');
    }
  },

  methods:{

    nomearFicha: IndicadorRealExternoBusiness.nomearFicha,

    close(){
      FrontBusiness.closeModal();
    },

    select(indicador){
      if (this.seleciona) {
        FrontBusiness.closeModal(indicador);
      } else {
        this.detalhes(indicador?.id)
      }
    },

    async process(sort_by, sort_dir, page_length, page_number){

      if(this.mode == 'novo'){

        let filtros = [...this.filtros?.filtros].filter(e => e.id);

        return IndicadorRealExternoBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
          .then(result => ({rows: result.pagina, total: result.quantidade}));
      }

      let filtros = Utils.clone(this.filtros);
      return IndicadorRealExternoBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros: filtros})
        .then(result => ({rows: result.pagina, total: result.quantidade}));

    },

    detalhes(id){
      FrontBusiness.openModal(DetalhesIndicadorRealExterno, {id});
    },

    async cadastrar(){
      const indicador = await FrontBusiness.openModal(FormIndicadorRealExterno, {retornarObject: true, livro: this.livro});
      if(indicador){
        await this.$refs.modal.load()
        FrontBusiness.openModal(DetalhesIndicadorRealExterno, {id: indicador?.id});
      }
    },

    selectIndicador(ficha) {
      let index = this.marcados.findIndex(e => e.id === ficha.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(ficha)
      }
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    }

  }
}
</script>

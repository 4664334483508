import AuthBusiness from "@/business/AuthBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import TextParser from "@/business/livros/TextParser.js";
import Http from "@/commons/Http";
import ValidationUtils from "@/commons/ValidationUtils.js";

const path = 'api/notificacao';

export default {

  categoriasNotificacao: Object.freeze([
    {id:'NOTIFICACAO',nome:'Notificação'},
    {id:'AUTUACAO',nome:'Autuação'},
  ]),

  baixarNotificacao(notificacao){
    return `${path}/${notificacao}/pdf`;
  },

  nomearEntidade(notificacao, code = true){
    return `Notificação ${code ? FrontBusiness.formatarCodigo(notificacao?.numero) +'/'+ notificacao?.ano: ''}`;
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  paginationExcel(sort, direction, page, size, filters = {}){
    return `${path}/pagination/export-excel?sort=o.cadastro,desc&page=${page || 1}&size=${size || 10}`;
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  paginationExcelV2(sort, direction, page, size){
    return `${path}/v2/pagination/export-excel?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`;
  },

  async getById(indicador){
    return Http.get(`${path}/${indicador}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async saveForm(dto, id = ''){
    return Http.post(`${path}/form/${id}`, dto);
  },

  salvarAdiamento(notificacao, dto) {
    return Http.post(`${path}/${notificacao}/salvar-adiamento`, dto);
  },

  async regerarChecklist(id){
    return Http.post(`${path}/${id}/checklist`);
  },

  async getAuditorias(id, page, size){
    return Http.get(`${path}/${id}/auditorias?page=${page || 1}&size=${size || 10}`);
  },

  async vinculos(id){
    return Http.get(`${path}/${id}/vinculos`);
  },

  async buscarPorCodigos(codigos) {
    if(!ValidationUtils.validateLongArray({values: codigos, min: 0, max: 99999999})){
      throw 'Intervalo Inválido';
    }
    return await Http.post(`${path}/codigos`, codigos);
  },

  async copilarTemplate(template, notificacao = {}, debug = {}){

    const dicionario = {
      checklist : {},
      notificacao:{
        codigo : notificacao.codigo,
        texto : notificacao.texto,
        cadastro : notificacao.cadastro
      }
    };

    await AuthBusiness.getLoggedUser().then(logged => dicionario.usuarioLogado = { ...logged, senha : undefined });

    // INDICES
    if (notificacao.indices != null) {
      Object.keys(notificacao.indices).forEach(i => {
        dicionario.checklist[i] = notificacao.indices[i];
        dicionario.checklist[i.toUpperCase()] = notificacao.indices[i];
        dicionario.checklist[i.toLowerCase()] = notificacao.indices[i];
      });
    }

    for(let eI in notificacao.envolvidos){
      await TextParser.copilarDicionarioEnvolvido(notificacao.envolvidos[eI], dicionario.checklist.envolvidos?.[id]);
    }

    dicionario.notificacao.envolvidos = notificacao?.envolvidos;
    dicionario.notificacao.protocolos = notificacao?.protocolos;
    dicionario.notificacao.matriculas = notificacao?.matriculas;
    dicionario.notificacao.enderecos = notificacao?.enderecos;

    const callback = async (nodes = [], dicionario = {}, debug = undefined) => {

      let output = '';

      for(let pos in nodes){

        let node = nodes[pos];
        let debugNode = { tipo : node.name, nodes : [], params : node.params, dicionario : dicionario };
        output += await TextParser.commonsParse(node, dicionario, callback, debugNode);

        // if(debug){
        //   debugNode.conteudo = TextParser.htmlText(node.body);
        //   debugNode.output = Utils.clone(output);
        //
        //   if(debugNode.tipo || debugNode.conteudo){
        //     debug?.nodes.push(Utils.clone(debugNode));
        //   }
        // }

      }

      return output;
    };

    await TextParser.init();
    return TextParser.parse(template, callback, dicionario);

  },

  async clonar(id) {
    return Http.post(`${path}/${id}/clonar`);
  },

  getResultadosNotificacao() {
    return [
      {id : 'POSITIVA', nome : 'Positiva'},
      {id : 'NEGATIVA', nome : 'Negativa'},
      {id : 'PARCIAL', nome : 'Parcial'},
      {id : 'CANCELADA', nome : 'Cancelada'},
    ]
  },

  getFormasNotificacao() {
    return [
      {id : 'NOTIFICADOR', nome : 'Notificador'},
      {id : 'CARTA_AR', nome : 'Carta AR'},
    ]
  },
}

<template>
  <modal :titulo="titulo" id="simulacao-registro-titulo" :loading="loading">
    <template #actions>
      <li class="list-inline-item">
        <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
          <i class="fal fa-print"></i>
        </a>
      </li>
    </template>

    <p v-if="loading">
      Processando atos: {{resumo.atual | inteiro}}/{{resumo.total | inteiro}}
    </p>

    <template v-if="fichaAtualizada">

      <template v-for="(ato, i) in erros">
        <erro-validacao :ato="ato" :protocolo="protocolo" :key="ato.id + '-' + i" servico="RegistroTitulo"
                        :exibeNomeServico="false" @abrirErro="abrirErro(...$event)" />
      </template>

      <card titulo="Dados" v-if="mostrarDados">
        <card-table class="table-borderless">
          <thead>
            <tr>
              <th width="33%" class="pl-2">CAMPO</th>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>

            <tr v-if="ficha.status != fichaAtualizada.status">
              <td class="pl-2">Situação</td>
              <td class="text-truncate">{{ficha.status}}</td>
              <td class="text-truncate">{{fichaAtualizada.status}}</td>
            </tr>

          </tbody>
        </card-table>
      </card>

      <card titulo="Texto" v-if="ficha.texto != fichaAtualizada.texto">
        <card-table class="table-borderless">

          <thead>
            <tr>
            <tr>
              <th>ANTES</th>
            </tr>
          </thead>
          <tbody class="mb-2" style="display: grid;">
            <tr>
              <td class="td-text-truncate" v-html="ficha.texto || '-'"></td>
            </tr>
          </tbody>

          <thead>
            <tr>
            <tr>
              <th>DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-text-truncate" v-html="fichaAtualizada.texto || '-'"></td>
            </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Observações" v-if="ficha.observacoes != fichaAtualizada.observacoes">
        <card-table class="table-borderless">

          <thead>
          <tr>
          <tr>
            <th>ANTES</th>
          </tr>
          </thead>
          <tbody class="mb-2" style="display: grid;">
          <tr>
            <td class="td-text-truncate" v-html="ficha.observacoes || '-'"></td>
          </tr>
          </tbody>

          <thead>
          <tr>
          <tr>
            <th>DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="td-text-truncate" v-html="fichaAtualizada.observacoes || '-'"></td>
          </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Atos Praticados">
        <card-content>

          <div v-for="ato in fichaAtualizada.atos" :key="ato.id">

            <p class="mb-2" @dblclick="exportar(ato, ato.codigoStr)">
              <strong>{{ato.codigoStr}} {{ato.tipoServico.opcoes.titulo}}</strong>
            </p>

          </div>

        </card-content>
      </card>

    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import RegistroTituloSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroTituloSimulacaoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
import Modal from "@/components/Modal";
import ErroValidacao from "@/views/Protocolo/Atos/Simulacao/ErroValidacao.vue";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness";
import RegistroPJSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroPJSimulacaoBusiness";

export default {

    name: "SimularRegistroTitulo",
    components: {CardContent, CardTable, Card, ErroValidacao, Modal},
    props: {
      id: String,
      protocolo: Object,
      ato: String,
      texto: String,
      modelo: String,
      tipoItem: {
        type: String,
        default: 'ATO'
      }
    },

    modal: {
        width: 1100
    },

    data() {
      return {
        loading: false,
        ficha: {},
        fichaAtualizada: null,
        erros: [],

        campos: {
          info: [],
          identificacao: [],
          area: [],
          endereco: [],
        },
        resumo : {
          total : 0,
          atual : 0,
          inicio : this.$moment(),
          fim : null
        }

      }
    },

    computed: {
      titulo() {
        let protocolo = this.protocolo;
        return `Simular Resultado: ${FrontBusiness.nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo, protocolo.tipoCadastro)}`
      },

      mostrarDados() {
        return this.ficha.status != this.fichaAtualizada.status
      }

    },

    beforeMount() {
      this.load();
    },

    methods: {

      async load() {
        this.loading = true;

        let documentos = [], atos = [], business, p1, p2, p3;

        IndicadorPessoalBusiness.resetCache();

        if(this.id) {
          p1 = RegistroTDBusiness.getById(this.id).then(f => this.ficha = f);
        }

        switch (this.tipoItem) {
          case 'ATO':
            if (this.protocolo?.id) {
              business = AtoBusiness;
              p2 = ProtocoloDocumentoBusiness.listar(this.protocolo.id).then(f => documentos = f);
              p3 = business.listarPorFicha(this.protocolo.id, 'indicador', this.id).then(f => atos = f);
            }
            break;
          case 'CERTIDAO':
            if (this.protocolo?.id) {
              business = CertidaoBusiness;
              p3 = CertidaoBusiness.listar(this.protocolo.id).then(f => documentos = f);
            }
            break;
        }

        let usuarioLogado = this.$root.usuarioLogado;

        await Promise.all([p1, p2, p3]);

        if (!atos.length && this.ato) {
          let ato = await business.getById(null, this.ato);
          if(ato.envolvidos) ato.envolvidos.forEach(e => e.indicadorPessoalVersaoId = e.indicadorPessoalVersao.id);
          atos = [ato];
        }

        this.resumo.total = atos.length;

        if(this.ato){
          let result;
          switch (this.tipoItem) {
            case 'ATO':
              result = await RegistroTituloSimulacaoBusiness.gerarMinuta(Utils.clone(this.protocolo), Utils.clone(this.ficha), atos, documentos, this.resumo, {id: this.ato, texto : this.texto}, usuarioLogado);
              let ato = result.atos.find(a => a.id == this.ato);
              FrontBusiness.closeModal(ato);
              break;
            case 'CERTIDAO':
              result = await business.gerarMinuta(this.protocolo.id, this.ato, this.modelo);
              FrontBusiness.closeModal(result);
              break;
          }

          return;
        }

        let result = await RegistroTituloSimulacaoBusiness.simular(Utils.clone(this.protocolo), Utils.clone(this.ficha), atos, documentos, this.resumo, usuarioLogado);

        this.resumo.fim =  this.$moment();

        this.fichaAtualizada = result.ficha;
        this.fichaAtualizada.atos = result.atos;
        this.erros = result.atos.filter(a => a.erros.length);

        this.loading = false;
      },

      async abrirErro(ato, areaErroSimulacao) {
        let atoId = ato.id != null ? ato.id : ato.atoId;
        FrontBusiness.closeModal({id: atoId, area: areaErroSimulacao});
      },

      tipo(v) {
        return FrontBusiness.getLabel(v, RegistroTDBusiness.getTiposCedulas());
      },

      exportar(ato, name) {
        console.debug('debug', name, ato.debug);
        const texto = Utils.stringify(ato.debug);
        console.debug('debug', name, texto);
      },

      async imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: ficha.livro, codigo: ficha.codigo,
          simulacao: true, atosSimulacao: this.fichaAtualizada.atos});
      },

    }

  }
</script>

<style lang=scss>
  #simulacao-registro-titulo {
    .td-text-truncate {
      max-width: 729px;
      p {
        white-space: pre-line;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>

<template>

  <card titulo="Ocorrências">

    <template #acoes>
      <a class="text-secondary ml-1" href title="Adicionar Ocorrência" @click.prevent="formulario()">
        <i class="fas fa-plus"></i>
      </a>
    </template>

    <card-table class="table-borderless mb-2" v-if="ocorrencias.length > 0">

      <thead>
        <tr>
          <th>Ocorrência</th>
          <th>Tipo de Ocorrência</th>
          <th>Descrição</th>
          <th>Inicío</th>
          <th>Fim</th>
          <th></th>
        </tr>
      </thead>

      <tbody>

        <tr v-for="ocorrencia in ocorrencias" :key="ocorrencia.id">
          <td>{{'Ocorrência '+ocorrencia.codigo}}</td>
          <td>{{ocorrencia.nome}}</td>
          <td>{{ocorrencia.descricao}}</td>
          <td width="100px">{{$formatters.date(ocorrencia.data)}}</td>
          <td width="100px">{{$formatters.date(ocorrencia.encerramento)}}</td>
          <td width="40px">
            <router-link :to="{ name: 'ocorrencia', params:{id : ocorrencia.id} }" class="icon" target="_blank">
              <i class="fas fa-external-link-alt"></i>
            </router-link>
          </td>
        </tr>

      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!ocorrencias.length && !loading" class="alert alert-outline-info">
      Não há ocorrências para exibir
    </div>

  </card>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import DetalhesOcorrencia from "@/views/Ocorrencia/DetalhesOcorrencia.vue";
  import SmallLoading from "@/components/SmallLoading";

  export default {
    name: "Ocorrencia",
    components: {SmallLoading, CardTable, Card},
    props: {
      ficha : Object
    },

    mounted(){
      this.load();
    },

    data() {
      return {
        ocorrencias : [],
        loading: true,
      }
    },

    methods:{

      async formulario(ocorrencia = {}){
        await FrontBusiness.openModal(DetalhesOcorrencia, { id : ocorrencia.id, matricula: this.ficha });
        await this.load();
      },

      async load(){
        this.ocorrencias = await OcorrenciaBusiness.listarPorIndicadorReal(this.ficha.id);
        this.loading = false;
      },

      labelTipo(tipo) {
        return FrontBusiness.getLabel(tipo, OcorrenciaBusiness.tipos());
      },

    }
  }
</script>

<style lang=scss>

</style>

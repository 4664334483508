<template>
  <div class="loading" :style="`text-align: center;${divStyle}`" v-if="loading">
    <span :class="color" class="ml-1 mb-1 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </div>
</template>

<script>
    export default {
      props: {
        loading: {type: Boolean, default: true},
        color: {type: String, default: 'text-info'},
        divStyle:{type: String, default: ''},
      },
      name: "SmallLoading"
    }
</script>

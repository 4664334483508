<template>
  <modal :titulo="titulo" id="simulacao-registro-auxiliar" :loading="loading">
    <template #actions>
      <li class="list-inline-item">
        <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
          <i class="fal fa-print"></i>
        </a>
      </li>
    </template>

    <p v-if="loading">
      Processando atos: {{resumo.atual | inteiro}}/{{resumo.total | inteiro}}
    </p>

    <template v-if="fichaAtualizada">

      <template v-for="(ato, i) in erros">
        <erro-validacao :ato="ato" :protocolo="protocolo" :key="ato.id + '-' + i" servico="RegistroAuxiliar"
                        :exibeNomeServico="false" @abrirErro="abrirErro(...$event)" />
      </template>

      <card titulo="Dados" v-if="mostrarDados">
        <card-table class="table-borderless">
          <thead>
            <tr>
              <th width="33%" class="pl-2">CAMPO</th>
              <th width="33%">ANTES</th>
              <th width="33%">DEPOIS</th>
            </tr>
          </thead>
          <tbody>

            <tr v-if="ficha.status != fichaAtualizada.status">
              <td class="pl-2">Situação</td>
              <td class="text-truncate">{{ficha.status}}</td>
              <td class="text-truncate">{{fichaAtualizada.status}}</td>
            </tr>

            <tr v-if="ficha.tipoCedula != fichaAtualizada.tipoCedula && this.fichaAtualizada.tipoRegistro === 'CEDULA'">
              <td class="pl-2">Tipo de Cédula</td>
              <td class="text-truncate">{{tipo(ficha.tipoCedula)}}</td>
              <td class="text-truncate">{{tipo(fichaAtualizada.tipoCedula)}}</td>
            </tr>

            <tr v-if="ficha.vencimentoCedula != fichaAtualizada.vencimento && this.fichaAtualizada.tipoRegistro === 'CEDULA'">
              <td class="pl-2">Vencimento</td>
              <td class="text-truncate">{{ficha.vencimentoCedula | data}}</td>
              <td class="text-truncate">{{fichaAtualizada.vencimento | data}}</td>
            </tr>

            <tr v-if="ficha.valorCedula != fichaAtualizada.valor && this.fichaAtualizada.tipoRegistro === 'CEDULA'">
              <td class="pl-2">Valor</td>
              <td class="text-truncate">{{$formatters.currency(ficha.valorCedula,2,'0,00')}}</td>
              <td class="text-truncate">{{$formatters.currency(fichaAtualizada.valor,2,'0,00')}}</td>
            </tr>

            <tr v-if="ficha.pacto != fichaAtualizada.pacto && this.fichaAtualizada.tipoRegistro === 'PACTO'">
              <td class="pl-2">Pacto</td>
              <td class="text-truncate">{{ficha.pacto | data}}</td>
              <td class="text-truncate">{{fichaAtualizada.pacto | data}}</td>
            </tr>

            <tr v-if="ficha.condominio != fichaAtualizada.condominio && this.fichaAtualizada.tipoRegistro === 'CONVENCAO_CONDOMINIO'">
              <td class="pl-2">Condomínio</td>
              <td class="text-truncate">{{ficha.condominio || '-'}}</td>
              <td class="text-truncate">{{fichaAtualizada.condominio  || '-'}}</td>
            </tr>


          </tbody>
        </card-table>
      </card>

      <card :titulo="tituloTexto" v-if="ficha.texto != fichaAtualizada.texto">
        <card-table class="table-borderless">

          <thead>
            <tr>
            <tr>
              <th>ANTES</th>
            </tr>
          </thead>
          <tbody class="mb-2" style="display: grid;">
            <tr>
              <td class="td-text-truncate" v-html="ficha.texto || '-'"></td>
            </tr>
          </tbody>

          <thead>
            <tr>
            <tr>
              <th>DEPOIS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-text-truncate" v-html="fichaAtualizada.texto || '-'"></td>
            </tr>
          </tbody>
        </card-table>
      </card>

      <card titulo="Observações" v-if="ficha.observacoes != fichaAtualizada.observacoes">
        <card-table class="table-borderless">

          <thead>
          <tr>
          <tr>
            <th>ANTES</th>
          </tr>
          </thead>
          <tbody class="mb-2" style="display: grid;">
          <tr>
            <td class="td-text-truncate" v-html="ficha.observacoes || '-'"></td>
          </tr>
          </tbody>

          <thead>
          <tr>
          <tr>
            <th>DEPOIS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="td-text-truncate" v-html="fichaAtualizada.observacoes || '-'"></td>
          </tr>
          </tbody>
        </card-table>
      </card>

      <TabelaRestricoesAtivas :ficha="fichaAtualizada" simulacao />

      <card titulo="Atos Praticados">
        <card-content>

          <div v-for="ato in fichaAtualizada.atos" :key="ato.id">

            <p class="mb-2" @dblclick="exportar(ato, ato.codigoStr)">
              <strong>{{ato.codigoStr}} {{ato.tipoServico.opcoes.titulo}}</strong>
            </p>

<!--            <div v-html="ato.minuta" v-dragscroll="true" class="texto-minuta mb-2 bg-sidebar-soft cursor-grab overflow-auto" v-if="ato.minuta"></div>-->
            <div v-html="ato.minuta" class="texto-minuta mb-2 bg-sidebar-soft overflow-auto" v-if="ato.minuta"></div>
            <div class="texto-minuta mb-5 text-red bg-sidebar-soft" v-else>
              Ato sem minuta configurada.
            </div>

          </div>

        </card-content>
      </card>

    </template>

  </modal>
</template>

<script>
import AuthBusiness from "@/business/AuthBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import IndicadorRealSimulacaoBusiness from "@/business/protocolo/simulacao/IndicadorRealSimulacaoBusiness.js";
import RegistroAuxiliarSimulacaoBusiness from "@/business/protocolo/simulacao/RegistroAuxiliarSimulacaoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
import Modal from "@/components/Modal";
import TabelaRestricoesAtivas from "@/components/Restricao/TabelaRestricoesAtivas";
import ErroValidacao from "@/views/Protocolo/Atos/Simulacao/ErroValidacao.vue";

export default {

    name: "SimularRegistroAuxiliar",
    components: {TabelaRestricoesAtivas, CardContent, CardTable, Card, ErroValidacao, Modal},
    props: {
      id: String,
      protocolo: Object,
      ato: String,
      texto: String
    },

    modal: {
        width: 1100
    },

    data() {
      return {
        loading: false,
        ficha: {},
        fichaAtualizada: null,
        erros: [],

        campos: {
          info: [],
          identificacao: [],
          area: [],
          endereco: [],
        },
        resumo : {
          total : 0,
          atual : 0,
          inicio : this.$moment(),
          fim : null
        }

      }
    },

    computed: {
      titulo() {
        return `Simular Resultado: ${FrontBusiness.nomearFicha(this.ficha)}`
      },

      tituloTexto() {
        return this.fichaAtualizada.tipoRegistro === 'CEDULA' ? 'Garantia' : 'Teor';
      },

      mostrarDados() {
        return this.ficha.status != this.fichaAtualizada.status
            || (this.fichaAtualizada.tipoRegistro === 'CEDULA' && (this.ficha.tipoCedula != this.fichaAtualizada.tipoCedula
                || this.ficha.vencimentoCedula != this.fichaAtualizada.vencimento || this.ficha.valorCedula != this.fichaAtualizada.valor))
            || (this.fichaAtualizada.tipoRegistro === 'PACTO' && this.ficha.pacto != this.fichaAtualizada.pacto)
            || (this.fichaAtualizada.tipoRegistro === 'CONVENCAO_CONDOMINIO' && this.ficha.condominio != this.fichaAtualizada.condominio)
      }

    },

    beforeMount() {
      this.load();
    },

    methods: {

      async load() {

        this.loading = true;

        let documentos = [], atos = [], p1, p2, p3;

        IndicadorPessoalBusiness.resetCache();
        p1 = RegistroAuxiliarBusiness.getById(this.id).then(f => this.ficha = f);
        if (this.protocolo?.id) {
          p2 = ProtocoloDocumentoBusiness.listar(this.protocolo.id).then(f => documentos = f);
          p3 = AtoBusiness.listarPorFicha(this.protocolo.id, 'registro-auxiliar', this.id).then(f => atos = f);
        }
        let usuarioLogado = this.$root.usuarioLogado;

        await Promise.all([p1, p2, p3]);

        if (!atos.length && this.ato) {
          let ato = await AtoBusiness.getById(null, this.ato);
          ato.envolvidos.forEach(e => e.indicadorPessoalVersaoId = e.indicadorPessoalVersao.id);
          atos = [ato];
        }

        this.resumo.total = atos.length;

        if(this.ato){
          let result = await RegistroAuxiliarSimulacaoBusiness.gerarMinuta(Utils.clone(this.protocolo), Utils.clone(this.ficha), atos, documentos, this.resumo, {id: this.ato, texto : this.texto}, usuarioLogado);
          let ato = result.atos.find(a => a.id == this.ato);
          FrontBusiness.closeModal(ato);
          return;
        }

        let result = await RegistroAuxiliarSimulacaoBusiness.simular(Utils.clone(this.protocolo), Utils.clone(this.ficha), atos, documentos, this.resumo, usuarioLogado);

        this.resumo.fim =  this.$moment();

        this.fichaAtualizada = result.ficha;
        this.fichaAtualizada.atos = result.atos;
        this.erros = result.atos.filter(a => a.erros.length);

        this.loading = false;
      },

      async abrirErro(ato, areaErroSimulacao) {
        let atoId = ato.id != null ? ato.id : ato.atoId;
        FrontBusiness.closeModal({id: atoId, area: areaErroSimulacao});
      },

      tipo(v) {
        return FrontBusiness.getLabel(v, RegistroAuxiliarBusiness.getTiposCedulas());
      },

      exportar(ato, name) {
        console.debug('debug', name, ato.debug);
        const texto = Utils.stringify(ato.debug);
        console.debug('debug', name, texto);
      },

      async imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: ficha.livro, codigo: ficha.codigo,
          simulacao: true, atosSimulacao: this.fichaAtualizada.atos});
      },

    }

  }
</script>

<style lang=scss>
  #simulacao-registro-auxiliar {
    .td-text-truncate {
      max-width: 729px;
      p {
        white-space: pre-line;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>

<template>
  <card titulo="Restrições e Direitos Ativos">
    <consulta :columns="columns" ref="tabela" :process="process" minWidth="100%" id="restricoes-direitos-ativos"
              label-lista-vazia="Não há restrições ativas para exibir">

      <template #row="{ row }">

        <tr>
          <td>{{descricaoAtoRestricaoCompleta(row.descricaoRestricao)}}</td>
          <td>
            <a href class="mb-1 pointer" @click.prevent="detalhesPropriedade(row)">
              {{nomearFicha(row.ficha)}}
            </a>
          </td>
          <td>{{nomePapel(row.papel)}}</td>
          <td>{{getLabelRestricao(row.restricao)}}</td>
        </tr>

      </template>
    </consulta>

  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
import Card from "@/components/Layout/components/Card.vue";
import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar";
import business from "@/views/Administracao/modulos/tipo-servico/business";
import RestricaoBusiness from "@/business/RestricaoBusiness";
import Consulta from "@/components/Consultas/Consulta";
import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business.js";

export default {

  name: "RestricoesIndicadorPessoal",
  components: {Consulta, Card},
  props: {
    pessoa : Object
  },

  data() {
    return {
      filtros : {
        id: this.pessoa.indicadorPessoalId,
      },
    }
  },

  computed: {
    columns() {
      return [
        {label: 'Ato', field: 'r.codigo', minWidth: '100px'},
        {label: 'Ficha', field: 'r.codigo_f', minWidth: '100px'},
        {label: 'Papel', field: 'r.papel', minWidth: '100px'},
        {label: 'Restrições', field: 'r.restricao', width: '130px'},
      ];
    },
  },

  async mounted(){
    await this.$refs?.tabela?.load();
  },

  methods:{

    nomePapel(papel){ return businessTipoServico.nomePapel(papel) },

    tratarFichas(restricao){
      restricao.ficha = JSON.parse(restricao.ficha);
      return restricao;
    },

    getLabelRestricao: business.getLabelRestricao,

    nomearFicha: FrontBusiness.nomearFicha,

    detalhesPropriedade(restricao) {
      if(restricao.entidade === 'REGISTRO_AUXILIAR'){
        FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id: restricao.ficha.id});
      }else{
        FrontBusiness.openModal(DetalhesIndicadorReal, {id: restricao.ficha.id});
      }
    },

    descricaoAtoRestricaoCompleta(descricaoRestricao){
      return AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(descricaoRestricao));
    },

    async process(sort_by, sort_dir, page_length, page_number){
      let result = await RestricaoBusiness.buscarRestricaoPessoa(sort_by, sort_dir, page_number, page_length, {filtros: this.filtros});
      return {rows: result?.pagina?.map(this.tratarFichas), total: result.quantidade};
    }

  }
}
</script>

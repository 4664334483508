import Http from "@/commons/Http";

const path = 'api/protocolo';

export default {

  async enviarEmailExigencia(protocolo, atividade){
    return Http.post(`${path}/${protocolo}/atividade/${atividade}/enviar-email`);
  },

  async listar(protocolo){
    return Http.get(`${path}/${protocolo}/atividade`);
  },

  async getById(id) {
    return Http.get(`${path}/null/atividade/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/null/atividade/${id}`, dto);
  },

  async alterarExigencia(exigencias, id = ''){
    return Http.post(`${path}/null/atividade/${id}/exigencia`, exigencias);
  },

  async regerarExigencia(id){
    return Http.post(`${path}/null/atividade/${id}/regerar-exigencia`);
  },

  async getUsuario(protocolo, etapa, order = 'desc'){
    return Http.get(`${path}/${protocolo}/atividade/usuario/${etapa}/${order}`);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/null/atividade/exigencia/v2/pagination?sort=${sort || 'pa.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  paginationExcelV2(sort, direction, page, size, filters = {}){
    return `${path}/null/atividade/exigencia/v2/pagination/export-excel?sort=${sort || 'pa.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`;
  },

  tipoAtividade(){
    return {
      'ATRIBUIR' : 'Atribuiu o protocolo',
      'DESATRIBUIR' : 'Desatribuiu o protocolo',
      'ENCAMINHAR' : 'Encaminhou o protocolo',
      'REPROTOCOLAR' : 'Reprotocolou o protocolo',
      'CADASTRAR' : 'Cadastrou o protocolo',
      'EXIGENCIA' : 'Registrou exigência',
      'AVANCAR' : 'Avançou a etapa',
      'VOLTAR' : 'Voltou a etapa do protocolo',
      'CANCELAR' : 'Cancelou o protocolo',
      'FINALIZAR' : 'Finalizou o protocolo',
      'REGISTRAR' : 'Registrou o protocolo',
      'SELAR' : 'Selou o protocolo',
      'ENTREGAR' : 'Entregou o protocolo',
      'REATIVACAO' : 'Reativou o protocolo',
      'CANCELAR_ENTREGA' : 'Cancelou entrega do protocolo',
      'LEGADO' : 'Atividade importada do sistema antigo',
      'SUSPENSAO' : 'Suspensão do vencimento do protocolo em virtude de: ',
      'REATIVACAO_PRAZO' : 'Retorno da contagem do vencimento do protocolo',
      'SOLICITAR_PERMISSAO_REGISTRO' : 'Solicitou permissão para registrar o protocolo, pois existem indisponibilidades/ocorrências na validação dos atos',
      'CONCEDER_PERMISSAO_REGISTRO' : 'Autorizou o registro do protocolo com indisponibilidades/ocorrências na validação dos atos',
      'NEGAR_PERMISSAO_REGISTRO' : ' Não autorizou o registro do protocolo com indisponibilidades/ocorrências na validação dos atos',
    }
  }

}

const IMPRESSAO_DESATUALIZADA = 'Impressão desatualizada! ',
      DIGITALIZACAO_DESATUALIZADA = 'Digitalização desatualizada! ',
      INDICAR_POSSUI_INDISPONIBILIDADE = 'Indicador possui ordens de indisponibilidade ativas! ',
      INDICAR_POSSUI_OCORRENCIAS = 'Indicador possui ocorrências ativas! ',
      INDICAR_POSSUI_PROTOCOLOS = 'Indicador possui protocolos vinculados! ',
      INDICAR_POSSUI_SALDO_PENDENTE = 'Pessoa possui saldo pendente em outros protocolos',
      REGAUXILIAR_POSSUI_PROTOCOLOS = 'Registro auxiliar possui protocolos vinculados! ',
      PROTOCOLO_POSSUI_VINCULOS = 'Não existe Matrícula/Transcrição ou Registro Auxiliar vinculado.',
      PROTOCOLO_SELOS_COM_ERRO = 'Existem selos não enviados ou com erro neste protocolo.';

export default {

  impressaoDesatualizada() {
    return IMPRESSAO_DESATUALIZADA;
  },

  digitalizacaoDesatualizada() {
    return DIGITALIZACAO_DESATUALIZADA;
  },

  indicadorPossuiIndisponibilidade() {
    return INDICAR_POSSUI_INDISPONIBILIDADE;
  },

  indicadorPossuiOcorrencias() {
    return INDICAR_POSSUI_OCORRENCIAS;
  },

  indicadorPossuiProtocolos() {
    return INDICAR_POSSUI_PROTOCOLOS;
  },

  indicadorPossuiSaldoPendente() {
    return INDICAR_POSSUI_SALDO_PENDENTE;
  },

  registroAuxiliarPossuiProtocolos() {
    return REGAUXILIAR_POSSUI_PROTOCOLOS;
  },

  protocoloPossuiVinculos() {
    return PROTOCOLO_POSSUI_VINCULOS;
  },
  protocoloPossuiSeloComErro() {
    return PROTOCOLO_SELOS_COM_ERRO;
  },
}

<template>
  <modal id="modal-versoes" titulo="Versões">
    <versoes :pessoa="pessoa" :consulta="true" @selecionar="selecionar"></versoes>
  </modal>
</template>

<script>
  import Modal from "@/components/Modal";
  import Versoes from "@/components/IndicadorPessoal/Detalhes/Versoes";
  import FrontBusiness from "@/business/FrontBusiness";

  export default {
    name: "ModalVersoes",
    components: {Modal, Versoes},
    props: {
      pessoa: Object
    },

    modal: {
      escapable: false
    },

    methods: {
      selecionar(id){
        FrontBusiness.closeModal(id);
      }
    }
  }
</script>

const doGet = async (endpoint) => {
  return fetch(`${window.server?.YGDRASIL_URL}${endpoint}`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then(response => response.json());
};

const doPost = async (endpoint, request, accept = 'application/json') => {
  return fetch(`${window.server?.YGDRASIL_URL}${endpoint}`, {
    method: 'post', body : JSON.stringify(request),
    headers: {
      'Accept': accept,
      'Content-Type': 'application/json'
    },
  }).then(response => accept == 'application/json' ? response.json() : response.text());
};

export default {

  /*** *************************************************************/
  /*** TABELA DE CUSTAS ***/
  async getTabelas(estado) {
    return doGet(`/api/v1/tabela-custa/${estado}/tabelas`);
  },

  async getCustasTabela(estado, inicio, fim) {
    return doGet(`/api/v1/tabela-custa/${estado}/custas/${inicio}/${fim}`);
  },

  /*** *************************************************************/
  /*** ATUALIZAÇÃO MONETARIA ***/
  async atualizarValores(estado, request){
    return doPost(`/api/v1/atualizacao-monetaria/${estado}/atualizar`, request);
  },

  /*** *************************************************************/
  /*** ATUALIZAÇÃO MONETARIA ***/
  async calculadoraCidadao(request){
    return doPost(`/api/v1/calculadora-cidadao/corrigir-indice`, request, 'text/html');
  },

  /*** *************************************************************/
  /*** ATUALIZAÇÃO MONETARIA ***/
  async buscarEndereco(sort, direction, page, size, request = {}){
    return doPost(`/api/v1/endereco/pagination?sort=${sort || 'c.cep'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, request);
  }
}

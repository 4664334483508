import FrontBusiness from "@/business/FrontBusiness.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import testarCondicao from "@/business/livros/copiladores/testarCondicao.js";
import Utils from "@/commons/Utils.js";

const repetidor = async function(node, callback, dicionario, debug ){

  let lista = getVar(node.params.lista, dicionario, false);

  if(node.params.se !== undefined){
    lista = lista.filter(e => testarCondicao(node.params, e));
  }

  const key = node.params.nome || 'item';

  let saida = [];

  if(debug) {
    debug.lista = lista;
  }

  for(let item of lista){
    let obj = {};
    obj[key] = item;
    if(debug) {
      debug.item = item;
    }
    saida.push(await callback(node.children, {...dicionario, ...obj}, debug));
  }

  return FrontBusiness.formatArray(saida, node.params.separador, node.params.ultimo);

};

export default repetidor;

<template>

  <modal id="formulario-documentacao" titulo="Gerenciamento de Documentação" :loading="!form">

    <template v-if="!auditoria">
      <card>

        <div class="row gutters-xs form">

          <v-input type="text" :readonly="true" label="Código" class="col-2" v-model="form.codigo" v-if="form.id"/>
          <v-input type="select" :options="tipos" label="Tipo" class="col-3"
                   v-model="form.tipo" :error="validation.tipo"/>
          <v-input type="date" class="col-4" label="Vencimento"
                   v-model="form.vencimento" :error="validation.vencimento"/>
          <v-input :error="validation.ativo" :required="true" class="col-auto"
                   label="Registro Ativo" type="radio" v-model="form.ativo"/>

          <div class="w-100"/>
          <v-input type="text" class="col-4" label="Nº Ordem"
                   v-model="form.numero" :error="validation.numero"/>
          <template v-if="form.tipo === 'PROCURACAO'">
            <v-input type="text" class="col-4" label="Livro"
                     v-model="form.livro" :error="validation.livro"/>
            <v-input type="text" class="col-4" label="Folha"
                     v-model="form.folha" :error="validation.folha"/>
            <v-input type="date" class="col-6" label="Lavratura"
                     v-model="form.lavratura" :error="validation.lavratura"/>
          </template>
          <template v-if="form.tipo === 'CONTRATO_SOCIAL'">
            <v-input type="text" class="col-4" label="Nº Registro"
                     v-model="form.numeroRegistro" :error="validation.numeroRegistro"/>
            <v-input type="date" class="col-6" label="Data Registro"
                     v-model="form.registro" :error="validation.registro"/>
          </template>

          <v-input type="date" class="col-6" label="Data de Arquivamento"
                   v-model="form.arquivamento" :error="validation.arquivamento"/>
          <div class="w-100"/>

          <v-input class="col-12" :error="validation.descricao"
                   v-model="form.descricao" type="textarea" label="Observação" />

        </div>

      </card>

      <anexos-wrapper nome="Documentação" entidade="DOCUMENTACAO" tipo-entidade="DOCUMENTACAO" :id="form.id" v-if="form.id" :digitalizacao="true" />

      <card titulo="Envolvidos">
        <card-table>

          <thead>
          <tr>
            <th>Nome</th>
            <th width="190px">Papel</th>
            <th width="150px">Documento</th>
            <th width="30px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(pessoa, i) in form.envolvidos" :key="pessoa.id">
            <td>{{pessoa.nome}}</td>
            <td>
              <v-input v-model="pessoa.papel" type="select" ref="papel" :options="papeis" />
            </td>
            <td>{{pessoa.indicadorPessoalVersao.documento}}</td>
            <td>
              <v-button :run="remover" :params="[i]" :button="false" :popover="true" class="icon" position="dropleft">
                <i class="fal fa-times" title="Excluir"></i>
              </v-button>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <buscar-pessoa @add="addEnvolvido($event)" class="mt-2" :novo="true" />
            </td>
          </tr>
          </tbody>

        </card-table>
      </card>
    </template>
    <auditoria-pagination v-else :referencia="id" operacao="UPDATE, CREATE" entidade="DOCUMENTACAO" :formatters="formatters" />

    <template #footer>
      <button v-if="!auditoria" type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <button v-if="auditoria" type="button" @click.prevent="showAuditorias()" class="btn btn-outline-secondary mr-auto">Voltar</button>
      <v-button v-if="!auditoria && id" :run="showAuditorias" class="btn-outline-primary">Auditoria</v-button>
      <v-button v-if="!auditoria" :run="salvar" class="btn-outline-primary ml-2">Salvar & Continuar</v-button>
    </template>

  </modal>

</template>

<script>
  import AnexoBusiness from "@/business/AnexoBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import DocumentacaoBusiness from "@/business/indicadores/DocumentacaoBusiness";
  import utils from "@/commons/Utils";
  import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import AuditoriaPagination from "@/v2/components/Auditoria/AuditoriaPagination";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";

  export default {
    name: "FormularioDocumentacao",
    components: {AnexosWrapper, CardTable, Card,
      AuditoriaPagination, Modal},
    props: {
      id: String,
      envolvido: Object
    },

    modal:{
      width: 700
    },

    async mounted() {

      if (this.id) {
        this.loadArquivos();
        this.$set(this, 'form', await DocumentacaoBusiness.getById(this.id));
      }else{
        this.addEnvolvido(this.envolvido);
      }
      this.papeis = DocumentacaoBusiness.getPapeisEnvolvidos();

    },

    data() {
      return {
        arquivos: [],
        files: [],
        form: {
          envolvidos: [],
          tipo: 'PROCURACAO',
          vencimento: null,
          numero: null,
          livro: null,
          folha: null,
          lavratura: null,
          numeroRegistro: null,
          registro: null,
          arquivamento: null,
          descricao: null,
        },
        validation: {},
        papeis: [],
        tipos: DocumentacaoBusiness.tipos(),
        auditoria: false,
      }
    },
    methods: {

      close: FrontBusiness.closeModal,

      async load(){
        if (this.id) {
          this.loadArquivos();
          this.$set(this, 'form', await DocumentacaoBusiness.getById(this.id));
        }else{
          this.addEnvolvido(this.envolvido);
        }

      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#formulario-documentacao');
      },

      async salvar() {
        let dto = utils.clone(this.form);
        // dto.envolvidos = dto.envolvidos.filter(e => e).map(e => e.indicadorPessoalId);

        let retorno = await DocumentacaoBusiness.save(dto, this.form.id).catch(this.showError)
        if(retorno){
          FrontBusiness.showSuccess('','Documento salvo com sucesso')
          this.$set(this.form, 'id', retorno.id);
          this.$set(this.form, 'codigo', retorno.codigo);
        }
      },

      async loadArquivos() {
        this.arquivos = await AnexoBusiness.listar('DOCUMENTACAO', this.id);
        this.$nextTick(() => {
          this.files = [];
        });
      },

      async inputFile() {
        if (this.files[0]) {
          await AnexoBusiness.inserir(this.files[0].file, 'DOCUMENTACAO', this.form.id)
            .catch((e) => FrontBusiness.showError('', e.message));
          this.loadArquivos();
        }
      },

      addEnvolvido(envolvido){
        this.$nextTick(() => {
          if(!this.form.envolvidos.find(e => e.indicadorPessoalId == envolvido.indicadorPessoalId) && envolvido){
            this.form.envolvidos.push({
              indicadorPessoalVersao: envolvido,
              papel: this.papeis[0]?.id,
              versao: null,
              ordem: 1000,
              tipoPessoa: envolvido?.tipoPessoa,
              nome: envolvido?.nome,
              indicadorPessoalId: envolvido?.indicadorPessoalId,
            });
          }
        });
      },

      remover(idx){
        this.form.envolvidos.splice(idx, 1);
      },

      showAuditorias(){
        this.auditoria = !this.auditoria;
      },

      async formatters() {
        let formatters = [];
        formatters = [
          {label: 'Tipo', key: 'tipo', type: 'enum', lista: this.tipos},
          {label: 'Vencimento', key: 'vencimento', type: 'date'},
          {label: 'Registro Ativo', key: 'ativo', type: 'boolean'},
          {label: 'Nº Ordem', key: 'numero', type: 'string'},
          {label: 'Livro', key: 'livro', type: 'string'},
          {label: 'Folha', key: 'folha', type: 'string'},
          {label: 'Lavratura', key: 'lavratura', type: 'date'},
          {label: 'Nº Registro', key: 'numeroRegistro', type: 'string'},
          {label: 'Data Registro', key: 'registro', type: 'date'},
          {label: 'Data de Arquivamento', key: 'arquivamento', type: 'date'},
          {label: 'Observação', key: 'descricao', type: 'string'},
          {label: 'Envolvidos', key: 'envolvidos', type: 'string', formatter: (value) => AuditoriaBusiness.envolvidos({envolvidos: value, documentacao: true})},

          {label: 'Ordem', key: 'ordem', type: 'string'},
          {label: 'Nome', key: 'nomeAnexo', type: 'string'},
          {label: 'Caminho', key: 'caminho', type: 'string'},
          {label: 'Tags', key: 'tags', type: 'listahtml'},
          {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
        ];

        return formatters;
      }

    }
  }
</script>

<style lang=scss>

</style>

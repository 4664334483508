import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import Http from "@/commons/Http";
import FrontBusiness from "@/business/FrontBusiness";
import Utils from "@/commons/Utils";
import CloudStorageBusinessFacede from "@/business/cloudStorage/CloudStorageBusinessFacede";
import AnexoFactory from "@/business/cloudStorage/AnexoFactory";

const path = 'api/anexo';

export default {

  async getDetalhesById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/detalhes/${id}`);
  },

  async ordenar(ordens){
    return Http.post(`${path}/ordenar`, ordens);
  },

  async deleteById(id){
    return Http.delete(`${path}/${id}`);
  },

  async save(id, dto){
    return Http.post(`${path}/${id}`, dto);
  },

  async listar(entidade, id = '', grupo = '', resetCache = ''){
    if(id && !Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${entidade}/${id}${grupo ? '/' + grupo : ''}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async downloadPdfDigitalizacao(entidade, id = '', grupo = '', resetCache = ''){

    if(id && !Utils.testUUID(id)){
      return Promise.reject();
    }
    const config = {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    };

    return Http.get(`${path}/pdf/${entidade}/${id}${grupo ? '/' + grupo : ''}${resetCache ? '?reset=' + resetCache : ''}`, config);
  },

  urlPdfDigitalizacao(entidade, id = '', grupo = '', resetCache = ''){
    if(id && !Utils.testUUID(id)){
      return Promise.reject();
    }
    return `${window.server.API}/api/v2/anexo/pdf/${entidade}/${id}${grupo ? '/' + grupo : ''}${resetCache ? '?reset=' + resetCache : ' '}`;
  },

  async listarAuditoria(entidade, id = '', grupo = ''){
    if(id && !Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/auditoria/${entidade}/${id}${grupo ? '/' + grupo : ''}`);
  },

  async inserir(file, entidade, id = '', progress = null, checklist = false, grupo = null, etapa = ''){

    //Tamanho máximo em Bytes
    const tamanhoMaxArquivo = FrontBusiness.maxUploadSize;
    // console.debug(file.size, tamanhoMaxArquivo, file.size<=tamanhoMaxArquivo);

    if (file.size <= tamanhoMaxArquivo) {
      const business = await CloudStorageBusinessFacede.getStorageBusiness();
      const anexo = AnexoFactory.criarAnexo(file, entidade, id, checklist, grupo, etapa);
      return business.upload(file, anexo);
    } else {
      FrontBusiness.showError('', 'O arquivo excede o tamanho máximo de ' + Utils.humanFileSize(tamanhoMaxArquivo, false)+ ' permitido');
    }

  },

  async ver(anexoOrIdAnexo = {}, thumb = false, resetCache = ''){
    let anexo = anexoOrIdAnexo?.id ? anexoOrIdAnexo : null;
    if((!Utils.isEmptyNullOrUndefined(anexo) && !Utils.testUUID(anexo.id) || (Utils.isEmptyNullOrUndefined(anexo) && !Utils.testUUID(anexoOrIdAnexo)))){
      return '-';
    } else if (Utils.isEmptyNullOrUndefined(anexo)) {
      const responseAnexo = await this.getDetalhesById(anexoOrIdAnexo);
      if (responseAnexo?.data) {
        anexo = responseAnexo.data;
      } else {
        return '-';
      }
    }
    const business = await CloudStorageBusinessFacede.getStorageBusiness();
    if(thumb){
      return await business.ver(anexo, thumb, resetCache);
    }else{
      return await business.verDigitalizacao(anexo, thumb, resetCache);
    }
  },

  async download(anexo, assinado = false, callBack = () => {}){
    const business = await CloudStorageBusinessFacede.getStorageBusiness();
    return business.download(anexo, assinado, callBack);
  },

  async getUploadUrl(anexo, id = '', grupo = null, etapa = null){
    const business = await CloudStorageBusinessFacede.getStorageBusiness();
    return business.getUploadUrl(anexo, id, grupo, etapa);
  },

  async salvarAnexo(anexo){
    return Http.post(`${path}/`, anexo);
  },

  async salvarTags(anexoId, dto){
    return Http.post(`${path}/tags/${anexoId}`, dto);
  },

  getEntidades(){

    let entidades = [];

    DominioBusiness.dominiosAtivos().forEach(e => {
      DominioBusiness.getTiposProtocoloPorDominio(e.id).forEach(t => {
        entidades.push({id : e.id + '-' + t.id, nome : FrontBusiness.nomearProtocolo(e.id, t.id), entidade: 'PROTOCOLO', dominio: e.id, tipoProtocolo: t.id});
      });
    });

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RI')){
        entidades.push(
          {id : 'PRE_MATRICULA', nome : 'Pré-Matrícula', entidade: 'INDICADOR_REAL', livro: 'MATRICULA', dominio: 'PROTOCOLO_RI'},
          {id : 'INDICADOR_REAL', nome : 'Matrícula', entidade: 'INDICADOR_REAL', livro: 'MATRICULA', dominio: 'PROTOCOLO_RI'},
          {id : 'TRANSCRICAO', nome : 'Transcrição', entidade: 'INDICADOR_REAL', livro: 'TRANSCRICAO', dominio: 'PROTOCOLO_RI'},
          {id : 'REGISTRO_AUXILIAR', nome : 'Registro Auxiliar', entidade: 'REGISTRO_AUXILIAR', livro: 'REGISTRO_AUXILIAR', dominio: 'PROTOCOLO_RI'},
        )
    }

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RTD')){
      entidades.push(
        {id : 'REGISTRO_TD', nome : 'Registro TD', entidade: 'INDICADOR', livro : 'REGISTRO_TD', dominio: 'PROTOCOLO_RTD'},
      )
    }

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RCPJ')){
      entidades.push(
        {id : 'REGISTRO_PJ', nome : 'Registro PJ', entidade: 'INDICADOR', livro : 'REGISTRO_PJ', dominio: 'PROTOCOLO_RCPJ'},
      )
    }

    entidades.push(
      {id : 'OCORRENCIA', nome : 'Ocorrência', entidade : 'OCORRENCIA', dominio : 'OCORRENCIA'},
      {id : 'NOTIFICACAO', nome : 'Notificação', entidade: 'NOTIFICACAO', dominio: 'NOTIFICACAO'},
      {id : 'OFICIO', nome : 'Ofício', entidade: 'OFICIO', dominio: 'OFICIO'},
      {id : 'DOCUMENTACAO', nome : 'Documentação', entidade: 'DOCUMENTACAO', dominio: ''}
    )
    return entidades;

  },

  getEntidadesDocumentosArquivados (){

    let origens = []

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RI')){
      origens.push(
        {id : 'INDICADOR_REAL', nome : 'Matrícula'},
        {id : 'TRANSCRICAO', nome : 'Transcrição'},
        {id : 'REGISTRO_AUXILIAR', nome : 'Registro Auxiliar'},
        {id : 'PROTOCOLO_RI-NORMAL', nome : 'Protocolo RI'},
        {id : 'PROTOCOLO_RI-EXAME_CALCULO', nome : 'Exame e Cálculo RI'},
        {id : 'PROTOCOLO_RI-PROCESSO_INTERNO', nome : 'Processo Interno RI'},
        {id : 'PROTOCOLO_RI-ORCAMENTO', nome : 'Orçamento de Protocolo RI'},
        {id : 'CERTIDAO_RI-NORMAL', nome : 'Certidão RI'},
        {id : 'CERTIDAO_RI-ORCAMENTO', nome : 'Orçamento de Certidão RI'},
      )
    }

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RTD')){
      origens.push(
        {id : 'REGISTRO_TD', nome : 'Registro TD'},
        {id : 'PROTOCOLO_RTD-NORMAL', nome : 'Protocolo RTD'},
        {id : 'PROTOCOLO_RTD-ORCAMENTO', nome : 'Orçamento de Protocolo RTD'},
        {id : 'CERTIDAO_RTD-NORMAL', nome : 'Certidão RTD'},
        {id : 'CERTIDAO_RTD-ORCAMENTO', nome : 'Orçamento de Certidão RTD'},
      )
    }

    if(VueBusiness.getVueRoot()?.atribuicaoAtiva('RCPJ')){
      origens.push(
        {id : 'REGISTRO_PJ', nome : 'Registro PJ'},
        {id : 'PROTOCOLO_RCPJ-NORMAL', nome : 'Protocolo RCPJ'},
        {id : 'PROTOCOLO_RCPJ-ORCAMENTO', nome : 'Orçamento de Protocolo RCPJ'},
        {id : 'CERTIDAO_RCPJ-NORMAL', nome : 'Certidão RCPJ'},
        {id : 'CERTIDAO_RCPJ-ORCAMENTO', nome : 'Orçamento de Certidão RCPJ'},
      )
    }

    origens.push(
      {id : 'OCORRENCIA', nome : 'Ocorrência'},
      {id : 'NOTIFICACAO', nome : 'Notificação'},
      {id : 'OFICIO', nome : 'Ofício'},
      {id : 'INDICADOR_PESSOAL', nome : 'Indicador Pessoal'},
    )

    return origens;
  },

  async createHash(entidade, id = ''){
    return Http.get(`api/digitalizacao/hash/${entidade}${id ? `/${id}` : ''}?client=${encodeURIComponent(window.server.API)}`);
  },

  async getProximoRegistro(livro, id, acao){
    return Http.get(`api/digitalizacao/${livro}/${id}/${acao}`);
  },

  async gerarDigitalizacaoAtoDigital(form){
    return Http.post(`api/digitalizacao/gerar-digitalizacao-ato-digital`,form);
  },

  async gerarDigitalizacaoAtoDigitalProtocolo(form){
    return Http.post(`api/digitalizacao/gerar-digitalizacao-ato-digital-protocolo`,form);
  },

  tiposDigitalizacao() {
    return [
      { id: '', nome: 'Todos' },
      { id: 'ATUALIZADA', nome: 'Atualizada' },
      { id: 'DESATUALIZADA', nome: 'Desatualizada' }
    ];
  },

  async getDetalhesAssinaturaById(id){
    return Http.get(`${path}/detalhes/${id}/assinatura`);
  },

}

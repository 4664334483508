import moment from 'moment';
import 'moment/locale/pt-br';
import { formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'

moment.locale('pt-br');

export default {
  install(Vue) {

    Vue.moment = Vue.prototype.$moment = moment;

    Vue.prototype.$formatters = {

      currency(value, decimais = 2, empty = '0,00') {
        return !value ? empty : Number(value).toLocaleString("pt-BR", {
          minimumFractionDigits: decimais?.min || decimais,
          maximumFractionDigits: decimais?.max || decimais,
          style: 'decimal',
          currency: 'BRL'
        });
      },

      money(value, max = 5) {

        if(!value){
          return '-';
        }

        let v = Number(value).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: max,
          style: 'decimal',
          currency: 'BRL'
        });

        return `${v}`;

      },

      fromNow(value) {
        return formatDistanceToNow(moment(value, "YYYY-MM-DD HH:mm:ss").toDate(), { locale: ptBR })
      },

      date(value, empty = '-') {
        if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
        return !value ? empty : moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
      },

      year(value, empty = '-') {
        if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
        return !value ? empty : moment(value, "YYYY-MM-DD").format("YYYY");
      },

      datetime(value, empty = '-') {
        if (value != null && typeof (value) === 'object' && value?.year) value = new Date(value?.year, value?.monthValue - 1, value?.dayOfMonth, value?.hour, value?.minute, value?.second);
        return !value ? empty : moment(value).format("DD/MM/YYYY HH:mm:ss");
      },

      filesize(size) {
        if (size > 1024 * 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
        } else if (size > 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
        } else if (size > 1024 * 1024) {
          return (size / 1024 / 1024).toFixed(2) + ' MB'
        } else if (size > 1024) {
          return (size / 1024).toFixed(2) + ' KB'
        }
        return size.toString() + ' B'
      },

      integer(value, empty = '-') {
        return !value ? empty : Number(value).toLocaleString("pt-BR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: 'decimal',
          currency: 'BRL'
        });
      },

      getLabel(valor, vetor = [], id = 'id', nome = 'nome'){
        return (vetor?.find(a => a[id] == valor) || {})[nome] || '';
      },

      boolean(value, empty) {
        if(value === true){
          return 'Sim';
        }
        if(value === false){
          return 'Não';
        }
        return empty;
      },

      segundosParaHoras(value, mostrarSegundos = true){

        if (!value || value < 0) {
          return '-';
        }

        const sec_num = parseInt(value, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }

        const time = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };

        if (mostrarSegundos) {
          return time.h + ':' + time.m + ':' + time.s;
        } else {
          return time.h + ':' + time.m;
        }
      },

      numeroRipSpu(value) {
        return (value || '').toString()
          .replace(/^(\d{4})(\d{7})(\d{2})/, "$1 $2-$3");
      }

    };
  }
}

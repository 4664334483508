import Http from "@/commons/Http";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";

const path = '/api/v2/bem-movel';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'i.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getFichasPorCodigo(params = {}) {
    const {status, dto} = params;
    return IndicadorAbstractService.getFichasPorCodigo({livro: 'BEM_MOVEL', status, dto});
  },
}

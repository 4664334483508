<template>
  <div id="aba-sigef">

    <card titulo="Georreferenciamento - SIGEF">

      <template #acoes>

        <template v-if="itens.length && !loading">
          <v-button :run="remover" :button="false" :popover="true" position="dropleft"
                    class="icon mx-1 float-right">REMOVER TODOS</v-button>
        </template>

        <template v-else-if="form.hash">

          <a :href="urlLimites" target="_blank" class="float-right small" download="limites.csv">
            Download CSV Limites
          </a>

          <a :href="urlVertices" target="_blank" class="float-right mr-4 small" download="vertices.csv">
            Download CSV Vértices
          </a>

        </template>

      </template>

      <small-loading v-if="loading"></small-loading>

      <card-table v-if="itens.length && !loading">
        <thead>
          <tr>
            <th>Do Vértice</th>
            <th>Ao Vértice</th>
            <th>Longitude</th>
            <th>Latitude</th>
            <th>Tipo</th>
            <th>Lado</th>
            <th>Confrontante</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of itens" :key="item.id">
            <td>{{item.vertice}}</td>
            <td>{{item.destino}}</td>
            <td>{{item.longitudeDms}}</td>
            <td>{{item.latitudeDms}}</td>
            <td>{{item.tipo}}</td>
            <td>{{item.lado}}</td>
            <td width="180px" class="small">{{item.confrontante}}</td>
          </tr>
        </tbody>
      </card-table>

      <template v-if="!itens.length && !loading">

        <div class="row gutters-xs">

          <v-input class="col" :error="validation.hash" v-model="form.hash" placeholder="Hash de autenticação (Certificação)" type="text"/>

          <div class="col-3">
            <v-upload v-model="form.vertice" extensions="csv" class="form-control icon" :class="{'planilha-error': validation.vertice}">
              <i class="fal fa-file-excel"></i> CSV Vértices
            </v-upload>
          </div>

          <div class="col-3">
            <v-upload v-model="form.limite" extensions="csv" class="form-control icon" :class="{'planilha-error': validation.limite}">
              <i class="fal fa-file-excel mr-1"></i> CSV Limites
            </v-upload>
          </div>

          <div class="col-auto">
            <v-button :run="importar" :popover="true" position="dropleft"
                      class="btn btn-outline-secondary btn-xs">CADASTRAR</v-button>
          </div>

        </div>

        <div class="alert alert-outline-info">
          Não há itens para exibir
        </div>

      </template>

    </card>
  </div>
</template>

<script>
  import ConfiguracaoBusiness from "@/business/ConfiguracaoBusiness";
  import ListaBusiness from "@/business/crud/ListaBusiness";
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import Utils from "@/commons/Utils";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
  import ModalApagarUnidades from "@/components/IndicadorReal/Detalhes/ModalApagarUnidades.vue";
  import FormIndicadorReal from "@/components/IndicadorReal/Form/FormIndicadorReal.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";

  export default {

    name: "Unidades",
    components: {SmallLoading, CardTable, Card},
    props: {
      ficha: Object
    },

    data() {
      return {
        loading: true,
        itens: [],
        form:{
          hash: null,
          limite: [],
          vertice: [],
        },
        validation:{
          hash: null,
        }
      }
    },

    async mounted() {
      await this.load();
    },

    computed:{
      urlLimites(){
        return `https://sigef.incra.gov.br/geo/exportar/limite/csv/${this.form.hash}/`;
      },
      urlVertices(){
        return `https://sigef.incra.gov.br/geo/exportar/vertice/csv/${this.form.hash}/`;
      },
    },

    methods: {

      async load(){
        this.loading = true;
        this.itens = await IndicadorRealBusiness.listarSigef(this.ficha.id).catch(e => ([]));
        this.loading = false;
      },

      async importar(){

        let limites = null;
        let vertices = null;

        if(this.form.limite?.[0]?.file && this.form.vertice?.[0]?.file){
          limites = this.form.limite?.[0]?.file;
          vertices = this.form.vertice?.[0]?.file;
        }else{
          // // tenta realizar o download dos arquivos
          // const cfg = {method: "GET", mode: 'no-cors', credentials: 'omit', referrerPolicy: "no-referrer", referrer: "about:client"}
          // limites = await fetch(this.urlLimites, {...cfg}).then(r => r.blob()).then(blob => blob?.size > 0 ? blob : null).catch(e => {
          //   console.error(e);
          //   return null;
          // });
          // vertices = await fetch(this.urlVertices, cfg).then(r => r.blob()).then(blob => blob?.size > 0 ? blob : null).catch(e => {
          //   console.error(e);
          //   return null;
          // });
        }

        if(limites && vertices){

          const formData = new FormData();
          formData.append("limite", limites);
          formData.append("vertice", vertices);

          let config = {
            timeout: 0,
            headers: {
              'Content-Type': undefined
            },
          };

          await this.$api.post(`api/v2/sigef/${this.ficha.id}/importar/${this.form.hash}`, formData, config).catch(e => {
            FrontBusiness.showError('Erro ao importar SIGEF', e.message);
          });
        }else{
          await this.$api.get(`api/v2/sigef/${this.ficha.id}/importar/${this.form.hash}`).catch(e => {
            FrontBusiness.showError('Erro ao importar SIGEF', e.message);
          });
        }

        await this.load();

      },

      async remover(){
        await this.$api.delete(`api/v2/sigef/${this.ficha.id}`).catch(e => ([]));
        await this.load();
      }

    }
  }
</script>
<style scoped lang=scss>

  .td-toggle {
    text-align: right;
    vertical-align: top;
  }

  .table-unidades {
    .hidden-column {
      display: none;
    }

    &:hover {
      .hidden-column {
        display: initial;
      }
    }
  }

  #div-unidades-virtuais table thead th {
    line-height: 11px !important;
  }

</style>

<template>
  <div v-dragscroll="dragscroll" class="card-table table-responsive" :class="[cardClassName, { 'table-borderless' : borderless}, { 'cursor-grab' : dragscroll}]">
    <table class="table table-card" :class="[tableClass, {'table-striped' : striped}]">
      <slot></slot>
    </table>
  </div>
</template>

<script>
  export default {
    name: "CardTable",
    props : {
      cardClassName : [Object, String],
      tableClass : [Object, String],
      borderless : {type : Boolean, default: () => true},
      striped : {type : Boolean, default: () => false},
      dragscroll: {type : Boolean, default: () => false},
    }
  }
</script>

import Utils from "@/commons/Utils.js";

const tratarTexto = async function(tag, node, callback, dicionario, debug){

  let texto = (await callback(node.children, dicionario, debug));
  let output = '';
  let exceto = node.params.exceto;

  if(tag == 'capitalizar'){
    output = Utils.capitalize(texto.trim());
    if(exceto){
      exceto.split('|').forEach(t => {
        output = output.replaceAll(Utils.capitalize(t), t);
      });
    }
  }

  if(tag == 'maiusculo'){
    output = texto.toUpperCase().trim();
    if(exceto){
      exceto.split('|').forEach(t => {
        output = output.replaceAll(t.toUpperCase(), t);
      });
    }
  }

  if(tag == 'minusculo'){
    output = texto.toLowerCase().trim();
    if(exceto){
      exceto.split('|').forEach(t => {
        output = output.replaceAll(t.toLowerCase(), t);
      });
    }
  }

  return output;

};

export default tratarTexto;

<template>
  <div class="card-content pt-2" :class="classes">

    <h3 class="card-title small border-sidebar font-weight-500 border-bottom pb-1 " v-if="titulo" style="min-height: 20px;">
      {{titulo}}
      <slot name="acoes"></slot>
    </h3>

    <slot></slot>

  </div>
</template>

<script>
  export default {
    name: "CardContent",
    props:{
      titulo : String,
      background : Boolean
    },
    computed:{
      classes(){
        return this.background ? 'bg-sidebar px-3' : 'px-0'
      }
    },
    data(){
      return {
      }
    }
  }
</script>

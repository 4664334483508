
const gerarHtmlNativo = async function(tag, node, callback, dicionario, debug){

  let output = '';

  let exceto = node.params.exceto;

  if(node.params.fechar === undefined && tag){
    output += `  <${tag}`;
    Object.keys(node.params).forEach(key => {
      if(!['abrir', 'fechar', 'exceto'].includes(key.toLowerCase())){
        output += ` ${key}="${node.params[key]}"`;
      }
    });
    output += `>`;
  }

  try{
    let texto = await callback(node.children, dicionario, debug);

    if(exceto){
      let style = false;
      switch (tag){
        case 'b':
        case 'negrito':
          style="font-weight: normal !important;";
          break;

        case 'u':
        case 'sublinhado':
          style="text-decoration: none !important;";
          break;

        case 'i':
        case 'italico':
          style="font-style: normal !important;";
          break;
      }
      if(style){
        exceto.split('|').forEach(t => {
          const initialSpace = t.startsWith(' ') ? ' ' : '';
          const finalSpace = t.endsWith(' ') ? ' ' : '';
          texto = texto.replaceAll(t, `${initialSpace}<span style="${style}">${t.trim()}</span>${finalSpace}`);
        });
      }
    }

    if(texto){
      output += texto.trim();
    }
  }catch (e) {
    console.error(e);
  }

  if(node.params.abrir === undefined && tag){
    output += `</${tag}>  `;
  }

  return output;

};

export default gerarHtmlNativo;

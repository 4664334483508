<template>
  <modal titulo="Texto Importado" :fechar="true" @close="close" >
    <template #actions>
      <li class="list-inline-item">
        <a @click.prevent.stop="editar" v-if="!editando" title="Editar Texto Importado">
          <i class="fal fa-edit"></i>
        </a>
      </li>
    </template>
    <card>
      <card-content>
        <template v-if="!editando">
          <div v-html="ficha.textoLegado" class="texto-legado" />
        </template>
        <template v-if="editando">
          <v-input type="editor" v-model="dto.textoLegado" />
        </template>
      </card-content>
    </card>
    <template #footer>
      <button type="button" @click.prevent="cancelar()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button class="btn btn-primary btn-xs ml-auto" :run="salvar" data-dismiss="modal" v-if="editando">Salvar</v-button>
    </template>
  </modal>
</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import Utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";

export default {

  name: "TextoImportado",
  components: {CardContent, Card, Modal},
  props: {
    ficha: Object,
    tipo: null,
  },

  data() {
    return {
      dto: {},
      editando: false
    }
  },

  methods: {
    editar() {
      this.dto = Utils.clone(this.ficha);
      this.toggleEdicao();
    },

    cancelar() {
      if (this.editando) {
        this.toggleEdicao();
      } else {
        FrontBusiness.closeModal(this.ficha);
      }
    },

    close() {
      FrontBusiness.closeModal(this.ficha);
    },

    toggleEdicao(){
      this.$set(this, 'editando', !this.editando);
    },

    async salvar() {
      let business = this.tipo === 'INDICADOR_REAL' ? IndicadorRealBusiness : RegistroAuxiliarBusiness
      let dto = Utils.clone(this.dto);
      dto.proprietarios = (dto.proprietarios || []).map((f, ordem) => ({
        fracao: f.fracao,
        ordem: ordem,
        indicadorPessoalVersao: {id: f.indicadorPessoalVersao.id}
      }));
      await business.editarTextoLegado(this.ficha.id, dto).catch(this.showError);
      this.ficha = Utils.clone(this.dto);
      this.toggleEdicao();
    }
  }
}
</script>

<style lang="scss">
.texto-legado{
  &, & > *, p, span{
    color: rgb(216, 213, 213) !important;
    background: transparent !important;
  }
}
</style>

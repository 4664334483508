import FrontBusiness from "@/business/FrontBusiness.js";
import {LacunaWebPKI} from "web-pki";
import Http from "@/commons/Http";
import utils from "@/commons/Utils";

//JSON License
const webPkiLicense = {
  "format": 2,
  "allowedDomains": [
    "asgard.1rigo.com",
    "sistemaasgard.com.br",
    "1rigo.com",
    "vhlsistemas.com.br",
    "*.sistemaasgard.com.br"
  ],
  "homologDomains": [
    "homologacao-1rigo.sistemaasgard.com.br",
    "homologacao-asgard.1rigo.com",
    "ip4:10.0.0.0/8",
    "ip4:127.0.0.0/8",
    "ip4:172.16.0.0/12",
    "ip4:192.168.0.0/16"
  ],
  "productLevel": "Pro",
  "expiration": "2025-05-07 15:00:00Z",
  "signature": "x0JRaXkZKAnU5ehGbU78xI8F466SzDBUPW6WGu4uYjEKaZg3mbZ0zDeQY+DfoN9ypnDduQ5J+ecy1IoC10/NK+wOLvUYyzP7RRSeQdxedJJ7ZOcYryZbF2BZkPemKjXxFkMTr5nb5vb4RBGHQsj7oGqFEyPLKYhrdZR+N87R1hdIIhhsYuRsWK0aJHCeCBMYrC3MLXwhRJFpcizykGw2Oo1jsaHtbCa7dy5xKkoHHJkhkltq0rls5QoKHToga67bifwahTVQSq58RQlJbXQNS2HK7vpHU5Gd2u1hkAY7r445GVE2w9N5EtM5YMRJ9VOLYFl8bRyt3enKuWmPgaULkg=="
};
console.debug("webPkiLicense.expiration", webPkiLicense.expiration);

const path = 'api/lacuna';
const pathHomologacao = '/api/v2/regras-estaduais/MG';
const pki = new LacunaWebPKI(webPkiLicense);

const EncryptionParamaters = {
    RSAEncryptionPkcs1 : 'RSAEncryptionPkcs1',
    RSAEncryptionOaepSHA1 : 'RSAEncryptionOaepSHA1',
    RSAEncryptionOaepSHA256 : 'RSAEncryptionOaepSHA256',
    RSAEncryptionOaepSHA384 : 'RSAEncryptionOaepSHA384',
    RSAEncryptionOaepSHA512 : 'RSAEncryptionOaepSHA512'
};

const lacunaCache = {
  certificados: null
};

window.webPkiLicense = webPkiLicense;
window.pki = pki;

export default {

  async initPki(args = {}){
    if (utils.isMobile()) return;
    await pki.init({
      useDomainNativePool: true,
      ready: async () => {
        await this.getCertificados();
      },
      notInstalled: () => {},
      defaultFail: (message, error, origin) => {
        console.error('An error has occurred on the signature browser component: ' + message, error, origin);
      },
      requiredApiVersion: '1.8.0',
      ...args,
      license: webPkiLicense
    });
    return pki;
  },

  getPki(){
    return pki;
  },

  setThumb(thumb){
    if(thumb){
      localStorage.setItem('cert.thumbprint', thumb);
    }else{
      localStorage.removeItem('cert.thumbprint');
    }
  },

  async getCertificados(){
    if(!lacunaCache.certificados?.length){
      lacunaCache.certificados = (await this.getCerts().catch(e => {
        console.error(e);
        return [];
      }));
    }
    return lacunaCache.certificados;
  },

  async getThumb(){
    let thumbprint = localStorage.getItem('cert.thumbprint');

    let cpf = window.server?.user?.cpf?.replace(/[^\d-]/g, '')?.replace('-', '');
    let cpf2 = window.server?.user?.cpfCertificado?.replace(/[^\d-]/g, '')?.replace('-', '');

    if(cpf2 && cpf2 != cpf){
      console.debug('Utilizando e-cpf diferente', window.server?.user?.cpfCertificado);
      cpf = cpf2;
    }

    if(cpf){

      let certs = await this.getCertificados();

      certs = certs.filter(cert => (cert.validityEnd >= (new Date())) && (cert.pkiBrazil?.cpf?.includes(cpf) && !cert.pkiBrazil?.cnpj));
      let cert = certs.filter(cert => cert?.thumbprint == thumbprint || !thumbprint)?.[0] || certs?.[0];
      console.debug('certs', certs, cert, cert?.thumbprint);

      if(cert?.thumbprint){
        this.setThumb(cert?.thumbprint);
        //await this.preauthorizeSignatures(cert?.thumbprint, 500).catch(e => null);
        thumbprint = cert?.thumbprint;
      }
    }

    return thumbprint;
  },

  async getLoginHash(){
    return Http.get(`${path}/public/pre-login`);
  },

  async getCerts(){
    return new Promise((resolve, reject) => {
      pki.listCertificates().success(resolve).fail(reject);
    });
  },

  async readCertificate(cert){
    return new Promise((resolve, reject) => {
      pki.readCertificate(cert).success(resolve).fail(reject);
    });
  },

  async signData(data){
    return new Promise((resolve, reject) => {
      pki.signData(data).success(resolve).fail(reject);
    });
  },

  async signWithRestPki(token, thumbprint){
    return new Promise((resolve, reject) => {
      pki.signWithRestPki({token, thumbprint}).success(resolve).fail(reject);
    });
  },

  async login(signature, cert, nonce){
    return Http.post(`${path}/public/login?n=${nonce}`, {cert, signature, nonce});
  },

  async loginArisp(signature, cert, nonce){
    return Http.post(`${path}/public/login-arisp?n=${nonce}`, {cert, signature, nonce});
  },

  async preauthorizeSignatures(thumb, qtd = 100){
    return new Promise((resolve, reject) => {
      pki.preauthorizeSignatures({certificateThumbprint: thumb, signatureCount: qtd}).success(resolve).fail(reject);
    });
  },

  async preSign(cert){
    return Http.post(`${path}/public/pre-sign`, {cert});
  },

  async signHash(thumbprint, hash, digestAlgorithm = 'SHA-256'){
    return new Promise((resolve, reject) => {
      pki.signHash({thumbprint, hash, digestAlgorithm}).success(resolve).fail(reject);
    });
  },

  async sign(entity, id, html, nome = 'assinado.pdf', download = true, requestOptions, downloadSign = false, isHomologacao){
    const thumb = await this.getThumb();
    if(!thumb){
      await Promise.reject("Erro ao tentar utilizar o certificado digital. Realize seu login novamente.");
    }

    const cert = await this.readCertificate(thumb).catch(e => {
      Promise.reject(`Erro ao tentar utilizar o certificado digital. Realize seu login novamente. (${e?.message || e})`);
    });
    const auth = await this.getLoginHash();
    const signatureCert = await this.signData({
      thumbprint: thumb,
      data: auth.nonce,
      digestAlgorithm: auth.digestAlgorithm
    });

    const preSign = await this.preSignEntity(entity, id, cert, html, requestOptions, signatureCert, auth.nonce, isHomologacao);
    const signature = await this.signHash(thumb, preSign.toSignHash, preSign.digestAlgorithm);
    let file = await this.signEntityFile(entity, id, signature, preSign.transferFile, nome, preSign.xml, downloadSign, requestOptions, isHomologacao);

    if(nome && file && download){
      file = await this.download(entity, id);
      FrontBusiness.downloadFile(file, nome);
    }
    if (nome && file && downloadSign) {
        FrontBusiness.downloadFile(file, nome);
    }
    return file;
  },

  async signAnexo(id){
    return this.sign("anexo", id, null, false);
  },

  async preSignEntity(entity, id, cert, html = '', request, signature, nonce, isHomologacao){
    return Http.post(`${isHomologacao ? pathHomologacao : path}/${entity}/${id ? id + '/' : ''}pre-sign?n=${nonce}`, {cert, html, request, signature, nonce});
  },

  async signEntityFile(entity, id, signature, transferFile, nome = '', xml = '', downloadSign = false, request, isHomologacao){
    if (downloadSign) {
      return Http.post(`${isHomologacao ? pathHomologacao : path}/${entity}/${id ? id + '/' : ''}sign`, {signature, transferFile, nome, xml, request},{
        responseType: 'blob',
        timeout : 0,
        headers: {'Content-Type': 'application/json'}
      })
    } else {
      return Http.post(`${isHomologacao ? pathHomologacao : path}/${entity}/${id ? id + '/' : ''}sign`, {signature, transferFile, nome, xml, request})
    }
  },

  async download(entity, id){
    const blobToString = (b) => {
      var u, x;
      u = URL.createObjectURL(b);
      x = new XMLHttpRequest();
      x.open('GET', u, false); // although sync, you're not fetching over internet
      x.send();
      URL.revokeObjectURL(u);
      return x.responseText;
    };

    let k = new Date().valueOf();
    return Http.get(`${path}/${entity}/public/${id}/download?reset=${k}`,{
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      console.debug('response', response);
      if(response.type.includes('application/json')){
        let url = blobToString(response);
        if(url.startsWith('http')){
          return url;
        }
      }
      return new Blob([response], {type: "application/pdf"});
    })
  },

  /************************************/

  async decrypt(data, algorithm = 'RSA-OAEP'){
    return new Promise(async (resolve, reject) => {

      const thumb = await this.getThumb();
      if(!thumb){
        reject("Erro ao tentar utilizar o certificado digital. Realize seu login novamente.");
      }

      console.debug('Decrypting with thumb', pki.encryptionParameters.rsaEncryptionOaepSHA256)

      pki.decrypt({
          certificateThumbprint: thumb,
          data: data,
          parameters: pki.encryptionParameters.rsaEncryptionOaepSHA256
        })
        .success(resolve)
        .fail(reject);
    });
  }

}

/*
    RSAEncryptionPkcs1 : 'RSAEncryptionPkcs1',
    RSAEncryptionOaepSHA1 : 'RSAEncryptionOaepSHA1',
    RSAEncryptionOaepSHA256 : 'RSAEncryptionOaepSHA256',
    RSAEncryptionOaepSHA384 : 'RSAEncryptionOaepSHA384',
    RSAEncryptionOaepSHA512 : 'RSAEncryptionOaepSHA512'
 */

<template>

  <nav>
    <ul class="pagination pagination-sm text-right mb-0">

      <!-- Previous page -->
      <li class="page-item" v-if="page > 1">
        <a href="#" @click.prevent="changePage(page - 1)" class="page-link">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span class="sr-only">Anterior</span> <!-- sr-only = only for screen readers -->
        </a>
      </li>

      <!-- Active page item -->
      <li class="page-item" v-for="i in pages" :key="i" :class="{active : page == i}">
        <a href="#" @click.prevent="changePage(i)" class="page-link" tabindex="-1">{{i}}</a>
      </li>

      <!-- Next page -->
      <li class="page-item" v-if="page < maxPage">
        <a href="#" @click.prevent="changePage(page + 1)" class="page-link">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
          <span class="sr-only">Próximo</span> <!-- sr-only = only for screen readers -->
        </a>
      </li>
    </ul>
  </nav>

</template>

<script>
export default {
  name: "VueTablePager",
  props:{
    page : Number,
    total : Number,
    perPage : Number,
    maxPage : Number,
  },

  computed:{
    pages(){
      let pages = [this.page - 4, this.page - 3, this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2, this.page + 3, this.page + 4 ].filter(i => i > 0 && i <= this.maxPage);
      return pages;
    }
  },

  methods:{
    changePage(page){
      if(page != this.page){
        this.$emit('change-page', page < this.maxPage ? page : this.maxPage);
      }
    }
  }

}
</script>

<template>

    <v-input type="date" class="col-3" label="Vencimento" v-if="mostrar"
             v-model="ato.dto.configuracaoRestricao.vencimento" :disabled="((!!ato.registro && !edicao) && !ato.legado) || (ato.legado && !edicao) || !edicao"/>

</template>

<script>

    export default {
        name: "VencimentoRestricao",
        props: {
            ato: {},
            edicao: {default: false}
        },
        beforeMount() {
            if(!this.ato.dto.configuracaoRestricao.vencimento) this.ato.dto.configuracaoRestricao.vencimento = null;
        },
        computed:{
          mostrar(){
            return this.ato.tipoServico?.opcoes?.mostrarVencimentoRestricao
          }
        }
    }
</script>

<template>
  <consulta :columns="columns" :process="process" id="consultar-indicador-pessoal"
            :titulo="titulo" :grabbable="true" :exportar="exportar"
            :custom-actions="actions">

    <template #filtros>
      <div class="form-check form-check-inline form-group mb-1">
        <input :disabled="disabledCampo('versao')" class="form-check-input" id="versao" type="checkbox"
               v-model="filtros.versao">
        <label for="versao" class="form-check-label">Pesquisar nas versões</label>
      </div>

      <div class="w-100"></div>

      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1 pl-i">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">
          <v-input v-if="item.id === 'tipo_cadastro'" v-model="item.value" :options="tiposCadastro" @change="getTipoCadastro(item.value)" type="select" class="col" />
          <v-input v-if="item.id === 'livro'" v-model="item.value" type="select" :options="novoOptionsLivro"  class="col"/>
        </template>
      </filtrar-consulta>
      <template v-if="mode == 'default'">

        <div class="w-100"></div>
        <v-input type="text" label="Nome" v-model="filtros.nome" class="col-6" />

        <v-input :disabled="disabledCampo('tipoPessoa')" v-model="filtros.tipoPessoa" :options="options.tiposPessoa" type="select" label="Tipo de Pessoa" class="col" />
        <!--      <v-input :disabled="disabledCampo('atribuicao')" v-model="filtros.atribuicao" :options="options.atribuicoes" type="select" label="Atribuição" class="col" />-->

        <v-input default="TODOS" v-model="filtros.tipoCadastro" :options="options.tiposCadastro" type="select" label="Tipo de Cadastro" class="col" />
        <v-input type="select" :label="`Tipo de busca do ${labelDocumento}`" :options="options.tiposBusca" v-model="filtros.tipoBuscaDocumento"
                 class="col-2" v-if="filtros.tipoPessoa != 'AMBOS'"/>
        <div class="w-100"></div>
        <v-input type="cnpj" :label="labelDocumento" v-model="filtros.documento" class="col" v-if="filtros.tipoPessoa === 'JURIDICA'" />
        <v-input type="cpf" :label="labelDocumento" v-model="filtros.documento" class="col" v-if="filtros.tipoPessoa === 'FISICA'" />

        <template v-if="filtros.tipoPessoa === 'FISICA'" >
          <v-input class="col-3" v-model="filtros.rg" type="text" label="RG"/>
          <v-input class="col-3" v-model="filtros.cnh" type="text" label="CNH"/>
          <v-input class="col-3" v-model="filtros.cip" type="text" label="Identidade Profissional"/>
          <v-input class="col-3" v-model="filtros.outros_documentos" type="text" label="Outros Documentos"/>
        </template>

        <v-input class="col-3" v-model="filtros.email" type="email" label="E-mail"/>
        <v-input class="col-3" v-model="filtros.telefone" type="telefone" label="Telefone"/>
        <v-input class="col-3" v-model="filtros.celular" type="telefone" label="Celular"/>
        <v-input class="col-3" v-model="filtros.comercial" type="telefone" label="Comercial"/>
        <v-input class="col-3" v-model="filtros.sequencial" type="text"  label="Sequencial"/>
        <v-input class="col-3" v-model="filtros.livro" type="select" :options="optionsLivro"  label="Livro" />

        <div class="w-100"></div>

        <v-input type="date" label="Data Cadastro (Início) " v-model="filtros.cadastroInicio" class="col-3" />
        <v-input type="date" label="Data Cadastro (Fim)" v-model="filtros.cadastroFim" class="col-3" />

        <v-input type="date" label="Data de Alteração Manual (Início) " v-model="filtros.dataAlteracaoManualInicio" class="col-3" />
        <v-input type="date" label="Data de Alteração Manual (Fim)" v-model="filtros.dataAlteracaoManualFim" class="col-3" />

        <v-input type="date" label="Data Registro (Início) " v-model="filtros.dataRegistroInicio" class="col-3" />
        <v-input type="date" label="Data Registro (Fim)" v-model="filtros.dataRegistroFim" class="col-3" />
      </template>
    </template>

    <template #row="{ row }">

      <!--      <tr @click="select(row)" class="pointer">-->
      <tr>

        <td width="50px" class="text-center">
          <a href class="icon" @click.prevent.stop="detalhes(row.indicadorPessoalId, row.id)">
            <i class="fal fa-address-book"></i>
          </a>
        </td>

        <td style="min-width: 300px;" class="nowrap">
          <a @click="select(row)" class="pointer icon mr-2">{{ row.nome }}</a>
          <alert-indisponibilidade :pessoa="row.indicadorPessoalId" />
        </td>

        <td>{{ row.documento }}</td>
        <td>{{ row.versao }}</td>
        <td>{{ row.cep }}</td>
        <td>{{ row.logradouro }}</td>
        <td>{{ row.bairro }}</td>
        <td>{{row.email}}</td>
        <td>{{ row.cidade }}</td>
        <td>{{ row.estado }}</td>
        <td>{{ labelEstadosCivil(row) }}</td>

      </tr>

    </template>

  </consulta>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import RelatorioBusiness from "@/business/RelatorioBusiness.js";
import AlertMessage from "@/components/alert/AlertConstMessage.js";
import AlertIndisponibilidade from "@/components/alert/AlertIndisponibilidade.vue";
import Consulta from "@/components/Consultas/Consulta.vue";
import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal.vue";
import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal.vue";
import Utils from "@/commons/Utils.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness";
import ConsultarRelatorio from "@/components/Consultas/ConsultarRelatorio.vue";

export default {
    name: "ConsultarIndicadorPessoal",
    components: {FiltrarConsulta, AlertIndisponibilidade, Consulta},
    props: {
      seleciona : {type : Boolean, default : true},
      fornecedor : {type : Boolean, default : false},
      versao : {type : Boolean, default : false},
      tipoPessoa : {type : String, default : 'AMBOS'},
      atribuicao : {type : String, default : 'TODOS'},
      disabledFiltros : {type : Array, default: () => []},
    },
    data() {

      let columns = [
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        {label: 'Nome', field: 'v.nome', headerClass: 'column__nome', name: 'nome', minWidth:'250px'},
        {label: 'Documento', field: 'v.documento', headerClass: 'column__documento', name: 'documento', minWidth:'170px'},
        {label: 'Versão', field: 'v.versao', headerClass: 'column__versao', name: 'versao'},
        {label: 'CEP', field: 'v.cep', headerClass: 'column__cep', name: 'cep'},
        {label: 'Logradouro', field: 'v.logradouro', headerClass: 'column__logradouro', name: 'logradouro', minWidth:'250px'},
        {label: 'Bairro', field: 'v.bairro', headerClass: 'column__bairro', name: 'bairro', minWidth:'250px'},
        {label: 'E-mail', field: 'i.email', headerClass: 'column__email', name: 'email', minWidth: '170px'},
        {label: 'Cidade', field: 'v.cidade', headerClass: 'column__cidade', name: 'cidade'},
        {label: 'UF', field: 'v.estado', headerClass: 'column__uf', name: 'estado'},
        {label: 'Estado Civil', field: 'v.estado_civil', headerClass: 'column__uf', name: 'estadoCivil'},
      ];
      let filtros = [{id: "tipo_cadastro",type: null,value: "TODOS", hiddenActions: true}]
      if(this.tipoPessoa && this.tipoPessoa !== 'AMBOS'){
        filtros.push({id: "tipo_pessoa",type: null,value: this.tipoPessoa, hiddenActions: true, disabled: true});
      }

      return {
        columns,
        mode: 'novo',
        opcoesConsulta: IndicadorPessoalBusiness.camposConsulta(),
        filtros : {
          filtros,
          tipoPessoa : this.tipoPessoa,
          atribuicao:  this.atribuicao,
          versao: this.versao,
          fornecedor: this.fornecedor,
          tipoBuscaDocumento: 'contains'
        },
        options : {
          tiposPessoa: [{id : 'AMBOS', nome : 'Ambos'}, ...IndicadorPessoalBusiness.getTiposPessoa()],
          atribuicoes: [{id : 'TODOS', nome : 'Todos'}, ...ProtocoloBusiness.getProtcolos()],
          tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
          tiposCadastro:[{id : 'TODOS', nome : 'Todos'}, ...IndicadorPessoalBusiness.getTiposCadastro(this.$root.isRI, this.$root.isRTD, this.$root.isRCPJ)]
        },
        tipoCadastro: null,
        indicadorPossuiIndisponibilidade : AlertMessage.indicadorPossuiIndisponibilidade(),
        indicadorPossuiOcorrencias : AlertMessage.indicadorPossuiOcorrencias()
      }

    },

    computed: {
      titulo() {
        let titulo = `Consultar Pessoas`
        if (this.fornecedor) {
          titulo += ' - Fornecedor'
        }
        if (this.tipoPessoa !== 'AMBOS')  {
          titulo += ` - ${FrontBusiness.getLabel(this.tipoPessoa, this.options.tiposPessoa)}`
        }
        if (this.atribuicao !== 'TODOS')  {
          titulo += ` - ${FrontBusiness.getLabel(this.atribuicao, this.options.atribuicoes)}`
        }
        if (this.disabledFiltros.includes("versao"))  {
          titulo += `${this.versao ? " " : "  "}`
        }

        return titulo;
      },
      labelDocumento() {
        return this.filtros.tipoPessoa === 'JURIDICA' ? 'CNPJ' : 'CPF';
      },

      tiposCadastro (){
        let isRI = this.$root.isRI;
        let isRTD = this.$root.isRTD;
        let isRCPJ = this.$root.isRCPJ;
          let options = [{id : 'TODOS', nome : 'Todos'}];

          if(isRI){
            options.push({id: 'indicadorRI', nome: 'Indicador Pessoal RI'});
          }

          if(isRTD){
            options.push({id: 'indicadorRTD', nome: 'Indicador Pessoal RTD'});
          }

          if(isRCPJ){
            options.push({id: 'indicadorRCPJ', nome: 'Indicador Pessoal RCPJ'});
          }

          return options;
      },

      novoOptionsLivro(){
        return ProtocoloBusiness.getLivros(this.tipoCadastro)
      },

      optionsLivro(){
        let options = [{id : 'TODOS', nome : 'Todos'}];

        if(this.filtros.tipoCadastro === 'indicadorRI'){
          options.push({id: 'livro-matricula', nome: "RI - Livro 2"});
          options.push({id: 'livro-registro-auxiliar', nome: "RI - Livro 3"});
          options.push({id: 'transcricao', nome: "RI - Transcrição"});
        }

        if(this.filtros.tipoCadastro === 'indicadorRTD'){
          options.push({id: 'livro-rtd-B', nome: "RTD - Livro B"});
          options.push({id: 'livro-F', nome: "RTD - Livro F"});
          options.push({id: 'livro-C', nome: "RTD - Livro C"});

        }

        if(this.filtros.tipoCadastro === 'indicadorRCPJ'){
          options.push({id: 'livro-rcpj-B', nome: "RCPJ - Livro B"});
          options.push({id: 'livro-A', nome: "RCPJ - Livro A"});
        }
        return options;
      },

      auditor() {
        return this.$root.usuarioLogado.auditor;
      },

      exportar(){
        let forms = {
          filtros:[...this.filtros?.filtros].filter(e => e.id),
          versao:this.filtros?.versao,
          direction:"asc",
          sort:"v.nome,codigo_ficha, a.codigo, v.id",
        };
        return this.mode === 'novo' && !this.auditor ? {id: "EXPORTAR_INDICADOR_PESSOAL", forms, extensao: "XLSX"} : null;
      },

      actions() {
        let actions = [];
        if (this.auditor) return actions;
        actions.push({
          class: 'at-options',
          icon: 'fal fa-plus-square mr-1',
          label: 'Adicionar',
          action: this.novo
        });
        return actions;
      }
    },

    methods:{
      getTipoCadastro(item){
        this.tipoCadastro = item;
      },

      close(){
        FrontBusiness.closeModal();
      },

      select(indicador){
        if (this.seleciona) {
          FrontBusiness.closeModal(indicador);
        } else {
          this.detalhes(indicador.indicadorPessoalId, indicador.id)
        }
      },

      async process(sort_by, sort_dir, page_length, page_number){
        if (this.mode === 'novo') {
          let filtros = [...this.filtros?.filtros].filter(e => e.id);
          if (this.fornecedor) filtros.push({id: 'fornecedor', value: true});
          let livro = filtros.filter(e => e.id === 'livro');
          return IndicadorPessoalBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {versao: this.filtros.versao, filtros, livro: livro})
            .then(result => ({rows: result.pagina, total: result.quantidade}));
        }
        return IndicadorPessoalBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros : this.filtros})
          .then(result => ({rows : result.pagina, total: result.quantidade}));
      },

      detalhes(id, versao){
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id, versao});
      },

      async novo(){
        let pessoa = await FrontBusiness.openModal(FormIndicadorPessoal);
        if(pessoa){
          FrontBusiness.showSuccess('', 'Indicador cadastrado com sucesso');
        }
      },

      labelEstadosCivil(pessoa) {
        return FrontBusiness.getLabel(pessoa.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil());
      },

      disabledCampo(campo) {
        return this.disabledFiltros.includes(campo);
      },
    }
  }
</script>

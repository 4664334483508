<template>

  <v-button v-if="hash" :run="open" title="Digitalizar Arquivo" class="btn pointer">
    <slot>
      <i class="fal fa-scanner-image"></i>
    </slot>
  </v-button>

</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness.js";

export default {
  name: "ScannerLink",

  props:{
    entidade : String,
    id : String,
    usuario : String,
    grupo : String,
    etapa : String,
  },

  data(){
    return {
      hash: null
    }
  },

  computed:{
    urlBase(){
      // return 'https://intranet.sistemaasgard.com.br';
      return /*window.server.dev ? 'http://localhost:9091' :*/ `https://${window.server.hom ? 'hom-' : ''}intranet.sistemaasgard.com.br`;
    },
    client(){
      return window.server.API;
    }
  },

  async beforeMount() {
    this.hash = await AnexoBusiness.createHash(this.entidade, this.id);
  },

  beforeDestroy() {
    window.removeEventListener('message', this.listen);
  },

  methods:{
    async open(){

      const obj = {
        h: this.hash,
        t: this.entidade,
        i: this.id,
        u: this.usuario,
        c: this.client,
        g: this.grupo,
        e: this.etapa
      };

      let query = Object.keys(obj)
                        .filter(k => !!obj[k])
                        .map((k) => k + '=' + encodeURIComponent(obj[k]))
                        .join('&');

      let url = `${this.urlBase}/#/scanner?${query}`;
      const w = window.open(url, '', "toolbar=no,scrollbars=yes,resizable=yes,width=1200,height=900");
      window.addEventListener('message', this.listen);
    },

    listen(event){

      if (event.origin.startsWith(this.urlBase)) {
        if(event.data.includes("load")){
          this.$emit("load");
        }
      }

      return;

    }

  }

}
</script>

<template>
    <div class="col">
        <label v-if="label">{{label}}</label>
        <select class="form-control form-control-xs col" @change="onChange($event)" :value="model">
            <option v-for="(coluna, index) in colunas" :value="coluna" :key="'coluna'+coluna+index">Coluna: "{{coluna}}"
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "SelectColunaPlanilha",

        props:{
            label: String,
            colunas: Array,
            valor: String,
        },

        data(){
          return {
              model: {},
          }
        },

        mounted() {
            this.model = this.valor;
        },

        methods: {

            onChange(event){
                this.$emit('update:model', event.target.value);
                this.$emit('change', event.target.value);
            }

        }

    }
</script>

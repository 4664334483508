
const getVarsList = function(input = ''){

  const _regex = /(\$\([a-zA-Z_0-9.\s]+\))/g;
  return (String(input || '').match(_regex) || []).map(variavel => ({
    value: variavel,
    newValue: `<var attr="${variavel.substr(2, variavel.length - 3)}" />`
  }));

};

export default getVarsList;

import Http from "@/commons/Http";

const path = 'api/documentacao';

export default {

  async pagination(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination?sort=${sort || 'd.codigo'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'd.codigo'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  nomearEntidade(documentacao){
    return `Documentação`;
  },

  async listar(indicador = '', filtros){
    return Http.post(`${path}/indicador-pessoal/${indicador}`, filtros);
  },

  async getById(indicador){
    return Http.get(`${path}/${indicador}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  tipos(){
    return [
      {id: 'CONTRATO_SOCIAL', nome: 'Contrato Social'},
      {id: 'PROCURACAO', nome: 'Procuração'},
      {id: 'ATA', nome: 'Ata'},
      {id: 'CERTIDAO', nome: 'Certidão'},
      {id: 'ESTATUTO', nome: 'Estatuto'},
      {id: 'OUTROS', nome: 'Outros'}
    ]
  },

  getPapeisEnvolvidos(){
    return [
      {id: 'ADMINISTRADOR', nome: 'Administrador'},
      {id: 'OUTORGANTE', nome: 'Outorgante'},
      {id: 'OUTORGADO', nome: 'Outorgado'},
      {id: 'SOCIO', nome: 'Sócio'},
      {id: 'SOCIO_ADMINISTRADOR', nome: 'Sócio administrador'},
    ]
  }

}

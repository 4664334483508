import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import ConsultarRelatorio from "@/components/Consultas/ConsultarRelatorio.vue";
import ExportUtils from "@/commons/ExportUtils";
export default {
  async exportarRelatorio(params) {
    const {forms, id, extensao} = params;
    try {
      await ArquivoRelatorioBusiness.save(id, forms, extensao);
      FrontBusiness.showSuccess('', "Relatório adicionado à fila de processo.");
      ExportUtils.openRelatorio({identificacao: id})
    } catch (e) {
      FrontBusiness.showError('Erro ao adicionar relatório a fila de processo!', e?.message);
    }
  },
  openRelatorio(params = {}) {
    FrontBusiness.openModal(ConsultarRelatorio, params);
  }
}

<template>
  <modal id="form-registro-td" :titulo="titulo" :loading="!form">

    <form @submit.prevent="" v-if="form" autocomplete="off">

      <card>

        <card-content class="row gutters-xs">

          <v-input :error="validation.tipoRegistro" v-model="form.tipoRegistro" :options="options.tiposRegistros"
                   type="select" class="col-4" label="Tipo de Registro" />

          <v-input class="col-2" label="Último Ato" v-model="form.ultimoAto" v-if="form.status == 'ABERTA'"
                   type="currency" :input-props="{precision : 0}" />

          <v-input v-if="form.id && form.status !== 'PENDENTE'" :error="validation.status" v-model="form.status" :options="options.statusEdicao"
                   type="select" class="col-2" label="Situação" />

          <template v-if="form.status !== 'PENDENTE'">
            <div class="w-100"></div>
            <v-input type="datetime-local" class="col-6" label="Abertura"
                     v-model="form.abertura" :error="validation.abertura"/>

            <v-input v-if="form.status !== 'ABERTA'" type="datetime-local" class="col-6" :label="form.status === 'CANCELADA' ? 'Cancelamento' : 'Encerramento'"
                     v-model="form.encerramento" :error="validation.encerramento"/>

          </template>

        </card-content>

      </card>

      <card titulo="Identificação">
        <card-content class="row gutters-xs">

          <v-input class="col-4" v-model="form.livroLetra" type="text" label="Livro (Letra)" />

        </card-content>
      </card>

      <card titulo="Observações">
        <card-content class="row gutters-xs">
          <v-input type="editor" class="col" v-model="form.observacoes" />
        </card-content>
      </card>

    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>
<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroPJBusiness from '@/business/indicadores/RegistroPJBusiness.js';
  import utils from "@/commons/Utils";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";

  export default {
    name: "FormRegistroPJ",
    components: {
      Card,
      CardContent,
      Modal
    },

    modal: {
      escapable: false
    },

    props: {
      id: String,
      livro: String,
      clone: {type: Object, default: () => ({})}
    },

    async mounted() {

      let registroTd = {};

      if (this.id) {
        registroTd = await RegistroPJBusiness.getById(this.id);
        this.titulo = `Editar ${this.nomearLivro(registroTd)}`;
      } else {
        this.titulo = 'Adicionar ' + this.nomeLivro;
        registroTd = {...this.clone};
      }

      let form = {
        ...registroTd
      };

      form.abertura = registroTd.abertura ? this.$moment(registroTd.abertura).format('YYYY-MM-DDTHH:mm:ss') : null;
      form.encerramento = registroTd.encerramento ? this.$moment(registroTd.encerramento).format('YYYY-MM-DDTHH:mm:ss') : null;

      this.$set(this, 'form', form);
    },

    data() {
      return {
        form: null,
        validation: {},
        titulo: '',
        decimais: 2,
        options: {
          tiposRegistros: RegistroPJBusiness.getTipos(),
          statusEdicao: RegistroPJBusiness.getStatusImovel(false),
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        }
      }
    },

    computed: {
      nomeLivro() {
          return FrontBusiness.nomearFicha({livro: this.form?.livro || this.livro, status: ''}, false);
      },
    },

    methods: {

      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#form-registro-titulo');
      },

      nomearLivro: FrontBusiness.nomearFicha,

      async salvar() {
        this.form.livro = this.form.livro || 'REGISTRO_PJ';
        let dto = utils.clone(this.form);

        if (!this.id) {
          FrontBusiness.closeModal(dto);
          return;
        }

        let registroTd = await RegistroPJBusiness.save(this.id, dto).catch(this.showError);
        if (registroTd) {
            FrontBusiness.closeModal(registroTd);
        }
      },

    }
  }
</script>

<style lang=scss>
  #form-registro-td {
    .card {
      hr {
        margin-top: .25rem;
      }

      .busca-cep {
        margin-top: 21px;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>

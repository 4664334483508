<template>

  <modal id="detalhes-ocorrencia" class="modal-detalhes-ocorrencia" :titulo="titulo" :loading="loading">

    <template v-if="ocorrencia">
      <div id="aba-dados-principais" v-if="ocorrencia">

        <card>
          <div class="row gutters-xs ocorrencia">

            <v-input type="v-select" :options="listas.tiposServico" label="Tipo de Ocorrência"
                     class="col-6" @change="setTipoServico()" v-model="tipoServico" />

            <v-input type="date" class="col-6" label="Data da Ocorrência"
                     v-model="ocorrencia.dataOcorrencia" :error="validation.dataOcorrencia" />

            <v-input class="col-12" :error="validation.descricao"
                     v-model="ocorrencia.descricao" type="text" label="Descrição" />

          </div>
        </card>

      </div>
    </template>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>

  import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness.js";
  import Utils from "@/commons/Utils.js";
  import Modal from "@/components/Modal.vue";
  import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness";
  import Card from "@/components/Layout/components/Card.vue";

  export default {

    name: "DetalhesOcorrencia",
    components: {
      Modal, Card
    },

    props: {
      anterior: Object,
      envolvido: Object,
      matricula: Object,
      id: String,
    },

    modal:{
      width: 800,
      escapable: false
    },

    async mounted(){
      this.loading = true;
      await TipoServicoBusiness.getByDominioSimples('OCORRENCIA').then(l => this.listas.tiposServico = l);

      this.ocorrencia = {
        envolvidos: [],
        indices: {},
        tipoServico: {},
        matriculas: [],
        protocolos: []
      };

      if(this.id){
        this.ocorrencia = await OcorrenciaBusiness.getById(this.id);
        this.tipoServico = this.ocorrencia.tipoServico;
      }

      if(this.matricula){
        this.ocorrencia.matriculas.push(this.matricula);
      }

      if(this.envolvido){
        this.ocorrencia.envolvidos.push(this.envolvido);
      }

      this.ocorrencia.tipoServico = this.ocorrencia.tipoServico || {};

      this.loading = false;

    },

    computed:{

      titulo(){
        return this.id ? 'Editar Ocorrência' : 'Cadastrar Ocorrência';
      },

    },

    data() {
      return {
        loading: false,
        ocorrencia : null,
        tipoServico : this.ocorrencia?.tipoServico?.id,
        validation: {},
        listas: {
          tiposServico : []
        }
      }
    },

    methods:{

      close : FrontBusiness.closeModal,

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#formulario-ocorrencia');
      },

      setTipoServico(){
        if(this.tipoServico){
          this.ocorrencia.tipoServico = this.listas.tiposServico.find(e => e.id == this.tipoServico);
        }
      },

      async salvar() {
        if(!this.ocorrencia.tipoServico?.id){
          return null;
        }

        let dto = Utils.clone(this.ocorrencia);
        //dto.envolvidos = dto.envolvidos?.filter(e => e).map(e => e.id);
        dto.matriculas = dto.matriculas?.filter(e => e).map(e => e.id);
        dto.protocolos = dto.protocolos?.filter(e => e).map(e => e.id);
        dto.tipoServico = Utils.getObjectId(dto.tipoServico);
        let retorno = await OcorrenciaBusiness.save(dto, this.ocorrencia?.id).catch(this.showError);
        if(retorno){
          await OcorrenciaBusiness.regerarChecklist(retorno).catch(e => console.error(e)).catch(e => console.error(e));
          FrontBusiness.redirect(`ocorrencia/${retorno}/geral`);
          this.close();
        }
      },

    }

  }
</script>

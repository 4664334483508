<template>
  <modal titulo="Envolvidos Sugeridos">

    <div class="alert alert-warning" v-if="!pessoas.length">
      Nenhum envolvido sugerido
    </div>

    <template v-for="lista in listas">

      <card :titulo="lista.nome" :key="lista.nome" v-if="lista.pessoas.length">

        <card-table>
          <thead>
          <tr>

            <th>Pessoa</th>
            <th width="150px">Documento</th>
            <th width="100px" class="text-center" v-if="lista.nome == 'Proprietários'">{{ unidadeArea }}</th>
            <th width="100px" class="text-center" v-if="lista.nome == 'Proprietários'">Fração</th>
            <th width="200px" v-if="lista.nome == 'Envolvidos'">Procurador</th>

            <th width="20px">
              <a class="icon" title="Adicionar Todos" @click.prevent="selecionar(lista.pessoas, true)"><i class="fa fa-plus"></i></a>
            </th>

          </tr>
          </thead>

          <tbody>
          <template  v-for="(pessoa) in lista.pessoas" >
            <tr :key="`${pessoa.indicadorPessoalVersao.id}-${pessoa.indicadorPessoalVersao.procurador}-${pessoa.indicadorPessoalVersao.procuradorId}`">
              <td>
                {{pessoa.indicadorPessoalVersao.nome}} (Versão: {{pessoa.indicadorPessoalVersao.versao}})
                <div class="small text-muted text-orange w-100" v-if="pessoa.indicadorPessoalVersao.conjuge" >
                  Cônjuge: {{pessoa.indicadorPessoalVersao.conjuge.nome}} (Versão: {{pessoa.indicadorPessoalVersao.conjuge.versao}})
                </div>
              </td>
              <td>{{pessoa.indicadorPessoalVersao.documento}}
                <div class="small text-muted text-orange w-100" v-if="pessoa.indicadorPessoalVersao.conjuge" >
                  {{pessoa.indicadorPessoalVersao.conjuge.documento}}
                </div>
              </td>

              <td class="text-center" v-if="lista.nome == 'Proprietários'">
                {{$formatters.currency(pessoa.area,{min : 2, max : 10})}}
                <div class="small text-muted text-orange w-100" style="height: 16px;" v-if="pessoa.indicadorPessoalVersao.conjuge" ></div>
              </td>

              <td class="text-center" v-if="lista.nome == 'Proprietários'">
                {{pessoa.fracao}}%
                <div class="small text-muted text-orange w-100" style="height: 16px;" v-if="pessoa.indicadorPessoalVersao.conjuge" ></div>
              </td>

              <td v-if="lista.nome == 'Envolvidos'">
                {{pessoa.indicadorPessoalVersao.procurador || ''}}{{`${pessoa.indicadorPessoalVersao.procuradorId?' - ':''}${pessoa.indicadorPessoalVersao.procuradorNome || ''}`}}
                <div class="small text-muted text-orange w-100" style="height: 16px;" v-if="pessoa.indicadorPessoalVersao.conjuge" ></div>
              </td>

              <td>
                <a class="icon" title="Adicionar Transmitente" @click.prevent="selecionar([pessoa])">
                  <i class="fa fa-plus"></i>
                </a>
                <div class="text-muted text-orange w-100" v-if="pessoa.indicadorPessoalVersao.conjuge" >
                  <a class="icon" title="Adicionar Cônjuge" @click.prevent="selecionar([{indicadorPessoalVersao: pessoa.indicadorPessoalVersao.conjuge}])">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </td>

            </tr>
          </template>


          </tbody>

        </card-table>

      </card>

    </template>

  </modal>
</template>

<script>

import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import EventBus from "@/commons/EventBus";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";

export default {

  name: "EnvolvidosAto",
  components: {CardTable, Card, Modal},
  props: {
    protocolo : String,
    ficha : Object,
    atuais : Array,
    proprietarios : Array,
  },

  async mounted(){

    let interessado = this.protocolo ? await ProtocoloBusiness.getInteressadosProtocolo(this.protocolo) : [];
    let outros = this.protocolo ? await AtoBusiness.getEnvolvidos(this.protocolo) : [];
    let conjuges = this.protocolo ? await AtoBusiness.getEnvolvidosConjugesProtocolo(this.protocolo) : [];
    let conjugeInteressado = this.protocolo ? await ProtocoloBusiness.getConjugeInteressado(this.protocolo) : [];

    let registroAuxiliar = this.ficha?.livro === "REGISTRO_AUXILIAR" ? await RegistroAuxiliarBusiness.getEnvolvidos(this.ficha.id) : [];

    let pessoas = [];

    conjuges.push(conjugeInteressado[0])
    outros.push(interessado[0]);

    pessoas.push(...(Utils.clone(this.proprietarios) || []));
    (pessoas || []).forEach(e => {
      if (e.indicadorPessoalVersao?.conjuge) conjuges.push({
        conjuge: e.indicadorPessoalVersao?.id,
        documento: e.indicadorPessoalVersao?.conjuge?.documento,
        id: e.indicadorPessoalVersao?.conjuge?.id,
        indicadorPessoalId: e.indicadorPessoalVersao?.conjuge?.indicadorPessoalId,
        nome: e.indicadorPessoalVersao?.conjuge?.nome,
        tipoPessoa: e.indicadorPessoalVersao?.conjuge?.tipoPessoa,
        versao: e.indicadorPessoalVersao?.conjuge?.versao,
      });
    });

    conjuges = conjuges.filter(p => !this.atuais.find(a => a.indicadorPessoalVersao?.id === p?.id || a.indicadorPessoalVersao?.indicadorPessoalId === p?.id));
    conjuges = conjuges.filter(p => !pessoas.find(a => a.indicadorPessoalVersao?.id === p?.id || a.indicadorPessoalVersao?.indicadorPessoalId === p?.id));

    (outros || []).forEach(e => {
      pessoas.push({ fracao : null, indicadorPessoalVersao : e });
    });

    (registroAuxiliar || []).forEach(e => {
      pessoas.push({ fracao : null, indicadorPessoalVersao : e });
    });

    pessoas.forEach(p => {
      if(p.indicadorPessoalVersao?.conjuge){
        p.indicadorPessoalVersao.conjuge = conjuges.find(c => c?.conjuge === p.indicadorPessoalVersao?.id);
      }

      if(conjugeInteressado.length){
        p.indicadorPessoalVersao.indicadorPessoalId === conjugeInteressado[0].conjuge ? p.indicadorPessoalVersao.conjuge = conjugeInteressado[0] : [];
      }
    })

    this.pessoas = pessoas.filter(p => !this.atuais.find(a => a.indicadorPessoalVersao.id === p.indicadorPessoalVersao.id));
  },

  computed:{

    comFracao(){
      return this.pessoas.filter(p => p.fracao !== null);
    },

    semFracao(){
      return this.pessoas.filter(p => p.fracao == null);
    },

    listas(){
      return [
        { nome : 'Proprietários', pessoas : this.comFracao },
        { nome : 'Envolvidos', pessoas : this.semFracao },
      ]
    },

    unidadeArea(){
      return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
    }

  },

  data() {
    return {
      pessoas : []
    }
  },

  methods:{

    async selecionar(proprietarios, todos = false, conjuge = false){

      proprietarios = await Promise.all(proprietarios.map(e => {
        return IndicadorPessoalBusiness.getVersaoByIdComCache(e.indicadorPessoalVersao?.indicadorPessoalVersaoId || e.indicadorPessoalVersao?.id || e.indicadorPessoalVersao?.indicadorPessoalId).then(versao => {
          e.indicadorPessoalVersao = {...e.indicadorPessoalVersao, ...versao};
          return e;
        });
      }));

      if (todos) {
        let lista = Utils.clone(proprietarios);
        proprietarios = [];
        lista.forEach(p => {
          proprietarios.push(p);
          if (p.indicadorPessoalVersao.conjuge) {
            proprietarios.push({indicadorPessoalVersao: p.indicadorPessoalVersao.conjuge});
          }
        })
      } else {
        if (proprietarios[0].indicadorPessoalVersao.conjuge && !conjuge){
          let addConjuge = await this.confirmarConjuge(proprietarios[0].indicadorPessoalVersao.conjuge)
          if (addConjuge) {
            proprietarios.push({indicadorPessoalVersao: addConjuge});
          }
        }
      }
      EventBus.$emit(`ADD-SUGESTAO-ENVOLVIDOS`, proprietarios);
      proprietarios.map(p => p.indicadorPessoalVersao.id).forEach(id => {
        this.pessoas = this.pessoas.filter(i => i.indicadorPessoalVersao.id !== id);
        this.pessoas.forEach(i => {
          if (i.indicadorPessoalVersao?.conjuge && i.indicadorPessoalVersao?.conjuge?.id === id) {
            delete i.indicadorPessoalVersao?.conjuge
          }
        });
      })
      if (!this.pessoas.length) {
        FrontBusiness.closeModal();
      }
    },

    async confirmarConjuge(conjuge) {
      let result = FrontBusiness.showConfirmacao("Envolvido possui cônjuge", "Deseja adicioná-lo ao ato?").then(async r => {
        if (r.value) {
          return conjuge;
        } else if (r.dismiss === 'cancel') {
          return false;
        } else {
          return false;
        }
      });

      return result
    },

  }

}
</script>

<style lang=scss>

</style>

<template>
  <div id="aba-dados-principais">
    <card v-if="loading">
      <small-loading :loading="true"></small-loading>
    </card>

    <template v-else>
      <card titulo="Bem Móvel">
        <card-content>
          <p v-if="ficha.abertura">
            <span class="font-weight-500">Abertura: </span><span>{{ficha.abertura | datahora}}</span>
          </p>

          <p>
            <span class="font-weight-500">Tipo: </span><span>{{ficha.tipoBem.nome}}</span>
          </p>

          <p v-if="ficha.nome">
            <span class="font-weight-500">Nome: </span><span>{{ficha.nome}}</span>
          </p>

          <p v-if="ficha.identificacao">
            <span class="font-weight-500">Identificação: </span><span>{{ficha.identificacao}}</span>
          </p>

          <p v-if="ficha.fabricante">
            <span class="font-weight-500">Fabricante: </span><span>{{ficha.fabricante}}</span>
          </p>

          <p v-if="ficha.dataReferencia">
            <span class="font-weight-500">Data de Referência: </span><span>{{ficha.dataReferencia | data}}</span>
          </p>

          <p v-if="ficha.localizacao">
            <span class="font-weight-500">Localização: </span><span>{{ficha.localizacao}}</span>
          </p>

          <p v-if="ficha.valor">
            <span class="font-weight-500">Valor: </span><span>{{ficha.valor | currency}}</span>
          </p>

          <p v-if="ficha.notaFiscal">
            <span class="font-weight-500">Nota fiscal: </span><span>{{ficha.notaFiscal}}</span>
          </p>

          <p v-if="ficha.detalhes">
            <span class="font-weight-500">Detalhes: </span><span>{{ficha.detalhes}}</span>
          </p>
        </card-content>
      </card>

    </template>

  </div>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroTDBusiness from "@/business/indicadores/RegistroTDBusiness.js";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import SmallLoading from "@/components/SmallLoading";
  import BemMovelBusiness from "@/business/crud/BemMovelBusiness";
  export default {

    name: "Dados",
    components: {CardContent, Card, SmallLoading},
    props: {
      id: String,
    },

    data() {
      return {
        envolvidos : [],
        ficha : [],
        loading : true,
        status: RegistroTDBusiness.getStatusImovel()
      }
    },

    async mounted() {
      this.loading = true;

      await Promise.all([
        BemMovelBusiness.getById(this.id).then(r => this.ficha = r),
      ]);

      this.loading = false;
    },

    methods:{
      detalhes(versao){
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {versao});
      },

      nomearFicha: FrontBusiness.nomearFicha,

    }
  }
</script>

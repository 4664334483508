<template>

    <tag-list v-if="restricoesDisponiveis" class="form-group col-auto" titulo="Criar Restrições"
              :tags="restricoesDisponiveis" :list1="ato.dto.configuracaoRestricao.restricoesSelecionadas" :disabled="restricoesDesabilitadas" />

</template>

<script>
import TagList from "@/components/Form/TagList";
import business from "@/views/Administracao/modulos/tipo-servico/business";

export default {
        name: "OpcoesRestricoes",
        components: {TagList},
        props:{
            ato: {},
            edicao: {default: false}
        },

        mounted() {
            this.tratarCriacaoObjetoRestricao();
            this.restricoesDisponiveis = business.restricoes().filter(r => this.ato?.tipoServico?.opcoes?.criarRestricao.includes(r.id));
            if(!this.ato?.tipoServico?.opcoes?.restricaoOpcional) {
                this.ato.dto.configuracaoRestricao.restricoesSelecionadas = this.ato?.tipoServico?.opcoes?.criarRestricao;
            }
            this.dasabilitarBotoesRestricao();
        },

        data() {
            return {
                restricoesDisponiveis: null,
                restricoesDesabilitadas: false,
            }
        },
        methods:{
            tratarCriacaoObjetoRestricao(){
                  if(!this.ato.dto.configuracaoRestricao) this.ato.dto.configuracaoRestricao = {restricoesSelecionadas:[]};
                  if(!this.ato.dto.configuracaoRestricao.restricoesSelecionadas) this.ato.dto.configuracaoRestricao.restricoesSelecionadas = [];
            },
            dasabilitarBotoesRestricao(){
                if(((this.ato.registro && !this.edicao) && !this.ato.legado || this.ato.legado && !this.edicao || (!this.ato?.tipoServico?.opcoes?.restricaoOpcional))) {
                    this.restricoesDesabilitadas = true;
                }
            }
        }
    }
</script>

const apiCache = new Map();

export default {

    async getCached(key, request = async () => {}, removeAfterComplete = true){

      // Verifica se já existe uma promessa para este parâmetro específico
      if (apiCache.has(key)) {
        return apiCache.get(key);
      }

      const promise =  request();
      apiCache.set(key, promise);

      try {
        // Espera pela resposta
        const result = await promise;
        if(removeAfterComplete){
          apiCache.delete(key);
        }
        return result;
      } catch (error) {
        // Se ocorrer um erro, remove a promessa do cache para permitir novas tentativas
        apiCache.delete(key);
        throw error;
      }

    },

}

<template>
  <consulta ref="modal" :columns="columns" :process="process"
            :custom-actions="actions" id="consultar-notificacao" :titulo="titulo" :stopLoad="true">

    <template #cabecalho>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>
    </template>

    <template #filtros>
      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode !== 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">
          <v-input v-if="item.id === 'categoria'" v-model="item.value" :options="listas.categoriasNotificacao" @change="changeCategoria(item.value)" type="select" class="col" />
          <template v-if="item.id === 'matriculas_id'">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </template>

          <buscar-protocolos class="col mb-0" :noChangeSelect="true" :valorInicial="protocolo" label="Protocolos Relacionados"
                             ref="buscarProtocolos" @selecionar="selecionar('protocolo', ...arguments)"
                             :limparCampo="false" :multiple="false" :dominio="dominioProtocolo"
                             :tipoProtocolo="tipoProtocolo" v-if="item.id === 'protocolos_id'"/>
        </template>
      </filtrar-consulta>
      <template v-if="mode === 'default'">
        <div class="row w-100" @keyup.enter="executeLoad()">
          <v-input type="v-select" :options="listas.categoriasNotificacao" label="Categoria" class="col px-1" v-model="filtros.categoria" @change="filtros.ano = null" :default="'NOTIFICACAO'" />
          <v-input type="number" label="Ano" v-model.number="filtros.ano" class="col-2 px-1" v-if="filtros.categoria == 'AUTUACAO'"/>
          <v-input type="number" label="Código" v-model.number="filtros.codigo" class="col-3 px-1" />
          <v-input type="date" label="Data Cadastro (Início)" :required="true" v-model="filtros.cadastroInicio" class="col-2 px-1"/>
          <v-input type="date" label="Data Cadastro (Fim)" :required="true" v-model="filtros.cadastroFim" class="col-2 px-1"/>

          <div class="w-100"/>
          <v-input type="v-select" :options="listas.tiposServico" label="Tipo de Notificação" class="col-4 px-1" v-model="filtros.tipoServico" />
          <v-input type="select" :options="status" label="Status" v-model="filtros.status" class="col pr-1"/>
          <v-input type="select" :options="listas.etapas" label="Etapa Atual" v-model="filtros.etapa_id" class="col px-1" />
          <v-input type="text" label="Texto" v-model="filtros.texto" class="col-12 pr-1" />
        </div>

        <card class="card-full mt-2" titulo="Matrículas Relacionadas">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full mt-2" titulo="Protocolos Relacionados">
          <card-content class="row gutters-xs">
            <buscar-protocolos :noChangeSelect="true" :valorInicial="protocolo" label="Protocolo" ref="buscarProtocolos"
                               @selecionar="selecionar('protocolo', ...arguments)"
                               :limparCampo="false" :multiple="false" :dominio="dominioProtocolo" :tipoProtocolo="tipoProtocolo" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full card-envolvido mt-2 row gutters-xs" titulo="Envolvidos">
          <card-content class="row gutters-xs">
            <v-input type="select" label="Tipo de busca do CPF/CNPJ" :options="options.tiposBusca" v-model="filtros.tipoBuscaEnvolvidoDocumento" class="col-3" />
            <v-input type="text" label="CPF/CNPJ" v-model="filtros.envolvidoDocumento" class="col-3" />
            <v-input type="text" label="Nome" v-model="filtros.envolvidoNome" class="col" />
          </card-content>
        </card>
      </template>
    </template>

    <template #row="{ row }">

      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectItem(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <router-link :to="{ name: 'notificacao', params:{id : row.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </td>

        <td width="140px" class="pointer icon" @click="selecionarNotificacao(row)"><a>{{ row.codigo | inteiro }}</a></td>
        <td width="140px" v-if="getUtilizarAno()">{{ row.ano }}</td>
        <td width="140px">{{ row.tipo }}</td>
        <td width="140px">{{ row.cadastro |datahora }}</td>
        <td width="50px" class="text-center">
          <v-button :run="duplicar" :params="[row.id]" :button="false" :popover="true" class="icon"
                    position="dropleft">
            <i class="fal fa-copy" title="Duplicar"></i>
          </v-button>
        </td>

      </tr>

    </template>

    <template #footer>

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="selecionarNotificacao" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import NotificacaoBusiness from "@/business/notificacao/NotificacaoBusiness.js";
import Consulta from "@/components/Consultas/Consulta";
import Card from "@/components/Layout/components/Card";
import CardContent from "@/components/Layout/components/CardContent";
import DetalhesNotificacao from "@/views/Notificacao/DetalhesNotificacao";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta";
import Utils from "@/commons/Utils";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import EtapaBusiness from "@/business/crud/EtapaBusiness";
import RelatorioBusiness from "@/business/RelatorioBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";

export default {
  name: "ConsultarNotificacao",
  components: {FiltrarConsulta, Consulta, Card, CardContent},

  props: {
    novoNotificacao : {type : Boolean, default : true},
    select : {type : Boolean, default : false},
    multiplos : {type : Boolean, default : false}
  },

  data() {
    return {
      buscar : 'MATRICULA',
      mode: 'novo',
      opcoesConsulta: [],
      filtros : {
        filtros: [{id: "categoria",type:null,value: "NOTIFICACAO",hiddenActions:true}],
        numero : null,
        ano : null,
        protocolo: null,
        matricula: null,
        envolvidoDocumento: null,
        envolvidoNome: null,
        tipoBuscaEnvolvidoDocumento: 'contains'
      },
      options: {
        tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
      },
      status: [{id: 'TODOS', nome: 'Todos'}, ...ProtocoloBusiness.getStatus()],
      protocolo: null,
      matricula: null,
      dominioProtocolo: 'PROTOCOLO_RI',
      tipoProtocolo: 'NORMAL',
      listas:{
        tiposServico: [],
        livros: [
          {id: 'MATRICULA', nome: 'Matrícula'},
          {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
        ],
        etapas: [],
        categoriasNotificacao: NotificacaoBusiness.categoriasNotificacao
      },
      marcados: [],
      categoriaSelecionada: 'NOTIFICACAO'
    }
  },

  async mounted(){
    await TipoServicoBusiness.getByDominioSimplesTodos('NOTIFICACAO').then(l => this.listas.tiposServico = Object.freeze(Utils.orderBy(l, 'nome')));
    await EtapaBusiness.getByDominioSimples('NOTIFICACAO').then(l => this.listas.etapas = Object.freeze(Utils.orderBy(l, 'nome')));

    this.loadOpcoes();
  },

  computed:{

    titulo(){
      return 'Consultar Notificações';
    },

    columns(){
      let cols = [];
      if(this.multiplos){
        cols = [
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        ];
      }
      cols.push({label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'});
      cols.push( {label:  'Código', field: 'o.codigo', name: 'codigo'});
      if(this.getUtilizarAno()){
        cols.push({label:  'Ano', field: 'o.ano', name: 'ano'});
      }
      cols.push({label:  'Tipo', field: 't.nome', name: 'tipo'});
      cols.push({label:  'Cadastro', field: 'o.cadastro', name: 'cadastro'});
      cols.push({label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'});
      return cols;
    },

    livroSelecionado() {
      return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
    },

    statusSelecionado() {
      return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
    },

    actions() {
      let actions = [];
      if (!this.$root.isVhlUser) {
        actions.push({
          class: 'at-options',
          icon: 'far fa-file-excel mr-1',
          label: 'Exportar XLS',
          action: this.exportar
        });
      }
      if (this.novoNotificacao) {
        actions.push({
          class: 'at-options',
          icon: 'far fa-plus-square mr-1',
          label: 'Nova Notificação',
          action: this.novo
        });
      }
      return actions;
    }

  },

  methods:{
    changeCategoria(categoria){
      this.categoriaSelecionada = categoria;
      this.loadOpcoes();
    },

    getUtilizarAno(){
      return this.categoriaSelecionada === 'AUTUACAO';
    },

    loadOpcoes(){
      let opcoes = [];
      opcoes.push({id: 'codigo', nome: 'Código', type: 'number'});
      opcoes.push({id: 'tipo_servico_id', nome: 'Tipo de Notificação', type: 'list', options: this.listas.tiposServico});
      opcoes.push({id: 'cadastro', nome: 'Data Cadastro', type: 'date'});
      opcoes.push({id: 'texto', nome: 'Texto', type: 'tsquery'});
      opcoes.push({id: 'matriculas_id',  nome: 'Matrículas Relacionadas', type: 'matricula'});
      opcoes.push({id: 'protocolos_id',  nome: 'Protocolos Relacionados', type: 'protocolo'});
      opcoes.push({id: 'envolvido_cpf',  nome: 'CPF Envolvido', type: 'cpf'});
      opcoes.push({id: 'envolvido_cnpj', nome: 'CNPJ Envolvido', type: 'cnpj'});
      opcoes.push({id: 'envolvido_nome', nome: 'Nome Envolvido', type: 'text'});
      opcoes.push({id: 'status', nome: 'Status/Situação', type: 'list', options: ProtocoloBusiness.getStatus()});
      opcoes.push({id: 'etapa_id', nome: 'Etapa Atual', type: 'list', options: this.listas.etapas});
      opcoes.push({id: 'categoria', nome: 'Categoria'});
      if(this.getUtilizarAno()){
        opcoes.push({id: 'ano', nome: 'Ano', type: 'number'});
      }
      this.opcoesConsulta = Utils.orderBy(opcoes, 'nome');
    },

    close(){
      FrontBusiness.closeModal();
    },

    selecionarNotificacao(notificacao){
      FrontBusiness.closeModal(notificacao);
    },

    async process(sort_by, sort_dir, page_length, page_number){

      if(this.filtros.status === 'TODOS'){
        this.filtros.status = null;
      }

      if(this.mode === 'novo') {
        let filtros = [...this.filtros?.filtros].filter(e => e.id);
        let matriculas_id = filtros.find(e => e.id === 'matriculas_id');
        if (matriculas_id) {
          matriculas_id.value = this.filtros.matricula;
        }
        let protocolos_id = filtros.find(e => e.id === 'protocolos_id');
        if (protocolos_id) {
          protocolos_id.value = this.filtros.protocolo;
        }
        return NotificacaoBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
        .then(result => ({rows : result.pagina, total: result.quantidade}));
      }
      return NotificacaoBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros : this.filtros})
      .then(result => ({rows : result.pagina, total: result.quantidade}));
    },

    detalhes(id){
      FrontBusiness.openModal(DetalhesNotificacao, {id});
    },

    async duplicar(id){
      let clone = await NotificacaoBusiness.clonar(id);
      if (clone) {
        FrontBusiness.redirect(`notificacao/${clone.id}/geral`);
      }

    },

    novo(){
      FrontBusiness.openModal(DetalhesNotificacao);
    },

    tipo(tipo){
      return FrontBusiness.getLabel(tipo, this.listas.tipos);
    },

    nomearProtocolo : FrontBusiness.nomearProtocolo,

    selecionar(nomeFiltro, item, add, x){

      this.$nextTick(() => {
        nomeFiltro = nomeFiltro || x;
        this.filtros[nomeFiltro] = item[0] ? item[0].id : null;
        if (nomeFiltro === 'matricula') {
          this[nomeFiltro] = item[0];
        } else {
          this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
        }

        if (add && item[0]) {
          this.executeLoad();
        }

      });
    },

    armazenarFiltro(){
      this.dominioProtocolo = this.$refs?.buscarProtocolos?.varDominio;
      this.tipoProtocolo = this.$refs?.buscarProtocolos?.varTipoProtocolo;
    },

    executeLoad(){
      this.armazenarFiltro();
      this.$refs.modal.load();
    },

    remover(idx, lista) {
      this.$nextTick(() => {
        this.filtros[lista].splice(idx, 1);
      });
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    },

    selectItem(item) {
      let index = this.marcados.findIndex(e => e.id === item.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(item)
      }
    },

    async exportar(){
      if(this.mode == 'novo'){
        let filtros = [...this.filtros?.filtros].filter(e => e.id);
        await RelatorioBusiness.downloadExcel(NotificacaoBusiness.paginationExcelV2(), 'relatorio.xls', 'POST', {filtros,categoria:'NOTIFICACAO', exportarXls: true});
      } else {
        let filtros = Utils.clone(this.filtros);
        await RelatorioBusiness.downloadExcel(NotificacaoBusiness.paginationExcel(), 'relatorio.xls', 'POST', {filtros, exportarXls: true});
      }
    },

  }
}
</script>

<style>
#consultar-notificacao th.column__botao{
  width: 50px;
}
</style>

<template>
  <div id="aba-anexo-indicador">
    <anexos-wrapper nome="Anexos" entidade="INDICADOR" :id="ficha.id" :permite-assinar-documentos="true"/>
  </div>
</template>

<script>
  import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";

  export default {

    name: "Anexos",
    components: {AnexosWrapper},
    props: {
      ficha: Object,
      livro: String
    },

    data() {
      return {
      }
    },

    methods: {
    }
  }
</script>

<template>
  <async-alert title="Verificando protocolos ativos"
               :service="load" :msg="msg" :tipo="tipo" @exist="exists" />
</template>

<script>

import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import AlertBanner from "@/components/alert/AlertBanner.vue";
import AlertMessage from "@/components/alert/AlertConstMessage.js";
import AlertIcon from "@/components/alert/AlertIcon.vue";
import AsyncAlert from "@/components/alert/AsyncAlert.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness";

export default {
  name: "AlertProtocoloAtivos",
  components: {
    AsyncAlert
  },

  props: {
    pessoa: Object,
    imovel: Object,
    tipo: {type: String, default: 'alert-icon'},
    externo: {type: Boolean, default: false},
    protocolo: String
  },

  data() {
    return {
      exist: false
    }
  },

  computed: {
    id() {
      return this.imovel?.id || this.pessoa?.id;
    },

    service() {
        if(!this.externo){
            return this.pessoa?.id ? IndicadorPessoalBusiness : IndicadorBusiness.getBusiness(this.imovel?.livro);
        }else{
            return IndicadorRealExternoBusiness;
        }
    },

    msg() {
      if (this.pessoa?.id) {
        return 'Existem protocolos ativos para essa pessoa'
      } else if (this.imovel?.livro === "REGISTRO_AUXILIAR") {
        return 'Existem protocolos ativos para esse registro auxiliar'
      } else if (this.imovel?.livro === "TRANSCRICAO") {
        return 'Existem protocolos ativos para essa transcrição '
      } else if(this.externo){
        return 'Existem protocolos ativos para esse indicador real externo'
      } else {
        return 'Existem protocolos ativos para essa matrícula'
      }
    },

  },

  methods: {
    exists() {
      this.exist = true;
      this.$emit('exist');
    },

    async load(){
      return new Promise((res, rej) => {
        this.service.buscarProtocolosAtivos(this.id, this.protocolo).then(e => {
          res(e.length > 0)
        }).catch(rej);
      });
    }

  }

}
</script>

<template>
  <span v-if="!visible" style="color: transparent">.</span>
  <small-loading :loading="true" v-else-if="visible && loading" :title="title" style="display: inline;" />
  <alert-banner :message="msg" v-else-if="mostrar && tipo == 'alert-banner'" :type="banner" :classe="bannerClass" />
  <alert-icon :message="msg" v-else-if="mostrar" :icon="icon" />
</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import AlertBanner from "@/components/alert/AlertBanner.vue";
import AlertIcon from "@/components/alert/AlertIcon.vue";
import SmallLoading from "@/components/SmallLoading.vue";

export default {
    name: "AsyncAlert",
    components: {
      AlertBanner, SmallLoading, AlertIcon
    },

    props: {
      id: String,
      service: Function,
      msg: String,
      title: String,
      tentativas: {type:Number, default:5},
      tipo: {type:String, default:'alert-icon'},
      banner: {type:String, default:'alert-danger'},
      bannerClass: {type:String, default:''},
      icon: String
    },

    async mounted() {

      if(this.tipo == 'alert-banner'){
        this.load();
      }else{
        this.observer = FrontBusiness.delayedView(this.$el, () => {
          this.load();
          return true;
        });

      }

      // this.observer = new IntersectionObserver((entries ) => {
      //   const el = entries?.[0];
      //   if (el?.isIntersecting) {
      //     if(el?.isVisible || typeof el?.isVisible === undefined){
      //       this.load();
      //       this.observer?.unobserve(this.$el);
      //       this.observer?.disconnect();
      //     }
      //   }
      // },{
      //   threshold: 0.5,
      //   trackVisibility: true,
      //   delay: 500
      // });
      //
      // this.observer.observe(this.$el);

    },

    destroyed() {
      this.observer?.disconnect();
    },

    data() {
      return {
        visible:false,
        observer: null,
        loading: true,
        mostrar : false,
        count: 0
      }
    },

    methods:{

      async load(){
        this.$set(this, 'visible', true);
        if(++this.count <= this.tentativas && this.loading && this.service){
          await this.service(this.id).then(e => {
            this.mostrar = e;
            this.$set(this, 'loading', false);
            if(this.mostrar){
              this.$emit('exist', true);
            } else {
              this.$emit('exist', false);
            }
          }).catch(e => setTimeout(this.load, 900))
        }
      },

    }

  }
</script>

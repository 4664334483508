import Http from "@/commons/Http";

const path = 'api/protocolo';

export default {

  async listar(protocolo){
    return Http.get(`${path}/${protocolo}/repasses`);
  },

  async possuiNaoConciliado(protocolo){
    return Http.get(`${path}/${protocolo}/repasses/possui-nao-conciliado`);
  },

  async getById(protocolo, indicador){
    return Http.get(`${path}/${protocolo}/repasses/${indicador}`);
  },

  async save(dto, protocolo, id = ''){
    if(!dto){
      throw 'Informe os dados do repasse';
    }
    return Http.post(`${path}/${protocolo}/repasses/${id}`, dto);
  },

  async confirmar(dto, protocolo, id, conciliado){
    return Http.post(`${path}/${protocolo}/repasses/${id}/confirmar/${conciliado}`, dto);
  },

  async excluir(protocolo, id){
    return Http.delete(`${path}/${protocolo}/repasses/${id}`);
  },

}

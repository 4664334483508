<template>

  <div class="buscar-fichas-wrapper row gutters-xs">

    <template v-if="selecionarLivros">
      <v-input type="select" :title="title" :label="showLabels ? 'Tipo Indicador' : ''" :options="livros" class="col-auto" v-model="livroSelecionado" @change="changeTipoFicha()" :disabled="livros.length == 1"/>
    </template>

    <div class="item-action col-auto" :style="{'padding-top': showLabels ? '30px' : '5px'}" v-if="search">
      <a ref="ficha" href="" @click.prevent="pesquisar()" class="icon">
        <i class="fa fa-search"></i>
      </a>
    </div>

    <v-input type="text" v-if="livroSelecionado !== 'TRANSCRICAO'"
             :label="showLabels ? 'Registro' : ''"
             :title="mensagemValidacao"
             :error="mensagemValidacao"
             :valid="campoValidado"
             v-model.trim="individual.codigo"
             :small="true"

             @enter="adicionar(true)"
             @blur="adicionar()"

             :placeholder="placeholder"
             :loading="pesquisando"

             :disabled="desabilitado"

             class="col not-set-label-validation" />

      <template v-else>
          <v-input :style="showLabels ? 'padding-top: 26px;' : ''"
                  class="col"
                  :title="mensagemValidacao"
                  :error="mensagemValidacao"
                  :valid="campoValidado">

              <v-input type="text"
                       v-model.trim="individual.codigo"
                       :small="true"
                       @enter="adicionar(true)"
                       @blur="adicionar()"
                       placeholder="Código"
                       :disabled="desabilitado"
                       :loading="pesquisando"
                       :valid="campoValidado"
                       class="col m-0 pl-1 pr-1" />

              <v-input type="text"
                       v-model.trim="individual.livroNumero"
                       :small="true"
                       @enter="adicionar(true)"
                       @blur="adicionar()"
                       placeholder="Número"
                       :disabled="desabilitado"
                       :loading="pesquisando"
                       :valid="campoValidado"
                       class="col m-0 pl-1 pr-1" />

              <v-input type="text"
                       v-model.trim="individual.livroLetra"
                       :small="true"
                       @enter="adicionar(true)"
                       @blur="adicionar()"
                       placeholder="Letra"
                       :disabled="desabilitado"
                       :loading="pesquisando"
                       :valid="campoValidado"
                       class="col m-0 pl-1 pr-1" />

              <v-input type="text"
                       v-model.trim="individual.livroFolha"
                       :small="true"
                       @enter="adicionar(true)"
                       @blur="adicionar()"
                       placeholder="Folha"
                       :disabled="desabilitado"
                       :loading="pesquisando"
                       :valid="campoValidado"
                       class="col m-0 pl-1 pr-1" />

              <a href="" @click.prevent="adicionar(true)" v-if="allowAdd && livroSelecionado !=='BEM_MOVEL'" class="btn btn-outline-secondary btn-xs">
                  <i class="fa fa-plus"></i>
              </a>

          </v-input>
      </template>

  </div>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndicadorRealExternoBusiness from "@/business/indicadores/IndicadorRealExternoBusiness.js";
  import Utils from "@/commons/Utils";
  import ConsultarIndicadorRealExterno from "@/components/IndicadorRealExterno/ConsultarIndicadorRealExterno.vue";
  import utils from "@/commons/Utils";

  export default {
    name: "BuscarFichasExternas",

    props: {
      livro: String,
      multiple : {type : Boolean, default : true},
      search : {type : Boolean, default : true},
      full : {type : Boolean, default : false},
      multiplos : {type : Boolean, default : false},
      allowAdd : {type : Boolean, default : false},
      desabilitar : {type : Boolean, default : false},
      selecionarLivros : {type : Boolean, default : true},
      valorInicial: {type: String, default: ''},
      valorInicialIndividual: {type: Object, default: () => {}},
      noChangeSelect: {type: Boolean, default: false},
      listaLivros: {type: Array, default: () => []},
      limparCampo: {type: Boolean, default: false},
      showLabels: {type: Boolean, default: true},
      title: {type: String, default: ''},
    },

    computed:{
      isMultiple() {
        return this.livroSelecionado === 'TRANSCRICAO' ? false : this.multiple;
      },
      placeholder(){
        return this.isMultiple ? 'Ex.: 300-320, 123.123, 156123, 156.128-156.136' : 'Ex.: 123.123';
      },

      livros(){
        let lst = [
          {id: 'MATRICULA', nome: 'Matrícula'},
          {id: 'TRANSCRICAO', nome: 'Transcrição'},
          {id: 'TORRENS', nome: 'Torrens'},
        ];
        if(this.selecionarLivros && this.listaLivros.length) lst = lst.filter(l => (this.listaLivros.includes(l.id)));
        return lst;
      },

    },

    watch: {
      livro(newValue, oldValue) {
        console.debug(newValue, oldValue);
        if(newValue != oldValue) this.livroSelecionado = newValue;
      },

      desabilitar(newValue, oldValue) {
        console.debug(newValue, oldValue);
        if(newValue != oldValue) this.desabilitado = newValue;
      },
    },

    data() {
      return {
        str : this.valorInicial,
        pesquisando : false,
        mensagemValidacao : null,
        campoValidado : false,
        livroSelecionado: this.livro,
        desabilitado: this.desabilitar,
        adicionar: Utils.debounce(this.adicionarFnc, 500),
        individual : {
          codigo: this.valorInicial || this.valorInicialIndividual?.codigo,
          livroLetra: this.valorInicialIndividual?.livroLetra,
          livroNumero: this.valorInicialIndividual?.livroNumero,
          livroFolha: this.valorInicialIndividual?.livroFolha,
          livrosIndicadorExterno: this.livroSelecionado
        },
        validarCodigosDebounce: Utils.debounce(async function () {
          await this.validarCodigos();
          if (this.full) await this.adicionar();
        }, 500),
        idsPesquisa: [],

      }
    },

    methods: {

      changeTipoFicha(){
        this.individual = {};
        this.adicionar(true, true);
      },

      async setPesquisando(pesquisando, load) {
        await this.$set(this, 'pesquisando', pesquisando)
        await this.$emit('pesquisando', pesquisando, load);
      },

      selecionarFichas(fichas, add){
        if (this.idsPesquisa && this.idsPesquisa.length) {
          fichas = fichas.filter(f => this.idsPesquisa.includes(f.id))
          if (add) this.idsPesquisa = [];
        }
        this.$emit('selecionar', fichas, add, this.campoValidado, this.mensagemValidacao, this.livroSelecionado);
        if(this.limparCampo){
          this.limpar();
        }
      },

      async pesquisar(){
        let result = await FrontBusiness.openConsulta(ConsultarIndicadorRealExterno,{ tipo: this.livroSelecionado, multiplos: this.multiplos && this.livroSelecionado !== 'TRANSCRICAO' });
        if(result){
          if (!this.isMultiple) {
            this.individual = Array.isArray(result) ? result[0] : result;
            this.idsPesquisa = [this.individual.id];
            this.adicionar();
            return
          }
          if (Array.isArray(result)) {
            result.forEach(r => {
              this.individual.codigo = `${this.individual.codigo || ''}${(this.individual.codigo ? ', ' : '')}${FrontBusiness.formatarCodigo(r.codigo)}`;
            })
            this.idsPesquisa = result.map(r => r.id);
          } else {
            this.individual.codigo = `${this.individual.codigo || ''}${(this.individual.codigo ? ', ' : '')}${FrontBusiness.formatarCodigo(result.codigo)}`;
            this.idsPesquisa = [result.id];
          }
          this.adicionar();
        }
      },

      async adicionarFnc(add, alteracao = false){
        let encontradas = await this.validarCodigos(add) || [];
        if(!this.noChangeSelect || (this.noChangeSelect && add)) this.selecionarFichas(encontradas, alteracao && this.noChangeSelect ? false : add);
        if(alteracao && this.noChangeSelect) {
          this.limpar();
        }
      },

      limpar() {
        this.individual = {};
        this.campoValidado = false;
      },

      async validarCodigos(add) {

        let encontradas = null;

        if (!Utils.isEmptyNullOrUndefined(this.individual.codigo)) {

          await this.setPesquisando(true);
          await this.desvalidarCodigos();

          try{
            if (this.isMultiple) {
              let fichas = Utils.stringCodigosLetraToArray(this.individual.codigo);

              if (!fichas.length) {
                throw 'Código digitado errado';
              }

              encontradas = await this.validarFichas(fichas);
            } else {
              encontradas = await this.validarFicha();
            }

            if(encontradas && encontradas.length){

              if((!this.isMultiple) && encontradas.length > 1 && !this.idsPesquisa.length){
                let error = `Foram encontrados ${encontradas.length} registros com os dados informados. Utilize a lupa para definir o registro a ser selecionado.`;
                encontradas = [];
                throw error;
              } else {
                this.$set(this, 'campoValidado', true);
              }

            }

          }catch (e) {
            console.error(e);
            this.$set(this, 'mensagemValidacao', e);
          }

        }
        await this.setPesquisando(false, add);

        return encontradas;

      },

      desvalidarCodigos(){
        this.$set(this, 'mensagemValidacao', null);
        this.$set(this, 'campoValidado', false);
      },

      async validarFichas(codigos) {
        let fichas = await IndicadorRealExternoBusiness.getFichasPorCodigo({
          dto: {codigos},
          livro: this.livroSelecionado
        });
        const codigosEncontrados = Utils.uniq(fichas.map(f => f.codigo));
        let possuiErro = false;
        possuiErro = codigosEncontrados.length !== codigos.length;
        if(possuiErro){
          let matriculasEncontradas = codigosEncontrados;
          let matriculasNaoEncontradas = codigos.filter(f => !matriculasEncontradas.includes(f));
          throw 'Código(s) não encontrado(s) ' + matriculasNaoEncontradas.map(n => n).join(', ');
        }
        return fichas;
      },

      async validarFicha() {
        let dto = utils.clone(this.individual);
        dto.codigo = (dto.codigo+'').replace(/[. ]+/g, "");
        let fichas = await IndicadorRealExternoBusiness.getFichasPorCodigo({dto, livro: this.livroSelecionado});
        if (!fichas.length) {
          throw 'Código não encontrado';
        }
        return fichas;
      },

    }

  }
</script>

<style lang=scss>
.not-set-label-validation {
  &.is-invalid, &.is-valid {
    label {
      color: unset !important;
    }
  }
}
</style>

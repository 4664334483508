import Http from "@/commons/Http";
import VueBusiness from "@/business/VueBusiness";
import Utils from "@/commons/Utils.js";

const path = "api/layout-impressao";
export default {

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async listByTipo(tipo){
    return Http.get(`${path}/${tipo}/simples`).then(l => l.map(i => {
      i.configuracao = JSON.parse(i.configuracao || '{}');
      i.atribuicao = JSON.parse(i.atribuicao || '[]');
      return i;
    }));
  },

  async listByTipoIds(tipo, ids){
    return Http.post(`${path}/${tipo}/simples`, ids).then(l => l.map(i => {
      i.configuracao = JSON.parse(i.configuracao || '{}');
      return i;
    }));
  },

  getEntidade(livro) {
    switch (livro) {
      case 'REGISTRO_AUXILIAR':
        return 'registro-auxiliar';
      case 'REGISTRO_TD':
      case 'REGISTRO_PJ':
        return 'indicador';
      default:
        return 'indicador-real';
    }
  },

  imprimirFichaUrl(livro, layout, indicador, version = 1){
    return `${path}/${layout}/imprimir/v${version}/${this.getEntidade(livro)}/${indicador}`;
  },

  async imprimirFichaLote(livro, layout, config){

    const l = livro == 'REGISTRO_AUXILIAR' ? 'registro-auxiliar' : 'indicador-real';

    return Http.post(`${path}/${layout}/imprimir-lote//${l}`, config, {
      responseType: 'blob',
      timeout : 0,
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      return new Blob([response], {type: "application/pdf"});
    });

  },

  simularRecibo(layout, protocolo){
    return `${path}/${layout}/recibo/${protocolo}/simular`;
  },

  recibo(layout, protocolo, params = ''){
    return `${path}/${layout}/recibo/${protocolo}?${params}`;
  },

  reciboComplementar(layout, protocolo, lancamento){
    return `${path}/${layout}/recibo/${protocolo}/complementar/${lancamento}`;
  },

  reciboSolicitacao(layout, protocolo, lancamento){
    return `${path}/${layout}/recibo/${protocolo}/solicitacao-valor/${lancamento}`;
  },


  getTipos() {
    let tipos = [
      {id: 'MATRICULA', nome: 'Matrícula'},
      {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
      {id: 'TRANSCRICAO', nome: 'Transcrição'},

      {id: 'MATRICULA_V2', nome: 'Matrícula - Nova Impressão'},
      {id: 'REGISTRO_AUXILIAR_V2', nome: 'Registro Auxiliar - Nova Impressão'},

      {id: 'MATRICULA_V3', nome: 'Matrícula - Nova Impressão [Experimental]'},
      {id: 'REGISTRO_AUXILIAR_V3', nome: 'Registro Auxiliar - Nova Impressão [Experimental]'},

      {id: 'CERTIDAO', nome: 'Certidão'},
      {id: 'CERTIDAO_V3', nome: 'Certidão - Nova Impressão [Experimental]'},
      {id: 'INCRA', nome: 'Relatório Incra'}

    ];

    if (VueBusiness.getVueRoot().isRTD) {
      tipos.push({id: 'REGISTRO_TD', nome: 'Registro RTD'});
    }

    if (VueBusiness.getVueRoot().isRCPJ) {
      tipos.push({id: 'REGISTRO_PJ', nome: 'Registro RCPJ'});
    }

    tipos = [...tipos, ... [
      {id: 'EXTRATO', nome: 'Extratos e Recibos'},
      {id: 'ETIQUETA', nome: 'Etiqueta'},

      {id: 'NOTIFICACAO', nome: 'Notificação'},
      {id: 'OFICIO', nome: 'Ofício'},
      {id: 'LIVRO_ESTRANGEIRO', nome: "Livro Estrangeiro"},
      {id: 'TERMO_PERSONALIZADO', nome: 'Termo Personalizado'},
      {id: 'CNM', nome: 'CNM'},

    ]];
    return tipos;
  },

  getTiposElemento() {
    return [
      {id: 'TEXT', nome: 'Texto'},
      {id: 'SQUARE', nome: 'Quadro/Linha'},
      {id: 'DATE_ATO', nome: 'Data do Ato'},
      {id: 'BRASAO_ESTADUAL', nome: 'Brasão Estadual'},
      {id: 'ANEXO', nome: 'Anexo'},
    ];
  },

  getModosElemento() {
    return [
      {id: 'NOVA', nome: 'Novas Folhas'},
      {id: 'ENCERRAMENTO', nome: 'Folhas Encerradas'},
      {id: 'ANDAMENTO', nome: 'Folhas em Andamento'}
    ];
  },

}

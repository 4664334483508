<template>
  <modal titulo="Selecionar Tags" class="modal-select-tags" id="select-tags">

    <card titulo="Tags" key="select">
      <v-input v-if="novasTags" type="tags" :taggable="false" :options="disponiveis" v-model="novasTags" :required="true" :placeholder="placeholder" :label="label" data-testid="tag"/>
    </card>

    <card titulo="Preview" key="preview">
      <TagShow v-for="tag in novasTags" :tag="tag" :key="tag.id"/>
    </card>

    <template #footer>
      <button @click.prevent="close()" class="btn btn-outline-secondary mr-auto" type="button">Cancelar</button>
      <button @click.prevent="close(novasTags)" class="btn btn-outline-primary" data-testid="salvar-tag">Salvar</button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";

export default {
    name: "TagSelectModal",
    components: {
      TagShow: () => import("@/components/Tag/TagShow"),
      Card: () => import("@/components/Layout/components/Card"),
      Modal: () => import("@/components/Modal"),
    },

    modal: {
      width: 600,
      escapable: false
    },

    props:{
      label: {type: String, default: ''},
      placeholder: {type: String, default: 'Tags'},
      tagsDisponiveis: null,
      tags: null,
    },

    data(){
      return {
        novasTags: null,
        disponiveis: [],
      }
    },

    mounted() {
      this.novasTags = this.tags;
      this.disponiveis = this.tagsDisponiveis;
    },

    methods:{
      close(response) {
        FrontBusiness.closeModal(response);
      }
    }
  }
</script>

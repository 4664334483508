<template>
  <th class="vue-table-header-cell" :class="classes" @click="toggleSort" :style="columnStyle" :title="column.label" :width="column.width">

    <component v-if="column.headerComponent" :is="column.headerComponent" :column="column" />

    <template v-else>{{column.label}}</template>

    <i class="sort-icon" :class="sortClass" v-if="sortable"></i>

  </th>
</template>

<script>
export default {
  name: "VueHeaderCell",

  props: {
    loading: Boolean,
    column: Object,
    direction : String,
  },

  computed:{

    classes(){

      let classes = [];
      if(this.column.headerClass){
        classes.push(this.column.headerClass);
      }

      if(this.direction){
        classes.push(`sorting sort-${this.direction}`);
      }

      if(this.sortable){
        classes.push('sortable');
      }

      return classes.join(' ');
    },

    sortClass(){
      return !this.direction ? 'fal fa-sort' : `fal fa-${this.direction == 'asc' ? 'sort-amount-up' : 'sort-amount-down'}`
    },

    sortable(){
      return this.column.sortable;
    },

    columnStyle(){
      let columnStyle = {};
      columnStyle.cursor = this.loading ? 'wait' : (this.sortable ? 'pointer' : 'auto');
      if(this.column.minWidth){
        columnStyle.minWidth = this.column.minWidth;
      }
      if(this.column.width){
        columnStyle.width = this.column.width;
        columnStyle.minWidth = columnStyle.minWidth || this.column.width;
      }
      return columnStyle;
    }

  },

  methods:{
    toggleSort(){
      if (this.sortable && !this.loading) {
        this.$emit('sort', this.column);
      }
    },
  },

}
</script>

<style lang="scss">
  .vue-table-header-cell{
    .sort-icon{
      float: right;
      margin-top: 6px;
      font-size: 10px;
    }
  }
</style>

import getVar from "@/business/livros/copiladores/getVar.js";
import Utils from "@/commons/Utils.js";

const agruparLista = async function(node, callback, dicionario, debug){

  let lista = getVar(node.params.lista, dicionario, false);

  // agrupa pela chave escolhida

  const tmp = Utils.groupBy(lista, (e) => getVar(node.params.por, e, false));
  const unique = node.params.unico !== undefined;

  const novaLista = Object.keys(tmp).map(k => unique ? tmp[k][0] : ({
    grupo : k,
    quantidade : tmp[k]?.length,
    lista: tmp[k]
  }));

  if(debug){
    debug.novaLista = novaLista;
  }

  // cria objeto de transporte
  let obj = {};
  obj[node.params.nome || 'agrupamento'] = novaLista;

  return await callback(node.children, Utils.clone({...dicionario, ...obj}), debug);

};

export default agruparLista;

<template>

  <modal id="detalhes-registro-titulo" class="modal-detalhes-entidade" :titulo="titulo" :loading="loading" :check="registroValido">
    <template #title-extra>
      <div v-if="ficha && ficha.status !== 'ABERTA'" class="badge text-center" :class="classStatus">
        {{ficha.status}}
      </div>
    </template>
    <template #actions>

      <template v-if="!usuario.auditor && ficha.status != 'PENDENTE'">

        <li class="list-inline-item" :class="{active : aba == 'anexos'}" >
          <a @click.prevent="aba = 'anexos'" title="Documentação & Anexos" href>
            <i class="fal fa-paperclip"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'historico'}">
          <a @click.prevent="aba = 'historico'" title="Histórico" href>
            <i class="fas fa-history"></i>
          </a>
        </li>

      </template>

        <li class="list-inline-item">
          <router-link :to="{ name: 'digitalizacao', params: { id: id, entidade : 'INDICADOR', tipoEntidade : livro }}" target="_blank" title="Digitalização">
            <i class="fal fa-file-image"></i>
          </router-link>
        </li>

      <template v-if="!usuario.auditor && ficha.status != 'PENDENTE'">

        <li class="list-inline-item">
          <a @click.prevent.stop="imprimir(ficha)" title="Imprimir" href>
            <i class="fal fa-print"></i>
          </a>
        </li>

      </template>

      <li class="list-inline-item" v-if="!usuario.auditor">
        <a @click.prevent.stop="editarIndicador(ficha)" title="Editar Indicador"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <template v-if="ficha.id">
      <template v-if="!usuario.auditor">

        <template v-for="(alerta, index) in alertas" >
          <AlertBanner v-if="alerta.mostrar" :key="index" :message="alerta.msg"/>
        </template>

      </template>

      <template v-if="this.componente === 'aba-atos' && ficha.textoLegado " >
        <a @click.prevent="vizualizarTextoLegado(ficha)" title="Vizualizar Texto" href>
          <AlertBanner message="Registro de título possui um texto importado!"/>
        </a>
      </template>

    </template>

    <component v-if="!loading && livro" :is="componente" :ficha="ficha" :id="ficha.id" :livro="livro" @load="load"/>

  </modal>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroPJBusiness from "@/business/indicadores/RegistroPJBusiness.js";
  import AlertBanner from "@/components/alert/AlertBanner";
  import AlertMessage from "@/components/alert/AlertConstMessage";
  import ImprimirFicha from "@/components/Livros/ImprimirFicha.vue";
  import Modal from "@/components/Modal";
  import Anexos from "@/components/IndicadorGeneric/Detalhes/Anexos";
  import Atos from "@/components/IndicadorGeneric/Detalhes/Atos.vue";
  import Historico from "@/components/IndicadorGeneric/Detalhes/Historico.vue";
  import Protocolos from "@/components/IndicadorGeneric/Detalhes/Protocolos.vue";
  import Dados from "@/components/RegistroPJ/Detalhes/Dados";
  import FormRegistroPJ from "@/components/RegistroPJ/Form/FormRegistroPJ.vue";
  import TextoLegado from "@/views/Protocolo/Atos/TextoLegado";
  import EventBus from "@/commons/EventBus";

  export default {

    name: "DetalhesRegistroPJ",
    components: {
      Modal,
      AlertBanner,
      "aba-dados" : Dados,
      "aba-atos" : Atos,
      "aba-historico" : Historico,
      "aba-anexos" : Anexos,
      "aba-protocolos" : Protocolos,
      "aba-registros-posteriores" : () => import("@/components/IndicadorGeneric/Detalhes/RegistrosPosteriores.vue")
    },

    props: {
      id : String,
      abaInicial: String
    },

    modal: {
      width: 900
    },

    async mounted(){
        await this.load();
        EventBus.$on('ATOS-LOAD', this.load);
    },

    computed:{
      titulo(){
        return this.ficha?.livro ? FrontBusiness.nomearFicha(this.ficha) : '';
      },
      livro(){
        return this.ficha?.livro;
      },
      componente(){
        return `aba-${this.aba}`;
      },
      alertas() {
          return [
              {mostrar: this.protocolosAtivos, msg: AlertMessage.indicadorPossuiProtocolos()}
          ];
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      classStatus() {
        if (this.ficha.status == 'ENCERRADA') {
          return 'badge-primary-soft';
        } else if (this.ficha.status == 'CANCELADA') {
          return 'badge-danger-soft';
        }
        return 'badge-success-soft'
      }
    },

    data() {
      return {
        ficha : {},
        aba : 'dados',
        protocolosAtivos: false,
        registroValido: false,
        abas:[],
        loading: true
      }
    },

    methods:{
      async load(loading = true){
        this.loading = loading;
        this.ficha = await RegistroPJBusiness.getById(this.id);

        let protocolos = [];
        const auditor = this.usuario.auditor;

        await Promise.all([
          !auditor ? RegistroPJBusiness.buscarProtocolosAtivos(this.ficha.id).then(r => protocolos = r) : null,
          this.registroTituloValidado()
        ]);

        this.protocolosAtivos = protocolos.length > 0;

        let exibir = !auditor && !this.preview;
        let isPendente = this.ficha.status == 'PENDENTE';

        this.abas = [
          {id: 'dados', nome: 'Dados', exibir: true, title: 'Dados Principais'},
          {id: 'atos', nome: 'Atos Registrados', exibir: !this.preview && !isPendente, title: 'Atos Registrados'},
          {id: 'registros-posteriores', nome: 'Registros Posteriores', exibir: true, title: 'Registros Posteriores'},
          {id: 'protocolos', nome: 'Protocolos', exibir: exibir}
        ];

        if (this.abaInicial) {
          this.$set(this, 'aba', this.abaInicial);
        }

        this.$set(this, 'loading', false);
      },


      async editarIndicador(ficha) {
          let result = await FrontBusiness.openModal(FormRegistroPJ, {id: ficha.id, livro: ficha.livro});
          if(result){
              this.$set(this, 'ficha', null);
              await this.load();
          }
      },

      imprimir(ficha){
        FrontBusiness.openModal(ImprimirFicha, {id: ficha.id, tipo: 'REGISTRO_PJ', codigo: ficha.codigo});
      },

      async vizualizarTextoLegado(ficha){
        let retorno = await FrontBusiness.openModal(TextoLegado, {ficha: ficha, tipo: "REGISTRO_PJ"});
        if (retorno) {
          this.ficha = retorno;
        }
      },

      async registroTituloValidado(){
        this.registroValido = await RegistroPJBusiness.indicadorValidado(this.ficha.id);
      }

    }

  }
</script>

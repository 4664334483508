<template>
  <modal titulo="Edição data de selagem" :loading="loading">

    <card>
      <div class="row no-gutters w-100">
        <v-input :disabled="!form.isPermitted" type="datetime-local" label="Data Selagem" :required="true" v-model="form.selado" class="col" :error="validation.selado"/>
      </div>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="run" class="ml-auto btn-outline-primary">Salvar</v-button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloCustaAdicionalBusiness from "@/business/protocolo/ProtocoloCustaAdicionalBusiness";

export default {
  name: "ModalEdicaoDataSelagem",
  components: {Card, Modal},
  props: {
    dto: Object,
    protocolo: Object,
    tipo: String,
  },

  data() {
    return {
      validation : {},
      loading: true,
      form: {
        selado: null,
        isPermitted: false,
      },
    }
  },

 async mounted(){
   await ProtocoloBusiness.verifyIfUpdateIsPermitted(this.protocolo?.id).then(response => {
       this.form.isPermitted = response ? response : false
   }).catch(e => {
       FrontBusiness.showError('', 'Erro ao consultar permissão para editar Data Selagem.', e?.message);
   });

   this.form.selado = this.$moment(this.dto.selado).format('YYYY-MM-DDTHH:mm');
   this.$set(this, 'loading', false);
  },

  methods:{

    close() {
      FrontBusiness.closeModal();
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '#formulario-cadastro-protocolo');
    },

    async run() {
      let retorno;
      if(this.form.selagem !== null){
      let dto = {
        id: this.dto.id,
        selado: this.form.selado
      }

      let result = await FrontBusiness.openConfirmacao("Tem certeza que deseja alterar a data de selagem dessa custa adicional? ", "Deseja continuar?");
        if(result === 'confirm'){
          try {
            switch (this.tipo) {
              case 'certidao':
                retorno = await CertidaoBusiness.editarDataSelagem(dto, this.protocolo?.id);
                break;
              case 'ato':
                retorno = await AtoBusiness.editarDataSelagem(dto, this.protocolo?.id);
                break;
              case 'custa-adicional':
                retorno = await ProtocoloCustaAdicionalBusiness.editarDataSelagem(dto, this.protocolo?.id);
                break;
            }
              FrontBusiness.closeModal(this.form);
          } catch (e) {
              FrontBusiness.showError('', e.message)
          }
        }
      }
    },
  }
}
</script>

<style lang=scss>

</style>

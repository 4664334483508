<template>

  <card titulo="Cancelar Restrições Não Registradas" v-if="temRestricoes">

    <table class="table card-table table-striped table-vcenter">
      <thead>
      <tr>
        <th width="20px"></th>
        <th class="pl-0">Ato</th>
        <th>Pessoa</th>
        <th>Restrições</th>
      </tr>
      </thead>
      <tbody>

      <template v-for="(restricao, index) in restricoes">
        <tr :key="'restricao'+index">

          <td class="text-left">
            <a class="icon" href="" @click.prevent="selecionar(restricao)">
              <i class="far"
                 :class="{ 'fa-check-circle' : selecaoCheckbox(restricao), 'fa-circle' : !selecaoCheckbox(restricao) }">
              </i>
            </a>
          </td>

          <td class="pl-0" style="width: 50%;">
            {{descricaoAtoRestricao(restricao.descricaoRestricao)}}
          </td>

          <td>
            <a href class="mb-1">
              {{restricao.nome}}
              <div class="small text-muted" v-if="restricao.documento">
                {{restricao.documento}}
              </div>
            </a>
          </td>

          <td>
            {{descricaoRestricao(restricao.restricao)}}
          </td>

        </tr>
      </template>
      </tbody>
    </table>
  </card>
</template>

<style lang="scss">

</style>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Card from "@/components/Layout/components/Card.vue";
import business from "@/views/Administracao/modulos/tipo-servico/business";

export default {

    name: "CancelarRestricoesNaoRegistradas",
    components: {Card},
    props: ['ato', 'protocolo', 'ficha', 'edicao'],

    data() {
      return {
        restricoes: []
      }
    },

    async beforeMount() {
      this.ato.dto.restricoesCanceladasSemRegistrar = this.ato?.dto?.restricoesCanceladasSemRegistrar || [];
      await this.getRestricoes()
    },

    watch: {
      async protocolo(newValue) {
        if (newValue) {
          await this.getRestricoes();
        }
      },
      async vincularOrigem(newValue) {
        if (newValue) {
          await this.getRestricoes();
        }
      }
    },

    computed: {
      temRestricoes() {
        return this.restricoes.length;
      }
    },

    methods: {
      async getRestricoes() {
        if (!(this.protocolo?.id)) return;
        let restricoes = this.ato.livro != 'REGISTRO_AUXILIAR' ? await AtoBusiness.restricoesSemRegistrar(this.protocolo?.id, this.ato?.id, this.ato?.indicadorReal?.id)
          : await AtoBusiness.restricoesSemRegistrarRegistroAuxiliar(this.protocolo?.id, this.ato?.id, this.ato?.registroAuxiliar?.id);
        let tags = this.ato.tipoServico?.opcoes?.cancelarRestricao;
        this.$set(this, 'restricoes', restricoes.filter(r => tags.includes(r.restricao)));
      },

      selecionar(restricao) {
        if(this.edicao){
          let restricoes = this.ato.dto.restricoesCanceladasSemRegistrar || [];
          this.$set(this.ato.dto, 'restricoesCanceladasSemRegistrar', []);

          if (restricoes.filter(r => r.atoId === restricao.atoId && r.restricao === restricao.restricao
              && r.indicadorPessoalVersaoId === restricao.indicadorPessoalVersaoId).length === 0) {
            restricoes.push({atoId:restricao.atoId, restricao: restricao.restricao, indicadorPessoalVersaoId: restricao.indicadorPessoalVersaoId});
          } else {
            restricoes = restricoes.filter(r => r.atoId !== restricao.atoId && r.restricao !== restricao.restricao
              && r.indicadorPessoalVersaoId !== restricao.indicadorPessoalVersaoId);
          }

          this.$set(this.ato.dto, 'restricoesCanceladasSemRegistrar', restricoes);
          this.$forceUpdate();
        }
      },
      selecaoCheckbox(restricao) {
        let restricoes = this.ato.dto.restricoesCanceladasSemRegistrar;
        return restricoes.filter(r => r.atoId === restricao.atoId
          && r.restricao === restricao.restricao && r.indicadorPessoalVersaoId === restricao.indicadorPessoalVersaoId ).length > 0;
      },

      descricaoRestricao(restricao) {
        return FrontBusiness.getLabel(restricao, business.restricoes());
      },

      descricaoAtoRestricao(restricao) {
        return AtoBusiness.descricaoAtoRestricaoCompleta(JSON.parse(restricao), this.ficha);
      },

    },


  }
</script>

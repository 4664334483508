<template>

  <div class="row gutters-xs" v-if="item && item.id">
    <div class="col-auto" style="min-width: 150px" v-if="filterTypes">
      <v-input type="select" class="mb-0" data-testid="tipos-filtro" :options="filterTypes" @change="handle()"
               v-model="item.type" :default="filterTypeDefault" :disabled="item.disabled" />
    </div>

    <template v-if="filterType == 'date'">

      <div class="col" v-if="item.type == 'between' || item.type == 'after'">
        <v-input type="date" class="mb-0" v-model="item.startDate" :disabled="item.disabled" />
      </div>

      <div class="col-auto pt-1" v-if="item.type == 'between'">até</div>

      <div class="col" v-if="item.type == 'between' || item.type == 'before'">
        <v-input type="date" class="mb-0" v-model="item.endDate" :disabled="item.disabled" />
      </div>

      <div class="col" v-if="item.type == 'equal'">
        <v-input type="date" class="mb-0" v-model="item.value" :disabled="item.disabled" />
      </div>

    </template>

    <template v-if="filterType == 'numeric'">

      <div class="col" v-if="item.type == 'between' || item.type == 'after'">
        <v-input type="number" class="mb-0" v-model="item.start" :disabled="item.disabled" />
      </div>

      <div class="col-auto pt-1" v-if="item.type == 'between'">até</div>

      <div class="col" v-if="item.type == 'between' || item.type == 'before'">
        <v-input type="number" class="mb-0" v-model="item.end" :disabled="item.disabled" />
      </div>

      <div class="col" v-if="item.type == 'equal'">
        <v-input type="number" class="mb-0" v-model="item.value" :disabled="item.disabled" />
      </div>

    </template>

    <template v-if="filterType == 'text-compare'">

      <div class="col" v-if="item.type == 'between' || item.type == 'after'">
        <v-input type="text" class="mb-0" v-model="item.startText" :disabled="item.disabled" />
      </div>

      <div class="col-auto pt-1" v-if="item.type == 'between'">até</div>

      <div class="col" v-if="item.type == 'between' || item.type == 'before'">
        <v-input type="text" class="mb-0" data-testid="end" v-model="item.endText" :disabled="item.disabled" />
      </div>

      <div class="col" v-if="item.type == 'equal'">
        <v-input type="text" class="mb-0" data-testid="igual" v-model="item.value" :disabled="item.disabled" />
      </div>

    </template>

    <template v-if="filterType == 'text'">
      <div class="col">
        <v-input type="text" class="mb-0" v-model="item.value" :disabled="item.disabled" />
        <div class="text-muted small">
          <b>DICA:</b> Utilize o símbolo <b>%</b> para realizar buscas parciais.
          <a href @click.prevent="dicasBusca('texto')">Clique aqui para alguns exemplos.</a>
        </div>
      </div>
    </template>

    <template v-if="filterType == 'tsquery'">
      <div class="col">
        <v-input type="text" class="mb-0" v-model="item.value" :disabled="item.disabled" />
<!--        <div class="text-muted small">-->
<!--          <b>DICA:</b> Utilize o símbolo <b>%</b> para realizar buscas parciais.-->
<!--          <a href @click.prevent="dicasBusca('texto')">Clique aqui para alguns exemplos.</a>-->
<!--        </div>-->
      </div>
    </template>

    <template v-if="filterType == 'simpleString'">
      <div class="col">
        <v-input type="text" class="mb-0" v-model="item.value" :disabled="item.disabled" />
      </div>
    </template>

    <template v-if="filterType == 'list'">
      <div class="col">
        <v-input type="select" class="mb-0" v-model="item.value" :options="filterOptions" :disabled="item.disabled" />
      </div>
    </template>

    <template v-if="filterType == 'number'">
      <div class="col">
        <v-input type="number" class="mb-0" v-model="item.value" :disabled="item.disabled" />
      </div>
    </template>

    <template v-if="filterType == 'currency'">
      <div class="col">
        <v-input type="currency" class="mb-0" v-model="item.value" :disabled="item.disabled" />
      </div>
    </template>

    <v-input class="col mb-0" v-model="item.value" type="cep" v-if="filterType == 'cep'" :disabled="item.disabled"/>
    <v-input class="col mb-0" v-model="item.value" type="cpf" v-if="filterType == 'cpf'" :disabled="item.disabled"/>
    <v-input class="col mb-0" v-model="item.value" type="number" v-if="filterType == 'area'" :disabled="item.disabled"/>
    <v-input class="col mb-0" v-model="item.value" type="cnpj" v-if="filterType == 'cnpj'" :disabled="item.disabled"/>
    <v-input class="col mb-0" v-model="item.value" type="cpfCnpj" v-if="filterType == 'cpfCnpj'" :disabled="item.disabled"/>
    <v-input class="col mb-0" v-model="item.value" type="telefone" v-if="filterType == 'telefone'" :disabled="item.disabled"/>
    <slot name="custom" :item="item" />

  </div>

  <span v-else>{{item}}</span>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import AjudaConsultas from "@/components/Consultas/AjudaConsultas.vue";

export default {
  name: "FiltrarConsultaItem",
  props:{
    item: Object,
    options: Array
  },

  data(){
    return {
    }
  },

  computed:{

    selected(){
      return this.options.find(e => e.id == this.item.id);
    },

    filterType(){
      return this.selected?.type;
    },

    filterOptions(){
      return this.selected?.options || [];
    },

    filterTypes(){
      if(['date', 'text', 'tsquery', 'cpfCnpj' ,'cpf', 'cnpj', 'numeric', 'area'].includes(this.filterType)){
        return FrontBusiness.tiposBuscaFiltro(this.filterType);
      }

      if(this.filterType == 'text-compare'){
        return [{id: 'between', nome: 'Entre'}, {id: 'after', nome: 'Maior ou Igual'}, {id: 'before', nome: 'Menor ou Igual'}, {id: 'equal', nome: 'Igual'}];
      }

      return null;
    },

    filterTypeDefault(){
      return this.filterTypes?.[0]?.id;
    }

  },

  methods:{

    dicasBusca(modulo){
      FrontBusiness.openModal(AjudaConsultas, {modulo})
    },

    handle(){
      if(this.filterType == 'date' || this.filterType == 'text-compare' || this.filterType == 'numeric'){

        if(this.item.type == 'after'){
          this.$set(this.item, 'endText', null);
          this.$set(this.item, 'endDate', null);
          this.$set(this.item, 'end', null);
          this.$set(this.item, 'value', null);
        }

        if(this.item.type == 'before'){
          this.$set(this.item, 'startText', null);
          this.$set(this.item, 'startDate', null);
          this.$set(this.item, 'start', null);
          this.$set(this.item, 'value', null);
        }

        if(this.item.type == 'equal'){
          this.$set(this.item, 'startDate', null);
          this.$set(this.item, 'startText', null);
          this.$set(this.item, 'start', null);
          this.$set(this.item, 'endDate', null);
          this.$set(this.item, 'endText', null);
          this.$set(this.item, 'end', null);
        }

      }
    }

  }

}
</script>

<template>

  <div class="form-group col">

    <div class="mb-2 w-100" v-if="!visualizar">
      <label v-if="!visualizacaoLote" class="form-label">Registro anterior</label>
      <i class="fa fa-plus-square pointer float-right mr-2" @click="registroAnterior()"></i>
    </div>

    <div id="registro-anterior-wrapper">
      <table class="table table-striped card-table table-vcenter">

        <tbody>
        <tr v-for="(registro, i) in registros" :key="'registroAnterior'+i">
          <td class="pl-2">{{getLabelRegistroAnterior(registro)}}</td>

        <td v-if="!visualizar" width="15px" style="padding-left: 8px;"
            class="text-right pr-2">
          <v-button v-if="registro.origemId && isImportarRegistroAnterior" :run="importarRegistroAnterior" :params="[registro.origemId]"
                  label="Deseja importar os dados?" :popover="true" :button="false" position="dropleft" class="icon ml-2">
            <i title="Importar dados" class="fal fa-file-import"></i>
          </v-button>
          </td>

          <td width="15px" style="padding-left: 0px;" class="text-right pr-2" v-if="!visualizar">
            <a class="icon" title="Editar" @click.prevent="registroAnterior(registro, i)">
              <i class="fa fa-edit"></i>
            </a>
          </td>
          <td width="15px;" style="padding-left: 0px;" class="text-right pr-2" v-if="!visualizar">
            <a class="icon" title="Excluir" @click.prevent="excluirRegistroAnterior(i)">
              <i class="far fa-trash-alt"></i>
            </a>
          </td>
        </tr>
        <tr v-if="registros.length === 0">
          <td class="p-2 text-truncate small">
            <div class="alert alert-warning alert-sm m-2">
              Nenhum registro anterior informado
            </div>
          </td>
        </tr>
        </tbody>

      </table>

    </div>

  </div>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from '@/business/indicadores/IndicadorRealBusiness';
  import RegistroAnterior from './RegistroAnterior';


  export default {

        name: "RegistroAnteriorLista",
        components: {},
        props: {
          registros: {type: Array, default: () => []},
          isImportarRegistroAnterior : {type: Boolean, default: true},
          visualizar: {type : Boolean, default : false},
          visualizacaoLote: {type: Boolean, default: false},
        },

        data(){
            return {
                acoes: [
                    {action: 'importarRegistroAnterior', name: 'Importar', type: 'aceita'},
                ],
                loading: false,
            }
        },

        methods : {

            async registroAnterior(registro, index) {
              this.loading = true;
              let registroAnterior = await FrontBusiness.openModal(RegistroAnterior, {registroEditar: registro});
              if(registroAnterior) {
                  if (index === null || index === undefined) {
                    this.$set(this, 'registros', [...this.registros, ...registroAnterior ]);
                  } else {
                    this.$set(this.registros, index, registroAnterior);
                  }
                  this.update();
              }
              this.loading = false;

            },

            excluirRegistroAnterior(index){
                let novosRegistros = [...this.registros];
                novosRegistros.splice(index, 1);
                this.$set(this, 'registros', novosRegistros);
                this.update();
            },

            getLabelRegistroAnterior(registro){
                return IndicadorRealBusiness.getLabelRegistroAnterior(registro);
            },

            update(){
                this.$emit('update', this.registros);
            },

            async importarRegistroAnterior(origemId, callback){
                try{
                    this.loading = true;
                    await this.$emit('importarDados', origemId);
                }catch (e) {
                    e;
                }finally {
                    this.loading = false;
                    if(callback) {
                        callback();
                    }
                }
            }
        }

    }
</script>

<style scoped>

  .btn__import:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
    box-shadow: none;
  }

  .btn__import {
    color: #7b7b7b;
    padding: 0px 0px 0px 0px;
  }

  .fa-file-import {
    font-size: 12px;
  }


</style>


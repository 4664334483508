<template>
  <small-loading :loading="true" v-if="loading" style="display: inline;" />
  <div v-else>
    <slot name="input" ></slot>
  </div>
</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import SmallLoading from "@/components/SmallLoading.vue";

export default {
    name: "AsyncInput",
    components: {
      SmallLoading
    },

    props: {
      id: String,
    },

    async mounted() {
        this.observer = FrontBusiness.delayedView(this.$el, () => {
          this.load();
          return true;
        });
    },

    destroyed() {
      this.observer?.disconnect();
    },

    data() {
      return {
        observer: null,
        loading: true,
      }
    },

    methods:{
      async load(){
        this.$set(this, 'loading', false);
      },
    }
  }
</script>

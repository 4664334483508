import AuthBusiness from "@/business/AuthBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import TextParser from "@/business/livros/TextParser.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";
import ValidationUtils from "@/commons/ValidationUtils.js";

const path = 'api/oficio';

export default {

  baixarOficio(oficio){
    return `${path}/${oficio}/pdf`;
  },

  nomearEntidade(oficio, code = true){
    return `Oficio ${code ? FrontBusiness.formatarCodigo(oficio?.numero)+'/'+ oficio?.ano : ''}`;
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'o.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getById(indicador){
    return Http.get(`${path}/${indicador}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async saveForm(dto, id = ''){
    return Http.post(`${path}/form/${id}`, dto);
  },

  salvarAdiamento(oficio, dto) {
    return Http.post(`${path}/${oficio}/salvar-adiamento`, dto);
  },

  async sugerir(query, filtros) {
    return Http.post(`${path}/sugerir/${query}`, filtros);
  },

  async clonar(id) {
    return Http.post(`${path}/${id}/clonar`);
  },

  async vinculados(id) {
    return Http.get(`${path}/${id}/vinculados`);
  },

  async regerarChecklist(id){
    return Http.post(`${path}/${id}/checklist`);
  },

  async getAuditorias(id){
    return Http.get(`${path}/${id}/auditorias`);
  },

  async vinculos(id){
    return Http.get(`${path}/${id}/vinculos`);
  },

  async buscarPorCodigos(codigos, ano, tipo) {
    if(!ValidationUtils.validateLongArray({values: codigos, min: 0, max: 99999999}) || !ano || !tipo){
      throw 'Intervalo Inválido';
    }
    return await Http.post(`${path}/codigos/${tipo}/${ano}`, codigos);
  },

  async listarPorEnvolvido(indicador = ''){
    return Http.get(`${path}/indicador-pessoal/${indicador}`);
  },

  getTipos() {
    return [
      {id: 'ENVIADO', nome: 'Enviado'},
      {id: 'RECEBIDO', nome: 'Recebido'}
    ]
  },

  async copilarTemplate(template, oficio = {}, debug = {}){

    const dicionario = {
      oficio : {
        ...oficio
      },
      checklist : {}
    };

    await AuthBusiness.getLoggedUser().then(logged => dicionario.usuarioLogado = { ...logged, senha : undefined });

    // INDICES
    if (oficio.indices != null) {
      Object.keys(oficio.indices).forEach(i => {
        dicionario.checklist[i] = oficio.indices[i];
        dicionario.checklist[i.toUpperCase()] = oficio.indices[i];
        dicionario.checklist[i.toLowerCase()] = oficio.indices[i];
      });
    }

    for(let eI in oficio.envolvidos){
      await TextParser.copilarDicionarioEnvolvido(oficio.envolvidos[eI], dicionario.checklist.envolvidos?.[id]);
    }

    /**
     * CONFIGURACOES DO certidao
     */
    dicionario.oficio.matriculas = oficio?.matriculas;
    dicionario.oficio.protocolos = oficio?.protocolos;

    const callback = async (nodes = [], dicionario = {}, debug = undefined) => {

      let output = '';

      for(let pos in nodes){

        let node = nodes[pos];
        let debugNode = { tipo : node.name, nodes : [], params : node.params, dicionario : dicionario };
        output += await TextParser.commonsParse(node, dicionario, callback, debugNode);

        if(debug){
          debugNode.conteudo = TextParser.htmlText(node.body);
          debugNode.output = Utils.clone(output);

          if(debugNode.tipo || debugNode.conteudo){
            debug?.nodes.push(Utils.clone(debugNode));
          }
        }

      }

      return output;
    };

    await TextParser.init();
    return TextParser.parse(template, callback, dicionario);

  },

  async gerarMinuta(oficio, modelo){
    return Http.get(`${path}/${oficio}/${modelo}/minuta`);
  },

}

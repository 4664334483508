import Vue from 'vue';

Vue.filter('inteiro', function (value, empty = '-') {

  return !value ? empty : Number(value).toLocaleString("pt-BR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: 'decimal',
                              currency: 'BRL'
                          });
});

import PixOnrBusiness from "@/business/financeiro/PixOnrBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import CertidaoImpressaoBusiness from "@/business/livros/CertidaoImpressaoBusiness.js";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness.js";
import TextParser from "@/business/livros/TextParser.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness.js";
import ProtocoloAtividadeBusiness from "@/business/protocolo/ProtocoloAtividadeBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
import ProtocoloFinanceiroBusiness from "@/business/protocolo/ProtocoloFinanceiroBusiness.js";
import ProtocoloRepasseBusiness from "@/business/protocolo/ProtocoloRepasseBusiness.js";
import RegrasEstaduaisBusiness from "@/business/RegrasEstaduaisBusiness.js";
import Utils from "@/commons/Utils.js";
import ProtocoloCustaAdicionalBusiness from "@/business/protocolo/ProtocoloCustaAdicionalBusiness";

export default {

  async copilarTextoExigencia(protocolo, exigencia = ' ', indices = {}, envolvidos = [], debug ) {

    let dicionario = {
      protocolo : {...protocolo},
      checklist: {...indices},
      envolvido: {...(indices.envolvido || {})},
    };

    if(indices.documento){
      dicionario.documento = indices.documento;
    }

    dicionario.imovel = await LivroMatriculaBusiness.gerarDicionarioImovel(indices.matricula || {}, true);

    if(dicionario.envolvido.indicadorPessoalVersao){
      dicionario.envolvido = {...dicionario.envolvido, ...dicionario.envolvido.indicadorPessoalVersao, indicadorPessoalVersao :undefined}
    }

    await TextParser.tratarEnvolvidos(envolvidos, dicionario);
    let insideEnvolvidoTag = false, ignore = [], proprietarios = {};

    let cache = {};

    const callback = async (nodes = [], dicionario = {}, debug ) => {

      let envolvidos = dicionario.envolvidos?.lista || [], ignore = [];
      if(dicionario.vinculo?.envolvidos?.lista){
        envolvidos = dicionario.vinculo.envolvidos.lista;
      }

      let output = '';

      for (const node of nodes) {

        let debugNode = debug ? {tipo: node.name, nodes: [], params: node.params} : null;

        switch (node.name) {

          case 'qrcode-pix-onr':{
            const url = PixOnrBusiness.createUrl('protocolo', protocolo.id);
            const height = node.params.altura || 100, width = node.params.largura || 100;
            output += `<img class="qrcode" src="${window.server.API}/api/public/qrcode/${width}/${height}/?text=${url}" width="${width}" height="${height}" />`;
            break;
          }

          case 'protocolos-ativos' : {

            let protocolosAtivos = await ProtocoloBusiness.fichasUsadas(protocolo.id).catch(() => []);

            protocolosAtivos = protocolosAtivos.map(p => {
              p.codigo = p.codigo;
              p.principal = p.tipoServico;
              p.tipo = p.tipoProtocolo;
              p.dataCadastro = p.cadastro;
              return p;
            }).filter(p => p.id != protocolo.id );

            output += await callback(node.children, {...dicionario, protocolosAtivos}, debugNode);
            break;
          }

          case 'saldo-provisionado' : {
            const saldoProvisionado = await RegrasEstaduaisBusiness.getSaldoProvisionadoSP(protocolo.id).catch(() => ({}));
            if(dicionario.protocolo){
              dicionario.protocolo.saldoProvisionado = saldoProvisionado;
            }
            output += await callback(node.children, dicionario, debugNode);
            break;
          }

          case 'genero':
            if(dicionario.envolvido?.indicadorPessoalId){
              if ((dicionario.envolvido.sexo || dicionario.envolvido?.indicadorPessoalVersao?.sexo || '').toUpperCase() == 'FEMININO') {
                output += '  ' + node.params.feminino  + ' ';
              } else {
                output += '  ' + node.params.masculino  + ' ';
              }
            }
            break;

          case 'documentos':{
            const val = dicionario.checklist[node.params.indice];
            let documentos = Utils.clone(Array.isArray(val) ? val : [val]).filter(e => e);
            if(!dicionario.documentos || !Object.keys(dicionario.documentos)?.length){
              dicionario.documentos = {};
              const docs = await ProtocoloDocumentoBusiness.listar(protocolo.id).catch(() => []);
              docs.forEach(d => {
                dicionario.documentos[d.id] = {...d.indices, tipoDocumento : d.tipo, tituloDocumento: d.nome}
              });
            }
            documentos = documentos.map(i => dicionario.documentos[i]).filter(e => e);
            output += await callback(node.children, {...dicionario, documentos}, debug);
            break;
          }

          case 'envolvidos':{

            insideEnvolvidoTag = true;
            ignore = [];
            let papel = node.params.papel, outputEnvolvidos = [];

            for(let eI in envolvidos){
              let envolvido = envolvidos[eI];

              if (!papel || envolvido.papel == papel) {

                if (ignore.includes(envolvido.indicadorPessoalVersao.indicadorPessoalId)) {
                  continue;
                }

                if (envolvido.indicadorPessoalVersao.conjuge) {
                  let conjuge = envolvidos.find(e => e.indicadorPessoalVersao.indicadorPessoalId == envolvido.indicadorPessoalVersao.conjuge.indicadorPessoalId);

                  if (conjuge) {
                    envolvido.conjuge = Utils.clone(conjuge);
                    envolvido.indicadorPessoalVersao.conjuge = Utils.clone(conjuge.indicadorPessoalVersao);
                    envolvido.conjugeId = conjuge.indicadorPessoalVersao.indicadorPessoalId;
                  }

                }

                dicionario.envolvido = await LivroMatriculaBusiness.gerarDicionarioEnvolvido(Utils.clone(envolvido), proprietarios, dicionario.checklist.envolvidos);

                outputEnvolvidos.push(await callback(node.children, dicionario, debugNode));
                dicionario.envolvido = null;

              }
            }

            output += '  ' + FrontBusiness.formatArray(outputEnvolvidos, node.params.separador, node.params.ultimo)  + ' ';

            insideEnvolvidoTag = false;
            break;

          }

          case 'plural':
            output += LivroMatriculaBusiness.plural(node, dicionario, envolvidos, {}, debugNode);
            break;

          case 'protocolos-ativos' : {

            let saida = [];
            for(let protocolo of dicionario.protocolosAtivos){
              dicionario.protocoloAtivo = { ...protocolo};
              dicionario.protocoloAtivo.codigo = protocolo.codigo;
              dicionario.protocoloAtivo.principal = protocolo.tipoServico;
              dicionario.protocoloAtivo.ficha = FrontBusiness.nomearFicha(protocolo);
              dicionario.protocoloAtivo.dataCadastro = protocolo.cadastro;
              saida.push(await callback(node.children, dicionario, debugNode));
              dicionario.protocoloAtivo = undefined;
            }
            output += FrontBusiness.formatArray(saida, node.params.separador, node.params.ultimo);
            break;
          }

          case 'emolumentos': {

            if(!dicionario.protocolo){
              dicionario.protocolo = {}
            }

            let p1 = ProtocoloFinanceiroBusiness.getSaldo(protocolo.id).then(l => dicionario.protocolo.recebido = l);
            let p3 = ProtocoloFinanceiroBusiness.getSaldoPendente(protocolo.id).then(l => dicionario.protocolo.recebimentoPendente = l);
            let p2 = ProtocoloFinanceiroBusiness.getCusto(protocolo.id).then(l => dicionario.protocolo.total = l);

            let r = await Promise.all([p1, p2, p3]);

            dicionario.protocolo.totalRecebido = dicionario.protocolo.recebido + dicionario.protocolo.recebimentoPendente;
            dicionario.protocolo.saldo = dicionario.protocolo.totalRecebido - dicionario.protocolo.total;
            let saldo = dicionario.protocolo.saldo;
            dicionario.protocolo.pagar = 0;
            dicionario.protocolo.devolver = 0;

            if(saldo > 0){
              dicionario.protocolo.devolver = saldo;
            }

            if(saldo < 0){
              dicionario.protocolo.pagar = saldo * -1;
            }

            // console.debug('saldo', saldo, r, dicionario.protocolo);

            output += await callback(node.children, dicionario, debugNode);

            break;
          }

          case 'custas' : {

            dicionario.buscas = protocolo?.custas;

            let p1 = dicionario.custasAdicionais ? null : ProtocoloCustaAdicionalBusiness.listar(protocolo.id).then(l => dicionario.custasAdicionais = l);
            let p2 = dicionario.prenotacoes ? null : ProtocoloBusiness.listarPrenotacao(protocolo.id).then(l => dicionario.prenotacoes = l);
            let p3 = dicionario.atos ? null : AtoBusiness.listarAtosProtocolo(protocolo.id).then(l => dicionario.atos = l);
            let p4 = dicionario.certidoes ? null : CertidaoBusiness.listar(protocolo.id).then(l => dicionario.certidoes = l);
            let p5 = dicionario.repasses ? null : ProtocoloRepasseBusiness.listar(protocolo.id).then(l => dicionario.repasses = l.map(r => ({
              valor : r.valor,
              descricao : r.descricao,
              categoria : r.repasse.nome
            })));

            await Promise.all([p1, p2, p3, p4, p5]);

            let obj = {emolumentos : 0, fundos : {}, total : 0, iss : 0, taxaJudiciaria : 0, fundosEstaduais : 0};

            dicionario.atos?.forEach(ato => {
              obj = FrontBusiness.somarTaxas(ato.custas, obj);
            });

            dicionario.custasAdicionais?.forEach(p => {
              obj = FrontBusiness.somarTaxas(p.custas, obj, p.quantidade);
            });

            dicionario.prenotacoes?.forEach(p => {
              obj = FrontBusiness.somarTaxas(p.custas, obj);
            });

            dicionario.certidoes?.forEach(p => {
              obj = FrontBusiness.somarTaxas(p.custas, obj);
            });

            if(dicionario?.buscas){
              obj = FrontBusiness.somarTaxas(dicionario.buscas, obj);
            }

            dicionario.protocolo.emolumentos = obj.emolumentos;
            dicionario.protocolo.iss = obj.iss;
            dicionario.protocolo.taxaJudiciaria = obj.taxaJudiciaria;
            dicionario.protocolo.fundosEstaduais = obj.fundosEstaduais;
            dicionario.protocolo.fundos = obj.fundos;

            const prenotacoes = (dicionario.prenotacoes || []).flatMap( a => Object.values(a.custas) );
            const buscas = protocolo?.custas ? Object.values(protocolo?.custas) : [];

            const criarVariavel = node.params.listar != undefined;
            const custasMap = [];

            let i = 0;

            const tratarCustas = (c, tipo) => {

              let quantidade = (c?.quantidade || 1);

              c = c || {
                nome: '',
                emolumentos:0,
                iss:0,
                taxaJudiciaria:0,
                fundosEstaduais:0
              };

              const dto = {
                descricao : c.nome || c.descricao,
                // descricao : c.descricao,
                isentado : c?.isentado,
                idAtoIsentado : c?.idAtoIsentado,
                baseCalculo : c?.baseCalculo,
                quantidade : quantidade + '',
                total : FrontBusiness.twoDecimals((c?.emolumentos + c?.iss + c?.taxaJudiciaria + c?.fundosEstaduais) * quantidade),
                emolumentos : FrontBusiness.twoDecimals(c?.emolumentos * quantidade),
                iss : FrontBusiness.twoDecimals(c?.iss * quantidade),
                taxaJudiciaria : FrontBusiness.twoDecimals(c?.taxaJudiciaria * quantidade),
                fundosEstaduais : FrontBusiness.twoDecimals(c?.fundosEstaduais * quantidade),
                fundos : {},
                tipo
              }

              Object.keys(c?.fundos || {}).forEach((taxa) => {
                dto.fundos[taxa] = FrontBusiness.twoDecimals( c?.fundos[taxa] * quantidade);
              });

              return dto;

            };

            // console.debug('PRENOTACAO', prenotacoes.length)
            for(let c of prenotacoes){
              let tmp = tratarCustas(c, 'PRENOTACAO');
              if(criarVariavel){
                custasMap.push(tmp);
              }else {
                output += await callback(node.children, {...dicionario, custa: tmp}, debugNode);
              }
            }

            // console.debug('custasAdicionais', dicionario.custasAdicionais.length)
            for(let a of dicionario.custasAdicionais){
              let custas = Object.values(a.custas);
              let custa = FrontBusiness.somarTaxas(a.custas, undefined, a.quantidade);
              let tmp = {...tratarCustas(custas[0], a.tipo, a.quantidade), ...custa, custaAdicional: a, quantidade : a.quantidade};
              if(criarVariavel){
                custasMap.push(tmp);
              }else{
                const dic = {...dicionario, custa : tmp};
                output += await callback(node.children, dic, debugNode);
              }
            }

            // console.debug('atos', dicionario.atos.length)
            for(let a of dicionario.atos){

              const ato = (await LivroMatriculaBusiness.gerarDicionario(a, a.ficha, protocolo, [], true)).ato;

              let custas = Object.values(a.custas);
              let custa = FrontBusiness.somarTaxas(a.custas);
              let tmp = {...tratarCustas(custas[0], 'ATO'), ...custa, ato, quantidade : 1};
              if(criarVariavel){
                custasMap.push(tmp);
              }else {
                const dic = {...dicionario, custa: tmp};
                output += await callback(node.children, dic, debugNode);
              }

            }

            // console.debug('certidoes', dicionario.certidoes.length)
            for(let c of dicionario.certidoes){

              const certidao = await CertidaoImpressaoBusiness.gerarDicionario(c, protocolo, true);

              let custas = Object.values(c.custas);
              let custa = FrontBusiness.somarTaxas(c.custas);
              let tmp = {...tratarCustas(custas[0], 'CERTIDAO'), ...custa, certidao, quantidade : 1};
              if(criarVariavel){
                custasMap.push(tmp);
              }else {
                const dic = {...dicionario, custa: tmp};
                output += await callback(node.children, dic, debugNode);
              }

            }

            //console.debug('custasMap', custasMap, dicionario.atos);

            if(criarVariavel){
              output += await callback(node.children, {...dicionario, custas: custasMap}, debugNode);
            }

            break;

          }

          case 'repasses' : {
            dicionario.repasses = await ProtocoloRepasseBusiness.listar(protocolo.id);

            if(node.params.listar != undefined){
              const repasses = dicionario.repasses.map(repasse => ({
                valor : repasse.valor,
                descricao : repasse.descricao,
                categoria : repasse.repasse.nome,
              }));
              output += await callback(node.children, {...dicionario, repasses}, debugNode);
            }else{
              for(let repasse of dicionario.repasses){
                dicionario.repasse = {
                  valor : repasse.valor,
                  descricao : repasse.descricao,
                  categoria : repasse.repasse.nome,
                };
                output += await callback(node.children, dicionario, debugNode);
                dicionario.repasse = undefined;
              }
            }

            break;
          }

          case 'usuario-etapa' : {

            let etapa = node.params.etapa;
            let idx = node.params.nome;
            let usuario = await ProtocoloAtividadeBusiness.getUsuario(protocolo.id, etapa).catch(e => {
              console.error(e);
              return null;
            });

            if(usuario && idx){
              dicionario[idx] = usuario;
            }
            output += await callback(node.children, dicionario, debugNode);

            break;
          }

          default:
            output += await TextParser.commonsParse(node, dicionario, callback, debugNode);
            break;

        }

        if(debug){
          debugNode.conteudo = TextParser.htmlText(node.body);
          if (debugNode.tipo || debugNode.conteudo) {
            debug.nodes.push(Utils.clone(debugNode));
          }
        }

      }

      return output;
    };

    await TextParser.init();
    let texto = await TextParser.parse(exigencia, callback, dicionario);
    return TextParser.clean(texto + '.');

  },

}

<template>
  <div class="col-auto d-flex align-items-center flex-column" v-if="loading" style="background: #0d161f">
    <div class="my-auto text-center">
<!--      <div class="spinner-grow text-secondary" role="status">-->
<!--        <span class="sr-only">Loading...</span>-->
<!--      </div>-->

      <div id="circle">
        <div class="loader">
          <div class="loader">
            <div class="loader">
              <div class="loader">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "Loading",
    props: {
      loading: Boolean
    },
  }
</script>

<style scoped>

  #circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 150px;
    height: 150px;
  }

  #circle .loader {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border: 8px solid #162534;
    border-top: 8px solid #FEE17D;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
    padding: 5px;
  }

  @keyframes rotate {
    100% {transform: rotate(360deg);}
  }
</style>

<template>
  <div class="alert alert-danger pointer mb-3" @click="informar()" v-if="mostrar">
    <b>ATENÇÃO: </b> <br />
    {{textoLegado}} do sistema anterior. <br />
    É necessário informar o tipo correto para que as informações sejam apresentadas corretamente.<br />
    Clique aqui para informar.
  </div>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import ModalItemProtocoloLegado from "@/views/Protocolo/Atos/Legado/ModalItemProtocoloLegado.vue";

export default {
  name: "ItemProtocoloLegado",
  props:{
    id: String,
    tipo: String,
    dominio: String,
    dto: Object
  },

  computed:{

    textoLegado(){
      switch (this.tipo) {
        case 'ato':
          return 'Este é um ato importado';
        // case 'protocolo':
        //   return 'Este é um protocolo importado';
        case 'custa-adicional':
          return 'Esta é uma custa adicional importada';
        default:
          return null;
      }
    },

    mostrar(){
      return this.textoLegado && this.dto?.legado && !this.dto?.tipoServico?.id;
    }

  },

  methods:{
    async informar(){
      const resultado = await FrontBusiness.openModal(ModalItemProtocoloLegado, {id: this.id, tipo:this.tipo, dominio: this.dominio});
      if(resultado){
        this.$emit('reload');
      }
    }
  }

}
</script>

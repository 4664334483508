import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import error from "@/business/livros/copiladores/error.js";
import indicadorPessoal from "@/business/livros/copiladores/indicadorPessoal.js";
import TextParser from "@/business/livros/TextParser.js";
import ProtocoloAtividadeBusiness from "@/business/protocolo/ProtocoloAtividadeBusiness.js";
import Utils from "@/commons/Utils.js";
//
const qualificarEnvolvidoDefault = function(envolvido, opts = ['rg', 'nascimento', 'nacionalidade', 'profissao', 'estadoCivil', 'endereco'], montarConjuge = false, inserirConjuge = false){

  let isMasc = envolvido?.sexo != 'FEMININO';
  let nome = envolvido?.nome?.toUpperCase() || error('envolvido.nome');

  let output = `${nome}, `; // inscrit${isMasc ? 'o' : 'a'} no
  let inserirInformacaoCasamento = false;

  // PESSOA JURÍDICA
  if(envolvido?.tipoPessoa == 'JURIDICA'){
    output += `CNPJ ${envolvido.documento || error('envolvido.documento')}`;//sob o n°
    output += `, natureza jurídica, com sede`;

  }else{

    output += `CPF ${envolvido.documento || error('envolvido.documento')}`; //sob o n°

    if(opts?.includes('rg')) {
      output += `, RG ${envolvido.rg || error('envolvido.rg')}`; //portador${isMasc ? '' : 'a'} do documento de identificação n°
    }

    if(opts?.includes('nacionalidade')) {
      let labelNacionalidade = FrontBusiness.getLabel(envolvido.nacionalidade, IndicadorPessoalBusiness.getNacionalidades(), 'id', envolvido.sexo?.toLowerCase() || 'masculino');
      output += `, ${labelNacionalidade?.toLowerCase() || error('envolvido.nacionalidade')}`;
    }

    if(opts?.includes('nascimento')) {
    output += `, nascid${isMasc ? 'o' : 'a'} em ${Utils.formatters.date(envolvido.nascimento, false)|| error('envolvido.nascimento')}`; //
    }

    if(opts?.includes('profissao')) {
      output += `, ${envolvido.profissao || error('envolvido.profissao')}`;
    }

    let labelEstadosCivil = FrontBusiness.getLabel(envolvido.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil(), 'id', envolvido.sexo?.toLowerCase() || 'masculino');
    let enderecoPlural = false;

    if(inserirConjuge && montarConjuge && envolvido.conjuge && (envolvido.possuiUniaoEstavel || envolvido.estadoCivil == 'CASADO')){
      if(envolvido.possuiUniaoEstavel){
        output += `, ${labelEstadosCivil?.toLowerCase() || error('envolvido.estadoCivil')}, ${isMasc ? 'o' : 'a'} que declara conviver em união estável com`;//qual vive em união estável com
      }

      if(!envolvido.possuiUniaoEstavel){
        let labelRegimeCasamento = FrontBusiness.getLabel(envolvido.regimeCasamento, IndicadorPessoalBusiness.getRegimes(), 'id', 'descricao');
        //output += `, casad${isMasc ? 'o' : 'a'}${labelRegimeCasamento ? ' '+labelRegimeCasamento : ''}, ${envolvido.casamentoAposLei6515 ? 'depois' : 'antes'} da vigência da Lei n° 6.515/1977, com `;
        output += `, casad${isMasc ? 'o' : 'a'}${labelRegimeCasamento ? ' '+labelRegimeCasamento : ''} em ${Utils.formatters.date(envolvido.dataCasamento, false)|| error('envolvido.dataCasamento')}, com `;
      }
      output += qualificarEnvolvidoDefault(envolvido.conjuge, (opts || []).filter(e => !['endereco', 'estadoCivil'].includes(e)), false, false);
      enderecoPlural = true;
    }else{
      if(opts?.includes('estadoCivil')) {
        output += `, ${labelEstadosCivil?.toLowerCase() || error('envolvido.estadoCivil')}`;
      }
    }

    if(opts?.includes('endereco')) {
      output += `, residente${enderecoPlural ? 's' : ''} e domiciliad${enderecoPlural || isMasc ? 'o' : 'a'}${enderecoPlural ? 's' : ''}`;//${enderecoPlural ? ' ambos ' : ''}
    }

  }

  if(opts?.includes('endereco')){
    output += ` na ${envolvido.logradouro || error('envolvido.logradouro')}`;
    if(envolvido.numero){
      output += `, n. ${envolvido.numero}`;
    }
    output += `, bairro ${envolvido.bairro || error('envolvido.bairro')}`;
    if(envolvido.complemento){
      output += `, ${envolvido.complemento || error('envolvido.complemento')}`;
    }
    if(envolvido.quadra){
      output += `, quadra ${envolvido.quadra || error('envolvido.quadra')}`;
    }
    if(envolvido.lote){
      output += `, lote ${envolvido.lote || error('envolvido.lote')}`;
    }
    output += `, ${envolvido.cidade || error('envolvido.cidade')}/${envolvido.estado || error('envolvido.estado')}`;
  }

  if(envolvido?.tipoPessoa == 'FISICA'){
    if(!inserirConjuge && montarConjuge && opts?.includes('estadoCivil') && !envolvido.conjuge && !envolvido.possuiUniaoEstavel) {
      output += `, ${isMasc ? 'o' : 'a'} qual declara não viver em união estável`;
    }
  }

  // console.debug(output, envolvido);

  return TextParser.clean(output);

};

const callback = async (nodes = [], dicionario = {}, debug = {}) => {

  let output = '';

  for(let pos in nodes){
    let node = nodes[pos];

    let debugNode = { tipo : node.name, nodes : [], params : node.params, dicionario : dicionario };

    switch(node.name){

      default:
        output += await TextParser.commonsParse(node, dicionario, callback, debugNode);
        break;
    }

  }

  return output;
};

const qualificarEnvolvido = async function(envolvido, campos = ['rg', 'nascimento', 'nacionalidade', 'profissao', 'estadoCivil', 'endereco'], montarConjuge = false, inserirConjuge = false){

  let output = '';

  if(window.server.config?.outrosTemplates?.qualificacaoIndividuo){
    let versao = await indicadorPessoal.tratarDicionarioPessoa(envolvido);
    // console.debug(versao, envolvido)
    if(versao.conjuge){
      versao.conjuge = await indicadorPessoal.tratarDicionarioPessoa(envolvido.conjuge);
      if(envolvido.possuiUniaoEstavel && envolvido.estadoCivil != 'CASADO'){
        versao.convivente = versao.conjuge;
        versao.conjuge = null;
      }
    }
    let dicionario = {envolvido: versao, campos, incluirConjuge : inserirConjuge};
    output = await TextParser.parse(window.server.config?.outrosTemplates?.qualificacaoIndividuo, callback, dicionario);
  }else{
    output = qualificarEnvolvidoDefault(envolvido, campos, montarConjuge, inserirConjuge);
  }

  return TextParser.clean(output);

};

export default qualificarEnvolvido;

/*

-------------------- PESSOA JURÍDICA

NOME EMPRESARIAL COMPLETO, inscrito(a) no CNPJ sob o n° xxxxxx, natureza jurídica, com sede na Rua xxxx, n° xxxxx, no bairro xxxx, na Cidade de xxxx/Estado.

-------------------- PESSOA FÍSICA

NOME COMPLETO, inscrito(a) no CPF sob o n° xxxxxx, portador(a) do documento de identificação n° xxxxx (órgão emissor/Estado), nascido(a) em xxx/xxx/xxxx, nacionalidade, profissão, estado civil

-------------------- PESSOA FÍSICA CASADAS:

[DADOS], e seu/sua esposo(a), [DADOS], casados pelo regime da xxxxx, antes da (ou na) vigência da Lei n° 6.515/1977, ambos residentes e domiciliados na [ENDERECO].

OU

[DADOS], casado(a) pelo regime da xxxx, antes da (ou na) vigência da Lei n° 6.515/1977, [DADOS], ambos residentes e domiciliados na [ENDERECO].

-------------------- PESSOA FÍSICA SEM UNIÃO ESTÁVEL:

[DADOS] , residente e domiciliado(a) na [ENDERECO], o qual declara não viver em união estável.

-------------------- PESSOA FÍSICA COM UNIÃO ESTÁVEL:

[DADOS], o(a) qual vive em união estável com [DADOS], ambos residentes e domiciliados na [ENDERECO].

OU

[DADOS], e seu (sua) companheiro(a), [DADOS], ambos residentes e domiciliados na [ENDERECO], os quais vivem em união estável entre si.

 */

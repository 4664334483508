import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
import agruparLista from "@/business/livros/copiladores/agruparLista.js";
import criarModelo from "@/business/livros/copiladores/criarModelo.js";
import debugar from "@/business/livros/copiladores/debugar.js";
import error from "@/business/livros/copiladores/error.js";
import filtrarLista from "@/business/livros/copiladores/filtrarLista.js";
import fragmento from "@/business/livros/copiladores/fragmento.js";
import gerarHtmlNativo from "@/business/livros/copiladores/gerarHtmlNativo.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import getVarsList from "@/business/livros/copiladores/getVarsList.js";
import indicadorPessoal from "@/business/livros/copiladores/indicadorPessoal.js";
import obrigarConteudo from "@/business/livros/copiladores/obrigarConteudo.js";
import repetidor from "@/business/livros/copiladores/repetidor.js";
import testarCondicao from "@/business/livros/copiladores/testarCondicao.js";
import textoFormatado from "@/business/livros/copiladores/textoFormatado.js";
import tratarTexto from "@/business/livros/copiladores/tratarTexto.js";
import usarModelo from "@/business/livros/copiladores/usarModelo.js";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import {getBase64Image} from "@/commons/Http";
import Utils from "@/commons/Utils";
import Tokenizer from "@/libs/Tokenizer";
import { useDateFormat } from '@vueuse/core'
import moment from 'moment';
import 'moment/locale/pt-br';

import extenso from 'extenso';

export default {

  stripHtml(html){
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  },

  htmlText(text){
    return (text || '').trim(); //.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('<', '&lt;')
  },

  error(texto){
    return `<span class="text-danger-soft erro-minuta" style="color: red;">$(${texto})</span>`;
  },

  async init(){
    // if(!window.fragmentos){
    //   window.fragmentos = await FragmentoBusiness.getAll();
    // }
  },

  async parse(input, callback, dicionario, debug){

    try{
      return await callback(this.test(input), dicionario, debug);
    }catch (e) {
      console.error(e);
      return error('Erro na conversão da minuta: ' + e.message);
    }

  },

  clean(texto = ''){
    texto = texto

      .replace(/(\r\n|\n|\r)/gm, " ")
      .replace(/\s+/g,' ')

      .replaceAll(' >', '>')
      .replaceAll(' </', '</')
      .replaceAll(' ,', ',')
      .replaceAll(' .', '.')
      .replaceAll(' /', '/')
      .replaceAll(' ;', ';')
      .replaceAll(' )', ')')
      .replaceAll('( ', '(')

      .replace(/^,|,$|(,)+/g, '$1')
      .replace(/^\.|\.$|(\.)+/g, '$1')
      .replaceAll('[.]', '[...]')

      .replaceAll('<br>', '<br/>')
      .replaceAll('<hr>', '<hr/>')

      .trim();

    const raw = Utils.removeHTML(texto).trim();

    // console.debug('raw', raw, raw.endsWith('.'), /[^\w\s]$/.test(raw.slice(0, -1)));
    if(raw.endsWith('.') && /[^\w\s]$/.test(raw.slice(0, -1))){
      return texto.slice(0, -1);
    }

    if(raw == '.'){
      return '';
    }

    return texto;

  },

  test(input){

    try{

      input = (input+'').replace(/<!--[\s\S]*?-->/g, "").trim()
        .replace(/(\r\n|\n|\r)/gm, " ").replace(/\s+/g,' ');

      getVarsList(input).forEach(variavel => input = input.replaceAll(variavel.value, variavel.newValue));

      return Tokenizer.tokens(input);

    }catch (e) {
      console.error(e);
      return error('Erro na leitura da minuta: ' + e.message);
    }

  },

  async gerar(template, dicionario){

    const callback = async (nodes = [], dicionario = {}, debug = {}) => {

      let output = '';

      for(let pos in nodes){
        let node = nodes[pos];
        let debugNode = { tipo : node.name, nodes : [], params : node.params, dicionario : dicionario };
        output += await this.commonsParse(node, dicionario, callback, debugNode);
      }

      return output;
    };

    await this.init();
    let texto = await this.parse(template, callback, dicionario);
    return this.clean(texto + '...');

  },

  async commonsParse(node, dicionario, callback, debug) {

    let output = '';
    switch (node.name) {

      case 'br':
      case 'novalinha':
      case 'nova-linha':
      case 'quebrarlinha':
        output += '<br/>';
        break;

      case 'linha':
      case 'hr':
        output += '<hr/>';
        break;

      case 'p':
      case 'paragrafo':
        output += await gerarHtmlNativo('p', node, callback, dicionario, debug);
        break;

      case "div": {
        output += await gerarHtmlNativo('div', node, callback, dicionario, debug);
        break;
      }

      case "tabela": {
        output += await gerarHtmlNativo('table', node, callback, dicionario, debug);
        break;
      }

      case "tabela-cabecalho": {
        output += await gerarHtmlNativo('thead', node, callback, dicionario, debug);
        break;
      }

      case "tabela-rodape": {
        output += await gerarHtmlNativo('tfoot', node, callback, dicionario, debug);
        break;
      }

      case "tabela-corpo": {
        output += await gerarHtmlNativo('tbody', node, callback, dicionario, debug);
        break;
      }

      case "tabela-linha": {
        output += await gerarHtmlNativo('tr', node, callback, dicionario, debug);
        break;
      }

      case 'th':
      case "tabela-titulo": {
        output += await gerarHtmlNativo('th', node, callback, dicionario, debug);
        break;
      }

      case 'td':
      case "tabela-celula": {
        output += await gerarHtmlNativo('td', node, callback, dicionario, debug);
        break;
      }

      case 'b':
      case 'negrito':
        output += await gerarHtmlNativo('b', node, callback, dicionario, debug);
        break;

      case 'u':
      case 'sublinhado':
        output += await gerarHtmlNativo('u', node, callback, dicionario, debug);
        break;

      case 'i':
      case 'italico':
        output += await gerarHtmlNativo('i', node, callback, dicionario, debug);
        break;

      case 'capitalizar':
        output += await tratarTexto('capitalizar', node, callback, dicionario, debug);
        break;

      case 'maiusculo':
        output += await tratarTexto('maiusculo', node, callback, dicionario, debug);
        break;

      case 'minusculo':
        output += await tratarTexto('minusculo', node, callback, dicionario, debug);
        break;

      case 'texto-formatado': {
        let texto = getVar(node.params.texto, dicionario, false);
        texto = textoFormatado((texto || '').replaceAll(`<p style="text-align:justify;"`, '<p').replace(/<p>(.*?)<\/p>/, '$1'));
        output += texto;
        break;
      }

      case 'extenso':{

        try{
          let numero = parseFloat(getVar(node.params.valor, dicionario, false));

          const isMoeda = node.params.moeda !== undefined;
          const isPercentual = node.params.percentual !== undefined;
          const isArea = node.params.area !== undefined;

          const isCurrency = isMoeda || isArea;

          let num = isCurrency ? parseFloat(numero).toFixed(2) : numero;
          let texto = extenso(`${num}`.replace('.', ','), { mode: isCurrency ? 'currency' : 'number' });

          // ajuste de bug no plugin "extenso" que inclui o texto 'de' mesmo quando não necessário
          if(!texto.endsWith('milhão de reais') && !texto.endsWith('bilhão de reais') && !texto.endsWith('milhões de reais') && !texto.endsWith('bilhões de reais')){
            texto = texto.replace(" de ", " ");
          }

          if(isMoeda && node.params.moeda){

            const moedaTipo = getVar(node.params.moeda, dicionario, false, true);
            const real = "real";
            const reais = "reais";
            let prefix = '';

            switch (moedaTipo){
              case "CRUZEIRO":
                texto = texto.replace(real, prefix+"cruzeiro").replace(reais, prefix+"cruzeiros");
                break;
              case "CRUZEIRO_NOVO":
                texto = texto.replace(real, prefix+"cruzeiro novo").replace(reais, prefix+"cruzeiros novos");
                break;
              case "CRUZADO":
                texto = texto.replace(real, prefix+"cruzado").replace(reais, prefix+"cruzados");
                break;
              case "CRUZADO_NOVO":
                texto = texto.replace(real, prefix+"cruzado novo").replace(reais, prefix+"cruzados novos");
                break;
              case "CRUZEIRO_REAL":
                texto = texto.replace(real, prefix+"cruzeiro real").replace(reais, prefix+"cruzeiros reais");
                break;
            }

          }

          if(node.params.area !== undefined){
            const areaTipo = getVar(node.params.area, dicionario, false, true);
            let metragem = IndicadorRealBusiness.getMedidas().find(e => e.id == areaTipo);
            if (node.params.abnt == undefined) {
              metragem.singular = (metragem?.singular || "metro quadrado").split(' ')[0];
              metragem.plural = (metragem?.plural || "metros quadrados").split(' ')[0];
            }

            texto = texto.replace('real', metragem?.singular || "metro")
              .replace('reais', metragem?.plural || "metros")
              .replace('centavos', metragem?.decimalPlural || "centímetros")
              .replace('centavo', metragem?.decimal || "centímetro") + (areaTipo == 'M2' ? " quadrados" : "");

          }

          output += texto;
        }catch (e) {
          output += error(`Não foi possível formatar o número informado`);
        }

        break;
      }

      case 'data':
      case 'formatar-data':{
        try {
          let formato = (node.params.formato || 'DD/MM/YYYY').replaceAll('{', '[').replaceAll('}', ']');
          let valor = getVar(node.params.valor, dicionario, false);
          let data = moment(valor);
          if (data.isValid()) {
            const formatted = useDateFormat(data, formato);
            output += formatted.value;
          } else {
            output += error(`Não foi possível formatar a data informada [${valor}]`);
          }
        }catch (e) {
          console.error(e);
          output += error(`Não foi possível formatar a data informada`);
        }
        break;
      }

      case 'numero':
      case 'formatar-numero':{

        let numero = getVar(node.params.valor, dicionario, false);
        try{
          if(isNaN(parseFloat(numero))){
            if(numero.includes('{{') && numero.includes('}}')){
              output += numero;
            }else{
              output += error(`Não foi possível formatar o número informado [${numero}]`);
            }
          }else{
            output += parseFloat(numero)
              .toLocaleString("pt-BR",
                {minimumFractionDigits: node.params.decimal || 0, maximumFractionDigits: node.params.maximo || node.params.decimal || 0,
                  style: 'currency', currency: 'BRL'})
              .replace("R$ ", "");
          }
        }catch (e) {
          output += error(`Não foi possível formatar o número informado [${numero}]`);
        }

        break;
      }

      case 'var':{

        let erro = false;
        let valor;
        try{

          valor = getVar(node.params.attr, dicionario);
          if(typeof valor == 'string'){ // remove o primeiro paragrafo do texto
            valor = valor.replace('<p>', '').replace('</p>', '');
            if(!valor.trim().length){
              erro = true;
            }
          }

          if(valor == undefined || valor == null){
            erro = true;
          }

        }catch (e) {
          console.error(node.params.attr, e);
          erro = true;
        }

        if(debug){
          debug.variavel = valor;
        }

        if(erro){
          valor = error(node.params.attr);
        }
        output += valor;
        break;
      }

      case 'vinculos':{

        let outputVinculos = [], ids = (dicionario.checklist[node.params.indice] || dicionario[node.params.indice] || []), lista = [];

        if(!dicionario.checklist[node.params.indice]){
          ids = getVar(node.params.indice, dicionario, false, false, true) || [];
        }

        if(!Array.isArray(ids)){
          ids = [ids];
        }

        // console.debug(dicionario);
        // console.debug("vinculos", ids, node.params.indice, dicionario.checklist[node.params.indice], dicionario[node.params.indice]);

        switch(node.params.tipo) {

          case 'INDICADOR_PESSOAL': {
            for(let id of ids){
              try{
                let pessoa = await IndicadorPessoalBusiness.getVersaoByIdComCache(id?.id || id);
                pessoa = await LivroMatriculaBusiness.gerarDicionarioEnvolvido({indicadorPessoalVersao : pessoa, ...pessoa});
                lista.push({ indicadorPessoal : pessoa});
              }catch (e) {
                console.error('INDICADOR_PESSOAL', e);
              }
            }
            break;
          }

          case 'INDICADOR_REAL': {

            lista = await IndicadorRealBusiness.getFichasPorId(ids).then(l => l.map(ficha => {
              return {
                order: ids.indexOf(ficha.id),
                imovel: {
                  ...ficha,
                  categoria: FrontBusiness.getLabel(ficha.categoria, IndicadorRealBusiness.getCategorias()).toLowerCase(),
                  tipoImovel: FrontBusiness.getLabel(ficha.tipoImovel, IndicadorRealBusiness.getTiposImovel()).toLowerCase(),
                  codigo: FrontBusiness.formatarCodigoPreMatricula(ficha.codigo, ficha),
                  livro: FrontBusiness.nomearFicha(ficha),
                  areas : JSON.parse(ficha.areas),
                  areasEdificadas : JSON.parse(ficha.areasEdificadas),
                  unidadesAcessorias : JSON.parse(ficha.unidadesAcessorias),
                  tipoImovelDescricao : ficha.descricaoTipoImovelOutros
                }
              }
            })).catch(e => []);
            lista.sort((a, b) => a.order - b.order);
            break;
          }

          case 'REGISTRO_AUXILIAR': {
            lista = await RegistroAuxiliarBusiness.getFichasPorId(ids).then(l => l.map(ficha => {
              return {
                order: ids.indexOf(ficha.id),
                registroAuxiliar: {
                  ...ficha,
                  codigo: FrontBusiness.formatarCodigoPreMatricula(ficha.codigo, ficha),
                  livro: FrontBusiness.nomearFicha(ficha)
                }
              }
            })).catch(e => []);
            lista.sort((a, b) => a.order - b.order);
            break;
          }

          case 'ATO_REGISTRO_AUXILIAR':
          case 'ATO_INDICADOR_REAL': {

            lista = await AtoBusiness.getItensPorIdSimplificado(ids).then(l => l.map(ato => {
              let codigo = ato.codigo || ato.codigoAto;
              let tipoAto = ato.tipo || ato.tipoServico?.opcoes?.tipoAto;
              let titulo = ato.titulo || ato.tipoServico?.opcoes?.titulo;
              if(!codigo){
                codigo = AtoBusiness.getCodigo(ato, ato.indicadorReal || ato.registroAuxiliar, tipoAto, true);
              }
              return {
                order: ids.indexOf(ato.id),
                ato: {
                  codigo: codigo,
                  registro: ato.registro,
                  tipo: tipoAto == 'REGISTRO' ? 'R' : 'Av',
                  titulo: titulo,
                  checklist: ato.checklist,
                  texto: ato.texto,
                  imovel: {
                    codigo: FrontBusiness.formatarCodigoPreMatricula(ato.indicadorReal?.codigo, ato.indicadorReal) + '',
                    livro: FrontBusiness.nomearFicha(ato.indicadorReal)
                  },
                  registroAuxiliar: {
                    codigo: FrontBusiness.formatarCodigoPreMatricula(ato.registroAuxiliar?.codigo, ato.registroAuxiliar) + '',
                    livro: FrontBusiness.nomearFicha(ato.registroAuxiliar)
                  }
                },
                envolvidos: ato.envolvidos,
              }
            })).catch(e => {
              console.error(e);
              return [];
            });
            lista.sort((a, b) => a.order - b.order);
            break;
          }

          case 'CANCELAMENTO_RESTRICAO': {

            let atoAberto = dicionario?.ato;

            let ids = atoAberto?.dto?.restricoesCanceladas || [];
            lista = await AtoBusiness.getRestricoesPorId(atoAberto?.id, ids).then(l => l.map(restricao => {

              let ato = JSON.parse(restricao.descricaoRestricao);

              let codigo = ato.codigo || ato.codigoAto;
              if(!codigo){
                codigo = AtoBusiness.getCodigo(ato, atoAberto?.ficha, ato.tipoAto, true);
              }
              return {
                ato: {
                  codigo: codigo,
                  tipo: ato.tipoAto == 'REGISTRO' ? 'R' : 'Av',
                  titulo: ato.opcoes?.titulo,
                  checklist: ato.checklist,
                  texto: ato.texto,
                },
                pessoa: {
                  nome : restricao.nome,
                  documento : restricao.documento,
                  restricao : restricao.restricao,
                  papel : restricao.papel,
                },
              }
            })).catch(e => {
              console.error(e);
              return [];
            });

            if(dicionario?.ato?.dto?.restricoesCanceladasSemRegistrar?.length){

              let method = atoAberto?.ficha?.livro != 'REGISTRO_AUXILIAR' ? AtoBusiness.restricoesSemRegistrar : AtoBusiness.restricoesSemRegistrarRegistroAuxiliar;

              let restricoes = (await method(dicionario?.protocolo?.id, atoAberto?.id, atoAberto?.ficha?.id));

              const restricoesCanceladasSemRegistrar = atoAberto.dto.restricoesCanceladasSemRegistrar;

              restricoes = (restricoes || []).filter(restricao => {
                return restricoesCanceladasSemRegistrar.filter(r => r.atoId === restricao.atoId
                  && r.restricao === restricao.restricao && r.indicadorPessoalVersaoId === restricao.indicadorPessoalVersaoId ).length > 0
              }).map(restricao => {

                let a = JSON.parse(restricao.descricaoRestricao);
                const codigo = AtoBusiness.gerarCodigoSimplificado(a?.ordem, atoAberto.ficha?.ctrl?.ultimoAto || 0, a?.tipoAto, atoAberto.ficha?.ctrl?.status);

                return {
                  ato: {
                    codigo: codigo,
                    tipo: a?.tipoAto == 'REGISTRO' ? 'R' : 'Av',
                    // titulo: ato.opcoes.titulo,
                    // checklist: ato.checklist,
                  },
                  pessoa: {
                    // nome : restricao.nome,
                    // documento : restricao.documento,
                    // restricao : restricao.restricao,
                    // papel : restricao.papel,
                  },
                }

              });

              if(restricoes.length){
                lista.push(...(restricoes || []));
              }

            }

            break;
          }
        }

        if(debug){
          debug.vinculos = Utils.clone(lista);
        }

        //console.debug(lista);

        if(node.params.lista !== undefined){
          //console.debug(node.params, lista, dicionario?.ato?.dto?.restricoesCanceladas);
          output += await callback(node.children, {...dicionario, vinculos : lista}, debug);
        }else{

          // FrontBusiness.showWarn(`Atenção: Recomendado uso do método "vinculos" com a tag "lista". Favor avisar a coordenação.` );

          for(let i in lista){
            dicionario.vinculo = {...lista[i]};
            if(dicionario.vinculo.envolvidos){
              await this.tratarEnvolvidos(dicionario.vinculo.envolvidos, dicionario.vinculo, {});
            }
            outputVinculos.push('  ' + await callback(node.children, dicionario, debug) + '  ');
            dicionario.vinculo = null;
          }

          output += '  ' + FrontBusiness.formatArray(outputVinculos, node.params.separador, node.params.ultimo)  + ' ';
        }

        break;

      }

      case 'link-externo': {
        let texto = getVar(node.params.texto, dicionario, false, true);
        let url = getVar(node.params.url, dicionario, false, true);
        output += `<a href="${url}" target="_blank">${texto}</a>`;
        break;
      }

      case 'qrcode': {
        let text = getVar(node.params.texto, dicionario, false);
        const height = node.params.altura || 100, width = node.params.largura || 100;
        output += `<img class="qrcode" src="${window.server.API}/api/public/qrcode/${width}/${height}/?text=${text}" width="${width}" height="${height}" />`;
        break;
      }

      case 'barcode': {
        const text = node.params.texto, height = node.params.altura, width = node.params.largura;
        output += `<img class="barcode" src="${window.server.API}/api/public/barcode/${width}/${height}/?text=${text}" width="${width}" height="${height}" />`;
        break;
      }

      case 'anexo':
      case 'foto': {
        const texto = getVar(node.params.imagem, dicionario, false, true), height = node.params.altura || 100, width = node.params.largura || 100;
        if (node.params.imagem === texto) {
          output += error(texto);
        } else {
          output += `<img style="object-fit: contain;" src="${window.server.API}/api/anexo/public/${texto}/download" width="${width}" height="${height}" />`;
        }
        break;
      }

      case 'base64': {
        output += await getBase64Image(node.params.url).catch(e => this.error(node.params.url));
        break;
      }

      case 'genero':
        if(dicionario.envolvido){
          output += this.tratarGenero(dicionario.envolvido, node.params);
        }
        break;

      case 'condicao':{
        if(testarCondicao(node.params, dicionario, debug)){
          if(debug){
            debug.children = node.children;
            debug.params.resultado = true;
          }
          output += await callback(node.children, dicionario, debug);
        }
        break;
      }

      case 'erro':{
        if(debug) {
          debug.children = node.children;
        }
        output += error((await callback(node.children, dicionario, debug)));
        break;
      }

      /** MÉTODOS MIGRADOS ****************************************************************/

      case 'fragmento':{
        output += await fragmento(node, callback, dicionario, debug);
        break;
      }

      case 'modelo':{
        output += await criarModelo(node, callback, dicionario, debug);
        break;
      }

      case 'usar-modelo':{
        output += await usarModelo(node, callback, dicionario, debug);
        break;
      }

      case 'repetidor' : {
        output += await repetidor(node, callback, dicionario, debug);
        break;
      }

      case 'agrupar-lista' : {
        output += await agruparLista(node, callback, dicionario, debug);
        break;
      }

      case 'filtrar-lista' : {
        output += await filtrarLista(node, callback, dicionario, debug);
        break;
      }

      case 'obrigar-conteudo' : {
        output += await obrigarConteudo(node, callback, dicionario, debug);
        break;
      }

      case 'debugar' : {
        output += await debugar(node, callback, dicionario, debug);
        break;
      }

      case 'indicador-pessoal' : {
        output += await indicadorPessoal.parse(node, callback, dicionario, debug);
        break;
      }

      // TEXT E NÃO COMPUTADAS
      default:

        output += node.body;

        if(node.type == 'OPEN' && node.children?.length){
          output += await callback(node.children, dicionario, debug);
          output += `</${node.name}>`;
        }

        // switch (node.type) {
        //   case 'OPEN':
        //     output += node.body;
        //     break;
        //   case 'CLOSE':
        //     output += node.body;
        //     break;
        //   case 'SELF_CLOSING':
        //     output += node.body;
        //     break;
        //   default:
        //     output += node.body;
        //     break;
        // }

        break;

    }

    return output;

  },

  tratarGenero(envolvido, params){

    let consorte = envolvido.conjuge || envolvido.convivente;

    if (params.conjugeFeminino || params.conjugeMasculino) {
      if ((consorte?.sexo || '').toUpperCase() == 'FEMININO') {
        return params.conjugeFeminino;
      } else {
        return params.conjugeMasculino;
      }
    }

    if ((envolvido.sexo || '').toUpperCase() == 'FEMININO') {
      return params.feminino;
    } else {
      return params.masculino;
    }

  },

  async copilarDicionarioEnvolvido(envolvido, dicionario = {}, fracaoPropriedade = 0){

    if(!envolvido.indicadorPessoalVersao){
      envolvido.indicadorPessoalVersao = await IndicadorPessoalBusiness.getVersaoByIdComCache(envolvido.indicadorPessoalVersaoId);
    }

    // Object.keys(envolvido.indicadorPessoal).forEach(key => {
    //   envolvido[key] = envolvido.indicadorPessoal[key];
    // });

    Object.keys(envolvido.indicadorPessoalVersao).forEach(key => {
      envolvido[key] = envolvido.indicadorPessoalVersao[key];
    });

    envolvido.conjuge = null;

    if(envolvido.fracao && envolvido.papel){

      envolvido.proprietario = !!fracaoPropriedade;

      // verificar se transmissao é total
      if(envolvido.papel == 'TRANSMITENTE' && envolvido.fracao > 0 && fracaoPropriedade){
        envolvido.transmissao = envolvido.fracao < fracaoPropriedade ? 'PARCIAL' : 'TOTAL';
      }

      if(envolvido.papel == 'DEVEDOR' && envolvido.fracao > 0 && fracaoPropriedade){
        envolvido.garantia = envolvido.fracao < fracaoPropriedade ? 'PARCIAL' : 'TOTAL';
      }

    }

    if (envolvido.tipoPessoa == 'JURIDICA') {
      envolvido.cnpj = envolvido.documento;
    }

    if (envolvido.tipoPessoa == 'FISICA') {
      envolvido.cpf = envolvido.documento;

      envolvido.estadoCivil = FrontBusiness.getLabel(envolvido.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil()).toLowerCase()
        .replace('o(a)', envolvido.sexo == 'FEMININO' ? 'a' : 'o');

      envolvido.regimeCasamento = FrontBusiness.getLabel(envolvido.regimeCasamento, IndicadorPessoalBusiness.getRegimes()).toLowerCase();

    }

    envolvido.sexo = FrontBusiness.getLabel(envolvido.sexo, IndicadorPessoalBusiness.getSexos()).toLowerCase();
    envolvido.tipoPessoa = FrontBusiness.getLabel(envolvido.tipoPessoa, IndicadorPessoalBusiness.getTiposPessoa()).toLowerCase();
    envolvido.nacionalidade = FrontBusiness.getLabel(envolvido.nacionalidade, IndicadorPessoalBusiness.getNacionalidades(), 'id', envolvido.sexo).toLowerCase();

    let camposEndereco = ['cep', 'estado', 'cidade', 'bairro', 'quadra', 'lote', 'logradouro', 'numero', 'complemento'];
    envolvido.endereco = {};
    envolvido.enderecoComercial = {};
    camposEndereco.forEach(c => {
      envolvido.endereco[c] = envolvido?.[c];
      envolvido.enderecoComercial[c] = envolvido?.[`${c}Comercial`];
    });

    // let camposContato = ['mail', 'celular', 'telefone', 'comercial'];


    envolvido.checklist = {...(dicionario || {})};

  },

  minutaInvalida(minuta){

    if(Utils.isEmptyNullOrUndefined(minuta)){
      return 'Ato sem minuta'
    }

    const vars = getVarsList(minuta);
    if (vars && vars.length > 0) {
      return "Faltam informações para a geração correta da minuta.";
    }

    return false;
  },

  async tratarEnvolvidos(lista, dicionario, proprietarios = {}){

    if(!lista){
      return;
    }

    let envolvidos = Utils.clone(lista);
    envolvidos.sort((a, b) => (a?.ordem || 0) - (b?.ordem || 0));
    dicionario.envolvidos = {lista : []};

    let transmissaoTotal = true;
    let fracao = 0;
    let fracaoGarantia = 0;
    let restricaoTotal = true;
    let fracaoRestricao = 0;

    IndicadorRealBusiness.getPapeisEnvolvidos().forEach(p => dicionario.envolvidos[p.id] = 0);
    RegistroAuxiliarBusiness.getPapeis().forEach(p => dicionario.envolvidos[p.id] = 0);

    for (let index = 0; index < envolvidos.length; index++) {

      let id = envolvidos[index].indicadorPessoalVersaoId, papel = envolvidos[index]?.papel?.toUpperCase();
      if(!envolvidos[index].indicadorPessoalVersao){
        envolvidos[index].indicadorPessoalVersao = await IndicadorPessoalBusiness.getVersaoByIdComCache(id);
      }

      let original = envolvidos[index].indicadorPessoalVersao.indicadorPessoalId;

      if(papel){
        ++dicionario.envolvidos[papel];

        if(papel == 'TRANSMITENTE'){
          transmissaoTotal = transmissaoTotal && envolvidos[index].fracao >= ( (proprietarios[original] || 0) - 0.01 );
          fracao += parseFloat(envolvidos[index].fracao || 0);
        }

        if(papel == 'CREDOR'){
          //garantiaTotal = garantiaTotal && envolvidos[index].fracao >= ( (proprietarios[original] || 0) - 0.01 );
          fracaoGarantia += parseFloat(envolvidos[index].fracao || 0);
        }

        if(papel == 'DEVEDOR'){
          restricaoTotal = restricaoTotal && envolvidos[index].fracao >= ( (proprietarios[original] || 0) - 0.01 );
          fracaoRestricao += parseFloat(envolvidos[index].fracao || 0);
        }

      }

    }

    dicionario.ato = dicionario.ato || {};

    dicionario.ato.transmissao = transmissaoTotal ? 'TOTAL' : 'PARCIAL';
    dicionario.ato.transmissaoImovel = fracao >= 99.9999999999 ? 'TOTAL' : 'PARCIAL';

    dicionario.ato.garantia = fracaoGarantia >= 99.9999999999 ? 'TOTAL' : 'PARCIAL';
    dicionario.ato.garantiaImovel = fracaoGarantia >= 99.9999999999 ? 'TOTAL' : 'PARCIAL';

    dicionario.ato.restricao = restricaoTotal ? 'TOTAL' : 'PARCIAL';
    dicionario.ato.restricaoImovel = fracaoRestricao >= 99.9999999999 ? 'TOTAL' : 'PARCIAL';

    dicionario.envolvidos.lista = envolvidos;

    return envolvidos;

  },

}


const textoFormatado = function(texto){

  return (texto || '') // converter pra span
    .replaceAll(`&nbsp;</b>`, '</b> ')
    .replaceAll(`&nbsp;</strong>`, '</strong> ')
    .replaceAll(`&nbsp;</i>`, '</i> ')
    .replaceAll(`&nbsp;</u>`, '</u> ')
    .replaceAll(`&nbsp;`, ' ')
    .replaceAll(`&amp;`, '&')
    .replaceAll('<br>', '<br/>')
    .replaceAll('<hr>', '<hr/>');

}

export default textoFormatado;

import AuthBusiness from "@/business/AuthBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness.js";
import CertidaoBusiness from "@/business/protocolo/CertidaoBusiness.js";
import SeloBusiness from "@/business/SeloBusiness.js";
import moment from 'moment';

export default {

  async gerarDicionario(certidao, protocolo, simplificado = false){

    const dicionario = {
      hoje : moment(),
      usuario :{},
      usuarioLogado : { }
    };

    if(!simplificado){
      await AuthBusiness.getLoggedUser().then(logged => dicionario.usuarioLogado = { ...logged, senha : undefined });
    }

    if(certidao.usuarioSelo?.nome){
      dicionario.usuario.selagem = certidao.usuarioSelo.nome;
    }

    /**
     * CONFIGURACOES DO certidao
     */
    dicionario.certidao = {
      id : certidao.id,
      codigo : certidao.pedido,
      pedido : certidao.pedido,
      selado : certidao.selado,
      isento : certidao.isento,
      tipo : FrontBusiness.getLabel(certidao?.tipoServico?.opcoes?.tipoCertidao, CertidaoBusiness.tiposCertidao()),
      titulo : certidao?.tipoServico?.nome,
      valores : {},
      protocolo : protocolo.codigo,
      cadastro : protocolo.cadastro
    };

    // INDICES
    if (certidao.indices != null) {
      dicionario.checklist = {};

      Object.keys(certidao.indices).forEach(i => {
        dicionario.checklist[i] = certidao.indices[i];
        dicionario.checklist[i.toUpperCase()] = certidao.indices[i];
        dicionario.checklist[i.toLowerCase()] = certidao.indices[i];
      });

    }

    dicionario.ficha = { type : '', id : null, paginas : [], livro : null };

    if(certidao.indicadorReal?.id){
      dicionario.imovel = await LivroMatriculaBusiness.gerarDicionarioImovel(certidao.indicadorReal, simplificado);
      dicionario.ficha = {...dicionario.ficha, ...certidao.indicadorReal};
    }

    if(certidao.registroAuxiliar?.id){
      dicionario.registroAuxiliar = await LivroMatriculaBusiness.gerarDicionarioRegistroAuxiliar(certidao.registroAuxiliar, simplificado);
      dicionario.ficha = {...dicionario.ficha, ...certidao.registroAuxiliar};
    }

    if(!dicionario.ficha?.id){
      dicionario.ficha = null;
    }

    // GERA AS TAXAS DO PROTOCOLO:
    if(!simplificado){
      const obj = FrontBusiness.somarTaxas(certidao.custas);
      dicionario.certidao = {...dicionario.certidao, ...obj};
      dicionario.seloPrincipal = await SeloBusiness.getSeloCertidao(certidao.id).catch(e => null);
      dicionario.selo = dicionario.seloPrincipal?.codigo;
    }

    return dicionario;

  }

}

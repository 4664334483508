<template>
  <card titulo="Atos Registrados">

    <template #acoes>
      <v-button :run="imprimirAtos" :button="false" :popover="true" :params="['rcpj']" v-if="$root.isRCPJ"
                position="dropleft" class="icon float-right ml-2" title="Imprimir Atos RCPJ">
        <i class="fal fa-print"></i>
      </v-button>
      <v-button :run="imprimirAtos" :button="false" :popover="true" :params="['rtd']" v-if="$root.isRTD"
                position="dropleft" class="icon float-right ml-2" title="Imprimir Atos RTD">
        <i class="fal fa-print"></i>
      </v-button>
      <v-button :run="imprimirAtos" :button="false" :popover="true" :params="['ri']" v-if="$root.isRI"
                position="dropleft" class="icon float-right ml-2" title="Imprimir Atos RI">
        <i class="fal fa-print"></i>
      </v-button>
    </template>

    <v-input type="radio" :options="atribuicoes" v-model="atribuicao" @change.prevent="load"/>

    <card-table class="table-borderless mb-2" v-if="atos.length > 0">
      <thead>
      <tr>
        <th>Ficha</th>
        <th>Ato</th>
        <th>Papel</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(ato) in atos" :key="ato.id">
        <td>
          <a href class="mb-1 pointer" @click.prevent="verFicha(ato.ficha)">
            {{getFicha(ato.ficha)}}
          </a>
          <div class="w-100 mt-1 small text-muted">
            <resumo-indicador-real :imovel="ato.ficha" />
          </div>
        </td>
        <td>
          <a class="icon" @click.prevent="verAto(ato)">
            {{getCodigo(ato, ato.ficha)}}
            <template v-if="ato.titulo">
              - {{ato.titulo}}
            </template>
            <template v-else-if="ato.tipoServico && ato.tipoServico.opcoes && ato.tipoServico.opcoes.titulo">
              - {{ato.tipoServico.opcoes.titulo}}
            </template>
          </a>
          <div class="w-100 mt-1 small text-muted">
            <template v-if="ato.protocoloCodigo">
              <router-link :to="{ name: 'protocolo', params:{id : ato.protocoloId} }" class="mb-1 pointer" target="_blank" @click.native="$event.stopImmediatePropagation()">
                {{ nomearProtocolo(ato.protocoloDominio, ato.tipoProtocolo, ato.protocoloCodigo) }}
              </router-link>
            </template>
            <span class="text-muted"> {{$formatters.date(ato.registro)}}</span>
            <v-button :button="false" class="icon ml-2" :class="{'text-success': ato.validacao}" :title="ato.validacao ? `Ato Validado` : `Aguardando Validação` ">
              <i class="fal fa-check-double"></i>
            </v-button>
          </div>
        </td>
        <td>{{ getPapel(ato.papel) }}</td>
      </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!atos.length && !loading" class="alert alert-outline-info">
      Não há atos para exibir
    </div>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import RelatorioBusiness from "@/business/RelatorioBusiness";
  import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import SmallLoading from "@/components/SmallLoading";
  import DetalhesAtoRegistrado from "@/components/IndicadorGeneric/Detalhes/DetalhesAtoRegistrado.vue";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
  import CardTable from "@/components/Layout/components/CardTable";
  import ResumoIndicadorReal from "@/components/IndicadorReal/ResumoIndicadorReal";
  import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business";

  export default {

    name: "Atos",
    components: {ResumoIndicadorReal, SmallLoading, CardTable, Card},
    props: {
      pessoa: Object
    },

    data() {
      return {
        atos: [],
        loading: false,
        atribuicao: null
      }
    },

    computed: {

      atribuicoes() {
        let atribuicoes = [];
        if(this.$root.isRI){
          atribuicoes.push({id: 'ri', nome: 'RI'});
        }
        if(this.$root.isRTD){
          atribuicoes.push({id: 'rtd', nome: 'RTD'});
        }
        if(this.$root.isRCPJ){
          atribuicoes.push({id: 'rcpj', nome: 'RCPJ'});
        }
        return atribuicoes;
      },
    },

    async mounted() {
      this.atribuicao = this.atribuicoes[0].id;
      await this.load();
    },

    methods: {
      getPapel(papel){ return businessTipoServico.nomePapel(papel) },
      getCodigo: AtoBusiness.getCodigo,
      getFicha: FrontBusiness.nomearFicha,

      async load() {
        if (this.loading) return
        this.loading = true;
        this.atos = [];
        this.atos = await IndicadorPessoalBusiness.buscarAtosRegistrados(this.pessoa.indicadorPessoalId, this.atribuicao);
        this.loading = false;
      },

      nomearProtocolo(dominio, tipoProtocolo, codigo){
        return FrontBusiness.nomearProtocolo(dominio, tipoProtocolo, codigo);
      },

      async verAto(ato) {
        // let ficha = await IndicadorRealBusiness.getById(ato.ficha.id);
        FrontBusiness.openModal(DetalhesAtoRegistrado, {item: ato, ficha: ato.ficha});
      },

      verFicha(ficha) {
        FrontBusiness.openModal(IndicadorBusiness.getDetalhesIndicador(ficha.livro), {id: ficha.id});
      },

      async imprimirAtos(atribuicao) {
        this.$set(this, 'loading', true);

        let url = `api/relatorio/relatorio-atos-indicador-real/${atribuicao}/${this.pessoa.id}`

        await RelatorioBusiness.visualizarPdf(url, "Relatório")

        this.$set(this, 'loading', false);
      }
    }
  }
</script>

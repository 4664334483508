<template>

  <card id="cardVersao" :titulo="titulo">
    <template #acoes>
      <a href v-if="aba != 'outros'" class="icon float-right pointer" @click.prevent="trocarAba('outros')">Outros Documentos</a>
      <a href v-if="aba != 'duplicados'" class="icon float-right pointer" @click.prevent="trocarAba('duplicados')">Documentos Duplicados</a>
    </template>
    <div class="div-select-all" style="top: 38px;">
      <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
        <i class="ml-2 fal fa-check-circle"></i>
      </a>
      <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
        <i class="ml-2 fal fa-circle"></i>
      </a>
    </div>

    <v-input v-if="aba === 'outros'" class="w-100" style="margin-top: -20px;" label="Pesquisar Versões">
      <buscar-pessoa @add="selecionarVersao($event)" :filtros="{tipoPessoa: this.pessoa.tipoPessoa, versao: true, disabledFiltros: ['tipoPessoa', 'versao']}" :novo="false"/>
    </v-input>

    <card-table class="table-borderless mb-2" :dragscroll="true" :cardClassName="cardClassName" v-if="lista.length > 0">
      <thead>
        <tr>
        <th v-if="aba === 'outros'"></th>
        <th></th>
        <th>Atos</th>
        <th>Detalhes</th>
        <th v-for="(title, index) in titles" :key="index">{{title}}</th>

        <th>ID</th>
        <th>Pessoa</th>
        <th>Documento IP</th>

      </tr>
      </thead>

      <tbody>

      <tr v-for="(versao, i) in lista" :key="versao.id">

        <td v-if="aba === 'outros'">
          <a href="#" class="icon" @click.prevent="remove(i)" title="Remover">
            <i class="fa fa-times"></i>
          </a>
        </td>
        <td>
          <a href="#" class="icon" @click.prevent="selecionar(versao)">
            <i class="far" :class="{'fa-check-circle text-green' : versao.selecionado, 'fa-circle' : !versao.selecionado}"></i>
          </a>
        </td>

        <td width="40px" class="text-center">
          <a title="Ver Atos" class="pointer" @click.prevent="openAtosVersao(versao)"><i class="fal fa-eye"></i></a>
        </td>

        <td class="text-center">
          <a href class="pointer" @click.prevent="detalhes(versao)">
            <i class="fas fa-home"></i>
          </a>
        </td>

        <td>{{versao.versao}}</td>
        <td>{{versao.nome}}</td>
        <td>{{versao.documento}}</td>

        <template v-if="pessoa.tipoPessoa == 'FISICA'">
          <td class="text-center">{{versao.expostaPoliticamente ? 'Sim' : 'Não'}}</td>
          <td class="text-center">{{versao.falecido ? 'Sim' : 'Não'}}</td>
          <td>{{labelSexo(versao.sexo)}}</td>
          <td>{{$formatters.date(versao.nascimento)}}</td>
          <td>{{labelNacionalidade(versao.nacionalidade)}}</td>
          <td>{{versao.profissao}}</td>
          <td>{{versao.nomePai}}</td>
          <td>{{versao.nomeMae}}</td>
          <td>{{labelEstadosCivil(versao.estadoCivil)}}</td>
          <td class="text-center">{{versao.possuiUniaoEstavel ? 'Sim' : 'Não'}}</td>
          <td>{{versao.conjugeNome}}</td>
          <td class="text-center">{{versao.casamentoAposLei6515 ? 'Sim' : 'Não'}}</td>
          <td class="text-center">{{$formatters.date(versao.dataCasamento)}}</td>
          <td>{{labelRegimeCasamento(versao.regimeCasamento)}}</td>
          <td>{{versao.detalhesRegime}}</td>
          <td>{{versao.rg}}</td>
          <td>{{versao.cnh}}</td>
          <td>{{versao.cip}}</td>
          <td>{{versao.passaporte}}</td>
          <td>{{versao.outrosDocumentos}}</td>
        </template>

        <template v-if="pessoa.tipoPessoa == 'JURIDICA'">
          <td>{{versao.inscricaoEstadual}}</td>
          <td>{{versao.inscricaoMunicipal}}</td>
          <td class="text-center">{{versao.possuiParticipacaoEstrangeira ? 'Sim' : 'Não'}}</td>
        </template>

        <td>{{versao.cep}}</td>
        <td>{{versao.logradouro}}</td>
        <td>{{versao.numero}}</td>
        <td>{{versao.lote}}</td>
        <td>{{versao.quadra}}</td>
        <td>{{versao.bairro}}</td>
        <td>{{versao.cidade}}</td>
        <td>{{versao.estado}}</td>
        <td>{{versao.complemento}}</td>
        <td>{{labelPais(versao.pais)}}</td>

        <template v-if="pessoa.tipoPessoa == 'FISICA'">
          <td>{{versao.cepComercial}}</td>
          <td>{{versao.logradouroComercial}}</td>
          <td>{{versao.numeroComercial}}</td>
          <td>{{versao.loteComercial}}</td>
          <td>{{versao.quadraComercial}}</td>
          <td>{{versao.bairroComercial}}</td>
          <td>{{versao.cidadeComercial}}</td>
          <td>{{versao.estadoComercial}}</td>
          <td>{{versao.complementoComercial}}</td>
          <td>{{labelPais(versao.paisComercial)}}</td>
        </template>

        <td>{{versao.id}}</td>
        <td>{{versao.indicadorPessoalId}}</td>
        <td>{{versao.indicadorPessoalDocumento}}</td>

      </tr>

      </tbody>

    </card-table>


    <small-loading v-if="loading"></small-loading>

    <div v-if="!lista.length && !loading" class="alert alert-outline-info">
      Não há versões para exibir
    </div>


  </card>

</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import AtosVersao from "@/components/IndicadorPessoal/Detalhes/AtosVersao";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";

  export default {

    name: "Duplicados",
    components: {SmallLoading, CardTable, Card},
    computed: {
      selecionados(){
        return this.lista.filter(e => e.selecionado).map(e => e.id);
      },
      lista(){
        return this.aba === 'duplicados' ? this.versoes : this.outros;
      },
      titulo(){
        return this.aba === 'duplicados' ? 'Documentos Duplicados' : 'Outros Documentos';
      }
    },

    props: {
      pessoa: Object
    },

    data() {

      let title = ['Versão', 'Nome'];
      if (this.pessoa.tipoPessoa == 'FISICA') {
        title.push('CPF', 'Pessoa Exposta Politicamente', 'Pessoa Falecida','Sexo', 'Data de Nascimento', 'Nacionalidade', 'Profissão', 'Nome do Pai', 'Nome da Mãe', 'Estado Civil', 'União Estável',
          'Cônjuge/Convivente', 'Após Lei nª 6.515', 'Data Casamento/União Estável', 'Regime', 'Detalhes do regime',
          'RG', 'CNH', 'Identidade Profissional', 'Passaporte', 'Outros Documentos');
      } else {
        title.push('CNPJ','Inscrição Estadual','Inscrição Municipal', 'Participação Estrangeira');
      }

      title.push('CEP', 'Logradouro (Rua)', 'Número', 'Quadra', 'Lote', 'Bairro (Setor)', 'Cidade', 'Estado', 'Complemento', 'País');

      if (this.pessoa.tipoPessoa == 'FISICA') {
        title.push('CEP Comercial', 'Logradouro (Rua) Comercial', 'Número Comercial', 'Quadra Comercial', 'Lote Comercial', 'Bairro (Setor) Comercial', 'Cidade Comercial', 'Estado Comercial', 'Complemento Comercial', 'País');
      }

      return {
        versoes: [],
        outros: [],
        cardClassName: {'card-table-scroll': true},
        titles: title,
        loading: true,
        aba : 'duplicados',
      }
    },

    async mounted() {
      await this.load();
    },
    methods: {
      async load() {
        this.versoes = await IndicadorPessoalBusiness.getDocumentosDuplicados(this.pessoa.indicadorPessoal.id);
        this.$set(this, 'outros', this.outros.filter(o => !o.selecionado));
        this.loading = false;
      },

      async merge(){
        let selecionados = this.selecionados;
        let result = await IndicadorPessoalBusiness.mergeDocuments(this.pessoa.indicadorPessoal.id, selecionados).catch(this.showError);
        if(result){
            FrontBusiness.showSuccess('', 'Correção realizada com sucesso');
            this.load();
        }


      },

      selecionar(versao, value) {
        this.$set(versao, 'selecionado', value != undefined ? value : !versao.selecionado);
      },

      async remove(i){
        this.outros.splice(i, 1);
      },

      async selecionarVersao(indicador) {
        let versao = await IndicadorPessoalBusiness.getVersaoById(indicador.id);
        if (versao) {
          if (!this.outros.find(v => v.id === versao.id) && this.pessoa.indicadorPessoalId !== versao.indicadorPessoalId) {
            versao.conjugeNome = versao.conjuge?.nome;
            versao.indicadorPessoalDocumento = versao.indicadorPessoal?.documento;
            this.outros.push(versao);
          }
        }
      },

      async openAtosVersao(versao = {}) {
        await FrontBusiness.openModal(AtosVersao, {id: versao.id, versao: versao});
      },

      labelSexo(item) {
        return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getSexos());
      },

      labelEstadosCivil(item) {
        return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getEstadosCivil());
      },

      labelRegimeCasamento(item) {
        return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getRegimes());
      },

      labelNacionalidade(item) {
        return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getNacionalidades());
      },

      labelPais(idNacionalidade) {
        return IndicadorPessoalBusiness.getLabelPais(idNacionalidade);
      },

      getPessoa(versao){
        const pessoa = {
          indicadorPessoalId: versao.indicadorPessoalId,
          indicadorPessoalVersaoId: versao.id,
          documento: versao.documento,
          nome: versao.nome,
        };
        return pessoa;
      },

      detalhes(versao) {
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: versao.indicadorPessoalId, versao: versao.id});
      },

      selecionarTodos(limpar) {
        this[this.aba === 'duplicados' ? 'versoes' : 'outros'].forEach((v) => this.selecionar(v, !limpar));
      },

      showError(e) {
          FrontBusiness.showError('', e.message);
      },

      trocarAba(aba) {
        this.$set(this, 'aba', aba);
        this.$set(this, 'outros', []);
        this.selecionarTodos(true);
      },
    }
  }
</script>

<style lang=scss>
  #cardVersao {
    h3 {
      margin-bottom: 30px;
    }
  }
  .card-table-scroll {
    padding-bottom: 5px !important;
    overflow: auto !important;

    table {
      width: max-content;

      th, td {
        padding: 0.75rem 1rem;
      }
    }
  }
  .div-select-all {
    position: absolute;
    top: 8px;
    right: 17px;
  }
</style>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import CalcularPorcentagem from "@/views/Protocolo/Atos/Ato/CalcularPorcentagem";
  import Utils from "@/commons/Utils";

  export default {

    name: "Proprietários",

    components: {CardTable, Card},

    props: ['proprietarios', 'ficha'],

    data() {
      return {}
    },

    computed: {
      showArea() {
        return this.ficha.medidaArea && this.ficha.area
      },
      unidadeArea(){
        return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
      },

      totalProprietarios() {
        return this.proprietarios.reduce((total, e) => total + parseFloat(e.fracao || 0), 0);
      },

      totalProprietariosArea() {
        return this.proprietarios.reduce((total, e) => total + parseFloat(e.area || 0), 0);
      },
    },

    methods: {
      remover(idx) {
        this.proprietarios.splice(idx, 1);
      },

      atualizar(i, envolvido) {
        this.remover(i, false);
        this.proprietarios.splice(i, 0, envolvido);
      },

      async editar(envolvido, i) {
        if(envolvido.indicadorPessoalVersao?.id){
          let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: envolvido.indicadorPessoalVersao?.id});
          if (retorno) {
            this.atualizar(i, {
              fracao: this.proprietarios[i].fracao || 0,
              area: this.proprietarios[i].area || 0,
              indicadorPessoalVersao: retorno,
            });
          }
        }
      },

      async selecionarEnvolvido(envolvido, fracao = 0, area = 0) {
        if(envolvido?.id) {
          let ordens = this.proprietarios.map(p => p.ordem);
          let ordem = Math.max(...ordens)
          this.proprietarios.push({
            indicadorPessoalVersao: envolvido,
            fracao: fracao,
            area,
            ordem: ordem + 1,
          });
        }
      },

      detalhes(pessoa, versao) {
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao});
      },

      async calcularPorcentagem() {
        let envolvidos = await FrontBusiness.openModal(CalcularPorcentagem, {
          ficha: this.ficha,
          proprietarios: Utils.clone(this.proprietarios)
        });
        if (envolvidos) {
          this.$emit('setProprietario', envolvidos);
        }
      },

      calcularFracao(e) {
        e.fracao = ((e.area || 0) * 100 / this.ficha.area).toFixed(2).toString();
      },
    }

  }
</script>

<template>

  <card titulo="Proprietários">
    <template #acoes>
      <div class="d-flex float-right"  style="align-items: center; margin-top: -10px;">
        <v-button :run="calcularPorcentagem" :button="false" class="btn-outline-primary btn-sm btn small float-right mr-2">
          <i class="fas fa-calculator" title="Calcular Porcentagem"></i> Calcular Porcentagem
        </v-button>
      </div>
    </template>

    <card-table class="table-borderless">

      <thead v-if="proprietarios.length">
      <tr>
        <th width="20px"></th>
        <th class="pl-0">Pessoa</th>
        <th width="150px" v-if="showArea">{{unidadeArea}}</th>
        <th width="150px">Fração</th>
        <th width="20px"></th>
      </tr>
      </thead>

      <tbody v-if="!proprietarios.length">

      <tr>
        <td colspan="100%" class="p-0">
          <div class="alert alert-outline-secondary alert-sm mb-0">
            Nenhum proprietário cadastrado
          </div>
        </td>
      </tr>

      </tbody>

      <tbody v-if="proprietarios.length">

        <tr v-for="(proprietario, i) in proprietarios" :key="i">

          <td class="text-left mover">
            <div class="ato-action">
              <a @click.prevent="editar(proprietario, i)" class="icon"><i class="fal fa-edit"></i></a>
            </div>
          </td>

          <td class="pl-0 mover">
            <template  v-if="proprietario.indicadorPessoalVersao">
              <div>
                <a href class="mb-1 pointer icon" @click.prevent="detalhes(proprietario.indicadorPessoalVersao, proprietario.indicadorPessoalVersao.id)">
                  {{proprietario.indicadorPessoalVersao.nome}}</a>
              </div>

              <div class="small text-muted">
                {{proprietario.indicadorPessoalVersao.documento}}
              </div>
            </template>
          </td>


          <td v-if="showArea">
            <div class="d-flex">
              <v-input v-model="proprietario.area" type="currency" placeholder="Área" :input-props="{precision : {min : 2, max : 10}}"/>
              <div class="ato-action float-left mt-1 ml-2">
                <a href title="Calcular Fração" class="icon small" @click.prevent.stop="calcularFracao(proprietario)">
                  <i class="fal fa-calculator"></i>
                </a>
              </div>
            </div>
          </td>

          <td>
            <v-input v-model="proprietario.fracao" type="currency" placeholder="Fração" :input-props="{precision : {min : 2, max : 10}}"/>
          </td>

          <td class="text-right">
            <div class="ato-action">
              <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
            </div>
          </td>

        </tr>

      </tbody>

      <tfoot>
        <tr v-if="proprietarios.length" style="color: #FEE17D; font-weight: bold;">

          <td colspan="2" class="text-right">
            Total:
          </td>

          <td v-if="showArea">
            {{$formatters.currency(totalProprietariosArea,{min : 2, max : 10})}}{{unidadeArea}}
          </td>

          <td>
            {{$formatters.currency(totalProprietarios,{min : 2, max : 10})}}%
          </td>

          <td class="text-right">
          </td>

        </tr>
      <tr>

        <td colspan="5" class="pr-0">
          <buscar-pessoa @add="selecionarEnvolvido($event)" :novo="true" />
        </td>

      </tr>

      </tfoot>

    </card-table>

  </card>

</template>

import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/natureza';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getAtivo(){
    return Http.get(`${path}/getAtivo`);
  },

  async getAllSimpleList() {
    return Http.get(`${path}/all-simple`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByDominio(id){
    return Http.get(`${path}/dominio/${id}`);
  },

  async getByDominioSimplesIds(dominio, ids){
    return Http.post(`${path}/dominio-simples/${dominio}`, ids).then(l => l.map(i => {
      i.tiposProtocolo = JSON.parse(i.tiposProtocolo);
      return i;
    }));
  },

  async getByDominioSimples(dominio){
    return Http.get(`${path}/dominio-simples/${dominio}`).then(l => l.map(i => {
      i.tiposProtocolo = JSON.parse(i.tiposProtocolo);
      return i;
    }));;
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

}

<template>
  <modal titulo="Acompanhamento Registral" :closeable="!rodando">

    <card title="Enviando atualizações">
      <card-content>

        <p class="text-center" v-html="processo" v-if="processo"></p>
        <p class="text-center" v-else>
          <template v-if="rodando">
            <span>Processando: {{contador.atual}} / {{contador.total}}</span>
            <small-loading :loading="true" style="display: inline-block; margin-top: 5px; margin-bottom: 0 !important;" color="text-white" class="ml-2 mb-1">
            </small-loading>
          </template>
        </p>
      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" :disabled="rodando"
              class="btn btn-primary ml-auto mr-auto">
        Fechar
      </button>
    </template>

  </modal>
</template>

<script>
import ArispBusiness from "@/business/ArispBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal.vue";
import SmallLoading from "@/components/SmallLoading.vue";
import AuditoriaBusiness from "@/business/AuditoriaBusiness";

export default {
    name: "ArispAcompanhamentoRegistral",
    components: {SmallLoading, CardContent, Card, Modal},
    props: {
    },

    modal:{
      width: 800,
      escapable: false
    },

    data() {
      return {
        contador:{
          total: 0,
          atual: 0
        },
        processo: null,
        pedidos : null,
        erros: [],
        rodando: false
      }
    },

    mounted() {
      this.iniciar();
    },

    methods: {

      close(){
        FrontBusiness.closeModal();
      },

      async mostrarErro(pedido, e){
        this.erros.push(`<b>Erro: </b> ${e?.message || e}`);
        return null;
      },

      async iniciar(){

        this.$set(this, 'rodando', true);
        this.contador.atual = 0;

        if(this.$root.usuarioUtilizaCertificadoTerceiros){

          this.processo = "Testando certificado";
          let testeCertificado = await ArispBusiness.testarCertificado().catch(e => this.mostrarErro(null, e));

          if(!testeCertificado){
            this.$set(this, 'rodando', false);
            return;
          }

          let r = await ArispBusiness.iniciarTokens().catch(e => this.mostrarErro(null, e));

          if(!r){
            this.$set(this, 'rodando', false);
            return;
          }

        }

        this.processo = "Buscando protocolos para atualização";
        let retorno = await ArispBusiness.getAgendamentosAguardandoEnvio().catch(e => {
          return false;
        });

        if(!retorno?.length){
          this.processo = "Nenhum protocolo encontrado";
          this.$set(this, 'rodando', false);
          return;
        }

        this.contador.total = retorno.length;

        let promisses = [];

        this.processo = null;
        for(let pedido of retorno){
          if(this.rodando){
            ++this.contador.atual;
            promisses.push(ArispBusiness.atualizarProtocolo(pedido.id).catch(e => null));
            await FrontBusiness.sleep(1000);
          }
        }

        await Promise.all(promisses);


        AuditoriaBusiness.auditarAcao({entidade: AuditoriaBusiness.enumEntidadeExecucao({entidade: 'EXECUCAO_ARISP', dominio: this.dominio}), descricao: `Acompanhamento Registral` })
          .catch(e => null);

        this.processo = "Não há mais pedidos para atualizar";
        this.$set(this, 'rodando', false);

      }
    }

  }
</script>

<template>
  <div class="col-12">
    <div style="max-width: 13px;float: right;padding-right: 0px;" class="col-1">
      <a class="icon" href="" @click.prevent="adicionarArea()">
        <i class="fa fa-plus-square"></i>
      </a>
    </div>

    <template v-for="(area, i) in areas">
      <div :key="i" class="form-group col-11" style="float: left;margin-left: -15px;max-width: 100%;padding-right: 0px">

        <div class="text-right col-12" style="padding-right: 0px;">
          <a class="icon" href="" @click.prevent="removerArea(i)">
            <i class="fa fa-times"></i>
          </a>
          <hr style="margin: 0px"/>
        </div>
        <template v-if="tipo !== 'confrontacoes'">

          <card titulo="Tipo" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" v-if="tipo === 'areas'">
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect(false)" label="Tipo" v-model="area.tipo.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.tipo.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" style="width: 583px" :class="{'novo': novo}" class="col-auto mr-1 float-left" v-if="['C'].includes(area.tipo.tipo)"
                                    :model.sync="area.tipo.coluna1" :colunas="colunas" :valor="area.tipo.coluna1" @change="onChange()"/>

              <div v-if="(area.tipo.tipo || '').includes('F')" style="width:583px" :class="{'novo': novo}" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.tipo.fixo" type="select" :options="tipos"  @change="onChange()"/>
              </div>

            </div>
          </card>

          <card titulo="Descrição" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" >
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.descricao.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.descricao.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.descricao.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.descricao.tipo)"
                                    :model.sync="area.descricao.coluna1" :colunas="colunas" :valor="area.descricao.coluna1" @change="onChange()"/>

              <div v-if="(area.descricao.tipo || '').includes('F')" :style="{ width: area.descricao.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.descricao.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha  :class="{'novo2': novo}" :label="area.descricao.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.descricao.tipo)" :model.sync="area.descricao.coluna2" :colunas="colunas" :valor="area.descricao.coluna2" @change="onChange()"/>
            </div>
          </card>

          <card titulo="Unidade" class="m-0 mt-1 w-100" :class="{'border-list': !novo}"  v-if="tipo === 'unidadesAcessorias'">
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.unidade.tipo" class="col-auto mr-1" style="width: 150px;"/>
              <SelectColunaPlanilha :label="area.unidade.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.unidade.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' : novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.unidade.tipo)"
                                    :model.sync="area.unidade.coluna1" :colunas="colunas" :valor="area.unidade.coluna1" @change="onChange()"/>

              <div v-if="(area.unidade.tipo || '').includes('F')" :style="{ width: area.unidade.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' : novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left">
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.unidade.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha :class="{'novo2': novo}" :label="area.unidade.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.unidade.tipo)" :model.sync="area.unidade.coluna2" :colunas="colunas" :valor="area.unidade.coluna2" @change="onChange()"/>
            </div>
          </card>

          <card titulo="Complemento" class="m-0 mt-1 w-100" :class="{'border-list': !novo}"  v-if="tipo === 'unidadesAcessorias'">
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.complemento.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.complemento.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.complemento.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' : novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.complemento.tipo)"
                                    :model.sync="area.complemento.coluna1" :colunas="colunas" :valor="area.complemento.coluna1" @change="onChange()"/>

              <div v-if="(area.complemento.tipo || '').includes('F')" :style="{ width: area.complemento.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' : novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left" :class="{'novo': novo}" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.complemento.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha :class="{'novo2': novo}" :label="area.complemento.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.complemento.tipo)" :model.sync="area.complemento.coluna2" :colunas="colunas" :valor="area.complemento.coluna2" @change="onChange()"/>
            </div>
          </card>

          <card titulo="Área" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" >
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect(false)" label="Tipo" v-model="area.area.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha label="Coluna" style="width: 583px" :class="{'novo': novo}" class="col-auto mr-1 float-left" v-if="['C'].includes(area.area.tipo)"
                                    :model.sync="area.area.coluna1" :colunas="colunas" :valor="area.area.coluna1" @change="onChange()"/>

              <div v-if="(area.area.tipo || '').includes('F')" style="width: 583px" :class="{'novo': novo}" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" :precision="decimais" v-model="area.area.fixo" type="currency" :input-props="{precision : {min : 2, max : 10}}" @change="onChange()"/>
              </div>
            </div>
          </card>

        </template>

        <template v-else>
          <card titulo="Confrontação" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" >
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.confrontacao.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.confrontacao.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.confrontacao.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.confrontacao.tipo)"
                                    :model.sync="area.confrontacao.coluna1" :colunas="colunas" :valor="area.confrontacao.coluna1" @change="onChange()"/>

              <div v-if="(area.confrontacao.tipo || '').includes('F')" :style="{ width: area.confrontacao.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.confrontacao.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha  :class="{'novo2': novo}" :label="area.confrontacao.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.confrontacao.tipo)" :model.sync="area.confrontacao.coluna2" :colunas="colunas" :valor="area.confrontacao.coluna2" @change="onChange()"/>
            </div>
          </card>
          <card titulo="Metragem" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" >
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.metragem.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.metragem.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.metragem.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.metragem.tipo)"
                                    :model.sync="area.metragem.coluna1" :colunas="colunas" :valor="area.metragem.coluna1" @change="onChange()"/>

              <div v-if="(area.metragem.tipo || '').includes('F')" :style="{ width: area.metragem.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.metragem.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha  :class="{'novo2': novo}" :label="area.metragem.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.metragem.tipo)" :model.sync="area.metragem.coluna2" :colunas="colunas" :valor="area.metragem.coluna2" @change="onChange()"/>
            </div>
          </card>
          <card titulo="Confrontante" class="m-0 mt-1 w-100" :class="{'border-list': !novo}" >
            <div class="row no-gutters w-100">
              <v-input type="select" default="C" :options="tipoSelect()" label="Tipo" v-model="area.confrontante.tipo" class="col-auto mr-1" style="width: 150px;"/>

              <SelectColunaPlanilha :label="area.confrontante.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: area.confrontante.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(area.confrontante.tipo)"
                                    :model.sync="area.confrontante.coluna1" :colunas="colunas" :valor="area.confrontante.coluna1" @change="onChange()"/>

              <div v-if="(area.confrontante.tipo || '').includes('F')" :style="{ width: area.confrontante.tipo.length > 1 ? novo ? '264.75px' :  '289.75px' :  novo ? '534px' :  '583px' }" class="col-auto no-gutters mr-1 float-left" >
                <v-input label="Fixo" class="w-100 form-group float-left" v-model="area.confrontante.fixo" type="text" @change="onChange()"/>
              </div>

              <SelectColunaPlanilha  :class="{'novo2': novo}" :label="area.confrontante.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 289.75px" v-if="['FC', 'CC'].includes(area.confrontante.tipo)" :model.sync="area.confrontante.coluna2" :colunas="colunas" :valor="area.confrontante.coluna2" @change="onChange()"/>
            </div>
          </card>
        </template>

      </div>
    </template>

    <div v-if="areas.length === 0" class="alert alert-warning alert-sm mb-0 mt-4">
      Nenhuma área cadastrada
    </div>
  </div>
</template>

<script>

  import Card from "@/components/Layout/components/Card";

  export default {
    components: {
      SelectColunaPlanilha: () => import("@/components/IndicadorReal/Detalhes/ImportacaoUnidades/SelectColunaPlanilha"),
      Card,
    },
    props : {
      novo : {type : Array, required : false},
      areas : {type : Array, required : true},
      possuiTipo : {type : Boolean, default : true},
      visualizar: {type : Boolean, default : false},
      titulo : String,
      tipo : String,
      colunas: Array,
    },

    name: "DetalharAreasPlanilha",
    data(){
      return {
        decimais : 10,
        loading: false,
        item: {
          fixo: null,
          coluna1: null,
          coluna2: null,
          tipo: 'C',
        },
        tipos: [
          {id: 'COMUM', nome: 'Comum'},
          {id: 'PRIVATIVA', nome: 'Privativa'},
          {id: 'OUTRAS', nome: 'Outras Áreas'},
        ]
      }
    },

    watch: {
      colunas: function () {
        this.$nextTick(async () => {
          const area = Object.assign({}, this.area);
          this.loading = true;
          setTimeout(()=>{
            this.loading = false;
            this.area = area;
          },5);
        });
      },
    },

    computed:{
      total(){
        return this.areas.reduce((acc, cur) => acc + cur.area, 0.00);
      },

    },

    methods:{


      tipoSelect(concatenar = true) {
        let tipos = [
          {id: 'C', nome: 'Coluna'},
          {id: 'F', nome: 'Fixo'}
        ]
        if (concatenar) {
          tipos = [...tipos, ... [{id: 'FC', nome: 'Fixo + Coluna'},
            {id: 'CF', nome: 'Coluna + Fixo'},
            {id: 'CC', nome: 'Coluna + Coluna'}]]
        }
        return tipos
      },

      removerArea(index) {
        this.$nextTick(() => {
          if (this.areas && this.areas.length) {
            this.areas.splice(index, 1);
          }
        });
      },

      adicionarArea(){
        this.$nextTick(() => {
          this.areas.push({tipo: {tipo: 'F', fixo: 'PRIVATIVA'}, unidade: {...this.item}, complemento: {...this.item},
            descricao : {...this.item},
            area : {...this.item},
            confrontacao : {...this.item},
            metragem : {...this.item},
            confrontante : {...this.item}});
        });
      },

      onChange(){
        this.$emit('update:value', this.areas);
        this.$emit('change', this.areas);
      },

    }

  }
</script>

<style scoped lang="css">
  .theme-dark #modals-container .border-list {
    border-color: #363b3e !important;
  }

  .novo {
    width: 534px !important;
  }

  .novo2 {
    width: 264.75px !important;
  }

</style>

import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import VueBusiness from "@/business/VueBusiness.js";
import Utils from "@/commons/Utils";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness";
import TipoServicoBusiness from "@/views/Administracao/modulos/tipo-servico/business";
import LivroMatriculaBusiness from "@/business/livros/LivroMatriculaBusiness";
import TextParser from "@/business/livros/TextParser";
import atoBusiness from "@/business/protocolo/AtoBusiness";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";

export default {

  async gerarMinutaLote(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'gerar-minuta', null, usuarioLogado);
    }

    return {atos, ficha};

  },

  async gerarMinuta(protocolo, ficha, atos, documentos = [], ctrl = {}, ato, usuarioLogado = {} ){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      if(ato?.id == atos[i]?.id){
        atos[i].templateMinuta = ato.texto;
      }
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, ato?.id == atos[i]?.id ? 'gerar-minuta' : 'processar', null, usuarioLogado);
      if(ato == atos[i]?.id){
        return {atos, ficha};
      }
    }

    return {atos, ficha};

  },

  async simular(protocolo, ficha, atos, documentos = [], ctrl = {}, usuarioLogado){

    ficha.ctrl = {
      ultimoAto: ficha.ultimoAto,
      status: ficha.status
    }

    for(let i in atos){
      ++ctrl.atual;
      await this.simularAto(atos[i], i, protocolo, ficha, documentos, 'completo', usuarioLogado);
    }

    return {atos, ficha};

  },

  async simularAto(ato, posicao, protocolo, ficha, documentos, modo = 'completo', usuarioLogado){
    ato.envolvidos = ato?.envolvidos || [];
    let simulacaoCompleta = modo == 'completo' || modo == 'gerar-minuta';
    ato.erros = [];

    if(ficha.status == 'PENDENTE'){
      ficha.prematricula = true;
    }

    let validacoesDocumentos = await IndicadorAbstractService.validarIndicadorDocumentos(ato.id, ato.indicador.id);
    if(validacoesDocumentos?.length){
      ato.erros.push(...validacoesDocumentos);
    }

    const criarErro = (msg, area = '', tipo = 'erro') => {
      ato.erros.push({area, msg, tipo});
    };

    //atualiza ato (para minuta e demais acoes)
    ato.ficha = Utils.clone(ficha);
    ato.codigo = (ficha.status == 'PENDENTE' || (ato.tipo || ato.tipoServico?.opcoes?.tipoAto) === 'ABERTURA') ? 0 : (parseInt(ficha.ultimoAto || 0,10) + 1);

    let tipo = ato.tipoServico.opcoes.tipoAto == 'REGISTRO' ? 'R.' : 'Av.';
    ato.codigoStr = ato.tipoServico.opcoes.tipoAto == 'ABERTURA' ? 'M.0' : tipo + ato.codigo;
    ficha.ultimoAto = ato.codigo;

    const motivoIsencao= VueBusiness.getVueRoot().isEstadoCE ? ato.ressarcimento?.motivo : ato.ressarcimento?.motivoIsencao;

    if(ato.isento && (Utils.isEmptyNullOrUndefined(motivoIsencao))){
      if(VueBusiness.getVueRoot().isEstadoSC){
        if(Utils.isEmptyNullOrUndefined(ato.ressarcimento?.tipoProcesso) || (ato.ressarcimento?.tipoProcesso != 'NAO_RESSARCIVEL' && Utils.isEmptyNullOrUndefined(ato.ressarcimento?.numeroProcesso))){
          criarErro(`Necessário preencher os dados de ressarcimento.`, null,  'erro');
        }
      }

      if(VueBusiness.getVueRoot().isEstadoMG || VueBusiness.getVueRoot().isEstadoCE){
        criarErro(`Necessário preencher os dados de ressarcimento.`, null,  VueBusiness.getVueRoot().isEstadoMG ? 'erro' : 'alerta');
      }
    }

    if (VueBusiness.getVueRoot().isEstadoPE) {
      if (Utils.isEmptyNullOrUndefined(ato.guia)){
        criarErro(`Necessário informar o número da Guia SICASE`, null,  'erro');
      }
    }

    await atoBusiness.validarAto(protocolo.id,ato.livro, ato.id, simulacaoCompleta, modo).then(alertas=>{
      alertas.map(a=>{
        criarErro(a.message,a.area, a.tipo);
      });
    });

    for(let envolvido of (ato.envolvidos || [])){
      envolvido.indicadorPessoalVersao = await IndicadorPessoalBusiness.getVersaoByIdComCache(envolvido.indicadorPessoalVersaoId);

      // if(envolvido.indicadorPessoalVersao.tipoPessoa == 'FISICA'){
      //   let pep = await PessoaExpostaPoliticamenteBusiness.getByDocumento(envolvido.documento);
      //   if (pep || envolvido.indicadorPessoalVersao.expostaPoliticamente) {
      //     criarErro("A parte envolvida " + envolvido.nome + " é uma pessoa exposta politicamente", null,  'warning');
      //   }
      // }

      if(VueBusiness.getVueRoot().isEstadoSC){
        let campos = IndicadorPessoalBusiness.validarEndereco(envolvido.indicadorPessoalVersao);
        if(campos?.length){
          criarErro(`O endereço do envolvido ${envolvido.nome} está incompleto: ${FrontBusiness.formatArray(campos)}`, '', 'warning');
        }
      }

    }

    (ato.tipoServico.opcoes.papeisObrigatorios || []).forEach(papel => {
      if(!ato.envolvidos.find(e => e.papel == papel)){
        criarErro(`Necessário informar uma parte envolvida como ${TipoServicoBusiness.nomePapel(papel)}`, 'envolvidos', 'erro');
      }
    });

    //verifica exigencia
    if (ato?.exigencias?.length) {
      criarErro("O ato possui exigências", 'exigencia', 'erro');
    }


    if (simulacaoCompleta && !ato?.indices?.respondidos || ato?.indices?.respondidos < ato?.indices?.quantidade) {

      if(!ato.legado){

        // INÍCIO: Montar Contador por Grupo de Checklist
        const item = await AtoBusiness.getById(protocolo.id, ato.id);

        const vinculos = {documentos};
        if (item.envolvidos) {
          vinculos.envolvidos = item.envolvidos;
        }

        let resultados = (item.checklist || []).map(checklist => {
          return ChecklistProcessamentoBusiness.tratarGrupoChecklist({requisitos : checklist.checklist, id: checklist.id, titulo: checklist.titulo}, {...item.indices}, vinculos)
        });

        resultados = await Promise.all(resultados);

        for(let checklist of (resultados || [])){
          if(checklist.contador.quantidade > 0 && checklist.contador.respondidos < checklist.contador.quantidade){
            let msgErro = 'Checklist está incompleto: ';
            let titulo = checklist.titulo || checklist.nome;
            let area = `checklist-${checklist.id}.checklist-group .nao-respondido`;
            if(VueBusiness.getVueRoot().getPermission({id: 'PERMITIR_REGISTRAR_CHECKLIST_INCOMPLETO_PROTOCOLO', dominio: protocolo?.dominio || 'PROTOCOLO_RI'})){
              criarErro(msgErro + titulo, area, 'alerta');
            }else{
              criarErro(msgErro + titulo, area, 'erro');
            }
          }
        }

      }

    }

    //ENCERRAMENTO DE MATRICULA
    if(['CANCELAMENTO', 'ENCERRAMENTO'].includes(ato.tipoServico.opcoes.operacaoIndicador)){
      let msg = `Este ato ${ato.tipoServico?.opcoes?.operacaoIndicador == 'CANCELAMENTO' ? 'cancelará' : 'encerrará'} o ${ficha.status == 'PENDENTE' || ficha.prematricula? 'pré-' : ''}${FrontBusiness.nomearLivro(ficha.livro).toLowerCase()}.`
      criarErro(msg, null,  'warning');
      ficha.status = ato.tipoServico.opcoes.operacaoIndicador == 'CANCELAMENTO' ? 'CANCELADA' : 'ENCERRADA';
    }

    //gerar minuta
    ato.debug = {};
    if(simulacaoCompleta){
      if(modo == 'gerar-minuta' || !ato?.tipoServico?.opcoes?.editarMinuta){
        ato.minuta = await LivroMatriculaBusiness.gerarMinutaAto(Utils.clone(ato), Utils.clone(ficha), Utils.clone(protocolo), Utils.clone(documentos), usuarioLogado);
      }else{
        ato.minuta = ato.texto;
      }

      const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
      if(minutaInvalida){
        criarErro(minutaInvalida, 'minuta', 'erro');
      }
    }



    const minutaInvalida = TextParser.minutaInvalida(ato.minuta);
    if(minutaInvalida){
      criarErro(minutaInvalida, 'minuta', 'erro');
    }

    //ABERTURA DE MATRICULA - DEVE SEMPRE SER APÓS A GERAÇÃO DA MINUTA
    if(ficha.status == 'PENDENTE'){
      // if(ato.tipoServico.opcoes.tipoAto != 'ABERTURA'){
      //   criarErro('O primeiro ato deve ser um ato de abertura', '', 'erro');
      // }else{
        ficha.status = 'ABERTA';
      // }
    }

    //verifica erros no calculo de custa
    if(ato.custas?.erro){
      criarErro(ato.custas.erro?.descricao || ato.custas.erro, '', 'erro');
    }

    if (ato.tipoServico.opcoes.editarIndicador){

      ['texto', 'observacoes'].forEach(k => {
        if(ato.dto[k] != undefined && ato.dto[k]){
          ficha[k] = ato.dto[k];
        }
      });

    }

  },

}

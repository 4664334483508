import Http from "@/commons/Http";
import Utils from "@/commons/Utils";
import RelatorioBusiness from "@/business/RelatorioBusiness";
import FrontBusiness from "@/business/FrontBusiness";

const path = 'api/arquivo-relatorio';

export default {

  camposConsulta() {
    return Utils.orderBy([
      {id: 'nome', nome: 'Nome', type: 'text'},
      {id: 'cadastro', nome: 'Data Cadastro', type: 'date'},
      {id: 'finalizacao', nome :'Data Finalização',type: 'date'},
      {id: 'status', nome: 'Status', type: 'list', options: this.getStatus()},
      ], 'nome');
  },

  getStatus() {
    return [
      {id: 'FILA', nome: 'Fila'},
      {id: 'PROGRESSO', nome: 'Em Progresso'},
      {id: 'CONCLUIDO', nome: 'Concluído'},
      {id: 'ERRO', nome: 'Erro'}
    ];
  },


  async save(id,filtro,extensao) {
    let dto = {
      "id":id,
      "filtro":filtro,
      "extensao":extensao
    }
    return Http.post(`${path}/`,dto);
  },

  async get(id) {
    return Http.get(`${path}/${id}`);
  },

  async visualizarPdf(idArquivo){
    await RelatorioBusiness.visualizarPdf(`${path}/download/${idArquivo}/${false}`, idArquivo, 'GET', null, false);
  },

  async arquivoDownload(arquivo,assinado){
    let tipo;
    switch (arquivo.extensao) {
      case 'PDF':
        tipo = "application/pdf"
        break;
      case 'XLSX':
        tipo = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        break;
      case 'TXT':
        tipo = "text/plain";
        break;
      case 'CSV':
        tipo = "text/x-comma-separated-values";
        break;
      case 'XML':
        tipo = "application/xml";
        break;
      case 'JSON':
        tipo = "application/json";
        break;
      default:
        tipo = "application/octet-stream"; // Padrão para arquivos desconhecidos
        break;
    }
    if(tipo != null){
      let name = !assinado ? arquivo.nome+"."+arquivo.extensao.toLowerCase() : arquivo.nome+"-assinado."+arquivo.extensao.toLowerCase()
      await RelatorioBusiness.downloadArquivo(`${path}/download/${arquivo.id}/${assinado}`, name, tipo)
    }else{
      FrontBusiness.showError('', 'Tipo de arquivo não encontrado! ');
    }
  },

  async excluirRelatorio(idArquivo){
    return Http.delete(`${path}/excluir/${idArquivo}`)
  },

  async regerar(idArquivo){
    return Http.get(`${path}/regerar/${idArquivo}`)
  },

  async ultimosRelatorios(tipo){
    return Http.get(`${path}/ultimos-relatorios/${tipo}`)
  },

  async paginationV2(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/v2/pagination?sort=${sort || 'ar.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

}

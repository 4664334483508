<template>
  <consulta ref="modal" :columns="columns" :process="process"
            :custom-actions="actions" id="consultar-oficio" :titulo="titulo" :stopLoad="true">

    <template #cabecalho>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>
    </template>

    <template #filtros>
      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode !== 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">

          <template v-if="item.id === 'matriculas_id'">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </template>

          <template v-if="item.id === 'registros_auxiliares_id'">
            <v-input class="col-auto pr-0" v-model="buscarRegistro" :options="listas.livrosRegistro" type="select" />
            <buscar-fichas :valorInicialIndividual="registro_auxiliar" label="Registro Auxiliar" :livro="livroSelecionadoRegistro" :status="statusSelecionadoRegistro"
                           ref="buscarFichasrReg" @selecionar="selecionar('registro_auxiliar', ...arguments)" :multiple="false"  class="pl-2 col"/>
          </template>

          <buscar-fichas-externas v-if="item.id === 'matriculas_externas_id'" :showLabels="false" :livro="livroSelecionadoFichaExterna" :valorInicial="matricula_externa"
                                  ref="buscarFichasExternas" @selecionar="selecionar('matricula_externa', ...arguments)" class="col" :multiple="false" :multiplos="false"/>

          <buscar-protocolos class="col mb-0" :noChangeSelect="true" :valorInicial="protocolo" label="Protocolos Relacionados"
                             ref="buscarProtocolos" @selecionar="selecionar('protocolo', ...arguments)"
                             :limparCampo="false" :multiple="false" :dominio="dominioProtocolo"
                             :tipoProtocolo="tipoProtocolo" v-if="item.id === 'protocolos_id'"/>
        </template>
      </filtrar-consulta>
      <template v-if="mode === 'default'">

        <div class="row w-100" @keyup.enter="executeLoad()">
          <v-input type="number" label="Número" v-model.number="filtros.numero" class="col-3 pr-1" />
          <v-input type="number" label="Ano" v-model.number="filtros.ano" class="col-3 px-1" :disabled="anoDefault"/>
          <v-input type="select" :options="listas.tipos" label="Tipo" class="col-3 px-1" v-model="filtros.tipo" :disabled="tipoDefault"/>
          <v-input type="select" :options="status" label="Status" v-model="filtros.status" class="col-3 px-1" />

          <div class="w-100"/>
          <v-input type="select" :options="listas.etapas" label="Etapa Atual" v-model="filtros.etapa_id" class="col pr-1" />
          <v-input type="date" label="Data Cadastro (Início)" :required="true" v-model="filtros.cadastroInicio" class="col px-1"/>
          <v-input type="date" label="Data Cadastro (Fim)" :required="true" v-model="filtros.cadastroFim" class="col px-1"/>

          <div class="w-100"/>
          <v-input type="select" :options="listas.tiposServico" label="Tipo de Ofício" class="col pr-1" v-model="filtros.tipoServico" />
          <v-input type="date" label="Prazo para Resposta (Início)" v-model="filtros.vencimentoInicio" class="col px-1"/>
          <v-input type="date" label="Prazo para Resposta (Fim)" v-model="filtros.vencimentoFim" class="col px-1"/>

          <div class="w-100"/>
          <v-input type="text" label="Número do Processo" v-model="filtros.processo" class="col-4 pr-1" />
          <v-input type="text" label="Número do Ofício" v-model="filtros.oficio" class="col-4 px-1" />
          <v-input type="text" label="Vara" v-model="filtros.vara" class="col-4 px-1" />
          <v-input type="text" label="Remetente" v-model="filtros.remetente" class="col-6 pr-1" />
          <v-input type="text" label="Destinatário" v-model="filtros.destinatario" class="col-6 px-1" />
          <v-input type="text" label="Observações" v-model="filtros.observacoes" class="col-12 pr-1" />

          <div class="w-100"/>
          <v-input type="text" label="Texto" v-model="filtros.texto" class="col-12 pr-1" />

        </div>

        <card class="card-full mt-2" titulo="Matrículas Relacionadas">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full mt-2" titulo="Registros Auxiliares Relacionados">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscarRegistro" :options="listas.livrosRegistro" type="select" />
            <buscar-fichas :valorInicialIndividual="registro_auxiliar" label="Registro Auxiliar" :livro="livroSelecionadoRegistro" :status="statusSelecionadoRegistro"
                           ref="buscarFichasrReg" @selecionar="selecionar('registro_auxiliar', ...arguments)" :multiple="false" :limpar-campo="false" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full mt-2" titulo="Protocolos Relacionados">
          <card-content class="row gutters-xs">
            <buscar-protocolos :noChangeSelect="true" :valorInicial="protocolo" label="Protocolo" ref="buscarProtocolos"
                               @selecionar="selecionar('protocolo', ...arguments)"
                               :limparCampo="false" :multiple="false" :dominio="dominioProtocolo" :tipoProtocolo="tipoProtocolo" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full mt-2" titulo="Indicadores Reais Externos Relacionados">
          <card-content class="row gutters-xs">
            <buscar-fichas-externas  :livro="livroSelecionadoFichaExterna" ref="buscarFichasExternas" @selecionar="selecionar('matricula_externa', ...arguments)"
                                     :valorInicial="matricula_externa" class="col" :multiple="false" :multiplos="false" :showLabels="false"/>
          </card-content>
        </card>

        <card class="card-full card-envolvido mt-2" titulo="Envolvidos">
          <card-content class="row gutters-xs">
            <v-input type="select" label="Tipo de busca do CPF/CNPJ" :options="options.tiposBusca" v-model="filtros.tipoBuscaEnvolvidoDocumento" class="col-3" />
            <v-input type="text" label="CPF/CNPJ" v-model="filtros.envolvidoDocumento" class="col-3" />
            <v-input type="text" label="Nome" v-model="filtros.envolvidoNome" class="col" />
          </card-content>
        </card>
      </template>
    </template>

    <template #row="{ row }">

      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectItem(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <router-link :to="{ name: 'oficio', params:{id : row.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </td>

        <td width="60px" class="pointer icon" @click="selecionarOficio(row)"><a>{{ row.numero | inteiro }}</a></td>
        <td width="60px">{{ row.ano }}</td>
        <td width="60px">{{ row.oficio }}</td>
        <td width="80px">{{ tipo(row.tipo) }}</td>
        <td width="240px">{{ row.tipoServico }}</td>
        <td width="100px">{{ row.cadastro | datahora }}</td>
        <td width="100px">{{ row.vencimento | data }}</td>
        <td width="50px" class="text-center">
          <router-link :to="{ name: 'oficio', params:{id : row.id}, query: { aba: 'anexos' } }" class="icon mr-3" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fal fa-paperclip" title="Anexos"></i>
          </router-link>
          <v-button :run="duplicar" :params="[row.id]" :button="false" :popover="true" class="icon"
                    position="dropleft">
            <i class="fal fa-copy" title="Duplicar"></i>
          </v-button>
        </td>

      </tr>

    </template>

    <template #footer>

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="selecionarOficio" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import OficioBusiness from "@/business/oficio/OficioBusiness.js";
import Consulta from "@/components/Consultas/Consulta";
import Card from "@/components/Layout/components/Card";
import CardContent from "@/components/Layout/components/CardContent";
import DetalhesOficio from "@/views/Oficio/DetalhesOficio.vue";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta";
import Utils from "@/commons/Utils";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import EtapaBusiness from "@/business/crud/EtapaBusiness";

export default {
    name: "ConsultarOficio",
    components: {FiltrarConsulta, Consulta, Card, CardContent },

    props: {
      novoOficio : {type : Boolean, default : true},
      select : {type : Boolean, default : false},
      multiplos : {type : Boolean, default : false},
      tipoDefault: {type : String, default : null},
      anoDefault: {type : Number, default : null},
    },

    data() {
      let filtros = []
      if(this.tipoDefault){
        filtros.push({id: "tipo",type: null,value: this.tipoDefault, hiddenActions: true, disabled: true});
      }
      if(this.anoDefault){
        filtros.push({id: "ano",type: null,value: this.anoDefault, hiddenActions: true, disabled: true});
      }
      return {
        buscar : 'MATRICULA',
        buscarRegistro : 'REGISTRO_AUXILIAR',
        mode: 'novo',
        opcoesConsulta: [],
        filtros : {
          filtros,
          numero : null,
          ano : this.anoDefault,
          protocolo: null,
          matricula: null,
          registro_auxiliar: null,
          matricula_externa: null,
          envolvidoDocumento: null,
          envolvidoNome: null,
          tipo: this.tipoDefault,
          tipoBuscaEnvolvidoDocumento: 'contains',
          vencimento: null
        },
        options: {
          tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
        },
        status: [{id: 'TODOS', nome: 'Todos'}, ...ProtocoloBusiness.getStatus()],
        protocolo: null,
        matricula: null,
        registro_auxiliar: null,
        matricula_externa: null,
        dominioProtocolo: 'PROTOCOLO_RI',
        tipoProtocolo: 'NORMAL',
        livroSelecionadoFichaExterna: 'MATRICULA',
        listas:{
          tipos : OficioBusiness.getTipos(),
          tiposServico: [],
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'},
            {id: 'TRANSCRICAO', nome: 'Transcrição'},
          ],
          livrosRegistro: [
            {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
            {id: 'PRE_REGISTRO_AUXILIAR', nome: 'Pré-Registro Auxiliar'}
          ],
          etapas: [],
        },
        marcados: []
      }
    },

    async mounted(){
      await TipoServicoBusiness.getByDominioSimplesTodos('OFICIO').then(l => this.listas.tiposServico = Object.freeze(Utils.orderBy(l, 'nome')));
      await EtapaBusiness.getByDominioSimples('OFICIO').then(l => this.listas.etapas = Object.freeze(Utils.orderBy(l, 'nome')));

      let opcoes = [];
      // opcoes.push({id: 'numero', nome: 'Número', type: 'number'});
      opcoes.push({id: 'ano', nome: 'Ano', type: 'number'});
      opcoes.push({id: 'tipo', nome: 'Tipo', type: 'list', options: this.listas.tipos});
      opcoes.push({id: 'tipo_servico_id', nome: 'Tipo de Ofício', type: 'list', options: this.listas.tiposServico});
      opcoes.push({id: 'cadastro', nome: 'Data Cadastro', type: 'date'});
      opcoes.push({id: 'vencimento', nome: 'Prazo para Resposta', type: 'date'});
      opcoes.push({id: 'processo', nome: 'Número do Processo', type: 'text'});
      opcoes.push({id: 'oficio', nome: 'Número do Ofício', type: 'text'});
      opcoes.push({id: 'vara', nome: 'Vara', type: 'text'});
      opcoes.push({id: 'remetente', nome: 'Remetente', type: 'text'});
      opcoes.push({id: 'destinatario', nome: 'Destinatário', type: 'text'});
      opcoes.push({id: 'observacoes', nome: 'Observações', type: 'tsquery'});
      opcoes.push({id: 'texto', nome: 'Texto', type: 'tsquery'});
      opcoes.push({id: 'matriculas_id',  nome: 'Matrículas Relacionadas', type: 'matricula'});
      opcoes.push({id: 'registros_auxiliares_id',  nome: 'Registros Auxiliares Relacionados', type: 'registro_auxiliar'});
      opcoes.push({id: 'matriculas_externas_id',  nome: 'Indicadores Reais Externos Relacionados', type: 'matricula_externa'});
      opcoes.push({id: 'protocolos_id',  nome: 'Protocolos Relacionados', type: 'protocolo'});
      opcoes.push({id: 'envolvido_cpf',  nome: 'CPF Envolvido', type: 'cpf'});
      opcoes.push({id: 'envolvido_cnpj', nome: 'CNPJ Envolvido', type: 'cnpj'});
      opcoes.push({id: 'envolvido_nome', nome: 'Nome Envolvido', type: 'text'});
      opcoes.push({id: 'numero', nome: 'Código', type: 'number'});
      opcoes.push({id: 'status', nome: 'Status/Situação', type: 'list', options: ProtocoloBusiness.getStatus()});
      opcoes.push({id: 'etapa_id', nome: 'Etapa Atual', type: 'list', options: this.listas.etapas})

      this.opcoesConsulta = Utils.orderBy(opcoes, 'nome');

    },

    computed:{

      titulo(){
        let titulo = 'Consultar Ofícios';
        if (this.tipoDefault || this.anoDefault) {
          titulo += ' -';
          if(this.tipoDefault){
            titulo += ' ' + FrontBusiness.getLabel(this.tipoDefault, this.listas.tipos);
          }
          if(this.anoDefault){
            titulo += ' ' + this.anoDefault;
          }
        }
        return titulo;
      },

      columns(){
        let cols = [];
        if(this.multiplos){
          cols = [
            {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
          ];
        }
        cols.push(
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
          {label:  'Número', field: 'o.numero', name: 'numero'},
          {label:  'Ano', field: 'o.ano', name: 'ano'},
          {label:  'Ofício', field: 'o.oficio', name: 'oficio'},
          {label:  'Tipo', field: 'o.tipo', name: 'tipo'},
          {label:  'Tipo de Ofício', field: 't.nome', name: 'tipoServico'},
          {label:  'Cadastro', field: 'o.cadastro', name: 'cadastro'},
          {label:  'Prazo para Resposta', field: 'o.vencimento', name: 'vencimento'},
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'}
        )
        return cols;
      },

      livroSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
      },

      statusSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
      },

      livroSelecionadoRegistro() {
        return this.buscarRegistro === 'PRE_REGISTRO_AUXILIAR' ? 'REGISTRO_AUXILIAR' : this.buscarRegistro;
      },

      statusSelecionadoRegistro() {
        return this.buscarRegistro === 'PRE_REGISTRO_AUXILIAR' ? 'PENDENTE' : 'ABERTA';
      },

      actions() {
        let actions = [];
        if (!this.novoOficio) return actions;
        actions.push({
          class: 'at-options',
          icon: 'far fa-plus-square mr-1',
          label: 'Novo Ofício',
          action: this.novo
        });
        return actions;
      }

    },

    methods:{

      close(){
        FrontBusiness.closeModal();
      },

      selecionarOficio(oficio){
        FrontBusiness.closeModal(oficio);
      },

      async process(sort_by, sort_dir, page_length, page_number){
        if(this.filtros.status === 'TODOS'){
          this.filtros.status = null;
        }

        if(this.mode == 'novo') {
          let filtros = [...this.filtros?.filtros].filter(e => e.id);
          let matriculas_id = filtros.find(e => e.id === 'matriculas_id');
          if (matriculas_id) {
            matriculas_id.value = this.filtros.matricula;
          }

          let matriculas_externas_id = filtros.find(e => e.id === 'matriculas_externas_id');
          if (matriculas_externas_id) {
            matriculas_externas_id.value = this.filtros.matricula_externa;
          }

          let registros_auxiliares_id = filtros.find(e => e.id === 'registros_auxiliares_id');
          if (registros_auxiliares_id) {
            registros_auxiliares_id.value = this.filtros.registro_auxiliar;
          }

          let protocolos_id = filtros.find(e => e.id === 'protocolos_id');
          if (protocolos_id) {
            protocolos_id.value = this.filtros.protocolo;
          }
          return OficioBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
          .then(result => ({rows : result.pagina, total: result.quantidade}));
        }
        return OficioBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros : this.filtros})
        .then(result => ({rows : result.pagina, total: result.quantidade}));
      },

      detalhes(id){
        FrontBusiness.openModal(DetalhesOficio, {id});
      },

      async duplicar(id){
        let clone = await OficioBusiness.clonar(id);
        if (clone) {
          FrontBusiness.redirect(`oficio/${clone.id}/geral`);
        }

      },

      novo(){
        FrontBusiness.openModal(DetalhesOficio);
      },

      tipo(tipo){
        return FrontBusiness.getLabel(tipo, this.listas.tipos);
      },

      nomearProtocolo : FrontBusiness.nomearProtocolo,

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      selecionar(nomeFiltro, item, add, x){
        this.$nextTick(() => {
          nomeFiltro = nomeFiltro || x;
          this.filtros[nomeFiltro] = item[0] ? item[0].id : null;
          if (['matricula', 'registro_auxiliar'].includes(nomeFiltro)) {
            this[nomeFiltro] = item[0];
          } else {
              this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
              if(nomeFiltro === 'matricula_externa' && item[0]) {
                this.livroSelecionadoFichaExterna = item[0]?.tipo;
              }
          }
          if (add && item[0]) {
            this.executeLoad();
          }

        });
      },

      armazenarFiltro(){
        this.dominioProtocolo = this.$refs?.buscarProtocolos?.varDominio;
        this.tipoProtocolo = this.$refs?.buscarProtocolos?.varTipoProtocolo;
      },

      executeLoad(){
        this.armazenarFiltro();
        this.$refs.modal.load();
      },

      remover(idx, lista) {
        this.$nextTick(() => {
          this.filtros[lista].splice(idx, 1);
        });
      },

      selecionarTodos(limpar = false) {
        this.marcados = [];
        this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
          let input = document.getElementById(`check-${r.id}`)
          input.checked = !limpar;
          if (!limpar) this.marcados.push(r)
        })
      },

      selectItem(item) {
        let index = this.marcados.findIndex(e => e.id === item.id);
        if (index >= 0) {
          this.marcados.splice(index, 1)
        } else {
          this.marcados.push(item)
        }
      },

    }
  }
</script>

<style lang="scss">
#consultar-oficio {
  th.column__botao{
      width: 50px;
  }
  .buscar-fichas-wrapper .form-group, .buscar-protocolos-wrapper .form-group, .card-envolvido .form-group{
    margin-bottom: 0 !important;
  }
}
</style>

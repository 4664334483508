<template>
  <modal titulo="Atualização de Itens Legados" :loading="loading" :max-height="false" :focus="false" id="modal-itens-legados">

    <div class="alert alert-outline-danger mb-3">
      <b>ATENÇÃO: </b> <br />
      {{textoLegado}} do sistema anterior. <br />
      É necessário informar o tipo correto para que as informações sejam apresentadas corretamente.
    </div>

    <card titulo="Escolha o tipo adequado">

      <div class="row">

        <v-input type="v-select" :options="tipos" v-model="form.tipoServicoId" class="col-12" />

        <div class="form-group col-auto pt-2" >
          <input style="position: relative; top: 2px; left: 0px;" type="checkbox" id="GERAR_NUMERO" v-model="form.atualizarProtocolo" />
          <label for="GERAR_NUMERO" style="position: relative; top: 0px; left:4px;" class="pointer pr-2">
            Informar como ato principal do protocolo?
          </label>
        </div>

      </div>

    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="confirmarAtualizacao" :popover="false" class="btn-outline-primary">Atualizar</v-button>
    </template>

  </modal>
</template>

<script>

import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import LegadoBusiness from "@/business/LegadoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "ModalItemProtocoloLegado",
  components: {Card, Modal},
  props:{
    id: String,
    tipo: String,
    nome: String,
    dominio: String,
    dto: Object
  },

  computed:{

    textoLegado(){
      switch (this.tipo) {
        case 'ato':
          return 'Este é um ato importado';
        case 'protocolo':
          return 'Este é um protocolo importado';
        case 'custa-adicional':
          return 'Esta é uma custa adicional importada';
        default:
          return null;
      }
    }

  },

  data(){
    return {
      loading: true,
      form:{
        tipoServicoId :null,
        atualizarProtocolo:false
      },
      tipos:[]
    }
  },

  async mounted() {
    this.tipos = await TipoServicoBusiness.getByDominioSimples(this.dominio);
    this.loading = false;
  },

  methods:{

    close: FrontBusiness.closeModal,
    async run(){
      let r = await LegadoBusiness.atualizarTipo(this.tipo, this.id, this.form.tipoServicoId, this.form.atualizarProtocolo);
      if(r){
        FrontBusiness.closeModal(true)
      }
    },

    async confirmarAtualizacao() {
      let msg = `Esta ação será replicada para todos os atos que possuem essa nomenclatura do sistema anterior.${this.form.atualizarProtocolo ? " Além disso, o sistema atualizará o Ato Principal de todos os protocolos que possuírem este tipo de ato." : ""}`
      return FrontBusiness.showConfirmacao(msg, "Tem certeza que deseja atualizar?").then(async r => {
        if (r.value) {
          await this.run()
          return true
        } else return r.dismiss !== 'cancel';
      }).catch(this.showError)
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '#modal-itens-legados');
    },
  }

}
</script>

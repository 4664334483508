import Http from "@/commons/Http";

const path = 'api/anexo';
const pathArquivoMorto = 'api/v2/arquivo-morto';

export default {

  async upload(file, anexo){

    const formData = new FormData();
    formData.append("file", file);
    formData.append("checklist", (anexo.checklist === undefined ? false : anexo.checklist));
    formData.append("etapa", anexo.etapa ? anexo.etapa.id : '');

    const progressFnc = (event) => {
      Math.round((event.loaded * 100) / event.total);
    }

    let config = {
      timeout: 0,
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: progressFnc
    };

    return Http.post(`${path}/upload-file/${anexo.entidade}/${anexo.referencia}${anexo.grupo ? `/${anexo.grupo}` : ''}`, formData, config);
  },

  async ver(anexo = {}, thumb = false, resetCache = ''){
    let url = `${window.server.API}/${path}/${anexo?.id ? anexo.id + '/' : ''}download${ thumb ? '/thumbnail' : ''}${resetCache ? '?reset=' + resetCache : ''}`;
    return url;
  },

  async verDigitalizacao(anexo = {}, thumb = false, resetCache = ''){
    let url = `${window.server.API}/api/digitalizacao/${anexo?.id ? anexo.id + '/' : ''}download${resetCache ? '?reset=' + resetCache : ''}`;
    return url;
  },

  download(anexo, assinado = false, callBack = () => {}){
    let url = `${window.server.API}/api/anexo/${anexo.id}/download/${assinado ? 'assinado/' : ''}?`;
    callBack(url);
    return url;
  },

  getUploadUrl(entidade, id = '', grupo = null, etapa = null){
    let url = `${window.server.API}/${path}/upload-file/${entidade}/${id}${grupo ? `/${grupo}` : ''}?${etapa ? `&etapa=${etapa}` : ''}`;
    return url;
  },

  getUploadUrlArquivoMorto() {
    let url = `${window.server.API}/${pathArquivoMorto}/upload-file/`;
    return url;
  },

  getDownloadUrl(anexo, assinado = false){
    return `${window.server.API}/${path}/${anexo.id}/download${assinado ? '/assinado' : ''}?download=true`;
  },

  getViewUrl(anexo, assinado = false){
    return `${window.server.API}/${path}/${anexo.id}/download${assinado ? '/assinado' : ''}?download=false`;
  },

  async getBucketInfo() {
    return await Http.get('/api/public/get-bucket-info').catch(e => console.log(e));
  },
}

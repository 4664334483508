<template>
    <card-table  id="detalhamento-areas-indicador-real" class="table-borderless">

      <thead>
      <tr>
        <th class="text-muted tamanho" v-if="ordenar"></th>
        <th class="text-muted" v-if="tipo == 'areas'"> Outras áreas </th>
        <th class="text-muted" width="">Descrição </th>
        <th class="text-muted" width="" v-if="tipo == 'unidadesAcessorias'">Unidade </th>
        <th class="text-muted" width="" v-if="tipo == 'unidadesAcessorias'">Complemento </th>
        <th class="text-muted" width="150px">Área </th>
        <th class="text-muted" width="30px" style="vertical-align: middle; text-align: center" v-if="!visualizar">
          <a class="icon" href="" @click.prevent="adicionarArea()">
            <i class="fa fa-plus-square"></i>
          </a>
        </th>
      </tr>
      </thead>
      <draggable v-model="areas" @end="$emit('ordenar', tipo, areas)" ghost-class="ghost"
                 handle=".move" tag="tbody" class="table-areas">
        <tr v-for="(area, i) in areas" :key="'item' + i">
          <td class="text-center icon move" v-if="ordenar">
            <span class="icon move">
              <i class="fas fa-arrows-alt"></i>
            </span>
          </td>
          <td class="form-group" v-if="tipo == 'areas'">
            <select :disabled="!edicao" class="form-control form-control-xs" v-model="area.tipo">
              <option value="COMUM">Comum</option>
              <option value="PRIVATIVA">Privativa</option>
              <option value="OUTRAS">Outras Áreas</option>
            </select>
          </td>
          <td class="form-group">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="area.descricao" type="text" />
          </td>

          <td class="form-group" v-if="tipo == 'unidadesAcessorias'">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="area.unidade" type="text" />
          </td>

          <td class="form-group" v-if="tipo == 'unidadesAcessorias'">
            <input :disabled="!edicao" class="form-control form-control-xs" v-model="area.complemento" type="text" />
          </td>

          <td class="form-group">
            <v-input :disabled="!edicao" :precision="decimais" v-model="area.area" type="currency" :input-props="{precision : {min : 2, max : 10}}"></v-input>
          </td>

          <td class="text-center" v-if="!visualizar">
            <a class="icon" href="" @click.prevent="removerArea(i)">
              <i class="fa fa-times"></i>
            </a>
          </td>
        </tr>
      </draggable>
      <tr v-if="areas.length == 0">
        <td colspan="100%">
          <div class="alert alert-warning alert-sm mb-0">
            Nenhuma área cadastrada
          </div>
        </td>
      </tr>

      <tfoot>
        <th width="150px" class="pl-0 text-muted" v-if="possuiTipo"></th>
        <th class="text-right" :colspan="`${tipo == 'unidadesAcessorias' ? 3 : 1}`">Total: </th>
        <th class="">{{$formatters.currency(total,{min : 2, max : 10})}}</th>
        <th class="text-right" v-if="!visualizar"></th>
      </tfoot>

    </card-table>
</template>

<script>
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import draggable from "vuedraggable";

  export default {
      components: {CardTable, draggable},
      props : {
        areas : {type : Array, required : true},
        possuiTipo : {type : Boolean, default : true},
        visualizar: {type : Boolean, default : false},
        titulo : String,
        tipo : String,
        edicao: {type : Boolean, default : true},
        ordenar: {type : Boolean, default : false}
      },

      name: "DetalharAreasIndicadorReal",
      data(){
        return {
          decimais : 10,
        }
      },

      computed:{
        total(){
          return this.areas.reduce((acc, cur) => acc + cur.area, 0.00);
        }
      },

      methods:{

        removerArea(index) {
          this.$nextTick(() => {
            if (this.areas && this.areas.length) {
              this.areas.splice(index, 1);
            }
          });
        },

        adicionarArea(){
          this.$nextTick(() => {
            this.areas.push({tipo: 'PRIVATIVA', descricao : '', area : 0});
          });
        }

      }

    }
</script>

<style scoped lang=scss>
  .tamanho {
    width: 5%;
    max-width: 36px;
  }
</style>

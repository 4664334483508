<template>

  <modal id="detalhes-registro-titulo" class="modal-detalhes-entidade" :titulo="titulo" :loading="loading" :check="false">
    <template #title-extra>
      <div v-if="ficha.status !== 'ABERTA'" class="badge text-center" :class="classStatus">
        {{ficha.status}}
      </div>
    </template>
    <template #actions>

      <template v-if="!usuario.auditor && ficha.status != 'PENDENTE'">

        <li class="list-inline-item" :class="{active : aba == 'anexos'}" >
          <a @click.prevent="aba = 'anexos'" title="Documentação & Anexos" href>
            <i class="fal fa-paperclip"></i>
          </a>
        </li>

        <li class="list-inline-item" :class="{active : aba == 'historico'}">
          <a @click.prevent="aba = 'historico'" title="Histórico" href>
            <i class="fas fa-history"></i>
          </a>
        </li>

      </template>


      <li class="list-inline-item" v-if="!usuario.auditor">
        <a @click.prevent.stop="editarIndicador(ficha)" title="Editar Indicador"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>

    </template>

    <template #header>

      <ul class="nav nav-tabs">

        <template  v-for="a in abas">
          <li class="nav-item" :key="a.id" v-if="a.exibir">
            <a @click.prevent="aba = a.id" :title="a.title" class="nav-link py-1 px-2 small"
               href :class="{'active' : aba == a.id}">
              {{a.nome}}
            </a>
          </li>
        </template>

      </ul>

    </template>

    <component v-if="!loading && livro" :is="componente" :ficha="ficha" :id="ficha.id" :livro="livro" tipo="BEM-MOVEL" @load="load"/>

  </modal>

</template>

<script>

  import FrontBusiness from "@/business/FrontBusiness";
  import AlertBanner from "@/components/alert/AlertBanner";
  import Modal from "@/components/Modal";
  import Anexos from "@/components/IndicadorGeneric/Detalhes/Anexos";
  import Historico from "@/components/IndicadorGeneric/Detalhes/Historico.vue";
  import Protocolos from "@/components/IndicadorGeneric/Detalhes/Protocolos.vue";
  import Dados from "@/components/BemMovel/Detalhes/Dados";
  import BemMovelBusiness from "@/business/crud/BemMovelBusiness";
  import FormBemMovel from "@/components/BemMovel/Form/FormBemMovel.vue";
  import EventBus from "@/commons/EventBus";

  export default {

    name: "DetalhesBemMovel",
    components: {
      Modal,
      AlertBanner,
      "aba-dados" : Dados,
      "aba-historico" : Historico,
      "aba-anexos" : Anexos,
      "aba-protocolos" : Protocolos,
    },

    props: {
      id : String,
      abaInicial: String
    },

    modal: {
      width: 900
    },

    async mounted(){
        await this.load();
        EventBus.$on('ATOS-LOAD', this.load);
    },

    computed:{
      titulo(){
        return this.ficha?.livro ? FrontBusiness.nomearFicha(this.ficha) : '';
      },
      livro(){
        return this.ficha?.livro;
      },
      componente(){
        return `aba-${this.aba}`;
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      classStatus() {
        if (this.ficha.status == 'ENCERRADA') {
          return 'badge-primary-soft';
        } else if (this.ficha.status == 'CANCELADA') {
          return 'badge-danger-soft';
        }
        return 'badge-success-soft'
      }
    },

    data() {
      return {
        ficha : {},
        aba : 'dados',
        abas:[],
        loading: true
      }
    },

    methods:{
      async load(loading = true){
        this.loading = loading;
        this.ficha = await BemMovelBusiness.getById(this.id);
        const auditor = this.usuario.auditor;

        let exibir = !auditor && !this.preview;

        this.abas = [
          {id: 'dados', nome: 'Dados', exibir: true, title: 'Dados Principais'},
          {id: 'protocolos', nome: 'Protocolos', exibir: exibir}
        ];

        if (this.abaInicial) {
          this.$set(this, 'aba', this.abaInicial);
        }

        this.$set(this, 'loading', false);
      },

      async editarIndicador(ficha) {
          let result = await FrontBusiness.openModal(FormBemMovel, {id: ficha.id, livro: ficha.livro});
          if(result){
              this.$set(this, 'ficha', null);
              await this.load();
          }
      },

    }

  }
</script>

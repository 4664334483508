import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import Utils from "@/commons/Utils.js";

const tabelaSigef = async function(ficha, node, callback, dicionario){

  let valores = await IndicadorRealBusiness.listarSigef(ficha.id).catch(e => []);

  if(node.params.lista !== undefined){
    dicionario.tabelaSigef = valores;
    return await callback(node.children, dicionario);
  }

  let limites = valores.map(e => {
    return `
        <tr>
            <td align="center" style="white-space: nowrap">${e.vertice}</td>
            <td align="center" style="white-space: nowrap">${e.longitudeDms}</td>
            <td align="center" style="white-space: nowrap">${e.latitudeDms}</td>
            <td align="center" style="white-space: nowrap">${Utils.formatters.currency(e.altitude, 2)}</td>
            <td align="center" style="white-space: nowrap">${e.destino}</td>
            <td align="center" style="white-space: nowrap">${e.azimuteDms}</td>
            <td align="center" style="white-space: nowrap">${Utils.formatters.currency(e.comprimento)}</td>
            <td>${e.confrontante}</td>
        </tr>
    `;
  });

  let vertices = valores.map(e => {
    return `
          <tr>
              <td align="center">${e.vertice}</td>
              <td align="center">${e.longitudeDms}</td>
              <td align="center">${Utils.formatters.currency(e.sigmaLongitude, 2)}</td>
              <td align="center">${e.latitudeDms}</td>
              <td align="center">${Utils.formatters.currency(e.sigmaLatitude, 2)}</td>
              <td align="center">${Utils.formatters.currency(e.altitude, 2)}</td>
              <td align="center">${Utils.formatters.currency(e.sigmaAltitude, 2)}</td>
              <td align="center">${e.metodoPosicionamento}</td>
          </tr>
      `;
  });

  let output = `
    <table class="tabela-sigef">
        <tr>
            <th colspan="8" align="center">Segue abaixo a tabela do Georreferenciamento, certificada pelo INCRA:</th>
        </tr>
        <tr>
            <th align="left" colspan="4">VÉRTICE</th>
            <th align="left" colspan="4">SEGMENTO VANTE</th>
        </tr>
        <tr>
            <th align="center" style="white-space: nowrap">Código</th>
            <th align="center" style="white-space: nowrap">Longitude</th>
            <th align="center" style="white-space: nowrap">Latitude</th>
            <th align="center" style="white-space: nowrap">Altitude(m)</th>
            <th align="center" style="white-space: nowrap">Código</th>
            <th align="center" style="white-space: nowrap">Azimute</th>
            <th align="center" style="white-space: nowrap">Dist.(m)</th>
            <th align="left" style="white-space: nowrap">Confrontações</th>
        </tr>
        ${limites.join('')}
        <tr>
            <th align="center" style="white-space: nowrap">Código</th>
            <th align="center" style="white-space: nowrap">Longitude</th>
            <th align="center" style="white-space: nowrap">Sigma Long.(m)</th>
            <th align="center" style="white-space: nowrap">Latitude</th>
            <th align="center" style="white-space: nowrap">Sigma Lat.(m)</th>
            <th align="center" style="white-space: nowrap">Altitude(m)</th>
            <th align="center" style="white-space: nowrap">Sigma Alt.(m)</th>
            <th align="center">Método Posicionamento</th>
        </tr>
        ${vertices.join('')}
    </table>
  `;

  return output;

};

export default tabelaSigef;

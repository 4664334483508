import Http from "@/commons/Http";

const path = 'api/protocolo';

const tratar = (p) => {
  if(p.caixa) p.caixa = JSON.parse(p.caixa);
  if(p.usuario) p.usuario = JSON.parse(p.usuario);
  if(p.protocolo) p.protocolo = JSON.parse(p.protocolo);
  return p;
};

export default {

  recibo(protocolo, tipo){
    return `${path}/${protocolo}/financeiro/recibo/${tipo}`;
  },

  async totalConsolidado(protocolo){
    return Http.get(`${path}/${protocolo}/financeiro/total-consolidado`);
  },

  reciboComplementar(protocolo, lancamento){
    return `${path}/${protocolo}/financeiro/recibo/complementar/${lancamento}`;
  },

  async getById(id){
    return Http.get(`${path}/financeiro/${id}`);
  },

  async numeroControleSicaseExistente(numero){
    return Http.get(`${path}/financeiro/numero-controle-existente/${numero}`);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro/pagination?sort=${sort || 'pf.descricao'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationHistorico(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro/historico/pagination?sort=${sort || 'competencia'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  paginationHistoricoExcel(sort, direction, page, size){
    return `${path}/financeiro/historico/pagination/export-excel?sort=${sort || 'competencia'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`;
  },

  async paginationReceberDevolver(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro/pagination-valores-receber-devolver?sort=${sort || 'p.dominio'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationReceberDevolverV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro/v2/pagination-valores-receber-devolver?sort=${sort || 'p.dominio'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationSaldosPendentesByIndicadorPessoal(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro/pagination-saldos-pendentes-indicador-pessoal?sort=${sort || 'p.dominio'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async listar(protocolo){
    return Http.get(`${path}/${protocolo}/financeiro`);
  },

  async getPorId(ids) {
    return await Http.post(`${path}/undefined/financeiro/ids`, ids).then(l => l.map(tratar));
  },

  async getSaldo(protocolo){
    return Http.get(`${path}/${protocolo}/financeiro/saldo`);
  },

  async getSaldoPendente(protocolo){
    return Http.get(`${path}/${protocolo}/financeiro/saldo-pendente`);
  },

  async getCusto(protocolo){
    return Http.get(`${path}/${protocolo}/financeiro/custo`);
  },

  async inserir(protocolo, dto){
    if(dto == null){
      throw 'Necessário preencher o formulário';
    }
    return Http.post(`${path}/${protocolo}/financeiro`, dto);
  },

  async verificaIdTransacaoBancaria(id) {
    return Http.get(`${path}/financeiro/id-transacao-bancaria/${id}`);
  },

  async inserirLote(files, protocolos, lancamento, anotacao){
    const formData = new FormData();
    files.map(f => formData.append("files", f));
    formData.append("transferenciaLoteDTO", new Blob([JSON.stringify({protocolos, lancamento, anotacao})], {
      type: "application/json"
    }));

    let config = {
      timeout: 0,
      headers: {'Content-Type': 'multipart/form-data'},
    };

    return Http.post(`${path}/financeiro/lote`, formData, config);
  },

  async editar(protocoloFinanceiroId, dto){
    if(!dto || !protocoloFinanceiroId){
      throw 'Necessário preencher o formulário';
    }
    return Http.post(`${path}/financeiro/${protocoloFinanceiroId}`, dto);
  },

  async conciliar(id){
    return Http.post(`${path}/financeiro/${id}/conciliar`);
  },

  async desconciliar(id){
    return Http.post(`${path}/financeiro/${id}/desconciliar`);
  },

  async rejeitar(id, dto){
    return Http.post(`${path}/financeiro/${id}/rejeitar`, dto);
  },

  async distribuirPagamento(files, protocolos, lancamento, anotacao){
    const formData = new FormData();
    files.map(f => formData.append("files", f));
    formData.append("distribuicaoLoteDTO", new Blob([JSON.stringify({protocolos, lancamento, anotacao})], {
      type: "application/json"
    }));

    let config = {
      timeout: 0,
      headers: {'Content-Type': 'multipart/form-data'},
    };

    return Http.post(`${path}/financeiro/distribuir-pagamento`, formData, config);

  },

  async transferenciaProtocolos(dto){
    return Http.post(`${path}/financeiro/transferencia-protocolos`, dto);
  },

  paginationCaixaConsolidado(sort, direction){
    return `${path}/financeiro/pagination-caixa-consolidado/export-excel?sort=${sort || 'u.nome'},${direction || 'asc'}`;
  },

  getTiposMovimento() {
    return [
      {id: 'ENTRADA', nome: 'Entrada'},
      {id: 'SAIDA', nome: 'Saída'},
    ];
  },

  getStatus() {
    return [
      {id: 'pendente', nome: 'Pendente'},
      {id: 'compensado', nome: 'Compensado'},
      {id: 'rejeitado', nome: 'Rejeitado'},
    ];
  },



  getMeiosPagamento() {
    return  [
      {id: 'INTERNO', nome: 'Interno'},
      {id: 'PARCELA_EXPRESS', nome: 'Parcela Express'}
    ];
  },



  getFormasPagamento() {
    return  [
      {id: 'BOLETO', nome: 'Boleto'},
      {id: 'DEBITO', nome: 'Cartão de Débito'},
      {id: 'CREDITO', nome: 'Cartão de Crédito'},
      {id: 'CERICE', nome: 'CERICE'},
      {id: 'CHEQUE', nome: 'Cheque'},
      {id: 'CHEQUE_IRREGULAR', nome: 'Cheque Irregular'},
      {id: 'DEPOSITO', nome: 'Depósito'},
      {id: 'TRANSFERENCIA_SALDO', nome: 'Transferência de Saldo'},
      {id: 'DINHEIRO', nome: 'Dinheiro'},
      {id: 'E_PROTOCOLO', nome: 'E-Protocolo'},
      {id: 'E_CERTIDAO', nome: 'E-Certidão'},
      {id: 'E_INTIMACAO', nome: 'E-Intimação'},
      {id: 'PIX', nome: 'PIX'},
      {id: 'SICASE', nome: 'SICASE'},
      {id: 'SICASE_COMPLEMENTAR', nome: 'SICASE Complementar'},
      {id: 'NAO_IDENTIFICADO', nome: 'Pagamento Não Identificado'},
      {id: 'CORI', nome: 'CORI'},
      {id: 'RIB', nome: 'RIB'},
    ];
  },

  getEntidades() {
    return  [
      {id: 'BOLETO', nome: 'Boleto'},
      {id: 'LANCAMENTO', nome: 'Receita/Despesa'},
      {id: 'TRANSFERENCIA', nome: 'Transferência'},
    ];
  },

  async excluir(protocolo, id){
    return Http.delete(`${path}/${protocolo}/anotacao/${id}`);
  },

  isConciliado(protocoloFinanceiro) {
    return protocoloFinanceiro.conciliado;
  },

  async sugerirDescricao(query){
    return Http.get(`${path}/financeiro/sugerir/descricao/${query}`);
  },

  formasPagamento($root, meioPagamento = false){

    let valores = {
      formasPagamentoEntrada: [],
      formasPagamentoSaida: []
    };

    if($root.config?.dinheiro?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'DINHEIRO', nome: 'Dinheiro'});
      valores.formasPagamentoSaida.push({id: 'DINHEIRO', nome: 'Dinheiro'});
    }

    if($root.config?.cheque?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'CHEQUE', nome: 'Cheque'});
      valores.formasPagamentoSaida.push({id: 'CHEQUE', nome: 'Cheque'});
    }

    if($root.config?.deposito?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'DEPOSITO', nome: 'Depósito'});
      valores.formasPagamentoSaida.push({id: 'DEPOSITO', nome: 'Depósito'});
    }

    if($root.config?.pix?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'PIX', nome: 'PIX'});
      valores.formasPagamentoSaida.push({id: 'PIX', nome: 'PIX'});
    }

    if($root.config?.debito?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'DEBITO', nome: 'Cartão de Débito'});
    }

    if($root.config?.credito?.ativarMeioDeCobranca){
      valores.formasPagamentoEntrada.push({id: 'CREDITO', nome: 'Cartão de Crédito'});
    }

    if($root.config.financeiro?.caixaEprotocolo){
      valores.formasPagamentoEntrada.push({id: 'E_PROTOCOLO', nome: 'E-Protocolo'});
      valores.formasPagamentoSaida.push({id: 'E_PROTOCOLO', nome: 'E-Protocolo'});
    }

    if($root.config.financeiro?.caixaEintimacao){
      valores.formasPagamentoEntrada.push({id: 'E_INTIMACAO', nome: 'E-Intimação'});
      valores.formasPagamentoSaida.push({id: 'E_INTIMACAO', nome: 'E-Intimação'});
    }

    if($root.config.financeiro?.caixaEcertidao){
      valores.formasPagamentoEntrada.push({id: 'E_CERTIDAO', nome: 'E-Certidão'});
      valores.formasPagamentoSaida.push({id: 'E_CERTIDAO', nome: 'E-Certidão'});
    }

    if($root.isEstadoSC || $root.isEstadoMG){
      valores.formasPagamentoEntrada.push({id: 'CORI', nome: 'CORI'});
      valores.formasPagamentoSaida.push({id: 'CORI', nome: 'CORI'});

      valores.formasPagamentoEntrada.push({id: 'RIB', nome: 'RIB'});
      valores.formasPagamentoSaida.push({id: 'RIB', nome: 'RIB'});
    }

    if($root.isEstadoCE){
      valores.formasPagamentoEntrada.push({id: 'CERICE', nome: 'CERICE'});
      valores.formasPagamentoSaida.push({id: 'CERICE', nome: 'CERICE'});
    }

    if($root.isEstadoPE){
      valores.formasPagamentoEntrada.unshift({id: 'SICASE', nome: 'SICASE'}, {id: 'SICASE_COMPLEMENTAR', nome: 'SICASE Complementar'});
    }

    if($root.config.boleto?.ativar){
      valores.formasPagamentoEntrada.push({id: 'BOLETO', nome: 'Boleto'});
    }

    valores.formasPagamentoSaida.push({id: 'NAO_IDENTIFICADO', nome: 'Pagamento Não Identificado'});

    if(meioPagamento && $root.config?.parcelaExpress?.ativo){
      let formaPagamentoParcelaExpress = [];
      if($root.config?.parcelaExpress?.pix?.ativo){
        formaPagamentoParcelaExpress.push({id: 'PIX_PARCELAEXPRESS', nome: 'PIX'});
      }
      if($root.config?.parcelaExpress?.cartaoDebito?.ativo){
        formaPagamentoParcelaExpress.push({id: 'DEBITO_PARCELAEXPRESS', nome: 'Cartão de Débito'});
      }
      if($root.config?.parcelaExpress?.cartaoCredito?.ativo){
        formaPagamentoParcelaExpress.push({id: 'CREDITO_PARCELAEXPRESS', nome: 'Cartão de Crédito'});
      }
      if($root.config?.parcelaExpress?.boleto?.ativo){
        formaPagamentoParcelaExpress.push({id: 'BOLETO_PARCELAEXPRESS', nome: 'Boleto'});
      }
      if (formaPagamentoParcelaExpress.length) {
        valores.formasPagamentoEntrada =  [
          {
            id: 'INTERNO',
            nome: 'Interno',
            options: valores.formasPagamentoEntrada
          },
          {
            id: 'PARCELAEXPRESS',
            nome: 'Parcela Express',
            options: formaPagamentoParcelaExpress
          },
        ];
      }


    }

    return valores;

  },

//   Ajustes

  async paginationAjustes(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/financeiro-ajuste/pesquisar/pagination?sort=${sort || 'cadastro'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async saveAjuste(dto){
    return Http.post(`${path}/financeiro-ajuste`, dto);
  },

  async editarAjuste(dto){
    return Http.put(`${path}/financeiro-ajuste`, dto);
  },
  async excluirAjuste(idAjuste){
    return Http.delete(`${path}/financeiro-ajuste/${idAjuste}`);
  },

}

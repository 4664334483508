<template>

  <div class="vue-table-wrapper px-10" :class="{'pt-10' : paginar}">

    <div class="d-flex d-flex align-items-center" v-if="paginar && header">

      <slot name="tableHeader"></slot>

      <div class="ml-auto mr-10" v-if="paginar">
        <select class="form-control" v-model.number="perPage" @change="processPagination">
          <option :key="opt.id" v-for="opt in perPageOptions" :value="opt.id">{{opt.nome}}</option>
        </select>
      </div>

      <div v-if="filters">
        <vue-table-filters @filtrar="changePage(1)" @limpar="$emit('limpar-filtros')">
          <template #filters>
            <slot name="filters"></slot>
          </template>
        </vue-table-filters>
      </div>

      <slot name="actions"></slot>

    </div>

    <div class="vue-table" :class="{'table-responsive' : responsive}" ref="tableWrapper">
      <table class="table" :class="classTable">

        <thead>
          <slot name="header">
            <tr>
              <vue-header-cell v-for="(column, i) in columns" :column="column" :key="i"
                               :loading="loading" :direction="getDirection(column)" @sort="toggleSort" />
            </tr>
          </slot>
        </thead>

        <tbody>

          <slot name="loading" v-if="loading">
            <tr>
              <td :colspan="columnsCount" class="text-center">
                <loading-spinner />
              </td>
            </tr>
          </slot>

          <slot name="row" v-for="(row, i) in rowsComputed" :row="row" v-else>
            <tr :key="i">
              <vue-table-cell v-for="(column, i) in columns" :column="column" :key="i" :row="row" />
            </tr>
          </slot>

          <slot name="empty" v-if="!loading && (!rowsComputed || !rowsComputed.length)">
            <tr>
              <td :colspan="columnsCount">
                <p class="alert alert-secondary my-0" role="alert">
                  Nenhum resultado para exibir
                </p>
              </td>
            </tr>
          </slot>

        </tbody>

        <slot name="tableFooter"></slot>

      </table>
    </div>

    <div class="d-flex d-flex align-items-center mt-2" v-if="paginar && totalRowsComputed > 1">

      <div>
        <p class="text-muted">Mostrando {{rowsComputed.length}} resultados de {{$formatters.integer(totalRowsComputed)}}</p>
      </div>

      <div class="ml-auto">
        <select class="form-control" v-model.number="perPage" @change="processPagination">
          <option :key="opt.id" v-for="opt in perPageOptions" :value="opt.id">{{opt.nome}}</option>
        </select>
      </div>

      <div class="ml-3" v-if="perPage > 0">
        <vue-table-pager @change-page="changePage($event)" :page="page" :total="totalRows" :max-page="maxPage" :per-page="perPage" />
      </div>

    </div>

  </div>

</template>

<script>
import Utils from "@/commons/Utils.js";
import LoadingSpinner from "@/v2/components/LoadingSpinner.vue";
import VueHeaderCell from "@/v2/plugins/Table/VueHeaderCell.vue";
import VueTableCell from "@/v2/plugins/Table/VueTableCell.vue";
import VueTableFilters from "@/v2/plugins/Table/VueTableFilters.vue";
import VueTablePager from "@/v2/plugins/Table/VueTablePager.vue";

//https://dashlite.themenio.com/demo5/kyc-list-regular.html
//https://dashlite.themenio.com/demo5/user-list-regular.html

export default {
  name: "VueTable",
  components: {LoadingSpinner, VueTableFilters, VueTableCell, VueHeaderCell, VueTablePager},
  props:{

    paginar : {type: Boolean, default: () => false},
    responsive : {type: Boolean, default: () => false},
    header : {type: Boolean, default: () => false},
    classTable : String,
    columns : Array,

    filters : {type: [Array, Object, Boolean], default: () => false},
    filterSize: String,

    lista : Array, // lista predefinida pelo usuario
    service : Function, // função para realizar a paginacao de forma async

  },

  data(){
    return {
      loading: true,
      sortBy : null,
      sortDir : null,
      perPage: 10,
      page: 1,
      totalRows : 0,
      rows : [],
      perPageOptions: [
        {id: 10, nome: 'Exibir 10 resultados' },
        {id: 50, nome: 'Exibir 50 resultados' },
        {id: 100, nome: 'Exibir 100 resultados' },
        {id: 250, nome: 'Exibir 250 resultados' },
        {id: 500, nome: 'Exibir 500 resultados' },
        {id: 1000, nome: 'Exibir 1.000 resultados' },
        {id: 0, nome: 'Exibir todos resultados' },
      ]
    }
  },

  computed:{

    maxPage(){
      return Math.ceil(this.totalRows / this.perPage)
    },

    columnsCount(){
      return this.columns?.length || '100%'
    },

    totalRowsComputed(){
      return this.lista ? this.lista.length : this.totalRows;
    },

    rowsComputed(){
      if(!this.paginar){
        return this.lista;
      }
      return this.rows;
    },

  },

  mounted() {
    setTimeout(this.processPagination, 500);

    //drag and scroll on table
    if(this.responsive){

      const el = this.$refs.tableWrapper;
      let x = 0, y = 0, top = 0, left = 0;

      const draggingFunction = (e) => {

        document.addEventListener('mouseup', () => {
          document.removeEventListener("mousemove", draggingFunction);
        });

        el.scrollLeft = left - e.pageX + x;
        el.scrollTop = top - e.pageY + y;

      };

      el.addEventListener('mousedown', (e) => {
        e.preventDefault();
        y = e.pageY;
        x = e.pageX;
        top = el.scrollTop;
        left = el.scrollLeft;
        document.addEventListener('mousemove', draggingFunction);
      });

    }

  },

  methods:{

    // método utilizado por itens externos que precisem reiniciar a paginação
    async reset(sortBy = null, page = 1){
      this.loading = true;
      this.page = page;
      this.sortBy = sortBy;
      await this.processPagination();
    },

    toggleSort(column){
      if(!this.loading){
        if(column.sortable == this.sortBy){
          this.sortDir = !this.sortDir || this.sortDir == 'desc' ? 'asc' : 'desc';
        }else{
          this.sortBy = column.sortable;
          this.sortDir = 'asc';
        }
        this.processPagination();
      }
    },

    getDirection(column){
      return column.sortable && column.sortable == this.sortBy ? this.sortDir : null
    },

    changePage(page){
      if(!this.loading){
        this.page = page;
        this.processPagination();
      }
    },

    async processPagination(){

      this.loading = true;

      if(this.page > this.maxPage){
        this.page = 1;
      }

      // await Utils.sleep(5000);

      if(this.paginar){
        if(this.service){
          await this.asyncPagination();
        }else{
          await this.localPagination();
        }
      }

      this.loading = false;

    },

    async asyncPagination(){
      let response = await this.service(this.sortBy, this.sortDir, this.page, this.perPage);
      this.rows = response?.rows || response?.pagina || [];
      this.page = response?.page || this.page;
      this.perPage = response?.perPage || this.perPage;
      this.totalRows = response?.total || response?.quantidade || 0;
    },

    async localPagination(){
      this.totalRows = this.lista.length;

      let ordered = Utils.clone(this.lista);
      if(this.sortBy){

        let sortType = 'string';
        // let values = ordered.map()

        console.debug(this.sortDir, this.sortBy)
        ordered = Utils.sortAlphanumeric(ordered, this.sortBy, this.sortDir);
      }

      if(this.perPage > 0 && this.perPage < this.totalRows){
        let init = (this.page - 1) * this.perPage;
        this.rows = Utils.clone(ordered.slice(init, init + this.perPage));
      }else{
        this.rows = Utils.clone(ordered);
      }

    }

  }

}
</script>

<style lang="scss">
.vue-table-wrapper{
  border: none;

  .vue-table{

    thead{

      tr{
        background: var(--table-row-border-color) !important;
        th{
          color: var(--table-row-th-color);
        }

        //th:not(.sorting){
        //  color: white !important;
        //}
      }

    }

    tbody{
      tr{

        border-bottom: 3px solid var(--table-row-border-color) !important;
        background-color: var(--table-row-bg-color) !important;

        &:last-child, &.bordeless{
          border-bottom: none !important;
        }

        &:nth-child(odd){
          background-color: var(--table-row-odd-bg-color) !important;
        }

        &:hover{
          background-color: var(--table-row-hover-color) !important;
        }

      }
    }
  }
}
</style>

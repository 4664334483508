import Http from "@/commons/Http";

const path = 'api/endereco';

export default {

  async getCep(cep, comercial){
    comercial = comercial ? 'Comercial' : '';
    if(!cep){
      return {}
    }
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(r => r.json())
      .catch( r => r );

    let endereco = {};
    endereco['logradouro'+comercial] = response.logradouro;
    endereco['bairro'+comercial] = response.bairro;
    endereco['cidade'+comercial] = response.localidade;
    endereco['estado'+comercial] = response.uf;
    return endereco;
  },

  async buscar(estado, logradouro){

    const url = `http://ceplivre.com.br/consultar/logradouro/5c9acc2b4c1611cd9fd2a21876d583ff/${logradouro}/json`;

    const response = await fetch(url)
      .then(r => r.json())
      .then(r => r.cep )
      .catch( r => r );

    return response.filter( e => e.uf_sigla == estado ).map(e => ({
      logradouro : e.logradouro,
      bairro : e.bairro,
      cidade : e.cidade,
      estado : e.uf_sigla,
      cep : e.cep,
    }));

  },

  getEstados(){
    return [
      { id: 'AC', nome : 'Acre' },
      { id: 'AL', nome : 'Alagoas' },
      { id: 'AM', nome : 'Amazonas' },
      { id: 'AP', nome : 'Amapá' },
      { id: 'BA', nome : 'Bahia' },
      { id: 'CE', nome : 'Ceará' },
      { id: 'DF', nome : 'Distrito Federal' },
      { id: 'ES', nome : 'Espírito Santo' },
      { id: 'GO', nome : 'Goiás' },
      { id: 'MA', nome : 'Maranhão' },
      { id: 'MG', nome : 'Minas Gerais' },
      { id: 'MS', nome : 'Mato Grosso do Sul' },
      { id: 'MT', nome : 'Mato Grosso' },
      { id: 'PA', nome : 'Pará' },
      { id: 'PB', nome : 'Paraíba' },
      { id: 'PR', nome : 'Paraná' },
      { id: 'PE', nome : 'Pernambuco' },
      { id: 'PI', nome : 'Piauí' },
      { id: 'RJ', nome : 'Rio de Janeiro' },
      { id: 'RN', nome : 'Rio Grande do Norte' },
      { id: 'RS', nome : 'Rio Grande do Sul' },
      { id: 'RO', nome : 'Rondônia' },
      { id: 'RR', nome : 'Roraima' },
      { id: 'SC', nome : 'Santa Catarina' },
      { id: 'SP', nome : 'São Paulo' },
      { id: 'SE', nome : 'Sergipe' },
      { id: 'TO', nome : 'Tocantins' }
    ];
  },

  getFiltros(){
    return [
      { nome : 'CEP', fields : [ {value : '', key : 'cep', tipo : 'cep' } ] },
      { nome : 'Logradouro (Rua)', fields : [ {value : '', key : 'logradouro', tipo : 'text' } ] },
      { nome : 'Bairro (Setor)', fields : [ {value : '', key : 'bairro', tipo : 'text' } ] },
      { nome : 'Cidade', fields : [ {value : '', key : 'cidade', tipo : 'text' } ] },
      { nome : 'Estado', fields : [ {value : '', key : 'estado', tipo : 'text' } ] },
    ];
  },

  async logradouro(sort, direction, page, size, filters = {}){
    return Http.post(`api/indicador-pessoal/logradouro?sort=${sort || 'cep'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getMunicipiosFromIndicadorReal(){
    return Http.get(`${path}/municipios`).then(l => (l || []).map(s => ({id: s.nome, nome: s.nome})))
  },

}

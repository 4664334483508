<template>
	<modal id="formulario-modal-confirmacao" :hideTitle="true">

    <p v-if="msg" class="text-center mt-2" v-html="msg"></p>

    <h3 v-if="pergunta" class="text-center my-2" :class="colorClass" v-html="pergunta"></h3>

    <p v-if="observacao" class="text-center mt-2" v-html="observacao"></p>

    <div v-if="confirmacao" class="form-group text-center mt-2 mb-0">

      <input :default="false" style="position: relative; top: 3px; left: 5px;"
             type="checkbox" id="checkbox-confirmacao" v-model="confirmarAcao"/>

      <label for="checkbox-confirmacao" style="position: relative; top: 1px; left: 9px;" class="pointer" v-html="confirmacao">
      </label>

    </div>

    <template #footer>
      <button type="button" @click.prevent="yes()" class="btn btn-primary ml-auto mr-3" id="btn-yes">Sim</button>
      <button type="button" @click.prevent="no()" class="btn btn-danger mr-auto ml-3">Não</button>
    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import Modal from "@/components/Modal";

  export default {
    name: "ModalConfirmacao",
    components: {Modal},
    props: {
      pergunta : String,
      msg : String,
      confirmacao : String,
      observacao : String
    },

    data() {
      return {
        confirmarAcao : false
      }
    },

    computed:{

      darkMode(){
        return this.$root.theme == 'dark';
      },

      colorClass(){
        return this.darkMode ? 'text-white' : 'text-black';
      }

    },

    mounted() {
      FrontBusiness.setFocus(this.$el, 'btn-yes');
    },

    methods:{

      close(resultado) {
        FrontBusiness.closeModal(resultado);
      },

      yes() {
        if(!this.confirmacao || this.confirmarAcao){
          this.close('confirm');
        }else{
          FrontBusiness.showError('', 'Você precisa concordar com esta ação para prosseguir.');
        }
      },

      no() {
        this.close('cancel');
      },

    }
  }
</script>

<style>
  #formulario-modal-confirmacao{
    border-left: 5px solid #f48a06;
    border-right: 5px solid #f48a06;
  }
</style>

import Http from "@/commons/Http";

const path = 'api/indisponibilidade';

export default {

  async listar(){
    return Http.get(`${path}`);
  },

  async getById(id) {
    return Http.get(`${path}/${id}`);
  },

  async getResumoById(id) {
    return Http.get(`${path}/${id}/resumo`);
  },

  async getIndividuosByOrdem(id) {
    return Http.get(`${path}/${id}/individuos`);
  },

  async getProtocolosByOrdem(id) {
    return Http.get(`${path}/${id}/protocolos`);
  },

  async getIndisponibilidadesByOrdem(id) {
    return Http.get(`${path}/${id}/vinculos-gerados`);
  },

  async importar(file) {

    const formData = new FormData();
    formData.append("file", file);

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return Http.post(`${path}/importar`, formData, config);
  },

  async gerarProtocolo(lista, manual = false) {
    return Http.post(`${path}/gerar-protocolo/${manual}`, lista);
  },

  async atualizarIndicadores(acao = '') {
    return Http.post(`${path}/atualizar/${acao}`);
  },

  async ignorar(dto) {
    return Http.post(`${path}/ignorar`, dto, {timeout: 0});
  },

  async reativar(id) {
    return Http.get(`${path}/reativar/${id}`);
  },

  async cancelarIndisponibilidade(dto) {
    return Http.post(`${path}/cancelar-indisponibilidade`, dto, {timeout: 0});
  },

  async cancelarOrdem(id, dto) {
    return Http.post(`${path}/${id}/cancelar-ordem`, dto, {timeout: 0});
  },

  async desfazerCancelamentoOrdem(id, dto) {
    return Http.get(`${path}/${id}/desfazer-cancelamento-ordem`, {timeout: 0});
  },

  async desfazerCancelamentoIndisponibilidade(dto) {
    return Http.post(`${path}/desfazer-cancelamento-indisponibilidade`, dto, {timeout: 0});
  },

  async vincularProtocoloExistente(id, dto) {
    return Http.post(`${path}/vincular-protocolo-existente/${id}`, dto, {timeout: 0});
  },

  async desvincularProtocolo(id, protocolo) {
    return Http.get(`${path}/${id}/desvincular-protocolo/${protocolo}`);
  },

  async getChallenge() {
    return Http.get(`${path}/cnib/challenge`);
  },

  async importarCnib(dto) {
    return Http.post(`${path}/cnib/challenge`, dto);
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'ic.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/v2/pagination?sort=${sort || 'ic.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationProtocolosGerados(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/protocolos-gerados/pagination?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationProtocolosGeradosV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/protocolos-gerados/v2/pagination?sort=${sort || 'p.codigo'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationOrdensIgnoradas(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/ordens-ignoradas/pagination?sort=${sort || 'ic.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationOrdensIgnoradasV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/ordens-ignoradas/v2/pagination?sort=${sort || 'ic.cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationPendentes(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pendentes/pagination?sort=${sort || 'cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  paginationExcel(sort, direction){
    return `${path}/pagination/export-excel?sort=${sort || 'pedido'},${direction || 'desc'}`;
  },

  async paginationPendentesV2(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pendentes/v2/pagination?sort=${sort || 'cadastro'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async paginationIndicadorPessoal(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/indisponibilidade-indicador-pessoal/pagination?sort=${sort || 'v.cnib'},${direction || 'desc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async sugerirJuizo(query){
    return Http.get(`${path}/sugerir/juizo/${query}`);
  },

  async salvarInfoAdicionais(indisponibilidadeOrdem){
    const dto = {
      id: indisponibilidadeOrdem.id,
      juizo: indisponibilidadeOrdem.juizo,
    };
    if(indisponibilidadeOrdem?.solicitante?.id){
      let solicitanteId = indisponibilidadeOrdem.solicitante.id;

      dto.solicitanteId = solicitanteId;
      dto.solicitante = {id : solicitanteId}
    }
    return Http.post(`${path}/${dto.id}/salvar`, dto);
  },

  sugerirSolicitante(dto) {
    return Http.post(`${path}/sugerir-solicitante`, dto);
  },

  async edicaoLote(dto) {
    return Http.post(`${path}/edicao-lote`, dto);
  },

  async cadastroManual(dto) {
    return Http.post(`${path}/cadastro-manual`, dto);
  },

  opcoesOrigens() {
    return [
      {id: 'PROPRIETARIO', nome: 'Proprietário'},
      {id: 'RESTRICAO', nome: 'Restrição'},
      {id: 'LEGADO', nome: 'Legado'}
    ]
  }

}

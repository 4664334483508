import Http from "@/commons/Http";
import CachedApi from "@/commons/CachedApi";

const path = 'api/tag';

export default {

  async getAll(){
    return CachedApi.getCached('get-all-tags', async () => {
      return Http.get(`${path}`);
    }, false);
  },

  async getByTipo(tipo){
    return CachedApi.getCached(`get-tags-by-tipo-${tipo}`, async () => {
      return Http.get(`${path}/tipo/${tipo}`);
    }, false);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getById(id){
    return Http.get(`${path}/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async saveByName(dto){
    return Http.post(`${path}/save-by-name`, dto);
  },

  async delete(id){
    return Http.delete(`${path}/${id}`);
  },

  async getTiposVinculo(){
    return Http.get(`${path}/tipo-vinculo`);
  },

  async getByVinculo(id, tipo){
    return Http.get(`${path}/tags-vinculo/${id}/${tipo}`);
  },

}

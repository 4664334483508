<template>

  <button :type="type" :class="[{'btn' : button, 'loading' : loading}, position, 'pointer']"
          @click.stop.prevent="click()" :title="title"
          :disabled="disabled" v-click-outside="close" :data-testid="dataTestid">

    <slot :loading="loading"></slot>

    <template v-if="loading && showLoading">
      <span class="ml-1 spinner-border spinner-border-sm"></span>
    </template>

    <div class="dropdown-menu dropdown-menu-arrow p-2 mb-2 text-center"
         v-if="popover" :class="{'show' : opened, 'sub-dropdown-menu': subMenu}">

      <p class="mb-1" v-if="label">{{label}}</p>

      <div v-if="showActions" class="btn-group" role="group">
        <template v-if="!list">
          <button type="button" class="btn btn-sm mx-1" v-for="item in listActions" :key="item.label"
                  @click.prevent.stop="action(item.action)" :class="item.class" :id="item.id" :data-testid="`${item.label}`">
            {{item.label}}
          </button>
        </template>

        <div v-else>
          <div v-for="item in listActions">
            <div v-if="!item.subActions">
              <v-button  class="icon d-block p-1 text-left w-100" :button="false" :key="item.label"
                        :class="item.class" :run="action"
                        :params="[item.action]"
                        :popover="item.popover"
                        :subMenu="true"
                        position="dropright"
                        :data-testid="dataTestid">
                <i :class="item.icon" v-if="item.icon" :id="item.id"></i>
                {{item.label}}
              </v-button>
            </div>
            <div v-else>
              <v-button v-if="item.subActions" :actions="item.subActions" :button="false" :list="true" :popover="true" :showLoading="false"
                        :class="'icon d-block p-1 text-left w-100 ' + item.class"
                        label="" position="dropright">
                <i :class="item.icon" v-if="item.icon" :id="item.id"></i>
                {{item.label}}
              </v-button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </button>

</template>

<script>

import utils from "@/commons/Utils";

export default {
    name: "V-Button",

    props: {
      type : {type : String, default : 'button'},
      run : Function,
      params : {type: Array, default: () => []},
      button : {type: Boolean, default: true},
      showLoading : {type: Boolean, default: true},
      popover : {type: Boolean, default: false},
      subMenu : {type: Boolean, default: false},
      position : {type : String, default : 'dropup'},
      label : {type: String, default: 'Você tem certeza?'},
      actions : Array,
      title: String,
      desabilitado : Boolean,
      showActions : {type: Boolean, default: true},
      list: {type: Boolean, default: false},
      dataTestid: String,
    },

    computed:{

      listActions(){
        return this.actions ? this.actions : [
          {class : 'btn-danger', id : 'cancelar', label : 'Cancelar', action : this.close},
          {class : 'btn-primary', id : 'confirmar', label : 'Confirmar', action : this.run}
        ];
      },

      disabled(){
        return this.desabilitado || this.loading;
      },

    },

    data() {
      return {
        uuid : utils.uuid(),
        loading : false,
        opened : false
      }
    },

    methods:{

      async close(){
        this.$set(this, 'opened', false);
      },

      async click(){
        let el = document.getElementsByClassName('sub-dropdown-menu');
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove('show');
        }

        if(this.disabled){
          return;
        }

        if(this.popover){
          this.opened = !this.opened;
          return;
        }

        await this.action(this.run);
      },

      async action(fnc = () => {}){
        if(this.disabled){
          return;
        }
        try{
          await this.close();
          this.$set(this, 'loading', true);
          await fnc(...this.params);
        }finally {
          this.$set(this, 'loading', false);
        }
      }
    }
  }
</script>

<style lang=scss>

</style>

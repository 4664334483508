<template>
  <card id="cardVersao" titulo="Versões">
    <card-table class="table-borderless mb-2" :dragscroll="true" :cardClassName="cardClassName" v-if="versoes.length > 0">
      <thead>
      <tr>
        <th></th>
        <th>{{consulta ? 'Utilizar' : ''}}</th>
        <th v-if="!consulta"></th>
        <th>Atos</th>
        <th v-for="(title, index) in titles" :key="index">{{title}}</th>
      </tr>
      </thead>

      <tbody>

      <tr v-for="(versao, i) in versoes" :key="versao.id">
        <td width="30px" class="text-center p-0">
          <v-button class="icon p-0" :run="remove" :params="[versao, i]" :button="false" :popover="true" title="Excluir Versão">
            <i class="fa fa-times"></i>
          </v-button>
        </td>
        <td width="30px" class="text-center p-0">
          <v-button class="icon p-0" :popover="!consulta && selecionado != versao.id" :run="selecionar" :params="[versao]" :title="consulta ? '' : (selecionado == versao.id ? 'Versão Atual' : 'Definir como Versão Atual')">
            <i class="far" :class="{'fa-check-circle text-green' : selecionado == versao.id, 'fa-circle' : selecionado != versao.id}"></i>
          </v-button>
        </td>
        <td width="30px" v-if="!consulta" class="text-center p-0">
          <v-button class="icon p-0" :run="editarIndicador" :params="[versao]" :button="false" title="Editar Versão">
            <i class="fa fa-edit"></i>
          </v-button>
        </td>
        <td width="40px" class="text-center p-0">
          <a title="Ver Atos" class="pointer p-0" @click.prevent="openAtosVersao(versao)"><i class="fal fa-eye"></i></a>
        </td>

        <td>{{versao.versao}}</td>
        <td>{{versao.nome}}</td>
        <td>{{versao.documento}}</td>

        <template v-if="pessoa.tipoPessoa == 'FISICA'">
          <td class="text-center">{{versao.expostaPoliticamente ? 'Sim' : 'Não'}}</td>
          <td class="text-center">{{versao.falecido ? 'Sim' : 'Não'}}</td>
          <td>{{labelSexo(versao.sexo)}}</td>
          <td>{{$formatters.date(versao.nascimento)}}</td>
          <td>{{labelNacionalidade(versao.nacionalidade)}}</td>
          <td>{{versao.profissao}}</td>
          <td>{{versao.nome_pai}}</td>
          <td>{{versao.nome_mae}}</td>
          <td>{{labelEstadosCivil(versao.estado_civil)}}</td>
          <td class="text-center">{{versao.possui_uniao_estavel ? 'Sim' : 'Não'}}</td>
          <td>{{versao.conjugeNome}}</td>
          <td class="text-center">{{versao.casamento_apos_lei6515 ? 'Sim' : 'Não'}}</td>
          <td class="text-center">{{$formatters.date(versao.data_casamento)}}</td>
          <td>{{labelRegimeCasamento(versao.regime_casamento)}}</td>
          <td>{{versao.detalhes_regime}}</td>
          <td>{{versao.rg}}</td>
          <td>{{versao.cnh}}</td>
          <td>{{versao.cip}}</td>
          <td>{{versao.passaporte}}</td>
          <td>{{versao.outros_documentos}}</td>
        </template>

        <template v-if="pessoa.tipoPessoa == 'JURIDICA'">
          <td>{{versao.inscricaoEstadual}}</td>
          <td>{{versao.inscricaoMunicipal}}</td>
          <td class="text-center">{{versao.possui_participacao_estrangeira ? 'Sim' : 'Não'}}</td>
        </template>

        <td>{{versao.cep}}</td>
        <td>{{versao.logradouro}}</td>
        <td>{{versao.numero}}</td>
        <td>{{versao.lote}}</td>
        <td>{{versao.quadra}}</td>
        <td>{{versao.bairro}}</td>
        <td>{{versao.cidade}}</td>
        <td>{{versao.estado}}</td>
        <td>{{versao.complemento}}</td>
        <td>{{labelPais(versao.pais)}}</td>

        <template v-if="pessoa.tipoPessoa == 'FISICA'">
          <td>{{versao.cepComercial}}</td>
          <td>{{versao.logradouroComercial}}</td>
          <td>{{versao.numeroComercial}}</td>
          <td>{{versao.loteComercial}}</td>
          <td>{{versao.quadraComercial}}</td>
          <td>{{versao.bairroComercial}}</td>
          <td>{{versao.cidadeComercial}}</td>
          <td>{{versao.estadoComercial}}</td>
          <td>{{versao.complementoComercial}}</td>
          <td>{{labelPais(versao.paisComercial)}}</td>
        </template>

        <td>{{versao.id}}</td>
        <td>{{$formatters.datetime(versao.cadastro)}}</td>
      </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading"></small-loading>

    <div v-if="!versoes.length && !loading" class="alert alert-outline-info">
      Não há versões para exibir
    </div>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
  import AtosVersao from "@/components/IndicadorPessoal/Detalhes/AtosVersao";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";
  import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
  import SiscoafBusiness from "@/business/protocolo/SiscoafBusiness";

  export default {

        name: "Versões",
        components: {SmallLoading, CardTable, Card},
        props: {
            pessoa: Object,
            consulta: Boolean
        },

        data() {

            let title = ['Versão', 'Nome'];
            if (this.pessoa.tipoPessoa == 'FISICA') {
                title.push('CPF', 'Pessoa Exposta Politicamente', 'Pessoa Falecida','Sexo', 'Data de Nascimento', 'Nacionalidade', 'Profissão', 'Nome do Pai', 'Nome da Mãe', 'Estado Civil', 'União Estável',
                    'Cônjuge/Convivente', 'Após Lei nª 6.515', 'Data Casamento/União Estável', 'Regime', 'Detalhes do regime',
                    'RG', 'CNH', 'Identidade Profissional', 'Passaporte', 'Outros Documentos');
            } else {
                title.push('CNPJ','Inscrição Estadual','Inscrição Municipal', 'Participação Estrangeira');
            }

            title.push('CEP', 'Logradouro (Rua)', 'Número', 'Quadra', 'Lote', 'Bairro (Setor)', 'Cidade', 'Estado', 'Complemento', 'País');

            if (this.pessoa.tipoPessoa == 'FISICA') {
                title.push('CEP Comercial', 'Logradouro (Rua) Comercial', 'Número Comercial', 'Quadra Comercial', 'Lote Comercial', 'Bairro (Setor) Comercial', 'Cidade Comercial', 'Estado Comercial', 'Complemento Comercial', 'País');
            }

            title.push('ID', 'Cadastro');

            return {
                versoes: [],
                cardClassName: {'card-table-scroll': true},
                titles: title,
                loading: true,
            }
        },

        computed: {
          selecionado() {
            return this.consulta ? this.pessoa.id : this.pessoa.indicadorPessoal?.versaoAtualId || this.pessoa.id
          },
        },

        async mounted() {
            await this.load();
        },
        methods: {
            async load(){
              this.loading = true;
              this.versoes = await IndicadorPessoalBusiness.getVersoesAll(this.pessoa.indicadorPessoal.id);
              this.loading = false;
            },

            async openAtosVersao(versao = {}) {
                await FrontBusiness.openModal(AtosVersao, {id: versao.id, versao: versao});
            },

            labelSexo(item) {
                return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getSexos());
            },

            labelEstadosCivil(item) {
                return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getEstadosCivil());
            },

            labelRegimeCasamento(item) {
                return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getRegimes());
            },

            labelNacionalidade(item) {
                return FrontBusiness.getLabel(item, IndicadorPessoalBusiness.getNacionalidades());
            },

            labelPais(idNacionalidade) {
                return IndicadorPessoalBusiness.getLabelPais(idNacionalidade);
            },

            async selecionar(versao){
              if (this.consulta) {
                this.$emit('selecionar', versao.id);
              } else {
                if (this.selecionado !== versao.id) {
                  await IndicadorPessoalBusiness.updateVersaoAtual(versao.indicadorPessoalId, versao.id, versao.versao).then(response => {
                    this.$emit('versaoAberta', versao.id);
                  }).catch(e => FrontBusiness.showError('', e.message));
                }
              }
            },

            update(){
              this.$emit('update', this.versoes);
            },
            async remove(versao, i){
              let verificacao = await IndicadorPessoalBusiness.getVinculoSiscoaf(versao.id);
              if(verificacao.length){
                let msg = "Não é possível excluir uma versão do Indicador Pessoal que está em uso. Indicador vinculado ao SISCOAF:  "
                let v;
                verificacao.forEach(p => {
                  v = (v == null ? "" : ", ");
                  msg += v + FrontBusiness.nomearProtocolo(p.dominio, p.tipoProtocolo, p.codigo, p.tipoCadastro);
                });
                FrontBusiness.showError(msg);
                return
              }
              await IndicadorPessoalBusiness.deleteByIdVersao(versao.id).then(response => {
                this.versoes.splice(i, 1);
              }).catch(e => FrontBusiness.showError('', e.message));
              this.update();
            },

            async editarIndicador(versao) {
              let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: versao.id});
              if(retorno){
                await this.load();
              }
            },
        }
    }
</script>

<style lang=scss>
  .card-table-scroll {
    padding-bottom: 5px !important;
    overflow: auto !important;

    table {
      width: max-content;

      th, td {
        padding: 0.75rem 1rem;
      }
    }
  }
</style>

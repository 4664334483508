<template>
  <modal titulo="Imprimir" id="modal-imprimir">

    <card>
      <card-content>

        <div class="row no-gutters w-100">

          <v-input type="select" :options="layouts" label="Layout" v-model="form.layout" class="col px-1" />

          <v-input label="Livro" v-model="form.livro" type="text" class="col-4 px-1" />
          <div class="w-100" />

          <v-input :defaut="false" label="Incluir texto externo? (Matrículas importadas com texto externo)"
                   v-model="form.incluirTextoLegado" type="radio" class="col px-1" />

<!--          <div class="w-100"></div>-->

<!--          <v-input label="Incluir linhas de Encerramento?" v-model="form.linhaEncerramento" type="radio" class="col" />-->

<!--          <template v-if="form.linhaEncerramento">-->
<!--            <v-input type="number" label="Quantidade de Linhas" class="col" v-model="form.quantidadeLinha" />-->
<!--          </template>-->
        </div>

        <card-table class="mt-1 mb-3">
          <tbody>
            <tr v-for="(ficha, i) in fichas" :key="`${ficha.livro}-${ficha.id}`">
              <td>
                {{nomearFicha(ficha)}}
              </td>
              <td width="30px">
                <a @click.prevent="removerFichas(i)" class="icon">
                  <i class="fa fa-times"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td colspan="100%">
                <buscar-fichas :limparCampo="true" :livro="livro"
                               status="ABERTA" ref="buscarFichas" @selecionar="selecionarFichas" />
              </td>
            </tr>
          </tbody>

        </card-table>

      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Cancelar</button>
      <v-button :run="marcarAtosImpressos" class="btn-outline-primary ml-auto">Marcar como impresso</v-button>
      <v-button :run="run" class="btn-outline-primary ml-2">Visualizar</v-button>
    </template>

  </modal>
</template>

<script>
import LayoutImpressaoBusiness from "@/business/crud/LayoutImpressaoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";
import moment from "moment";

export default {
    name: "ImprimirFichaLote",
    components: {CardTable, CardContent, Card, Modal},

    props: ['livro'],

    async mounted() {

      let promises;
      if (this.livro == 'REGISTRO_AUXILIAR') {
        promises = [/*LayoutImpressaoBusiness.listByTipo('REGISTRO_AUXILIAR_V2'),*/ LayoutImpressaoBusiness.listByTipo('REGISTRO_AUXILIAR_V3')];
      } else {
        promises = [/*LayoutImpressaoBusiness.listByTipo('MATRICULA_V2'),*/ LayoutImpressaoBusiness.listByTipo('MATRICULA_V3')];
      }

      let r = await Promise.all(promises);
      this.layouts = r[0];
      this.$set(this.form, 'layout', this.layouts?.[0]?.id);

    },

    modal: {
      // width: 400,
      escapable: true
    },

    data() {
      return {
        loading: false,

        layouts: [],
        fichas:[],
        template: '',
        form: {

          layout: null,
          indicadores:[],

          completo: true,
          parcial: false,
          linha : false,
          linhaEncerramento: false,
          incluirTextoLegado: false,
          quantidadeLinha: 1,

        },

      }
    },

    methods: {

      close(response) {
        FrontBusiness.closeModal(response);
      },

      async run() {

        try{

          if(!this.fichas?.length){
            return;
          }

          const dto = {
             incluirTextoLegado : this.form.incluirTextoLegado,
             impressaoCompleta : this.form.completo,
             livro : this.form.livro,
             ids: this.fichas.map(e => e.id)
          }

          console.debug('config', dto);

          let pdf = await LayoutImpressaoBusiness.imprimirFichaLote(this.livro, this.form.layout, dto);
          FrontBusiness.downloadFile(pdf, 'impressao.pdf');

        }catch (e){
          console.error(e);
        }
      },

      async marcarAtosImpressos() {

        try{
          if(!this.fichas?.length){
            return;
          }

          const ids = this.fichas.map(e => e.id);

          await AtoBusiness.marcarImpressosLote(this.livro, true,ids)
          FrontBusiness.showSuccess('', 'Atos marcados como impressos com sucesso!');
        }catch (e){
          console.error(e);
        }

      },

      nomearFicha: FrontBusiness.nomearFicha,

      removerFichas(idx) {
        this.$nextTick(() => {
          this.fichas.splice(idx, 1);
        });
      },

      selecionarFichas(fichas) {
        this.$nextTick(() => {
          fichas?.forEach((ficha) => {
            if (ficha && !this.fichas.find(e => e.id === ficha.id && e.livro === ficha.livro)) {
              this.fichas.push(ficha);
            }
          });
        });
      },


    }
  }
</script>

import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/caixa';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids){
    return Http.post(`${path}/simple`, ids);
  },

  async getCaixaPorId(ids) {
    return await Http.post(`${path}/ids`, ids);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

  async setConciliado(dto){
    if(dto.entidade == 'TRANSFERENCIA'){
      return Http.post(`${path}/conciliado/${dto.entidade}/${dto.transferenciaId}`);
    }else{
      return Http.post(`${path}/conciliado/${dto.entidade}/${dto.id}`);
    }
  },

  async getExtratoPeriodo(caixa, inicio, fim){
    return Http.get(`${path}/${caixa}/extrato/${inicio}/${fim}`);
  },

  async getExtrato(caixa, inicio, fim, conciliacao, transacao, regime){
    return Http.get(`${path}/${caixa}/extrato/${inicio}/${fim}/${conciliacao}/${transacao}/${regime}`);
  },

  async getSaldos(){
    return Http.get(`api/caixa/saldos`);
  },

  async getCaixasInativos(){
    return Http.get(`api/caixa/caixasInativos`);
  },

  async getSaldoAdiantamentos() {
    return Http.get(`api/caixa/saldoAdiantamentos`);
  }

}

<template>

  <modal :titulo="titulo" :loading="loading">
    <card>
      <div class="row gutters-xs">
        <v-input v-if="tipoProtocolo == 'ORCAMENTO'" type="select" :options="options.tiposDominio" label="Tipo" v-model="form.dominio" class="col-2" />
        <v-input type="text" label="Código" :error="validation.codigo" v-model="form.codigo" class="col-3" />
        <v-input v-if="$root.config.geral.permitirIncluirLetraCodigoProtocolo" type="text" label="Letra" :error="validation.letra" v-model="form.letra" class="col-3" />
        <v-input type="datetime-local" label="Data Cadastro" v-model="form.cadastro" :error="validation.cadastro" class="col-3"  />
        <v-input type="select" :options="options.status" label="Status" v-model="form.status" :error="validation.status" class="col-3" />
      </div>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="salvar" :popover="true" class="btn-outline-primary">Salvar</v-button>
    </template>

  </modal>

</template>

<script>

import FrontBusiness from "@/business/FrontBusiness.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "ModalFichaAntiga",
  components: {Card, Modal},
  props:{
    dominio: String,
    tipoProtocolo: String,
    tipoCadastro: String,
  },

  computed: {

    titulo() {
      return 'Cadastrar ' + FrontBusiness.nomearProtocolo(this.dominio, this.tipoProtocolo) + ' Manualmente';
    },
  },

  data(){
    return {
      loading: false,
      validation:{},
      form:{
        dominio: this.dominio,
        tipoProtocolo: this.tipoProtocolo,
        tipoCadastro: this.tipoCadastro,
      },
      options: {
        status: [{id: 'CANCELADO', nome: 'Cancelado'}, {id: 'FINALIZADO', nome: 'Finalizado'}],
        tiposDominio: DominioBusiness.getSimpleDominiosAtivos()
      }
    }
  },

  methods:{

    async salvar(){
      this.$set(this, 'validation', {});
      let validated = true;
      let dto = utils.clone(this.form);
      ['codigo', 'cadastro', 'status', 'dominio', 'tipoProtocolo'].forEach(e => {
        if (!dto[e]) {
          this.$set(this.validation, e, 'Campo obrigatório');
          validated = false;
        }
      });
      if(validated){
        let codigos = utils.stringCodigosToArray(dto?.codigo)
        let temp = utils.clone(dto)
        dto = codigos.map((c) => {
          return {...temp, ...{codigo: c,}}
        })
        let r = await this.$api.post(`api/protocolo/retroativa`, dto).catch(this.showError);
        if(r){
          this.close(r);
        }
      }
    },

    close(response) {
      FrontBusiness.closeModal(response);
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '');
    },

  }

}
</script>

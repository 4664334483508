//https://github.com/greim/html-tokenizer#parserparsehtml
import Parser from 'html-tokenizer/parser';

const TEXT = 'TEXT', OPEN = 'OPEN', CLOSE = 'CLOSE', ERROR = 'ERROR', SELF_CLOSING = 'SELF_CLOSING';

window.cacheHtml = {};

export default {

  tokens(input){

    if(window.cacheHtml?.[input]){
      return window.cacheHtml?.[input];
    }

    const iterator = Parser.parse(input);

    let stack = [];
    let ast = [];
    let parent = null;

    for (const token of iterator) {

      if(token.type == 'start' || token.type == 'done'){
        continue;
      }

      token.type = token.type.toUpperCase();
      token.children = [];

      if(token.selfClosing && token.type == CLOSE){
       continue;
      }

      if(token.selfClosing){
       token.type = SELF_CLOSING;
      }

      token.params = {...token.attributes};
      delete token.attributes;

      token.body = token.text;
      delete token.text;

      // pula nós vazios de texto
      // if (this.options.skipWhiteSpace && token.body.replace(/\s+/g, '').length === 0) {
      //   continue
      // }

      if (token.type === SELF_CLOSING || token.type === TEXT) {

        if(token.type === SELF_CLOSING){
          const params = Object.keys(token.params).map(k => `${k}="${token.params[k]}"`).join(' ');
          token.body = `<${token.name} ${params} />`;
        }

        (parent) ? parent.children.push(token) : ast.push(token);

      } else if (token.type === OPEN) {

        const params = Object.keys(token.params).map(k => ` ${k}="${token.params[k]}"`).join('');
        token.body = `<${token.name}${params}>`;

        if (!parent) {
          parent = token;
          ast.push(parent);
        } else {
          parent.children.push(token);
          stack.push(parent);
          parent = token;
        }

      } else if (token.type === CLOSE) {

        if(parent){ //!token.canClose(parent)
          parent.isClosed = true;
          parent = stack.pop();
        }else{

          throw new SyntaxError('Unmatched close token: ' + token.body);

        }

      } else {
        /* istanbul ignore next */
        throw new SyntaxError('Unknown token: ' + token.type);
      }

    }

    if (parent) {
      throw new SyntaxError('Unmatched open token: ' + parent.body);
      // if (this.options.strict) {
      //   throw new SyntaxError('Unmatched open token: ' + parent.body);
      // } else {
      //   ast.push(new Token(ERROR, token.body));
      // }
    }

    window.cacheHtml[input] = ast;

    return ast

  }

}

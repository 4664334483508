<template>
  <modal titulo="Excluir Unidades em Lote" id="apagar-unidades" :fechar="true" @close="close" :loading="loading">

    <div class="row mx-0">
      <card titulo="Selecione as unidades para excluir:" class="col p-0">
        <div class="div-select-all">
          <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
            <i class="ml-2 fal fa-check-circle"></i>
          </a>
          <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
            <i class="ml-2 fal fa-circle"></i>
          </a>
        </div>
        <div class="form-group ml-3 mt-2 mb-0" v-for="(unidade, j) in unidades" :key="j">
          <template v-if="unidade.possuiVinculos && unidade.possuiVinculos.length">
            <p class="alert alert-danger mb-2 pointer">
              A {{nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo, status : unidade.status })}} ainda possui vínculos com:
              <span v-for="(documento, d) in unidade.possuiVinculos" :key="documento.id"> {{nomearProtocolo(documento.dominio, documento.tipoProtocolo, documento.codigo)}}{{d + 1 === unidade.possuiVinculos.length ? '' : ', '}}</span>
            </p>
          </template>
          <input style="position: relative; top: 1px; left: 0;" type="checkbox" :id="unidade.id" :disabled="sucessoAoExcluir.includes(unidade.id)"
                 v-model="selecionados['unidade_' + unidade.id]" :value="selecionados['unidade_' + unidade.id]" :checked="true"/>
          <label :for="unidade.id" :title="nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo, status : unidade.status })" style="position: relative; top: 0px; left:4px;"
                 class="pointer">
            {{nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo, status : unidade.status })}}
          </label>

          <div class="float-right" v-if="(lotes.total > 0) || (sucessoAoExcluir.length > 0 || Object.keys(errosAoExcluir).length > 0)">
            <a v-if="sucessoAoExcluir.includes(unidade.id)" class="icon text-green" title="Unidade Excluída">
              <i class="fas fa-check-double"></i>
            </a>
            <AlertIcon class="small" :message="errosAoExcluir[unidade.id]" v-else-if="errosAoExcluir[unidade.id]"/>
            <small-loading v-else-if="exclusaoProcessada[unidade.id]"></small-loading>
          </div>

          <div class="small text-muted" style="margin-left: 16px; width: calc(100% - 16px) !important;">
            <span>{{labelTipoImovel(unidade)}} <template v-if="unidade.descricaoTipoImovelOutros && unidade.descricaoTipoImovelOutros !== labelTipoImovel(unidade)"> - {{unidade.descricaoTipoImovelOutros}}</template></span>
            <span v-if="unidade.unidade" class="font-weight-500"> | Unidade: </span><span>{{unidade.unidade}}</span>
            <span v-if="unidade.bloco" class="font-weight-500"> | Bloco: </span><span>{{unidade.bloco}}</span>
            <span v-if="unidade.lote" class="font-weight-500"> | Lote: </span><span>{{unidade.lote}}</span>
            <span v-if="unidade.quadra" class="font-weight-500"> | Quadra: </span><span>{{unidade.quadra}}</span>
          </div>
        </div>
      </card>
    </div>

    <template #footer>
      <button type="button" @click.prevent="close(true)" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="remover" :popover="true" class="btn-primary" :desabilitado="loading">
        <template #default="{ loading }">
          <template v-if="loading">
            Excluindo: {{lotes.atual}}/{{lotes.total}}
          </template>
          <template v-else>
            Excluir
          </template>
        </template>
      </v-button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils.js";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal";


export default {
    name: "ModalApagarUnidades",
    components: {
      Card, Modal,
      SmallLoading: () => import("@/components/SmallLoading"),
      AlertIcon: () => import("@/components/alert/AlertIcon"),
    },
    props: {
      unidadesPendentes: Array
    },

    modal: {
      width: 600
    },

    data() {
      return {
        loading: false,
        dto: {},
        unidades: [],
        selecionados: {},
        mostrar: true,
        tiposImovel: IndicadorRealBusiness.getTiposImovel(),

        lotes : {
          total : 0,
          atual : 0
        },

        errosAoExcluir: {},
        sucessoAoExcluir: [],
        exclusaoProcessada: [],

      }
    },

    computed: {},

    mounted() {
      this.unidades = this.unidadesPendentes;
      this.initValues();
    },

    methods: {

      async initValues() {
        this.loading = true;
        this.$set(this, 'selecionados', {});
        this.unidades.forEach((unidade) => {
          this.$set(this.selecionados, 'unidade_' + unidade.id, false);
        })
        this.loading = false;
      },

      selecionarTodos(limpar) {
        for (let a in this.selecionados) {
          this.$set(this.selecionados, a, !limpar ? true : false)
        }
      },
      nomearProtocolo : FrontBusiness.nomearProtocolo,

      nomearFicha: FrontBusiness.nomearFicha,

      async close(e) {
        FrontBusiness.closeModal(e);
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#apagar-unidades');
      },

      labelTipoImovel(unidade) {
        return this.tiposImovel.find(e => e.id == unidade.tipoImovel)?.nome;
      },

      async remover() {
        let selecionado = Object.keys(this.selecionados).find(key => this.selecionados[key] === true);
        let unidades = [];
        let unidadesInicial = Utils.clone(this.unidades)
        if (selecionado) {
          for (let d in this.selecionados) {
            if (this.selecionados[d]) {
              unidades.push(d.split('_')[1])
            }
          }
          this.lotes.total = unidades.length;
          this.lotes.atual = 0;
          this.errosAoExcluir = {};
          this.sucessoAoExcluir = [];

          let promises = unidades.map(id => IndicadorRealBusiness.removerUnidade(id).then(l => {
            this.sucessoAoExcluir.push(id);
            unidadesInicial = unidadesInicial.filter(unidade => unidade.id !== id);
          }).catch(e => {
            console.debug(e);
            this.$set(this.errosAoExcluir, id, e.message);
            unidadesInicial.forEach(unidade => {
              if(unidade.id === id){
                unidade.possuiVinculos = e.dto;
              }
            });
          }).finally(() => {
            this.lotes.atual++;
            this.exclusaoProcessada.push(id);
          }));

          await Promise.all(promises);
          unidades = unidadesInicial;
          if (Object.keys(this.errosAoExcluir).length > 0) {
            FrontBusiness.showWarn('', `Processo de exclusão finalizado com erros`);
          } else {
            FrontBusiness.showSuccess('', `Processo de exclusão finalizado com sucesso`);
            await this.close(true);
          }
          this.unidades = unidades;
          await this.initValues();
        } else {
          FrontBusiness.showError('Atenção!', 'Selecione as unidades para excluir', 'app', 5000);
        }
      },

    }
  }
</script>

<style lang=scss>
  #apagar-unidades {
    .card-title {
      /*white-space: nowrap;*/
      /*overflow: hidden;*/
      /*text-overflow: ellipsis;*/
      line-height: 1.2rem;
    }

    .div-select-all {
      position: absolute;
      top: 8px;
      right: 17px;
    }
  }
</style>

<template>
  <modal titulo="Digitalização em Lote" id="modal-imprimir">

    <card>
      <card-content>

        <ul class="mb-5" v-show="files.length">
          <li v-for="file in files" :key="file.id">
            <span>{{file.name}}</span> -
            <span>{{file.size | formatSize}}</span>
            <span v-if="file.error" class="text-red"> - {{file.error}}</span>
            <span v-else-if="file.success" class="text-success"> - OK</span>
            <span v-else-if="file.active"> - enviando...</span>
            <span v-else>
            <a class="icon text-red pointer ml-2 small" @click.prevent="$refs.upload.remove(file)">
              <i class="fal fa-trash"></i>
            </a>
          </span>
          </li>
        </ul>

        <div class="example-btn" v-show="files.length">
          <v-input v-model="form.tipo" :options="tipo" :default="1" type="radio"/>

          <file-upload
            class="btn btn-outline-primary mx-1 pointer"
            :custom-action="upload"
            :multiple="true"
            :drop="true"
            :drop-directory="true"
            v-model="files"
            :thread="1"
            accept="image/tif,image/tiff"
            extensions="tif,tiff"
            ref="upload">
            <i class="fa fa-plus pointer"></i>
            Adicionar Arquivos
          </file-upload>

          <button type="button" class="btn btn-outline-success mx-1 pointer" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
            Começar o Envio
          </button>

          <button type="button" class="btn btn-outline-danger mx-1 pointer"  v-else @click.prevent="$refs.upload.active = false">
            <i class="fa fa-stop" aria-hidden="true"></i>
            Parar o Envio
          </button>

        </div>

        <template v-if="!files.length">

          <div class="text-center pt-3">

            <label for="file" class="btn btn-lg btn-outline-primary">
              <i class="fal fa-upload mr-2"></i> Selecionar Arquivos
            </label>

            <div class="pt-2 text-muted">ou arraste e solte os arquivos aqui</div>

          </div>

        </template>

        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Solte os arquivos aqui</h3>
        </div>

      </card-content>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-2">Fechar</button>
    </template>

  </modal>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";
import FileUpload from 'vue-upload-component'

export default {
    name: "DigitalizarFichaLote",
    components: {CardContent, Card, Modal, FileUpload},

    props: ['livro'],

    async mounted() {
    },

    modal: {
      // width: 400,
      escapable: false
    },

    data() {
      return {
        files: [],
        inputFile : null,
        load : null,
        form: {},
        tipo: [{id: 1, nome: 'Manter todas'}, {id: 2, nome: 'Substituir todas'}, {id: 3, nome: 'Substituir apenas a última'}]
      }
    },

    methods: {

      close(response) {
        FrontBusiness.closeModal(response);
      },

      async upload(file) {

        const formData = new FormData();
        formData.append("file", file.file);
        formData.append("tipo", this.form.tipo);

        console.debug(formData, file.file)
        let config = {
          timeout: 0,
          headers: {'Content-Type': 'multipart/form-data'}
        };

        return this.$api.post(`api/digitalizacao/${this.livro}`, formData, config);

      },

    }
  }
</script>

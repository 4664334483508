<template>
  <span>
      {{tipoImovelStr}}
      <template v-for="campo in campos">
        <span :key="campo.key" v-if="imovel[campo.key]"> | <b v-if="campo.nome">{{campo.nome}}:</b> {{imovel[campo.key]}}</span>
      </template>

      <span v-if="imovel.area"> | <b>Área total:</b> {{$formatters.currency(imovel.area,{min : 2, max : 10})}} {{unidadeArea}}</span>

  </span>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";

export default {
  name: "ResumoIndicadorReal",
  props:{
    imovel: Object
  },

  computed:{
    tipoImovel(){
      return IndicadorRealBusiness.getTiposImovel().find(e => e.id == this.imovel.tipoImovel)?.nome
    },

    tipoImovelStr(){
      if(!this.tipoImovel && this.imovel.descricaoTipoImovelOutros){
        return this.imovel.descricaoTipoImovelOutros;
      }
      if(this.tipoImovel && !this.imovel.descricaoTipoImovelOutros){
        return this.tipoImovel;
      }
      if(this.tipoImovel?.toLowerCase() == this.imovel?.descricaoTipoImovelOutros?.toLowerCase()){
        return this.tipoImovel;
      }
      return `${this.tipoImovel} (${this.imovel.descricaoTipoImovelOutros})`;
    },

    unidadeArea(){
      return this.imovel.medidaArea ? FrontBusiness.getLabel(this.imovel.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
    }

  },

  data(){
    return {
      campos:[
        {nome: 'Unidade', key: 'unidade'},
        {nome: 'Localização', key: 'localizacao'},
        {nome: 'Empreendimento/Condomínio', key: 'empreendimento'},
        {nome: 'Andar', key: 'andar'},
        {nome: 'Bloco', key: 'bloco'},
        {nome: 'Lote', key: 'lote'},
        {nome: 'Quadra', key: 'quadra'},
        {nome: 'Logradouro', key: 'logradouro'},
        {nome: 'N°', key: 'numero'},
        {nome: 'Bairro', key: 'bairro'},
        {nome: 'Cidade', key: 'cidade'},
      ]
    }
  },

  methods:{

  }
}
</script>

<template>
  <modal titulo="Cancelar Indisponibilidade">

    <card>
      <form @submit.prevent="" class="row gutters-xs form">
        <v-input type="textarea" label="Motivo" class="col-12" :error="validation.motivo" v-model="form.motivo" />
      </form>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <v-button :run="run" class="btn-outline-primary" :popover="true">Salvar</v-button>
    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import Modal from "@/components/Modal";

  export default {
    name: "ModalCancelarIndisponibilidade",
    components: {Card, Modal},

    props: {
      indisponibilidadeIndividuos: Array,
      ids : Array,
    },

    data() {
      return {
        form: { motivo : '' },
        validation: { motivo: null }
      }
    },

    methods: {

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '');
      },

      close(r) {
        FrontBusiness.closeModal(r);
      },

      async run() {
        this.$set(this, 'validation', {});
        if(!this.form.motivo.length) {
          return this.$set(this.validation, 'motivo', 'Campo Obrigatório');
        }
        let dto = {
          motivo: this.form.motivo,
          individuos: this.indisponibilidadeIndividuos,
          indisponibilidades: this.ids
        }

        let retorno = await IndisponibilidadeBusiness
          .cancelarIndisponibilidade(dto)
          .catch(this.showError);

        if(retorno){
          FrontBusiness.showSuccess('', 'Indisponibilidades canceladas');
          this.close(true);
        }
      },

    }

  }
</script>

<template>
  <div>
    <template v-if="loading">
      <span class="ml-1 spinner-border spinner-border-sm"></span>
    </template>
    <i class="pointer far" @click.prevent="toggle()"
       :class="{'fa-angle-up' : mostrar, 'fa-angle-down' : !mostrar}"></i>
  </div>
</template>

<script>
  export default {
    name: "Toggle",
    props: {
      mostrar : Boolean,
      chave : String,
      run : Function,
      params : {type: Array, default: () => []},
      salvarMostrar : Boolean
    },
    async mounted() {
      try {
        if (!!this.chave && localStorage.getItem(this.key) && this.salvarMostrar) {
          await this.run(JSON.parse(localStorage.getItem(this.key)))
        }
      } catch (e) {
        console.error(e)
      }
    },
    computed:{
      key(){
        return `toggle-${this.chave}`;
      }
    },
    data() {
      return {
        loading : false,
      }
    },
    methods:{
      async toggle() {
        try {
          this.$set(this, 'loading', true);
          await this.run(...this.params);
        } finally {
          this.$set(this, 'loading', false)
        }
        if(this.salvarMostrar) {
          localStorage.setItem(this.key, this.mostrar);
        }
      }
    }
  }
</script>

<style lang=scss>

</style>

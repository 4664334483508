import Http from "@/commons/Http";
import FrontBusiness from "@/business/FrontBusiness";

const path = 'api/indicador-real';
const secondaryPath = '/unidade';

export default {

  getCamposObrigatoriosIndicador(){
    return [
      {id: 'categoria', nome: 'A categoria do indicador é obrigatória'},
      {id: 'tipoImovel', nome: 'O tipo do imóvel é obrigatório'},
    ]
  },

  async upload(file, indicadorRealId, configuracao){
    const formData = new FormData();
    formData.append("file", file);
    formData.append("configuracao", new Blob([JSON.stringify(configuracao)], {
          type: "application/json"
    }));

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': undefined
      },
    };

    return Http.post(`${path}/${indicadorRealId}/${secondaryPath}/upload-planilha`, formData, config);

  },

  async preview(file, indicadorRealId, configuracao){
    const formData = new FormData();
    formData.append("file", file);
    formData.append("configuracao", new Blob([JSON.stringify(configuracao)], {
      type: "application/json"
    }));

    let config = {
      timeout: 0,
      headers: {
        'Content-Type': undefined
      },
    };

    return Http.post(`${path}/${indicadorRealId}/${secondaryPath}/preview/upload-planilha`, formData, config);

  },

  async removerUnidadesVirtuaisPendentes(fichaId) {
    return Http.post(`${path}/${fichaId}/${secondaryPath}/remover-todas/PENDENTE`).catch((e) => FrontBusiness.showError(e.message));
  },

}

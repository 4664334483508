import Vue from 'vue';
import moment from 'moment';

// Configure o locale padrão para pt-BR
moment.locale('pt-br');

import tinymce from 'tinymce/tinymce';
window.tinymce = tinymce;

import '@/filters/CurrencyFilter';
import '@/filters/DataFilter';
import '@/filters/DataHora';
import '@/filters/FormatSizeFilter';
import '@/filters/InteiroFilter';

import VueAxios from '@/plugins/Axios';
Vue.use(VueAxios);

import VueSSE from '@/plugins/SSE';
Vue.use(VueSSE);

import VFilters from '@/plugins/Formatters';
Vue.use(VFilters);

import ClickOutside from '@/plugins/ClickOutside';
Vue.use(ClickOutside);

import VueInput from '@/components/Form/V-Input'
Vue.component('v-input', VueInput);

import VInputCep from '@/components/Form/V-InputCep'
Vue.component('v-input-cep', VInputCep);

import VueButton from '@/components/Form/V-Button'
Vue.component('v-button', VueButton);

import VueUpload from '@/components/Form/V-Upload'
Vue.component('v-upload', VueUpload);

import BuscarPessoa from '@/components/BuscarPessoa'
Vue.component('buscar-pessoa', BuscarPessoa);

import BuscarFichas from "@/components/BuscarFichas.vue";
Vue.component('buscar-fichas', BuscarFichas);

import BuscarProtocolos from "@/components/BuscarProtocolos";
Vue.component('buscar-protocolos', BuscarProtocolos);

import BuscarFichasExternas from "@/components/BuscarFichasExternas.vue";
Vue.component('buscar-fichas-externas', BuscarFichasExternas);

import IndicadorPessoalNome from "@/components/IndicadorPessoal/Detalhes/IndicadorPessoalNome";
Vue.component('indicador-pessoal-nome', IndicadorPessoalNome);

/**********************************/

import VueVirtualScroller from 'vue-virtual-scroller';
Vue.use(VueVirtualScroller);
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import VModal from 'vue-js-modal';
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

import Notifications from 'vue-notification';
Vue.use(Notifications);

import VueInputTag from '@/components/Form/V-InputTag'
Vue.component('v-input-tag', VueInputTag);

import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll);

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)

/**********************************/

import VueTable from '@/v2/plugins/Table';
Vue.use(VueTable);

<template>
    <div class="input-group" v-if="model">
      <card :titulo="label" class="m-0 w-100 mt-1" :class="{'border-list': !novo}">
        <div class="row no-gutters w-100">
          <v-input type="select" :default="['medidaArea'].includes(campo) ? 'F' : 'C'" :options="tipos(campo)" label="Tipo" v-model="model.tipo" class="col-auto mr-1" style="width: 150px;"/>

          <SelectColunaPlanilha :label="model.tipo === 'CC' ? 'Coluna 1' : 'Coluna'" :style="{ width: model.tipo.length > 1 ? '297.25px' : !novo ? '598px' : '556px' }" class="col-auto mr-1 float-left" v-if="['C', 'CF', 'CC'].includes(model.tipo)"
                                :model.sync="model.coluna1" :colunas="colunas" :valor="model.coluna1" @change="onChange()"/>

          <div v-if="(model.tipo || '').includes('F')" :style="{ width: model.tipo.length > 1 ? '297.25px' :  !novo ? '598px' : '556px' }" class="col-auto no-gutters mr-1 float-left" >
            <v-input v-if="campo === 'medidaArea'" :class="classe" class="w-100 form-group float-left" v-model="model.fixo" :options="options.medidas" type="select" label="Fixo" @change="onChange()"/>
            <v-input v-else :class="classe" class="w-100 form-group float-left" label="Fixo" v-model="model.fixo" type="currency" :input-props="{precision : decimais}" @change="onChange()"/>
          </div>

          <SelectColunaPlanilha :label="model.tipo === 'CC' ? 'Coluna 2' : 'Coluna'" class="col-auto mr-1 float-left" style="width: 297.25px" v-if="['FC', 'CC'].includes(model.tipo)" :model.sync="model.coluna2" :colunas="colunas" :valor="model.coluna2" @change="onChange()"/>
        </div>
      </card>
    </div>
</template>

<script>
    import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
    import Card from "@/components/Layout/components/Card";

    export default {
        name: "CampoArea",
        components: {
          SelectColunaPlanilha: () => import("@/components/IndicadorReal/Detalhes/ImportacaoUnidades/SelectColunaPlanilha"),
          Card,
        },
        props:{
            label: String,
            colunas: Array,
            value: Object,
            campo: String,
            classe: {type: String, default: 'input-area-geral'},
            novo: {type: Boolean, default: false},
        },

        data(){
            return {
                decimais: {min : 2, max : 10},
                model: null,
                options: {
                    medidas: IndicadorRealBusiness.getMedidas(),
                },
                loading: false,
            }
        },

        watch: {
          colunas: function () {
            this.$nextTick(async () => {
              const model = Object.assign({}, this.model);
              this.loading = true;
              setTimeout(()=>{
                this.loading = false;
                this.model = model;
              },5);
            });
          },
        },

        mounted() {
            if(this.value){
                this.model = this.value;
            } else {
                this.model = {};
            }
        },

        methods: {

          tipos(campo) {
            let tipos = [
              {id: 'C', nome: 'Coluna'},
              {id: 'F', nome: 'Fixo'}
            ]
            let naoConcatena = ['area', 'fracaoSolo', 'fracaoSoloPercentual', 'coeficienteProporcionalidade'];
            if (!naoConcatena.includes(campo)) {
              tipos = [...tipos, ... [{id: 'FC', nome: 'Fixo + Coluna'},
                {id: 'CF', nome: 'Coluna + Fixo'},
                {id: 'CC', nome: 'Coluna + Coluna'}]]
            }
            if (campo === 'medidaArea') {
              tipos = [
                {id: 'F', nome: 'Fixo'}
              ]
            }
            return tipos
          },

            onChange(){
                this.$emit('update:value', this.model);
            }

        }
    }
</script>
<style scoped lang="css">
    .theme-dark #modals-container .border-list {
      border-color: #363b3e !important;
    }

</style>

import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import Utils from "@/commons/Utils.js";
import moment from "moment";

const tratarDicionarioPessoa = async function(pessoa){

  if(!pessoa?.id){
    return null;
  }

  const dicionario = {...pessoa};

  if (dicionario.tipoPessoa == 'JURIDICA') {
    dicionario.cnpj = dicionario.documento;
    dicionario.naturezaJuridica = FrontBusiness.getLabel(dicionario.naturezaJuridica, IndicadorPessoalBusiness.getNaturezaJuridica()).toLowerCase();
  }

  if (dicionario.tipoPessoa == 'FISICA') {
    dicionario.cpf = dicionario.documento;

    dicionario.estadoCivil = FrontBusiness.getLabel(dicionario.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil()).toLowerCase()
      .replace('o(a)', dicionario.sexo == 'FEMININO' ? 'a' : 'o');

    dicionario.regimeCasamento = FrontBusiness.getLabel(dicionario.regimeCasamento, IndicadorPessoalBusiness.getRegimes()).toLowerCase();

    if(dicionario.nascimento != null){
      dicionario.idade = moment().diff(dicionario.nascimento, 'years');
    }

  }

  dicionario.sexo = FrontBusiness.getLabel(dicionario.sexo, IndicadorPessoalBusiness.getSexos()).toLowerCase();
  dicionario.tipoPessoa = FrontBusiness.getLabel(dicionario.tipoPessoa, IndicadorPessoalBusiness.getTiposPessoa()).toLowerCase();
  dicionario.nacionalidade = FrontBusiness.getLabel(dicionario.nacionalidade, IndicadorPessoalBusiness.getNacionalidades(), 'id', dicionario.sexo).toLowerCase();

  let camposEndereco = ['cep', 'estado', 'cidade', 'bairro', 'quadra', 'lote', 'logradouro', 'numero', 'complemento', 'pais'];
  dicionario.endereco = {};
  dicionario.enderecoComercial = {};
  camposEndereco.forEach(c => {
    dicionario.endereco[c] = dicionario?.[c];
    dicionario.enderecoComercial[c] = dicionario?.[`${c}Comercial`];
  });

  dicionario.endereco.pais = FrontBusiness.getLabel(dicionario.endereco.pais, IndicadorPessoalBusiness.getNacionalidades(), 'id', 'pais');
  dicionario.enderecoComercial.pais = FrontBusiness.getLabel(dicionario.enderecoComercial.pais, IndicadorPessoalBusiness.getNacionalidades(), 'id', 'pais');

  return dicionario;

}

const parse = async function(node, callback, dicionario, debug){

  let id = getVar(node.params.id, dicionario, false);
  const pessoa = await IndicadorPessoalBusiness.getVersaoByIdComCache(id).catch(e => {
    console.error(e);
    return null;
  });

  // cria objeto de transporte
  let obj = {};
  obj[node.params.nome || 'pessoa'] = await tratarDicionarioPessoa(pessoa);

  return await callback(node.children, Utils.clone({...dicionario, ...obj}), debug);

};

export default {parse, tratarDicionarioPessoa};

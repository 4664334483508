import CachedApi from "@/commons/CachedApi.js";
import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/checklist';

export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList(){
    return Http.get(`${path}/simple`);
  },

  async getAllSimpleList(){
    return Http.get(`${path}/all-simple`);
  },

  async getById(id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByIdCached(id){
    return CachedApi.getCached('checklist-'+id, async () => {
      return Http.get(`${path}/${id}`);
    }, false);
  },

  async getLstTipoChecklist(){
    return Http.get(`${path}/tipo-checklist`);
  },

  async getLstChecklist(dominios){
    let params = new URLSearchParams();
    if(dominios) {
      params.append('dominios', dominios);
    }
    return Http.get(`${path}/list-checklist`, {params});
  },

  async getChecklistsUsados(){
    return Http.get(`${path}/usados`);
  },

  async save(dto, id = ''){
    return Http.post(`${path}/${id}`, dto);
  },

}

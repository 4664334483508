<template>
  <span v-if="loading || this.possuiErro" @click.prevent="abrir()">
    <async-alert tipo="alert-icon" :tentativas="1" class="pointer mr-2"
                 title="Processo de selagem em execução" icon="far fa-stamp text-red"
                 msg="Há erros nas informações do selo" :service="service" />
  </span>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
import SeloBusiness from "@/business/SeloBusiness.js";
import AsyncAlert from "@/components/alert/AsyncAlert.vue";
import ModalSelosPendentes from "@/components/Protocolo/ModalSelosPendentes";

export default {
  name: "LoadingSelo",
  components: {AsyncAlert},
  props: {
      tipo: String,
      id: String,
      dto: Object
    },
    data() {
      return {
        loading: true,
        possuiErro:false
      }
    },

    computed:{
      msgErro(){
        return this.possuiErro ? 'Há erros nas informações do selo' : '';
      },
      tipoBusca(){
        return this.tipo === 'busca' ? 'protocolo' : this.tipo;
      }
    },

    methods: {

      // quem faz a chamada do serviço é o componente AsyncAlert
      async service(){
        return new Promise(this.verify);
      },

      async verify(res, rej) {
        if(this.loading){

          let result = await SeloBusiness.consultarStatusSelagem(this.tipoBusca, this.id)
                                          .catch(e => {
                                            this.loading = false;
                                            this.possuiErro = true;
                                            res(true);
                                            return {selando: true};
                                          });

          if (result.selando) {
            await FrontBusiness.sleep(3000);
            await this.verify();
          } else {
            this.$emit('selado', result);
            this.loading = false;
            if(res != undefined){
              res(false);
            }
          }

        }
      },

      abrir() {
        FrontBusiness.openModal(ModalSelosPendentes, {id: this.id, tipo: this.tipo});
      }
    }
  }
</script>

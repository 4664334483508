<template>
  <card titulo="Indisponibilidades">
    <template #acoes>
      <a @click="consultarCnib()" title="Consultar CNIB" class="icon float-right">
        <i class="fal fa-search"></i>
      </a>
    </template>
    <card-content v-if="ficha.indisponibilidades.length">
      <table class="table table-striped card-table table-vcenter">
        <thead>
          <tr>
            <th class="pl-1" width="100px">Protocolo Indisponibilidade</th>
            <th class="pl-1" width="100px">Nome</th>
            <th class="pl-1" width="100px">Documento</th>
            <th width="30px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(indisponibilidade, index) in ficha.indisponibilidades" :key="'ficha_indisponibilidade'+index">
            <td class="text-truncate pr-2">{{indisponibilidade.cnib}}</td>
            <td class="pr-2">{{indisponibilidade.nome}}</td>
            <td class="text-truncate pr-2">{{indisponibilidade.documento}}</td>

            <td class="nowrap">
              <v-button :run="abrirDetalhes" :params="[indisponibilidade.ordem]" :button="false" class="icon text-secondary" title="Exibir Detalhes">
                <i class="fas fa-home"></i>
              </v-button>
            </td>

          </tr>
        </tbody>
      </table>
    </card-content>

    <div v-if="!ficha.indisponibilidades.length" class="alert alert-outline-info">
      Não há indisponibilidades para exibir
    </div>

  </card>
</template>


<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import CnibPesquisaDocumento from "@/components/Arisp/CnibPesquisaDocumento.vue";
  import DetalhesIndisponibilidade from "@/components/Indisponibilidade/DetalhesIndisponibilidade.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";

  export default {

    name: "Indisponibilidades",
    components: {CardContent, Card},
    props: {
        ficha : Object
    },

    computed:{
      documentos(){
        return this.ficha.proprietarios.map(i => i.indicadorPessoalVersao.documento)
      }
    },

    methods:{
      abrirDetalhes(id) {
        FrontBusiness.openModal(DetalhesIndisponibilidade, {id});
      },

      async consultarCnib() {
        await FrontBusiness.openModal(CnibPesquisaDocumento, {documentos: this.documentos, consulta: true});
      },
    }

  }

</script>

<template>
  <card titulo="Histórico">

    <auditoria-pagination :referencia="ficha.id" entidade="INDICADOR_REAL" :formatters="formatters" />

  </card>
</template>

<script>
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import Card from "@/components/Layout/components/Card.vue";
  import AuditoriaBusiness from "@/business/AuditoriaBusiness";
  import Utils from "@/commons/Utils";

  export default {

    name: "Histórico",
    components: {
      Card,
      AuditoriaPagination: () => import('@/v2/components/Auditoria/AuditoriaPagination')
    },
    props: {
      ficha : Object
    },

    data() {
      return {
        historico : [],
        loading: true,
        disable: false,
        page: 1,
        pageSize: 10
      }
    },

    methods:{

      async formatters(auditoria) {
        let formatters = [];
        if(auditoria.entidade === 'ANEXO_INDICADOR_REAL'){
          formatters = [
            {label: 'Ordem', key: 'ordem', type: 'string'},
            {label: 'Nome', key: 'nomeAnexo', type: 'string'},
            {label: 'Caminho', key: 'caminho', type: 'string'},
            {label: 'Tags', key: 'tags', type: 'listahtml'},
            {label: 'Caminho Assinado', key: 'caminhoAssinado', type: 'string'},
          ];
        } else {
          formatters = [
            {label: 'CNM', key: 'cnm'},
            {label: 'Versão', key: 'versao'},
            {label: 'Categoria', key: 'categoria', type: 'enum', lista: IndicadorRealBusiness.getCategorias()},
            {label: 'Livro - Transcrição', key: 'livroTranscricao', type: 'enum', lista: IndicadorRealBusiness.getLivrosTranscricao()},
            {label: 'Unidade Autônoma', key: 'unidadeAutonoma', type: 'boolean'},
            {label: 'Tipo de Imóvel', key: 'tipoImovel', type: 'enum', lista: IndicadorRealBusiness.getTiposImovel()},
            {label: 'Imóvel da União', key: 'imovelUniao', type: 'boolean'},
            {label: 'Imóvel da Marinha', key: 'marinha', type: 'boolean'},
            {label: 'Imóvel de Fronteira', key: 'fronteira', type: 'boolean'},
            {label: 'Descrição', key: 'descricaoTipoImovelOutros'},
            {label: 'Último Ato', key: 'ultimoAto'},
            {label: 'Situação', key: 'status', type: 'enum', lista: IndicadorRealBusiness.getStatusImovel(false)},
            {label: 'Abertura', key: 'abertura', type: 'datetime'},
            {label: 'Cancelamento/Encerramento', key: 'encerramento', type: 'datetime'},
            {label: 'Registro Anterior', key: 'origens', formatter: (registros) => {
                let html = [];
                for (const registro of (registros || [])) {
                  const h = `<p>${IndicadorRealBusiness.getLabelRegistroAnterior(registro)}</p>`
                  html.push(h);
                }
                return registros?.length ? `${html.join('')}` : null;
              }},
            {label: 'Número RIP SPU', key: 'numeroRipSpu', group: 'Imóvel União', formatter: (registros) => {
                return this.$formatters.numeroRipSpu(registros);
              }},
            {label: 'Regime', key: 'regime', type: 'enum', lista: IndicadorRealBusiness.getRegimesImovelUniao(), group: 'Imóvel União'},
            {label: 'Medida Área', key: 'medidaAreaUniao', type: 'enum', lista: IndicadorRealBusiness.getMedidasUniao(), group: 'Imóvel União'},
            {label: 'Área Terreno da União', key: 'areaUniao', type: 'decimal', group: 'Imóvel União'},
            {label: 'Livro (Número)', key: 'livroNumero'},
            {label: 'Livro (Letra)', key: 'livroLetra'},
            {label: 'Folha', key: 'livroFolha'},
            {label: 'Número da Unidade', key: 'unidade', group: 'Identificação'},
            {label: 'Bloco', key: 'bloco', group: 'Identificação'},
            {label: 'Quadra', key: 'quadra', group: 'Identificação'},
            {label: 'Lote', key: 'lote', group: 'Identificação'},
            {label: 'Localização', key: 'localizacao', group: 'Identificação'},
            {label: 'Empreendimento/Condomínio', key: 'empreendimento', group: 'Identificação'},
            {label: 'Andar', key: 'andar', group: 'Informações Complementares'},
            {label: 'Pavimento', key: 'pavimento', group: 'Informações Complementares'},
            {label: 'Grupo', key: 'grupo', group: 'Informações Complementares'},
            {label: 'Denominação da Área Rural', key: 'denominacao', group: 'Informações Complementares'},
            {label: 'INCRA (CCIR)', key: 'incra', group: 'Informações Complementares'},
            {label: 'ITR', key: 'itr', group: 'Informações Complementares'},
            {label: 'CAR', key: 'car', group: 'Informações Complementares'},
            {label: 'Certificação no SIGEF', key: 'certificacaoIncra', group: 'Informações Complementares'},
            {label: 'Cadastro Imobiliário', key: 'cadastroImobiliario', group: 'Informações Complementares'},
            {label: 'Cadastro Imobiliário Brasileiro (CIB)', key: 'cadastroImobiliarioBrasileiro', group: 'Informações Complementares'},
            {label: 'Cadastro Fiscal', key: 'cadastroFiscal', group: 'Informações Complementares'},
            {label: 'CEP', key: 'cep', group: 'Endereço'},
            {label: 'Tipo logradouro', key: 'tipoLogradouro', group: 'Endereço'},
            {label: 'Logradouro', key: 'logradouro', group: 'Endereço'},
            {label: 'Número', key: 'numero', group: 'Endereço'},
            {label: 'Bairro', key: 'bairro', group: 'Endereço'},
            {label: 'Cidade', key: 'cidade', group: 'Endereço'},
            {label: 'Estado', key: 'estado', group: 'Endereço'},
            {label: 'Complemento', key: 'complemento', group: 'Endereço'},
            {label: 'Medida Área', key: 'medidaArea', type: 'enum', lista: IndicadorRealBusiness.getMedidas(), group: 'Área'},
            {label: 'Área total', key: 'area', type: 'decimal', group: 'Área'},
            {label: 'Medida Área DOI', key: 'medidaAreaDoi', type: 'enum', lista: IndicadorRealBusiness.getMedidas(), group: 'Área'},
            {label: 'Área total DOI', key: 'areaTotalDoi', type: 'decimal', group: 'Área'},
            {label: 'Fração solo', key: 'fracaoSolo', type: 'decimal', group: 'Área'},
            {label: 'Fração solo (%)', key: 'fracaoSoloPercentual', type: 'decimal', group: 'Área'},
            {label: 'Coeficiente de proporcionalidade', key: 'coeficienteProporcionalidade', type: 'decimalSimples', group: 'Área'},
            {label: '', key: 'areas', formatter: (registros) => {
                let html = [];
                let h = '';
                for (const registro of (registros || [])) {
                  h = `<li>`;
                  if (!Utils.isEmptyNullOrUndefined(registro.tipo)) {
                    h += `<p>Tipo: ${([{id: 'COMUM', nome: 'Comum'}, {id: 'PRIVATIVA', nome: 'Privativa'}]).find(m => m.id === registro.tipo)?.nome}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.descricao)) {
                    h += `<p>Descrição: ${registro.descricao}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.area)) {
                    h += `<p>Área: ${this.$formatters.currency(registro.area)}</p>`;
                  }
                  h += `</li>`;
                  html.push(h);
                }
                return registros?.length ? `<ul>${html.join('')}</ul>` : null;
              }, group: 'Outras Áreas'},
            {label: '', key: 'areasEdificadas', formatter: (registros) => {
                let html = [];
                let h = '';
                for (const registro of (registros || [])) {
                  h = `<li>`;
                  if (!Utils.isEmptyNullOrUndefined(registro.descricao)) {
                    h += `<p>Descrição: ${registro.descricao}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.area)) {
                    h += `<p>Área: ${this.$formatters.currency(registro.area)}</p>`;
                  }
                  h += `</li>`;
                  html.push(h);
                }
                return registros?.length ? `<ul>${html.join('')}</ul>` : null;
              }, group: 'Áreas Edificadas'},
            {label: '', key: 'unidadesAcessorias', formatter: (registros) => {
                let html = [];
                let h = '';
                for (const registro of (registros || [])) {
                  h = `<li>`;
                  if (!Utils.isEmptyNullOrUndefined(registro.descricao)) {
                    h += `<p>Descrição: ${registro.descricao}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.unidade)) {
                    h += `<p>Unidade: ${registro.unidade}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.complemento)) {
                    h += `<p>Complemento: ${registro.complemento}</p>`;
                  }
                  if (!Utils.isEmptyNullOrUndefined(registro.area)) {
                    h += `<p>Área: ${this.$formatters.currency(registro.area)}</p>`;
                  }
                  h += `</li>`;
                  html.push(h);
                }
                return registros?.length ? `<ul>${html.join('')}</ul>` : null;
              }, group: 'Unidades Acessórias'},
            {label: '', key: 'confrontacoes', formatter: (registros) => {
                let html = [];
                for (const registro of (registros || [])) {
                  let h = `<li><p>Confrontação: ${registro.confrontacao}</p>`;
                  h += `<p>Metragem: ${registro.metragem}</p>`;
                  h += `<p>Confrontante: ${registro.confrontante}</p>`;
                  h += `</li>`;
                  html.push(h);
                }
                return registros?.length ? `<ul>${html.join('')}</ul>` : null;
              }, group: 'Confrontações'},
            {label: 'Proprietários', key: 'proprietarios', type: 'string', formatter: (value) => AuditoriaBusiness.proprietarios({proprietarios: value})},
            {label: 'Especificações do Imóvel', key: 'especificacao'},
            {label: 'Observações para Certidão', key: 'certidao'},
            {label: 'Observações Internas', key: 'observacao'},
            {label: 'Registros Vinculados', key: 'registrosVinculados', formatter: async (value) => {
                if (value && Array.isArray(value)) {
                  const promises = value.map(async (item) => {
                    return await AuditoriaBusiness.nomearFicha(item.id, 'registroAuxiliar');
                  });
                  const results = await Promise.all(promises);
                  return results.join('<br>');
                }
                return '';
              }
            },
            {label: 'Texto Importado', key: 'textoLegado'},
          ];

          formatters = [...formatters, ... await AuditoriaBusiness.formattersAtos(auditoria)]
        }

        return formatters;
      }

    }
  }
</script>

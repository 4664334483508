import error from "@/business/livros/copiladores/error.js";

const obrigarConteudo = async function(node, callback, dicionario, debug){

  let conteudo = await callback(node.children, {...dicionario}, debug);

  if(!conteudo.trim()){
    return error(node?.params?.erro || "Necessário verificar a minuta");
  }

  return conteudo;

};

export default obrigarConteudo;

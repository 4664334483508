<template>
  <modal :titulo="titulo" id="detalhes-indisponibilidade" class="modal-detalhes-entidade"
         :loading="loading">
    <template #title-extra>
      <div v-if="indisponibilidadeOrdem.cancelado" class="badge badge-danger-soft text-center m-0" >
        Cancelada
      </div>
    </template>
    <template v-if="!loading" #default>

      <card titulo="Informações Adicionais">

          <template #acoes>

            <a class="icon ml-1 float-right"  @click.prevent="salvarInfoAdicionais()">
              SALVAR
            </a>

            <a class="icon ml-1 float-right" style="padding-right: 10px"  @click="close()">
              CANCELAR
            </a>

          </template>

        <card-content>
          <div class="row gutters-xs">
          <v-input class="col form-group" label="Juízo" v-model="indisponibilidadeOrdem.juizo" type="text" />
          </div>

          <div class="row gutters-xs" v-if="!indisponibilidadeOrdem.solicitante">
            <v-input class="col form-group" label="Solicitante"  >
              <buscar-pessoa ref="buscar-solicitante" :default-query="indisponibilidadeOrdem.nomeSolicitante || ''" @add="selecionarSolicitante($event)" :novo="true" />
            </v-input>
          </div>

          <div class="row gutters-xs" v-else>
            <v-input class="col form-group" label="Solicitante">
              <input type="text" readonly v-model="indisponibilidadeOrdem.solicitante.nome" class="form-control form-control-xs" />
            </v-input>

            <div class="col-auto mt-1">
              <button class="btn btn-outline-secondary btn-xs" style="margin-top: 22px" @click="removerSolicitante" type="button" title="Remover">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
        </card-content>
      </card>

      <card titulo="Motivo" v-if="motivo">
        {{(motivo || '-')}}
      </card>

      <card titulo="Dados Gerais">
        <card-content>

          <p class="semi-bold" v-if="indisponibilidadeOrdem.tipo != 'CNIB'">
            <span>Tipo de Cadastro: </span><span>{{indisponibilidadeOrdem.tipo}}</span>
          </p>

          <p class="semi-bold">
            <span>Protocolo Indisponibilidade: </span><span>{{indisponibilidadeOrdem.protocoloCnib}}</span>
          </p>

          <p class="semi-bold" v-if="indisponibilidadeOrdem.protocoloCnibCancelado">
            <span>Protocolo Indisponibilidade cancelado: </span><span>{{indisponibilidadeOrdem.protocoloCnibCancelado}}</span>
          </p>

          <p class="semi-bold">
            <span>Data da importação: </span><span>{{indisponibilidadeOrdem.cadastro | datahora}}</span>
          </p>

          <p class="semi-bold">
            <span>Data do pedido: </span><span>{{indisponibilidadeOrdem.pedido | data}}</span>
          </p>

          <p class="semi-bold">
            <span>Data do pedido de cancelamento: </span><span>{{indisponibilidadeOrdem.cancelamento | data}}</span>
          </p>

          <p class="semi-bold">
            <span>Instituição: </span><span>{{indisponibilidadeOrdem.instituicao}}</span>
          </p>

          <p class="semi-bold">
            <span>Fórum / Vara: </span><span>{{indisponibilidadeOrdem.forumVara}}</span>
          </p>

          <p class="semi-bold">
            <span>Usuário: </span><span>{{indisponibilidadeOrdem.nomeUsuario}}</span>
          </p>

          <p class="semi-bold">
            <span>Nº processo: </span><span>{{indisponibilidadeOrdem.numeroProcesso}}</span>
          </p>

          <p class="semi-bold" v-if="indisponibilidadeOrdem.email">
            <span>E-mail: </span><span>{{indisponibilidadeOrdem.email}}</span>
          </p>

          <p class="semi-bold" v-if="indisponibilidadeOrdem.telefone">
            <span>Telefone: </span><span>{{indisponibilidadeOrdem.telefone}}</span>
          </p>

          <p class="semi-bold" v-if="indisponibilidadeOrdem.observacao">
            <span>Observações: </span><span>{{indisponibilidadeOrdem.observacao}}</span>
          </p>

        </card-content>
      </card>


      <card titulo="Envolvidos">
        <card-table class="table-borderless mb-1" v-if="indisponibilidadeOrdem.individuos.length > 0">

          <thead>
            <tr>
              <th width="400px">Nome</th>
              <th width="200px">Documento</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="pessoa in indisponibilidadeOrdem.individuos" :key="pessoa.id">
              <td><a class="mb-1">{{pessoa.nome}}</a></td>
              <td> {{pessoa.documento}}</td>
            </tr>
          </tbody>

        </card-table>
      </card>

      <card titulo="Indicadores Pessoais Encontrados">
        <card-table class="table-borderless mb-1" v-if="indisponibilidadeOrdem.indisponibilidades.length > 0">
          <thead>
          <tr>
            <th width="400px">Nome</th>
            <th width="200px">Documento</th>
            <th width="50px"></th>
          </tr>
          </thead>

          <tbody>
          <template v-for="(pessoa, i) in indisponibilidadeOrdem.indisponibilidades">
            <tr :key="i">
              <td colspan="3">
                <table class="w-100 tbl-indisp">
                  <tr>
                    <td width="400px">
                      <span class="d-flex align-items-center">
                  <a href class="pointer text-uppercase" @click.prevent="detalhesPessoa(pessoa)">{{pessoa.nome}}</a>
                  <div class="d-flex align-items-center" v-if="pessoa.individuo && pessoa.individuo.cancelado">
                    <div class="badge text-center badge-danger-soft m-0 ml-2 ">
                      Indisponibilidade Cancelada
                    </div>
                    <v-button v-if="usuarioAdminOuGestor" style="width: 20px; height: 20px; padding: 0; display: flex; justify-content: center; align-items: center;"
                              :run="desfazerCancelamentoIndisponibilidade" :params="[pessoa, null, true]" class="btn btn-xs btn-outline-danger ml-2" title="Desfazer cancelamento da indisponibilidade">
                      <i class="fad fa-undo-alt" style="font-size: 11px"></i>
                    </v-button>
                  </div>
                </span>
                    </td>
                    <td width="200px" align="left"> {{pessoa.documento}}</td>
                    <td width="50px" align="left">
                      <v-button v-if="usuarioAdminOuGestor && !indisponibilidadeOrdem.protocoloCnibCancelado && !pessoa.individuo.cancelado"  :button="false"
                                class="icon ml-3" :run="cancelarIndisponibilidade" :params="[pessoa, null, true]" position="dropleft"
                                title="Cancelar Indisponibilidade">
                        <i class="fal fa-ban"></i>
                      </v-button>
                    </td>
                  </tr>
                </table>

                <div v-if="pessoa.protocolos.length" class="w-100 small" style="display: flex;padding: 0 9px;">
                  Protocolos gerados:
                  <div v-for="(protocolo, i) in pessoa.protocolos" :key="protocolo.id">
                    <template v-if="i > 0">,</template>
                    <router-link :to="{ name: 'protocolo', params:{id : protocolo.id} }" target="_blank" @click.native="$event.stopImmediatePropagation()" class="ml-1">
                      {{ nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo) }}
                    </router-link>
                  </div>
                </div>

                <div v-if="pessoa.individuo && pessoa.individuo.cancelado" class="w-100 small text-muted" style="padding: 0 9px;">
                  <span v-if="pessoa.individuo.usuarioCancelamento"><b>Usuário:</b> {{pessoa.individuo.usuarioCancelamento.nome}}</span>
                  <span> | <b>Data:</b> {{pessoa.individuo.cancelamento | datahora }}</span>
                  <span> | <b>Motivo:</b> {{pessoa.individuo.motivoCancelamento}}</span>
                </div>

              </td>
            </tr>
            <tr :key="i + '-fichas'" v-if="pessoa.fichas && pessoa.fichas.length" style="background-color: rgba(35, 41, 49, 0.4) !important; border-bottom: 5px solid #1C1E24 !important;">
              <td colspan="3">
                <card :titulo="`Indicadores Reais Encontrados (${pessoa.fichas.length})`">

                    <RecycleScroller class="fichas-scroller" :items="pessoa.fichas" :minItemSize="50" :sizeField="'size'" key-field="indisponibilidade" v-slot="{ item }">

                      <div class="ficha-item bg-sidebar d-flex align-items-center" style="flex-wrap: wrap;">

                        <a href class="pointer" @click.prevent="detalhesFicha(item)">{{nomearFicha(item)}}</a>
                        <div class="d-flex align-items-center" v-if="item.cancelamento">
                          <span class="badge text-center badge-danger-soft m-0 ml-2">
                            Indisponibilidade Cancelada
                          </span>
                          <v-button v-if="usuarioAdminOuGestor" style="width: 20px; height: 20px; padding: 0; display: flex; justify-content: center; align-items: center;"
                                    :run="desfazerCancelamentoIndisponibilidade" :params="[pessoa, item]" class="btn btn-xs btn-outline-danger ml-2" title="Desfazer cancelamento da indisponibilidade">
                            <i class="fad fa-undo-alt" style="font-size: 11px"></i>
                          </v-button>
                        </div>

<!--                        <popover :name="item.indisponibilidade" event="hover" v-if="item.cancelamento">-->
<!--                          <span><b>Motivo:</b> {{item.motivoCancelamento}}</span>-->
<!--                          <span v-if="item.usuarioCancelamento"> | <b>Usuário:</b> {{item.usuarioCancelamento}}</span>-->
<!--                          <span> | <b>Data:</b> {{item.cancelamento | datahora }}</span>-->
<!--                        </popover>-->

                        <span class="float-right" v-if="usuarioAdminOuGestor && !indisponibilidadeOrdem.protocoloCnibCancelado && !item.cancelamento">
                          <v-button :button="false" class="icon ml-3" :run="cancelarIndisponibilidade"
                                    :params="[pessoa, item]" position="dropright" title="Cancelar Indisponibilidade">
                            <i class="fal fa-ban"></i>
                          </v-button>
                        </span>
                        <div v-if="item.protocolos.length" class="w-100 small" style="display: flex;">
                          Protocolos gerados:
                          <div v-for="(protocolo, i) in item.protocolos" :key="protocolo.id">
                            <template v-if="i > 0">,</template>
                            <router-link :to="{ name: 'protocolo', params:{id : protocolo.id} }" target="_blank" @click.native="$event.stopImmediatePropagation()" class="ml-1">
                              {{ nomearProtocolo(protocolo.dominio, protocolo.tipoProtocolo, protocolo.codigo) }}
                            </router-link>
                          </div>
                        </div>
                        <div v-if="item.cancelamento" class="w-100 small text-muted" :style="`max-height: ${item.size - 50}px; overflow: auto;`">
                          <span v-if="item.usuarioCancelamento"><b>Usuário:</b> {{item.usuarioCancelamento}}</span>
                          <span> | <b>Data:</b> {{item.cancelamento | datahora }}</span>
                          <span> | <b>Motivo:</b> {{item.motivoCancelamento}}</span>
                        </div>
                      </div>

                    </RecycleScroller>

<!--                    <tr v-for="ficha in pessoa.fichas" :key="ficha.id">-->
<!--                      <td>-->
<!--                        <span class="d-flex align-items-center">-->
<!--                          <a href class="mb-1 pointer" @click.prevent="detalhesFicha(ficha)">{{nomearFicha(ficha)}}</a>-->
<!--                          <div v-if="ficha.cancelamento" class="badge text-center badge-danger-soft ml-2">-->
<!--                            Indisponibilidade Cancelada-->
<!--                          </div>-->
<!--                        </span>-->
<!--                        <div v-if="ficha.cancelamento" class="w-100 small text-muted">-->
<!--                          <span><b>Motivo:</b> {{ficha.motivoCancelamento}}</span>-->
<!--                          <span v-if="ficha.usuarioCancelamento"> | <b>Usuário:</b> {{ficha.usuarioCancelamento}}</span>-->
<!--                          <span> | <b>Data:</b> {{ficha.cancelamento | datahora }}</span>-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td align="left">-->
<!--                        <v-button v-if="usuarioAdminOuGestor && !indisponibilidadeOrdem.protocoloCnibCancelado && !ficha.cancelamento" :button="false" class="icon ml-3" :run="cancelarIndisponibilidade" :params="[pessoa, ficha]" position="dropright" title="Cancelar Indisponibilidade">-->
<!--                          <i class="fal fa-ban"></i>-->
<!--                        </v-button>-->
<!--                      </td>-->
<!--                    </tr>-->

                </card>
              </td>
            </tr>

          </template>

          </tbody>

        </card-table>

        <div class="alert alert-warning mb-0" role="alert" v-else>
          Nenhum indicador pessoal encontrado na base
        </div>

      </card>

      <card titulo="Protocolos Gerados">
        <template #acoes>

          <a class="icon ml-1 float-right"  @click.prevent="vincularProtocolo()">
            NOVO VÍNCULO
          </a>

        </template>
        <card-table class="table-borderless mb-1" v-if="indisponibilidadeOrdem.protocolos.length > 0">

          <thead>
            <tr>
              <th>Código</th>
              <th>Ato Principal</th>
              <th>Status</th>
              <th>Usuário</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="protocolo in indisponibilidadeOrdem.protocolos" :key="protocolo.id">
              <td>
                <a :href="`${$root.publicPath}#/protocolo/${protocolo.id}/geral`" target="_blank" class="mb-1 pointer">{{protocolo.codigo}}</a>
              </td>
              <td>{{protocolo.tipoServico}}</td>
              <td>{{protocolo.status}}</td>
              <td>{{protocolo.atendente}}</td>
              <td>
                <a v-if="usuarioAdminOuGestor" @click.prevent="desvincularProtocolo(protocolo)" class="icon" title="Desvincular Protocolo">
                  <i class="fa fa-unlink"></i>
                </a>
              </td>
            </tr>
          </tbody>

        </card-table>

        <div class="alert alert-warning mb-0" role="alert" v-else>
          Nenhum protocolo gerado
        </div>

      </card>

    </template>
    <template #footer>
      <v-button class="btn btn-outline-success btn-xs ml-auto" v-if="ignorar && usuarioAdminOuGestor" :run="reativar" :popover="true">
        Reativar sugestão ignorada
      </v-button>
      <v-button v-if="!indisponibilidadeOrdem.cancelado" :class="`btn btn-outline-primary btn-xs ml-${ignorar ? '2' : 'auto'}`" :run="gerarProtocolo" :popover="true">
        Protocolar
      </v-button>
      <v-button v-if="!indisponibilidadeOrdem.tipoCancelamento && !indisponibilidadeOrdem.cancelado" :class="`btn btn-outline-danger btn-xs ml-2`" :run="cancelarOrdem">
        Cancelar Ordem
      </v-button>
      <v-button v-if="!indisponibilidadeOrdem.tipoCancelamento && indisponibilidadeOrdem.cancelado" label="Desfazer cancelamento da ordem?"
                class="btn btn-outline-primary btn-xs ml-auto" :popover="true" :run="desfazerCancelamentoOrdem">
        Desfazer Cancelamento
      </v-button>
    </template>
  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness";
  import Utils from "@/commons/Utils";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
  import ModalCancelarIndisponibilidade from "@/components/Indisponibilidade/ModalCancelarIndisponibilidade.vue";
  import ModalCancelarOrdem from "@/components/Indisponibilidade/ModalCancelarOrdem.vue";
  import ModalVincularIndisponibilidade from "@/components/Indisponibilidade/ModalVincularIndisponibilidade.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import ModalVinculoSolicitante from "@/components/Indisponibilidade/ModalVinculoSolicitante.vue";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";

  export default {
    name: "DetalhesIndisponibilidade",
    components: { CardTable, CardContent, Card, Modal},

    props: {
        id: String,
        ignorar: Boolean
    },

    computed: {
      titulo() {
        return 'Indisponibilidade';
      },

      motivo(){
        return this.indisponibilidadeOrdem.motivoCancelamento || this.indisponibilidadeOrdem?.indisponibilidades?.[0]?.motivo
      },

      usuario() {
        return this.$root.usuarioLogado;
      },

      usuarioAdminOuGestor(){
        return this.usuario.administrador || this.usuario.gestor;
      }
    },

    data() {
      return {
        indisponibilidadeOrdem: {},
        pessoasIndisponibilidade: null,
        fichas: null,
        loading: true,
        item: null,
      }
    },

    async beforeMount() {
      await this.load();
    },

    methods: {

      async load() {
        this.$set(this, 'loading', true);

        this.indisponibilidadeOrdem = await IndisponibilidadeBusiness.getResumoById(this.id);
        this.indisponibilidadeOrdem.juizo = this.indisponibilidadeOrdem.juizo || '' ;

        this.pessoasIndisponibilidade = [];

        await IndisponibilidadeBusiness.getIndividuosByOrdem(this.id).then(l => {
          this.indisponibilidadeOrdem.individuos = l || [];
          Utils.sortAlphanumeric(this.indisponibilidadeOrdem?.individuos, 'nome');
        });

        const p2 = IndisponibilidadeBusiness.getProtocolosByOrdem(this.id).then(l => {
          this.indisponibilidadeOrdem.protocolos = l || [];
          Utils.orderFields(this.indisponibilidadeOrdem.protocolos, ['codigo']);
        });

        const p3 = IndisponibilidadeBusiness.getIndisponibilidadesByOrdem(this.id).then(l => {
          this.indisponibilidadeOrdem.indisponibilidades = (l || []).map(e => {
            e.individuo = (this.indisponibilidadeOrdem.individuos || []).find(c => c.id === e?.individuoId);
            e.fichas = JSON.parse(e.fichas);
            e.protocolos = [];
            e.fichas.map(f => {
              f.protocolos = [];
              if (f.protocolo) {
                f.protocolos.push({
                  id: f.protocolo,
                  codigo: f.protocoloCodigo,
                  dominio: f.protocoloDominio,
                  tipoProtocolo: f.protocoloTipoProtocolo,
                })
                delete f.protocolo;
                delete f.protocoloCodigo;
                delete f.protocoloDominio;
                delete f.protocoloTipoProtocolo;
              }
              if (f.protocoloCancelamento) {
                f.protocolos.push({
                  id: f.protocoloCancelamento,
                  codigo: f.protocoloCancelamentoCodigo,
                  dominio: f.protocoloCancelamentoDominio,
                  tipoProtocolo: f.protocoloCancelamentoTipoProtocolo,
                })
                delete f.protocoloCancelamento;
                delete f.protocoloCancelamentoCodigo;
                delete f.protocoloCancelamentoDominio;
                delete f.protocoloCancelamentoTipoProtocolo;
              }

              f.protocolos.forEach(p => {
                if (!e.protocolos.find(a => a.id === p.id)) {
                  e.protocolos.push(p);
                }
              })

              f.size = f.protocolos.length ? 70 : 50;
              if (f.cancelamento) {
                let length = f.motivoCancelamento.length + f.usuarioCancelamento.length + 49
                let motivoCancelamento = length / 50 < 1 ? 1 : parseInt(length/ 100 + 0.99);
                f.size = f.size + motivoCancelamento * 20;
              }
              return f
            })
            return e;
          });
          Utils.sortAlphanumeric(this.indisponibilidadeOrdem?.indisponibilidades, 'nome');
        });

        await Promise.all([p3, p2]);

        this.$set(this, 'loading', false);
      },

      selecionarSolicitante(solicitante){
          this.$set(this.indisponibilidadeOrdem, 'solicitante', solicitante);
      },

      removerSolicitante(){
          this.$set(this.indisponibilidadeOrdem, 'solicitante', null);
      },

      close(retorno) {
        FrontBusiness.closeModal(retorno);
      },

      detalhesPessoa(pessoa) {
        if(pessoa.indicadorPessoalId){
          FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId});
        }
      },

      detalhesFicha(ficha) {
        if(ficha.indicadorRealId){
          FrontBusiness.openModal(DetalhesIndicadorReal, {id: ficha.indicadorRealId});
        }
      },

      abrirProtocolo(id){
        if(id){
          FrontBusiness.redirect(`protocolo/${id}/geral`);
        }
      },

      nomearFicha: FrontBusiness.nomearFicha,
      nomearProtocolo: FrontBusiness.nomearProtocolo,

      async salvarInfoAdicionais(){
          let r = await IndisponibilidadeBusiness.salvarInfoAdicionais(this.indisponibilidadeOrdem)
            .catch(e => FrontBusiness.fullErrorHandler(this, e));
          if(r){
            this.close();
          }
      },

      async gerarProtocolo(){
        let nomeSolicitante = this.$refs?.["buscar-solicitante"]?.query;
        if (nomeSolicitante && !this.indisponibilidadeOrdem?.solicitante?.id) {
          let pessoas = await IndisponibilidadeBusiness.sugerirSolicitante({nome: nomeSolicitante, tipo: 'JURIDICA'});

          if(pessoas.length > 1) {
            this.indisponibilidadeOrdem.solicitante = await FrontBusiness.openModal(ModalVinculoSolicitante, {pessoas: pessoas});
          } else {
            this.indisponibilidadeOrdem.solicitante = pessoas[0] || await IndicadorPessoalBusiness.criarIndicador({
              documentoDesconhecido: true,
              nome: nomeSolicitante,
              indicadorPessoal: {
                nome: nomeSolicitante,
                documentoDesconhecido: true,
                tipoPessoa: 'JURIDICA'
              },
              tipoPessoa: 'JURIDICA'
            });
          }
        }

        let r = await IndisponibilidadeBusiness.salvarInfoAdicionais(this.indisponibilidadeOrdem)
          .catch(e => FrontBusiness.fullErrorHandler(this, e));

        if(r){
          let resultado = await IndisponibilidadeBusiness.gerarProtocolo([this.indisponibilidadeOrdem.id], true).catch(e => {
            FrontBusiness.showError('', e.message);
            return false;
          });

          if(resultado){
            await IndisponibilidadeBusiness.atualizarIndicadores('reset-view').catch(e => null);
            await this.$router.push({name: 'protocolo', params: {id: resultado[0]}});
            window.location.reload();
          }
        }

      },

      async vincularProtocolo(){
        let resultado = await FrontBusiness.openModal(ModalVincularIndisponibilidade, {id: this.indisponibilidadeOrdem.id});
        if(resultado){
          await IndisponibilidadeBusiness.atualizarIndicadores('reset-view').catch(e => null);
          this.close(true);
        }
      },

      async reativar(){
        let retorno = await IndisponibilidadeBusiness
          .reativar(this.indisponibilidadeOrdem.id)
          .catch(this.showError);

        if(retorno){
          FrontBusiness.showSuccess('', 'Sugestão reativada');
          this.close(true);
        }

      },

      async cancelarIndisponibilidade(pessoa, ficha, individuo = false) {
        let ids = [];
        let indisponibilidadeIndividuos = [];
        if (ficha?.indicadorRealId) {
          ids.push(ficha.indisponibilidade);
        } else {
          (pessoa?.fichas || []).map(f => {
            if (!f.cancelamento) {
              ids.push(f.indisponibilidade);
            }
          })
        }
        if (individuo) {
          indisponibilidadeIndividuos = (this.indisponibilidadeOrdem.individuos || []).filter(c => c.documento === pessoa.documento).map( i =>  i.id );
        }
        let response = await FrontBusiness.openModal(ModalCancelarIndisponibilidade, {indisponibilidadeIndividuos, ids});
        if (response) {
          await this.load();
        }
      },

      async desfazerCancelamentoIndisponibilidade(pessoa, ficha, individuo = false) {
        return FrontBusiness.showConfirmacao("", "Desfazer cancelamento da indisponibilidade?").then(async r => {
          if (r.value) {
            let ids = [], indisponibilidadeIndividuos = [];
            if (ficha?.indicadorRealId) {
              ids.push(ficha.indisponibilidade);
            } else {
              (pessoa?.fichas || []).map(f => {
                if (f.cancelamento) {
                  ids.push(f.indisponibilidade);
                }
              })
            }
            if (individuo) {
              indisponibilidadeIndividuos = (this.indisponibilidadeOrdem.individuos || []).filter(c => c.documento === pessoa.documento).map( i =>  i.id );
            }
            let retorno = await IndisponibilidadeBusiness.desfazerCancelamentoIndisponibilidade({
              individuos: indisponibilidadeIndividuos,
              indisponibilidades: ids
            }).catch(this.showError);
            if(retorno){
              await this.load();
            }
            return true;
          } else if (r.dismiss === 'cancel') {
            return false;
          } else {
            return true;
          }
        }).catch(this.showError)
      },

      async cancelarOrdem() {
        let response = await FrontBusiness.openModal(ModalCancelarOrdem, {id: this.indisponibilidadeOrdem.id});
        if (response) {
          await this.load();
        }
      },

      async desfazerCancelamentoOrdem() {
        let retorno = await IndisponibilidadeBusiness.desfazerCancelamentoOrdem(this.indisponibilidadeOrdem.id).catch(this.showError);
        if(retorno){
          await this.load();
        }
      },

      async desvincularProtocolo(protocolo) {
        return FrontBusiness.showConfirmacao("", "Desfazer desvincular o protocolo da indisponibilidade?").then(async r => {
          if (r.value) {
            let retorno = await IndisponibilidadeBusiness.desvincularProtocolo(this.indisponibilidadeOrdem.id, protocolo.id).catch(this.showError);
            if(retorno){
              await this.load();
            }
            return true;
          } else if (r.dismiss === 'cancel') {
            return false;
          } else {
            return true;
          }
        }).catch(this.showError)
      },

    },

  }
</script>

<style lang="scss" scoped>

.fichas-scroller{
  max-height: 200px;
}

.fichas-scroller .ficha-item{
  padding: 8px 15px;
  margin-bottom: 5px;
}

.tbl-indisp {
  padding: 0;
  td {
    padding-bottom: 0;
  }
}
</style>

<template>
  <modal titulo="Validar Assinatura" :loading="loading">

    <p class="alert alert-danger mb-2 pointer" v-if="!loading && !assinaturas.length">
      Não foi possível validar as assinaturas do anexo ou o mesmo não possui uma assinatura.
    </p>

    <template v-else>
      <card titulo="Assinaturas Encontradas">
        <card-content>
          <div class="alert" v-for="(assinatura, i) in assinaturas" :key="i" :class="{'alert-danger' : !assinatura.valid, 'alert-success' : assinatura.valid}">
            <p v-if="!assinatura.valid"><b>ASSINATURA INVÁLIDA</b></p>
            <p>{{assinatura.name}} ({{assinatura.document}})</p>
            <p class="text-muted">Assinado em {{$formatters.datetime(assinatura.date)}} [{{assinatura.issuer}}]</p>
          </div>

        </card-content>
      </card>

    </template>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-1">Fechar</button>
    </template>

  </modal>
</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness.js";
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";

export default {
  name: "ModalValidarAssinatura",
  components: {CardContent, Card, Modal},

  props:{
    id:String
  },

  async mounted() {
    this.assinaturas = await AnexoBusiness.getDetalhesAssinaturaById(this.id).catch(e => []);
    console.log(this.assinaturas)
    this.$set(this, 'loading', false);
  },

  data() {
    return {
      loading: true,
      assinaturas: []
    }
  },

  methods: {

    close() {
      FrontBusiness.closeModal();
    },

  }
}
</script>

<template>
	<modal titulo="Auditoria" :loading="loading" id="modal-auditoria">

    <auditoria-details-content :auditoria="auditoria" :formatters="formatters" v-if="!loading" />

  </modal>
</template>

<script>
  import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness";
  import Utils from "@/commons/Utils.js";
  import DetalhesIndisponibilidade from "@/components/Indisponibilidade/DetalhesIndisponibilidade.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Modal from "@/components/Modal";
  import AuditoriaDetailsContent from "@/v2/components/Auditoria/AuditoriaDetailsContent.vue";

  import business from "./index.js";
  import ImageFromAnexo from "@/components/ImageFromAnexo";
  import IdeMinuta from "@/components/IdeMinuta/IdeMinuta";

  export default {

    name: "AuditoriaDetails",
    components: {AuditoriaDetailsContent, Modal},
    props: {
      id : String,
      formatters: Array
    },

    modal:{
      width: 1100
    },

    data() {
      return {
        loading: true,
        auditoria: null,
      }
    },

    computed:{
      show(){
        return !this.loading && Object.keys(this.rows).length;
      }
    },

    async mounted(){
      this.auditoria = await AuditoriaBusiness.getById(this.id);
      this.loading = false;
    },

    methods:{
      close() {
        FrontBusiness.closeModal();
      },
    }

  }
</script>

import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import utils from "@/commons/Utils";
import Utils from "@/commons/Utils.js";
import DominioBusiness from "@/business/protocolo/DominioBusiness";

export default {

  nome(dominio){
    switch (dominio) {
      case 'PROTOCOLO_RI':
        return 'Tipos de Ato';
      case 'CERTIDAO_RI':
        return 'Tipos de Certidão';
      case 'OFICIO':
        return 'Tipos de Ofício';
      case 'OCORRENCIA':
        return 'Tipos de Ocorrência';
      case 'NOTIFICACAO':
        return 'Tipos de Notificação';
      case 'PERSONALIZADO':
        return 'Processos Personalizados';
    }
  },

  nomePapel(papel){
    return this.papeis().find(p => p.id === papel)?.nome;
  },

  verificarPapeisEnvolvidos(envolvidos, papeisObrigatorios = []){

    let papeisEnvolvidos = [];
    envolvidos.forEach(envolvido => papeisEnvolvidos.push(envolvido.papel));

    const papeisFaltando = papeisObrigatorios.filter(papel => !papeisEnvolvidos.includes(papel));
    let retorno = '';
    for(let index = 0; index < papeisFaltando.length; index++){
      if(index === 0) retorno = papeisFaltando.length > 1 ? 'Envolvidos com papeis ' : 'Envolvido com papel ';
      if(index+1 < papeisFaltando.length) retorno += this.nomePapel(papeisFaltando[index])+ (index+2 === papeisFaltando.length ? '' : ', ');
      if(index+1 === papeisFaltando.length) retorno += papeisFaltando.length > 1 ? ' e ' +this.nomePapel(papeisFaltando[index]) + ' são obrigatórios' : this.nomePapel(papeisFaltando[index]) + ' é obrigatório';
    }
    return retorno;
  },

  papeis(dominio){
    return Utils.orderBy([
      {id: 'AUTOR', nome: 'Autor', dominio : ['PROTOCOLO_RI']},
      {id: 'REU', nome: 'Réu', dominio : ['PROTOCOLO_RI']},
      {id: 'ADQUIRENTE', nome: 'Adquirente', dominio : ['PROTOCOLO_RI']},
      {id: 'AVALISTA_FIADOR', nome: 'Avalista Fiador', dominio : ['PROTOCOLO_RI']},
      {id: 'COMPROMISSADO', nome: 'Compromissado', dominio : ['PROTOCOLO_RI']},
      {id: 'CREDOR', nome: 'Credor', dominio : ['PROTOCOLO_RI']},
      {id: 'CEDENTE', nome: 'Cedente', dominio : ['PROTOCOLO_RI']},
      {id: 'CESSIONARIO', nome: 'Cessionário', dominio : ['PROTOCOLO_RI']},
      {id: 'NU_PROPRIETARIO', nome: 'Nu-Proprietário', dominio : ['PROTOCOLO_RI']},
      {id: 'DEVEDOR', nome: 'Devedor', dominio : ['PROTOCOLO_RI']},
      {id: 'GARANTIDOR', nome: 'Garantidor', dominio : ['PROTOCOLO_RI']},
      {id: 'EMITENTE', nome: 'Emitente', dominio : ['PROTOCOLO_RI']},
      {id: 'INTERESSADO', nome: 'Interessado', dominio : ['PROTOCOLO_RI']},
      {id: 'ANUENTE', nome: 'Anuente', dominio : ['PROTOCOLO_RI']},
      {id: 'MEEIRO', nome: 'Meeiro', dominio : ['PROTOCOLO_RI']},
      {id: 'TESTEMUNHA', nome: 'Testemunha', dominio : ['PROTOCOLO_RI']},
      {id: 'TRANSMITENTE', nome: 'Transmitente', dominio : ['PROTOCOLO_RI']},
      {id: 'TRANSMITENTE_CREDITO', nome: 'Transmitente de Crédito', dominio : ['PROTOCOLO_RI']},
      {id: 'CONTRATANTE', nome: 'Contratante', dominio : ['PROTOCOLO_RI']},
      {id: 'INTERVENIENTE', nome: 'Interveniente', dominio : ['PROTOCOLO_RI']},
      {id: 'USUFRUTUARIO', nome: 'Usufrutuário', dominio : ['PROTOCOLO_RI']},
      {id: 'LOCADOR', nome: 'Locador', dominio : ['PROTOCOLO_RI']},
      {id: 'LOCATARIO', nome: 'Locatário', dominio : ['PROTOCOLO_RI']},
      {id: 'EXECUTADO', nome: 'Executado', dominio : ['PROTOCOLO_RI']},
      {id: 'EXEQUENTE', nome: 'Exequente', dominio : ['PROTOCOLO_RI']},
      {id: 'REQUERENTE', nome: 'Requerente', dominio : ['PROTOCOLO_RI']},
      {id: 'REQUERIDO', nome: 'Requerido', dominio : ['PROTOCOLO_RI']},
      {id: 'REPRESENTANTE', nome: 'Representante', dominio : ['PROTOCOLO_RI']},
      {id: 'PROCURADOR', nome: 'Procurador', dominio : ['PROTOCOLO_RI']},
      {id: 'PROMITENTE_VENDEDOR', nome: 'Promitente Transmitente', dominio : ['PROTOCOLO_RI']},
      {id: 'PROMITENTE_COMPRADOR', nome: 'Promitente Adquirente', dominio : ['PROTOCOLO_RI']},
      {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário', dominio : ['PROTOCOLO_RI']},

      {id: 'ARRENDATARIO', nome: 'Arrendatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'DECLARANTE', nome: 'Declarante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'DESTINATARIO', nome: 'Destinatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'MANDANTE', nome: 'Mandante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'MANDATARIO', nome: 'Mandatário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'PERMUTANTE', nome: 'Permutante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'PROPRIETARIO', nome: 'Proprietário', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'REMETENTE', nome: 'Remetente', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'SINDICO', nome: 'Síndico', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'NOTIFICADO', nome: 'Notificado', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'NOTIFICANTE', nome: 'Notificante', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},
      {id: 'PESSOA_JURIDICA_PRINCIPAL', nome: 'Pessoa Jurídica Principal', dominio : ['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ']},

    ]).filter((item) => {
      return item.dominio.includes(dominio) || !dominio;
    });
  },

  tiposDoi(antigos = false) {
    let tipos = [
      {id: 'ADJUDICACAO', nome: 'Adjudicação'},
      {id: 'ARREMATACAO_EM_HASTA_PUBLICA', nome: 'Alienação por iniciativa particular ou leilão judicial'},
      {id: 'CESSAO_DE_DIREITOS', nome: 'Cessão de direitos'},
      {id: 'COMPRA_E_VENDA', nome: 'Compra e venda'},
      {id: 'DACAO_EM_PAGAMENTO', nome: 'Dação em pagamento'},
      {id: 'DISTRATO_DE_NEGOCIO', nome: 'Distrato de Negócio'},
      {id: 'DOACAO_EM_ADIANTAMENTO_DA_LEGITIMA', nome: 'Doação em Adiantamento da Legítima'},
      {id: 'INCORPORACAO_E_LOTEAMENTO', nome: 'Incorporação e Loteamento'},
      {id: 'INTEGRALIZACAO_SUBSCRICAO_DE_CAPITAL', nome: 'Integralização Subscrição de Capital'},
      {id: 'PERMUTA', nome: 'Permuta'},
      {id: 'PROCURACAO_EM_CAUSA_PROPRIA', nome: 'Procuração em Causa Própria'},
      {id: 'PROMESSA_DE_CESSAO_DE_DIREITOS', nome: 'Promessa de Cessão de Direitos'},
      {id: 'PROMESSA_DE_COMPRA_E_VENDA', nome: 'Promessa de Compra e Venda'},
      {id: 'AFORAMENTO', nome: 'Aforamento'},
      {id: 'CASAMENTO', nome: 'Casamento em comunhão universal de bens'},
      {id: 'CISAO', nome: 'Cisão total ou parcial'},
      {id: 'COMPRA_E_VENDA_ENFITEUSE', nome: 'Compra e venda de imóvel gravado por enfiteuse'},
      {id: 'CONCESSAO_CDRU', nome: 'Concessão de Direito Real de Uso (CDRU)'},
      {id: 'CONCESSAO_CUEM', nome: 'Concessão de Uso Especial para Fins de Moradia (CUEM)'},
      {id: 'CONSOLIDACAO', nome: 'Consolidação da Propriedade em Nome do Fiduciário'},
      {id: 'DESAPROPRIACAO_REFORMA_AGRARIA', nome: 'Desapropriação para fins de Reforma Agrária'},
      {id: 'DESAPROPRIACAO_EXCETO_REFORMA_AGRARIA', nome: 'Desapropriação, exceto para Reforma Agrária'},
      {id: 'DIREITO_LAJE', nome: 'Direito de laje'},
      {id: 'DIREITO_SUPERFICIE', nome: 'Direito de Superfície'},
      {id: 'DOACAO_EXCETO_EM_ADIANTAMENTO_DA_LEGITIMA', nome: 'Doação, exceto em Adiantamento de Legítima'},
      {id: 'INCORPORACAO', nome: 'Incorporação'},
      {id: 'INVENTARIO', nome: 'Inventário'},
      {id: 'SEPARACAO_DIVORCIO_UNIAO_ESTAVEL', nome: 'Part. Separação/Divórcio/União Estável'},
      {id: 'RETORNO_DE_CAPITAL_PROPRIO_EXTINCAO_PJ', nome: 'Retorno de Capital Próprio na Extinção de Pessoa Jurídica'},
      {id: 'RETORNO_DE_CAPITAL_PROPRIO_EXCETO_EXTINCAO_PJ', nome: 'Retorno de Capital Próprio, exceto na Extinção de Pessoa Jurídica'},
      {id: 'TITULO_DOMINIO', nome: 'Título de Domínio - TD'},
      {id: 'USUCAPIAO', nome: 'Usucapião'},
      {id: 'OUTROS', nome: 'Outros'},
      {id: 'NENHUM', nome: 'Nenhum'},
    ];

    if(antigos){
      tipos = tipos.concat([
        {id: 'DESAPROPRIACAO', nome: 'Valor Desapropriação'},
        {id: 'DISSOLUCAO_DE_SOCIEDADE', nome: 'Dissolução de Sociedade'},
        {id: 'DOACAO', nome: 'Doação'},
        {id: 'HERANCA_LEGADO_OU_MEACAO', nome: 'Herança, Legado ou Meação (adjudicação)'},
        {id: 'PARTILHA_AMIGAVEL_OU_LITIGIOSA', nome: 'Partilha Amigável ou Litigiosa'},
        {id: 'RETORNO_DE_CAPITAL_PROPRIO', nome: 'Retorno de Capital Próprio'},
        {id: 'DOACAO_96_97', nome: 'Doação (96 e 97) Doação e doação em Adiant. Da Legítima (2.x)'},
        {id: 'RETIFICACAO_ATO', nome: 'Retificação do Ato'}
      ]);
    }

    return tipos;
  },

  tiposCertidao(){
    return [

      {id: 'INTEIRO_TEOR', nome: 'Inteiro Teor'},
      {id: 'INTEIRO_TEOR_SEM_BUSCA', nome: 'Inteiro Teor - Sem Busca (4hrs)'},
      {id: 'ONUS_ACOES', nome: 'Ônus & Ações'},
      {id: 'INFORMACAO_VERBAL', nome: 'Informação Verbal'},
      {id: 'COPIA_POR_PAGINA', nome: 'Cópia Reprográfica por Página'},
      {id: 'RELATORIO', nome: 'Certidão em Relatório'},
      {id: 'QUESITOS', nome: 'Certidão em Relatório com Quesitos'},
      {id: 'UNIDADES_VIRTUAIS', nome: 'Certidão de Atos Virtuais'},
      {id: 'SITUACAO_JURIDICA', nome: 'Certidão de Situação Jurídica'},

      // {id: 'TRANSCRICAO', nome: 'Transcrição'},
      // {id: 'RESUMO', nome: 'Resumo'},
      // {id: 'NEGATIVA_IMOVEL', nome: 'Negativa Imóvel'},
      // {id: 'NEGATIVA_PESSOA', nome: 'Negativa Pessoa'},

    ]
  },

  getLabelRestricaoPapel(restricao) {
    let papeisHabilitados = restricao.papeis.filter(papel => papel.habilitado).map(papel => papel.nome).join(', ');
    return papeisHabilitados ? 'Papéis: ' + papeisHabilitados : 'Papéis: Nenhum papel habilitado';
  },
  formatAuditoriaRestricoes(data) {
  let html = '';

    for (let i = 0; i < data.restricoes.length ; i++) {
      if (data.restricoes[i].habilitada) {
        html += `<b>${data.restricoes[i].nome}: Sim</b><br>`;
        let papel = this.getLabelRestricaoPapel(data.restricoes[i]);
        html += `${papel}<br>`;
      } else {
        html += `<b>${data.restricoes[i].nome}: Não</b><br>`;
      }
    }
  return html;
},

  getLabelRestricao(restricaoId){
    switch (restricaoId) {
      case 'ONUS' : return 'Ônus';
      case 'ACAO' : return 'Ação';
      case 'INDISPONIBILIDADE' : return 'Indisponibilidade';
      case 'COMPROMISSO' : return 'Compromisso de Compra e Venda';
      case 'DOMINIO' : return 'Domínio Útil';
    }
  },

  restricoes(){
    return [
      {id: 'ONUS', nome: 'Ônus'},
      {id: 'ACAO', nome: 'Ação'},
      {id: 'INDISPONIBILIDADE', nome: 'Indisponibilidade'},
      {id: 'COMPROMISSO', nome: 'Compromisso de Compra e Venda'},
      {id: 'DOMINIO', nome: 'Domínio Útil'}
    ]
  },

  restricoesPapeis(){
    return [
      {
        id: 'ONUS',
        nome: 'Ônus',
        habilitada: false,
        papeis: [
          {id: 'CREDOR', nome: 'Credor', habilitado: false},
          {id: 'DEVEDOR', nome: 'Devedor', habilitado: false},
          {id: 'EMITENTE', nome: 'Emitente', habilitado: false},
          {id: 'GARANTIDOR', nome: 'Garantidor', habilitado: false},
          {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário', habilitado: false}
        ]
      },
      {
        id: 'ACAO',
        nome: 'Ação',
        habilitada: false,
        papeis: [
          {id: 'CREDOR', nome: 'Credor', habilitado: false},
          {id: 'DEVEDOR', nome: 'Devedor', habilitado: false},
          {id: 'EMITENTE', nome: 'Emitente', habilitado: false},
          {id: 'GARANTIDOR', nome: 'Garantidor', habilitado: false},
          {id: 'FIEL_DEPOSITARIO', nome: 'Fiel Depositário', habilitado: false},
          {id: 'AUTOR', nome: 'Autor', habilitado: false},
          {id: 'REU', nome: 'Réu', habilitado: false}
        ]
      },
      {
        id: 'COMPROMISSO',
        nome: 'Compromisso de Compra e Venda',
        habilitada: false,
        papeis: [
          {id: 'PROMITENTE_COMPRADOR', nome: 'Promitente Adquirente', habilitado: false},
          {id: 'PROMITENTE_VENDEDOR', nome: 'Promitente Transmitente', habilitado: false}
        ]
      },
      {
        id: 'DOMINIO',
        nome: 'Domínio Útil',
        habilitada: false,
        papeis: [
          {id: 'DEVEDOR', nome: 'Devedor', habilitado: false}
        ]
      }
    ]
  },

  operacoesRestricao(){
    return [
      {id: 'CRIAR', nome: 'Criar Restrição'},
      {id: 'CANCELAR', nome: 'Cancelar Restrição'},
      {id: 'REATIVAR', nome: 'Reativar Restrição'},
      {id: 'ALTERAR_CREDOR', nome: 'Alterar Credor'}
    ]
  },

  operacoesCoaf(){
    return [
      {id: 'ADJUDICACAO', nome: 'Adjudicação', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'ARREMATACAO_EM_HASTA_PUBLICA', nome: 'Arrematação em hasta pública', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'CESSAO_DE_DIREITOS', nome: 'Cessão de direitos', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'COMPRA_E_VENDA', nome: 'Compra e venda', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DACAO_EM_PAGAMENTO', nome: 'Dação em pagamento', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DESAPROPRIACAO', nome: 'Valor Desapropriação', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DISSOLUCAO_DE_SOCIEDADE', nome: 'Dissolução de Sociedade', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DISTRATO_DE_NEGOCIO', nome: 'Distrato de Negócio', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DOACAO', nome: 'Doação', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'DOACAO_EM_ADIANTAMENTO_DA_LEGITIMA', nome: 'Doação em Adiantamento da Legítima', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'HERANCA_LEGADO_OU_MEACAO', nome: 'Herança, Legado ou Meação', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'INCORPORACAO_E_LOTEAMENTO', nome: 'Incorporação e Loteamento', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'INTEGRALIZACAO_SUBSCRICAO_DE_CAPITAL', nome: 'Integralização Subscrição de Capital', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'PARTILHA_AMIGAVEL_OU_LITIGIOSA', nome: 'Partilha Amigável ou Litigiosa', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'PERMUTA', nome: 'Permuta', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'PROCURACAO_EM_CAUSA_PROPRIA', nome: 'Procuração em Causa Própria', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'PROMESSA_DE_CESSAO_DE_DIREITOS', nome: 'Promessa de Cessão de Direitos', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'PROMESSA_DE_COMPRA_E_VENDA', nome: 'Promessa de Compra e Venda', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'RETORNO_DE_CAPITAL_PROPRIO', nome: 'Retorno de Capital Próprio', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'OUTROS', nome: 'Outros', dominio: ['RI', 'RTD', 'RCPJ']},
      {id: 'NENHUM', nome: 'Nenhum', dominio: ['RI', 'RTD', 'RCPJ']},
    ]
  },

  tiposRegistro(){
    return [
      {id: 'CEDULA', nome: 'Cédula'},
      {id: 'CONVENCAO_CONDOMINIO', nome: 'Convenção de Condomínio'},
      {id: 'PACTO', nome: 'Pacto Antenupcial'},
      {id: 'OUTROS', nome: 'Outros Títulos'},
    ];
  },

  livros(){
    return [
      {id: 'MATRICULA', nome: 'Matrícula'},
      {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
      {id: 'TRANSCRICAO', nome: 'Transcrição'},
      {id: 'TORRENS', nome: 'Torrens'},
    ];
  },

  async getRegras() {
    return utils.orderBy([
      {id: 'MATRICULA', nome: 'Matrícula'},
      {id: 'REGISTRO_SIMPLES', nome: 'Registro Simples'},
      {id: 'REGISTRO_SIMPLES_FAR', nome: 'Registro Simples Far'},
      {id: 'REGISTRO_SIMPLES_PMCMV', nome: 'Registro Simples PMCMV'},
      {id: 'REGISTRO_AVANCADO', nome: 'Registro Avançado'},
      {id: 'REGISTRO_INSTITUICAO', nome: 'Registro de Instituição com Especificação de Condomínio'},
      {id: 'REGISTRO_INCORPORACAO', nome: 'Registro de Incorporação'},
      {id: 'REGISTRO_TORRENS', nome: 'Registro Torrens'},
      {id: 'REGISTRO_DEBENTURES', nome: 'Registro Debêntures'},
      {id: 'LOTEAMENTO_GLEBA', nome: 'Loteamento/Gleba'},
      {id: 'CONVENCAO_CONDOMINIO', nome: 'Convenção de Condomínio'},
      {id: 'PACTO_ANTENUPCIAL', nome: 'Pacto Antenupcial'},
      {id: 'REGISTRO_CEDULA', nome: 'Registro Cédula'},
      {id: 'GARANTIA_IMOBILIARIA_RURAL', nome: 'Garantia Imobiliária Rural'},
      {id: 'GARANTIA_IMOBILIARIA_DEMAIS', nome: 'Garantia Imobiliária Demais'},
      {id: 'AVERBACAO', nome: 'Averbação'},
      {id: 'LEI_19571', nome: 'Lei 19.571'},
      {id: 'AVERBACAO_SEM_VALOR', nome: 'Averbação sem Valor'},
      {id: 'RETIFICACAO_HIPOTESE', nome: 'Retificação Hipóteses'},
      {id: 'RETIFICACAO_DEMAIS_HIPOTESES', nome: 'Retificação Demais Hipóteses'},
      {id: 'AVERBACAO_RETIFICACAO_HIPOTESE', nome: 'Averbação Retificação Hipótese'},
      {id: 'NOTIFICACAO_CONFRONTANTE_HIPOTESE', nome: 'Notificação Confrontante Hipótese'},
      {id: 'EXPEDICAO_EDITAL_HIPOTESE', nome: 'Expedição Edital Hipótese'},
      {id: 'RESERVA_LEGAL', nome: 'Reserva Legal'},
      {id: 'CERTIDAO_INTEIRO_TEOR', nome: 'Certidão de Inteiro Teor'},
      {id: 'CERTIDAO_RESUMO_MATRICULA', nome: 'Certidão Reumo de Matrícula'},
      {id: 'CERTIDAO_RELATORIO', nome: 'Certidão em Relatório'},
      {id: 'CERTIDAO_RELATORIO_QUESITOS', nome: 'Certidão em Relatório com Quesitos'},
      {id: 'CERTIDAO_TRANSCRICAO_INSCRICAO', nome: 'Certidão de Transcrição'},
      {id: 'CERTIDAO_NEGATIVA_PESSOA', nome: 'Certidão Negativa de Pessoa'},
      {id: 'CERTIDAO_NEGATIVA_IMOVEL', nome: 'Certidão Negativa de Imóvel'},
      {id: 'CERTIDAO_ONUS_ACOES', nome: 'Certidão de Ônus e Ações'},
      {id: 'CERTIDAO_INFORMACAO_VERBAL', nome: 'Informação Verbal'},
      {id: 'INTIMACAO_PESSOA', nome: 'Intimação por Pessoa'},
      {id: 'EXPEDICAO_EDITAL_INTIMACAO', nome: 'Expedição Edital Intimação'},
      {id: 'INTIMACAO_RURAL_QUILOMETRO', nome: 'Intimação Rural por Quilômetro'},
      {id: 'SEXTA_NOTA_GENERICA', nome: '6ª Nota Genérica'},
      {id: 'SETIMA_NOTA_GENERICA', nome: '7ª Nota Genérica'},
      {id: 'DECIMA_PRIMEIRA_GENERICA', nome: '11ª Genérica'},
      {id: 'COPIA_POR_PAGINA', nome: 'Cópia por Página'},
      {id: 'ATOS_GRATUITOS', nome: 'Atos Gratuitos'},
      {id: 'REGISTRO_PAGAMENTO_POSTERIOR', nome: 'Registro (Pagamento Posterior)'},
      {id: 'AVERBACAO_SEM_VALOR_PAGAMENTO_POSTERIOR', nome: 'Averbação Sem Valor (Pagamento Posterior)'},
      {id: 'USUCAPIAO_INICIO_PROCEDIMENTO', nome: 'Usucapião - Início do procedimento'},
      {id: 'USUCAPIAO_EFETIVACAO_REGISTRO', nome: 'Usucapião - Efetivação do registro'},
      {id: 'AVERBACAO_CEDULA_RURAL', nome: 'Averbação Cédula Rural'},

      {id: 'PAGAMENTO_DIFERIDO_CNIB_AVERBACAO_SEM_VALOR', nome: 'Ordem Judicial CNIB - Pagamento Diferido (Averbação sem valor)'},
      {id: 'PAGAMENTO_DIFERIDO_CNIB_BUSCA', nome: 'Ordem Judicial CNIB - Pagamento Diferido (Busca)'},
      {id: 'PAGAMENTO_DIFERIDO_CNIB_PRENOTACAO', nome: 'Ordem Judicial CNIB - Pagamento Diferido (Prenotação)'},

    ], 'nome')
  },

  camposBaseCalculo(dominio){
    let campos = AtoBusiness.camposBaseCalculo();
    if(['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ'].includes(dominio)) {
      campos = campos.filter(campo => campo.id === 'AVALIACAO');
    }
    return campos;
  },

  tiposAto(dominio){

    let opcoes = [
      {id: 'ABERTURA', nome: 'Abertura'},
    ]

    // if(['PROTOCOLO_RTD', 'PROTOCOLO_RCPJ'].includes(dominio)){
    //   opcoes = [];
    // }

    opcoes.push({id: 'AVERBACAO', nome: 'Averbação'});
    opcoes.push({id: 'REGISTRO', nome: 'Registro'})
    return opcoes;
  },

  getOperacoesCoaf(dominio) {
    if (DominioBusiness.isDominioRTD(dominio)) {
      return this.operacoesCoaf().filter(t => t.dominio.includes('RTD'));
    }
    if (DominioBusiness.isDominioRCPJ(dominio)) {
      return this.operacoesCoaf().filter(t => t.dominio.includes('RCPJ'));
    }
    if (DominioBusiness.isDominioRI(dominio)) {
      return this.operacoesCoaf().filter(t => t.dominio.includes('RI'));
    }
    return [];
  }
}

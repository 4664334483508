import Utils from "@/commons/Utils";
const folder = 'uploads';

export default {

  _getFileName(file){
    return Utils.removerAcentos(file.name.replaceAll(" ", "-").replaceAll(",", "-"));
  },

  _getName(file){
    return Utils.uuid()+"."+file.name.split('.').pop();
  },

  _getRelativePath(file, entidade, grupo, id) {
    const pastaEntidade = Utils.clone(entidade).toLowerCase().replaceAll("_", "-");
    if(grupo !== null && grupo !== false) {
      id = id + "/" + grupo;
    }
    return `${folder}/${pastaEntidade}/${id}/${this._getName(file)}`;
  },

  criarAnexo(file, entidade, id = '', checklist = false, grupo = null, etapa = '') {
    let anexo = {
      nome: this._getName(file),
      nomeAnexo: this._getFileName(file),
      caminho: this._getRelativePath(file, entidade, grupo, id),
      extensao: this._getFileName(file).split('.').pop(),
      tamanho: file.size,
      tipo: file.type,
      entidade: entidade,
      referencia: id,
      checklist: (checklist === undefined ? false : checklist),
    };
    if(!Utils.isEmptyNullOrUndefined(etapa)) {
      anexo.etapa = {id: etapa};
    }
    if(grupo !== null && grupo !== false) {
      anexo.grupo = grupo;
    }


    return anexo;
  }

}

<template>
  <consulta ref="modal" :columns="columns" :process="process"
            :custom-actions="actions"
            id="consultar-ocorrencia" :titulo="titulo" :stopLoad="true">

    <template #cabecalho>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>
      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>
    </template>

    <template #filtros>
      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode !== 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">

          <template v-if="item.id === 'matriculas_id'">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </template>

          <buscar-protocolos class="col mb-0" :noChangeSelect="true" :valorInicial="protocolo" label="Protocolos Relacionados"
                             ref="buscarProtocolos" @selecionar="selecionar('protocolo', ...arguments)"
                             :limparCampo="false" :multiple="false" :dominio="dominioProtocolo"
                             :tipoProtocolo="tipoProtocolo" v-if="item.id === 'protocolos_id'"/>
        </template>
      </filtrar-consulta>
      <template v-if="mode === 'default'">
        <div class="row no-gutters w-100" @keyup.enter="executeLoad()">
          <v-input type="text" label="Ocorrência Nº" v-model="filtros.codigo" class="col-3 px-1" />
          <v-input type="v-select" :options="listas.tiposServico" label="Tipo de Ocorrência" class="col-4 px-1" v-model="filtros.tipoServico"/>
          <v-input type="text" label="Descrição" v-model="filtros.descricao" class="col-5 px-1" />
          <div class="w-100" />
          <v-input type="select" :options="status" label="Status" v-model="filtros.status" class="col px-1"/>
          <v-input type="select" :options="listas.etapas" label="Etapa Atual" v-model="filtros.etapa_id" class="col px-1" />
          <div class="w-100" />
          <v-input type="date" label="Data Ocorrência (Início)" :required="true" v-model="filtros.dataInicio" class="col-2 px-1"/>
          <v-input type="date" label="Data Ocorrência (Fim)" :required="true" v-model="filtros.dataFim" class="col-2 px-1"/>
          <v-input type="date" label="Data Cadastro (Início)" :required="true" v-model="filtros.cadastroInicio" class="col-2 px-1"/>
          <v-input type="date" label="Data Cadastro (Fim)" :required="true" v-model="filtros.cadastroFim" class="col-2 px-1"/>
          <v-input type="date" label="Data Encerramento (Início)" :required="true" v-model="filtros.encerramentoInicio" class="col-2 px-1"/>
          <v-input type="date" label="Data Encerramento (Fim)" :required="true" v-model="filtros.encerramentoFim" class="col-2 px-1"/>
          <v-input type="date" label="Data do Processo (Início)" :required="true" v-model="filtros.dataProcessoInicio" class="col-3 px-1"/>
          <v-input type="date" label="Data do Processo (Fim)" :required="true" v-model="filtros.dataProcessoFim" class="col-3 px-1"/>
          <v-input type="date" label="Data de Reingresso (Início)" :required="true" v-model="filtros.dataReingressoInicio" class="col-3 px-1"/>
          <v-input type="date" label="Data de Reingresso (Fim)" :required="true" v-model="filtros.dataReingressoFim" class="col-3 px-1"/>
          <div class="w-100" />

          <v-input type="text" label="Número do processo" v-model="filtros.processo" class="col-4 px-1" />
          <v-input type="text" label="Juiz" v-model="filtros.juiz" class="col-4 px-1" />
          <v-input type="text" label="Vara" v-model="filtros.vara" class="col-4 px-1" />

          <v-input type="text" label="Ocorrência" v-model="filtros.ocorrencia" class="col-4 px-1" />
          <v-input type="text" label="Observações" v-model="filtros.observacoes" class="col-4 px-1" />
          <v-input type="text" label="Informações" v-model="filtros.informacoes" class="col-4 px-1" />

        </div>

        <card class="card-full mt-2" titulo="Matrículas Relacionadas">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="matricula" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado"
                           ref="buscarFichas" @selecionar="selecionar('matricula', ...arguments)" :multiple="false" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full mt-2" titulo="Protocolos Relacionados">
          <card-content class="row gutters-xs">
            <buscar-protocolos :noChangeSelect="true" :valorInicial="protocolo" label="Protocolos" ref="buscarProtocolos"
                               @selecionar="selecionar('protocolo', ...arguments)"
                               :limparCampo="false" :multiple="false" :dominio="dominioProtocolo" :tipoProtocolo="tipoProtocolo" class="pl-2 col"/>
          </card-content>
        </card>

        <card class="card-full card-envolvido mt-2 row gutters-xs" titulo="Envolvidos">
          <card-content class="row gutters-xs">
            <v-input type="select" label="Tipo de busca do CPF/CNPJ" :options="options.tiposBusca" v-model="filtros.tipoBuscaEnvolvidoDocumento" class="col-3" />
            <v-input type="text" label="CPF/CNPJ" v-model="filtros.envolvidoDocumento" class="col-3" />
            <v-input type="text" label="Nome" v-model="filtros.envolvidoNome" class="col" />
          </card-content>
        </card>
      </template>
    </template>

    <template #row="{ row }">


      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectItem(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <router-link :to="{ name: 'ocorrencia', params:{id : row.id} }" class="icon" target="_blank" @click.native="$event.stopImmediatePropagation()">
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </td>


        <td width="140px" class="pointer icon" @click="select(row)">
          <a>{{ row.codigo | inteiro }}</a>
        </td>
        <td width="140px">{{ row.tipo }}</td>
        <td width="140px">{{ row.data | data }}</td>
        <td width="140px">{{ row.cadastro | datahora }}</td>
        <td width="140px">{{ row.encerramento | datahora }}</td>
        <td width="50px" class="text-center">
          <v-button :run="duplicar" :params="[row.id]" :button="false" :popover="true" class="icon"
                    position="dropleft">
            <i class="fal fa-copy" title="Duplicar"></i>
          </v-button>
        </td>

      </tr>

    </template>

    <template #footer>

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="select" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import OcorrenciaBusiness from "@/business/ocorrencia/OcorrenciaBusiness.js";
import Consulta from "@/components/Consultas/Consulta";
import Card from "@/components/Layout/components/Card";
import CardContent from "@/components/Layout/components/CardContent";
import DetalhesOcorrencia from "@/views/Ocorrencia/DetalhesOcorrencia.vue";
import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta";
import Utils from "@/commons/Utils";
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness";
import EtapaBusiness from "@/business/crud/EtapaBusiness";

export default {
  name: "ConsultarOcorrencia",
  components: {FiltrarConsulta, Consulta, Card, CardContent},

  props: {
    multiplos : {type : Boolean, default : false}
  },

  data() {
    return {
      buscar : 'MATRICULA',
      mode: 'novo',
      opcoesConsulta: [],
      filtros : {
        numero: null,
        tipoServico: null,
        data: null,
        descricao: null,
        protocolo: null,
        matricula: null,
        envolvidoDocumento: null,
        envolvidoNome: null,
        tipoBuscaEnvolvidoDocumento: 'contains'
      },
      options: {
        tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
      },
      status: [{id: 'TODOS', nome: 'Todos'}, ...ProtocoloBusiness.getStatus()],
      protocolo: null,
      matricula: null,
      dominioProtocolo: 'PROTOCOLO_RI',
      tipoProtocolo: 'NORMAL',
      listas: {
        tiposServico: [],
        livros: [
          {id: 'MATRICULA', nome: 'Matrícula'},
          {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
        ],
        etapas: []
      },
      marcados: []
    }
  },

  computed:{

    titulo(){
      return 'Consultar Ocorrências';
    },

    columns(){
      let cols = [];
      if(this.multiplos){
        cols = [
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        ];
      }
      cols.push(
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
        {label:  'Código', field: 'o.codigo', name: 'codigo'},
        {label:  'Tipo', field: 't.nome', name: 'tipo'},
        {label:  'Data da Ocorrência', field: 'o.data_ocorrencia', name: 'data'},
        {label:  'Cadastro', field: 'o.cadastro', name: 'cadastro'},
        {label:  'Encerramento', field: 'o.encerramento', name: 'encerramento'},
        {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'}
      )
      return cols;
    },

    livroSelecionado() {
      return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
    },

    statusSelecionado() {
      return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
    },

    actions() {
      let actions = [];
      if (this.auditor) return actions;
      actions.push({
        class: 'at-options',
        icon: 'far fa-plus-square mr-1',
        label: 'Nova Ocorrência',
        action: this.novo
      });
      return actions;
    }
  },

  async mounted(){
    await TipoServicoBusiness.getByDominioSimples('OCORRENCIA').then(l => this.listas.tiposServico = Object.freeze(Utils.orderBy(l, 'nome')));
    await EtapaBusiness.getByDominioSimples('OCORRENCIA').then(l => this.listas.etapas = Object.freeze(Utils.orderBy(l, 'nome')));

    let opcoes = [];
    opcoes.push({id: 'tipo_servico_id', nome: 'Tipo de Ocorrência', type: 'list', options: this.listas.tiposServico});
    opcoes.push({id: 'descricao', nome: 'Descrição', type: 'text'});
    opcoes.push({id: 'data_ocorrencia', nome: 'Data Ocorrência', type: 'date'});
    opcoes.push({id: 'cadastro', nome: 'Data Cadastro', type: 'date'});
    opcoes.push({id: 'encerramento', nome: 'Data Encerramento', type: 'date'});
    opcoes.push({id: 'data_processo', nome: 'Data  do Processo', type: 'date'});
    opcoes.push({id: 'data_reingresso', nome: 'Data de Reingresso', type: 'date'});
    opcoes.push({id: 'processo', nome: 'Número do processo', type: 'text'});
    opcoes.push({id: 'juiz', nome: 'Juiz', type: 'text'});
    opcoes.push({id: 'vara', nome: 'Vara', type: 'text'});
    opcoes.push({id: 'ocorrencia', nome: 'Ocorrência', type: 'tsquery'});
    opcoes.push({id: 'observacoes', nome: 'Observações', type: 'tsquery'});
    opcoes.push({id: 'informacoes', nome: 'Informações', type: 'tsquery'});
    opcoes.push({id: 'matriculas_id',  nome: 'Matrículas Relacionadas', type: 'matricula'});
    opcoes.push({id: 'protocolos_id',  nome: 'Protocolos Relacionados', type: 'protocolo'});
    opcoes.push({id: 'envolvido_cpf',  nome: 'CPF Envolvido', type: 'cpf'});
    opcoes.push({id: 'envolvido_cnpj', nome: 'CNPJ Envolvido', type: 'cnpj'});
    opcoes.push({id: 'envolvido_nome', nome: 'Nome Envolvido', type: 'text'});
    opcoes.push({id: 'codigo', nome: 'Código', type: 'number'});
    opcoes.push({id: 'status', nome: 'Status/Situação', type: 'list', options: ProtocoloBusiness.getStatus()});
    opcoes.push({id: 'etapa_id', nome: 'Etapa Atual', type: 'list', options: this.listas.etapas})

    this.opcoesConsulta = Utils.orderBy(opcoes, 'nome');
  },

  methods:{

    close(){
      FrontBusiness.closeModal();
    },

    select(item) {
      FrontBusiness.closeModal(item);
    },

    async process(sort_by, sort_dir, page_length, page_number){

      if(this.filtros.status === 'TODOS'){
        this.filtros.status = null;
      }

      if(this.mode === 'novo') {
        let filtros = [...this.filtros?.filtros].filter(e => e.id);
        let matriculas_id = filtros.find(e => e.id === 'matriculas_id');
        if (matriculas_id) {
          matriculas_id.value = this.filtros.matricula;
        }
        let protocolos_id = filtros.find(e => e.id === 'protocolos_id');
        if (protocolos_id) {
          protocolos_id.value = this.filtros.protocolo;
        }
        return OcorrenciaBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros})
        .then(result => ({rows : result.pagina, total: result.quantidade}));
      }
      return OcorrenciaBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros : this.filtros})
      .then(result => ({rows : result.pagina, total: result.quantidade}));
    },

    novo(){
      FrontBusiness.openModal(DetalhesOcorrencia);
    },

    tipo(tipo){
      return FrontBusiness.getLabel(tipo, this.listas.tipos);
    },

    nomearProtocolo : FrontBusiness.nomearProtocolo,

    nomearFicha(ficha){
      return FrontBusiness.nomearFicha(ficha);
    },

    selecionar(nomeFiltro, item, add, x){

      this.$nextTick(() => {
        nomeFiltro = nomeFiltro || x;
        this.filtros[nomeFiltro] = item[0] ? item[0].id : null;
        if (nomeFiltro === 'matricula') {
          this[nomeFiltro] = item[0];
        } else {
          this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
        }
        if (add && item[0]) {
          this.executeLoad();
        }

      });
    },

    armazenarFiltro(){
      this.dominioProtocolo = this.$refs?.buscarProtocolos?.varDominio;
      this.tipoProtocolo = this.$refs?.buscarProtocolos?.varTipoProtocolo;
    },

    executeLoad(){
      this.armazenarFiltro();
      this.$refs.modal.load();
    },

    remover(idx, lista) {
      this.$nextTick(() => {
        this.filtros[lista].splice(idx, 1);
      });
    },

    async duplicar(id){
      let clone = await OcorrenciaBusiness.clonar(id);
      if (clone) {
        FrontBusiness.redirect(`ocorrencia/${clone.id}/geral`);
      }
    },

    selecionarTodos(limpar = false) {
      this.marcados = [];
      this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
        let input = document.getElementById(`check-${r.id}`)
        input.checked = !limpar;
        if (!limpar) this.marcados.push(r)
      })
    },

    selectItem(item) {
      let index = this.marcados.findIndex(e => e.id === item.id);
      if (index >= 0) {
        this.marcados.splice(index, 1)
      } else {
        this.marcados.push(item)
      }
    },

  }
}
</script>

<style lang=scss>
#consultar-ocorrencia{
  th.column__botao{
    width: 50px;
  }

  .buscar-fichas-wrapper .form-group, .buscar-protocolos-wrapper .form-group, .card-envolvido .form-group{
    margin-bottom: 0 !important;
  }
}
</style>

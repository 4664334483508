<template>
  <div id="aba-dados-principais">
    <card v-if="loading">
      <small-loading :loading="true"></small-loading>
    </card>

    <template v-else>
      <card :titulo="tipoRegistro">
        <card-content>

          <p>
            <span class="font-weight-500">CNM: </span><span>{{ficha.cnm}}</span>
            <button class="btn btn-sm btn-outline-info pointer ml-2" @click="gerarCNM(ficha.id)">Gerar CNM</button>
          </p>

          <p>
            <span class="font-weight-500">Situação: </span><span>{{status.find(s => s.id === ficha.status).nome}}</span>
          </p>

          <p v-if="ficha.cadastro">
            <span class="font-weight-500">Cadastro: </span><span>{{ficha.cadastro | datahora}}</span>
          </p>

          <p v-if="ficha.abertura">
            <span class="font-weight-500">Abertura: </span><span>{{ficha.abertura | datahora}}</span>
          </p>

          <p v-if="ficha.encerramento && (ficha.status === 'ENCERRADA' || ficha.status === 'CANCELADA')">
            <span v-if="ficha.status === 'ENCERRADA'" class="font-weight-500">Encerramento: </span><span v-if="ficha.status === 'CANCELADA'" class="font-weight-500">Cancelamento: </span><span>{{ficha.encerramento | datahora}}</span>
          </p>

          <p v-if="ficha.tipoRegistro == 'CEDULA' && ficha.tipoCedula">
            <span class="font-weight-500">Tipo de cédula: </span><span>{{tipoCedula}}</span>
          </p>

          <p v-if="ficha.tipoRegistro == 'CONVENCAO_CONDOMINIO'">
            <span class="font-weight-500">Nome condomínio: </span><span>{{nomeCondominio}}</span>
          </p>

          <p v-if="ficha.tipoRegistro == 'PACTO'">
            <span class="font-weight-500">Data do pacto: </span><span>{{$formatters.date(pacto)}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Identificação" v-if="ficha.livroLetra || ficha.vencimentoCedula || ficha.valorCedula">
        <card-content>
          <p v-if="ficha.livroLetra">
            <span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span>
          </p>

          <template v-if="ficha.tipoRegistro == 'CEDULA'">

            <p v-if="ficha.vencimentoCedula">
              <span class="font-weight-500">Vencimento: </span><span>{{$formatters.date(ficha.vencimentoCedula)}}</span>
            </p>

            <p v-if="ficha.valorCedula">
              <span class="font-weight-500">Valor: </span><span>{{ficha.valorCedula | currency}}</span>
            </p>

          </template>

        </card-content>
      </card>

      <card :titulo="labelTexto" v-if="ficha.texto">
        <card-content>
          <div v-html="ficha.texto"></div>
        </card-content>
      </card>

      <card titulo="Observações" v-if="ficha.observacoes">
        <card-content>
          <div v-html="ficha.observacoes"></div>
        </card-content>
      </card>

      <card titulo="Envolvidos" v-if="envolvidos && envolvidos.length">

        <table class="table table-striped card-table table-vcenter">

          <thead>
          <tr>
            <th class="pl-2">PESSOA</th>
            <th>DOCUMENTO</th>
            <th class="text-right pr-2">PAPEL</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(envolvido, index) in envolvidos" :key="index+envolvido.indicadorPessoalVersaoId">
            <td class="pl-2">
              <a href class="mb-1 pointer" @click.prevent="detalhes(envolvido.indicadorPessoalVersaoId)">{{envolvido.nome}}</a>
            </td>
            <td class="nowrap">{{envolvido.documento}}</td>
            <td class="nowrap text-right pr-2">{{nomePapel(envolvido.papel)}}</td>
          </tr>
          </tbody>

        </table>

      </card>

      <card titulo="Matrículas Relacionadas" v-if="ficha.indicadorReal && ficha.indicadorReal.length">
        <card-table>
          <thead>
            <tr>
              <th>Matrícula</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="matricula in ficha.indicadorReal" :key="matricula.id">
              <td class="pointer icon" @click="detalhesFicha(matricula.id)">
                {{nomearFicha(matricula)}}
              </td>
            </tr>
          </tbody>

        </card-table>
      </card>

      <TabelaRestricoesAtivas :ficha="ficha" />
    </template>

  </div>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import TabelaRestricoesAtivas from "@/components/Restricao/TabelaRestricoesAtivas";
  import business from "@/views/Administracao/modulos/tipo-servico/business";
  import SmallLoading from "@/components/SmallLoading";
  import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business.js";

  export default {

    name: "Dados",
    components: {TabelaRestricoesAtivas, CardTable, CardContent, Card, SmallLoading},
    props: {
      id: String,
    },

    data() {
      return {
        envolvidos : [],
        ficha : [],
        loading : true,
        status: RegistroAuxiliarBusiness.getStatusImovel()
      }
    },

    computed:{
      tipoRegistro(){
        if (this.ficha.tipoRegistro == 'OUTROS') {
          return `Outros Títulos ${this.ficha.descricaoTipoRegistroOutros ? '- ' + this.ficha.descricaoTipoRegistroOutros : ''}`
        }
        return this.ficha.tipoRegistro == 'CEDULA' ? 'Cédula' : this.ficha.tipoRegistro == 'PACTO' ? 'Pacto Antenupcial' : this.ficha.tipoRegistro == 'CONVENCAO_CONDOMINIO' ? 'Convenção de Condomínio' : 'Outros Títulos';
      },
      tipoCedula(){
        return this.ficha.tipoCedula ? (RegistroAuxiliarBusiness.getTiposCedulas()||[]).find(e => e.id == this.ficha.tipoCedula).nome : null;
      },
      labelTexto(){
        return this.ficha.tipoRegistro === 'CEDULA' ? "GARANTIA" : "TEOR";
      },
      nomeCondominio(){
        return this.ficha.condominio;
      },
      pacto(){
        return this.ficha.pacto;
      }
    },

    async mounted() {
      this.loading = true;
      this.ficha = await RegistroAuxiliarBusiness.getById(this.id);
      this.envolvidos = await RegistroAuxiliarBusiness.getEnvolvidos(this.id);
      this.loading = false;
    },

    methods:{

      nomePapel(papel){ return businessTipoServico.nomePapel(papel) },

      detalhes(versao){
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {versao});
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      detalhesFicha(id){
        FrontBusiness.openModal(DetalhesIndicadorReal, {id});
      },

      async gerarCNM(id) {
        RegistroAuxiliarBusiness.gerarCNM(id).then(response => {
          this.ficha.cnm = Object.freeze(response.cnm);
          FrontBusiness.showSuccess('', response.message);
        }).catch(error => FrontBusiness.showError('', error.message))
      }

    }
  }
</script>

<template>
  <div class="alert alert-banner" role="alert" :class="[classe, type]" >
    <span class="mr-2">
        <i :class="icon || 'fal fa-exclamation-triangle'"></i>
    </span>
    {{message}}
    <div class="mt-1">
      <v-button v-if="actionFunction !== null" :popover="popover" class="btn btn-secondary mr-2 small" style="padding: .1rem .5rem" :run="actionFunction">{{actionsText}}</v-button>
      <v-button v-if="nonActionFunction !== null" :popover="popover" class="btn btn-secondary mr-2 small" style="padding: .1rem .5rem" :run="nonActionFunction">{{nonActionsText}}</v-button>
    </div>
  </div>
</template>

<script>
    export default {
        name: "AlertBanner",
        props: {
          message: {type: String, default: ''},
          classe: {type: String, default: ''},
          icon: {type: String, default: ''},
          actionsText: {type: String, default: 'SIM'},
          nonActionsText: {type: String, default: 'NÃO'},
          popover: {type: Boolean, default: true},
          actionFunction: {type: Function, default: null},
          nonActionFunction: {type: Function, default: null},
          type: {type: String, default: 'alert-warning'},
        }
    }
</script>

<style>
  .alert.alert-banner:not(.alert-native){
    margin: .75rem 0rem;
    border-radius: .25rem;
  }
</style>

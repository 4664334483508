<template>
  <modal titulo="Preview de Unidades">
    <card titulo="Pendentes">
      <card-table class="table-borderless mb-2" >
        <thead>
        <tr>
          <th>Ficha</th>
          <th>Tipo</th>
          <th>Unidade</th>
          <th>Bloco</th>
          <th>Lote</th>
          <th>Quadra</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(unidade, i) in unidades" :key="unidade.id">
            <td>
              {{nomearFicha({ livro : 'MATRICULA', codigo : unidade.codigo || i, status : unidade.status })}}</td>
            <td>
              {{tipoImovel(unidade.tipoImovel)}}
            </td>
            <td>{{unidade.unidade}}</td>

            <td>{{unidade.bloco}}</td>
            <td>{{unidade.lote}}</td>
            <td>{{unidade.quadra}}</td>

            <td class="text-left">
              <a title="Detalhes da Unidade" class="icon ml-2" @click.prevent="detalhes(unidade)">
                <i class="fal fa-home"></i>
              </a>
            </td>

          </tr>
        </tbody>
      </card-table>
    </card>

  </modal>
</template>

<script>

  import CardTable from "@/components/Layout/components/CardTable";
  import Card from "@/components/Layout/components/Card";
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import Modal from "@/components/Modal";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";

  export default {
      components: {
        CardTable, Card, Modal
      },
      props : {
        unidades : {type : Array, required : true},
      },

      name: "ListaUnidadesPreview",
      data(){
        return {
        }
      },

      methods:{

        nomearFicha : FrontBusiness.nomearFicha,

        tipoImovel(tipo) {
          return FrontBusiness.getLabel(tipo, IndicadorRealBusiness.getTiposImovel());
        },

        detalhes(unidade) {
          FrontBusiness.openModal(DetalhesIndicadorReal, {preview: unidade});
        },

      }

    }
</script>

import axios from 'axios'

const api = axios.create({
  withCredentials: true,
});

/** controle de requisições **/
const MAX_REQUESTS_COUNT = 25;
const INTERVAL_MS = 5;
let PENDING_REQUESTS = 0;

const installApi = async () => {
  if(!window.server?.API){
    await fetch('env.json').then(response => response.json()).then(env => {
      let url = env.API === '/' ? window.location.origin : env.API;
      if(url.endsWith('/')){
        url = url.substring(0, -1);
      }
      api.defaults.baseURL = url;
      window.server.API = url;
    }).catch(error => null);
  }
}

const authError = (error) => {

  let message = error?.response?.data || error;

  if(error.response && error.response?.status == 401){
    //FrontBusiness.showWarn('', 'Necessário realizar o login');
  }

  if (error?.message === "Network Error"){
    // console.warn('Aguardando conexão. Aguarde um instante e tente novamente, por favor.');
    message = 'Aguardando conexão. Aguarde um instante e tente novamente, por favor.';
  }

  return Promise.reject(message);
};

const getServer = () => window.server.API;

export default {
  async install(Vue) {

    await installApi();

    api.interceptors.request.use(config => {

      //filtragem no conteudo enviado pela tela para o back, de forma a garantir que não sejam enviados objetos vazios
      // if (config.method === 'post' && config.data && typeof config.data === 'object') {
      //   const isSafeToClean = !(config.data instanceof URLSearchParams) && !(config.data instanceof FormData) && !(config.data instanceof Blob);
      //   if (isSafeToClean) {
      //     const nova = Utils.cleanDeep(config.data);
      //     config.data = nova;
      //   }
      // }

      return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
          if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
            PENDING_REQUESTS++
            clearInterval(interval)
            resolve(config)
          }
        }, INTERVAL_MS)
      });
    });

    api.interceptors.response.use(response => {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.resolve(response.data);
    }, error => {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return authError(error);
    });

    Vue.API = Vue.prototype.$api = api;

  }
};

export {api, getServer, installApi};

import getVar from "@/business/livros/copiladores/getVar.js";
import testarCondicao from "@/business/livros/copiladores/testarCondicao.js";
import Utils from "@/commons/Utils.js";

const filtrarLista = async function(node, callback, dicionario, debug){

  let lista = getVar(node.params.lista, dicionario, false);

  // filtrar
  const novaLista = lista?.filter(e => testarCondicao(node.params, e, debug)) || [];
  if(debug) {
    debug.novaLista = novaLista;
  }

  // cria objeto de transporte
  let obj = {};
  obj[node.params.nome || 'lista'] = novaLista;

  return await callback(node.children, {...dicionario, ...obj}, debug);

};

export default filtrarLista;

<template>
  <modal :titulo="titulo" :closeable="false">

    <card :title="processo">
      <card-content>

        <p>{{processo}}</p>

        <p>
          <span>{{contador.atual}} / {{contador.total}}</span>
          <small-loading :loading="true" style="display: inline-block; margin-top: 5px" class="ml-2 mb-1">
          </small-loading>
        </p>

      </card-content>
    </card>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal.vue";
  import SmallLoading from "@/components/SmallLoading.vue";

  export default {
    name: "SeeModal",
    components: {SmallLoading, CardContent, Card, Modal},
    props: {
      titulo: String,
      url: String,
      aviso: String,
      mostrarErro: {type:Boolean, default: () => true}
    },

    modal:{
      width: 400,
      escapable: false
    },

    data() {
      return {
        contador:{
          total: 0,
          atual: 0
        },
        erro: null,
        processo: null,
        sse: null
      }
    },

    mounted() {
      this.iniciar();
    },

    computed:{
      urlTratada(){
        return this.url.startsWith('/') ? "/"+(this.url.replace(/^\/+/, "")) : this.url;
      }
    },

    methods: {
      async iniciar(){

        this.sse = await this.$sse(this.urlTratada, {withCredentials : true});

        // Catch any errors (ie. lost connections, etc.)
        this.sse.onError(e => {
          console.error('lost connection; giving up!', e);
          this.sse.close();
        });

        // Listen for messages without a specified event
        this.sse.subscribe('', (data, rawEvent) => {
          console.debug('Received a message w/o an event!', data, rawEvent);
          this.messages.push(data);
        });

        this.sse.subscribe('chat', (data, rawEvent) => {
          this.messages.push(data);
        });

        this.sse.subscribe('iniciar', (data) => {
          this.$set(this.contador, 'total', parseInt(data, 10));
        });

        this.sse.subscribe('proximo', (data) => {
          this.$set(this.contador, 'atual', parseInt(data, 10));
        });

        this.sse.subscribe('erro', (data) => {
          this.sse.close();
          if(this.mostrarErro){
            FrontBusiness.showError('', data);
            FrontBusiness.closeModal(false);
          }else{
            FrontBusiness.closeModal({erro : data});
          }
        });

        this.sse.subscribe('processo', (data) => {
          this.$set(this, 'processo', data);
        });

        this.sse.subscribe('complete', (data) => {
          console.debug(data);
          this.sse.close();
          FrontBusiness.closeModal(data);
        });

      }
    }

  }
</script>

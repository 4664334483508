<template>
  <card titulo="Vínculo Societário">

    <template #acoes>
      <a class="text-secondary ml-1" href title="Adicionar Vínculo Societário" @click.prevent="formulario()" v-if="pessoa.tipoPessoa === 'JURIDICA'">
        <i class="fas fa-plus"></i>
      </a>
    </template>

    <card-table class="table-borderless mb-2" v-if="socios.length > 0">

      <thead>
        <tr>
          <th>{{tiluloColuna}}</th>
          <th>Vínculo</th>
          <th>Inicio </th>
          <th>Fim</th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="socio in socios" :key="socio.id">
          <td>
              {{pessoa.tipoPessoa === "FISICA" ? socio.indicadorPessoal.nome : socio.indicadorPessoalSocio.nome}}
          </td>
          <td>{{getVinculo(socio)}}</td>
          <td>{{socio.inicio | data}}</td>
          <td>{{socio.fim | data}}</td>
          <td width="30px">
            <a title="Editar" v-if="pessoa.tipoPessoa !== 'FISICA'" class="pointer" @click.prevent="formulario(socio)">
              <i class="fal fa-edit"></i>
            </a>
          </td>
          <td width="30px">
            <v-button  v-if="pessoa.tipoPessoa !== 'FISICA'" :run="remover" :button="false" :params="[socio]" :popover="true" position="dropleft" class="icon" style="color: #FEE17D;">
              <i class="fal fa-times" title="Excluir"></i>
            </v-button>
          </td>
        </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading" ></small-loading>

    <div v-if="!socios.length && !loading" class="alert alert-outline-info">
      Não há vínculos societários para exibir
    </div>

  </card>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import SocioBusiness from "@/business/indicadores/SocioBusiness";
  import SocioFormulario from "@/components/IndicadorPessoal/Detalhes/SocioFormulario";
  import Card from "@/components/Layout/components/Card.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import SmallLoading from "@/components/SmallLoading";

  export default {

    name: "Socios",
    components: {SmallLoading, CardTable, Card},
    props: {
      pessoa : Object
    },

    data() {
      return {
        socios : [],
        loading: true,
      }
    },

    async mounted(){
      this.load();
    },
    computed: {

      getTipo() {
        return this.pessoa.tipoPessoa === "FISICA" ? 'listarEmpresas' :'listarSocios';
      },

      tiluloColuna() {
        return this.pessoa.tipoPessoa === "FISICA" ? 'Empresa' : 'Nome';
      },
    },

    methods:{

      async formulario(socio = {}){
        await FrontBusiness.openModal(SocioFormulario, { id : socio.id, indicadorPessoal: this.pessoa.indicadorPessoal });
        this.load();
      },

      async load(){
        this.socios = await SocioBusiness[this.getTipo](this.pessoa.indicadorPessoalId);
        this.loading = false;
      },

      async remover(socio) {
        await SocioBusiness.deleteById(socio.id)
        .catch(e => FrontBusiness.showError('', e.message));
        this.load();
      },

      getVinculo(item) {
        if (item.tipoVinculo !== 'OUTRO') {
          return FrontBusiness.getLabel(item.tipoVinculo, this.$root.enums?.EnumTipoVinculo);
        }
        return "Outro - " + item.descricao;
      }

    }
  }
</script>

<template>
	<table class="async-table">

    <thead>
      <header-cell v-for="(column, i) in columns" :column="column" :key="i" :loading="loading"
                   :direction="getDirection(column)" @change="toggleSort" />
    </thead>

    <tbody data-testid="tabela-consulta">

      <slot name="error" v-if="error">
        <tr>
          <td colspan="100% p-0">
            <div class="alert alert-outline-danger">Não foi possível realizar a consulta</div>
          </td>
        </tr>
      </slot>

      <slot name="loading" v-if="loading">
        <tr>
          <td colspan="100%" align="center">

            <div class="col-auto d-flex align-items-center flex-column" style="min-height: 150px;">
              <div class="my-auto text-center">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>

          </td>
        </tr>
      </slot>

      <slot name="row" v-for="row in rows" :row="row" :columns="columns" v-else />
      <slot name="rowExtra"/>

      <slot name="empty" v-if="!loading && (!rows || !rows.length) && !error" />

    </tbody>

    <tfoot v-if="$slots.footer || $scopedSlots.footer">
      <slot name="footer" :rows="rows" />
    </tfoot>

  </table>
</template>

<script>
import EventBus from "@/commons/EventBus";
import Utils from "@/commons/Utils.js";
import HeaderCell from "@/components/Table/HeaderCell.vue";
import TableEventBus from './index';

export default {

    name: "AsyncTable",
    components: {HeaderCell},

    props: {
      columns : Array,
      perPage : {type : Number, default : 10},
      delayed : {type : Boolean, default : false},
      pagination : Function,
      name : {type : String, required : true},
    },

    computed:{
    },

    data() {
      return {
        qtd_registro : this.perPage,
        loading: false,
        page: 1,
        sortBy : null,
        sortDir : null,
        totalRows : 0,
        rows : [],
        setQtd: false,
        process : null,
        error: false
      }
    },

    mounted(){

      this.process = Utils.debounce(function(){
        this.$set(this, 'loading', true);
        this.$set(this, 'error', false);
        this.pagination(this.sortBy, this.sortDir, this.qtd_registro, this.page).then(async r => {

          if(this.setQtd && r.total > 0 && !r.rows.length){
            this.$set(this, 'page', 1);
            await this.process();
          } else {
            TableEventBus.$emit(`vap-mount-${this.name}`, this.page, r.total, this.qtd_registro);
            this.$set(this, 'totalRows', r.total);
            this.$set(this, 'rows', r.rows);
            this.$set(this, 'loading', false);
            EventBus.$emit(`ASYNC-TABLE-ROWS`, r.rows || []);
          }

          this.$emit('paginated', {rows: this.rows, totalRows: this.totalRows});

        })?.catch(e => {
          console.log(e)
          this.$set(this, 'error', true);
          this.$set(this, 'totalRows', 0);
          this.$set(this, 'rows', []);
          this.$set(this, 'loading', false);
          this.$emit('paginated', {rows: this.rows, totalRows: this.totalRows});

        })
      }, 150);

      if(!this.delayed){
        this.process();
      }

      TableEventBus.$on(`vap-set-page-${this.name}`, this.paginar);

    },

    beforeDestroy(){
      TableEventBus.$off(`vap-set-page-${this.name}`, this.paginar);
    },

    methods:{

      toggleSort(column){
        if(!this.loading){
          if(column.field == this.sortBy){
            this.sortDir = !this.sortDir || this.sortDir == 'desc' ? 'asc' : 'desc';
          }else{
            this.sortBy = column.field;
            this.sortDir = 'asc';
          }
          this.process();
        }
      },

      getDirection(column){
        return column.field == this.sortBy ? this.sortDir : null
      },

      paginar(page, qtd = this.qtd_registro, setQtd = false){
        if(!this.loading){
          this.$set(this, 'page', page);
          this.$set(this, 'qtd_registro', qtd);
          this.$set(this, 'setQtd', setQtd);
          this.process();
        }
      },

    }
  }
</script>

import Utils from "@/commons/Utils.js";

const debugar = async function(node, callback, dicionario ){

  const debugador = {
    dicionario : Utils.clone(dicionario),
    nodes: []
  };

  let output = await callback(node.children, dicionario, debugador);
  console.debug('Debug', Utils.clone(debugador), output);

  return output;

};

export default debugar;

<template>
  <consulta :columns="columns" :process="process" id="consultar-cep" titulo="Consultar CEP">

    <template #filtros>
      <v-input v-model="filtros.estado" :options="options.estado" type="select" label="Estado" class="col-3" />

      <v-input v-model="filtros.cidade" type="text" placeholder="Cidade" label="Cidade" class="col-3"/>

      <v-input v-model="filtros.bairro" type="text" placeholder="Bairro" label="Bairro" class="col-3"/>

      <v-input v-model="filtros.logradouro" type="text" placeholder="Logradouro" label="Logradouro" style="width: 570px;" class="col"/>

    </template>

    <template #row="{ row }">

      <tr>
        <td @click="select(row)" class="pointer icon" >
          <a>{{ row.cep }}</a>
        </td>
        <td>{{ row.logradouro }}</td>
        <td>{{ row.bairro }}</td>
        <td>{{ row.cidade }}</td>
        <td>{{ row.estado }}</td>

      </tr>

    </template>

  </consulta>
</template>

<script>
import EnderecoBusiness from "@/business/EnderecoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import Consulta from "@/components/Consultas/Consulta";
import CepBusiness from "@/business/CepBusiness";

export default {
    name: "ConsultarCep",
    components: {Consulta},
    data() {

      return {
        filtros : {},
        options: {
          estado: EnderecoBusiness.getEstados()
        }
      }
    },

    computed:{
      columns(){
        return [
          {label: 'CEP', field: 'c.cep', headerClass: 'column__cep', name: 'cep', minWidth: '85px'},
          {label: 'Logradouro', field: 'c.logradouro', headerClass: 'column__logradouro', name: 'logradouro'},
          {label: 'Bairro', field: 'c.bairro', headerClass: 'column__bairro', name: 'bairro'},
          {label: 'Cidade', field: 'm.cidade', headerClass: 'column__cidade', name: 'cidade'},
          {label: 'UF', field: 'm.estado', headerClass: 'column__estado', name: 'estado', minWidth: '50px'},
        ]
      }
    },

    methods:{

      close(){
        FrontBusiness.closeModal();
      },

      select(cep){
        FrontBusiness.closeModal(cep);
      },

      async process(sort_by, sort_dir, page_length, page_number){
        return CepBusiness.getEndereco(sort_by, sort_dir, page_number, page_length, {filtros : this.filtros})
          .then(result => ({rows : result.pagina, total: result.quantidade}));
      }

    }
  }
</script>

<style lang=scss>
#consultar-cep{
  th.column__botao{
    width: 50px;
  }
}
</style>

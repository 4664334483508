import error from "@/business/livros/copiladores/error.js";
import proxy from "@/business/livros/copiladores/proxy.js";
import Utils from "@/commons/Utils.js";
import moment from "moment";

const getVar = function(key, dicionario = {}, useProxy = true, rawOnError = false, nullable = false){

  try{

    // let tmp = Utils.get(dicionario, key, undefined);
    let keys = (key + '').split('.'), tmp = {...dicionario};
    keys.forEach((k, i) => tmp = (tmp || {})[k]);

    if(tmp !== undefined && tmp !== null){
      return useProxy ? proxy(tmp, key) : tmp;
    }

  }catch (e) {
    console.error('getVar', e);
  }

  // console.error(rawOnError, key, this.error(key));

  return nullable ? undefined : (rawOnError ? key : error(key));

};

export default getVar;

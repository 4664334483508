<script>

import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Utils from "@/commons/Utils";
import FrontBusiness from "@/business/FrontBusiness";

export default {

    name: "EditarRegistroAuxiliar",
    components: {Card, CardTable},
    props: ['ato', 'edicao', 'ficha'],

    data(){
      return {
        form: {},
        validation: {},
        tipoCedulas: RegistroAuxiliarBusiness.getTiposCedulas(),
        options : {
          campos : []
        },
        camposEditado: 0,
        indicadorReal: JSON.stringify(this.ficha.indicadorReal),
        buscar : 'MATRICULA',
        indicador_real: '',
        listas:{
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        },
        selected: []
      }
    },

    computed:{
      isCedula(){
        return this.ficha.tipoRegistro === 'CEDULA'
      },

      isPacto(){
        return this.ficha.tipoRegistro === 'PACTO'
      },

      isConvencao(){
        return this.ficha.tipoRegistro === 'CONVENCAO_CONDOMINIO'
      },

      isOutros(){
        return this.ficha.tipoRegistro === 'OUTROS'
      },

      titulo(){
        return this.isCedula ? "Garantia" : "Teor";
      },

      camposEditados(){
          let retorno = [], camposEditado = this.camposEditado;
          this.options.campos.forEach((c) => {
              if(c.id !== 'valorRegistro'){
                  if(this.ato.dto[c.id] !== undefined) retorno.push(c);
              } else {
                  if(this.ato.dto.valoresBaseCalculo[c.id] !== undefined) retorno.push(c);
              }

          })
          return retorno
      },

      optionsCampos() {
          let opcoes = this.options.campos;
          let campos = this.camposEditados;
          if (campos.length > 0) {
              opcoes = opcoes.filter(function (obj) {
                  let c = campos.find(a => a.id == obj.id);
                  return !c;
              });
          }
          return opcoes;
      },

      livroSelecionado() {
          return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
      },

      statusSelecionado() {
          return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
      }

    },

    mounted() {
        if(this.isCedula){
            this.options.campos = [
                {id: 'tipoCedula', nome: 'Tipo da cédula'},
                {id: 'valorRegistro', nome: 'Valor da cédula'},
                {id: 'vencimento', nome: 'Vencimento'}
            ];
        } else if(this.isPacto) {
            this.options.campos = [
                {id: 'pacto', nome: 'Pacto'}
            ];
        } else if(this.isConvencao){
            this.options.campos = [
                {id: 'condominio', nome: 'Nome do condomínio'}
            ];
        } else if(this.isOutros){
            this.options.campos = [
              {id: 'descricaoTipoRegistroOutros', nome: 'Descrição'}
            ];
        }

        this.options.campos.push({id: 'texto', nome: this.titulo});
        this.options.campos.push({id: 'observacoes', nome: 'Observações'});
        this.options.campos.push({id: 'indicadorReal',  nome: 'Matrículas Relacionadas', type: 'matricula'});
        this.options.campos.push({id: 'livroLetra', tipo: 'text', nome: 'Livro (Letra)'});
        this.validarData();
    },

    methods: {
        name(id) {
            return (this.options.campos.find(c => c.id == id) || {}).nome;
        },
        add(e) {
            this.$nextTick(() => {
                if (this.form.campo == 'indicadorReal') {
                  if (this.ato.dto.indicadorReal) this.$set(this.ato.dto, 'indicadorReal', this.selected);
                }
                if(this.form.campo && this.form.valor){
                    if (this.form.campo != 'valorRegistro' ) {
                        this.$set(this.ato.dto, this.form.campo, this.form.valor);
                    } else {
                        this.$set(this.ato.dto.valoresBaseCalculo, this.form.campo, this.form.valor);
                    }
                  this.$set(this, 'form', {});
                  this.$set(this, 'form', {});
                  this.$set(this, 'camposEditado', this.camposEditado + 1);
                  this.validarData();
                  // this.$emit('change-editor', e, 'CAMPO_REGISTRO_AUXILIAR');
                }
            });
        },

        remove(key) {
            const dto = Object.assign({}, this.ato.dto);
            if (key != 'valorRegistro' ) {
                if(key == 'indicadorReal'){
                  this.selected = [];
                }
                delete dto[key];
            } else {
                delete dto.valoresBaseCalculo[key];
            }
            this.ato.dto = dto;
            this.validarData();
        },

        validarData(){
          let data = this.ato.dto?.pacto || this.form.valor;
          this.$set(this.validation, 'pacto', null);
          if (data && (this.$moment(data).isAfter(this.$moment()) || !this.$moment(data).isValid() || data.split('-')[0] < '1850')) {
            this.$set(this.validation, 'pacto', 'Data inválida');
          }
        },

        selecionarFichas(nomeFiltro, item, add, x){
          if(!this.selected.find(e => e.id == item[0].id)){
            this.selected.push(item[0])
          }
        },

        nomearFicha(ficha){
          return FrontBusiness.nomearFicha(ficha);
        },
    }

  }
</script>

<template>

  <card titulo="Editar Registro Auxiliar">

    <card-table class="table-borderless">

      <thead>
      <tr>
        <th width="200px">Campo</th>
        <th class="pr-0">Valor</th>
        <th width="20px"></th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="campo in camposEditados" :key="campo.id">

        <td>{{campo.nome}}</td>

        <td class="pr-0">

          <v-input :disabled="!edicao" v-model="ato.dto[campo.id]" :options="tipoCedulas"
                   type="select" v-if="campo.id == 'tipoCedula'"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'valorRegistro'" type="currency" :precision="2"
                   v-model="ato.dto.valoresBaseCalculo[campo.id]"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'vencimento'" v-model="ato.dto[campo.id]" type="date"
                   placeholder="Data do vencimento"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'pacto'" v-model="ato.dto[campo.id]" type="date"
                   placeholder="Data do pacto"  :error="validation.pacto" @change="validarData" />

          <v-input :disabled="!edicao" v-if="campo.id == 'condominio'" type="text" v-model="ato.dto[campo.id]"
                   :placeholder="campo.nome"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'texto'" type="editor" v-model="ato.dto[campo.id]"
                   :placeholder="campo.nome"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'observacoes'" type="editor" v-model="ato.dto[campo.id]"
                   :placeholder="campo.nome"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'livroLetra'" type="text" v-model="ato.dto[campo.id]"
                   :placeholder="campo.nome"/>

          <v-input :disabled="!edicao" v-if="campo.id == 'descricaoTipoRegistroOutros'" type="text" v-model="ato.dto[campo.id]"
                   :placeholder="campo.nome"/>
          <template v-if="campo.id === 'indicadorReal'">
            <card-table>
              <tbody v-for="i in ato.dto.indicadorReal">
              <tr>
                <td>
                  {{nomearFicha(i)}}
                </td>
              </tr>
              </tbody>
            </card-table>
          </template>
        </td>

        <td class="text-right">
          <div class="ato-action" v-if="edicao">
            <a href="" @click.prevent="remove(campo.id)" class="icon"><i class="fa fa-times"></i></a>
          </div>
        </td>

      </tr>

      <tr v-if="!camposEditados || !camposEditados.length">
        <td colspan="100%" class="p-0">
          <div class="alert alert-outline-secondary alert-sm mb-0">
            Nenhum campo será alterado
          </div>
        </td>
      </tr>

      </tbody>


      <tfoot v-if="edicao">
        <tr>

          <td class="pl-0" style="vertical-align: top;">
            <v-input :idindice="'CAMPO_REGISTRO_AUXILIAR'" class="mb-0" v-model="form.campo"
                     :small="true" type="select" :options="optionsCampos"></v-input>
          </td>

          <td class="px-0">
            <card v-if="form.campo === 'indicadorReal'">
              <card-table class="mb-0">
                <thead>
                  <tr style="display: flex; margin-bottom: 10px" >
                    <th>Matrícula</th>
                  </tr>
                </thead>
                <tbody>
                <tr style="display: flex; padding-bottom: 12px" v-for="i in selected">
                  <td>
                    {{nomearFicha(i)}}
                  </td>
                </tr>
                <tr>
                  <td colspan="100%">
                    <div class="row">
                      <v-input class="col-auto pr-0 mb-2" v-model="buscar" :options="listas.livros" type="select" />
                      <buscar-fichas v-model="form.valor=selected" :livro="livroSelecionado" :status="statusSelecionado"
                                     ref="" @selecionar="selecionarFichas('indicador_real', ...arguments)"
                                     class="pl-2 col"/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </card-table>

            </card>
            <v-input v-model="form.valor" :options="tipoCedulas"
                     type="select" v-if="form.campo == 'tipoCedula'"/>

            <v-input v-if="form.campo == 'valorRegistro'" type="currency" :input-props="{precision : 2}"
                     v-model="form.valor"/>

            <v-input v-if="form.campo == 'vencimento'" v-model="form.valor" type="date"
                     placeholder="Data do vencimento"/>

            <v-input v-if="form.campo == 'pacto'" v-model="form.valor" type="date"
                     placeholder="Data do pacto" :error="validation.pacto" @change="validarData"/>

            <v-input v-if="form.campo == 'condominio'" type="text" v-model="form.valor"
                     :placeholder="name(form.campo)"/>

            <v-input v-if="form.campo == 'texto'" type="editor" v-model="form.valor"
                     :placeholder="name(form.campo)"/>

            <v-input v-if="form.campo == 'observacoes'" type="editor" v-model="form.valor"
                     :placeholder="name(form.campo)"/>

            <v-input v-if="form.campo == 'livroLetra'" type="text" v-model="form.valor"
                     :placeholder="name(form.campo)"/>

            <v-input v-if="form.campo == 'descricaoTipoRegistroOutros'" type="text" v-model="form.valor"
                     :placeholder="name(form.campo)"/>
          </td>

          <td class="text-right pr-0" style="vertical-align: top;">
            <div class="ato-action" v-if="form && form.campo">
              <a href="" @click.prevent="add($event)" class="btn btn-outline-secondary btn-xs">
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </td>

        </tr>
      </tfoot>

    </card-table>


  </card>

</template>

import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/protocolo';

export default{

  async listar(protocolo, tipo = ''){
    return Http.get(`${path}/${protocolo}/custa-adicional/listar/${tipo}`);
  },

  async listarIds(protocolo){
    return Http.get(`${path}/${protocolo}/custa-adicional/listar-ids`);
  },

  async save(dto, protocolo, id){
    return Http.post(`${path}/${protocolo}/custa-adicional${id ? '/'+id : ''}`, dto);
  },

  async getById(protocolo, id){
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${protocolo}/custa-adicional/${id}`);
  },

  async delete(id, protocolo){
    return Http.delete(`${path}/${protocolo}/custa-adicional/${id}`);
  },

  async editarDataSelagem(dto, protocolo){
    return Http.post(`${path}/${protocolo}/custa-adicional/atualizar-data-selagem`, dto);
  },

  formatarValorCusta(item){

    if(item.isento && !item.valor){
        return 'Isenta';
    }

    return Number(item.valor * item.quantidade).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: 'decimal',
      currency: 'BRL'
    });

  },

}

<template>
  <card id="cardVersao" titulo="Dados anteriores">
    <template #acoes>
      <a class="text-secondary ml-1" href title="Adicionar dado anterior" @click.prevent="addIndicador()">
        <i class="fas fa-plus"></i>
      </a>
    </template>
    <card-table class="table-borderless mb-2" :dragscroll="true" :cardClassName="cardClassName" v-if="versoes.length > 0">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th v-for="(title, index) in titles" :key="index">{{title.nome}}</th>
      </tr>
      </thead>

      <tbody>

      <tr v-for="(versao, i) in versoes" :key="versao.id">
        <td>
          <v-button class="icon" :run="remove" :params="[versao, i]" :button="false" :popover="true" title="Excluir Versão">
            <i class="fa fa-times"></i>
          </v-button>
        </td>
        <td>
          <v-button class="icon" :run="editarIndicador" :params="[versao]" :button="false" title="Editar Versão">
            <i class="fa fa-edit"></i>
          </v-button>
        </td>
        <td v-for="(title, index) in titles" :key="index">
          <template v-if="title.type === 'boolean'">
            {{versao[title.id] ? 'Sim' : 'Não'}}
          </template>
          <template v-else-if="title.type === 'enum'">
            {{getLabel(versao[title.id], title.options)}}
          </template>
          <template v-else-if="title.type === 'datahora'">
            {{versao[title.id] | datahora}}
          </template>
          <template v-else>
            {{versao[title.id]}}
          </template>
        </td>

      </tr>
      </tbody>

    </card-table>

    <small-loading v-if="loading"></small-loading>

    <div v-if="!versoes.length && !loading" class="alert alert-outline-info">
      Não há dados anteriores para exibir
    </div>

  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import SmallLoading from "@/components/SmallLoading";
import FormIndicadorRealHistorico from "@/components/IndicadorReal/Form/FormIndicadorRealHistorico.vue";
import IndicadorRealHistoricoBusiness from "@/business/indicadores/IndicadorRealHistoricoBusiness";
import Utils from "@/commons/Utils";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import EnderecoBusiness from "@/business/EnderecoBusiness";

export default {

  name: "Versões",
  components: {SmallLoading, CardTable, Card},
  props: {
    ficha: Object,
    consulta: Boolean
  },

  data() {

    let title = [
      {"nome": "Versão", "id": "versao"},
      {"nome": "Categoria", "id": "categoria", "type": "enum", "options": IndicadorRealBusiness.getCategorias()},
      {"nome": "Unidade Autônoma", "id": "unidadeAutonoma", "type": "boolean"},
      {"nome": "Tipo de imóvel", "id": "tipoImovel", "type": "enum", "options": IndicadorRealBusiness.getTiposImovel()},
      {"nome": "Descrição do Tipo de Imóvel", "id": "descricaoTipoImovelOutros"},
      {"nome": "Imóvel da União", "id": "imovelUniao", "type": "boolean"},
      {"nome": "Imóvel da Marinha", "id": "marinha", "type": "boolean"},
      {"nome": "Imóvel de Fronteira", "id": "fronteira", "type": "boolean"},
      {"nome": "Número da unidade", "id": "unidade"},
      {"nome": "Bloco", "id": "bloco"},
      {"nome": "Quadra", "id": "quadra"},
      {"nome": "Lote", "id": "lote"},
      {"nome": "Localização", "id": "localizacao"},
      {"nome": "Empreendimento/Condomínio", "id": "empreendimento"},
      {"nome": "Andar", "id": "andar"},
      {"nome": "Pavimento", "id": "pavimento"},
      {"nome": "Grupo (Tipo)", "id": "grupo"},
      {"nome": "Denominação da Área Rural", "id": "denominacao"},
      {"nome": "INCRA (CCIR)", "id": "incra"},
      {"nome": "ITR", "id": "itr"},
      {"nome": "CAR", "id": "car"},
      {"nome": "Certificação no SIGEF", "id": "certificacaoIncra"},
      {"nome": "Cadastro imobiliário", "id": "cadastroImobiliario"},
      {"nome": "Cadastro Imobiliário Brasileiro (CIB)", "id": "cadastroImobiliarioBrasileiro"},
      {"nome": "Cadastro fiscal", "id": "cadastroFiscal"},
      {"nome": "CEP", "id": "cep"},
      {"nome": "Tipo logradouro", "id": "tipoLogradouro"},
      {"nome": "Logradouro (rua)", "id": "logradouro"},
      {"nome": "Número", "id": "numero"},
      {"nome": "Bairro (setor)", "id": "bairro"},
      {"nome": "Cidade", "id": "cidade"},
      {"nome": "Estado", "id": "estado"},
      {"nome": "Complemento", "id": "complemento"},
      {"nome": "ID", "id": "id"},
      {"nome": "Cadastro", "id": "cadastro", "type": "datahora"},
      {"nome": "Atualização", "id": "atualizacao", "type": "datahora"}
    ];

    return {
      versoes: [],
      cardClassName: {'card-table-scroll': true},
      titles: title,
      loading: true,
      options: {
        tipos: IndicadorRealBusiness.getTiposImovel(),
        categorias: IndicadorRealBusiness.getCategorias(),
        estados: EnderecoBusiness.getEstados(),
      },
    }
  },

  async mounted() {
    await this.load();
  },
  methods: {

    getLabel: FrontBusiness.getLabel,
    async load(){
      this.loading = true;
      this.versoes = await IndicadorRealHistoricoBusiness.listarDadosAnteriores(this.ficha.id);
      this.loading = false;
    },

    update(){
      this.$emit('update', this.versoes);
    },
    async remove(versao, i){
      await IndicadorRealHistoricoBusiness.delete(versao.id).then(response => {
        this.versoes.splice(i, 1);
      }).catch(e => FrontBusiness.showError('', e.message));
      this.update();
    },

    async editarIndicador(versao) {
      let retorno = await FrontBusiness.openModal(FormIndicadorRealHistorico, {id: versao.id});
      if(retorno){
        await this.load();
      }
    },

    async addIndicador() {
      let indicador = {};
      this.titles.map(f => {
        indicador[f.id] = Utils.clone(this.ficha[f.id]);
      })
      delete indicador.id;
      indicador.indicadorReal = {id: this.ficha.id, livro: this.ficha.livro};
      indicador.versao = this.versoes[0]?.versao + 1;
      let retorno = await FrontBusiness.openModal(FormIndicadorRealHistorico, {indicador});
      if(retorno){
        await this.load();
      }
    },
  }
}
</script>

<style lang=scss>
.card-table-scroll {
  padding-bottom: 5px !important;

  overflow: auto !important;

  table {
    width: max-content;

    th, td {
      padding: 0.75rem 1rem;
    }
  }
}
</style>

import Http, {source} from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/usuario';

let cancelSource;
const cancelSearch = () => {
  if (cancelSource) {
    cancelSource.cancel();
  }
}
export default {

  async getAll(resetCache = ''){
    return Http.get(`${path}${resetCache ? '?reset=' + resetCache : ''}`);
  },

  async getSimpleList() {
    return Http.get(`${path}/simple`);
  },

  async getSimpleListIds(ids) {
    return Http.post(`${path}/simple`, ids);
  },

  async getAllSimpleList() {
    return Http.get(`${path}/all-simple`);
  },

  async getById(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/${id}`);
  },

  async getByDepartamento(id) {
    if(!Utils.testUUID(id)){
      return Promise.reject();
    }
    return Http.get(`${path}/departamento/${id}`);
  },

  async save(dto, id = '') {
    return Http.post(`${path}/${id}`, dto);
  },

  async saveAvatar(id, avatar) {
    return Http.post(`${path}/${id}/avatar`, avatar);
  },

  async deleteAvatar(id) {
    return Http.delete(`${path}/${id}/avatar`);
  },

  async alertaVersao(id, dto = {}) {
    if(!id){
      return;
    }
    return Http.post(`${path}/${id}/alerta-versao`, dto);
  },

  async validarEmailDuplicado(dto){
    return Http.post(`${path}/email`, dto);
  },

  async getAvatar(id, cache = true) {
    let url = `${path}/public/${id}/avatar${!cache ? `?clear=${Date.now()}` : ''}`;

    if(!Utils.testUUID(id)){
      return 'img/odin.png';
    }

    let avatar = await Http.get(url, {cache : cache})
      .catch(() => null);
    return avatar?.length > 1 ? `${window.server.API}/${url}` : 'img/odin.png';
  },

  getPublicAvatar(id) {
    return `${window.server.API}/${path}/public/${id}/avatar`;
  },

  async sugerir(query, departamento) {
    cancelSearch();
    cancelSource = source.source();
    let retorno;
    await Http.get(`${path}/sugerir/${departamento}/${query}`, { cancelToken: cancelSource.token }).then((response) => {
      if (response) {
        retorno = response;
        cancelSource = null;
      }
    });
    return retorno;
  },

  async pagination(sort, direction, page, size, filters = {}){
    return Http.post(`${path}/pagination?sort=${sort || 'u.nome'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

  async getGestores(){
    return Http.get(`${path}/list-all/gestores`);
  },

  async atualizarSenha(dto){
    return Http.post(`${path}/atualizar-senha`, dto);
  },

  async getRegistroAtividade(usuario, dto){
    return Http.post(`${path}/registro-atividade/${usuario}`, dto);
  },

}

<template>
  <div class="loading-spinner text-center" :class="{'d-inline-block': inline, 'loading-black': black }">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props:{
    size: {type : String, default : 'normal'},
    inline: {type : Boolean, default : () => true},
    black: {type : Boolean, default : () => false},
  }
}
</script>

<style lang="scss">
/*https://tobiasahlin.com/spinkit/*/
.loading-spinner {
  position: relative;
  min-width: 30px;

  --color: white;

  &.loading-black{
    --color: black;
  }

  .spinner {
    margin: 0 auto;
    width: 30px;
    height: 20px;
    text-align: center;
    font-size: 10px;
  }

  &.d-inline-block .spinner{
    bottom: -5px;
    position: absolute;
  }

  .spinner > div {
    background-color: var(--color);
    height: 100%;
    width: 4px;
    display: inline-block;
    margin-left: 2px;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }

}

</style>

import Vue from 'vue';

Vue.filter('currency', function (value, decimais = 2, empty = '0,00') {
  return !value ? empty : Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: decimais?.min || decimais,
    maximumFractionDigits: decimais?.max || decimais,
    style: 'decimal',
    currency: 'BRL'
  });
});

Vue.filter('moeda', function (value, max = 5) {

  if(!value){
    return '-';
  }

  let v = Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: max,
    style: 'decimal',
    currency: 'BRL'
  });

  return `${v}`;

});

<template>
  <modal :titulo="tituloCancelar" id="consulta-selo">

    <card>
      <form @submit.prevent="" class="row gutters-xs form">

        <card-table>

          <thead>
          <tr>
            <th>Código do Selo</th>
            <th width="30px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(codigo, i) in form.codigos" :key="i">
            <td>{{codigo}}</td>
            <td>
              <v-button :run="remover" :params="[i]" :button="false" :popover="true" class="icon" position="dropleft">
                <i class="fal fa-times" title="Excluir"></i>
              </v-button>
            </td>
          </tr>
          <tr>
            <td colspan="100%">
              <div class="row no-gutters w-100">
                <v-input class="col" :error="validation.codigo"
                         v-model="form.codigo" type="text"/>
                <div class="col-auto">
                  <button class="btn btn-outline-secondary btn-xs" @click="adicionar()" title="Adicionar" type="button">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </card-table>

        <div class="w-100"></div>

        <v-input :error="validation.reembolso" v-model="form.reembolso" v-if="mostrarReembolso"
                 label="Haverá Reembolso?"
                 type="radio" class="col-auto mt-2" :required="true" />

        <v-input :error="validation.anular" v-model="form.anular" v-if="mostrarAnular"
                 label="Anular Selo?"
                 type="radio" class="col-auto mt-2" :required="true" />

        <div class="w-100"></div>

        <v-input type="textarea" label="Motivo" class="col-12"
                 v-model="form.motivo" :error="validation.motivo" />

      </form>
    </card>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Fechar</button>
      <v-button :run="cancelarSelo" class="btn-outline-primary" :popover="true">{{tituloCancelar}}</v-button>
    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness.js";
  import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness.js";
  import SeloBusiness from "@/business/SeloBusiness.js";
  import utils from "@/commons/Utils.js";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import Utils from "@/commons/Utils.js";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";

  export default {
    name: "ModalConsultaSelo",
    components: {CardTable, Card, Modal},

    props: {
      codigos: {type: Array, default: () => []}
    },

    data() {
      return {
        form: {
          codigos : [],
          reembolso : true,
          anular : true,
          motivo : 'Cancelamento realizado para manter a regularidade dos atos de registro.'
        },
        validation: {},
        erros: [],
      }
    },

    created() {
      this.form.codigos = this.codigos;
    },

    computed:{

      tituloCancelar() {
        return this.$root.isEstadoPR ? 'Anular Selo' : 'Cancelar Selo';
      },

      estado(){
        return this.$root.config.estado;
      },

      mostrarReembolso(){
        return this.estado == 'GO';
      },

      mostrarAnular(){
        return this.estado == 'PR';
      }

    },

    methods: {

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '');
      },

      close() {
        FrontBusiness.closeModal();
      },

      async cancelarSelo() {

        if(this.form.motivo.length && this.form.codigos.length) {
          let codigos = Utils.clone(this.form.codigos)
          let codigosErro = []
          for (let x in codigos) {
            this.erros = await SeloBusiness.inutilizar(codigos[x], {
              reembolso: this.form.reembolso,
              anular: this.form.anular,
              motivo: this.form.motivo
            });
            let codigoTemErro = false;
            for (let e in this.erros) {
              codigoTemErro = true;
              FrontBusiness.showError('', this.erros[e])
            }
            if (codigoTemErro) {
              codigosErro.push(codigos[x]);
            }
          }
          let plural = codigos.length - codigosErro.length > 1 ? 's' : '';
          if (codigosErro.length !== this.form.codigos.length) FrontBusiness.showSuccess('', `Selo${plural} cancelado${plural} com sucesso`);
          this.$set(this.form, 'codigos', codigosErro);
          if (!codigosErro.length) await this.close();
        }

      },

      async adicionar() {
        if (!this.form.codigo) return;
        if (this.form.codigos.includes(this.form.codigo)) return this.form.codigo = null;
        this.form.codigos.push(this.form.codigo)
        this.form.codigo = null
      },

      remover(idx){
        this.form.codigos.splice(idx, 1);
      },

    }

  }
</script>

